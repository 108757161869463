import { addDays } from 'date-fns'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { requestResourcesWithRelated } from '../../../datastore'
import { selectProjectSites } from '../../../redux/selectors/projectSites'
import { selectTasks } from '../../../redux/selectors/tasks'
import { selectCanCreateTaProjectSites } from '../../../redux/selectors/abilities'

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results 
 * 
 * @returns  requested resources 
 */
export const useGeneralDetailsResources = (projectId: number | null) => {
    const dispatch = useAppDispatch()

    const periodStart = addDays(new Date(), -7)

    useEffect(() => {
        dispatch(requestResourcesWithRelated('projects', { eqFilter: { status: 'OPEN' } }, ['projectSites.sites']))
    }, [])


    useEffect(() => {
/*
// Disabled request for tasks for now - too slow. Need to be able to query by date...
        if (projectId) {
            dispatch(requestResources('tasks', {
                eqFilter: { project: projectId },
                periodFilter: { attr: 'startDate', periodStart }
            }))
        }
*/
    }, [projectId])

    const allProjectSites = useAppSelector(state => selectProjectSites(state, {}))

    return {
        availableProjectSites: useAppSelector(state => selectCanCreateTaProjectSites(state, allProjectSites)),
        tasks: useAppSelector(state => selectTasks(state, { projectId: projectId || undefined, periodStart })),
    }
}