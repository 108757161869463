import { extractTagContent } from "./helpers/extractTag";

/**
 * Parse HTML template and extract the following:
 * - Information we've placed in meta tag, i.e. selector name and 'incomplete' message
 * - style tag
 * - html body 
 */
export const parseTemplate = ( docHtml: string ) => {
    
        if (!docHtml) {
            return {}
        }

        // If document is properly formatted html, it will contain 'head' and 'body' sections:
        // -> extract the head (not required in output, but may contain tags with info that we need)    
        
        const head = extractTagContent(docHtml, 'head');
        const body = extractTagContent(docHtml, 'body');
        
        let interactive = false
        let incompleteMessage = ''
        let selectorNames: string[] = []
        let styleNode = null
        let title = ''

        if (head) {
            // Create a temporary div and populate it with 'head' contents (so we can parse it more easily)
            const tempContainer = document.createElement('div')
            tempContainer.innerHTML = head

            // Search for tags that are useful
            for (const node of Array.from(tempContainer.childNodes)) {
                if (node.nodeName === 'META') {
                    // Check the <meta> tag for useful attributes...                        
                    if ((node as HTMLElement).dataset) {
                        // data-selector-names / data-selector-name attribute
                        // If present, specifies one or more 'selectors' to use to retrieve live data for document
                        const selectorNamesList = (node as HTMLElement).dataset.selectorNames || ''
                        if (selectorNamesList) {
                            selectorNames = selectorNamesList.split(',') 
                        }
                        if ((node as HTMLElement).dataset.selectorName) {
                            selectorNames.push((node as HTMLElement).dataset.selectorName as string)
                        }

                        interactive = (node as HTMLElement).dataset.interactive === 'true'

                        // data-incomplete-message attribute
                        // Message to display if user leaves any options incomplete (e.g. doesn't check any of a radio button group)
                        incompleteMessage = (node as HTMLElement).dataset.incompleteMessage || ''
                    }

                } else if (node.nodeName === 'TITLE') {
                    // If document includes a title, record it
                    title = node.textContent || ''

                } else if (node.nodeName === 'STYLE') {
                    // If document includes an internal style sheet, record it
                    styleNode = node
                }
            }
        }

    return {
        interactive,
        incompleteMessage,
        title,
        styleNode,
        selectorNames,
        head,
        body
    }
}

