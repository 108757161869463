import { TaEdit } from '../../../redux/slices/pgTaskAnalysis'

    /**
     * 
     * @returns true if SWMS contains at least one configured control
     */
     export const getControlsConfigured = (taEdit:TaEdit) => {
        let controlFound = false
        taEdit.userData.steps.forEach(step => {
            step.hazards.forEach(hazard => {
                if (hazard.controls.length) {
                    controlFound = true
                }
                if (hazard.customControls.length) {
                    controlFound = true
                }
            })
        })
        return controlFound
    }