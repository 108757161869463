const env = {
    nodeEnv: process.env.NODE_ENV,
    cognitoDomain: process.env.REACT_APP_COGNITO_DOMAIN,
    cognitoClientId: process.env.REACT_APP_COGNITO_CLIENTID,
    cognitoCallbackUri: process.env.REACT_APP_COGNITO_CALLBACK_URI,
    cognitoLogoutUri: process.env.REACT_APP_COGNITO_LOGOUT_URI,

    portalAuthUrl: process.env.REACT_APP_PORTAL_AUTH_URL,
    portalClientId: process.env.REACT_APP_PORTAL_CLIENTID,
    appCallbackUri: process.env.REACT_APP_NATIVE_CALLBACK_URI,
    appLogoutUri: process.env.REACT_APP_NATIVE_LOGOUT_URI,

    appUrl: process.env.REACT_APP_URL,
    apiRoot: process.env.REACT_APP_API_ROOT,
    // ALLOWS iPHONE VIEWING
    // apiRoot: 'http://192.168.1.93:8080/api/v1/',
    appVersion: '2.02'
}

export default env