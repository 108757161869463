import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { requestResourcesWithRelated } from '../../../datastore'
import { selectOpenSites } from '../../../redux/selectors/sites'
import { selectCanCreateObservationSites } from '../../../redux/selectors/abilities'

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results 
 * 
 * @returns  requested resources 
 */

export const useDetailsResources = (siteId: number | null) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
       dispatch(requestResourcesWithRelated('projects', { eqFilter: { status: 'OPEN' } },['projectSites.sites']))
    }, [])

    const sites = useAppSelector(state => selectOpenSites(state, {}))

    return {
        availableSites: useAppSelector(state => selectCanCreateObservationSites(state, sites)),
    }
}