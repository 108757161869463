import { AppDispatch, RootState } from "../../redux/store";
import { removeResource, ResourceMap, ResourceType } from "../../redux/slices/resources";
import { RequestResourcesOptions } from "../requestResources";
import { filterResources } from "./filterResources";
import { NormalizedData } from "@disruptph/json-api-normalizer";
import { processReceivedResources } from './processReceivedResources'


/**
 * Process data requested using 'changeDetectFilter' - used when updating resources that may change in such a way 
 * as to move outside our scope of interest, for example a site changing from 'open' to 'closed'.
 * 
 * This will be done as follows:
 * 
 * 1. Perform an initial request using 'autoRequestFilter', i.e. filter defining what we actually want
 * 2. Perform subsequent 'modified since time x' requests using 'changeDetectFilter', inclusive enough to pick up any modified resources
 *    that now fall outside 'autoRequestFilter'
 * 
 * 3. (this function) process result as follows:
 * 
 * - Get the subset of returned data that satisfies 'autoRequestFilter' - this is the resources we want
 * 
 * - Get the subset of returned data that DOESN'T satisfy 'autoRequestFilter' ('resourceIdsOutsideFilter')
 * 
 * - Get the existing resoures in the store that DO satisfy 'autoRequestFilter' ('existingResourceIds')
 * 
 * - Any resources in both resourceIdsOutsideFilter and existingResourceIds are resources that we requested previously
 *   but which have changes such that they no longer fall in our scope of interest, and should therefore be removed from the store  *    
 * 
 * @param newResources  - New resource data returned by api
 * @param opts          - options object containing 'autoRequestFilter'
 */
export const processChangeDetectedResources = (newResources: NormalizedData, resourceType: ResourceType, opts: RequestResourcesOptions, dispatch: AppDispatch, getState: () => RootState) => {

    const newResource = newResources[resourceType]
    if (newResource) {
        const allNewIds = Object.keys(newResource)

        // Get subset of resources returned from api that satisfy filter
        const resourcesToUpdate = filterResources(newResources as { [key: string]: ResourceMap }, resourceType, opts)
        const resourceIdsToUpdate = resourcesToUpdate.map(resource => resource.id)

        // Get subset that are outside filter
        const resourceIdsOutsideFilter = allNewIds.filter(id => !resourceIdsToUpdate.includes(id))

        const existingResourceIds = filterResources(getState().resources, resourceType, opts).map(resource => resource.id)
        // Any existing resources in the store that previously satisfied the filter, but which are in resourceIdsOutsideFilter,
        // must have changed - these are resources that should be removed from store
        const resourceIdsToRemove = existingResourceIds.filter(id => resourceIdsOutsideFilter.includes(id))

        // remove resources that need to be removed
        resourceIdsToRemove.forEach(id => {
            dispatch(removeResource({ resourceType, id }))
        })

        // Convert 'resources to update' from array back to 'normalized' object, and update in store
        const normalisedResourcesToUpdate = {
            [resourceType]: resourcesToUpdate.reduce((acc: ResourceMap, resource) => {
                acc[resource.id] = resource
                return acc
            }, {})
        }
        return processReceivedResources(normalisedResourcesToUpdate as NormalizedData, dispatch, getState)
    }
}