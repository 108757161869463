import { requestSiteObservation } from '../siteObservations/resourceRequests/requestObservation'
import { getSiteObservation } from './siteObservation'
import { requestIncident } from '../incidentReports/resourceRequests/requestIncident'
import { getIncident } from './incidentReport'
import { requestTa } from '../taskAnalysis/resourceRequests/requestTa'
import { getTaskAnalysis } from './taskAnalysis'

import { getSitePpe, requestSitePpe } from './sitePpe'
import { getToolbox, requestToolbox  } from './toolbox'
import { getPersonnelOnSite, requestPersonnelOnSite } from './personnelOnSite'
import { getSite, requestSite } from './site'
import { getLoggedInUser, requestLoggedInUser } from './loggedInUser'
import { getSiteContacts, requestSiteContacts } from './siteContacts'
import { getSiteHazards, requestSiteHazards } from './siteHazards'
import { getSiteNotifiableWork, requestSiteNotifiableWork } from './siteNotifiableWork'
import { getSiteHighRiskWork, requestSiteHighRiskWork } from './siteHighRiskWork'
import { getSiteProjects, requestSiteProjects } from './siteProjects'
import { getSitePlans, requestSitePlans } from './sitePlans'

const reportRequests = {

    loggedInUser: {
        request: requestLoggedInUser,
        get: getLoggedInUser
    },
    
    site:{
        request: requestSite,
        get: getSite
    },

    siteProjects:{
        request: requestSiteProjects,
        get: getSiteProjects
    },
    
    siteContacts:{
        request: requestSiteContacts,
        get: getSiteContacts
    },

    siteHazards:{
        request: requestSiteHazards,
        get: getSiteHazards
    },

    sitePpe:{
        request: requestSitePpe,
        get: getSitePpe
    },

    siteNotifiableWork:{
        request: requestSiteNotifiableWork,
        get: getSiteNotifiableWork
    },

    siteHighRiskWork:{
        request: requestSiteHighRiskWork,
        get: getSiteHighRiskWork
    },

    sitePlans:{
        request: requestSitePlans,
        get: getSitePlans
    },

    ta: {
        request: requestTa,
        get: getTaskAnalysis
    },

    toolbox: {
        request: requestToolbox,
        get: getToolbox
    },
    
    siteObservation:{
        request: requestSiteObservation,
        get: getSiteObservation
    },        
    
    incident:{
        request: requestIncident,
        get: getIncident
    },    

    personnelOnSite: {
        request: requestPersonnelOnSite,
        get: getPersonnelOnSite      
    }
}

export default reportRequests