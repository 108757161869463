import {
  addSeconds,
  differenceInSeconds,
  isAfter,
  isBefore,
  parseJSON,
  sub
} from "date-fns"
import { getLogger } from "loglevel"

import { AppDispatch, RootState } from "../redux/store"
import { setRequestComplete } from "../redux/slices/resourceMeta"
import { RequestStatus, ResourceEvent } from "./types"
import { ApiResult, ApiService } from "../interfaces/api"
import {
  LocalResourceData,
  removeResource,
  ResourceType
} from "../redux/slices/resources"
import { requestResources } from "./requestResources"
import { filterResources } from "./helpers/filterResources"
import { setApiLastPingTime } from "../redux/slices/temp"
import { restoreExistingSession, UserCheckStatus } from "../features/auth"
import { setUser } from "../features/auth/setUser"

const log = getLogger("resourceRequests")

const MAX_IN_PROGRESS_SECONDS = 60
const OFFLINE_PING_SECONDS = 30
const USER_UPDATE_SECONDS = 600

var updateInProgress = false

/**
 * Periodically refresh resource data from api
 */
export const updateResources =
  (updateTime: Date) =>
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      const api: ApiService = services.api

      if (updateInProgress) {
        // don't continue if the previous update is still in progress
        log.debug("updateResources runtime exceeded 10 seconds")
        return
      }

      const state = getState()

      // Don't attempt update if no user logged in
      if (!state.app.user) {
        return
      }

      updateInProgress = true

      try {
        for (const requestKey in state.resourceMeta.activeRequests) {
          // Check whether an active request has been 'in progress' for too long
          // Requests should timeout anyway, this is just a fallback to make sure request can't be stuck 'in progress' forever
          // TODO: cancel a timed out request at Axios level?

          const { status, requestStartTime } =
            state.resourceMeta.activeRequests[requestKey]

          if (
            status === RequestStatus.IN_PROGRESS &&
            differenceInSeconds(updateTime, parseJSON(requestStartTime)) >
            MAX_IN_PROGRESS_SECONDS
          ) {
            log.warn(`updateResources: ${requestKey} request timed out`)
            dispatch(
              setRequestComplete({
                requestKey,
                resourceEvent: ResourceEvent.RESOURCE_LOAD_ERROR
              })
            )
          }
        }

        if (api.isOnline()) {
          const resourceTypes = Object.keys(
            getState().resourceMeta.resourceUpdateStatus
          ) as ResourceType[]
          await Promise.all(resourceTypes.map(async (resourceType) => {
            const updateStatus =
              getState().resourceMeta.resourceUpdateStatus[resourceType]

            // Check whether this resource type is due to be refreshed
            if (
              updateStatus?.autoRequestFilter &&
              updateStatus?.autoRefreshSeconds &&
              (!updateStatus?.lastUpdated ||
                differenceInSeconds(
                  updateTime,
                  parseJSON(updateStatus?.lastUpdated)
                ) >= updateStatus.autoRefreshSeconds)
            ) {
              // Resource is due to be refreshed
              log.debug(`updateResources: updating ${resourceType}`)

              await requestResources(resourceType, {
                ...updateStatus.autoRequestFilter
              })(dispatch, getState, services)
            }

            // If resource type is set to auto expire, look for resources whos last request time has passed the expiry date
            if (updateStatus?.expireAfterDays) {
              const expireAfterDate = sub(updateTime, {
                days: updateStatus.expireAfterDays
              })

              // Using fnFilter rather than periodFilter because lastRequestedDate isn't an attribute
              const expiredResources = filterResources(
                getState().resources,
                resourceType,
                {
                  fnFilter: (resource: LocalResourceData) =>
                    isBefore(
                      parseJSON(resource.lastRequestedDate),
                      expireAfterDate
                    )
                }
              )

              expiredResources.forEach((resource) => {
                dispatch(removeResource({ resourceType, id: resource.id }))
                log.debug(
                  `Expired resource removed from store:${resourceType}:${resource.id}`
                )
              })
            }
          }))
        } else {
          // Backend offline / no internet connection
          // -> skip all periodic updates, just do a periodic 'ping' so we know when we're back online
          if (
            !state.temp.apiLastPingTime ||
            isAfter(
              updateTime,
              addSeconds(
                parseJSON(state.temp.apiLastPingTime),
                OFFLINE_PING_SECONDS
              )
            )
          ) {
            dispatch(setApiLastPingTime(updateTime.toISOString()))
            const pingResult = await api.apiPing()
            if (pingResult === ApiResult.SUCCESS) {
              // ping succeeded...
              const userCheckStatus = await restoreExistingSession(
                dispatch,
                getState,
                api
              )
              if (userCheckStatus === UserCheckStatus.LOGGED_IN_PREV) {
                // Successfully restored previous session, i.e. got new access token
                // -> can set api back online
                api.setOnline(true)
              }
            }
          }
        }

        // Do periodic update of current user details from portal
        // TODO: this isn't really part of resource update, could move to separate file / reactor
        if (
          !state.app.user.isGuest && 
          differenceInSeconds(
            updateTime,
            parseJSON(state.app.lastUserUpdateTime)
          ) > USER_UPDATE_SECONDS
        ) {
          const getUserResponse = await api.getUpdateUser()
          if (getUserResponse.result === ApiResult.SUCCESS) {
            // Got good response from 'get-update-user' - login successful
            // Update user record in state with data received from get-user
            const userData = getUserResponse.responseData
            if (userData) {
              log.info(
                `updateResources - updated user ${userData.user?.firstName} ${userData.user?.lastName} from api`
              )
              dispatch(
                setUser({
                  user: userData.user,
                  permissions: userData.permissions
                })
              )
            }
          }
        }
      } catch (e) {
        log.error(`updateResources error:${e}`)
      }
      updateInProgress = false
    }

/**
 * Auto-request 'Reactor' - updates resource data in the background
 *
 * Will run every 10 seconds on change in temp.appTime
 *
 * @returns thunk to be dispached if an update is due
 */

var lastAppTime = 0

export const updateResourcesReactor = (state: RootState) => {
  if (!state.temp.startupInProgress && state.temp.appTime !== lastAppTime) {
    lastAppTime = state.temp.appTime
    return updateResources(new Date())
  }
}
