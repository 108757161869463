import { IonCard, IonCol } from '@ionic/react';
import { useAppDispatch } from '../redux/hooks'
import { setRedirectPath } from '../redux/slices/temp';
import './ActivityCard.css'

interface  ActivityCardProps {
    iconSrc:string,
    cardTitle:string,
    cardContent:string,
    cardColor?:string,
    href?:string}

const ActivityCard: React.FC<ActivityCardProps> = ({iconSrc, cardTitle, cardContent, cardColor, href}) => {
    const dispatch = useAppDispatch()

    return (
        <IonCol class="card-column" sizeXl="4" sizeLg="4" sizeMd="4" sizeSm="6" size="6">            
            <IonCard className="activity-card ion-text-center" button={true} onClick={() => dispatch(setRedirectPath(href!))} style={{"backgroundColor":cardColor}}>
           
                <div className="flex-card">
                    <div><img alt='' src={iconSrc}></img></div>
                    <h1>{cardTitle}</h1>
                    <div><p>{cardContent}</p></div>
                </div>
            </IonCard>
        </IonCol>
    );
}

export default ActivityCard;