import type { RootState } from '../../../redux/store'
import { ResourceMap } from "../../slices/resources"

/**
 * Memoized resources selector - creates a subset of state.resources, memoized so as not to return a new object on 
 * every call (unless actual resource data changes)
 * 
 * Will typically be used to generate a 'resources' input selector for createSelector
 */
export const selectResourcesMemo = (resources: string[]) => {

    let resourcesMemo: { [key: string]: ResourceMap } = {}

    return (state: RootState) => {
        // iterate through resources to see if any have changed
        for (const resource of resources) {
            if (state.resources[resource as keyof typeof state.resources] !== resourcesMemo[resource]) {
                // At least one resource map is different to memoized version
                // -> replace previous version with this one
                resourcesMemo = {
                    ...resourcesMemo,
                    [resource]: state.resources[resource as keyof typeof state.resources]
                }                
            }
        }
        return resourcesMemo
    }
}
