import { Fragment } from "react"
import _, { uniqBy } from "lodash"
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
    IonButton,
    IonContent,
    IonFooter,
    IonItemDivider,
    IonList,
} from "@ionic/react"
import { useAppSelector, useAppDispatch } from '../../redux/hooks'

import SelectListInput from "../../components/form/SelectListInput"
import TextArea from "../../components/form/TextArea"
import CameraInput, { PhotoData } from "../../components/form/CameraInput"
import PhotoDisplay from "../../components/form/PhotoDisplay"
import RadioGroup from "../../components/form/RadioGroup"

import { useHomeIconHandler } from "../../hooks/homeIconHandler"
import { homeIconAction } from "../../features/incidentReports/homeIconAction"
import { IncidentReportEvent, injuryTypeTexts } from "../../features/incidentReports/incidentReportsTypes"
import { updateSequence } from "../../features/incidentReports/incidentReportsSequence"
import { setIncidentReportWorkersInvolved } from "../../redux/slices/pgIncidentReports"
import IncidentWorker from "../../datastore/models/IncidentWorker"
import { useRequestUsersBySite } from "../../features/commonResourceRequests/useRequestUsersBySite"
import { InjuryType } from "../../datastore/models/Incident"
import { selectUsers } from "../../redux/selectors/users"

export type IncidentWorkerEdit = Omit<Partial<IncidentWorker>, 'injuryType' | 'photo'> & {
    injuryType: InjuryType | '', // Allow empty string so we can start with nothing selected
    photo?: PhotoData,
    dirty?: boolean
}

type Inputs = {
    workers: IncidentWorkerEdit[]
}

const schema = yup.object().shape({
    workers: yup.array().of(
        yup.object().shape({
            user: yup.number().when('deleted', {
                is: (deleted?: boolean) => !deleted,
                then: yup.number().required('Please select the worker involved')
            }),
            injuryDescription: yup.string().when('deleted', {
                is: (deleted?: boolean) => !deleted,
                then: yup.string().required('Please describe how this worker was involved or injured'),
            }),
            injuryType: yup.string().when('deleted', {
                is: (deleted?: boolean) => !deleted,
                then: yup.string().required('Please select an option'),
            }),
            deleted: yup.boolean()
        })
    )
}).required()

export const WorkersInvolved: React.FC = () => {
    const dispatch = useAppDispatch()
    const { incidentReportEdit } = useAppSelector(state => state.pgIncidentReports)
    
    const { siteUsers } = useRequestUsersBySite(incidentReportEdit.site)
    const currentUser = useAppSelector(state => state.app.user)
    const involvedUsers = useAppSelector(state => selectUsers(state, {userIds:incidentReportEdit.workersInvolved.map(worker => worker.user)}))
    // Users that might be displayed / selected from - include site users, currently logged in user, 
    // and any other users previously selected as involved workers
    const availableUsers = uniqBy(siteUsers.concat(currentUser?[currentUser]:[], involvedUsers), 'id')

    const { handleSubmit, watch, setValue, control, formState: { errors, dirtyFields } } = useForm<Inputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            workers: incidentReportEdit.workersInvolved
        }
    })

    const { fields, append, update } = useFieldArray({
        control,
        name: "workers",
        keyName: "ufaId", // defaults to "id" - changed so as not to overwrite resource id
    })

    const addWorker = () => {
        append({
            injuryType: incidentReportEdit.incidentType === 'NearMiss' ? 'None' : ''
        })
    }

    const deleteWorker = (index: number) => {
        const item = fields[index]
        if (item) {
            update(index, {
                ...item,
                deleted: true
            })
        }
    }   

    const formSubmitBack = (data: any) => {
        formSubmit(data)
        dispatch(updateSequence(IncidentReportEvent.BACK_SELECTED))
    }

    const formSubmitNext = (data: any) => {
        formSubmit(data)
        dispatch(updateSequence(IncidentReportEvent.NEXT_SELECTED))
    }

    const formSubmitHome = (data: any) => {
        formSubmit(data)
        dispatch(homeIconAction())
    }
    useHomeIconHandler('/incidents', handleSubmit(formSubmitHome))

    const formSubmit = (data: any) => {

        const updatedWorkers = data.workers.map((worker: IncidentWorkerEdit, i: number) => {
            const itemDirtyFields = dirtyFields?.workers ? dirtyFields.workers[i] : undefined
            const dirty = !!(
                itemDirtyFields && (
                    itemDirtyFields.user || 
                    itemDirtyFields.injuryDescription ||
                    itemDirtyFields.injuryType ||
                    itemDirtyFields.photo ||
                    itemDirtyFields.deleted)
                )
            return {
                ...worker,
                dirty: worker.dirty || dirty
            } as IncidentWorkerEdit
        })

        if (updatedWorkers.filter((worker: IncidentWorkerEdit) => worker.dirty).length) {
            dispatch(setIncidentReportWorkersInvolved(updatedWorkers))
        }
    }

    return (
        <>
            <IonContent class="content-padding">
                <form id="incident-details-form">
                    <h2>Workers involved in this incident:</h2>
                    <IonList id="incident-worker-list" lines="none">
                        {fields?.map((worker, i) => ({ ...worker, fieldIndex: i })).filter(worker => !worker.deleted).map(worker => (
                            <Fragment key={worker.ufaId}>
                                <SelectListInput
                                    className="modal-input"
                                    title="Select worker involved"
                                    placeholder="Select worker involved"
                                    listData={availableUsers.map(user => ({ text: `${user.firstName} ${user.lastName}`, id: user.id })) || []}
                                    fieldName={`workers[${worker.fieldIndex}].user`}
                                    watch={watch}
                                    setValue={setValue}
                                    error={_.get(errors, ['workers', worker.fieldIndex, 'user'])}
                                />

                                <CameraInput
                                    fieldName={`workers[${worker.fieldIndex}].photo`}
                                    placeholder="Add photo"
                                    watch={watch}
                                    setValue={setValue}
                                />
                                <PhotoDisplay
                                    fieldName={`workers[${worker.fieldIndex}].photo`}
                                    watch={watch}
                                    setValue={setValue}
                                />

                                <IonItemDivider />
                                <TextArea
                                    fieldName={`workers[${worker.fieldIndex}].injuryDescription`}
                                    inputMode="text"
                                    placeholder={incidentReportEdit.incidentType === 'NearMiss'
                                        ? 'Describe what could have happened to this worker'
                                        : 'Describe the extent of the injury'}
                                    rows={2}
                                    watch={watch}
                                    setValue={setValue}
                                    error={_.get(errors, ['workers', worker.fieldIndex, 'injuryDescription'])}
                                />


                                <IonItemDivider />
                                <h3>How was the injury managed?</h3>
                                <RadioGroup
                                    className="flex column"
                                    fieldName={`workers[${worker.fieldIndex}].injuryType`}
                                    options={[
                                        { value: 'None', label: injuryTypeTexts.None },
                                        { value: 'TreatOnSite', label: injuryTypeTexts.TreatOnSite },
                                        { value: 'MedCentre', label: injuryTypeTexts.MedCentre },
                                        { value: 'Hospital', label: injuryTypeTexts.Hospital },
                                    ]}
                                    watch={watch}
                                    setValue={setValue}
                                    error={_.get(errors, ['workers', worker.fieldIndex, 'injuryType'])}
                                />

                                <IonButton onClick={() => deleteWorker(worker.fieldIndex)}>
                                    Delete worker
                                </IonButton>

                                <IonItemDivider className="last-divider" />

                            </Fragment>
                        ))}
                    </IonList>
                    <div className="flex centre-row">
                        <IonButton onClick={addWorker}>
                            ADD Worker
                        </IonButton>
                    </div>
                </form>

            </IonContent>

            <IonFooter class="incidents-nav">
                <IonButton onClick={handleSubmit(formSubmitBack)}>Back</IonButton>
                <IonButton onClick={handleSubmit(formSubmitNext)}>Next</IonButton>
            </IonFooter>
        </>)
}
