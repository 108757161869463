import { isAfter, isBefore, parseJSON } from 'date-fns'
import _ from 'lodash'
import { ResourceFilters } from '../types'

/*
export interface ResourceFilters {
    eqFilter?: { [key: string]: any },
    neqFilter?: { [key: string]: any },
    inFilter?: { [key: string]: number[] },
    periodFilter?: { attr: string, periodStart?: Date | string, periodEnd?: Date | string },
}
*/

/**
 * Test whether one set of filters is a subset of another
 * test filter will be a subset if it contains at least all the same filters that superset does
 * 
 * @param superFilter 
 * @param testFilter - filters to test whether a subset of superfilter
 * @returns 
 */
export const filterIsSubset = (superFilter: ResourceFilters, testFilter: ResourceFilters): boolean => {

    return (
        (
            !superFilter.eqFilter ||
            singleFilterIsSubset(superFilter.eqFilter, testFilter.eqFilter)            
        ) &&
        (
            !testFilter.eqFilter ||
            singleFilterIsSubset(superFilter.eqFilter, testFilter.eqFilter) ||
            (!!superFilter.inFilter && singleFilterIsSubset(superFilter.inFilter, simpleToArray(testFilter.eqFilter)))
        ) &&

        singleFilterIsSubset(superFilter.neqFilter, testFilter.neqFilter) &&

        (
            !superFilter.inFilter ||
            singleFilterIsSubset(superFilter.inFilter, testFilter.inFilter) ||
            (!!superFilter.inFilter && singleFilterIsSubset(superFilter.inFilter, simpleToArray(testFilter.eqFilter)))
        ) && 
        (
            !testFilter.inFilter || 
            singleFilterIsSubset(superFilter.inFilter, testFilter.inFilter)
        ) &&
        
        periodFilterIsSubset(superFilter.periodFilter, testFilter.periodFilter)        
    )
}

/**
 *  Test whether a single filter object is a subset of another
 *  true if:
 *  - all attributes in super have matching attributes in test
 *  - super isn't defined
 * 
 * @param superFilter   - Filter we're testing as 'superset'
 * @param testFilter    - Filter to test whether a subset
 * @returns             - true if testFilter is a subset of superFilter
 */
const singleFilterIsSubset = (superFilter?: { [key: string]: any }, testFilter?: { [key: string]: any }) => {

    // If superfilter doesn't exist then anything is a subset
    if (!superFilter) return true

    // If superfilter exists but testfilter doesn't then not a subset
    if (!testFilter) return false

    const testFilterKeys = Object.keys(testFilter)

    return Object.keys(superFilter).reduce((acc: boolean, key) => {
        return acc && testFilterKeys.includes(key) && (
            Array.isArray(superFilter[key]) && Array.isArray(testFilter[key])
                ? arrayIsSubset(superFilter[key], testFilter[key])
                : _.isEqual(superFilter[key], testFilter[key])
        )
    }, true)
}

const arrayIsSubset = (superArray: (number | string)[], testArray: (number | string)[]) => (
    testArray.reduce((isSubset: boolean, item: number | string) => (
        isSubset && superArray.includes(item)
    ), true)
)

const simpleToArray = (filter?: { [key: string]: any }) => {
    if (!filter) return

    return Object.keys(filter).reduce((newFilter: { [key: string]: any[] }, key) => {
        newFilter[key] = [filter[key]]
        return newFilter
    }, {})
}

const periodFilterIsSubset = (superFilter?: ResourceFilters['periodFilter'], testFilter?: ResourceFilters['periodFilter']) => {

    if (!superFilter) {
        // If no superFilter then any period filter is a subset
        return true
    }

    if (!testFilter) {
        // If superFilter and no testFilter then not a subset
        return false
    }
    
    if (superFilter.attr !== testFilter.attr) {
        // If filters don't have the same attribute then not a match
        return false
    }

    const superStart = superFilter.periodStart? parseJSON(superFilter.periodStart) : null
    const superEnd = superFilter.periodEnd? parseJSON(superFilter.periodEnd) : null
    const testStart = testFilter.periodStart? parseJSON(testFilter.periodStart) : null
    const testEnd = testFilter.periodEnd? parseJSON(testFilter.periodEnd) : null

    return (
        (!superStart || (!!superStart && !!testStart && !isBefore(testStart, superStart))) &&
        (!superEnd || (!!superEnd && !!testEnd && !isAfter(testEnd, superEnd)))
    )
}
