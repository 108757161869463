import { getSiteToken, hasToken, setAccessToken } from './accessToken'

import { loginGuest } from './loginGuest'
import { logout } from './logout'

import { apiGetResource } from './apiGetResource'
import { apiPostResource } from './apiPostResource'
import { apiPatchResource } from './apiPatchResource'

import { getDocument } from './getDocument'
import { addDocument } from './addDocument'

import { addLogEntry } from './addLogEntry'
import { apiPing } from './apiPing'
import { isOnline, setOnline } from './onlineStatus'
import { getCurrentUser } from './getCurrentUser'
import { getUpdateUser } from './getUpdateUser'

export const AXIOS_GET_TIMEOUT = 30000 //5000
// Use a longer timeout for post / patch, just in case we need longer to send a large photo...
export const AXIOS_POST_TIMEOUT = 30000

// Number of failed requests before api is considered 'offline'
export const API_MAX_FAILURES = 3

const api = {
  apiPing,
  isOnline,
  setOnline,

  getSiteToken,
  setAccessToken,
  hasToken,

  getCurrentUser,
  getUpdateUser,
  loginGuest,
  logout,

  apiGetResource,
  apiPostResource,
  apiPatchResource,

  getDocument,
  addDocument,

  addLogEntry
}

export default api