import React, { useRef } from 'react';
import './QrScanner.css'
import { useQrScan } from './useQrScan';

// https://www.youtube.com/watch?v=oYChA-rPgpI

interface QrScannerProps {
    callback: (result: string, scanData: string) => boolean
}

const QrScanner: React.FC<QrScannerProps> = ({ callback }) => {
    const canvas = useRef<HTMLCanvasElement>(null)
    const { isLoading } = useQrScan(canvas, callback)

    return (
        <div className="scanner-container">
            {isLoading ? <div>Opening Camera...</div> : <></>}
            <canvas ref={canvas} style={{"visibility":isLoading?"hidden":"visible"}}></canvas>
        </div>
    )
}

export default QrScanner