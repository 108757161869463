
import { Middleware } from 'redux'
import { AppDispatch, RootState, setStoreSubscriptions } from '../redux/store'

import { initLocalStore, localStore } from "../storage/storage"
import { ResourcesState, setResourceType } from './slices/resources'
import log from 'loglevel'
import env from '../config'
import { setAppVersion } from './slices/app'

type ActionFn = () => void

var keysToSave: string[] = []
var persistedSlices: string[] = []
var heldActions: ActionFn[] = []

/**
 * Initialise redux store from persistent storage (called on startup)
 */
export const initialiseState = () => async (dispatch: AppDispatch, getState: () => RootState) => {

  try {

    log.debug('initialiseState: calling initLocalStore')

    // Get persisted state
    await initLocalStore()
    const state = getState()
    persistedSlices = Object.keys(state).filter(key => key !== 'resources' && key !== 'temp')

    log.debug('getting app slice from localStore')

    // retrieve just the 'app' slice and check the saved app version
    const appSlice = await localStore.get('app')

    const [savedMajorVersion, savedMinorVersion] = (appSlice?.appVersion || '').split('.')
    const [envMajorVersion, envMinorVersion] = (env.appVersion || '').split('.')

    const versionsKnown = !!(savedMajorVersion && savedMinorVersion && envMajorVersion && envMinorVersion)

    if (versionsKnown && (savedMajorVersion !== envMajorVersion || savedMinorVersion !== envMinorVersion)) {
      log.info(`App version change - ${appSlice?.appVersion} to ${env.appVersion}`)
    }

    if (savedMajorVersion === envMajorVersion) {

      // If major version matches, go ahead and restore the other slices
      dispatch({ type: 'app/setAll', payload: appSlice })

      log.debug('getting other slices from localStore')

      for (const slice of persistedSlices.filter(slice => slice !== 'app')) {

        const savedSlice = await localStore.get(slice)
        if (savedSlice) {
          dispatch({ type: `${slice}/setAll`, payload: savedSlice })
        }
      }

      const resourceTypes = Object.keys(state.resources)

      log.debug('getting resources slice from localStore')

      for (const resourceType of resourceTypes) {
        const resource = await localStore.get(`resources/${resourceType}`)
        dispatch(setResourceType({ resourceType: resourceType, resourceMap: resource }))
      }

    } else {

      // App has been updated to a new major version - delete all persisted state data in case format is no longer compatible      
      log.info('App version different to saved value - removing persisted state data')
      /*
      for (const slice of persistedSlices) {
        await localStore.remove(slice)
      }
      // App update shouldn't affect resources, but we currently have no other way to force a refresh of client datastore if required?
      const resourceTypes = Object.keys(state.resources)
      for (const resourceType of resourceTypes) {
        await localStore.remove(`resources/${resourceType}`)
      }
      */
      await localStore.clear()
    }

    dispatch(setAppVersion(env.appVersion))

    // Set subscriptions (reactors etc) now that initialisation is complete    
    setStoreSubscriptions()

  } catch (e) {
    log.error('exception thrown in initialiseState:', e)
  }
}

export const persistMiddleware: Middleware<{}, RootState> = store => next => async action => {
  const result = next(action)

  const type = action.type

  if (typeof (type) === 'string') {

    try {

      // One or more resources being added / updated from api or user actions
      if (type === 'resources/setResource') {
        const state = store.getState()
        const resourceTypes = Object.keys(action.payload)
        for (const resourceType of resourceTypes) {
          if (state.resources[resourceType as keyof typeof state.resources]) {
            keysToSave.push(`resources/${resourceType}`)
            requestIdleCallback(() => {
              saveKeys(store.getState)
            })
          }
        }
        return result
      }

      // A resource being deleted
      if (type === 'resources/removeResource') {
        const state = store.getState()
        const resourceType = action.payload.resourceType
        if (state.resources[resourceType as keyof typeof state.resources]) {
          keysToSave.push(`resources/${resourceType}`)
          requestIdleCallback(() => {
            saveKeys(store.getState)
          })
        }
        return result
      }

      // A whole resource type being deleted
      if (type === 'resources/removeResourceType') {
        const state = store.getState()
        const resourceType = action.payload
        if (state.resources[resourceType as keyof typeof state.resources]) {
          keysToSave.push(`resources/${resourceType}`)
          requestIdleCallback(() => {
            saveKeys(store.getState)
          })
        }
        return result
      }

      // Other slice being updated
      const [slice, subAction] = type.split('/')

      // Don't persist on 'setAll' actions as these are used to initialse state FROM persistent storage
      if (persistedSlices.includes(slice) && subAction !== 'setAll') {

        keysToSave.push(slice)

        requestIdleCallback(() => {
          saveKeys(store.getState)
        })
        return result
      }

      // a 'held' action is something we don't want to do while saving state may be in progress, e.g. a login/logout redirect     
      if (type === 'persistence/heldAction') {
        if (keysToSave.length === 0) {
          // If there's nothing waiting to be saved, do the action
          action.payload()
        } else {
          // Otherwise, save it to be executed later
          heldActions.push(action.payload)
        }
      }
    } catch (err) {
      log.error(`persistMiddleware failed to write to storage, action type ${type}:${err}`)
    }
  }
  return result
}


async function saveKeys(getState: () => RootState) {
  // Save state for all queued keys
  while (keysToSave.length) {
    const keyToSave = keysToSave[0]
    // Remove all occurrences of key from queue
    // (so that if slice has been updated multiple times we only save it once)
    keysToSave = keysToSave.filter(key => key !== keyToSave)

    const state = getState()
    const [slice, resource] = keyToSave.split('/')

    if (slice === 'resources') {
      await localStore.set(`resources/${resource}`, state.resources[resource as keyof ResourcesState])
    } else {
      await localStore.set(keyToSave, state[keyToSave as keyof RootState])
    }
  }

  // Try a short delay to make sure save is complete before logging out?
  await new Promise(r => setTimeout(r, 500));

  // Execute any 'held' actions (i.e. things we didn't want to do before state saving was completed)
  while (heldActions.length) {

    const action = heldActions.shift()
    if (action) {
      action()
    }
  }
}
