import { createSelector } from '@reduxjs/toolkit'
import { parseJSON } from 'date-fns'
import { getResource, getResources } from '../../datastore'
import { Toolbox } from '../../datastore/models'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'

const selectToolboxesResources = selectResourcesMemo(['sites', 'projectSites', 'toolboxes'])
const selectToolboxesParams = selectParamsMemo()
/**
 * Get toolboxes
 * 
 * Optional params:
 * - siteId (for toolbox meetings by site)
 * - periodStart
 * - periodEnd
 */
export const selectToolboxes = createSelector([selectToolboxesResources, selectToolboxesParams], (resources, params) => {

    if (!resources) {
        return []
    }
    const { siteId } = params

    const periodFilter = { attr: 'openDateTime', periodStart: params.periodStart, periodEnd: params.periodEnd }
    const toolboxes = getResources<Toolbox>(resources, 'toolboxes', { periodFilter, eqFilter:siteId ? { site: siteId } : {} })

    return toolboxes
        .map(toolbox => {
            const openDateTime = parseJSON(toolbox.openDateTime)
            return {
                ...toolbox,
                openDateTime,
            }
        })
        .sort((a, b) => a.openDateTime.getTime() - b.openDateTime.getTime())
})

const selectToolboxResources = selectResourcesMemo(['toolboxes'])
const selectToolboxParams = selectParamsMemo()
export const selectToolbox = createSelector([selectToolboxResources, selectToolboxParams], (resources, params) => {
    return getResource<Toolbox>(resources.toolboxes, params.toolboxId)
})