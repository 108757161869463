import React, { useState } from 'react'
import { FieldError, UseFormWatch, UseFormSetValue } from "react-hook-form"
import { IonButton, IonContent, IonDatetime, IonInput, IonLabel, IonModal } from '@ionic/react'
import { parseJSON, format as dateFormat, isValid, formatISO, parseISO } from 'date-fns'
import './DateInput.css'
import parseDate from '../../helpers/parseDate'
import dateToISOString from '../../helpers/dateToIsoString'


const formatDateDisplay = (dateTime?: Date | string | null) => {
    if (dateTime) {
        const dtDateTime = parseJSON(dateTime)
        if (isValid(dtDateTime)) {
            return dateFormat(dtDateTime, 'dd MMM yyyy')
        }
    }
    return ''
}

interface DateInputProps {
    id?: string
    label?: string,
    fieldName: string,
    watch: UseFormWatch<any>
    setValue: UseFormSetValue<any>
    error?: FieldError
    showClear?: boolean
    max?: string
    min?: string
}

const DateInput: React.FC<DateInputProps> = ({ id, label, fieldName, watch, setValue, error, showClear, max, min }) => {

    const formValue = watch(fieldName)
    const value = dateToISOString(parseDate(formValue))

    // Use 'Show Modal flag' approach
    // (would like to use useIonModal hook but won't work inside react hook form controller)
    const [showDateModal, setShowDateModal] = useState<boolean>(false)

    const [pickerDate, setPickerDate] = useState<string | null>(null)
    const [preEditDate, setPreEditDate] = useState<string | null>(null)

    const startEdit = () => {
        setPreEditDate(value)

        // Date picker doesn't have a 'no date selected' state. If supplied with a null value it will default to today's date
        // -> if 'value' not set, set picker explicitly to today's date so we have a copy of its' actual value
        const editDate = value || new Date().toISOString()
        // Date picker requres locally formatted ISO time (i.e. local time with offset, NOT utc)
        const formattedDate = formatISO(parseJSON(editDate))
        setPickerDate(formattedDate)
        setShowDateModal(true)
    }

    const dateSelected = (event: CustomEvent) => {
        setPickerDate(event.detail.value)
    }

    const okSelected = () => {
        const formattedDate = pickerDate ? formatISO(parseISO(pickerDate)) : ''
        setValue(fieldName, formattedDate, { shouldDirty: true })
        setShowDateModal(false)
    }

    const clearSelected = () => {
        setValue(fieldName, null, { shouldDirty: true })
        setShowDateModal(false)
    }

    const maxLocal = max ? formatISO(parseJSON(max)) : undefined
    const minLocal = min ? formatISO(parseJSON(min)) : undefined

    return (
        <div id={id}>
            <IonLabel class="oss-input-label">{label}</IonLabel>

            <IonInput
                className="oss-input"
                value={formatDateDisplay(value)}
                style={error ? { borderColor: "red" } : {}}
                onClick={startEdit}
            >
            </IonInput>

            <IonModal backdropDismiss={false} isOpen={showDateModal}>
                <IonContent className="date-select-container" force-overscroll="false">
                    <div className='safe-area-top'></div>
                    <IonDatetime
                        presentation="date"
                        value={pickerDate}
                        onIonChange={dateSelected}
                        max={maxLocal}
                        min={minLocal}
                    />
                    <div className="flex centre-row">
                        {showClear ?
                            <IonButton onClick={clearSelected}>{preEditDate ? 'Clear' : 'Cancel'}</IonButton>
                            : <></>
                        }
                        <IonButton onClick={okSelected}>Ok</IonButton>
                    </div>
                </IonContent>
            </IonModal>

            <div className="oss-input-error-message">{error?.message}</div>
        </div>
    )
}

export default DateInput