import { useEffect } from 'react'
import { useAppDispatch } from '../../../redux/hooks'
import { requestResourcesWithRelated } from '../../../datastore'

/**
 * 'useStartResources' hook
 * - Requests resources that 'start' page may need in store, i.e. 'projectSites' to check for create permissions
 * 
 * @returns  requested resources 
 */
export const useStartResources = (enable: boolean) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (enable) {
            dispatch(requestResourcesWithRelated('projects', { eqFilter: { status: 'OPEN' } }, ['projectSites']))
        }
    }, [enable])
}