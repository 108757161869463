import { AppDispatch, RootState } from "../../redux/store"
import reportRequests from "../../features/reportRequests"
import { ResourceEvent } from "../../datastore/types"
import log from "loglevel"

export const getReportData = async (
  dispatch: AppDispatch,
  getState: () => RootState,
  services: any,
  selectorNames: string[],
  params: Record<string, any>
) => {
  // Get collection of 'data requesters' from configured selector names
  // Each entry in reportRequests is a pair of a 'request' function (performs resource requests)
  // and a 'get' function (calls selectors to retrieve data from redux store and assembles into format for report)
  const reportDataRequesters = (selectorNames || []).reduce(
    (acc: any[], selectorName) => {
      const reportDataRequester =
        reportRequests[selectorName as keyof typeof reportRequests]
      if (reportDataRequester) {
        acc.push({
          ...reportDataRequester,
          selectorName
        })
      }
      return acc
    },
    []
  )

  // Call all 'request' functions and await results
  await Promise.all(
    reportDataRequesters.map(async (requester) => {
      const reportDataResult = await requester.request({ ...params })(
        dispatch,
        getState,
        services
      )
      if (reportDataResult !== ResourceEvent.RESOURCE_VALID) {
        // Log error but allow user to continue - may still be able to view from cached data
        log.debug(
          `getReportData: error requesting document resources (data selector ${requester.selectorName})`
        )
      }
    })
  )

  const reportData: Record<string, any> = { images: [] }
  // Call all 'get' functions and combine results into reportData object
  for (const requester of reportDataRequesters) {
    const selectorData = requester.get(params)(dispatch, getState)
    if (!selectorData) {
      // 'get' function will return null if required data not in store
      return { isSuccess: false }
    }

    for (const key of Object.keys(selectorData)) {
      const itemData = selectorData[key]
      reportData[key] = itemData

      // image id arrays will be contained within individual selectorData objects (incident, siteObservation, etc)
      // -> combine under separate 'images' key so that report rendering knows where to find them
      if (itemData?.images) {
        reportData.images = reportData.images.concat(itemData.images)
      }
    }
  }

  return reportData
}
