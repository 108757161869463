import { AppDispatch, RootState } from '../../redux/store'
import { selectIncident } from '../../redux/selectors/incidents'
import { selectIncidentWorkers } from '../../redux/selectors/incidentWorkers'
import { selectIncidentActions } from '../../redux/selectors/incidentActions'
import { requestIncident } from './resourceRequests/requestIncident'
import { updateSequence } from './incidentReportsSequence'
import { IncidentReportEvent } from './incidentReportsTypes'
import log from 'loglevel'
import { setAlert } from '../../utils/alert'
import { initialiseIncidentReportEdit, setIncidentReportReviewId } from '../../redux/slices/pgIncidentReports'
import { selectCanEditIncidentAsReporter, selectCanEditIncidentAsSupervisor } from '../../redux/selectors/abilities'
import { hideLoadingSpinner, showLoadingSpinner } from '../../redux/slices/temp'

export const loadIncident = (incidentId?: number) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    if (incidentId) {

        dispatch(showLoadingSpinner('Loading Incident Report...'))

        await requestIncident({ incidentId })(dispatch, getState, services)

        const incident = selectIncident(getState(), { incidentId })

        if (incident) {

            if ((selectCanEditIncidentAsReporter(getState(), incident) ||
                selectCanEditIncidentAsSupervisor(getState(), incident)) && !incident.closedDate) {
                // Incident is still open for editing - load it and go to 'details' page
                const workers = selectIncidentWorkers(getState(), { incidentId })
                const actions = selectIncidentActions(getState(), { incidentId })

                dispatch(initialiseIncidentReportEdit({
                    ...incident,
                    photo: incident.photo ? { docId: incident.photo } : undefined,
                    workersInvolved: workers.map(worker => ({
                        ...worker,
                        photo: worker.photo ? { docId: worker.photo } : undefined
                    })),
                    actions,
                }))
                dispatch(updateSequence(IncidentReportEvent.DETAILS_SELECTED))

            } else {
                // Incident is closed out / not editable by this user - display it in 'review' format
                dispatch(setIncidentReportReviewId(incidentId))
                dispatch(updateSequence(IncidentReportEvent.REVIEW_SELECTED))
            }

        } else {
            dispatch(setAlert(
                'Failed to load Incident Report',
                'Internet connection may be lost'
            ))
        }
        dispatch(hideLoadingSpinner())

    } else {
        log.error('loadIncident called with no incidentId')
    }    
}