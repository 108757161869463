const sites = {
    table: 'sites',
    idKey: 'id',

    attrs: [
        'siteName',
        'address',
        'addressNumber',
        'street',
        'suburb',
        'city',
        'region',
        'postcode',
        'country',
        'timeZone',
        'location',
        'signinCode',
        'emergencyRespData',
        'highRiskWork',
        'notifiableWork',
        'visitorSignin',
        'workerSignin',
        'driverSignin',
        'inductionDays',
        'toolboxDays',
        'status',
        'setupCompletion',
        'closedDate',
        'creationDate',
        'lastModifiedDate',
        'deleted'
    ],

    relationships: [
        {
            type: 'companies',
            key: 'company',            
        }
    ],
}

export default sites