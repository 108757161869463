import { subDays } from "date-fns"
import { uniq } from "lodash"
import { useEffect, useState } from "react"
import { AppDispatch, RootState } from "../../../redux/store"
import {
  getResources,
  requestResourcesWithRelated,  
} from "../../../datastore"
import { Project, ProjectSite } from "../../../datastore/models"
import { resourceEventCombined } from "../../../datastore/resourceEventCombined"
import { ResourceEvent } from "../../../datastore/types"
import { useTask } from "../../../hooks/useTask"
import { useAppSelector } from "../../../redux/hooks"
import { selectSiteObservationsWithActions } from "../../../redux/selectors/siteObservations"
import { selectProjectSites } from "../../../redux/selectors/projectSites"
import { ResourceChecklist } from "../resourceChecklist"

export const useSiteObservationsResources = (
  siteId: number | null | undefined,
  companyId: number | null | undefined,
  observationsList: ResourceChecklist,
  refresh?: any
) => {

  const [checkedObservations, setCheckedObservations] = useState<number[]>([])
  useEffect(() => {
    // checkedObservations = observations already ticked in toolbox
    // Need to include these separately in selector because they may be 'closed out' during
    // meeting and will then no longer appear under 'open' list
    setCheckedObservations(observationsList.checked.map((item) => item.id))
  }, [observationsList])

  const taskResult = useTask(
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      if (companyId) {
        // Requesting records for all company sites:

        // Request projectSites / sites for all open projects
        const projectsResult = await requestResourcesWithRelated(
          "projects",
          { eqFilter: { status: "OPEN" } },
          ["projectSites.sites"]
        )(dispatch, getState, services)

        const projects = getResources<Project>(
          getState().resources,
          "projects",
          { eqFilter: { company: companyId, status: "OPEN" } }
        )
        const projectSites = getResources<ProjectSite>(
          getState().resources,
          "projectSites",
          { inFilter: { project: projects.map((project) => project.id) } }
        )
        const siteIds = uniq(
          projectSites.map((projectSite) => projectSite.site)
        )

        const result = await requestResourcesWithRelated(
          "siteObservations",
          {
            inFilter: { site: siteIds },
            periodFilter: {
              attr: "observationDate",
              periodStart: subDays(new Date(), 7)
            }
          },
          ["siteObservationActions"]
        )(dispatch, getState, services)

        return {
          isSuccess:
            resourceEventCombined(projectsResult, [result]) !==
            ResourceEvent.RESOURCE_LOAD_ERROR
        }

      } else {
        // Requesting records for specified site:
        const result = await requestResourcesWithRelated(
          "siteObservations",
          {
            eqFilter: siteId ? { site: siteId } : {},
            periodFilter: {
              attr: "observationDate",
              periodStart: subDays(new Date(), 7)
            }
          },
          ["siteObservationActions"]
        )(dispatch, getState, services)

        return {
          isSuccess: result !== ResourceEvent.RESOURCE_LOAD_ERROR
        }
      }
    },
    [siteId, companyId, refresh],
    !!siteId || !!companyId
  )

  const siteIds = useAppSelector((state) =>
    selectProjectSites(state, { companyId })
  ).map((projectSite) => projectSite.siteId)

  return {
    siteObservations: useAppSelector((state) =>
      selectSiteObservationsWithActions(
        state,
        siteId
          ? {
              siteId,
              open: true,
              list: checkedObservations
            }
          : {
              siteIds,
              open: true,
              list: checkedObservations
            }
      )
    ),
    isLoading: taskResult.isLoading,
    isSuccess: taskResult.isSuccess,
    isError: taskResult.isError
  }
}
