import React from 'react'
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useAppSelector, useAppDispatch } from '../../redux/hooks'

import {
    IonButton,
    IonContent,
    IonItemDivider,
} from '@ionic/react'
import { login, loginGuest } from '../../features/auth'
import Input from '../../components/form/Input'
import { phoneRegex } from '../../helpers/regex'

type Inputs = {
    phone: string,
    email: string,
    firstName: string,
    lastName: string,
    company: string
}

const schema = yup.object().shape({
    phone: yup.string().when('email', {
        is: '',
        then: yup.string().matches(phoneRegex, {
            message: 'Please enter valid phone number',
            excludeEmptyString: true
        }).required('Either Phone number or Email is required'),

        otherwise: yup.string().matches(phoneRegex, {
            message: 'Please enter valid phone number',
            excludeEmptyString: true
        })
    }),
    email: yup.string().when('phone', {
        is: '',
        then: yup.string().email().required('Either Phone number or Email is required'),
        otherwise: yup.string().email('Please enter a valid email address')
    }),
    firstName: yup.string().required('First Name is Required'),
    lastName: yup.string(),
    company: yup.string(),

    // Not sure exactly what [['phone', 'email']] does but required to prevent cyclic dependency error
}, [['phone', 'email']]).required()

const GetUser: React.FC = () => {

    const lastGuestUser = useAppSelector((state) => state.app.lastGuest)

    const { handleSubmit, watch, setValue, formState: { errors } } = useForm<Inputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            phone: lastGuestUser?.phone || '',
            email: lastGuestUser?.email ||'',
            firstName: lastGuestUser?.firstName || '',
            lastName: lastGuestUser?.lastName || '',
            company: lastGuestUser?.guestCompany || ''
        }
    });

    const dispatch = useAppDispatch()
    const signinCode = useAppSelector((state) => state.pgSignin.signinCode)

    function doSignin(data: any) {
        const user = {
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            email: data.email,
            isGuest: true,
            guestCompany: data.company,
        }
        dispatch(loginGuest(signinCode, user))
    }

    return (
        <IonContent id="user-details-page" class="content-padding">
            <h1>Sign In - Details:</h1>
            <h3>Please log in using your Builda Price Account:</h3>
            <div className="flex centre-row">
                <IonButton onClick={() => dispatch(login())}>Log In</IonButton>
            </div>

            <div className="flex centre-row">
                <IonItemDivider />
                <p className="divider-p">or</p>
                <IonItemDivider />
            </div>

            <form onSubmit={handleSubmit(doSignin)}>

                <h3>Log in as a Guest</h3>
                <p>(if you don't have a Builda Price Login):</p>

                <Input
                    label="Phone:"
                    fieldName="phone"
                    inputMode="tel"
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                />

                <Input
                    label="Email:"
                    fieldName="email"
                    inputMode="email"
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                />

                <Input
                    label="First Name:"
                    fieldName="firstName"
                    inputMode="text"
                    watch={watch}
                    setValue={setValue}
                />

                <Input
                    label="Last Name:"
                    fieldName="lastName"
                    inputMode="text"
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                />

                <Input
                    label="Company:"
                    fieldName="company"
                    inputMode="text"
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                />
                <div className="flex centre-row">
                    <IonButton type="submit" >Log In as Guest</IonButton>
                </div>
            </form>

        </IonContent>
    )
};

export default GetUser;