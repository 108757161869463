/**
 * Add image path to an image filename
 * Used where a resource stores the name only (e.g. an icon), and the actual location may vary
 * depending on context
 *
 */
const formatPhoneLink = (number:string) => {
    
    if (!number) {
        return ''
    }
    let dialNumber = number
    dialNumber = lettersToDigit(dialNumber, ['A','B','C'],      '2')
    dialNumber = lettersToDigit(dialNumber, ['D','E','F'],      '3')
    dialNumber = lettersToDigit(dialNumber, ['G','H','I'],      '4')
    dialNumber = lettersToDigit(dialNumber, ['J','K','L'],      '5')
    dialNumber = lettersToDigit(dialNumber, ['M','N','O'],      '6')
    dialNumber = lettersToDigit(dialNumber, ['P','Q','R','S'],  '7')
    dialNumber = lettersToDigit(dialNumber, ['T','U','V'],      '8')
    dialNumber = lettersToDigit(dialNumber, ['W','X','Y','Z'],  '9')

    return `<a href="tel:${dialNumber}">${number}</a>`    
}

export default formatPhoneLink

const lettersToDigit = (number:string, find:string[], replaceWith: string) => {
    let result = number
    find.forEach(char => result = result.replaceAll(char, replaceWith))
    return result
}