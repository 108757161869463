import { useAppSelector } from '../../redux/hooks';
import { selectTaEdit } from '../../redux/selectors/tas';
import './TaskHeading.css'

interface TaskHeadingProps {
    stepNumber?: boolean
}

const TaskHeading: React.FC<TaskHeadingProps> = ({ stepNumber }) => {
    const taEdit = useAppSelector(selectTaEdit);
    const { selectedStepIndex } = useAppSelector((state) => state.pgTaskAnalysis)

    return (
        <div className="ta-task-heading">
            <span className="task-title">{taEdit.userData.name}</span>
            { stepNumber ?
                <span className="task-step">&nbsp;&gt; Step {selectedStepIndex + 1}</span>
                :
                <></>
            }
        </div>
    )
}

export default TaskHeading;