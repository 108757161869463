import { LocalResourceData } from '../../redux/slices/resources'
import { Relationship } from '../types'

export function flattenResource(resource: LocalResourceData/*<any>*/) {
    if (!resource) {
        return null        
    }

    const attributes = resource.attributes

    let relationships = {}
    if (resource.relationships) {
    relationships = Object.keys(resource.relationships).reduce((acc:{[key:string]:number}, key) => {
        const relationship = resource.relationships[key]?.data as Relationship
        // relationship may be 'null' if no related resource
        if (relationship) {
            acc[key] = +relationship.id
        }
        return acc
    },{})
}

    const flattened = {
        id:+resource.id,        
        ...attributes,
        ...relationships        
    } as any

    if (resource.localId) {
        flattened.localId = +resource.localId
    }

    return flattened
}