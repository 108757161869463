//import { getLogger } from 'loglevel'

import { AppDispatch, RootState } from "../../redux/store"

import { ApiService, ApiResult } from "../../interfaces/api"
import { setGuestAccessToken, setLastGuest } from "../../redux/slices/app"
import { setUser } from "./setUser"

import { UnsavedUser } from "../../datastore/models/User"
import { setAlert } from "../../utils/alert"

//const log = getLogger('auth')

export const loginGuest =
  (signinCode: string, guestUser: UnsavedUser) =>
  async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const api: ApiService = services.api

    const loginResult = await api.loginGuest(signinCode, guestUser)

    if (loginResult && loginResult.userId) {
      const newUser = {
        id: loginResult.userId,
        ...guestUser
      }
      dispatch(
        setUser({
          user: newUser,
          permissions: {
            companyAttributes: [],
            projectAttributes: [],
            siteAttributes: []
          },
          sub:''
        })
      )
      dispatch(setLastGuest(newUser))
      dispatch(setGuestAccessToken(loginResult.accessToken))
    } else {
      if (loginResult.result === ApiResult.NETWORK_ERROR) {
        dispatch(setAlert("Guest Login Failed", "Error connecting to server"))
      } else {
        dispatch(setAlert("Guest Login Failed", "Unknown Error"))
      }
    }
  }
