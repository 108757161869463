import { NormalizedData } from "@disruptph/json-api-normalizer"
import _ from 'lodash'
import log from "loglevel"
import models from '../models'

export const formatResource = (resource: any, type: string, id: number): NormalizedData => {
    const model = models[_.kebabCase(type) as keyof typeof models]    

    if (!model) {
        log.error('formatResource:invalid resource type:', type)
        return {}
    }

    const key: string = id.toString() 
    const normalisedResource: NormalizedData = {}
    normalisedResource[type] = {
        [key]: {
            id: key,
            type,
            attributes: model.attrs.reduce((acc: { [key: string]: any }, attr: string): { [key: string]: any } => {
                if (resource.hasOwnProperty(attr)) {
                    if (typeof resource[attr] === 'object') {
                        if (resource[attr] instanceof Date) {
                            // Convert Date attributes to ISO strings
                            acc[attr] = resource[attr].toISOString()
                        } else if (resource[attr] === null) {
                            // null is just null...
                            acc[attr] = resource[attr]
                        } else {
                            // Store any other kind of object as JSON
                            //acc[attr] = JSON.stringify(resource[attr])    
                            // Actually doesn't seem to need stringifying?
                            // If we do stringify then we need to un-stringify before
                            // saving local store copy...
                            acc[attr] = resource[attr]
                        }
                    } else {
                        acc[attr] = resource[attr]
                    }
                }
                return acc
            }, {}),
            relationships: model.relationships?.length
                ?(model.relationships as { type: string, key: string }[]).reduce((acc: { [key: string]: any }, relationship: any): { [key: string]: any } => {
                    // Need to be able to set relationship to null to update database field, i.e. when deleting a linked resource
                    if (resource[relationship.key] === null) {
                        acc[relationship.key] = { data: null }
                    }
                    if (resource[relationship.key]) {
                        acc[relationship.key] = {
                            data: {
                                id: resource[relationship.key]?.toString() || null,
                                type: relationship.type
                            }
                        }
                    }
                    return acc
                }, {})
                : {}
        }
    }

    return normalisedResource
}