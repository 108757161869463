/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import Axios, { AxiosError } from 'axios'
import { ApiResult } from '../interfaces/api'
import { getApiRoot } from '../helpers/getApiRoot'

var accessToken: string

export const setAccessToken = (newToken: string) => { accessToken = newToken }
export const getAccessToken = () => accessToken

export const hasToken = ():boolean => !!accessToken

/**
 * Get a 'site token' from backend
 * token allows read access to public site data for first part of signin process if we don't yet have a user token
 * @param signinCode - signinCode of site to get token for
 * @returns ApiResult
 */
export const getSiteToken = async (signinCode: string): Promise<ApiResult> => {

  try {
    const response = await Axios.get(`${getApiRoot()}auth/get-site-token?signinCode=${signinCode}`)
    
    if (response.data.accessToken) {
      accessToken = response.data.accessToken
      return ApiResult.SUCCESS
    }
  } catch (err: any | AxiosError) {
    if (Axios.isAxiosError(err) && err.response) {
      // API responded with error code - probably means signinCode wasn't valid          
      return ApiResult.SERVER_ERROR
    } else {
      // Request failed or no response from API
      // Probably means backend is down or no internet connection
      return ApiResult.NETWORK_ERROR
    }
  }
  return ApiResult.SERVER_ERROR
}

export const getToken = async (origin: string): Promise<ApiResult> => {
  try {    
    const response = await Axios.get(`${getApiRoot()}auth/get-token?origin=${origin}`)
    if (response.data.accessToken) {
      accessToken = response.data.accessToken
      return ApiResult.SUCCESS
    }
  } catch (err: any | AxiosError) {
    if (Axios.isAxiosError(err) && err.response) {
      // API responded with error code => no refresh token available or some other problem with auth server          
      return ApiResult.AUTH_ERROR

    } else {
      // Request failed or no response from API
      // Probably means backend is down or no internet connection
      return ApiResult.NETWORK_ERROR
    }
  }
  return ApiResult.SERVER_ERROR
}