import { FieldError, UseFormWatch, UseFormSetValue } from "react-hook-form"
import { IonLabel } from '@ionic/react'
import ImgDocContainer from '../ImgDocContainer'
import { useDocument } from '../../docstore/useDocument'
import { useEffect, useState } from "react"
import './PhotoDisplay.css'

interface InputProps {
    id?: string
    label?: string
    fieldName: string
    watch: UseFormWatch<any>
    setValue: UseFormSetValue<any>
    error?: FieldError
}

const PhotoDisplay: React.FC<InputProps> = ({ id, label, fieldName, watch, setValue, error }) => {

    const photo = watch(fieldName)
    const [formBlob, setFormBlob] = useState<Blob | null>(null)
    const docId = (photo && photo.docId && !photo.dataUrl && !photo.deleted) ? photo.docId : null
    // docData = photo loaded from persistent storage / back end IF docId is set and dataUrl isn't
    // - i.e. photo has been previously saved and user is viewing the saved copy
    //const savedDoc = useDocument(docId).docData
    const { docData, isLoading } = useDocument(docId)

    useEffect(() => {
        // formBlob = form copy of photo, converted to blob from dataUrl
        // - i.e. set when user has just taken photo
        if (photo && photo.dataUrl && !photo.deleted) {
            ; (async () => {
                const blobData = await (await fetch(photo.dataUrl as RequestInfo)).blob()
                setFormBlob(blobData)
            })()
        } else {
            setFormBlob(null)
        }
    }, [photo])

    // Display photo that's just been taken if available, otherwise use saved version
    const blobData = formBlob || docData?.docBlob

    return (
        <div id={id}>
            {label ? <IonLabel class="oss-input-label">{label}</IonLabel> : <></>}

            {photo && !photo.deleted ?
                <div className="img-viewer-wrapper">
                    <div onClick={() => {
                        setValue(fieldName, { docId: photo?.docId, deleted: true }, { shouldDirty: true })
                    }}>
                        <span>&#215;</span>
                    </div>
                    {blobData ?
                        <ImgDocContainer
                            docBlob={blobData}
                        />
                        : <div>{isLoading?'Loading...':'Unable to display photo'}</div>
                    }
                </div>
                : <></>
            }
            <div className="oss-input-error-message">{error?.message}</div>
        </div>
    )
}

export default PhotoDisplay