import { createSelector } from '@reduxjs/toolkit'
import { getResource, getResources, listIncludesResource } from '../../datastore'
import { Incident, IncidentAction } from '../../datastore/models'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'

const selectResources = selectResourcesMemo(['sites', 'incidents'])
const selectParams = selectParamsMemo()
/**
 * Empty params object = return all incidents
 * params.open = return all open incidents
 * params.list = return incidents with specified ids
 */
export const selectIncidents = createSelector([selectResources, selectParams], (resources, params) => {
    let incidents = getResources<Incident>(resources, 'incidents', { eqFilter: params.siteId ? { site: params.siteId } : {} })

    if (params.open || params.checklist) {
        return incidents.reduce((acc: Incident[], incident) => {
            if ((params.open && !incident.closedDate) ||
                (params.list && listIncludesResource(params.list, incident))) {
                return acc.concat([incident])
            }
            return acc
        }, [])
    }
    return incidents
})

const selectIncidentsWithActionsResources = selectResourcesMemo(['incidents', 'incidentActions'])
const selectIncidentsWithActionsParams = selectParamsMemo()
export const selectIncidentsWithActions = createSelector([
    selectIncidents,
    selectIncidentsWithActionsResources,
    selectIncidentsWithActionsParams], (incidents, resources, params) => {

        return incidents.map(incident => ({
            incident,
            actions: getResources<IncidentAction>(resources, 'incidentActions', { eqFilter: { incident: incident.id } })
        }))
    })

const selectIncidentParams = selectParamsMemo()
/**
 * Get single incident by id
 * 
 */
export const selectIncident = createSelector([selectResources, selectIncidentParams], (resources, params) => {
    return getResource<Incident>(resources.incidents, params.incidentId)
})
