import { AppDispatch, RootState } from '../../redux/store'
import { selectSignedinSiteId } from "../../redux/selectors/signins"
import { updateSequence } from './incidentReportsSequence'
import { initialiseIncidentReportEdit } from '../../redux/slices/pgIncidentReports'
import { IncidentReportEvent } from './incidentReportsTypes'
import { requestResourcesWithRelated } from '../../datastore'
import { selectCanCreateIncident } from '../../redux/selectors/abilities'
import { selectOpenProjectSites } from '../../redux/selectors/projectSites'
import log from 'loglevel'
import { IncidentType } from '../../datastore/models/Incident'
import { hideLoadingSpinner, showLoadingSpinner } from '../../redux/slices/temp'
import { uniq } from 'lodash'

export const createIncident = (incidentType: IncidentType, siteId?: number) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    let state = getState()
    const userId = state.app.user?.id
    const signedInSiteId = selectSignedinSiteId(state)

    dispatch(showLoadingSpinner('Loading...'))

    // Get projectSite records, and filter to those for projects this user has create permission for
    await requestResourcesWithRelated('projects', { eqFilter: { status: 'OPEN' } }, ['projectSites'])(dispatch, getState, services)
    state = getState()
    const projectSites = selectOpenProjectSites(state)
    const availableSiteIds = uniq(
        projectSites
          .filter((projectSite) => selectCanCreateIncident(state, projectSite))
          .map((projectSite) => projectSite.site)
      )
  
      let defaultSiteId = 0
  
      // Set default site for incident:
      if (availableSiteIds.length) {
        if (siteId && availableSiteIds.includes(siteId)) {
          // If we were given a specific site id, use it (i.e. creation from toolbox)
          defaultSiteId = siteId
        } else if (signedInSiteId && availableSiteIds.includes(signedInSiteId)) {
          // If we're signed in to a site, use that
          defaultSiteId = signedInSiteId
        } else if (availableSiteIds.length === 1) {
          // If only one site is avaiable, choose it by default
          defaultSiteId = availableSiteIds[0]
        }
        
    /*
    const filteredProjectSites = projectSites.filter(projectSite => selectCanCreateIncident(state, projectSite))

    if (filteredProjectSites.length) {
        // default to the first record we find
        let defaultProjectSite = filteredProjectSites[0]

        if (filteredProjectSites.length > 1) {            
            // If multiple projectSites available, look for one that matches the site we're currently signed in to
            const signedinSiteProjects = filteredProjectSites.filter(projectSite => projectSite.site === signedInSiteId)
            if (signedinSiteProjects.length) {
                defaultProjectSite = signedinSiteProjects[0]
            }
        }
*/
        dispatch(initialiseIncidentReportEdit({
            company: 0,
            site: defaultSiteId,
            incidentDate: new Date().toISOString(),
            incidentType,
            reporter: userId
        }))
                
    } else {
        log.error('createIncident:No projectSite with create permission found');
    }

    dispatch(hideLoadingSpinner())    
    dispatch(updateSequence(IncidentReportEvent.DETAILS_SELECTED))
}