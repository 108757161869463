import { AppDispatch, RootState } from "../../../redux/store"
import { useAppSelector } from "../../../redux/hooks"
import {
  getResources,
  requestResources,
  requestResourcesWithRelated
} from "../../../datastore"
//import { selectTasks } from '../../../redux/selectors/tasks'
import { selectActiveTas } from "../../../redux/selectors/tas"
import { selectProjectSites } from "../../../redux/selectors/projectSites"
import { addDays } from "date-fns"
import { Project, ProjectSite, Task } from "../../../datastore/models"
import { useTask } from "../../../hooks/useTask"
import { uniq } from "lodash"
import { resourceEventCombined } from "../../../datastore/resourceEventCombined"
import { ResourceEvent } from "../../../datastore/types"

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results
 *
 * @returns
 * - requested resources
 * - 'requestsDone' flag (true when request completes, either success or failure)
 * - refresh function - call to initiate a refresh (e.g. from refresh pulldown)
 */
export const useTasksResources = (
  projectId: number | null,
  siteId: number | null,
  companyId: number | null,
  refresh?: any
) => {
  const periodStart = addDays(new Date(), -7)

  const taskResult = useTask(
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      if (companyId) {
        // Requesting records for all company sites:

        // Request projectSites / sites for all open projects
        const projectsResult = await requestResourcesWithRelated(
          "projects",
          { eqFilter: { status: "OPEN" } },
          ["projectSites.sites"]
        )(dispatch, getState, services)

        const projects = getResources<Project>(
          getState().resources,
          "projects",
          { eqFilter: { company: companyId, status: "OPEN" } }
        )
        const projectSites = getResources<ProjectSite>(
          getState().resources,
          "projectSites",
          { inFilter: { project: projects.map((project) => project.id) } }
        )
        const siteIds = uniq(
          projectSites.map((projectSite) => projectSite.site)
        )

        const result = await requestResources("tas", {
          inFilter: { site: siteIds },
          periodFilter: { attr: "startDate", periodStart }
        })(dispatch, getState, services)

        return {
          isSuccess:
            resourceEventCombined(projectsResult, [result]) !==
            ResourceEvent.RESOURCE_LOAD_ERROR
        }
      } else {
        const result = await requestResources("tas", {
          eqFilter: { project: projectId, site: siteId },
          periodFilter: { attr: "startDate", periodStart }
        })(dispatch, getState, services)
        return {
          isSuccess: result !== ResourceEvent.RESOURCE_LOAD_ERROR
        }
      }

      /*
Don't get tasks for now - too many, need better filtering options first
            if ((projectId !== lastProjectId) || refresh) {
                dispatch(requestResources('tasks', {
                    requestKey: 'tasksOnDemand',
                    eqFilter: { project: projectId },
                    periodFilter: { attr: 'startDate', periodStart }
                }))
            }
*/
    },
    [siteId, projectId, companyId, refresh],
    (!!siteId && !!projectId) || !!companyId
  )

  const siteIds = useAppSelector((state) =>
    selectProjectSites(state, { companyId })
  ).map((projectSite) => projectSite.siteId)

  return {
    allScheduledTasks: [] as Task[], //useAppSelector(state => selectTasks(state, { projectId, periodStart })),
    allTas: useAppSelector((state) =>
      selectActiveTas(
        state,
        siteId ? { projectId, siteId, periodStart } : { siteIds, periodStart }
      )
    ),    
    isLoading: taskResult.isLoading,
    isSuccess: taskResult.isSuccess,
    isError: taskResult.isError
  }
}
