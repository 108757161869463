import { AppDispatch, RootState } from "../../redux/store"
import { selectSiteUsers } from "../../redux/selectors/users"
import { getResources, requestResources } from "../../datastore"
import { ProjectSite } from "../../datastore/models"
import { ResourceEvent } from "../../datastore/types"
import { resourceEventCombined } from "../../datastore/resourceEventCombined"
import { useTask } from "../../hooks/useTask"
import { useAppSelector } from "../../redux/hooks"

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results
 *
 * @returns  requested resources
 */
export const useRequestUsersByProject = (projectId?: number | null) => {
  const taskResult = useTask(
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      // Request projectSite record(s) for selected project
      const result1 = await requestResources("projectSites", {
        eqFilter: { project: projectId }
      })(dispatch, getState, services)

      // Get projectSite records
      const projectSites = getResources<ProjectSite>(
        getState().resources,
        "projectSites",
        { eqFilter: { project: projectId } }
      )

      // Request siteUsers by related sites
      const siteIds = projectSites.map((projectSite) => projectSite.site)
      const result2 = projectSites.length
        ? await requestResources("siteUsers", {
            inFilter: { site: siteIds },
            include: ["user"]
          })(dispatch, getState, services)
        : ResourceEvent.NO_RESOURCE_FOUND

      //      const state = getState()

      return {
        isSuccess:
          resourceEventCombined(ResourceEvent.RESOURCE_VALID, [
            result1,
            result2
          ]) !== ResourceEvent.RESOURCE_LOAD_ERROR
        //      data: {
        //        users: selectSiteUsers(state, { siteIds })
        //      }
      }
    },
    [projectId],
    !!projectId
  )

  return {
    //users: (taskResult.data?.users || []) as User[],
    // Note: select users here (rather than in task) so that we get whatever we have in the store straight away
    siteUsers: useAppSelector((state) => selectSiteUsers(state, { projectId })),
    isLoading: taskResult.isLoading,
    isSuccess: taskResult.isSuccess,
    isError: taskResult.isError
  }
}
