import { saveResource } from '../../datastore'
import { savePhoto } from '../../docstore/savePhoto'
import { AppDispatch, RootState } from '../../redux/store'
import { selectProject } from '../../redux/selectors/projects'
import { setToolboxEdit } from '../../redux/slices/pgToolbox'

import log from 'loglevel'
import { saveTbAttendees } from './saveTbAttendees'

export const saveToolbox = async (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState()
    const toolboxEdit = state.pgToolbox.toolboxEdit    
    let companyId = toolboxEdit.company
    let projectId   // Default to undefined if project not explicitly set
    let siteId      // Default to undefined if project not explicitly set
    if (toolboxEdit.project && toolboxEdit.project > 0) {
        // If we're NOT in 'All Projects' mode (i.e. a specific project has been set), look up 
        // company belonging to selected project
        // (otherwise leave as previously selected toolbox.company))
        const project = selectProject(state, { projectId: toolboxEdit.project })
        if (!project) {
            log.error('Unable to save tbOther - project not found')
            return
        } 
        companyId = project?.company
        projectId = toolboxEdit.project
        siteId = toolboxEdit.site
    }
    if (companyId) {

        const groupPhotoId = await savePhoto(
            toolboxEdit.groupPhoto,
            'Toolbox Group Photo',
            { company: companyId, project: projectId, site: siteId }
        )(dispatch, getState)

        // Set photo id in edit buffer (in case we need to delete it...)
        if (groupPhotoId) {
            dispatch(setToolboxEdit({ groupPhoto: { ...toolboxEdit.groupPhoto, docId: groupPhotoId } }))
        }

        // update company in edit buffer (so we can use it when saving related resources)
        dispatch(setToolboxEdit({ company: companyId }))

        const toolboxId = dispatch(saveResource({
            id: toolboxEdit.id,
            company: companyId,
            project: projectId,
            site: siteId,
            creator: toolboxEdit.creator,
            openDateTime: toolboxEdit.openDateTime,
            closeDateTime: toolboxEdit.closeDateTime,
            groupPhoto: (!toolboxEdit.groupPhoto || toolboxEdit.groupPhoto.deleted) ? null : groupPhotoId,
            meetingData: toolboxEdit.meetingData
        }, 'toolboxes'))

        if (toolboxId !== toolboxEdit.id) {
            dispatch(setToolboxEdit({ id: toolboxId }))
            // If we've just saved toolbox record for the first time, save attendees as well
            // (would otherwise do this directly from roll call page submit, but on initial save 
            // need to get toolbox id first)
            dispatch(saveTbAttendees)
        }
/*
        if (state.pgToolbox.stToolbox === stTOOLBOX.ROLL_CALL) {
            dispatch(saveTbAttendees)
        }
*/
    }
}