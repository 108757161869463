/**
 * State management specific to 'incident reports' page
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PhotoData } from '../../components/form/CameraInput'
import { IncidentData } from '../../datastore/models/Incident'
import { stINCIDENT_REPORTS } from '../../features/incidentReports/incidentReportsTypes'
import { IncidentType } from '../../datastore/models/Incident'
import { IncidentActionEdit } from '../../pages/IncidentReports/Actions'
import { IncidentWorkerEdit } from '../../pages/IncidentReports/WorkersInvolved'

export interface IncidentReportEdit {
    id?: number,
    company: number,
    site: number,
    project?: number,
    incidentDate: string,
    incidentType: IncidentType,
    reporter: number,
    description: string,
    photo?: PhotoData | null,
    actionVerifier?: number | null
    incidentData: IncidentData,
    notifiedDate?:string
    closedDate?: string
    workersInvolved: IncidentWorkerEdit[]
    actions: IncidentActionEdit[]
}

export interface PgIncidentReportsState {
    stIncidentReports: stINCIDENT_REPORTS
    incidentReportEdit: IncidentReportEdit
    reviewIncidentReportId?: number
    dirty: boolean
    toolboxMode: boolean
    toolboxIncident: number | null
    toolboxSite: number | null
}

const initialState: PgIncidentReportsState = {
    stIncidentReports: stINCIDENT_REPORTS.START,
    incidentReportEdit: {
        company: 0,
        site: 0,
        incidentDate: new Date().toISOString(),
        incidentType: 'NearMiss',
        reporter: 0,
        description: '',
        incidentData: {
            worksafeNotified: 'No',
            worksafeCase: '',
            containmentAction: '',
            containmentActionNa: false,
            damageExtentCompany: '',
            damageExtentCompanyNa: false,
            damageExtentThirdParty: '',
            damageExtentThirdPartyNa: false,
            equipmentRemedy: '',
            equipmentRemedyNa: false,
            mediaCoverage: '',
            mediaCoverageNa: false,
            personalFactor: false,
            jobFactor: false,
            externalFactor: false,
            incidentCause: '',
            drugAlcoholTest: '',
            taUsed: '',
            toolbox: ''
        },
        workersInvolved: [],
        actions: []
    },
    dirty: false,
    toolboxMode: false,
    toolboxIncident: null,
    toolboxSite: null
}

export const pgIncidentReports = createSlice({
    name: 'pgIncidentReports',
    initialState,
    reducers: {

        /**
        * Replace entire slice with new data
        * (will be called when initialising from persistent storage)  
        * - merge with initial state in case no saved copy or new items have been added since last save
        */
        setAll(state, action: PayloadAction<PgIncidentReportsState>) {
            return {
                ...state,
                ...action.payload
            }
        },

        /**
        * Reset state to initial values (e.g. on logout)
        *
        */
        setInitial(state, action: PayloadAction<void>) {
            return initialState
        },

        setIncidentReportsState(state, action: PayloadAction<stINCIDENT_REPORTS>) {
            state.stIncidentReports = action.payload
        },

        setIncidentReportReviewId(state, action: PayloadAction<number>) {
            state.reviewIncidentReportId = action.payload
        },

        /**
         * Initialise edit buffer (either with blank / default data or with an existing record)         
         */
        initialiseIncidentReportEdit(state, action: PayloadAction<Partial<IncidentReportEdit>>) {
            state.incidentReportEdit = {
                ...initialState.incidentReportEdit,
                ...action.payload
            }
            state.dirty = false
        },

        initialiseIncidentReportData(state, action: PayloadAction<Partial<IncidentData>>) {
            state.incidentReportEdit.incidentData = {
                ...initialState.incidentReportEdit.incidentData,
                ...action.payload
            }
            state.dirty = false
        },

        /**
         * Update edit buffer by merging new data with it 
         * (can be used to selectively set different attributes)         
         */
        setIncidentReportEdit(state, action: PayloadAction<Partial<IncidentReportEdit>>) {
            state.incidentReportEdit = {
                ...state.incidentReportEdit,
                ...action.payload
            }
            state.dirty = true
        },

        setIncidentReportData(state, action: PayloadAction<Partial<IncidentData>>) {
            state.incidentReportEdit.incidentData = {
                ...state.incidentReportEdit.incidentData,
                ...action.payload
            }
            state.dirty = true
        },

        setIncidentReportWorkersInvolved(state, action: PayloadAction<IncidentWorkerEdit[]>) {
            state.incidentReportEdit.workersInvolved = [...action.payload]
            state.dirty = true
        },

        setIncidentReportWorkerId(state, action: PayloadAction<{ index: number, id?: number }>) {
            if (action.payload.id) {
                const worker = state.incidentReportEdit.workersInvolved[action.payload.index]
                if (worker) {
                    worker.id = action.payload.id
                }
                state.dirty = true
            }
        },

        setIncidentReportActions(state, action: PayloadAction<IncidentActionEdit[]>) {
            state.incidentReportEdit.actions = [...action.payload]
            state.dirty = true
        },

        setIncidentReportActionId(state, action: PayloadAction<{ index: number, id?: number }>) {
            if (action.payload.id) {
                const editAction = state.incidentReportEdit.actions[action.payload.index]
                if (editAction) {
                    editAction.id = action.payload.id
                }
                state.dirty = true
            }
        },

        setIncidentReportDirty(state, action: PayloadAction<boolean>) {
            state.dirty = action.payload
        },

        setIncidentReportToolboxMode(state, action: PayloadAction<{ mode: boolean, incident?: number, site?: number }>) {

            if (action.payload.mode) {
                // Entering 'toolbox mode'            
                state.toolboxMode = true
                state.toolboxIncident = action.payload.incident || null
                state.toolboxSite = action.payload.site || null

            } else {
                state.toolboxMode = false
                state.toolboxIncident = null
                state.toolboxSite = null
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const {
    setAll,
    setInitial,
    setIncidentReportsState,
    setIncidentReportReviewId,
    initialiseIncidentReportEdit,
    initialiseIncidentReportData,
    setIncidentReportEdit,
    setIncidentReportData,
    setIncidentReportWorkersInvolved,
    setIncidentReportWorkerId,
    setIncidentReportActions,
    setIncidentReportActionId,

    setIncidentReportDirty,
    setIncidentReportToolboxMode
} = pgIncidentReports.actions

export default pgIncidentReports.reducer