import _ from 'lodash'
import log from 'loglevel'
import { ResourceType } from '../../../redux/slices/resources'
import models from '../models'

/**
 * Given a resource type, look up the resource type of a related resource
 * 
 * @param baseType resource type to look for relationship of
 * @param relation name / key of the relationship on the base type
 * @returns resource type of the corresponding related resource
 */
export const getRelationType = (baseType:string, relation:string) => {
    const model = models[_.kebabCase(baseType) as keyof typeof models]

    if (!model) {
        log.error('getRelationType:invalid resource type:', baseType)
        return ''
    }

    const relationships = model.relationships
    if (!relationships || !Array.isArray(relationships)) {
        log.error('getRelationType: could not find relationships on type ', baseType)
        return ''
    }

    const relationship = relationships.find(relationship => relationship.key === relation)
    
    if (!relationship) {        
        return ''
    }
    return (_.camelCase(relationship.type) as ResourceType) || ''
}