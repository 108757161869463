import _ from 'lodash'
import log from 'loglevel'
import models from '../models'

/**
 * Given a resource type, look up the relationship name / key of a related resource of a specified type
 * 
 * In some cases there will be more than one relationship for a given resource type, e.g. an incident / observation 
 * record that relates to several users
 * 
 * @param baseType resource type to look for relationship of
 * @param relatedType type of related resource to find relationship for
 * @param test boolean - set to true to supress error logging if we're checking for a relationship that
 *                       might or might not exist
 * @returns array of relationship keys on baseType referring to relatedType
 */
export const getRelationNames = (baseType: string, relatedType: string, test = false) => {
    const model = models[_.kebabCase(baseType) as keyof typeof models]

    if (!model) {
        log.error(`getRelationNames:invalid resource type ${baseType}`)
        return []
    }

    const relationships = model.relationships
    if (!relationships || !Array.isArray(relationships)) {
        if (!test) log.error(`getRelationNames: could not find any relationships on type ${baseType}`)
        return []
    }

    const matchedRelationships = relationships.filter(relationship => relationship.type === _.kebabCase(relatedType))

    if (!matchedRelationships.length) {
        if (!test) log.error(`getRelationNames: could not find relationship of type ${relatedType} on type ${baseType}`)
        return []
    }
    return matchedRelationships.map(relationship => relationship.key)
}