import { AppDispatch, RootState } from '../../redux/store'

import { deleteResource, saveResource } from '../../datastore'
import { deleteDoc } from '../../docstore/deleteDoc'
import { savePhoto } from '../../docstore/savePhoto'
import { TbOtherEdit } from '../../pages/Toolbox/OtherBusiness'
import { selectProject } from '../../redux/selectors/projects'
import log from 'loglevel'

export const saveTbOther = (item: Partial<TbOtherEdit>, setUpdatedItem?: (item: Partial<TbOtherEdit>) => void) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {

        const state = getState()
        const toolboxEdit = state.pgToolbox.toolboxEdit

        let companyId = toolboxEdit.company
        let projectId   // Default to undefined if project not explicitly set
        let siteId      // Default to undefined if project not explicitly set
        if (toolboxEdit.project && toolboxEdit.project > 0) {
            // If we're NOT in 'All Projects' mode (i.e. a specific project has been set), look up 
            // company belonging to selected project
            // (otherwise leave as previously selected toolbox.company))
            const project = selectProject(state, { projectId: toolboxEdit.project })
            if (!project) {
                log.error('Unable to save tbOther - project not found')
                return
            }            
            companyId = project?.company
            projectId = toolboxEdit.project
            siteId = toolboxEdit.site
        }

        if (item.deleted) {

            if (item.photo?.docId) {
                dispatch(deleteDoc(item.photo.docId))
            }

            if (item.id) {
                dispatch(deleteResource(item.id, 'tbOther'))
            }

        } else {
            const photoId = await savePhoto(
                item.photo,
                'Toolbox Other Item',
                { company: companyId, project: projectId, site: siteId }
            )(dispatch, getState)

            saveResource({
                id: item.id,
                company: companyId,
                heading: item.heading,
                text: item.text,
                photo: photoId || null, // Need to set to null to remove an existing docId
                toolbox: toolboxEdit.id
            }, 'tbOther')(dispatch, getState)
        }
    }