import { useAppSelector, useAppDispatch } from "../../redux/hooks"

import {
  addAvailableCustomHazard,
  addTaHazard,
  deleteAvailableCustomHazard,
  deleteTaHazard,
  setSelectedHazardCategories,
  setTaSelectedHazard
} from "../../redux/slices/pgTaskAnalysis"
import {
  IonButton,
  IonContent,
  IonItem,
  IonItemDivider,
  IonItemOption,
  IonList
} from "@ionic/react"
import { useEffect, useRef, useState } from "react"
import { selectHazardCategoryContents } from "../../redux/selectors/categories"
import { selectRootCategoriesFromHazards } from "../../redux/selectors/taHazardSelection"

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import TextArea from "../../components/form/TextArea"
import { selectStepEdit } from "../../redux/selectors/tas"
import { genericTextRegex } from "../../helpers/regex"
import { SlidingItem } from "../../components/SlidingItem"
import { getRiskScoringComplete } from "../../features/taskAnalysis/helpers/getRiskScoringComplete"
import { setAlert } from "../../utils/alert"
import "./SelectHazardCategories.css"
import TaskAnalysisNav from "./TaskAnalysisNav"
import TaskHeading from "./TaskHeading"
import { useHomeIconHandler } from "../../hooks/homeIconHandler"
import { homeIconAction } from "../../features/taskAnalysis/homeIconAction"
import {
  TaEvent,
  updateSequence
} from "../../features/taskAnalysis/taskAnalysisSequence"
import ASCheckbox from "../../components/ASCheckbox"

type Inputs = {
  hazardText: string
}

const schema = yup
  .object()
  .shape({
    hazardText: yup
      .string()
      .matches(genericTextRegex, {
        message: "Invalid characters",
        excludeEmptyString: true
      })
      .max(250, "Too many characters")
  })
  .required()

export const SelectHazardCategories: React.FC = () => {
  const dispatch = useAppDispatch()
  const {
    selectedStepIndex,
    selectedHazardCategories,
    availableCustomHazards
  } = useAppSelector((state) => state.pgTaskAnalysis)
  const stepEdit = useAppSelector(selectStepEdit)

  const allRootCategories = useAppSelector((state) =>
    selectHazardCategoryContents(state, { categoryId: null })
  )

  const usedRootCategories = useAppSelector(
    selectRootCategoriesFromHazards(stepEdit?.hazards || [])
  )

  // Array of custom hazards with risk scoring complete
  const [completedCustomHazards, setCompletedCustomHazards] = useState<
    string[]
  >([])

  useEffect(() => {
    setCompletedCustomHazards(
      stepEdit?.hazards.reduce((completedHazards: string[], hazard) => {
        if (!hazard.hazardTypeId && getRiskScoringComplete(hazard)) {
          return completedHazards.concat([hazard.customHazardType])
        } else {
          return completedHazards
        }
      }, []) || []
    )
  }, [stepEdit?.hazards])

  const setCustomHazard = (customHazardType: string, useHazard: boolean) => {
    if (useHazard) {
      dispatch(addTaHazard({ hazardTypeId: null, customHazardType }))
    } else {
      const hazardIndex =
        stepEdit?.hazards.findIndex(
          (hazard) =>
            customHazardType && hazard.customHazardType === customHazardType
        ) ?? -1
      const hazard = stepEdit?.hazards[hazardIndex]
      if (hazard) {
        if (getRiskScoringComplete(hazard)) {
          dispatch(
            setAlert(
              "Remove Hazard?",
              `You've already assigned controls for this hazard, do you want to:`,
              [
                {
                  text: "Cancel",
                  role: "cancel",
                  handler: () => true
                },
                {
                  text: "Review Controls",
                  handler: () => {
                    dispatch(setTaSelectedHazard(hazardIndex))
                    dispatch(updateSequence(TaEvent.EDIT_HAZARD_SELECTED))
                    return true
                  }
                },
                {
                  text: "Remove Hazard",
                  handler: () => {
                    dispatch(
                      deleteTaHazard({ hazardTypeId: null, customHazardType })
                    )
                    return true
                  }
                }
              ]
            )
          )
        } else {
          dispatch(deleteTaHazard({ hazardTypeId: null, customHazardType }))
        }
      }
    }
  }

  const listRef = useRef<HTMLIonListElement>(null)

  const deleteCustomHazard = (hazardText: string) => {
    listRef?.current?.closeSlidingItems()

    const hazard = stepEdit?.hazards.find(
      (hazard) => hazard.customHazardType === hazardText
    )

    if (hazard && getRiskScoringComplete(hazard)) {
      dispatch(
        setAlert(
          "Remove Hazard?",
          `You've already assigned controls for this hazard, are you sure you want to delete it?`,
          [
            {
              text: "Cancel",
              role: "cancel",
              handler: () => true
            },
            {
              text: "Delete Hazard",
              handler: () => {
                dispatch(deleteAvailableCustomHazard(hazardText))
                dispatch(
                  deleteTaHazard({
                    hazardTypeId: null,
                    customHazardType: hazardText
                  })
                )
                return true
              }
            }
          ]
        )
      )
    } else {
      dispatch(deleteAvailableCustomHazard(hazardText))
      dispatch(
        deleteTaHazard({ hazardTypeId: null, customHazardType: hazardText })
      )
    }
  }

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    watch
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      hazardText: ""
    }
  })

  const newCustomHazardText = watch("hazardText")

  /**
   * Form submit adds a new custom hazard to the list available, and selects it
   */
  const formSubmit = (data: any) => {
    dispatch(addAvailableCustomHazard(data.hazardText))
    setCustomHazard(data.hazardText, true)
    setValue("hazardText", "")
  }

  useHomeIconHandler("/task-analysis", () => dispatch(homeIconAction()))

  const selectCategory = (categoryId: number | null, useCategory: boolean) => {
    if (categoryId) {
      if (useCategory && !selectedHazardCategories.includes(categoryId)) {
        dispatch(
          setSelectedHazardCategories(
            selectedHazardCategories.concat([categoryId])
          )
        )
      }

      if (!useCategory && selectedHazardCategories.includes(categoryId)) {
        dispatch(
          setSelectedHazardCategories(
            selectedHazardCategories.filter(
              (category) => category !== categoryId
            )
          )
        )
      }
    }
  }

  return (
    <>
      <IonContent className="content-padding">
        <TaskHeading stepNumber={true} />
        <IonItemDivider />
        <h3 id="step-name">{`${selectedStepIndex + 1}. ${stepEdit?.text}`}</h3>
        <IonItemDivider />
        <h2 id="hazard-question">
          Which of these Hazards (if any) could be present in this Task Step?
        </h2>

        {/* Preset hazards */}
        <IonList id="preset-hazards-list" className="hazard-list">
          {allRootCategories.subcategories.map((item, i) => (
            <IonItem key={item.id} lines="none">
              <div>
                <ASCheckbox
                  checked={selectedHazardCategories.includes(item.id || 0)}
                  disabled={usedRootCategories.includes(item.id || 0)}
                  onClick={(state) => selectCategory(item.id, state)}
                  label={item.categoryText}
                />
              </div>
            </IonItem>
          ))}
        </IonList>

        {allRootCategories.subcategories.length &&
        availableCustomHazards.length ? (
          <IonItemDivider />
        ) : (
          <></>
        )}

        {/* Custom hazards list */}
        <IonList ref={listRef} id="custom-hazards-list" className="hazard-list">
          {availableCustomHazards.map((hazardText, i) => (
            <SlidingItem
              key={i}
              lines="none"
              itemContent={
                <ASCheckbox
                  color={
                    completedCustomHazards.includes(hazardText)
                      ? "green"
                      : "grey"
                  }
                  checked={
                    !!stepEdit?.hazards.find(
                      (hazard) => hazard.customHazardType === hazardText
                    )
                  }
                  onClick={(state) => setCustomHazard(hazardText, state)}
                  label={hazardText}
                />
              }
              optionsContent={
                <IonItemOption
                  color="danger"
                  onClick={() => deleteCustomHazard(hazardText)}
                >
                  Delete
                </IonItemOption>
              }
            />
          ))}
        </IonList>

        <form onSubmit={handleSubmit(formSubmit)} id="custom-hazard-entry">
          <h2>Add a custom Hazard:</h2>
          <div>
            <TextArea
              fieldName="hazardText"
              inputMode="text"
              rows={1}
              watch={watch}
              setValue={setValue}
              errors={errors}
            />
            <IonButton
              type="submit"
              disabled={
                newCustomHazardText === "" ||
                availableCustomHazards.includes(newCustomHazardText)
              }
            >
              ADD
            </IonButton>
          </div>
        </form>
      </IonContent>
      <TaskAnalysisNav />
    </>
  )
}
