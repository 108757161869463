import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from '../../redux/store'
import { getResource, getResources } from '../../datastore'
import { TaTemplate } from '../../datastore/models'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'
import { selectParamsMemo } from './helpers/selectParamsMemo'

const selectAllTaTemplates = (state: RootState) => state.resources.taTemplates
const selectTaTemplateParams = (state: RootState, taTemplateId: number | null) => taTemplateId
export const selectTaTemplate = createSelector([selectAllTaTemplates, selectTaTemplateParams], (allTaTemplates, taTemplateId) => {

    return taTemplateId ? getResource<TaTemplate>(allTaTemplates, taTemplateId) : null
})

const selectTaTemplateFromNameResources = selectResourcesMemo(['jobCategories', 'companies', 'taTemplates'])
const selectTaTemplateFromNameParams = selectParamsMemo()
/**
 * Find a template by it's name and category
 * 
 * Used if user types a template name and we want to match it to an existing template
 */
export const selectTaTemplateFromName = createSelector([selectTaTemplateFromNameResources, selectTaTemplateFromNameParams], (resources, params) => {

    if (params && params.itemName) {

        let eqFilter:Record<string, any> = { name: params.itemName }
        if (params.categoryId) {
            eqFilter.category = params.categoryId
        }
        if (params.companyId) {
            eqFilter.company = params.companyId
        }
        const templates = getResources<TaTemplate>(resources, 'taTemplates', { eqFilter})

        if (templates.length) {
            return templates[0]
        }
    }
    return null
})
