import { setRedirectPath } from "../../redux/slices/temp"
import { AppDispatch, RootState } from "../../redux/store"
import { saveObservation } from "./saveObservation"
import { siteObservationReturnToToolbox } from "./siteObservationsToolbox"
import { SiteObservationEvent, stSITE_OBSERVATIONS } from "./siteObservationsTypes"
import { updateSequence } from './siteObservationsSequence'

/**
 * Actions performed on tapping navbar home icon
 * 
 * - if in toolbox mode, return to toolbox meeting
 * - otherwise return to home page 
 */
export const homeIconAction = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { stSiteObservations, toolboxMode, dirty, reviewSiteObservationId } = getState().pgSiteObservations

    // If user has an unsaved edit, save it
    if (dirty) {
        // Need to await so that we get new / updated observationId
        // Shouldn't be long wait as we're only awaiting async blob conversion / storage access
        await saveObservation()(dispatch, getState)
    }

    if (toolboxMode) {
        let observationId = null

        if (stSiteObservations === stSITE_OBSERVATIONS.REVIEW) {
            // If we return from 'review' page, we've been looking at observation with id reviewSiteObservationId
            observationId = reviewSiteObservationId
        }

        if (stSiteObservations === stSITE_OBSERVATIONS.DETAILS || stSiteObservations === stSITE_OBSERVATIONS.ACTIONS) {
            // If we return from 'details' or 'actions' page, we've been looking at observation stored in edit buffer
            // Re-read observation id in case it was set / updated by save
            observationId = getState().pgSiteObservations.siteObservationEdit.details.id
        }
        dispatch(siteObservationReturnToToolbox(observationId || null))
        dispatch(updateSequence(SiteObservationEvent.START_SELECTED))

    } else {

        dispatch(setRedirectPath('/home'))
    }
}