const signinDocs = {
  table: 'signin_docs',
  idKey: 'id',

  attrs: [
    'title',    
    'order',    
    'showFrequencyWorker',
    'showFrequencyVisitor',
    'showFrequencyDriver',
    'default',
    'docType',
    'creationDate',
    'lastModifiedDate',
    'deleted'
  ],

  relationships: [   
    {
      type: 'companies',
      key: 'company',
    },    
    {
      type: 'sites',
      key: 'site'
    },
    {
      type: 'doc-info',
      key: 'docInfo',            
    } 
  ],
}

export default signinDocs