import { AppDispatch, RootState } from "../../../redux/store"
import { useAppSelector } from "../../../redux/hooks"
import { selectSiteHazardReport } from "../../../redux/selectors/siteHazards"
import { selectProjectSites } from "../../../redux/selectors/projectSites"
import { getResources, requestResources, requestResourcesWithRelated } from "../../../datastore"
import { useTask } from "../../../hooks/useTask"
import { ResourceEvent } from "../../../datastore/types"
import { Project, ProjectSite } from "../../../datastore/models"
import { uniq } from "lodash"
import { resourceEventCombined } from "../../../datastore/resourceEventCombined"

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results
 *
 * @returns
 * - requested resource (i.e. hazards)
 * - 'requestsDone' flag (true when request completes, either success or failure)
 * - refresh function - call to initiate a refresh (e.g. from refresh pulldown)
 */
export const useSiteHazardsResources = (
  siteId: number | null,
  companyId: number | null,
  refresh?: any
) => {
  const taskResult = useTask(
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      if (companyId) {
        // Requesting records for all company sites:

        // Request projectSites / sites for all open projects
        const projectsResult = await requestResourcesWithRelated(
          "projects",
          { eqFilter: { status: "OPEN" } },
          ["projectSites.sites"]
        )(dispatch, getState, services)

        const projects = getResources<Project>(
          getState().resources,
          "projects",
          { eqFilter: { company: companyId, status: "OPEN" } }
        )
        const projectSites = getResources<ProjectSite>(
          getState().resources,
          "projectSites",
          { inFilter: { project: projects.map((project) => project.id) } }
        )
        const siteIds = uniq(
          projectSites.map((projectSite) => projectSite.site)
        )

        const result = await requestResources("siteHazards", {
          inFilter: { site: siteIds }
        })(dispatch, getState, services)

        return {
            isSuccess: resourceEventCombined(projectsResult, [result]) !==
            ResourceEvent.RESOURCE_LOAD_ERROR
        }

      } else {
        // Requesting records for a specified site:
        const result = await requestResources("siteHazards", {
          eqFilter: { site: siteId }
        })(dispatch, getState, services)

        return {
          isSuccess: result !== ResourceEvent.RESOURCE_LOAD_ERROR
        }
      }
    },
    [siteId, companyId, refresh],
    !!siteId || !!companyId
  )

  const siteIds = useAppSelector((state) =>
    selectProjectSites(state, { companyId })
  ).map((projectSite) => projectSite.siteId)

  return {
    hazards: useAppSelector((state) =>
      selectSiteHazardReport(state, siteId ? { siteId } : { siteIds })
    ),
    isLoading: taskResult.isLoading,
    isSuccess: taskResult.isSuccess,
    isError: taskResult.isError
  }
}
