import { AppDispatch, RootState } from '../../redux/store'
import { returnToStart } from "./returnToStart"
import { saveObservation } from './saveObservation'

/**
 * Dispatched from 'Save' option on 'Actions' page
 * 
 * async so it can await save 
 */
export const saveObservationAndFinish = () => async (dispatch: AppDispatch, getState: () => RootState) => {

    // Need to await save, following processes may read saved version
    await saveObservation()(dispatch, getState)
    dispatch(returnToStart())
}