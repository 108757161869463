import { isValid, parseJSON } from 'date-fns'

/*
 * Utility functions for generating JSON-API filter strings 
 */

/**
 * Generates a time-period filter, with a start and / or end time for a given attribute
 * 
 * @param attribute name of date attribute to filter by
 * @param params object containing one or both of attributes 'periodStart' and 'periodEnd', 
 *               as either Dates or ISO formatted date strings. periodStart is inclusive; periodEnd is exclusive
 * @returns filter string
 */
export const periodFilter = (attribute: string, params: { [key: string]: any }) => {
    const { periodStart, periodEnd } = params

    const periodStartDt = periodStart ? parseJSON(periodStart) : null
    const periodEndDt = periodEnd ? parseJSON(periodEnd) : null
    const startFilter = periodStartDt && isValid(periodStartDt) ? `(${attribute},:gte,\`${periodStartDt.toISOString()}\`)` : ''
    const endFilter = periodEndDt && isValid(periodEndDt) ? `(${attribute},:lt,\`${periodEndDt.toISOString()}\`)` : ''

    return startFilter + endFilter
}

/**
 * Generates a simple filter for one or more attributes equal to specified values
 * 
 * @param filters object containing attribute - value pairs to filter on, e.g. {site:2}
 * @returns filter string
 */
export const eqFilter = (filters: { [key: string]: any }) => {
    return Object.keys(filters).reduce((acc: string, attribute) => {
        // Apply backtick quoting to string attributes 
        return acc + `(${attribute},:eq,${quote(filters[attribute])})`
    }, '')
}

/**
 * Generates a simple filter for one or more attributes NOT equal to specified values
 * 
 * @param filters object containing attribute - value pairs to filter on, e.g. {site:2}
 * @returns filter string
 */
export const neqFilter = (filters: { [key: string]: any }) => {
    return Object.keys(filters).reduce((acc: string, attribute) => {
        return acc + `(${attribute},:neq,${quote(filters[attribute])})`
    }, '')
}

/**
 * Generates a simple filter for one or more attributes/relationships in an array of specified values 
 * 
 * @param filters object containing attribute - value pairs to filter on, e.g. {id:[2,3,4]}
 * @returns filter string
 */
export const inFilter = (filters: { [key: string]: (number | string | null | undefined)[] }) => {
    return Object.keys(filters).reduce((acc: string, attribute) => {
        const inStr = filters[attribute].reduce((acc: string, item) => {
            return item === undefined ? acc : (acc ? `${acc},${quote(item)}` : quote(item))
        }, '')
        return acc + `(${attribute},:in,[${inStr}])`
    }, '')
}

/**
 * Format attribute for inclusion in filter string:
 * - Apply backtick quoting to string attributes
 * - Convert other types to string
 */
const quote = (attr: string | number | null | boolean) => {

    // Caution: although null is allowed in json-api filter string it doesn't seem to work
    // - null attributes don't appear in response, and filters 'eq null' and 'neq null' always 
    //   return empty
    if (attr === null) return 'null'

    return (typeof attr === 'string' ? `\`${attr}\`` : `${attr}`)
}