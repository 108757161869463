import { useEffect, useRef } from "react"

interface CheckboxProps {
  containerClass?: string
  color?: string
  label?: string
  checked?: boolean
  disabled?: boolean
  viewOnly?: boolean
  onClick?: (checked: boolean) => void
}

/**
 * 'Checkbox' created using clickable images
 * - does not propigate events to elements it's placed on top of (Required when placing checkbox on accordion 
 * so that selecting or deselecting the checkbox doesn't expand and collapse the accordion at the same time)
 */
const ASCheckbox: React.FC<CheckboxProps> = ({
  containerClass,
  color,      // 'grey'/'blue'/'green' (or any other colours we add icons for)
  label,
  checked,    // Externally supplied 'checked' state
  disabled,   // If true will be greyed out
  viewOnly,   // If true will be displayed normally but cannot be clicked
  onClick     // handler to call when clicked
}) => {
  const checkboxRef = useRef<HTMLButtonElement>(null)

  const isChecked = useRef<boolean>()
  useEffect(() => {
    isChecked.current = !!checked
  }, [checked])


  function clickHandler(e: any) {
    e.stopPropagation()
    if (e.detail === 1 && onClick && !disabled) {
      //isChecked.current = !isChecked.current
      onClick(!isChecked.current)
    }
  }

  useEffect(() => {
    // Normal 'onClick' is a react 'synthetic event' for which we can't stop propagation
    // => need to attach event listener instead so that we can call 'stopPropagation()'
    // Note no dependency array - handler needs to be reattached every render in case it 
    // gets updated with new parameters in calling component
    if (onClick && !viewOnly) {
      const checkboxEl = checkboxRef.current
      if (checkboxEl) checkboxEl.addEventListener("click", clickHandler)
      // Need to remove event listener in cleanup fn otherwise we leak checkboxRef instances
      return () => {
        if (checkboxEl) checkboxEl.removeEventListener("click", clickHandler)
      }
    }
  })

  const img = `./assets/ui_images/${checked
    ? color
      ? `checkbox_checked_${color}.svg`
      : "checkbox_checked_blue.svg"
    : `checkbox_blank.svg`
    }`

  return (
    <button
      className={`checkbox-container${containerClass ? ` ${containerClass}` : ""
        }${disabled ? " disabled" : ""}`}
      ref={checkboxRef}
      type="button"
    >
      <div className={"checkbox-img-container"}>
        <img
          alt={
            checked
              ? `${label} checkbox checked`
              : `${label} checkbox unchecked`
          }
          src={img}
          className="fake-checkbox"
        />
      </div>
      {label ? <span className="checkbox-label" style={{ "padding": "5px 0 5px 0" }} >{label}</span> : <></>}
    </button>
  )
}

export default ASCheckbox
