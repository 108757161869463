import { getResources } from "../../datastore"
import { Parameter } from "../../datastore/models";
import { RootState } from "../store"

const CONFIG_NAME = null;

export const selectParam = (name: string) => (state: RootState) => {
  
  const params = getResources<Parameter>(state.resources, "parameters", { eqFilter: { name: name } })
  .filter(parameter => (!CONFIG_NAME && !parameter.configName) || (CONFIG_NAME === parameter.configName))
  return params.length === 1 ?  params[0].value : ''
}
