const toolboxes = {
  table: 'toolboxes',
  idKey: 'id',

  attrs: [
    'meetingData',
    'openDateTime',
    'closeDateTime',
    'creationDate',
    'lastModifiedDate',
    'deleted'
  ],

  relationships: [
    {
      type: 'companies',
      key: 'company',
    }, 
    {
      type: 'projects',
      key: 'project',
    },
    {
      type: 'sites',
      key: 'site',
    },
    {
      type: 'users',
      key: 'creator',
    },
    {
      type: 'doc-info',
      key: 'groupPhoto',
    }
  ],
}

export default toolboxes