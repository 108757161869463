import { AppDispatch, RootState } from "../../redux/store"
import { ApiResult, ApiService } from "../../interfaces/api"
import { logout, UserCheckStatus } from "./"
import log from "loglevel"
import { setUser } from "./setUser"
import { setLogLevels } from "../../utils/log"
import { authGetUser, authRefreshToken } from "../../services/Auth"
import { setNextTokenRefresh } from "./tokenRefresh"
import { setIsNewLogin } from "../../redux/slices/temp"

/**
 * If a user was previously logged in, attempt to restore session
 * Called from startup OR when backend api connection is re-established after being offline
 */
export const restoreExistingSession = async (
  dispatch: AppDispatch,
  getState: () => RootState,
  api: ApiService
): Promise<UserCheckStatus> => {
  const { user, sub, guestAccessToken } = getState().app

  dispatch(setIsNewLogin(false))

  if (user) {
    // Our state says there's an existing session...
    if (user.isGuest) {
      // ... it's a guest user
      if (guestAccessToken) {
        api.setAccessToken(guestAccessToken)
        return UserCheckStatus.LOGGED_IN_PREV
      }

    } else {
      // ... non-guest user - check if we have a connection to our server
      const pingResult = await api.apiPing()
      if (pingResult === ApiResult.NETWORK_ERROR) {
        // If no network connection, leave existing user logged in
        return UserCheckStatus.NETWORK_FAILED
      }

      let authUser = null
      let tokenResponse = null
      try {
        // Attempt to refresh session
        tokenResponse = await authRefreshToken()
        authUser = await authGetUser()

      } catch (err) {
        if ((err as any).message === 'Unable To Obtain Server Configuration') {
          api.setOnline(false)
          return UserCheckStatus.NETWORK_FAILED
        }
      }

      // check that refreshed session sub matches the previously logged in user
      if (authUser && sub !== authUser.sub) {
        log.error(
          "restoreExistingSession: saved sub doesn't match current session - logging out"
        )
        dispatch(logout())
        return UserCheckStatus.LOGGED_OUT
      }

      if (tokenResponse?.accessToken) {
        api.setAccessToken(tokenResponse.accessToken)
        dispatch(setNextTokenRefresh(tokenResponse.expiresIn))

        const getUserResponse = await api.getUpdateUser()
        switch (getUserResponse.result) {
          case ApiResult.SUCCESS:
            // Got good response from 'get-update-user' - login successful
            // Update user record in state with data received from get-update-user
            const userData = getUserResponse.responseData
            if (userData) {
              if (userData.user.id !== user.id) {
                log.error(
                  "restoreExistingSession: saved user doesn't match current session - logging out"
                )
                dispatch(logout())
                return UserCheckStatus.LOGGED_OUT
              }

              log.info(
                `restoreExistingSession: - user ${userData.user?.firstName} ${userData.user?.lastName} session restored`
              )
              dispatch(
                setUser({
                  user: userData.user,
                  permissions: userData.permissions,
                  sub: authUser?.sub
                })
              )
              setLogLevels(userData.user.logLevels)
            }
            return UserCheckStatus.LOGGED_IN_PREV

          case ApiResult.NETWORK_ERROR:
            api.setOnline(false)
            return UserCheckStatus.NETWORK_FAILED

          default:
            log.error(
              "restoreExistingSession: failed to get user info from token - logging out"
            )
            dispatch(logout())
            return UserCheckStatus.LOGGED_OUT
        }
      }
    }
  }

  log.info("restoreExistingSession - no existing user")
  dispatch(setUser(null))
  return UserCheckStatus.LOGGED_OUT
}
