import { useEffect, useRef } from "react"
//import { arrowDownCircleOutline } from "ionicons/icons"
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonContent,
  IonFooter,
  IonItem,
  IonList
  //  IonRefresher,
  //  IonRefresherContent
} from "@ionic/react"

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import { useAppSelector, useAppDispatch } from "../../redux/hooks"
import TextArea from "../../components/form/TextArea"
import {
  setToolboxMeetingData,
  setToolboxTask
} from "../../redux/slices/pgToolbox"
import { saveToolbox } from "../../features/toolbox/saveToolbox"
import { saveTbTasks } from "../../features/toolbox/saveTbTasks"
import { saveTbTas } from "../../features/toolbox/saveTbTas"
import { inChecklist } from "../../features/toolbox/resourceChecklist"
import { useTasksResources } from "../../features/toolbox/resourceRequests/useTasksResources"

import "./Tasks.css"
import { updateSequence } from "../../features/toolbox/toolboxSequence"
import { ToolboxEvent } from "../../features/toolbox/toolboxTypes"
import {
  taToolboxCreateSelected,
  taToolboxReviewSelected
} from "../../features/taskAnalysis/taskAnalysisToolbox"
import ASCheckbox from "../../components/ASCheckbox"

type Inputs = {
  comments: string
}

const schema = yup
  .object()
  .shape({
    comments: yup.string()
  })
  .required()

export const Tasks: React.FC = () => {
  const dispatch = useAppDispatch()

  const { toolboxEdit, tasksList, tasList } = useAppSelector(
    (state) => state.pgToolbox
  )

  const { allScheduledTasks, allTas, isLoading } = useTasksResources(
    toolboxEdit.project || null,
    toolboxEdit.site || null,
    toolboxEdit.company || null
  )

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty }
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      comments: toolboxEdit.meetingData.tasksComments
    }
  })

  const formSubmitBack = (data: any) => {
    formSubmit(data)
    dispatch(updateSequence(ToolboxEvent.BACK_SELECTED))
  }

  const formSubmitNext = (data: any) => {
    formSubmit(data)
    dispatch(updateSequence(ToolboxEvent.NEXT_SELECTED))
  }

  const formSubmit = (data: any) => {
    if (isDirty) {
      dispatch(setToolboxMeetingData({ tasksComments: data.comments }))
      dispatch(saveToolbox)
    }
    dispatch(saveTbTasks)
    dispatch(saveTbTas)
  }

  const refresherRef = useRef<HTMLIonRefresherElement>(null)
  useEffect(() => {
    if (!isLoading) {
      refresherRef.current?.complete()
    }
  }, [!isLoading])

  // Get SWMS not associated with a scheduled task
  const nonTaskSwms = allTas
    .filter(
      (ta) =>
        !allScheduledTasks.find(
          (task) => task.id === ta.scheduledTask
        )
    )

  return (
    <>
      <IonContent class="content-padding">
        <h2>Tasks for Today</h2>
        <p>
          What jobs are scheduled for today? Is there a SWMS created
          and available for each job?
        </p>
        {/* 
        <IonRefresher ref={refresherRef} slot="fixed" onIonRefresh={() => setRefresh(refresh+1)}>
          <IonRefresherContent
            pullingIcon={arrowDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          />
        </IonRefresher>
         */}
        <form className="toolbox-form">
          {allScheduledTasks && allScheduledTasks.length ? (
            <>
              <h3>Scheduled Tasks:</h3>

              <IonList>
                <IonAccordionGroup class="test-groups" multiple={true}>
                  {allScheduledTasks?.map((task) => (
                    <IonAccordion key={task.id}>
                      <IonItem slot="header">
                        <ASCheckbox
                          checked={inChecklist(tasksList, task)}
                          onClick={(checked) =>
                            dispatch(
                              setToolboxTask({ task, newState: checked })
                            )
                          }
                          label={task.taskName}
                        />
                      </IonItem>

                      <IonList class="tasks-list-temp" slot="content">
                        {allTas.filter((ta) => ta.scheduledTask === task.id)
                          .length ? (
                          <h3>Active SWMS:</h3>
                        ) : (
                          <></>
                        )}
                        {allTas
                          .filter((ta) => ta.scheduledTask === task.id)
                          .map((ta) => (
                            <IonItem key={ta.id}>
                              <div className="flex row full-width-temp">
                                <ASCheckbox
                                  checked={inChecklist(tasList, ta)}
                                  viewOnly={true}
                                  label={ta.userData.name}
                                />
                                <IonButton
                                  className="right-button-temp"
                                  size="small"
                                  color={
                                    inChecklist(tasList, ta) ? "" : "warning"
                                  }
                                  onClick={() =>
                                    dispatch(taToolboxReviewSelected(ta.id))
                                  }
                                >
                                  Review
                                </IonButton>
                              </div>
                            </IonItem>
                          ))}
                        <IonButton
                          color="success"
                          size="small"
                          onClick={() =>
                            dispatch(
                              taToolboxCreateSelected(
                                toolboxEdit.project || null,
                                toolboxEdit.site || null,
                                task.id
                              )
                            )
                          }
                        >
                          Create SWMS
                        </IonButton>
                      </IonList>
                    </IonAccordion>
                  ))}
                </IonAccordionGroup>
              </IonList>
            </>
          ) : (
            <></>
          )}
          {nonTaskSwms.length
            ? <>
              <h3>
                {allScheduledTasks && allScheduledTasks.length
                  ? "Other Active SWMS:"
                  : "Active SWMS:"}
              </h3>
              <IonList>
                {nonTaskSwms.map((ta) => (
                  <IonItem key={ta.id}>
                    <div className="flex row full-width-temp">
                      <ASCheckbox
                        checked={inChecklist(tasList, ta)}
                        viewOnly={true}
                        label={ta.userData.name}
                      />
                      <IonButton
                        size="small"
                        className="right-button-temp"
                        onClick={() => dispatch(taToolboxReviewSelected(ta.id))}
                      >
                        Review
                      </IonButton>
                    </div>
                  </IonItem>
                ))}
              </IonList>
            </>
            : <></>
          }

          <div className="flex centre-row">
            <IonButton
              onClick={() =>
                dispatch(
                  taToolboxCreateSelected(
                    toolboxEdit.project || null,
                    toolboxEdit.site || null
                  )
                )
              }
            >
              Create SWMS
            </IonButton>
          </div>

          <TextArea
            fieldName="comments"
            inputMode="text"
            placeholder="COMMENTS - Any extra details you want to record"
            rows={6}
            watch={watch}
            setValue={setValue}
            errors={errors}
          />
        </form>
      </IonContent>

      <IonFooter class="toolbox-nav">
        <IonButton onClick={handleSubmit(formSubmitBack)}>Back</IonButton>
        <IonButton onClick={handleSubmit(formSubmitNext)}>Next</IonButton>
      </IonFooter>
    </>
  )
}
