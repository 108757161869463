/**
 * State management specific to signin page
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Site } from '../../datastore/models'
import { ActiveSigninDoc } from '../../datastore/models/SigninDoc'
import { stSIGNIN } from '../../features/signin/signinTypes'

export interface PgSigninState {
    stSignin: stSIGNIN
    signinCode: string
    signinSiteId: number | null    // id of site user is in the process of signing into
    signinType: 'Worker' | 'Visitor' | 'Driver'
    induction: boolean
    docList: ActiveSigninDoc[]    
    selectedDocIndex: number | null
    selectedDocTitle: string
    nearbySites: Site[]
}

const initialState: PgSigninState = {
    stSignin: stSIGNIN.INITIALISE,
    signinCode: '',
    signinSiteId: null,
    signinType: 'Worker',
    induction: false,
    docList: [],    
    selectedDocIndex: null,
    selectedDocTitle: '',
    nearbySites:[]
}

export const pgSigninSlice = createSlice({
    name: 'pgSignin',
    initialState,
    reducers: {
        /**
        * Replace entire slice with new data
        * (will be called when initialising from persistent storage)  
        */
        setAll(state, action: PayloadAction<PgSigninState>) {
            return {
                ...state,
                ...action.payload
            }  
        },

        /**
         * Reset state to initial values (e.g. on logout)
         *
         */
        setInitial(state, action: PayloadAction<void>) {
            return initialState
        },

        setSigninState(state, action: PayloadAction<stSIGNIN>) {
            state.stSignin = action.payload
        },

        setSigninCode(state, action: PayloadAction<string>) {
            state.signinCode = action.payload
        },

        setSigninSite(state, action: PayloadAction<number | null>) {
            state.signinSiteId = action.payload
        },

        setSigninType(state, action: PayloadAction<'Worker' | 'Visitor' | 'Driver'>) {
            state.signinType = action.payload
        },

        setIsInduction(state, action: PayloadAction<boolean>) {
            state.induction = action.payload
        },

        setDocList(state, action: PayloadAction<ActiveSigninDoc[]>) {
            state.docList = action.payload
        },        

        setSelectedDocIndex(state, action: PayloadAction<number>) {
            state.selectedDocIndex = action.payload
        },

        setSelectedDocTitle(state, action: PayloadAction<string>) {
            state.selectedDocTitle = action.payload
        },

        setSigninDocCompleted(state, action: PayloadAction<{ index: number, value: boolean }>) {
            const index = action.payload.index
            if (!isNaN(index) && index >= 0 && index < state.docList.length) {
                state.docList[index].completed = action.payload.value
            }
        },

        setNearbySites(state, action: PayloadAction<Site[]>) {
            state.nearbySites = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { 
    setAll,
    setInitial,
    setSigninState,
    setSigninCode,
    setSigninSite,
    setSigninType,
    setIsInduction,
    setDocList,    
    setSelectedDocIndex,
    setSelectedDocTitle,
    setSigninDocCompleted,
    setNearbySites
} = pgSigninSlice.actions

export default pgSigninSlice.reducer