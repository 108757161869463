import { useEffect, useRef, useState } from "react"
import { useAppSelector, useAppDispatch } from "../../redux/hooks"
import {
  IonButton,
  IonContent,
  IonItem,
  IonItemDivider,
  IonItemOption,
  IonList
} from "@ionic/react"

import {
  setTaHazardControls,
  setTaHazardCustomControls
} from "../../redux/slices/pgTaskAnalysis"
import {
  selectHazardCategory,
  selectHazardType
} from "../../redux/selectors/hazardsAndControls"
import TextArea from "../../components/form/TextArea"
import { selectHazardEdit, selectStepEdit } from "../../redux/selectors/tas"

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { genericTextRegex } from "../../helpers/regex"
import { SlidingItem } from "../../components/SlidingItem"
import { selectHazardCategoryPath } from "../../redux/selectors/categories"
import TaskAnalysisNav from "./TaskAnalysisNav"
import "./SelectControls.css"
import TaskHeading from "./TaskHeading"
import { useHomeIconHandler } from "../../hooks/homeIconHandler"
import { homeIconAction } from "../../features/taskAnalysis/homeIconAction"
import ASCheckbox from "../../components/ASCheckbox"

type Inputs = {
  controlText: string
}

const schema = yup
  .object()
  .shape({
    controlText: yup
      .string()
      .matches(genericTextRegex, {
        message: "Invalid characters",
        excludeEmptyString: true
      })
      .max(250, "Too many characters")
  })
  .required()

export const SelectControls: React.FC = () => {
  const dispatch = useAppDispatch()

  const { selectedStepIndex } = useAppSelector((state) => state.pgTaskAnalysis)
  const stepEdit = useAppSelector(selectStepEdit)
  const hazardEdit = useAppSelector(selectHazardEdit)
  const selectedHazardType = useAppSelector((state) =>
    selectHazardType(state, { hazardTypeId: hazardEdit?.hazardTypeId })
  )

  const selectedHazardCategory = useAppSelector((state) =>
    selectHazardCategory(state, { hazardTypeId: hazardEdit?.hazardTypeId })
  )
  const selectedHazardCategoryPath = useAppSelector((state) =>
    selectHazardCategoryPath(state, selectedHazardCategory)
  )

  const [availableCustomControls, setAvailableCustomControls] = useState<
    string[]
  >([])

  const setControl = (controlId: number, useControl: boolean) => {
    if (useControl && !hazardEdit?.controls.includes(controlId)) {
      dispatch(
        setTaHazardControls(hazardEdit?.controls.concat([controlId]) || [])
      )
    }

    if (!useControl && hazardEdit?.controls.includes(controlId)) {
      dispatch(
        setTaHazardControls(
          hazardEdit.controls.filter((control) => control !== controlId)
        )
      )
    }
  }

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    watch
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      controlText: ""
    }
  })

  const newCustomControlText = watch("controlText")

  /**
   * Form submit adds a new custom control to the list of controls available, and selects it for hazard
   */
  const formSubmit = (data: any) => {
    setAvailableCustomControls(
      availableCustomControls.concat([data.controlText])
    )
    dispatch(
      setTaHazardCustomControls(
        hazardEdit?.customControls.concat([data.controlText]) || []
      )
    )
    setValue("controlText", "")
  }

  const setCustomControl = (controlText: string, useControl: boolean) => {
    if (useControl && !hazardEdit?.customControls.includes(controlText)) {
      dispatch(
        setTaHazardCustomControls(
          hazardEdit?.customControls.concat([controlText]) || []
        )
      )
    }

    if (!useControl && hazardEdit?.customControls.includes(controlText)) {
      dispatch(
        setTaHazardCustomControls(
          hazardEdit?.customControls.filter(
            (control) => control !== controlText
          )
        )
      )
    }
  }

  const listRef = useRef<HTMLIonListElement>(null)

  const deleteCustomControl = (controlText: string) => {
    listRef?.current?.closeSlidingItems()
    setAvailableCustomControls(
      availableCustomControls.filter((control) => control !== controlText)
    )
    dispatch(
      setTaHazardCustomControls(
        hazardEdit?.customControls.filter(
          (control) => control !== controlText
        ) || []
      )
    )
  }

  useHomeIconHandler("/task-analysis", () => dispatch(homeIconAction()))

  // On initial render, add any custom controls the hazard already has to the list of available custom controls
  useEffect(() => {
    let newAvailableControls: string[] = []
    hazardEdit?.customControls.forEach((control) => {
      if (!availableCustomControls.includes(control)) {
        newAvailableControls.push(control)
      }
    })
    setAvailableCustomControls(
      availableCustomControls.concat(newAvailableControls)
    )
  }, [])

  return (
    <>
      <IonContent className="select-controls content-padding">
        <TaskHeading stepNumber={true} />
        <IonItemDivider />
        <h3 id="step-name">{`${selectedStepIndex + 1}. ${stepEdit?.text}`}</h3>
        <IonItemDivider />

        <div className="tree-grid-risk-page">
          {selectedHazardType ? (
            <>
              <h4 className={`col-1-item`}>
                {selectedHazardCategoryPath[
                  selectedHazardCategoryPath.length - 1
                ].categoryText + " >"}
              </h4>
              <h1 className={`col-1-item`}>{selectedHazardType.hazardText}</h1>
            </>
          ) : (
            <h1 className={`col-1-item`}>{hazardEdit?.customHazardType}</h1>
          )}
        </div>

        <h2 className="bp-orange">
          What controls will you put in place for this hazard?
        </h2>

        <IonList ref={listRef} id="preset-hazards-list">
          {selectedHazardType?.controls.map((control) => (
            <IonItem key={control.id} lines="none">
              <div id="hazard-control-item">
                <ASCheckbox
                  color="grey"
                  checked={hazardEdit?.controls.includes(control.id)}
                  onClick={(state) => setControl(control.id, state)}
                  label={control.controlText}
                />
              </div>
            </IonItem>
          ))}
          {availableCustomControls.map((controlText, i) => (
            <SlidingItem
              key={i}
              lines="none"
              itemContent={
                <ASCheckbox
                  color="grey"
                  checked={hazardEdit?.customControls.includes(controlText)}
                  onClick={(state) => setCustomControl(controlText, state)}
                  label={controlText}
                />
              }
              optionsContent={
                <IonItemOption
                  color="danger"
                  onClick={() => deleteCustomControl(controlText)}
                >
                  Delete
                </IonItemOption>
              }
            />
          ))}
        </IonList>

        <form onSubmit={handleSubmit(formSubmit)} id="custom-hazard-entry">
          <h2>Add a custom control:</h2>
          <div>
            <TextArea
              fieldName="controlText"
              inputMode="text"
              rows={1}
              watch={watch}
              setValue={setValue}
              errors={errors}
            />
            <IonButton
              type="submit"
              disabled={
                newCustomControlText === "" ||
                availableCustomControls.includes(newCustomControlText)
              }
            >
              ADD
            </IonButton>
          </div>
        </form>
      </IonContent>
      <TaskAnalysisNav />
    </>
  )
}
