const siteContacts = {
  table: "site_users",
  idKey: "id",

  attrs: ["creationDate", "lastModifiedDate", "deleted"],

  relationships: [
    {
      type: "companies",
      key: "company"
    },
    {
      type: "projects",
      key: "project"
    },
    {
      type: "sites",
      key: "site"
    },
    {
      type: "users",
      key: "user"
    }
  ]
}

export default siteContacts
