import React, { useState } from 'react'
import { FieldError, UseFormWatch, UseFormSetValue } from "react-hook-form"
import { IonButton, IonContent, IonFooter, IonInput, IonItem, IonLabel, IonList, IonModal } from '@ionic/react'

interface SelectListInputProps {
    label?: string,
    placeholder?: string,
    fieldName: string,
    title: string,
    className?: string,
    listData: { text: string, id: number }[]
    watch: UseFormWatch<any>
    setValue: UseFormSetValue<any>
    errors?: { [key: string]: FieldError | undefined } // Use if whole 'errors' object passed 
    error?: FieldError // Use if just this field's error is passed (may need for array fields)
    disabled?: boolean
}

const SelectListInput: React.FC<SelectListInputProps> = ({
    label, placeholder, fieldName, title, listData, watch, setValue, errors, error, disabled, className
}) => {

    const fieldError = errors && errors[fieldName] ? errors[fieldName] : error
    const value = watch(fieldName)

    // Use 'Show Modal flag' approach
    // (would like to use useIonModal hook but won't work inside react hook form controller)
    const [showModal, setShowModal] = useState<boolean>(false);

    const currentItemText = (listData.find(item => item.id === value))?.text

    const onChange = (itemId: number) => {
        setValue(fieldName, itemId, { shouldDirty: true })
    }

    return (
        <div className={className}>
            <div className="flex-row" >
                <div style={{ "width": "100%" }} className="select-list-input-container">
                    {label ? <IonLabel className="oss-input-label" style={{ "marginBottom": "5px" }}>
                        {label}</IonLabel> : <></>}
                    <IonInput className="oss-input"
                        readonly={true}
                        placeholder={placeholder}
                        value={currentItemText}
                        style={fieldError ? { borderColor: "red" } : {}}
                        onClick={() => { if (!disabled) setShowModal(true) }}
                    >
                    </IonInput>
                </div>

                <IonModal backdropDismiss={false} isOpen={showModal}>
                    <IonContent className="content-padding" class="normal-page" force-overscroll="false">
                        <div className='safe-area-top'></div>
                        <h2>{title}</h2>
                        <IonList>
                            {listData.map((item, i) => (
                                <IonItem key={i} onClick={() => { onChange(item.id); setShowModal(false) }}>
                                    {item.text}
                                </IonItem>
                            )
                            )}
                        </IonList>

                    </IonContent>
                    <IonFooter>
                        <div className="flex centre-row safe-area-bottom">
                            <IonButton onClick={() => setShowModal(false)}>Cancel</IonButton>
                        </div>
                    </IonFooter>
                </IonModal>

            </div>
            <div className="oss-input-error-message">{fieldError?.message}</div>
        </div>
    )

}
export default SelectListInput