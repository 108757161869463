const parameters = {
    table: 'parameters',
    idKey: 'id',
  
    attrs: [
      'context',
      'configName',
      'name',
      'value',
      'creationDate',
      'lastModifiedDate',
    ],
  
    relationships: [       
    ],
  }

  export default parameters
  