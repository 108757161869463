/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import Axios, { AxiosError } from 'axios'
import { UnsavedUser } from '../datastore/models'
import { getApiRoot } from '../helpers/getApiRoot'
import { ApiResult } from '../interfaces/api'
import { setAccessToken } from './accessToken'


export const loginGuest = async (signinCode: string, guestUser: UnsavedUser) => {

    try {
      const response = await Axios.post(`${getApiRoot()}auth/login-guest?signinCode=${signinCode}`, guestUser)
      if (typeof response.data.userId !== 'undefined' && response.data.accessToken) {
        setAccessToken(response.data.accessToken)
        return {
          result: ApiResult.SUCCESS,
          userId: response.data.userId,
          accessToken: response.data.accessToken
        }
      }
  
    } catch (err: any | AxiosError) {
      if (Axios.isAxiosError(err) && err.response) {
        // API responded with error code - probably means signinCode or guest record wasn't valid          
        return { result: ApiResult.SERVER_ERROR }
  
      } else {
        // Request failed or no response from API
        // Probably means backend is down or no internet connection
        return { result: ApiResult.NETWORK_ERROR }
      }
    }
    return { result: ApiResult.SERVER_ERROR }
  }