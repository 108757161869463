import { useEffect, useRef, useState } from "react"

import { IonAlert, IonApp, IonLoading, IonRouterOutlet } from "@ionic/react"

import { setupIonicReact } from "@ionic/react"

import { IonReactRouter } from "@ionic/react-router"
import { Redirect, Route } from "react-router-dom"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"

/* Theme variables */
import "./theme/variables.css"
import "./theme/global.css"

import "./App.css"

import RouteChanger from "./components/RouteChanger"

import { useAppSelector, useAppDispatch } from "./redux/hooks"

import { getAlertButtons, setAlert } from "./utils/alert"
import ErrorBoundary from "./components/ErrorBoundary"

import Menu from "./components/Menu"
import Splash from "./pages/Splash"

import Signin from "./pages/Signin"
import Home from "./pages/Home"
import Toolbox from "./pages/Toolbox"
import SiteObservations from "./pages/SiteObservations"
import TaskAnalysis from "./pages/TaskAnalysis"
import Documents from "./pages/Documents"
import IncidentReports from "./pages/IncidentReports"
import LoggingOut from "./pages/LoggingOut"
import { differenceInMilliseconds } from "date-fns"
import Callback from "./pages/Callback"
import AcceptTerms from "./pages/AcceptTerms"

const App: React.FC = () => {
  const dispatch = useAppDispatch()

  const [showAlert, setShowAlert] = useState<boolean>(false)

  const { alertHeader, alertMessage, loadingSpinner, loadingMessage } =
    useAppSelector((state) => state.temp)

  const alertButtons = getAlertButtons()

  setupIonicReact({
    mode: "md"
  })

  useEffect(() => {
    if (alertMessage) {
      setShowAlert(true)
    }
  }, [alertHeader, alertMessage])

  const loadingShowTime = useRef<Date>(new Date(0))
  useEffect(() => {
    if (loadingSpinnerRef.current) {
      if (loadingSpinner) {
        loadingSpinnerRef.current.present()
        loadingShowTime.current = new Date()
      } else {
        // Cannot dismiss immediately after presenting - spinner will fail to see 'dismiss' and remain on screen
        // -> ensure we call 'dismiss' a minimum of 100ms after 'present'
        if (
          differenceInMilliseconds(new Date(), loadingShowTime.current) > 100
        ) {
          loadingSpinnerRef.current.dismiss()
        } else {
          setTimeout(() => loadingSpinnerRef.current?.dismiss(), 100)
        }
      }
    }
  }, [loadingSpinner])

  const loadingSpinnerRef = useRef<HTMLIonLoadingElement>(null)

  return (
    <ErrorBoundary>
      <IonApp>
        <IonReactRouter>
          <RouteChanger
            noAuthRoutes={["/splash", "/signin", "/logout", "/callback"]}
            loginRoute="/signin"
          />

          <Menu />
          <IonRouterOutlet id="router-outlet">
            <Route exact path="/">
              <Redirect to="/splash" />
            </Route>
            <Route path="/callback">
              <Callback />
            </Route>
            <Route path="/accept-terms">
              <AcceptTerms />
            </Route>
            <Route path="/logout">
              <LoggingOut />
            </Route>
            <Route path="/splash">
              <Splash />
            </Route>
            <Route path="/signin">
              <Signin />
            </Route>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/task-analysis">
              <TaskAnalysis />
            </Route>
            <Route path="/toolbox">
              <Toolbox />
            </Route>
            <Route path="/observations">
              <SiteObservations />
            </Route>
            <Route path="/incidents">
              <IncidentReports />
            </Route>
            <Route path="/documents">
              <Documents />
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => {
            dispatch(setAlert("", ""))
            setShowAlert(false)
          }}
          header={alertHeader}
          message={alertMessage}
          buttons={alertButtons}
        />

        <IonLoading ref={loadingSpinnerRef} message={loadingMessage} />
      </IonApp>
    </ErrorBoundary>
  )
}

export default App
