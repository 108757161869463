import log from "loglevel"
import { isPlatform } from "@ionic/react"

import { AppDispatch, RootState } from "../../redux/store"
import { restoreExistingSession, UserCheckStatus } from "../auth"
import { getAppOpenUrl } from "./getAppOpenUrl"
import { initialiseSigninState } from "../signin/initialiseSigninState"
import { initialiseState } from "../../redux/persistence"
import { setRedirectPath, setStartupInProgress } from "../../redux/slices/temp"
import { startTokenRefresh } from "../auth/tokenRefresh"
import { initialiseGlobalResourceUpdate } from "../resources/globalResourceUpdate"
import { agreeTermsRequired } from "./agreeTermsRequired"
import { requestResources } from "../../datastore"

export const startup =
  () =>
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      const api = services.api

      log.debug(`Entered startup function`)

      if (isPlatform("desktop")) log.debug("Platform: desktop")
      if (isPlatform("android")) log.debug("Platform: android")
      if (isPlatform("ios")) log.debug("Platform: ios")
      if (isPlatform("pwa")) log.debug("Platform: pwa")
      if (isPlatform("capacitor")) log.debug("Platform: capacitor")

      await initialiseState()(dispatch, getState)

      const url = await getAppOpenUrl()

      if (url.pathname !== "/callback") {
        const loginStatus = await restoreExistingSession(dispatch, getState, api)

        if (loginStatus !== UserCheckStatus.LOGGED_OUT) {
          dispatch(initialiseGlobalResourceUpdate())
          dispatch(startTokenRefresh)

          // parameters may be refreshed periodically, but always read at startup / login
          await requestResources("parameters", {})(dispatch, getState, services)          
        }

        if (agreeTermsRequired(getState)) {
          dispatch(setRedirectPath("/accept-terms"))
        } else {
          dispatch(initialiseSigninState())
        }
      }      

      dispatch(setStartupInProgress(false))
      log.info("Startup function Complete")
    }
