import { IonContent, IonPage, useIonViewDidEnter } from '@ionic/react';
import { usePageTimeout } from '../hooks/usePageTimeout';
import { useAppDispatch } from '../redux/hooks';
import { setSplashStartTime } from '../redux/slices/temp';
import './Splash.css';

const MAX_DISPLAY_SECONDS = 10

const Splash: React.FC = () => {

    const dispatch = useAppDispatch()

    useIonViewDidEnter(() => {
        dispatch(setSplashStartTime())
    })

    // If page has been shown too long, automatically switch back to signin page
    // (this should not occur - just so user can't get stuck on splash screen if something goes wrong in startup code)
    usePageTimeout(MAX_DISPLAY_SECONDS * 1000, "/signin")

    return (
        <IonPage>
            <IonContent fullscreen id="splash-content">
                <div id="splash-container">
                    {/* Cone logo */}
                    <img alt="cone" src="./assets/ui_images/oss_cone.svg"></img>
                    <div id="tape-title">
                        {/* Tape divider */}
                        <div></div>
                        {/* OSS text */}
                        <img alt="Attention Safety - Go Home Safe Every Day" src="./assets/ui_images/attention_safety_title_white.svg"></img>
                    </div>
                    {/* Builda Price logo */}
                    <div id="bp-logo-grid">
                        {/* <span id="bp-advert-text">Brought to you by</span>
                        <div id="bp-advert-icon">
                            <img alt="" src="./assets/ui_images/Builda_Price_Logo_Icon.png"></img>
                        </div>
                        <div id="bp-advert-title">
                            <img alt="Builda Price" src="./assets/ui_images/Builda_Price_Logo_Text.png"></img>
                        </div> */}
                        <img alt="Brought to you by Builda Price" src="./assets/ui_images/BP_Brought_to_you_Logo_white.svg"></img>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default Splash