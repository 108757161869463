import { isBefore, isValid, parseISO, parseJSON } from "date-fns";
import { isFinite } from "lodash";
import { selectParam } from "../../redux/selectors/parameters";
import { RootState } from "../../redux/store";

/**
 * Test whether user needs to agree to terms and conditions 
 * 
 * @returns true if:
 * - user is not a guest, AND
 * - 'termsDate' parameter is set (i.e. 'valid from' date of current terms and conditions document), AND
 * - 'termsDoc' parameter is set (i.e. document containing terms and conditions)
 * - User has no 'termsAgreedDate' (they've never agreed to terms), OR 
 * - user 'termsAgreedDate' is before 'termsDate' (terms have been updated and user needs to agree again)
 */
export const agreeTermsRequired = (getState: () => RootState) => {
    const state = getState()
    const user = state.app.user
    if (!user || user.isGuest) {
        return false
    }
    const termsDate = parseISO(selectParam('termsDate')(getState())) 
    const termsAgreedDate = parseJSON(user.termsAgreedDate || '')
    const termsDoc = +selectParam('termsDoc')(getState())
    
    return (
        isValid(termsDate) && 
        isFinite(termsDoc) &&
        (!isValid(termsAgreedDate) || isBefore(termsAgreedDate, termsDate)))
}