import React from 'react';
import log from 'loglevel'
import Splash from '../pages/Splash';

/**
 * A basic error boundary
 * TODO: render something useful
 * Currently this will catch and log an error, but UI will still break and show default error page
 */
class ErrorBoundary extends React.Component {
  state = {
    error: null,
  }

  static getDerivedStateFromError(error:any) {
    // Update state so next render shows fallback UI.
    return { error: error };
  }

  componentDidCatch(error:any, info:any) {    
    log.error('Error Boundary', error, 'Info:', JSON.stringify(info))
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      // TODO: Display splash screen for a few seconds in case error is transient, then add error message
      return <Splash/>
    }
    return this.props.children;
  }
}

export default ErrorBoundary