import log from "loglevel"
import { logout, UserCheckStatus } from "."
import { ApiResult, ApiService } from "../../interfaces/api"
import { AppDispatch, RootState } from "../../redux/store"
import { setAlert } from "../../utils/alert"
import { setUser } from "./setUser"
import { authGetCallbackTokenResponse, authGetUser } from "../../services/Auth"
import {
  setAuthCode,
  setIsNewLogin,
  setRedirectPath
} from "../../redux/slices/temp"
import { saveBufferedLogs, setLogLevels } from "../../utils/log"
import { initialiseSigninState } from "../signin/initialiseSigninState"
import { setNextTokenRefresh } from "./tokenRefresh"
import { agreeTermsRequired } from "../startup/agreeTermsRequired"
import { requestResources } from "../../datastore"
import { initialiseGlobalResourceUpdate } from "../resources/globalResourceUpdate"

export const handleLoginCallback =
  () =>
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      const url = getState().temp.appOpenUrl

      const _url = new URL(url)
      const urlParams = new URLSearchParams(_url.search)
      const authCode = urlParams.get("code") || ""

      // handleLoginCallback may be called multiple times
      // compare authCode in supplied url with previously saved one - if they're the same then handleLoginCallback
      // has already run so no need to do anything
      if (authCode === getState().temp.authCode) {
        return
      }

      if (!authCode) {
        log.warn('handleLoginCallback called with no authCode')        
        dispatch(setRedirectPath("/signin"))
        return
      }

      dispatch(setAuthCode(authCode))

      const api = services.api

      let loginStatus: UserCheckStatus

      try {
        const tokenResponse = await authGetCallbackTokenResponse(url)
        api.setAccessToken(tokenResponse.accessToken)
        dispatch(setNextTokenRefresh(tokenResponse.expiresIn))
        loginStatus = await initialiseLoginState(dispatch, getState, api)
        if (loginStatus === UserCheckStatus.NETWORK_FAILED) {
          api.setOnline(false)
        }
      } catch (err) {
        log.warn(`handleLoginCallback:${err}`)
        loginStatus = UserCheckStatus.LOGGED_OUT
      }

      const user = getState().app.user
      if (!user || loginStatus !== UserCheckStatus.LOGGED_IN_NEW) {
        dispatch(setAlert("Login Error", "Unable to log in"))
        dispatch(setRedirectPath("/signin"))
        return
      }

      dispatch(setIsNewLogin(true))

      dispatch(initialiseGlobalResourceUpdate())

      // parameters may be refreshed periodically, but always read at startup / login
      await requestResources("parameters", {})(dispatch, getState, services)

      if (agreeTermsRequired(getState)) {
        dispatch(setRedirectPath("/accept-terms"))
      } else {
        dispatch(initialiseSigninState())
      }
    }

const initialiseLoginState = async (
  dispatch: AppDispatch,
  getState: () => RootState,
  api: ApiService
) => {
  let authUser = null

  try {
    authUser = await authGetUser()

    const getUserResponse = await api.getUpdateUser()
    switch (getUserResponse.result) {
      case ApiResult.SUCCESS:
        // Got good response from 'get-update-user' - login successful
        // Update user record in state with data received from get-user
        const userData = getUserResponse.responseData
        if (userData) {
          log.info(
            `initialiseLoginState - new user ${userData.user?.firstName} ${userData.user?.lastName} logged in, got record from api`
          )

          dispatch(
            setUser({
              user: userData.user,
              permissions: userData.permissions,
              sub: authUser.sub
            })
          )

          // Turn on any logging specified for this user
          setLogLevels(userData.user.logLevels)

          // Write any buffered log entries to back end
          saveBufferedLogs()

          return UserCheckStatus.LOGGED_IN_NEW
        } else {
          log.warn(
            `initialiseLoginState  - getUpdateUser failed to get user data`
          )
        }
        break

      case ApiResult.NETWORK_ERROR:
        log.info(`initialiseLoginState  - network error calling getUpdateUser`)
        if (getState().app.user) {
          // If we still have a user in the store, assume that user is still logged in but we're off line
          return UserCheckStatus.NETWORK_FAILED
        }
        break

      default:
        throw new Error("Unknown error calling getUpdateUser")
    }
  } catch (err) {
    log.warn(`initialiseLoginState :${err}`)
  }

  dispatch(setAlert("Login Failed", ""))
  if (getState().app.user || authUser) {
    // If login process failed, but we still have a user record, logout to ensure everything is cleared / reset
    dispatch(logout())
  }
  return UserCheckStatus.LOGGED_OUT
}
