import { Redirect, useLocation } from 'react-router-dom';
import { useEffect } from 'react'

import { useAppSelector, useAppDispatch } from '../redux/hooks'

import { setCurrentPath, setLastPath, setRedirectPath } from '../redux/slices/temp'

interface RouteChangerProps {
  noAuthRoutes?: string[],
  loginRoute: string
}

const RouteChanger: React.FC<RouteChangerProps> = (props) => {

  const dispatch = useAppDispatch()
  const location = useLocation()

  const user = useAppSelector((state) => state.app.user)
  const { startupInProgress, currentPath, redirectPath, lastPath } = useAppSelector((state) => state.temp)

  useEffect(() => {

    //console.log(`routeChanger useEffect, currentPath:${currentPath}, redirectPath:${redirectPath}, lastPath:${lastPath}`)    

    // update current path in store
    if (currentPath !== location.pathname) {
      dispatch(setCurrentPath(location.pathname))
    }

    if (startupInProgress) {
      return
    }

    if (!user) {
      // No user logged in:
      // Check the route we are on or are transitioning to to determine whether it is 'no auth'
      // (can be accessed without authentication)
      const testPath = redirectPath ? redirectPath : location.pathname

      let isNoAuthRoute = (testPath.search(props.loginRoute) === 0)

      if (props.noAuthRoutes) {
        props.noAuthRoutes.forEach(route => {
          if (testPath.search(route) === 0) {
            isNoAuthRoute = true
          }
        })
      }
      if (!isNoAuthRoute) {
        // TODO: save existing route to store to return to after login?
        dispatch(setRedirectPath(props.loginRoute))
      }
    }

    if (redirectPath && location.pathname !== redirectPath && lastPath !== location.pathname) {
      dispatch(setLastPath(location.pathname))
    }

    if (redirectPath && location.pathname === redirectPath) {
      dispatch(setRedirectPath(''))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location, currentPath, redirectPath, startupInProgress])

  if (redirectPath && location.pathname !== redirectPath) {
    // 'push=true' allows use of back button
    // - don't want to be able to return to splash or callback paths via back button
    const doPush = (location.pathname !== '/splash' && location.pathname.search('/callback') !== 0)

    //console.log(`routeChanger redirecting to:${redirectPath}, doPush:${doPush}`)

    return <Redirect push={doPush} to={redirectPath} />
  }
  return (<></>)
};

export default RouteChanger