/**
 * State management specific to 'resources' slice (e.g. local cache of backend db)
 * 
 * json-api-normalizer: see https://www.smashingmagazine.com/2017/05/json-api-normalizer-redux/
 * 
 * (@disruptph version is same library re-exported with typescript definitions)
 */

import { NormalizedData, ResourceData } from '@disruptph/json-api-normalizer'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface LocalResourceData extends ResourceData {
    localId: number,
    lastRequestedDate: string
}

export interface ResourceMap { [key: string]: LocalResourceData }

export interface ResourcesState {
    companies: ResourceMap
    sites: ResourceMap
    parameters: ResourceMap
    projects: ResourceMap
    projectSites: ResourceMap
    docInfo: ResourceMap
    signinDocs: ResourceMap
    signins: ResourceMap
    users: ResourceMap
    siteContacts: ResourceMap
    siteUsers: ResourceMap
    ppeTypes: ResourceMap
    sitePpe: ResourceMap
    hazardCategories: ResourceMap
    controls: ResourceMap
    hazardTypes: ResourceMap
    hazardTypeControls: ResourceMap
    jobCategories: ResourceMap
    taTemplates: ResourceMap
    tas: ResourceMap
    taWorkers: ResourceMap
    siteHazards: ResourceMap    
    toolboxes: ResourceMap
    tbAttendees: ResourceMap
    tbSiteHazards: ResourceMap
    tbObservations: ResourceMap
    tbIncidents: ResourceMap
    tbTasks: ResourceMap
    tbTas: ResourceMap
    tbOther: ResourceMap
    inductions: ResourceMap
    tasks: ResourceMap
    siteObservations: ResourceMap
    siteObservationActions: ResourceMap
    incidents: ResourceMap
    incidentWorkers: ResourceMap
    incidentActions: ResourceMap
    notifiableWork: ResourceMap
    highRiskWork: ResourceMap
}

export type ResourceType = keyof ResourcesState

const initialState: ResourcesState = {
    companies: {},
    sites: {},
    parameters: {},
    projects: {},
    projectSites: {},
    docInfo: {},
    signinDocs: {},
    signins: {},
    users: {},
    siteContacts: {},
    siteUsers: {},
    ppeTypes: {},
    sitePpe: {},
    hazardCategories: {},
    controls: {},
    hazardTypes: {},
    hazardTypeControls: {},
    jobCategories: {},
    taTemplates: {},
    tas: {},
    taWorkers: {},
    siteHazards: {},
    toolboxes: {},
    tbAttendees: {},
    tbSiteHazards: {},
    tbObservations: {},
    tbIncidents: {},
    tbTasks: {},
    tbTas: {},
    tbOther: {},
    inductions: {},
    tasks: {},
    siteObservations: {},
    siteObservationActions: {},
    incidents: {},
    incidentWorkers: {},
    incidentActions: {},
    notifiableWork: {},
    highRiskWork: {},
}

const allowedResourceTypes = Object.keys(initialState)

export const resources = createSlice({
    name: 'resources',
    initialState,
    reducers: {

        /**
         * Set / replace a whole resource type map (will be called when initialising state from persistent storage)
         * 
         */
        setResourceType(state, action: PayloadAction<{ resourceType: string, resourceMap: ResourceMap }>) {
            const resourceType = action.payload.resourceType
            if (allowedResourceTypes.includes(resourceType)) {
                state[resourceType as keyof typeof state] = action.payload.resourceMap
            }
        },

        /**
         * Delete a whole resource type map (will be called when purging non-public data on logout) 
         */
        removeResourceType(state, action: PayloadAction<string>) {
            const resourceType = action.payload
            if (allowedResourceTypes.includes(resourceType)) {
                state[resourceType as keyof typeof state] = {}
            }
        },

        /**
         * Add or update one or more individual resources (will typically be called to update state with new resource data received from API)
         * 
         */
        setResource(state, action: PayloadAction<NormalizedData>) {

            const resourceTypes = Object.keys(action.payload)

            for (const resourceType of resourceTypes) {
                // Check incoming resource data matches something we expect
                if (allowedResourceTypes.includes(resourceType)) {
                    state[resourceType as keyof typeof state] = {
                        ...state[resourceType as keyof typeof state],
                        ...action.payload[resourceType] as { [key: string]: LocalResourceData }
                    }
                }
            }
        },

        removeResource(state, action: PayloadAction<{ resourceType: ResourceType, id: string }>) {
            const existingResourceType = state[action.payload.resourceType]
            if (existingResourceType) {
                delete state[action.payload.resourceType][action.payload.id]
            }
        },

        setResourceRequestedDate(state, action: PayloadAction<{ resourceType: ResourceType, id: string, requestedDate:string }>) {
            const resourceMap = state[action.payload.resourceType]
            if (resourceMap) {
                const resource = resourceMap[action.payload.id]
                if (resource) {
                    state[action.payload.resourceType][action.payload.id] = {
                        ...resource,
                        lastRequestedDate: action.payload.requestedDate
                    }
                }
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const { setResourceType, removeResourceType, setResource, removeResource, setResourceRequestedDate } = resources.actions

export default resources.reducer