import { createSelector } from '@reduxjs/toolkit'
import { getResource, getResources, listIncludesResource } from '../../datastore'
import { SiteObservation, SiteObservationAction } from '../../datastore/models'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'

const selectResources = selectResourcesMemo(['sites', 'siteObservations'])
const selectParams = selectParamsMemo()
/**
 * Empty params object = return all observations
 * params.open = return all open observations
 * params.list = return observations with specified ids
 */
export const selectSiteObservations = createSelector([selectResources, selectParams], (resources, params) => {

    let siteObservations = getResources<SiteObservation>(resources, 'siteObservations', {
        periodFilter: { attr: 'observationDate', periodStart: params.periodStart, periodEnd: params.periodEnd },
        eqFilter: params.siteId?{ site: params.siteId } :{},
        inFilter: params.siteIds?{ site: params.siteIds } :{}
    })
    
    if (params.open || params.checklist) {
        return siteObservations.reduce((acc:SiteObservation[], observation) => {
            if ((params.open && (!observation.observerClosedDate || !observation.supervisorClosedDate)) ||
                (params.list && listIncludesResource(params.list, observation))) {
                return acc.concat([observation])
            }
            return acc
        },[])
    }
    return siteObservations
})

const selectSiteObservationsWithActionsResources = selectResourcesMemo(['siteObservations', 'siteObservationActions'])
const selectSiteObservationsWithActionsParams = selectParamsMemo()
export const selectSiteObservationsWithActions = createSelector([
    selectSiteObservations,
    selectSiteObservationsWithActionsResources,
    selectSiteObservationsWithActionsParams], (observations, resources, params) => {

    return observations.map(observation => ({
        observation,
        actions: getResources<SiteObservationAction>(resources, 'siteObservationActions', {eqFilter: {observation: observation.id}})
    }))
})

const selectObservationParams = selectParamsMemo()

/**
 * Get single observation by id
 * 
 */
export const selectSiteObservation = createSelector([selectResources, selectObservationParams], (resources, params) => {
    return getResource<SiteObservation>(resources.siteObservations, params.siteObservationId)
})