import { AppDispatch, RootState } from '../../redux/store'
import { setAlert } from '../../utils/alert'
import { saveTa } from './saveTa'
import { setTaEditId, setTaRevision, setTaSuperseded, setTaSupersedes, setTaWorkers } from '../../redux/slices/pgTaskAnalysis'
import { selectTaEditLocked } from '../../redux/selectors/tas'
import { TaEvent, updateSequence } from './taskAnalysisSequence'

export const editTa = () => (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState()
    const taEdit = state.pgTaskAnalysis.taEdit

    if (selectTaEditLocked(state)) {
        dispatch(setAlert(
            'SWMS Locked',
            'This SWMS has already been signed. Editing it will create a new revision and workers will need to sign on again'
            , [{
                text: 'Cancel',
                role: 'cancel',
                handler: () => true
            },
            {
                text: "Continue",
                handler: () => {
                    // Save SWMS as 'superseded'
                    dispatch(setTaSuperseded(true))
                    dispatch(saveTa())

                    // Now working on revised SWMS - no longer superseded
                    dispatch(setTaSuperseded(false))
                    // Save existing id as what the new SWMS will supersede
                    if (taEdit.id) {
                        dispatch(setTaSupersedes(taEdit.id))
                    }
                    // Remove existing id so that SWMS will be saved as a new version
                    dispatch(setTaEditId(undefined))
                    // Increment revision
                    const oldRevision = getState().pgTaskAnalysis.taEdit.revision
                    dispatch(setTaRevision((oldRevision || 0) + 1))

                    // Reset workers to new, unsigned versions
                    dispatch(setTaWorkers(taEdit.workers.map(worker => ({
                        company: worker.company,
                        user: worker.user,
                        isSupervisor: worker.isSupervisor
                    }))))
                    // Save updated ta
                    dispatch(saveTa())

                    dispatch(updateSequence(TaEvent.EDIT_SELECTED))
                    return true
                }
            }]))
    } else {
        dispatch(updateSequence(TaEvent.EDIT_SELECTED))
    }
}
