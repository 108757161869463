import { IonIcon, IonItem, IonItemDivider, IonList } from "@ionic/react"
import { chevronBackOutline, folderOutline, lockClosed } from "ionicons/icons"
import { CategoryContents } from "../redux/selectors/categories"

interface CategorySelectorProps {
    categoryPath: { id: number | null, categoryText: string }[]
    categoryContents: CategoryContents
    selectedItemId?: number
    setSelectedCategory: (categoryId: number | null) => void
    setSelectedItem: (itemId: number | null) => void
}

const CategorySelector: React.FC<CategorySelectorProps> = ({ categoryPath, categoryContents, selectedItemId, setSelectedCategory, setSelectedItem }) => {

    if (!categoryPath.length) {
        return (<></>)
    }

    const category = categoryPath[categoryPath.length - 1]
    const parent = categoryPath.length > 1 ? categoryPath[categoryPath.length - 2].id : null

    return (
        <>
            {category.categoryText ?
                <div className="cat-select-level" key={category.id}>
                    {categoryPath.length > 1 ? <IonIcon icon={chevronBackOutline}></IonIcon> : <></>}

                    <h3 onClick={() => setSelectedCategory(parent)}>
                        {category.categoryText}
                    </h3>
                </div>
                : <></>
            }

            <IonItemDivider />

            <IonList>
                {categoryContents.subcategories!.map((subCat) => (
                    <IonItem key={subCat.id}>

                        <div className="cat-select-level item" key={subCat.id}>
                            <IonIcon icon={folderOutline}></IonIcon>
                            <div onClick={() => setSelectedCategory(subCat.id)}>
                                {subCat.categoryText}
                            </div>
                        </div>
                    </IonItem>
                ))}

                {categoryContents.items!.map((item) => (

                    <IonItem key={item.id}>
                        <div className="cat-select-level item" key={item.id}>
                            <img alt='' className="item-icon" src={item.id === selectedItemId ? "./assets/icon/item-icon-tick.png" : "./assets/icon/item-icon.png"} />                            
                            {/*<img className="item-icon" src={"./assets/icon/item-icon.png"} />*/}
                            <div onClick={() => setSelectedItem(+item.id)}>
                                {item.itemText}
                            </div>
                            {item.readOnly
                                ? <>&nbsp;<IonIcon icon={lockClosed}></IonIcon></>
                                : <></>
                            }
                        </div>
                    </IonItem>
                ))}
            </IonList>
        </>)
}

export default CategorySelector