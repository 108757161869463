import { AppDispatch, RootState } from "../../redux/store"
import { setSelectedDocIndex, setSelectedDocTitle, setSigninDocCompleted } from "../../redux/slices/pgSignin"
import { updateSequence } from "./signinSequence"
import { SigninEvent } from "./signinTypes"


export const selectNextSigninDoc = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { docList, selectedDocIndex } = getState().pgSignin

    if (selectedDocIndex !== null) {

        dispatch(setSigninDocCompleted({ index: selectedDocIndex, value: true }))

        if (selectedDocIndex + 1 < docList.length) {
            dispatch(setSelectedDocIndex(selectedDocIndex + 1))
            dispatch(setSelectedDocTitle(docList[selectedDocIndex + 1].title))
        } else {        
            // User has viewed all documents they're required to - signin is complete
            dispatch(updateSequence(SigninEvent.PRESENT_DOCS_COMPLETE))
        }
    }
}