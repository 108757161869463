import { createSelector } from '@reduxjs/toolkit'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { getResource, getResources } from '../../datastore'
import { SiteContact, User } from '../../datastore/models'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'

const selectResources = selectResourcesMemo(['sites', 'siteContacts', 'users'])
const selectParams = selectParamsMemo()
export const selectSiteContacts = createSelector([selectResources, selectParams], (resources, params) => {

    const siteContacts = getResources<SiteContact>( resources, 'siteContacts', { eqFilter: { site: params.siteId } })
    return siteContacts.map(contact => {
        const user = getResource<User>(resources.users, contact.user)
        return {
            ...contact,
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            phone: user?.phone || '',
            email: user?.email || '',
        }
    })
})