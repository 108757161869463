import { AppDispatch, RootState } from "../../redux/store"
import { requestResources } from "../../datastore"
import { setRequestComplete, setRequestStart } from "../../redux/slices/resourceMeta"
import { selectSiteContacts } from "../../redux/selectors/siteContacts"


export const requestSiteContacts = (params: { [key: string]: any }) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const { siteId, requestKey } = params

    if (requestKey) {
        dispatch(setRequestStart({ requestKey }))
    }
    const result = await requestResources('siteContacts', { eqFilter: { site: siteId }, include: ['user'] })(dispatch, getState, services)

    if (requestKey) {
        dispatch(setRequestComplete({ requestKey, resourceEvent: result}))
    }
    return result
}

export const getSiteContacts = (params: { [key: string]: any }) => (dispatch: AppDispatch, getState: () => RootState) => {
    const siteId = params.siteId
    
    return {
        siteContacts: selectSiteContacts(getState(), { siteId })
    } 
}