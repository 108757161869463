import { IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import NavBar from '../components/NavBar';
import ActivityCard from '../components/ActivityCard';
import './Home.css';
import { setRedirectPath } from '../redux/slices/temp';
import { selectSignedinSiteId } from '../redux/selectors/signins';
import { selectSite } from '../redux/selectors/sites';
import { signoutWithConfirm } from '../features/signin/signout';
import { selectCanViewAny } from '../redux/selectors/abilities';
import { location } from 'ionicons/icons';

const Home: React.FC = () => {
    const dispatch = useAppDispatch()

    const signedinSiteId = useAppSelector((state) => selectSignedinSiteId(state))
    const site = useAppSelector(state => selectSite(state, { siteId: signedinSiteId || undefined }))
    const user = useAppSelector(state => state.app.user)
    const canViewAny = useAppSelector(selectCanViewAny)

    return (
        <IonPage>
            <IonHeader>
                <NavBar />
            </IonHeader>
            <IonContent fullscreen id="home-content">
                <IonGrid id="activities-grid" fixed={true}>
                    <IonRow>
                        {/* Special button */}
                        <IonCol class="full-width-card" sizeXl="4" sizeLg="4" sizeMd="12" sizeSm="12" size="12">
                            {site
                                ? <IonCard className="sign-out-card" button={true} onClick={() => dispatch(signoutWithConfirm(site?.siteName))}>
                                    <div className="container">
                                        <IonIcon className="location" icon={location}></IonIcon>
                                        <div className="scan-in-text">
                                            <h1>{site.siteName}</h1>
                                            <p>Tap here to sign out</p>
                                        </div>
                                    </div>
                                </IonCard>
                                : <IonCard className="sign-in-card" button={true} onClick={() => dispatch(setRedirectPath('/signin'))}>
                                    <div className="container">
                                        <img alt="camera" src="./assets/ui_images/camera_temp.svg"></img>
                                        <div className="scan-in-text">
                                            <h1>SITE SCAN IN</h1>
                                            <p>Tap here to scan into a site</p>
                                        </div>
                                    </div>
                                </IonCard>
                            }
                        </IonCol>
                        {// Hide most functions for guests and others without any view permission (i.e. buildaprice users without access to safety)
                            user && (!user.isGuest  && canViewAny)
                                ? <>
                                    {/* Toolbox Talk */}
                                    <ActivityCard
                                        iconSrc="./assets/ui_images/speech_bubble.svg"
                                        cardTitle="Toolbox Talk"
                                        cardContent="Record site meetings"
                                        href="/toolbox" />
                                    {/* Site Observations */}
                                    <ActivityCard
                                        iconSrc="./assets/ui_images/magnifying_glass.svg"
                                        cardTitle="Site Observations"
                                        cardContent="Record site hazards"
                                        href="/observations" />
                                    {/* Assess Risk */}
                                    {/*
                                    <ActivityCard
                                        iconSrc="./assets/ui_images/alert_orange.svg"
                                        cardTitle="Assess Risk"
                                        cardContent="Identify hazards at work" />
                                    */}
                                    {/* SWMS */}                                     
                                    <ActivityCard
                                        iconSrc="./assets/ui_images/check_mark.svg"
                                        cardTitle="SWMS"
                                        cardContent="TA / JSA / JSEA"
                                        href="/task-analysis" />                                    
                                    {/* Incident Report */}
                                    <ActivityCard
                                        iconSrc="./assets/ui_images/plus.svg"
                                        cardTitle="Incident Report"
                                        cardContent="Record near misses, injuries & incidents"
                                        cardColor="var(--dangerBP)"
                                        href="/incidents" />
                                    {/* Vehicle Admin */}
                                    {/*
                                    <ActivityCard
                                        iconSrc="./assets/ui_images/vehicle.svg"
                                        cardTitle="Vehicle Admin"
                                        cardContent="Quick vehicle check" />
                                     */}
                                </>
                                : <></>}
                        {/* Documents */}
                        <ActivityCard
                            iconSrc="./assets/ui_images/document.svg"
                            cardTitle="Documents"
                            cardContent="Documents & Reports"
                            href="/documents" />
                        {/* Temp card
                    <ActivityCard
                        iconSrc="./assets/ui_images/clock.svg"
                        cardTitle="Splash"
                        cardContent="Splash screen"
                        cardColor="var(--successBP)"
                        href="/splash" /> */}
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Home;
