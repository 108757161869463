import { createSelector } from '@reduxjs/toolkit'
import { parseJSON } from 'date-fns'
import type { RootState } from '../../redux/store'
import { TaEdit } from '../slices/pgTaskAnalysis'
import { TaStep, TaStepState, TaHazard } from '../../datastore/models/Ta'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import Ta from '../../datastore/models/Ta'
import { getResource, getResources } from '../../datastore'
import { ProjectSite, TaWorker } from '../../datastore/models'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'
import { ResourceFilters } from '../../datastore/types'

export const validateHazard = (hazard: TaHazard): TaHazard => (
    {
        hazardTypeId: hazard?.hazardTypeId || null,          // number | null
        customHazardType: hazard?.customHazardType || '',    // string
        irConsequence: hazard?.irConsequence || 0,           // number
        irLikelyhood: hazard?.irLikelyhood || 0,             // number
        rrConsequence: hazard?.rrConsequence || 0,           // number
        rrLikelyhood: hazard?.rrLikelyhood || 0,             // number
        controls: hazard?.controls || [],                    // number[],
        customControls: hazard?.customControls || [],        // string[],
        needsReview: hazard?.needsReview || false,           // boolean
    }
)

export const validateStep = (step: TaStep): TaStep => {

    const hazards = step?.hazards ? step.hazards.map(hazard => validateHazard(hazard)) : []

    return (
        {
            text: step?.text || '',
            placeholder: step?.placeholder,
            hazards,
            state: step?.state ?? TaStepState.NONE,
        }
    )
}

const validateTa = (taEdit: TaEdit): TaEdit => {

    const steps = taEdit.userData.steps ? taEdit.userData.steps.map(step => validateStep(step)) : []

    return (
        {
            id: taEdit.id,
            company: taEdit.company,
            project: taEdit.project,
            site: taEdit.site,
            scheduledTask: taEdit.scheduledTask,
            creator: taEdit.creator,
            startDate: taEdit.startDate,

            draft: taEdit.draft,
            revision: taEdit.revision,
            superseded: taEdit.superseded,
            supersedes: taEdit.supersedes,
            userData: {
                name: taEdit.userData.name,
                description: taEdit.userData.description,
                steps,
                ppe: taEdit.userData.ppe || [],
                customPpe: taEdit.userData.customPpe || [],
            },
            workers: taEdit.workers || []
        })
}

const selectRawTaEdit = (state: RootState) => state.pgTaskAnalysis.taEdit
const selectSelectedStepIndex = (state: RootState) => state.pgTaskAnalysis.selectedStepIndex
const selectSelectedHazardIndex = (state: RootState) => state.pgTaskAnalysis.selectedHazardIndex

/**
 * 
 * The following selectors return validated versions of stepEdit and taEdit, i.e. enforcing that all required
 * fields are present. The shape of the ta object may change over time, and we may 
 * load an out of date version from persistent storage following an app update - don't want to crash because of missing fields
 * 
 * Use these rather than selecting state.pgTaskAnalysis.stepEdit and state.pgTaskAnalysis.taEdit directly
 */

/**
 * Select 'taEdit' buffer with validation that it contains correct fields
 */
export const selectTaEdit = createSelector([selectRawTaEdit], (taEdit): TaEdit => {
    return validateTa(taEdit)
})

/**
 * Select step currently being edited, with validation that it contains correct fields
 */
export const selectStepEdit = createSelector([selectTaEdit, selectSelectedStepIndex], (taEdit, selectedStepIndex) => {

    const rawStepEdit = taEdit.userData.steps[selectedStepIndex]
    return rawStepEdit ? validateStep(rawStepEdit) : null
})

/**
 * Select hazard currently being edited with validation that it contains correct fields
 */
export const selectHazardEdit = createSelector([selectStepEdit, selectSelectedHazardIndex], (stepEdit, selectedHazardIndex) => {
    if (stepEdit) {
        const rawHazardEdit = stepEdit.hazards[selectedHazardIndex]
        if (rawHazardEdit) {
            return validateHazard(rawHazardEdit)
        }
    }
    return null
})

const selectTaResources = selectResourcesMemo(['tas', 'taWorkers'])
const selectTaParams = selectParamsMemo()

export const selectTa = createSelector([selectTaResources, selectTaParams], (resources, params): TaEdit | null => {

    const flattenedTa = getResource<Ta>(resources.tas, params.taId)

    if (flattenedTa) {
        const userData = flattenedTa.userData
        const workers = getResources<TaWorker>(resources, 'taWorkers', { eqFilter: { ta: flattenedTa.id } })

        const ta = {
            id: flattenedTa.id || undefined,
            company: flattenedTa.company,
            project: flattenedTa.project,
            site: flattenedTa.site,
            scheduledTask: flattenedTa.scheduledTask,
            creator: flattenedTa.creator,
            startDate: flattenedTa.startDate,
            draft: flattenedTa.draft,
            revision: flattenedTa.revision,
            superseded: flattenedTa.superseded,
            supersedes: flattenedTa.supersedes,
            userData: {
                name: userData.name,
                description: userData.description,
                steps: userData.steps,
                ppe: userData.ppe,
                customPpe: userData.customPpe,
            },
            workers: workers
        } as TaEdit

        return validateTa(ta)
    }
    return null
})

const selectActiveTasResources = selectResourcesMemo(['sites', 'projects', 'projectSites', 'tas'])
const selectActiveTasParams = selectParamsMemo()
export const selectActiveTas = createSelector([selectActiveTasResources, selectActiveTasParams], (resources, params) => {

    const { siteId, siteIds, projectId, projectSiteId } = params

    const periodFilter = { attr: 'startDate', periodStart: params.periodStart, periodEnd: params.periodEnd }
    const projectSite = getResource<ProjectSite>(resources.projectSites, projectSiteId)

    let eqFilter: ResourceFilters['eqFilter'] = {}
    if (siteId) {
        eqFilter.site = siteId
    }
    if (projectId) {
        eqFilter.project = projectId
    }
    if (projectSiteId) {
        eqFilter.site = projectSite?.site
        eqFilter.project = projectSite?.project
    } 
    if (siteIds) {
        return getResources<Ta>(resources, 'tas', { periodFilter, neqFilter: { draft: true, superseded: true }, eqFilter, inFilter: { site:siteIds } })    
    }   
    return getResources<Ta>(resources, 'tas', { periodFilter, neqFilter: { draft: true, superseded: true }, eqFilter })
})


const selectDraftTasResources = selectResourcesMemo(['projectSites', 'users', 'tas'])
const selectDraftTasParams = selectParamsMemo()
export const selectDraftTas = createSelector([selectDraftTasResources, selectDraftTasParams], (resources, params) => {

    const eqFilter: { [key: string]: number | boolean } = { draft: true }
    if (params.projectSiteId) {
        eqFilter.projectSite = params.projectSiteId
    }
    if (params.userId) {
        eqFilter.creator = params.userId
    }

    return getResources<Ta>(resources, 'tas', { eqFilter })
        .map(ta => {
            const lastModifiedDate = parseJSON(ta.lastModifiedDate)
            return {
                ...ta,
                lastModifiedDate,
            }
        })
        .sort((a, b) => a.lastModifiedDate.getTime() - b.lastModifiedDate.getTime())
})

/*
use this if we decide to unify the above...
const selectTasResources = selectResourcesMemo(['projectSites', 'users','tas'])
const selectTasParams = selectParamsMemo()
export const selectTas = createSelector([selectTasResources, selectTasParams], (resources, params) => {

    const eqFilter: { [key: string]: number | boolean } = { draft: true }
    if (params.projectSiteId) {
        eqFilter.projectSite = params.projectSiteId
    }
    if (params.userId) {
        eqFilter.creator = params.userId
    }

    return getResources<Ta>(resources, 'tas', { eqFilter })
        .map(ta => {
            const lastModifiedDate = parseJSON(ta.lastModifiedDate)
            return {
                id: ta?.id,
                lastModifiedDate,
                text: `${format(lastModifiedDate, 'dd MMM yyyy HH:mm:ss')}:${_.get(ta, 'userData.name')}`
            }
        })
        .sort((a, b) => a.lastModifiedDate.getTime() - b.lastModifiedDate.getTime())
})
*/
export const selectTaEditFullySignedOff = (state: RootState) => {
    const taEdit = state.pgTaskAnalysis.taEdit

    return !taEdit.draft && !taEdit.workers.filter(worker => !worker.signature).length
}

export const selectTaEditLocked = (state: RootState) => {
    const taEdit = state.pgTaskAnalysis.taEdit

    return !taEdit.draft && taEdit.workers.filter(worker => worker.signature).length
}