import _, { uniq } from "lodash"
import { getLogger } from "loglevel"
import {
  setRequestComplete,
  setRequestStart
} from "../redux/slices/resourceMeta"
import { ResourceType } from "../redux/slices/resources"
import { AppDispatch, RootState } from "../redux/store"
import { getResources, GetResourcesOptions } from "./getResources"
import { getRelationNames } from "./json-api/helpers/getRelationNames"
import { requestResources, RequestResourcesOptions } from "./requestResources"
import { resourceEventCombined } from "./resourceEventCombined"
import { ResourceEvent } from "./types"

const log = getLogger("resourceRequests")

/**
 * Request records for a specified resource, and then request records of other specified resource types that are related 
 * to the base records returned
 * 
 * @param resourceType Base resource
 * @param opts Request options for base resource
 * @param relatedTypes Array of related types. Use dot notation for cascaded relationships, 
 * e.g. [projectSites.sites] = get related projectSite records, plus site records related to those
 * @returns 
 */
export const requestResourcesWithRelated =
  (
    resourceType: ResourceType,
    opts: RequestResourcesOptions,
    relatedTypes: string[]
  ) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    services: any
  ): Promise<ResourceEvent> => {
    const baseReqOpts = Object.assign({}, opts)
    let requestVersion
    if (opts.requestKey) {
      // If a request key was supplied, use it to track full set of requests (i.e. don't use for base resource request)
      delete baseReqOpts.requestKey

      // console.log('requestResourcesWithRelated setRequestStart-requestKey:', opts.requestKey, 'resourceType:', resourceType)

      dispatch(setRequestStart({ requestKey: opts.requestKey }))
      // Record the current 'request version' for this request key
      // (this will tell us if the same request gets made again before this one completes)
      requestVersion = _.get(
        getState().resourceMeta,
        ["activeRequests", opts.requestKey, "version"],
        0
      )
    }

    const baseReqResult = await requestResources(resourceType, baseReqOpts)(
      dispatch,
      getState,
      services
    )

    // If called with no related resources specified, or request for base resource failed, finish at this point
    if (
      !relatedTypes ||
      !relatedTypes.length ||
      baseReqResult === ResourceEvent.RESOURCE_LOAD_ERROR
    ) {
      if (opts.requestKey) {
        dispatch(
          setRequestComplete({
            requestKey: opts.requestKey,
            resourceEvent: baseReqResult
          })
        )
      }
      return baseReqResult
    }

    const baseResources = getResources(
      getState().resources,
      resourceType,
      opts as GetResourcesOptions
    )
    const resourceIds = baseResources.map((resource) => +(resource as any).id)

    const results = await Promise.all(
      relatedTypes.map(async (relationTypePath) => {
        const pathSections = relationTypePath.split(".")
        if (!pathSections.length) {
          return ResourceEvent.RESOURCE_LOAD_ERROR
        }
        const relationType = pathSections[0]
        const relatedRelationTypes =
          pathSections.length > 1 ? pathSections.slice(1).join(".") : null

        // Look for a relationship defined on the related resource that points to the base resource
        // ('1 to many' relationship for base resource)
        const relationNames = getRelationNames(relationType, resourceType, true)
        if (relationNames.length) {
          // Unlikely to be more than one relationship for a given resource type in this case, but
          // use Promise.all for consistency
          return Promise.all(
            relationNames.map((relationName) => {
              // If getting related resources for a single base resource, use 'eq' rather than 'in' filter
              // (helps caching logic to know when to use an incremental request)
              const relatedOpts =
                resourceIds.length === 1
                  ? { eqFilter: { [relationName]: resourceIds[0] } }
                  : { inFilter: { [relationName]: resourceIds } }

              return relatedRelationTypes
                ? requestResourcesWithRelated(
                    relationType as ResourceType,
                    relatedOpts,
                    [relatedRelationTypes]
                  )(dispatch, getState, services)
                : requestResources(relationType as ResourceType, relatedOpts)(
                    dispatch,
                    getState,
                    services
                  )
            })
          )
        } else {
          // Look for a relationship defined on the base resource that points to the related resource
          // ('1 to 1' relationship)
          const relationNames = getRelationNames(
            resourceType,
            relationType,
            true
          )
          if (relationNames.length) {
            // There may be multiple relationNames here if the base resource has multiple relationships to the same
            // resource type
            const relatedResults = await Promise.all(
              relationNames.map((relationName) => {
                const relatedIds = uniq(
                  baseResources
                    .map(
                      (resource) =>
                        (resource as { [key: string]: any })[relationName]
                    )
                    .filter((id) => id)
                )
                // If getting single related resource, use 'eq' rather than 'in' filter
                // (helps caching logic to know when to use an incremental request)
                const relatedOpts =
                  relatedIds.length === 1
                    ? { eqFilter: { id: relatedIds[0] } }
                    : { inFilter: { id: relatedIds } }
                return relatedRelationTypes
                  ? requestResourcesWithRelated(
                      relationType as ResourceType,
                      relatedOpts,
                      [relatedRelationTypes]
                    )(dispatch, getState, services)
                  : requestResources(relationType as ResourceType, relatedOpts)(
                      dispatch,
                      getState,
                      services
                    )
              })
            )
            // Logically, related resources must exist - if any request does not return 'valid', treat as error
            return relatedResults.some(
              (result) => result !== ResourceEvent.RESOURCE_VALID
            )
              ? [ResourceEvent.RESOURCE_LOAD_ERROR]
              : [ResourceEvent.RESOURCE_VALID]
          } else {
            log.error(
              "requestResourcesWithRelated: unknown relation type ",
              relationType,
              " resourceType ",
              resourceType
            )
            return ResourceEvent.RESOURCE_LOAD_ERROR
          }
        }
      })
    )

    // 'results' is 2 dimensional array due to nested Promise.all above
    const combinedResults = ([] as ResourceEvent[]).concat(...results)
    const result = resourceEventCombined(
      ResourceEvent.RESOURCE_VALID,
      combinedResults
    )
    if (opts.requestKey) {
      // Check whether request version is the same as before making the request.
      // If not, then another request with the same requestKey was started after this one
      // -> in this case don't call setRequestComplete because we want calling process to see the result
      // of the more recent request
      if (
        requestVersion ===
        _.get(
          getState().resourceMeta,
          ["activeRequests", opts.requestKey, "version"],
          0
        )
      ) {
        dispatch(
          setRequestComplete({
            requestKey: opts.requestKey,
            resourceEvent: result
          })
        )
      } else {
        log.debug(
          "requestResourcesWithRelated: request key",
          opts.requestKey,
          " superceded by newer version"
        )
      }
    }
    return result
  }
