//import { Position, Geolocation } from '@capacitor/geolocation';
import React from 'react';

import {
    IonPage,
    IonHeader,
    IonContent,
} from '@ionic/react';

import { useAppSelector } from '../../redux/hooks'

import { stSIGNIN } from '../../features/signin/signinTypes'

import NavBar from '../../components/NavBar';
import AltNavBar from '../../components/AltNavBar';
import './Signin.css'
import './QRGuide.css'
import GetUser from './GetUser';
import EnterCode from './EnterCode';
import ScanQr from './ScanQr';
import SelectSigninType from './SelectSigninType';
import PresentDocs from './PresentDocs';
import Complete from './Complete';

const Signin: React.FC = () => {

    const { user } = useAppSelector((state) => state.app)
    const { stSignin, selectedDocTitle } = useAppSelector((state) => state.pgSignin)

/*    const [location, setLocation] = useState<Position>({
        timestamp: 0,
        coords: {
            latitude: 0,
            longitude: 0,
            accuracy: 0,
            altitudeAccuracy: null,
            altitude: null,
            speed: null,
            heading: null
        }
    });
*/
    function renderSwitch() {
        switch (stSignin) {

            case stSIGNIN.SCAN_QR:
                return <ScanQr />

            case stSIGNIN.ENTER_CODE:
                return <EnterCode />

            case stSIGNIN.CHECK_CODE:
                return (
                    <IonContent class="signin-page">
                        <div id="site-code-container">
                            <h1>Checking site code...</h1>
                        </div>
                    </IonContent>
                )

            case stSIGNIN.GET_USER:
                return <GetUser />

            case stSIGNIN.SELECT_SIGNIN_TYPE:
                return <SelectSigninType />

            case stSIGNIN.PRESENT_DOCS:
                return <PresentDocs />

            case stSIGNIN.COMPLETE:
                return <Complete/>                

            default:
                return '';
        }
    }

    return (
        <>
            <IonPage>
                <IonHeader>
                    {user && !user.isGuest
                        ? <NavBar
                            pageTitle={stSignin !== stSIGNIN.PRESENT_DOCS ? 'Site Sign In' : ''} 
                            pageTitleSmall={stSignin === stSIGNIN.PRESENT_DOCS ? selectedDocTitle : ''}
                          />
                        : <AltNavBar
                            pageTitle={stSignin === stSIGNIN.PRESENT_DOCS ? selectedDocTitle : ''}
                            hideBackButton={stSignin === stSIGNIN.SCAN_QR}
                          />
                    }
                </IonHeader>
                {renderSwitch()}
            </IonPage>
        </>
    );
};

export default Signin;

