import { RootState } from '../../redux/store'
import { LOCAL_ID_START } from '../../redux/slices/resourceMeta'
import { getResourceIds } from '../getResourceIds'
import { ResourceType } from '../../redux/slices/resources'
import { getRelationType } from '../json-api/helpers/getRelationType'
import { RequestResourcesOptions } from '../requestResources'

/**
 * Search request options filters for resource ids, mapping localIds to real Ids where available
 * 
 * @param opts 
 * @returns updated request options object, or null if no valid request to make (i.e. local-only ids found)
 */
export const idMapFilters = (resourceType: ResourceType, opts: RequestResourcesOptions, getState: () => RootState) => {

    const { eqFilter, inFilter } = opts || {}

    const state = getState()

    let doRequest = true

    const mappedEqFilter = eqFilter ? Object.keys(eqFilter).reduce((acc: { [key: string]: number | string | boolean | null | undefined }, key) => {
        if (key === 'id') {
            if (eqFilter[key] === 'latest') {
                acc.id = 'latest'
            } else {
                // Find 'real' id
                const filterId = ((typeof eqFilter[key] === 'number') ? eqFilter[key] : undefined) as (number | undefined)
                const mappedId = getResourceIds(state.resources[resourceType], filterId).keyId
                acc.id = mappedId && mappedId < LOCAL_ID_START ? mappedId : null
            }
        } else {
            const relationType = getRelationType(resourceType, key)
            if (relationType) {
                if (eqFilter[key] === null) {
                    // preserve relationships where id=null - may be used to get items not having that relationship
                    acc[key] = null

                } else {
                    const filterId = ((typeof eqFilter[key] === 'number') ? eqFilter[key] : undefined) as (number | undefined)
                    const mappedId = getResourceIds(state.resources[relationType as ResourceType], filterId).keyId
                    if (mappedId && mappedId < LOCAL_ID_START) {
                        acc[key] = mappedId
                    } else {
                        // if we find any relation id that's local-only, then the resource we're filtering has to also be local-only
                        // -> no point in making request 
                        doRequest = false
                    }
                }
            } else {
                // key is not id or a relationship - must be attribute, return unchanged
                acc[key] = eqFilter[key]
            }
        }
        return acc
    }, {}) : undefined
    if (mappedEqFilter && (mappedEqFilter.id === null || !doRequest)) {
        // eqFilter contained 'id' but value did not map to realId
        // -> no request to make
        return null
    }

    let allInFilterIdsReal = true
    const mappedInFilter = inFilter ? Object.keys(inFilter).reduce((acc: { [key: string]: (number | string | null | undefined)[] }, key) => {
        if (key === 'id') {
            // Find ids in inFilter.id array that we have 'real' ids for
            const mappedIds = inFilter[key].reduce((acc: number[], id) => {
                const filterId = ((typeof id === 'number') ? id : undefined) as (number | undefined)
                const mappedId = getResourceIds(state.resources[resourceType], filterId).keyId
                return (mappedId && mappedId < LOCAL_ID_START) ? acc.concat([mappedId]) : acc
            }, [])
            acc.id = mappedIds
            if (!mappedIds.length) {
                allInFilterIdsReal = false
            }

        } else {
            const relationType = getRelationType(resourceType, key)
            // key is a relationship...
            if (relationType) {
                // Find ids in inFilter array that we have 'real' ids for
                const mappedIds = inFilter[key].reduce((acc: number[], id) => {
                    const filterId = ((typeof id === 'number') ? id : undefined) as (number | undefined)
                    const mappedId = getResourceIds(state.resources[relationType], filterId).keyId
                    return (mappedId && mappedId < LOCAL_ID_START) ? acc.concat([mappedId]) : acc
                }, [])
                acc[key] = mappedIds
                if (!mappedIds.length) {
                    allInFilterIdsReal = false
                }

            } else {
                // key is not id or a relationship - must be attribute, return unchanged
                acc[key] = inFilter[key]
            }
        }
        return acc
    }, {}) : undefined

    if (mappedInFilter && !allInFilterIdsReal) {
        // At least one inFilter with no real ids present
        // -> no request to make
        return null
    }

    // If we got this far, request potentially has something to return
    // -> return updated options
    let mappedOpts = { ...opts }
    if (mappedEqFilter) mappedOpts.eqFilter = mappedEqFilter
    if (mappedInFilter) mappedOpts.inFilter = mappedInFilter

    return mappedOpts
}