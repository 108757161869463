import { ModalOptions, UseIonModalResult } from "@ionic/react"
import { HookOverlayOptions } from "@ionic/react/dist/types/hooks/HookOverlayOptions"
import { useReducer } from "react"

interface ModalList {
    [key: string]: {
        present: (options?: (Omit<ModalOptions, "component" | "componentProps"> & HookOverlayOptions) | undefined) => void
        dismiss: () => void
    }
}

/**
 * Modal Manager - allows one or more modals to be set up within a top-level component, and then invoked from child components.
 * This approach is useful for modals which, when dismissed, cause the underlying component to go out of scope, which otherwise throws an exception
 * 
 * Useage - returns three functions:
 * 
 * setModalFns: call with parameter 'modalKey' (a string to identify this modal by). 
 *  - Returns a function taking a parameter 'useIonModalResult' - this should be called with the object returned by useIonModal, containing present and dismiss
 *    functions for the new modal
 * 
 * presentModal: call to present modal (will typically be passed as prop to child component which will invoke the modal)
 * dismissModal: call to dismiss modal (not required unless the modal can be dismissed by external logic)
 *  
 * presentModal and dismissModal have a single modalKey parameter identifying the specific modal to present / dismiss
 */
export const useModalManager = () => {

    const [modals, setModal] = useReducer((state: ModalList, newModal: { modalKey: string, useIonModalResult: UseIonModalResult }) => {
        const [present, dismiss] = newModal.useIonModalResult
        return {
            ...state,
            [newModal.modalKey]: { present, dismiss }
        }
    }, {} as ModalList)

    return {
        setModalFns: (modalKey: string) => (useIonModalResult: UseIonModalResult) => setModal({ modalKey, useIonModalResult }),
        presentModal: (key: string) => {
            const modal = modals[key]
            if (modal) {
                modal.present()
            }
        },
        dismissModal: (key: string) => {
            const modal = modals[key]
            if (modal) {
                modal.dismiss()
            }
        }

    }
}