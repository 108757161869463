import React from "react"
import { addDays } from "date-fns"
import { IonButton, IonContent, IonFooter, IonItemDivider, useIonModal } from "@ionic/react"
import { useAppSelector, useAppDispatch } from '../../redux/hooks'

import SelectListInput from "../../components/form/SelectListInput"

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import DateInput from "../../components/form/DateInput"
import TextArea from "../../components/form/TextArea"

import CameraInput, { PhotoData } from "../../components/form/CameraInput"
import PhotoDisplay from "../../components/form/PhotoDisplay"
import { initISODateValidator } from "../../helpers/yupISODateValidator"
import { setAlert } from "../../utils/alert"

import { useHomeIconHandler } from "../../hooks/homeIconHandler"
import { homeIconAction } from "../../features/incidentReports/homeIconAction"
import { IncidentReportEvent } from "../../features/incidentReports/incidentReportsTypes"
import { useDetails1Resources } from "../../features/incidentReports/resourceRequests/useDetails1Resources"
import { updateSequence } from "../../features/incidentReports/incidentReportsSequence"
import { setIncidentReportData, setIncidentReportEdit } from "../../redux/slices/pgIncidentReports"
import { returnToStart } from "../../features/incidentReports/returnToStart"
import TimeInput from "../../components/form/TimeInput"
import IncidentTypeTile from "./IncidentTypeTile"
import Input from "../../components/form/Input"

import { WorksafeInfoModal } from './WorksafeInfoModal'
import RadioGroup from "../../components/form/RadioGroup"
import { YesNo } from "../../datastore/models/Incident"

import './IncidentReports.css'
import { openInBrowser } from "../../services/DocumentBrowser"

export type IncidentDetails1Edit = {
    site: number,
    incidentDate: string,
    description: string,
    photo?: PhotoData,
    worksafeNotified: YesNo,
    worksafeCase: string,
}

// Add yup validator for ISO string formatted dates
initISODateValidator()

const schema = yup.object().shape({
    site: yup.number().required('Please select a site'),
    incidentDate: yup.string().isISODate({ max: addDays(new Date(), 1), maxMsg: 'Date cannot be in the future' }),
    description: yup.string().required("Please describe what happened"),
    worksafeNotified: yup.string().required("Please select an option"),
    worksafeCase: yup.string().when('worksafeNotified', {
        is: 'Yes',
        then: yup.string().required("Please enter Worksafe case number"),
    })
}).required()

export const Details1: React.FC = () => {
    const dispatch = useAppDispatch()
    const { toolboxMode, toolboxSite, incidentReportEdit, dirty } = useAppSelector(state => state.pgIncidentReports)

    const { handleSubmit, watch, getValues, setValue, formState: { errors, isDirty } } = useForm<IncidentDetails1Edit>({
        resolver: yupResolver(schema),
        defaultValues: {
            site: incidentReportEdit.site,
            incidentDate: incidentReportEdit.incidentDate,
            description: incidentReportEdit.description,
            photo: incidentReportEdit.photo || undefined,
            worksafeNotified: incidentReportEdit.incidentData.worksafeNotified,
            worksafeCase: incidentReportEdit.incidentData.worksafeCase
        }
    })

    const { availableSites, reporter } = useDetails1Resources(incidentReportEdit.reporter)

    const formSubmitNext = (data: any) => {
        formSubmit(data)
        dispatch(updateSequence(IncidentReportEvent.NEXT_SELECTED))
    }

    const formSubmitHome = (data: any) => {
        formSubmit(data)
        dispatch(homeIconAction())
    }
    useHomeIconHandler('/incidents', handleSubmit(formSubmitHome))


    const formSubmit = (data: any) => {
        if (isDirty) {
            dispatch(setIncidentReportEdit({
                site: data.site,
                incidentDate: data.incidentDate,
                description: data.description,
                photo: data.photo,
            }))

            dispatch(setIncidentReportData({
                worksafeNotified: data.worksafeNotified,
                worksafeCase: data.worksafeCase,
            }))
        }
    }

    const cancel = () => {
        if (isDirty || dirty) {
            dispatch(setAlert(
                'Discard changes?',
                "You've made changes to this incident report, do you want to discard them?",
                [{
                    text: 'Continue with Report',
                    role: 'cancel',
                    handler: () => true
                },
                {
                    text: "Discard changes and Exit",
                    handler: () => {
                        dispatch(returnToStart())
                        return true
                    }
                }]
            ))
        } else {
            dispatch(returnToStart())
        }
    }

    const worksafeModalDismiss = () => {
        dismissWorksafeModal()
    }

    const [presentWorksafeModal, dismissWorksafeModal] = useIonModal(WorksafeInfoModal, { dismiss: worksafeModalDismiss })

    return (
        <>
            <IonContent class="content-padding">
                <form id="incident-details-form">
                    <h2>This incident happened at</h2>
                    <SelectListInput
                        className="modal-input"
                        title="Select site incident occurred at:"
                        placeholder="Site"
                        listData={availableSites?.map(site => ({ text: site.siteName, id: site.id })) || []}
                        fieldName="site"
                        watch={watch}
                        setValue={setValue}
                        error={errors.site}
                        // disable edit if:
                        // - only one site available
                        // - in toolbox mode for a specific site
                        // (site will be fixed to match toolbox meeting) 
                        disabled={(getValues('site') && (!availableSites || availableSites.length < 2)) || (toolboxMode && !!toolboxSite)}
                    />

                    <IonItemDivider />

                    <div id="date-time-container">
                        <span>on</span>
                        <div>
                            <DateInput
                                fieldName="incidentDate"
                                watch={watch}
                                setValue={setValue}
                                error={errors.incidentDate}
                                max={new Date().toISOString()}
                            />
                        </div>

                        <span>at</span>
                        <div>
                            <img src="./assets/ui_images/time-outline.svg" alt="clock icon" />
                        </div>
                        <div>
                            <TimeInput
                                fieldName="incidentDate"
                                watch={watch}
                                setValue={setValue}
                            />
                        </div>

                    </div>

                    <IonItemDivider />
                    <h3>Type of Incident:</h3>
                    <IncidentTypeTile type={incidentReportEdit.incidentType} />

                    {incidentReportEdit.incidentType === 'SeriousInjury' ||
                        incidentReportEdit.incidentType === 'SeriousIllness' ||
                        incidentReportEdit.incidentType === 'HiRiskIncident' ||
                        incidentReportEdit.incidentType === 'Death'
                        ? <>
                            <IonItemDivider />
                            <h3>Has Worksafe been Notified?</h3>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <h3>SERIOUS INCIDENTS ONLY - check <a onClick={() => presentWorksafeModal()}>HERE</a></h3>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <h3><a onClick={() => {
                                openInBrowser("https://www.worksafe.govt.nz/notify-worksafe")
                            }}>Notify Worksafe Online</a></h3>

                            <RadioGroup
                                className="flex row"
                                fieldName="worksafeNotified"
                                options={[
                                    { value: 'Yes', label: 'YES' },
                                    { value: 'No', label: 'NO' },
                                ]}
                                watch={watch}
                                setValue={setValue}
                                error={errors.worksafeNotified}
                            />

                            <Input
                                placeholder="If YES, add Case ID#"
                                fieldName="worksafeCase"
                                inputMode="text"
                                watch={watch}
                                setValue={setValue}
                                error={errors.worksafeCase}
                            />
                        </>
                        : <></>
                    }
                    <IonItemDivider />
                    <div className="flex row">
                        <h3>Reported by:&nbsp;</h3><h3 style={{ "fontWeight": "normal" }}>{`${reporter?.firstName} ${reporter?.lastName}`}</h3>
                    </div>

                    <TextArea
                        fieldName="description"
                        inputMode="text"
                        placeholder={"Describe what happened:"}
                        rows={2}
                        watch={watch}
                        setValue={setValue}
                        error={errors.description}
                    />

                    <CameraInput
                        fieldName="photo"
                        label="Add photo of incident"
                        watch={watch}
                        setValue={setValue}
                    />
                    <PhotoDisplay
                        fieldName="photo"
                        watch={watch}
                        setValue={setValue}
                    />

                </form>

            </IonContent>

            <IonFooter class="incidents-nav">
                <IonButton onClick={cancel}>Cancel</IonButton>
                <IonButton onClick={handleSubmit(formSubmitNext)}>Next</IonButton>
            </IonFooter>
        </>)
}
