import { isValid } from 'date-fns'

/**
 * 'safe' date to ISO string
 * 
 * Basically Date.toISOString with check for non-existent date
 * 
 * @returns ISO formatted date string or null if invalid or non existent date
 */

const dateToISOString = (date:Date | null | undefined) => {
    if (!date || !isValid(date)) {
        return null
    }
    return date.toISOString()
}

export default dateToISOString