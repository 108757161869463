import { Site } from "../../../datastore/models"

export const addressFull = (site?: Site | null) => {
  return site
    ? {
        address: site.address,
        addressNumber: site.addressNumber,
        street: site.street,
        suburb: site.suburb,
        city: site.city,
        region: site.region,
        postcode: site.postcode
      }
    : {
        address: "",
        addressNumber: "",
        street: "",
        suburb: "",
        city: "",
        region: "",
        postcode: ""
      }
}
