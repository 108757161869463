import { AppDispatch, RootState } from '../redux/store'
import { LoadStatus, setDocStatus } from '../redux/slices/docMeta'
import { localStore } from '../storage/storage'
import { DocData } from './types'
import { getResource } from '../datastore'
import { DocInfo } from '../datastore/models'
import log from 'loglevel'
import { getDocLoadStatus } from './getDocLoadStatus'

export interface GetDocResult extends DocData {    
    isError: boolean
}

export const getDoc = (id: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()

    // Default to an empty blob
    let result:GetDocResult = {
        docBlob: new Blob([]),
        docExt: '',
        isError:true
    }

    const docInfo = getResource<DocInfo>(state.resources.docInfo, id)
    if (!docInfo) {
        log.debug('getDoc unable to get docInfo record ', id)
        return result
    }

    const loadStatus = getDocLoadStatus(state.resources.docInfo, state.docMeta.docStatus, docInfo.id)
    if (loadStatus === null) {
        log.error('getDoc unable to get doc load status ', docInfo.id)
        return result
    }

    if (loadStatus === LoadStatus.COMPLETE || loadStatus === LoadStatus.SAVE_PENDING || loadStatus === LoadStatus.SAVING) {

        // If document is still in the process of being saved to backend, it may be stored locally under either 
        // 'real' id or localId
        // -> determine which based on what we find docStatus under
        const localStoreId = state.docMeta.docStatus[docInfo.id] ? docInfo.id : docInfo.localId

        const docBlob = await localStore.get(`docs/${localStoreId}`)
        if (docBlob !== null) {

            result = {
                docBlob: docBlob,
                docExt: docInfo.fileName.split('.').pop() as string,
                isError: false
            }

            // Determine localStoreId again just in case it moved while awaiting localStore.get
            const localStoreId = getState().docMeta.docStatus[docInfo.id] ? docInfo.id : docInfo.localId
            if (localStoreId) {
                // Update status 'lastUsed' date 
                dispatch(setDocStatus({
                    docId: localStoreId, docStatus: {
                        lastUsed: new Date().toISOString()
                    }
                }))
            }        

        } else {

            log.warn('getDoc unable to load file from local storage: ', docInfo.fileName)
            // If we failed to get document from storage, change it's status to 'LOAD_PENDING' so it will get requested again
            // TODO check this, may need to explicitly re-request?
            dispatch(setDocStatus({
                docId: docInfo.id, docStatus: {
                    loadStatus: LoadStatus.LOAD_PENDING
                }
            }))
        }
    }
    return result

    /*
    TODO: maybe load document directly from API if it previously couldn't be saved in local storage for some reason (out of space?)    
    */
}

