import { User } from "../../../datastore/models";

/**
 * Get just contact details from a user record 
 */
export const userContact = (user?: User | null) => (
    user ?
        {
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            phone: user.phone || '',
            email: user.email || ''
        }
        : {
            firstName: '',
            lastName: '',
            phone: '',
            email: ''
        }
)