import React, { useState } from 'react'
import { FieldError, UseFormWatch, UseFormSetValue } from "react-hook-form"
import { IonButton, IonContent, IonDatetime, IonInput, IonLabel, IonModal } from '@ionic/react'
import { parseJSON, format, isValid, formatISO, parseISO } from 'date-fns'
import './DateInput.css'
import parseDate from '../../helpers/parseDate'
import dateToISOString from '../../helpers/dateToIsoString'


const formatTimeDisplay = (dateTime?: Date | string | null) => {
    if (dateTime) {
        const dtDateTime = parseJSON(dateTime)
        if (isValid(dtDateTime)) {
            return format(dtDateTime, 'hh:mm aa')
        }
    }
    return ''
}

interface DateInputProps {
    id?: string
    label?: string,
    fieldName: string,
    watch: UseFormWatch<any>
    setValue: UseFormSetValue<any>
    error?: FieldError
    showClear?: boolean
}

const DateInput: React.FC<DateInputProps> = ({ id, label, fieldName, watch, setValue, error, showClear }) => {

    const formValue = watch(fieldName)
    const value = dateToISOString(parseDate(formValue))

    // Use 'Show Modal flag' approach
    // (would like to use useIonModal hook but won't work inside react hook form controller)
    const [showTimeModal, setShowTimeModal] = useState<boolean>(false)

    const [pickerDate, setPickerDate] = useState<string | null>(null)
    const [preEditDate, setPreEditDate] = useState<string | null>(null)

    const startEdit = () => {
        setPreEditDate(value)

        // Date picker doesn't have a 'no date selected' state. If supplied with a null value it will default to today's date
        // -> if 'value' not set, set picker explicitly to today's date so we have a copy of its' actual value
        const editDate = value || new Date().toISOString()
        // Date picker requres locally formatted ISO time (i.e. local time with offset, NOT utc)
        setPickerDate(formatISO(parseJSON(editDate)))
        setShowTimeModal(true)
    }

    const timeSelected = (event: CustomEvent) => {
        setPickerDate(event.detail.value)
    }

    const okSelected = () => {
        const formattedDate = pickerDate ? formatISO(parseISO(pickerDate)) : ''
        setValue(fieldName, formattedDate, { shouldDirty: true })
        setShowTimeModal(false)
    }

    const clearSelected = () => {
        setValue(fieldName, null, { shouldDirty: true })
        setShowTimeModal(false)
    }

    return (
        <div id={id}>
            <IonLabel class="oss-input-label">{label}</IonLabel>

            <IonInput
                className="oss-input"
                value={formatTimeDisplay(value)}
                style={error ? { borderColor: "red" } : {}}
                onClick={startEdit}
            >
            </IonInput>

            <IonModal backdropDismiss={false} isOpen={showTimeModal}>
                <IonContent className="date-select-container" force-overscroll="false">
                    <div className='safe-area-top'></div>
                    <IonDatetime
                        presentation="time"
                        value={pickerDate}
                        onIonChange={timeSelected}
                        minuteValues={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]}
                    />
                    <div className="flex centre-row">
                        {showClear ?
                            <IonButton onClick={clearSelected}>{preEditDate ? 'Clear' : 'Cancel'}</IonButton>
                            : <></>
                        }
                        <IonButton onClick={okSelected}>Ok</IonButton>
                    </div>
                </IonContent>
            </IonModal>

            <div className="oss-input-error-message">{error?.message}</div>
        </div>
    )
}

export default DateInput