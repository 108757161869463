import React from "react"
import { FieldError, UseFormWatch, UseFormSetValue } from "react-hook-form"
import { IonItem, IonRadio, IonRadioGroup } from "@ionic/react"
import "./RadioGroup.css"

interface InputProps {
  id?: string
  className?: string
  label?: string
  fieldName: string
  options: { value: string; label: string }[]
  watch: UseFormWatch<any>
  setValue: UseFormSetValue<any>
  error?: FieldError
}

const RadioGroup: React.FC<InputProps> = ({
  id,
  className,
  label,
  fieldName,
  options,
  watch,
  setValue,
  error
}) => {
  const value = watch(fieldName)

  return (
    <IonRadioGroup id={id} className={className} value={value}>
      <div>
        <div className="radio-group-container">
          {options.map((option) => (
            <IonItem key={option.value} lines="none">
              <IonRadio
                labelPlacement="end"
                value={option.value}
                onClick={(event) =>
                  setValue(fieldName, event.currentTarget.value, {
                    shouldDirty: true
                  })
                }
              >
                {option.label}
              </IonRadio>
            </IonItem>
          ))}
        </div>
        <div className="oss-input-error-message">{error?.message}</div>
      </div>
    </IonRadioGroup>
  )
}

export default RadioGroup
