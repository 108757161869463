import { get, template } from 'lodash'
import { differenceInDays, format, parseJSON } from 'date-fns'
import {formatInTimeZone } from 'date-fns-tz'

import { getRiskBand } from '../../helpers/riskScore'
import addImagePath from './renderHelpers/addImagePath'
import addAssetsPath from './renderHelpers/addAssetsPath'
import formatPhoneLink from './renderHelpers/formatPhoneLink'
import { embed } from './renderHelpers/embed'
import { formatAddress } from './renderHelpers/formatAddress'

export const populateTemplate = (docHtml: string, reportData: Record<string, any>, getImgData: (id: number) => string | null) => {

    // Add some helper functions to state data so that they're in scope if called from the template
    const templateData = {
        ...reportData,
        differenceInDays,                                             // from date-fns
        formatDate: format,                                           // from date-fns
        formatInTimeZone,                                             // from date-fns-tz
        parseJSON: parseJSON,                                         // from date-fns
        formatAddress,
        get: (path: string | string[]) => get(templateData, path),    // from lodash
        addImagePath,
        addAssetsPath,
        getRiskBand,
        getImgData,
        embed: (id:number) => embed(getImgData(id) || '', ''),
        phoneLink: formatPhoneLink
    }

    // Note this will throw an error if any problem rendering template
    // -> Handle in calling fn
    const templateFn = template(docHtml)
    return templateFn(templateData)
}