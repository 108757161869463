import { AppDispatch, RootState } from '../../redux/store'
import {
    initialiseToolboxAttendees,
    initialiseToolboxEdit,
    initialiseToolboxIncidents,
    initialiseToolboxObservations,
    initialiseToolboxSiteHazards,
    initialiseToolboxTas,
    initialiseToolboxTasks
} from '../../redux/slices/pgToolbox'
import { ToolboxEvent } from './toolboxTypes'
import { updateSequence } from './toolboxSequence'
import { selectSignedinSiteId } from '../../redux/selectors/signins'
import { requestResourcesWithRelated } from '../../datastore'
import { selectCanCreateToolbox } from '../../redux/selectors/abilities'
import { selectOpenProjectSites } from '../../redux/selectors/projectSites'
import log from 'loglevel'
import { hideLoadingSpinner, showLoadingSpinner } from '../../redux/slices/temp'
import { uniq } from 'lodash'

export const createToolbox = async (dispatch: AppDispatch, getState: () => RootState, services:any) => {

    let state = getState()
    const openDateTime = new Date().toISOString()
    const userId = state.app.user?.id
    const signedInSiteId = selectSignedinSiteId(state)

    dispatch(showLoadingSpinner('Loading...'))

    // Get projectSite records, and filter to those for projects this user has create permission for    
    await requestResourcesWithRelated('projects', { eqFilter: { status: 'OPEN' } }, ['projectSites.sites'])(dispatch, getState, services)
    state = getState()
    const projectSites = selectOpenProjectSites(state)
    const filteredProjectSites = projectSites.filter(projectSite => (!signedInSiteId || projectSite.site === signedInSiteId) && selectCanCreateToolbox(state, projectSite))

    let defaultProjectSite

    if (filteredProjectSites.length) {        
        if (filteredProjectSites.length === 1) {
            // If there is only one available projectSite, select it by default
            defaultProjectSite = filteredProjectSites[0]
        }
    } else {
        log.error('createToolbox:No projectSite with create permission found');
    }

    // If there is only one possible company, set it by default
    const companies = uniq(filteredProjectSites.map(projectSite => +projectSite.company))
    let companyId

    if (defaultProjectSite) {
        companyId = +defaultProjectSite.company
    } else if (companies.length === 1) {
        companyId = companies[0]
    }

    dispatch(initialiseToolboxEdit({
        company: companyId,
        project: defaultProjectSite?.project,
        site: signedInSiteId || 0,
        creator: userId,
        openDateTime,
    }))

    dispatch(initialiseToolboxAttendees([]))
    dispatch(initialiseToolboxSiteHazards([]))
    dispatch(initialiseToolboxObservations([]))
    dispatch(initialiseToolboxIncidents([]))
    dispatch(initialiseToolboxTasks([]))
    dispatch(initialiseToolboxTas([]))

    dispatch(hideLoadingSpinner())

    dispatch(updateSequence(ToolboxEvent.NEXT_SELECTED))
}

