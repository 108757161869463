import { createSelector } from '@reduxjs/toolkit'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { getResource, getResources } from '../../datastore'
import { PpeType, SitePpe } from '../../datastore/models'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'

const selectResources = selectResourcesMemo(['sites', 'sitePpe', 'ppeTypes'])
const selectSitePpeParams = selectParamsMemo()
export const selectSitePpe = createSelector([selectResources, selectSitePpeParams], (resources, params) => {

    if (resources && params.siteId) {

        const sitePpe = getResources<SitePpe>( resources, 'sitePpe', { eqFilter: { site: params.siteId } })

        return sitePpe.map(item => {
            const ppeType = getResource<PpeType>(resources.ppeTypes, item.ppeType)
            return {
                ...item,
                ...ppeType
            }
        })
    }
    return []
})