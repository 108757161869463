import { differenceInSeconds } from 'date-fns'
import { AppDispatch, RootState } from '../../redux/store'
import { createReactor } from '../../redux/selectors/helpers/createReactor'
import { requestDocs } from '../../docstore/requestDocs'
import { selectCompanies } from '../../redux/selectors/companies'
import { requestResources } from '../../datastore'
import { initialiseResourceStatus } from '../../redux/slices/resourceMeta'
import { ResourceType } from '../../redux/slices/resources'
import { ResourceEvent, ResourceUpdateStatus } from '../../datastore/types'
import { selectResourceUpdateStatusValid } from '../../redux/selectors/resourceMeta'
import log from 'loglevel'

const globalResourceUpdateConfig: { [key in Partial<ResourceType>]?: ResourceUpdateStatus } = {

    docInfo: {
        autoRequestFilter: { inFilter: { id: [1, 2, 3, 4, 5, 42, 43, 44, 45, 46, 47] } },
        minRefreshSeconds: 60
    },
    // More-or-less static (currently not user-created)
    // -> Should always be available offline, no need to delete on logout
    ppeTypes: {
        autoRequestFilter: {},
        minRefreshSeconds: 3600
    },
    hazardCategories: {
        autoRequestFilter: {},
        minRefreshSeconds: 3600
    },
    controls: {
        autoRequestFilter: {},
        minRefreshSeconds: 3600
    },
    hazardTypes: {
        autoRequestFilter: {},
        minRefreshSeconds: 3600
    },
    hazardTypeControls: {
        autoRequestFilter: {},
        minRefreshSeconds: 3600
    },
    jobCategories: {
        autoRequestFilter: {},
        minRefreshSeconds: 3600
    },
    // user-created, but finite/relatively small record count
    companies: {
        autoRequestFilter: { eqFilter: { active: true } },
        changeDetectFilter: {},
        minRefreshSeconds: 60
    },
    projects: {
        autoRequestFilter: { eqFilter: { status: 'OPEN' } },
        changeDetectFilter: {},
        autoRequestRelated: ['projectSites'],
        minRefreshSeconds: 60
    },
    projectSites: {
        // Will be refreshed as related resource of projects - should not auto refresh independently
        autoRefreshSeconds: 0,
        autoRequestRelated: ['sites'],
        minRefreshSeconds: 60
    },
    sites: {
        // Will be refreshed as related resource of projectSites - should not auto refresh independently
        autoRefreshSeconds: 0,
        minRefreshSeconds: 60
    },

    taTemplates: {
        autoRequestFilter: {}
    },
}

const DEFAULT_AUTO_REFRESH_SECONDS = 3600
const DEFAULT_MIN_REFRESH_SECONDS = 5

export const initialiseGlobalResourceUpdate = () => (dispatch: AppDispatch, getState: () => RootState) => {

    const globalUpdateResources = Object.keys(globalResourceUpdateConfig) as ResourceType[]

    globalUpdateResources.forEach(resourceType => {
        const config = globalResourceUpdateConfig[resourceType]
        const existingStatus = getState().resourceMeta.resourceUpdateStatus[resourceType]

        const newStatus: ResourceUpdateStatus = {
            autoRefreshSeconds: config!.autoRefreshSeconds ?? DEFAULT_AUTO_REFRESH_SECONDS,
            minRefreshSeconds: config!.minRefreshSeconds ?? DEFAULT_MIN_REFRESH_SECONDS,
            autoRequestFilter: config!.autoRequestFilter,
            autoRequestRelated: config!.autoRequestRelated,
            changeDetectFilter: config!.changeDetectFilter,
            expireAfterDays: config!.expireAfterDays
        }
        if (existingStatus?.lastUpdated && !selectResourceUpdateStatusValid(getState(), { type: resourceType })) {

            log.info(`initialiseGlobalResourceUpdate: ${resourceType} cache status missing or suspect, reset`)

            newStatus.validTo = undefined
            newStatus.lastUpdated = undefined
            newStatus.lastUpdateResult = ResourceEvent.NONE
        }

        dispatch(initialiseResourceStatus({
            resourceType, status: newStatus
        }))
    })
}


const updateGlobalDocs = () => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    await requestResources('companies', { eqFilter: { active: true } })(dispatch, getState, services)

    const companies = selectCompanies(getState(), {})
    if (companies) {

        // Request all template documents used by available companies
        const templateDocIds = companies.reduce((acc: number[], company) => {
            let addTemplateDocIds: number[] = []
            if (typeof company.templateDocs === 'object') {
                Object.keys(company.templateDocs).forEach(key => {
                    const docId = company.templateDocs[key]
                    // add template doc id if we don't already have it in list
                    // (multiple companies will likely share the same templates)
                    if (!acc.includes(docId)) {
                        addTemplateDocIds = addTemplateDocIds.concat([docId])
                    }
                })
            }
            return acc.concat(addTemplateDocIds)
        }, [])
        if (templateDocIds.length) {
            dispatch(requestDocs({ inFilter: { id: templateDocIds } }))
        }
    }

}

const REACTOR_UPDATE_SECONDS = 3600
var updateTimeLast: Date

/**
 * Auto-request 'Reactor' - updates document data in the background
 * 
 * Will run every 10 seconds on change in temp.appTime, or on changes in docInfo
 * 
 * @returns thunk to be dispatched if an update is due
 */
export const globalResourceUpdateReactor = createReactor(
    [(state: RootState) => state.temp.appTime,
    (state: RootState) => state.app.user],
    (appTime, user) => {

        if (user && (!updateTimeLast || differenceInSeconds(new Date(), updateTimeLast) >= REACTOR_UPDATE_SECONDS)) {
            updateTimeLast = new Date()
            return updateGlobalDocs()
        }
    }
)