import { AppDispatch, RootState } from "../../redux/store"
import { getResource } from "../../datastore"
import { User } from "../../datastore/models"
import { selectSite } from "../../redux/selectors/sites"
import { selectSiteObservation } from "../../redux/selectors/siteObservations"
import { selectSiteObservationActions } from '../../redux/selectors/siteObservationActions'
import { userContact } from "./helpers/userContact"
import { addressFull } from "./helpers/addressFull"

export const getSiteObservation = (params: { [key: string]: any }) => (dispatch: AppDispatch, getState: () => RootState) => {
    const siteObservationId = params.siteObservationId

    const state = getState()
    const siteObservation = selectSiteObservation(getState(), { siteObservationId })
    if (!siteObservation) {
        return null
    }

    const site = selectSite(state, { siteId: siteObservation.site })
    const actions = selectSiteObservationActions(state, { siteObservationId })

    return {
        siteObservation: {
            company: siteObservation.company,
            site: {
                siteName: site?.siteName || '',
                ...addressFull(site),
                timeZone: site?.timeZone || ''
            },
            //project: siteObservation.project, // request/select project above if we need it
            observationDate: siteObservation.observationDate,
            observer: {
                id: siteObservation.observer, // report uses id to determine when observer and action creator are the same person
                ...userContact(getResource<User>(state.resources.users, siteObservation.observer))
            },
            description: siteObservation.description,
            photoBefore: siteObservation.photoBefore,
            photoAfter: siteObservation.photoAfter,
            observerClosedDate: siteObservation.observerClosedDate,
            supervisorClosedDate: siteObservation.supervisorClosedDate,
            notifiedSupervisor: userContact(getResource<User>(state.resources.users, siteObservation.notifiedSupervisor)),
            closingSupervisor: userContact(getResource<User>(state.resources.users, siteObservation.closingSupervisor)),

            actions: actions.map(action => ({
                ...action,
                creator: {
                    id: action.creator, // report uses id to determine when observer and action creator are the same person
                    ...userContact(getResource<User>(state.resources.users, action.creator))
                },
                assignedTo: userContact(getResource<User>(state.resources.users, action.assignedTo))
            })),
            images: [siteObservation.photoBefore, siteObservation.photoAfter].filter(id => !!id)
                .map(id => ({ savedId: id }))
        }
    }
}