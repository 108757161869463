import log from 'loglevel'
import { selectSite } from '../../redux/selectors/sites'
import { selectProjectSites } from '../../redux/selectors/projectSites'
import { selectUsers } from '../../redux/selectors/users'
import { AppDispatch, RootState } from '../../redux/store'
import { setAlert } from '../../utils/alert'
import { requestSupervisorsBySite } from '../commonResourceRequests/useRequestSupervisorsBySite'
import { returnToStart } from './returnToStart'
import { saveIncident } from './saveIncident'
import { sendIncidentNotification } from './sendIncidentNotification'
import { setIncidentReportEdit } from '../../redux/slices/pgIncidentReports'

export const notifyIncident = () => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    const { actionVerifier, incidentType, actions, site, notifiedDate } = getState().pgIncidentReports.incidentReportEdit

    let recipientIds: number[] = []
    const previouslyNotified = !!notifiedDate

    if (!previouslyNotified && !['NearMiss','MinorInjury'].includes(incidentType)) {
        // Incident hasn't been notified yet, and is more serious than 'Minor Injury'
        // -> send notification to all site supervisors
        await requestSupervisorsBySite(site)(dispatch, getState, services)

        const state = getState()
        const incidentSite = selectSite(state, { siteId: site })
        const projectSites = selectProjectSites(state, { siteId: site })
        recipientIds = selectUsers(state, {
            companyIds: incidentSite ? [incidentSite.company] : [],
            superviseProjectIds: projectSites.map(projectSite => projectSite.projectId)
        }).map( user => user.id)

    } else {
        recipientIds = actionVerifier? [actionVerifier]:[]
    }

    if (!actions?.length) {
        dispatch(setAlert(
            'No Corrective Actions',
            "You haven't entered any corrective actions to be taken to prevent this incident occurring in future, continue anyway?",
            [{
                text: 'Cancel',
                role: 'cancel',
                handler: () => true
            },
            {
                text: "Submit Incident Report",
                handler: () => {
                    dispatch(saveAndNotify(recipientIds, previouslyNotified))
                    return true
                }
            }]
        ))
    } else {

        if (!actionVerifier) {
            dispatch(setAlert(
                'No Action Verifier',
                "You haven't selected anyone to verify that corrective actions are implemented and effective, continue anyway?",
                [{
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => true
                },
                {
                    text: "Submit Incident Report",
                    handler: () => {
                        dispatch(saveAndNotify(recipientIds, previouslyNotified))
                        return true
                    }
                }]
            ))
        } else {
            dispatch(saveAndNotify(recipientIds, previouslyNotified))
        }
    }
}

const saveAndNotify = (recipientIds: number[], previouslyNotified: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!previouslyNotified) {
        dispatch(setIncidentReportEdit({notifiedDate: new Date().toISOString()}))
    }
    await saveIncident()(dispatch, getState)
    if (recipientIds.length) {
        const incidentId = getState().pgIncidentReports.incidentReportEdit.id
        if (incidentId) {
            dispatch(sendIncidentNotification(incidentId, recipientIds, previouslyNotified))
            dispatch(setAlert(
                'Supervisor Notified',
                'Thank you for making this incident report - the site supervisor has been notified'
            ))
        } else {
            log.error('notifyIncident: Failed to get incidentId after saving')
        }
    }
    dispatch(returnToStart())
}