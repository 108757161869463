import { getResourceIds } from '../datastore'
import { ResourceMap } from '../redux/slices/resources'

import { RootState } from '../redux/store'

export const getDocLoadStatus = (allDocInfo:ResourceMap, allDocStatus: RootState['docMeta']['docStatus'], docId:number) => {

    if (!allDocStatus) {
        return null
    }

    const { keyId, localId } = getResourceIds(allDocInfo, docId)
    if (!keyId) {
        return null
    }

    let docStatus = allDocStatus[keyId]

    if (!docStatus && localId) {        
        docStatus = allDocStatus[localId]
    }
    
    if (docStatus) {
        return docStatus.loadStatus
    }
    return null
}