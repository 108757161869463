const inductions = {
  table: 'inductions',
  idKey: 'id',

  attrs: [  
    'inductionDate',
    'inductionType',    
    'creationDate',
    'lastModifiedDate'
  ],

  relationships: [    
    {
      type: 'companies',
      key: 'company',
    },
    {
      type: 'sites',
      key: 'site',
    },
    {
      type: 'users',
      key: 'user',
    }    
  ],
}

export default inductions