import { AppDispatch, RootState } from '../../redux/store'
import { setAlert } from '../../utils/alert'
import { saveIncident } from './saveIncident'
import { returnToStart } from './returnToStart'
import { setIncidentReportEdit } from '../../redux/slices/pgIncidentReports'

export const closeIncident = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { incidentReportEdit: { actions } } = getState().pgIncidentReports

    if (actions.filter(action => !action.deleted && !action.verifiedText).length) {
        dispatch(setAlert(
            'Not all actions have been verified as completed and effective',
            'Before closing out the incident, you need to record how each action has been verified'
        ))
        return
    }

    dispatch(setAlert(
        'Close Out Incident',
        'By continuing, you are declaring that, to the best of your knowledge, ' +
        'all actions necessary to prevent this incident happening in future have been implemented and are effective',
        [{
            text: 'Cancel',
            role: 'cancel',
            handler: () => true
        },
        {
            text: 'Continue',
            handler: () => {
                dispatch(setIncidentReportEdit({ closedDate: new Date().toISOString() })) 
                    ; (async () => {
                        // Need to await save, following processes read saved version
                        await saveIncident()(dispatch, getState)
                        dispatch(returnToStart())
                    })()
                return true
            }
        }]
    ))
}