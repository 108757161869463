/**
 * State management applicable to whole app
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import User from '../../datastore/models/User'
import Project from '../../datastore/models/Project'
import env from '../../config'

export type UserAttribute =
    'canSigninHealthAndSafety' |
    'canViewHealthAndSafety' |
    'canCreateHealthAndSafety' |
    'canSuperviseHealthAndSafety' |
    'canManageHealthAndSafety' |
    'canManageCompany'

export interface UserCompanyAttribute {
    attribute: UserAttribute
    description: string
    company: number
}


export interface UserProjectAttribute {
    attribute: UserAttribute
    description: string
    company: number
    project: number
}

export interface UserSiteAttribute {
    attribute: UserAttribute
    company: number
    site: number
}

export interface UserPermissions {
    companyAttributes: UserCompanyAttribute[]
    projectAttributes: UserProjectAttribute[]
    siteAttributes: UserSiteAttribute[]
}

export interface AppState {
    user: User | null
    permissions: UserPermissions
    sub: string                    // auth server sub (used on user refresh to check session still matches the previous user)
    lastUserUpdateTime: string
    lastGuest: User | null
    guestAccessToken: string
    viewSiteId: number | null     // id of site user has selected for viewing. Set to signinSiteId on signin, but user can opt to change
    project: Project | null
    appVersion: string
}

const initialState: AppState = {
    user: null,
    permissions: {
        companyAttributes: [],
        projectAttributes: [],
        siteAttributes: []
    },
    sub:'',
    lastUserUpdateTime: new Date(0).toISOString(),
    lastGuest: null,
    guestAccessToken: '',
    viewSiteId: null,
    project: null,
    appVersion: env.appVersion
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {

        /**
         * Replace entire slice with new data
         * (will be called when initialising from persistent storage)  
         * - merge with initial state in case no saved copy or new items have been added since last save
         * 
         */
        setAll(state, action: PayloadAction<AppState>) {
            return {
                ...state,
                ...action.payload
            }
        },

        /**
        * Reset state to initial values (e.g. on logout)
        *
        */
        setInitial(state, action: PayloadAction<void>) {
            return initialState
        },

        setAppUser(state, action: PayloadAction<{ user: User, permissions: UserPermissions, sub?: string } | null>) {
            if (action.payload) {
                state.user = action.payload.user
                state.permissions = action.payload.permissions
                if (action.payload.sub) {
                    state.sub = action.payload.sub
                }
                state.lastUserUpdateTime = new Date().toISOString()
            } else {
                state.user = null
                state.permissions = {
                    companyAttributes: [],
                    projectAttributes: [],
                    siteAttributes: []
                }
                state.sub = ''
                state.lastUserUpdateTime = new Date(0).toISOString()
            }
        },

        setLastGuest(state, action: PayloadAction<User | null>) {
            state.lastGuest = action.payload
        },

        setGuestAccessToken(state, action: PayloadAction<string>) {
            state.guestAccessToken = action.payload
        },

        setViewSite(state, action: PayloadAction<number | null>) {
            state.viewSiteId = action.payload
        },

        setProject(state, action: PayloadAction<Project | null>) {
            state.project = action.payload
        },

        setAppVersion(state, action: PayloadAction<string>) {
            state.appVersion = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setAll, setInitial, setAppUser, setLastGuest, setGuestAccessToken, setViewSite, setProject, setAppVersion } = appSlice.actions

export default appSlice.reducer