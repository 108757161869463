import log from 'loglevel'
import _ from 'lodash'
import { RootState } from '../../redux/store'
import { Relationship } from '../types'
import { getResourceIds } from '../getResourceIds'

/**
 * Given a resource's 'relationships' hash, return updated 'relationships' with any local ids changed to 'real' ids
 * (called prior to writing record to back end)
 * 
 * Related resources must be successfully saved to back end in the order that they were created for 'real' ids to be available,
 * i.e. this function assumes that records that the relationships point to have already been saved and the corresponding ids can be read.
 * If not the relationship's 'localId' will be left unchanged, and the write should fail (i.e. it will fail if sent to API).
 * 
 * @param relationships 
 * @returns 
 */
export const getUpdatedRelationships = (relationships: { [entity: string]: { data: Relationship | Relationship[] | null } }, getState: () => RootState) => {
 /*   : { [key: string]: { data: Relationship } } => {*/
    const state = getState()

    const updatedRelationships: { [key: string]: { data: Relationship | null }} = {}
    for (const relKey in relationships) {
        const relationship = relationships[relKey]

        if (relationship.data === null) {
            // Need to be able to set relationship to null to update database field, i.e. when deleting a linked resource
            updatedRelationships[relKey] = {
                data: null
            }
        } else {
            if (relationship.data) {
                if (Array.isArray(relationship.data)) {
                    log.error('getUpdatedRelationships: many to one relationships not supported')
                } else {
                    // Only handling 1 to 1 relationships at this point
                    const { keyId } = getResourceIds(state.resources[_.camelCase(relationship.data.type) as keyof typeof state.resources], +relationship.data.id)

                    if (keyId) {
                        updatedRelationships[relKey] = {
                            data: {
                                type: relationship.data.type,
                                id: keyId.toString()
                            }
                        }
                    } else {
                        log.error('Broken relationship found:type=', relationship.data.type, 'id=', relationship.data.id)
                    }
                }
            }
        }
    }
    return updatedRelationships
}