import React from "react"
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { IonButton, IonContent, IonFooter, IonItemDivider } from "@ionic/react"

import { useAppSelector, useAppDispatch } from '../../redux/hooks'

import TextArea from "../../components/form/TextArea"
import Checkbox from "../../components/form/Checkbox"
import RadioGroup from "../../components/form/RadioGroup"

import { useHomeIconHandler } from "../../hooks/homeIconHandler"
import { homeIconAction } from "../../features/incidentReports/homeIconAction"
import { IncidentReportEvent } from "../../features/incidentReports/incidentReportsTypes"
import { updateSequence } from "../../features/incidentReports/incidentReportsSequence"
import { setIncidentReportData } from "../../redux/slices/pgIncidentReports"
import { YesNo, YesNoNa } from "../../datastore/models/Incident"

export type IncidentDetails2Edit = {
    containmentAction: string,
    containmentActionNa: boolean,
    damageExtentCompany: string,
    damageExtentCompanyNa: boolean,
    damageExtentThirdParty: string,
    damageExtentThirdPartyNa: boolean,
    equipmentRemedy: string,
    equipmentRemedyNa: boolean,
    mediaCoverage: string,
    mediaCoverageNa: boolean,
    personalFactor: boolean,
    jobFactor: boolean,
    externalFactor: boolean,
    incidentCause: string,
    drugAlcoholTest: YesNoNa
    taUsed: YesNo,
    toolbox: YesNo
}

const schema = yup.object().shape({
    containmentAction: yup.string().when('containmentActionNa', {
        is: false,
        then: yup.string().required("Please complete, or tick 'N/A' if not applicable"),
    }),
    containmentActionNa: yup.boolean(),
    damageExtentCompany: yup.string().when('damageExtentCompanyNa', {
        is: false,
        then: yup.string().required("Please complete, or tick 'N/A' if not applicable"),
    }),
    damageExtentCompanyNa: yup.boolean(),
    damageExtentThirdParty: yup.string().when('damageExtentThirdPartyNa', {
        is: false,
        then: yup.string().required("Please complete, or tick 'N/A' if not applicable"),
    }),
    damageExtentThirdPartyNa: yup.boolean(),
    equipmentRemedy: yup.string().when('equipmentRemedyNa', {
        is: false,
        then: yup.string().required("Please complete, or tick 'N/A' if not applicable"),
    }),
    equipmentRemedyNa: yup.boolean(),
    mediaCoverage: yup.string().when('mediaCoverageNa', {
        is: false,
        then: yup.string().required("Please complete, or tick 'N/A' if not applicable"),
    }),
    mediaCoverageNa: yup.boolean(),    
    externalFactor: yup.boolean().when(['personalFactor', 'jobFactor'], {
        is: (personalFactor:boolean, jobFactor:boolean) => (!personalFactor && !jobFactor),
        then:yup.boolean().oneOf([true],'Please select at least one factor')
    }),
    incidentCause: yup.string().required("Please describe the cause of the incident"),
    drugAlcoholTest:yup.string().required("Please select an option"),
    taUsed:yup.string().required("Please select an option"),
    toolbox:yup.string().required("Please select an option"),    

}).required()

export const Details2: React.FC = () => {
    const dispatch = useAppDispatch()
    const { incidentReportEdit } = useAppSelector(state => state.pgIncidentReports)

    const { handleSubmit, watch, setValue, formState: { errors, isDirty } } = useForm<IncidentDetails2Edit>({
        resolver: yupResolver(schema),
        defaultValues: {
            containmentAction: incidentReportEdit.incidentData.containmentAction,
            containmentActionNa: incidentReportEdit.incidentData.containmentActionNa,
            damageExtentCompany: incidentReportEdit.incidentData.damageExtentCompany,
            damageExtentCompanyNa: incidentReportEdit.incidentData.damageExtentCompanyNa,
            damageExtentThirdParty: incidentReportEdit.incidentData.damageExtentThirdParty,
            damageExtentThirdPartyNa: incidentReportEdit.incidentData.damageExtentThirdPartyNa,
            equipmentRemedy: incidentReportEdit.incidentData.equipmentRemedy,
            equipmentRemedyNa: incidentReportEdit.incidentData.equipmentRemedyNa,
            mediaCoverage: incidentReportEdit.incidentData.mediaCoverage,
            mediaCoverageNa: incidentReportEdit.incidentData.mediaCoverageNa,
            personalFactor: incidentReportEdit.incidentData.personalFactor,
            jobFactor: incidentReportEdit.incidentData.jobFactor,
            externalFactor: incidentReportEdit.incidentData.externalFactor,
            incidentCause: incidentReportEdit.incidentData.incidentCause,
            drugAlcoholTest: incidentReportEdit.incidentData.drugAlcoholTest,
            taUsed: incidentReportEdit.incidentData.taUsed,
            toolbox: incidentReportEdit.incidentData.toolbox
        }
    })

    const formSubmitBack = (data: any) => {
        formSubmit(data)
        dispatch(updateSequence(IncidentReportEvent.BACK_SELECTED))
    }

    const formSubmitNext = (data: any) => {
        formSubmit(data)
        dispatch(updateSequence(IncidentReportEvent.NEXT_SELECTED))
    }

    const formSubmitHome = (data: any) => {
        formSubmit(data)
        dispatch(homeIconAction())
    }
    useHomeIconHandler('/incidents', handleSubmit(formSubmitHome))

    const formSubmit = (data: any) => {
        if (isDirty) {

            dispatch(setIncidentReportData({
                ...data
            }))
        }
    }

    return (
        <>
            <IonContent class="content-padding">
                <form id="details2-form">

                    <TextArea
                        fieldName="containmentAction"
                        inputMode="text"
                        placeholder={"What is being done to minimise or contain the incident?"}
                        rows={2}
                        watch={watch}
                        setValue={setValue}
                        error={errors.containmentAction}
                    />
                    <Checkbox
                        label='N/A'
                        fieldName='containmentActionNa'
                        watch={watch}
                        setValue={setValue}
                    />
                    <IonItemDivider />

                    <TextArea
                        fieldName="damageExtentCompany"
                        inputMode="text"
                        placeholder={"Extent of damage to Company Facilities or Equipment (if any)"}
                        rows={2}
                        watch={watch}
                        setValue={setValue}
                        error={errors.damageExtentCompany}
                    />
                    <Checkbox
                        label='N/A'
                        fieldName='damageExtentCompanyNa'
                        watch={watch}
                        setValue={setValue}
                    />
                    <IonItemDivider />

                    <TextArea
                        fieldName="damageExtentThirdParty"
                        inputMode="text"
                        placeholder={"Extent of damage to Third Party Facilities or Equipment (if any)"}
                        rows={2}
                        watch={watch}
                        setValue={setValue}
                        error={errors.damageExtentThirdParty}
                    />
                    <Checkbox
                        label='N/A'
                        fieldName='damageExtentThirdPartyNa'
                        watch={watch}
                        setValue={setValue}
                    />
                    <IonItemDivider />

                    <TextArea
                        fieldName="equipmentRemedy"
                        inputMode="text"
                        placeholder={"What checks have been done to remedy any damaged equipment?"}
                        rows={2}
                        watch={watch}
                        setValue={setValue}
                        error={errors.equipmentRemedy}
                    />
                    <Checkbox
                        label='N/A'
                        fieldName='equipmentRemedyNa'
                        watch={watch}
                        setValue={setValue}
                    />
                    <IonItemDivider />

                    <TextArea
                        fieldName="mediaCoverage"
                        inputMode="text"
                        placeholder={"What extent of media coverage (if any) is expected?"}
                        rows={2}
                        watch={watch}
                        setValue={setValue}
                        error={errors.mediaCoverage}
                    />
                    <Checkbox
                        label='N/A'
                        fieldName='mediaCoverageNa'
                        watch={watch}
                        setValue={setValue}
                    />
                    <IonItemDivider />

                    <h3>What factors caused this to happen?</h3>
                    <div className="flex row">
                        <Checkbox
                            fieldName='personalFactor'
                            watch={watch}
                            setValue={setValue}
                        />
                        <div className="flex column">
                            <h3>Personal Factor - Worker Error</h3>
                            <span>Did not follow Procedures / Lack of Skill or Knowledge</span>
                        </div>
                    </div>
                    <div className="flex row">
                        <Checkbox
                            fieldName='jobFactor'
                            watch={watch}
                            setValue={setValue}
                        />
                        <div className="flex column">
                            <h3>Job Factor - Unsafe Work Conditions</h3>
                            <span>Inadequate Procedures in Place / Inadequate Tools & Equipment / Lack of Communication or Supervision</span>
                        </div>
                    </div>
                    <div className="flex row">
                        <Checkbox
                            fieldName='externalFactor'
                            watch={watch}
                            setValue={setValue}
                        />
                        <div className="flex column">
                            <h3>External Factor - Outside Cause</h3>
                            <span>The cause of the incident was outside the control of the person involved</span>
                        </div>
                    </div>
                    <div className="oss-input-error-message">{errors.externalFactor?.message}</div>
                    <IonItemDivider />

                    <TextArea
                        fieldName="incidentCause"
                        inputMode="text"
                        placeholder={"What was the cause of the incident? Describe in your own words why it happened"}
                        rows={2}
                        watch={watch}
                        setValue={setValue}
                        error={errors.incidentCause}
                    />

                    <h3>Has Alcohol/Drug Testing been initiated if necessary to establish cause?</h3>
                    <RadioGroup
                        className="flex row"
                        fieldName="drugAlcoholTest"
                        options={[
                            { value:'Yes', label:'YES' },
                            { value:'No', label:'NO' },
                            { value:'NA', label:'N/A' },
                        ]}
                        watch={watch}
                        setValue={setValue}
                        error={errors.drugAlcoholTest}
                    />
                    <IonItemDivider />

                    <h3>Was there an Active SWMS for the job related to this incident?</h3>
                    <RadioGroup
                        className="flex row"
                        fieldName="taUsed"
                        options={[
                            { value:'Yes', label:'YES' },
                            { value:'No', label:'NO' },                            
                        ]}
                        watch={watch}
                        setValue={setValue}
                        error={errors.taUsed}
                    />
                    <IonItemDivider />

                    <h3>Was there a pre-start Toolbox Talk meeting?</h3>
                    <RadioGroup
                        className="flex row"
                        fieldName="toolbox"
                        options={[
                            { value:'Yes', label:'YES' },
                            { value:'No', label:'NO' },                            
                        ]}
                        watch={watch}
                        setValue={setValue}
                        error={errors.toolbox}
                    />

                </form>

            </IonContent>

            <IonFooter class="incidents-nav">
                <IonButton onClick={handleSubmit(formSubmitBack)}>Back</IonButton>
                <IonButton onClick={handleSubmit(formSubmitNext)}>Next</IonButton>
            </IonFooter>
        </>)
}
