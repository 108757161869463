import { get } from 'lodash'
import { getLogger } from 'loglevel'
import { RootState } from '../../redux/store'
import { ResourceType } from '../../redux/slices/resources'
import { RequestResourcesOptions } from '../requestResources'
import { filterIsSubset } from './filterIsSubset'
import { isValid, parseJSON } from 'date-fns'
import { BuildResourcesRequestOptions } from '../json-api/helpers/buildResourceRequest'
import { ResourceFilters } from '../types'


const log = getLogger('resourceRequests')

/**
 * modify request filters based on what we already have in cache
 *  
 * @description Returns a hash of the following:
 * - canUpdateCache - boolean, true if cache status can be updated base on this request (i.e. it's either a subset or superset of autoReqFilter)
 * - effectiveFilter - filter describing the data we're actually interested in - will be autoReqFilter if request falls within the cache, or the 
 *                        actual filter that was originally requested if not
 * - adjustedOpts - the request that should be made to the api. Will be:
 * 
 *  * autoReqFilter if applicable, or
 *  * changeDetectFilter if applicable and one is configured, or
 *  * the originally supplied filter
 *  
 * plus a 'modified since' time if applicable
 */
export const cacheAdjustFilters = (resourceType: ResourceType, opts: RequestResourcesOptions, getState: () => RootState)
    : { canUpdateCache: boolean, effectiveFilter: ResourceFilters, adjustedOpts: BuildResourcesRequestOptions } => {

    if (opts.include) {
        // Cannot use 'modified since' caching logic if request specifies included resources
        log.debug(`cacheAdjustFilters:request for ${resourceType} with include (no caching)`)
        return {
            canUpdateCache: false,
            effectiveFilter: opts,
            adjustedOpts: opts
        }
    }

    const resourceStatus = getState().resourceMeta.resourceUpdateStatus[resourceType]

    if (resourceStatus?.autoRequestFilter) {
        if (filterIsSubset(resourceStatus.autoRequestFilter, opts)) {
            // Request filter is a subset of 'autoRequestFilter'
            // -> actual request will use 'autoRequestFilter', i.e. rather than request specific items we just update 
            // the whole resource to the present time (which we would be doing anyway)
            let modifiedSince
            if (resourceStatus.validTo && isValid(parseJSON(resourceStatus.validTo))) {
                // If resource has a previously recorded 'valid to' time, request resources modified since then
                modifiedSince = resourceStatus.validTo

                log.debug(`cacheAdjustFilters:incremental request for ${resourceType}`)

            } else {
                log.debug(`cacheAdjustFilters:full request for ${resourceType}`)
            }
            if (modifiedSince && resourceStatus.changeDetectFilter) {
                // If we have a 'modifiedSince' time (i.e. this is an incremental request) and there is a 'changeDetectFilter'
                // configured, use that rather than autoRequestFilter
                return {
                    canUpdateCache: true,
                    effectiveFilter: resourceStatus.autoRequestFilter,
                    adjustedOpts: {
                        ...resourceStatus.changeDetectFilter,
                        modifiedSince
                    }
                }
            }
            return {
                canUpdateCache: true,
                effectiveFilter: resourceStatus.autoRequestFilter,
                adjustedOpts: {
                    ...resourceStatus.autoRequestFilter,
                    modifiedSince
                }
            }

        } else if (filterIsSubset(opts, resourceStatus.autoRequestFilter)) {
            // Requested filter is a superset of 'autoRequestFilter'
            // -> actual request will be sent unmodified, but we can update 'validTo' based on the result

            log.debug(`cacheAdjustFilters:full request for ${resourceType} (superset of autoRequestFilter)`)

            return {
                effectiveFilter: opts,
                canUpdateCache: true,
                adjustedOpts: opts
            }
        }
    }

    // Check whether request is for a specific individual resource
    let singleResourceId = null
    if (opts.eqFilter?.id) {
        singleResourceId = opts.eqFilter.id
    } else {
        if (opts.inFilter?.id && opts.inFilter?.id.length === 1) {
            singleResourceId = opts.inFilter.id[0]
        }
    }
    // If it is, see if we already have a copy in the store
    if (singleResourceId && typeof singleResourceId === 'number') {
        const resource = get(getState(), ['resources', resourceType, singleResourceId])
        if (resource) {
            const lastModifiedDate = resource.attributes.lastModifiedDate
            if (lastModifiedDate && isValid(parseJSON(lastModifiedDate))) {
                // We have a copy of resource - modify request to only return it if the server copy 
                // has been modified since our copy

                log.debug(`cacheAdjustFilters:incremental request for ${resourceType}[${singleResourceId}]`)

                return {
                    canUpdateCache: false,
                    effectiveFilter: opts,
                    adjustedOpts: {
                        ...opts,
                        modifiedSince: lastModifiedDate
                    }
                }
            }
        }
    }

    // Default - either this is not a cached / auto updated resource, or request doesn't align with 'autoRequestFilter'
    // -> send unmodified and don't update 'validTo'

    log.debug(singleResourceId
        ? `cacheAdjustFilters:request for ${resourceType}[${singleResourceId}]`
        : `cacheAdjustFilters:request for ${resourceType} (no caching)`
    )

    return {
        canUpdateCache: false,
        effectiveFilter: opts,
        adjustedOpts: opts
    }
}