import {
    IonHeader,
    IonPage
} from '@ionic/react';

import React from 'react'

import NavBar from '../../components/NavBar'
import { useAppSelector } from '../../redux/hooks'

import { stTOOLBOX } from '../../features/toolbox/toolboxTypes'

import { RollCall } from './RollCall'
import { SiteHazards } from './SiteHazards'
import { SiteObservations } from './SiteObservations'
import { IncidentReports } from './IncidentReports'
import { Tasks } from './Tasks'
import { Start } from './Start';
import { OtherBusiness } from './OtherBusiness'
import './ToolboxNav.css'
import './Toolbox.css'
import { Review } from './Review'
import { Register } from './Register'

const TaskAnalysis: React.FC = () => {    
    const { stToolbox } = useAppSelector((state) => state.pgToolbox)

    function renderSwitch() {
        switch (stToolbox) {

            case stTOOLBOX.START:
                return <Start />

            case stTOOLBOX.REGISTER:
                return <Register />

            case stTOOLBOX.ROLL_CALL:
                return <RollCall />

            case stTOOLBOX.SITE_HAZARDS:
                return <SiteHazards />

            case stTOOLBOX.SITE_OBSERVATIONS:
                return <SiteObservations />

            case stTOOLBOX.INCIDENT_REPORTS:
                return <IncidentReports/>

            case stTOOLBOX.TASKS:
                return <Tasks/>

            case stTOOLBOX.OTHER_BUSINESS:
                return <OtherBusiness/>

            case stTOOLBOX.REVIEW:
                return <Review/>
        }
    }

    return (
        <IonPage className="normal-page">
            <IonHeader>
                <NavBar pageTitle="Toolbox Meeting" />
            </IonHeader>

            {renderSwitch()}

        </IonPage>
    )
}

export default TaskAnalysis