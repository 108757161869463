import { AppDispatch, RootState } from '../redux/store'

import { deleteResource, getResource } from '../datastore'
import { DocInfo } from '../datastore/models'
import { localStore } from '../storage/storage'
import { clearDocStatus } from '../redux/slices/docMeta'

export const deleteDoc = (docId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {

    const docInfo = getResource<DocInfo>(getState().resources.docInfo, docId)
    if (docInfo) {
        localStore.remove(`docs/${docInfo.id}`)
        dispatch(clearDocStatus(`${docInfo.id}`))

        // Deleting docInfo resource also deletes document in back end
        dispatch(deleteResource(docId, 'docInfo'))
    }
}