import { AppDispatch, RootState } from '../../redux/store'
import { setToolboxEdit } from '../../redux/slices/pgToolbox'
import { updateSequence } from './toolboxSequence'
import { ToolboxEvent } from './toolboxTypes'
import { saveToolbox } from './saveToolbox'

export const closeToolbox = () => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setToolboxEdit({ closeDateTime: new Date().toISOString() }))
    dispatch(saveToolbox)
    dispatch(updateSequence(ToolboxEvent.CLOSE_SELECTED))
}