import { IonButton, IonContent, IonFooter, IonItem, IonItemDivider, IonList } from "@ionic/react"

import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { selectUser } from '../../redux/selectors/users'
import TextArea from "../../components/form/TextArea"
import SelectListInput from "../../components/form/SelectListInput"
import DateInput from "../../components/form/DateInput"

import { setSiteObservationActions, setSiteObservationDetails } from '../../redux/slices/pgSiteObservations'

import { get } from "lodash"
import CameraInput, { PhotoData } from "../../components/form/CameraInput"
import PhotoDisplay from "../../components/form/PhotoDisplay"
import { SiteObservation, SiteObservationAction } from "../../datastore/models"
import { initISODateValidator } from "../../helpers/yupISODateValidator"
import { setAlert } from "../../utils/alert"
import { format, parseJSON } from "date-fns"
import { Fragment } from "react"
import { closeObservation } from "../../features/siteObservations/closeObservation"
import { homeIconAction } from "../../features/siteObservations/homeIconAction"
import { useHomeIconHandler } from "../../hooks/homeIconHandler"
import { saveObservationAndFinish } from "../../features/siteObservations/saveObservationAndFinish"
import { updateSequence } from "../../features/siteObservations/siteObservationsSequence"
import { SiteObservationEvent } from "../../features/siteObservations/siteObservationsTypes"
import { selectCanEditObservationAsSupervisor } from '../../redux/selectors/abilities'
import { selectSiteUsers, selectSiteContactUsers } from "../../redux/selectors/users"

export type SiteObservationActionEdit = Partial<SiteObservationAction> & { dirty?: boolean }

type Inputs = {
    actions: SiteObservationActionEdit[]
    photoAfter: PhotoData
}

// Add yup validator for ISO string formatted dates
initISODateValidator()

const schema = yup.object().shape({
    actions: yup.array().of(
        yup.object().shape({
            actionText: yup.string().when('deleted', {
                is: (deleted?: boolean) => !deleted,
                then: yup.string().required('Please describe the action to be taken'),
            }),
            creator: yup.number(),
            assignedTo: yup.number(),
            completedDate: yup.string().when('deleted', {
                is: (deleted?: boolean) => !deleted,
                then: yup.string().isISODate({ nullable: true })
            })
        }).required()
    )
})

interface Props {
    // Modals are instantiated in the containing 'index' component - this fn is used to present them
    presentModal: (modalKey: string) => void
}

export const Actions: React.FC<Props> = ({ presentModal }) => {
    const dispatch = useAppDispatch()

    const { toolboxMode, siteObservationEdit: { details, actions }, dirty } = useAppSelector(state => state.pgSiteObservations)
    // get available users and supervisors using selectors only rather than hooks
    // -> assumes user records have already been requested and avoids unnecessary requests
    const users = useAppSelector(state => selectSiteUsers(state, { siteId: details.site }))
    const supervisors = useAppSelector(state => selectSiteContactUsers(state, { siteId: details.site, contactType: 'Supervisor' }))
    const userId = useAppSelector(state => state.app.user?.id)
    const observer = useAppSelector(state => selectUser(state, { userId: details.observer }))
    const notifiedSupervisor = useAppSelector(state => selectUser(state, { userId: details.notifiedSupervisor }))
    const closingSupervisor = useAppSelector(state => selectUser(state, { userId: details.closingSupervisor }))
    const isSupervisor = useAppSelector(state => selectCanEditObservationAsSupervisor(state, details as SiteObservation))
    const isObserver = userId === details.observer

    const { handleSubmit, watch, setValue, getValues, control, formState: { errors, dirtyFields, isDirty } } = useForm<Inputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            actions: actions,
            photoAfter: details.photoAfter
        }
    })

    const { fields, append, update } = useFieldArray({
        control,
        name: "actions",
        keyName: "ufaId", // defaults to "id" - changed so as not to overwrite resource id
    })

    const addItem = () => {
        append({
            actionText: '',
            creator: userId,
            assignedTo: userId
        })
    }

    const deleteItem = (index: number) => {
        const action = fields[index]
        if (action) {
            update(index, {
                ...action,
                deleted: true
            })
        }
    }

    const formSubmitBack = (data: any) => {
        formSubmit(data)
        dispatch(updateSequence(SiteObservationEvent.BACK_SELECTED))
    }

    const formSubmitSave = (data: any) => {
        formSubmit(data)
        dispatch(saveObservationAndFinish())
    }

    const formSubmitClose = (data: any) => {
        formSubmit(data)
        dispatch(closeObservation(isObserver, isSupervisor))
    }

    const formSubmitNotify = (data: any) => {
        formSubmit(data)

        dispatch(setAlert(
            'Notify Observation',
            'By continuing, you are indicating that you are unable to resolve the issue and that further action by a supervisor is required',
            [{
                text: 'Cancel',
                role: 'cancel',
                handler: () => true
            },
            {
                text: "Continue",
                handler: () => {
                    presentModal('selectSupervisor')
                    return true
                }
            }]
        ))
    }

    const formSubmitHome = (data: any) => {
        formSubmit(data)
        dispatch(homeIconAction())
    }
    useHomeIconHandler('/observations', handleSubmit(formSubmitHome))

    const formSubmit = (data: any) => {

        const updatedActions = data.actions.map((action: SiteObservationActionEdit, i: number) => {
            const itemDirtyFields = dirtyFields?.actions ? dirtyFields.actions[i] : undefined
            const dirty = !!(
                itemDirtyFields &&
                (itemDirtyFields.actionText || itemDirtyFields.creator || itemDirtyFields.assignedTo || itemDirtyFields.completedDate || itemDirtyFields.deleted))
            return {
                ...action,
                dirty: action.dirty || dirty
            } as SiteObservationActionEdit
        })

        if (updatedActions.filter((action: SiteObservationActionEdit) => action.dirty).length) {
            dispatch(setSiteObservationActions(updatedActions))
        }

        if (dirtyFields?.photoAfter) {
            dispatch(setSiteObservationDetails({ photoAfter: data.photoAfter }))
        }
    }

    return (
        <>
            <IonContent class="content-padding">

                <h2>What actions have been, or will be, taken to mitigate the risk?</h2>
                <IonItemDivider className="observation-divider" />
                <form id="mitigation-form">
                    <IonList id="mitigation-list" lines="none">
                        {fields?.map((action, i) => ({ ...action, fieldIndex: i })).filter(action => !action.deleted).map(action => {
                            const actionCreator = supervisors?.find(user => user.id === action.creator)
                            return (
                                <Fragment key={action.ufaId}>
                                    <IonItem >
                                        <div className="flex column">

                                            <TextArea
                                                fieldName={`actions[${action.fieldIndex}].actionText`}
                                                placeholder="Action to be taken"
                                                inputMode="text"
                                                rows={1}
                                                watch={watch}
                                                setValue={setValue}
                                                error={(errors?.actions && errors.actions[action.fieldIndex]) ? errors.actions[action.fieldIndex]?.actionText : undefined}
                                            />

                                            {/* Display 'assigned by' user only if different to the currently logged in user */}
                                            {actionCreator && actionCreator.id !== userId ?
                                                <>
                                                    <span>Assigned By:</span>
                                                    <span>{`${actionCreator.firstName} ${actionCreator.lastName}`}</span>
                                                </>
                                                : <></>
                                            }

                                            <SelectListInput
                                                className="modal-input"
                                                title="Select person who will carry out action:"
                                                placeholder="Name of person assigned to action"
                                                listData={users?.map(user => ({ text: `${user.firstName} ${user.lastName}`, id: user.id })) || []}
                                                label="Assigned To:"
                                                fieldName={`actions[${action.fieldIndex}].assignedTo`}
                                                watch={watch}
                                                setValue={setValue}
                                                error={(errors?.actions && errors.actions[action.fieldIndex]) ? errors.actions[action.fieldIndex]?.assignedTo : undefined}
                                            />

                                            <DateInput
                                                label="Date Completed:"
                                                fieldName={`actions[${action.fieldIndex}].completedDate`}
                                                watch={watch}
                                                setValue={setValue}
                                                error={get(errors, ['actions', action.fieldIndex, 'completedDate'])}
                                                showClear={true}
                                            />
                                            {getValues(`actions.${action.fieldIndex}.completedDate`) ? <></>
                                                : <IonButton onClick={() => deleteItem(action.fieldIndex)}>Delete</IonButton>}

                                        </div>
                                    </IonItem>
                                    <IonItemDivider className="observation-divider" />
                                </Fragment>
                            )
                        })}
                    </IonList>
                    <IonButton onClick={() => addItem()}>Add Action</IonButton>

                    <CameraInput
                        fieldName="photoAfter"
                        watch={watch}
                        setValue={setValue}
                        placeholder="Add photo to evidence mitigation"
                    />
                    <PhotoDisplay
                        fieldName="photoAfter"
                        watch={watch}
                        setValue={setValue}
                    />
                </form>

                <div className="flex column closeout-details">
                    {details.observerClosedDate ?
                        <>
                            <span><b>Closed by Observer:</b> {observer?.firstName} {observer?.lastName} </span>
                            <span><b>Date:</b> {format(parseJSON(details.observerClosedDate), 'dd MMM yyyy')} </span>
                        </> : <></>
                    }
                    {details.supervisorClosedDate ?
                        <>
                            <span><b>Closed by Supervisor:</b> {closingSupervisor?.firstName} {closingSupervisor?.lastName} </span>
                            <span><b>Date:</b> {format(parseJSON(details.supervisorClosedDate), 'dd MMM yyyy')} </span>
                        </> : <></>}

                    {((isObserver && !details.observerClosedDate) || (isSupervisor && !details.supervisorClosedDate)) ?
                        <IonButton onClick={handleSubmit(formSubmitClose)}>
                            {!details.observerClosedDate
                                ? 'Close Out Observation'
                                : 'Close out as Supervisor'
                            }
                        </IonButton> : <></>}

                    {details.notifiedSupervisor ?
                        <span><b>Supervisor to Action:</b> {notifiedSupervisor?.firstName} {notifiedSupervisor?.lastName} </span> : <></>}
                    {!details.notifiedSupervisor ?
                        <IonButton onClick={handleSubmit(formSubmitNotify)}>Notify a Supervisor</IonButton> : <></>}
                    {isSupervisor && details.notifiedSupervisor ?
                        <IonButton onClick={handleSubmit(formSubmitNotify)}>Notify another Supervisor</IonButton> : <></>}
                    {toolboxMode ?
                        <IonButton expand="block" onClick={handleSubmit(formSubmitHome)}>Return to Toolbox Meeting</IonButton> : <></>}
                </div>

            </IonContent>

            <IonFooter class="observations-nav">
                <IonButton onClick={handleSubmit(formSubmitBack)}>Back</IonButton>
                <IonButton disabled={!dirty && !isDirty} onClick={handleSubmit(formSubmitSave)}>Save</IonButton>
                {/* <IonButton >Back to Start</IonButton> ?? */}
            </IonFooter>
        </>)
}