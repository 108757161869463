import { ChangeEvent, useEffect, useRef, useState } from "react";
import './RiskSlider.css'

interface RiskSliderProps {
    color?: string
    labels: string[]
    initialValue: number
    maxValue?: number
    setValue: (value: number) => void
}

const RiskSlider: React.FC<RiskSliderProps> = ({ color, labels, initialValue, maxValue, setValue }) => {

    const [labelIndex, setLabelIndex] = useState<number>(2)

    const [showDescription, setShowDescription] = useState<boolean>(false)

    const showPopup = () => {
        setShowDescription(true)
    }

    const hidePopup = () => {
        setShowDescription(false)
    }

    function changeValue(event: ChangeEvent<HTMLInputElement>) {
        const value = +event.currentTarget.value
        // Limit to maximum allowed value
        if (maxValue && value > maxValue - 1) {
            setLabelIndex(maxValue - 1)
            event.currentTarget.value = labelIndex.toString()
            return
        }

        setLabelIndex(value)
        setValue(value + 1)
    }

    const sliderRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        // Set initial value for slider
        // Note that risk scores will number from 1, whereas slider value is 0 based
        let sliderInitial
        const stepCount = labels.length
        if (initialValue >= 1 && initialValue <= stepCount) {
            // We have a valid value from stepEdit buffer
            sliderInitial = initialValue - 1
        } else {
            // No initial value supplied - risk score hasn't been set yet. Default to mid-range
            sliderInitial = Math.trunc(stepCount / 2)
            setValue(sliderInitial + 1)
        }

        // Limit value to maxValue (typically to ensure user can't enter a residual risk score that is higher than initial risk)
        if (maxValue && sliderInitial >= maxValue) {
            sliderInitial = maxValue - 1
            setValue(maxValue)
        }

        setLabelIndex(sliderInitial)
        if (sliderRef.current) {
            sliderRef.current.value = sliderInitial.toString()
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [initialValue])

    return (
        <>
            {showDescription ?
                <div className="slider-description"><h3>{labels[labelIndex]}</h3></div>
                : <></>
            }

            <div className="slider-v3-container">
                <input
                    className={`slider-v3 ${color}`}
                    type="range"
                    ref={sliderRef}
                    defaultValue={labelIndex}
                    min="0"
                    max="4"
                    onChange={changeValue}
                    onMouseDown={showPopup} onMouseUp={hidePopup}
                    onTouchStart={showPopup} onTouchEnd={hidePopup}
                ></input>
            </div>
        </>
    )
}

export default RiskSlider