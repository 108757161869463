const regions = {
  table: 'regions',
  idKey: 'id',

  attrs: [
    'country',
    'region',
    'emergencyNumbers',    
    'creationDate',
    'lastModifiedDate',    
  ],

  relationships: [],
}

export default regions