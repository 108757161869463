import { throttle } from 'lodash';
import { useEffect, useRef, useState } from 'react';

//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import { Document, Page, pdfjs } from 'react-pdf';

// import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
// import { Zoom } from "swiper";
// import "swiper/swiper.min.css";
// import "swiper/modules/zoom/zoom.min.css";
import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './PdfDocContainer.css'

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'

interface ContainerProps {
    docBlob: Blob,
    setUserData?: (userData: Record<string, any>) => void
    setIsValid?: (isValid: boolean) => void
    setNumPages?: (numPages: number) => void
    setIsLoading?: (isLoading: boolean) => void
    pageNum?: number
}

const PdfDocContainer: React.FC<ContainerProps> = ({ docBlob, setUserData, setIsValid, setNumPages, setIsLoading, pageNum }) => {

    const [docData, setDocData] = useState<Uint8Array>()
    const [containerWidth, setContainerWidth] = useState<number>()
    const componentMounted = useRef(true)

    const [numPages, setThisNumPages] = useState<number>(1)
    const dispPageNum = Math.min((pageNum || 1), numPages)

    useEffect(() => {
        async function getPdfDoc() {
            if (docBlob) {

                setThisNumPages(1)

                // Convert file content from blob to byte array
                const reader = new FileReader();
                reader.readAsArrayBuffer(docBlob);
                reader.onloadend = function () {
                    if (componentMounted.current) {
                        setDocData(reader.result as Uint8Array)
                    }
                }
            }
        }
        getPdfDoc()
        // Currently no support for user data entry in a pdf
        // => set user data to nothing, set to 'always valid'
        if (setUserData) {
            setUserData({})
        }
        if (setIsValid) {
            setIsValid(true)
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [docBlob])

    const containerRef = useRef<HTMLDivElement>(null)

    const setContainerSize = () => {
        if (containerRef.current) {
            const width = window.innerWidth
            setContainerWidth(width)
            // Attempt to set viewport height when in landscape mode - doesn't really work as component doesn't allow setting
            // height and width at the same time, viewport aspect ratio seems to be fixed at that of the pdf
            // Also tried setting width and height of canvas - also doesn't work, distorts pdf if aspect ratio is different
            //   const height = window.innerHeight - 100
            //   setContainerHeight(height)

            //   console.log(`set container width to ${width}`)
        }
    }

    const onLoadSuccess = (params: { numPages: number }) => {
        if (componentMounted.current) {
            setThisNumPages(params.numPages)
            if (setNumPages) {
                setNumPages(params.numPages)
            }
            if (setIsLoading) {
                setIsLoading(false)
            }
        }
    }

    const onLoadError = (error: Error) => {
        if (componentMounted.current) {
            // 'Worker was destroyed' error may occur due to component being remounted (React 18 behaviour)
            if (error.message !== 'Worker was destroyed') {
                alert('Error while loading document! ' + error.message)
            }
            if (setIsLoading) {
                setIsLoading(false)
            }
        }
    }

    // Need a stable reference to throttled function so that window.addEventListener and window.removeEventListener
    // recognise it as the same function 
    const throttledsetContainerSize = useRef(throttle(setContainerSize, 500))

    useEffect(() => {
        // Component mount       
        componentMounted.current = true
        setContainerSize()
        window.addEventListener("resize", throttledsetContainerSize.current)

        // component unmount cleanup
        return () => {
            window.removeEventListener("resize", throttledsetContainerSize.current)
            componentMounted.current = false
        }
    }, [])

    return (
        <>
            <TransformWrapper>
                <TransformComponent contentClass="pdf-zoom-container">
                    {
                        docData
                            ? <Document
                                file={docData}
                                loading=""
                                onLoadSuccess={onLoadSuccess}
                                onLoadError={onLoadError}
                            >
                                <Page className="" pageNumber={dispPageNum} width={containerWidth} />
                            </Document>
                            : <></>
                    }
                </TransformComponent>
            </TransformWrapper>

            <div ref={containerRef} className="swiper-zoom-container-fake" style={{ "display": "none" }}>

                <div style={{ "display": "none" }}>
                    {
                        docData
                            ? <Document
                                file={docData}
                                loading=""
                                onLoadSuccess={onLoadSuccess}
                                onLoadError={onLoadError}
                            >
                                <Page pageNumber={dispPageNum} width={containerWidth} />
                                {/* <Page className="swiper-zoom-container-fake " pageNumber={dispPageNum} height={containerHeight}/> */}
                            </Document>
                            : <></>
                    }
                </div>

            </div>
        </>
    )
};

export default PdfDocContainer;
