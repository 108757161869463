import { createSelector } from '@reduxjs/toolkit'
import { getResources } from '../../datastore'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'
import { NotifiableWork } from '../../datastore/models'

const selectNotifiableWorkResources = selectResourcesMemo(['notifiableWork']) 
const selectNotifiableWorkParams = selectParamsMemo()

/**
 * Get array of notifiable work items
 */
export const selectNotifiableWork = createSelector([selectNotifiableWorkResources, selectNotifiableWorkParams], (resources, params) => {
    const opts = params.itemIds
        ? { inFilter: { id: params.itemIds } }
        : {}
    return getResources<NotifiableWork>(resources, 'notifiableWork', opts)
})


