import { AppDispatch, RootState } from "../../redux/store"
import { requestResources } from "../../datastore"
import { ResourceEvent } from "../../datastore/types"
import { useAppSelector } from "../../redux/hooks"
import { useTask } from "../../hooks/useTask"
import { selectSiteContactUsers } from "../../redux/selectors/users"

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results
 *
 * @returns  requested resources
 */
export const useRequestSupervisorsBySite = (siteId: number) => {
  const taskResult = useTask(
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      const result = await requestResources("siteContacts", {
        eqFilter: {
          site: siteId,
          contactType: "Supervisor"
        },
        include: ["user"]
      })(dispatch, getState, services)

      return {
        isSuccess: result !== ResourceEvent.RESOURCE_LOAD_ERROR
      }
    },
    [siteId], !!siteId
  )

  return {
    // Note: select users here (rather than in task) so that we get whatever we have in the store straight away
    supervisors: useAppSelector((state) =>
      selectSiteContactUsers(state, {
        siteId: siteId,
        contactType: "Supervisor"
      })
    ),
    isLoading: taskResult.isLoading,
    isSuccess: taskResult.isSuccess,
    isError: taskResult.isError
  }
}

// To be removed - currently still used by incident notification
export const requestSupervisorsBySite =
  (siteId?: number | null) =>
  async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const result = await requestResources("siteContacts", {
      eqFilter: {
        site: siteId,
        contactType: "Supervisor"
      },
      include: ["user"]
    })(dispatch, getState, services)

    return result
}