import { useEffect, useRef } from "react"

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonContent,
  IonFooter,
  IonItem,
  IonList
  //    IonRefresher,
  //    IonRefresherContent
} from "@ionic/react"
//import { arrowDownCircleOutline } from 'ionicons/icons'

import { useAppSelector, useAppDispatch } from "../../redux/hooks"

import {
  setToolboxMeetingData,
  setToolboxSiteHazard
} from "../../redux/slices/pgToolbox"
import { inChecklist } from "../../features/toolbox/resourceChecklist"
import { useSiteHazardsResources } from "../../features/toolbox/resourceRequests/useSiteHazardsResources"
import TextArea from "../../components/form/TextArea"
import { saveToolbox } from "../../features/toolbox/saveToolbox"
import { updateSequence } from "../../features/toolbox/toolboxSequence"
import { saveTbSiteHazards } from "../../features/toolbox/saveTbSiteHazards"
import { ToolboxEvent } from "../../features/toolbox/toolboxTypes"
import ASCheckbox from "../../components/ASCheckbox"

type Inputs = {
  comments: string
}

const schema = yup
  .object()
  .shape({
    comments: yup.string()
  })
  .required()

export const SiteHazards: React.FC = () => {
  const dispatch = useAppDispatch()
  const { toolboxEdit, siteHazardsList } = useAppSelector(
    (state) => state.pgToolbox
  )

  const { hazards, isLoading } = useSiteHazardsResources(toolboxEdit.site || null, toolboxEdit.company || null)

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty }
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      comments: toolboxEdit.meetingData.siteHazardsComments
    }
  })

  const formSubmitBack = (data: any) => {
    formSubmit(data)
    dispatch(updateSequence(ToolboxEvent.BACK_SELECTED))
  }

  const formSubmitNext = (data: any) => {
    formSubmit(data)
    dispatch(updateSequence(ToolboxEvent.NEXT_SELECTED))
  }

  const formSubmit = (data: any) => {
    if (isDirty) {
      dispatch(setToolboxMeetingData({ siteHazardsComments: data.comments }))
      dispatch(saveToolbox)
    }
    dispatch(saveTbSiteHazards)
  }

  const refresherRef = useRef<HTMLIonRefresherElement>(null)
  useEffect(() => {
    if (!isLoading) {
      refresherRef.current?.complete()
    }
  }, [!isLoading])

  return (
    <>
      <IonContent class="content-padding">
        <h2>General Site Hazards</h2>
        <p>What do we need to be aware of on site today?</p>
        {/* 
                <IonRefresher
                    ref={refresherRef}
                    slot="fixed"
                    onIonRefresh={() => setRefresh(refresh+1)}
                >
                    <IonRefresherContent
                        pullingIcon={arrowDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing..."
                    />
                </IonRefresher>
                 */}
        <IonList>
          <IonAccordionGroup multiple={true}>
            {hazards?.map((hazard) => (
              <IonAccordion key={hazard.id}>
                <IonItem slot="header">
                  <ASCheckbox
                    checked={inChecklist(siteHazardsList, hazard)}
                    onClick={(checked) =>
                      dispatch(
                        setToolboxSiteHazard({ hazard, newState: checked })
                      )
                    }
                    label={hazard.hazardText}
                  />
                </IonItem>
                <IonList slot="content">
                  <IonItem>
                    <div className="flex-column">
                      {hazard.hazardTypeDetail ? (
                        <p>{hazard.hazardTypeDetail}</p>
                      ) : (
                        <></>
                      )}
                      {hazard.detail ? <p>{hazard.detail}</p> : <></>}

                      <h3>Controls:</h3>

                      <ul>
                        {hazard.controls.map((control: any, id: number) => (
                          <li key={id}>
                            {control.controlText}
                            {control.controlTypeDetail ? (
                              <p>{control.controlTypeDetail}</p>
                            ) : (
                              <></>
                            )}
                            {control.detail ? <p>{control.detail}</p> : <></>}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </IonItem>
                </IonList>
              </IonAccordion>
            ))}
          </IonAccordionGroup>
        </IonList>

        {/* use when we have 'edit site hazards' fn in app */}
        {/* <IonButton >Add Hazard</IonButton> */}
        <TextArea
          fieldName="comments"
          inputMode="text"
          placeholder="COMMENTS - Any extra details you want to record"
          rows={6}
          watch={watch}
          setValue={setValue}
          errors={errors}
        />
      </IonContent>

      <IonFooter class="toolbox-nav">
        <IonButton onClick={handleSubmit(formSubmitBack)}>Back</IonButton>
        <IonButton onClick={handleSubmit(formSubmitNext)}>Next</IonButton>
      </IonFooter>
    </>
  )
}
