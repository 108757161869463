import { AppDispatch, RootState } from '../../../redux/store'
import { requestResourcesWithRelated } from '../../../datastore'

export const requestSiteObservations = (params: { [key: string]: any }) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const { siteId, includeActions } = params

    return await (requestResourcesWithRelated('siteObservations', {
        eqFilter: siteId ? { site: siteId } : {},
        periodFilter: { attr: 'observationDate', periodStart: params.periodStart, periodEnd: params.periodEnd }
    }, includeActions ? ['siteObservationActions'] : []))(dispatch, getState, services)
}