import { LOCAL_ID_START } from '../redux/slices/resourceMeta'
import { ResourceMap } from '../redux/slices/resources'

import { getResourceById } from './helpers/getResourceById'

/**
 * Gets ids for a given resource:
 * keyId: id to use when saving a resource. Will be one of the following:
 * 
 * - The resource's 'real' id from the database, if it has previously been written successfully
 * - An existing 'local id' if the resource has been saved before but has not yet been written to the database
 *   (i.e. when working in offline mode)
 * 
 * localId: the existing or new local Id, if applicable.
 */
export const getResourceIds = (resources: ResourceMap, existingId?: number | null): { keyId?: number, localId?: number } => {

    // Existing id may be:
    // - null (indicating this is a new resource being written for the first time)
    // - a canonical id from the database
    // - a temporary 'local Id' assigned when the the resource was created, that hasn't yet been updated to the real one

    if (existingId) {
        // Look for an existing resource in local state
        const existingResource = getResourceById(resources, existingId)
        if (existingResource) {
            // Found an existing resource with either an id or localId matching this one
            return {
                keyId: +existingResource.id,
                localId: existingId >= LOCAL_ID_START ? existingId : existingResource.localId
            }
        }
        // No resource found - return existing id unchanged
        return {
            keyId: existingId,
            localId: existingId >= LOCAL_ID_START ? existingId : undefined
        }
    } else {
        return {}
    }
}