import { IonButton, IonContent, IonItemDivider } from "@ionic/react"
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { selectSite } from '../../redux/selectors/sites'
import { updateSequence } from "../../features/signin/signinSequence"
import { SigninEvent } from "../../features/signin/signinTypes"
import { setSigninType } from "../../redux/slices/pgSignin"

const SelectSigninType: React.FC = () => {

    const { signinSiteId } = useAppSelector(state => state.pgSignin)
    const site = useAppSelector(state => selectSite(state, { siteId: signinSiteId }))

    const dispatch = useAppDispatch()

    const selectSigninType = (signinType: 'Worker' | 'Visitor' | 'Driver') => {
        dispatch(setSigninType(signinType))
        dispatch(updateSequence(SigninEvent.SELECT_SIGNIN_TYPE))
    }

    return (

        <IonContent class="normal-page content-padding">
            <h1>Sign In Type:</h1>
            <p>What are you here to do today?</p>
            {site?.workerSignin ?
                <>
                    <IonButton onClick={() => selectSigninType('Worker')}>Sign in as a Worker</IonButton>
                    <p>Choose this option if you are an employee or subcontractor working on site</p>
                    <IonItemDivider/>
                    <br/>
                </>
                : <></>
            }
            {site?.visitorSignin ?
                <>
                    <IonButton onClick={() => selectSigninType('Visitor')}>Sign in as a Visitor</IonButton>
                    <p>Choose this option if you are just visiting, and will be accompanied by someone who's fully inducted</p>
                    <IonItemDivider/>
                    <br/>
                </>
                : <></>
            }
            {site?.driverSignin ?
                <>
                    <IonButton onClick={() => selectSigninType('Driver')}>Sign in as a Driver</IonButton>
                    <p>Choose this option if you are a truck driver here to drop off or collect materials</p>
                    <IonItemDivider/>
                    <br/>
                </>
                : <></>
            }
            <IonButton onClick={() => dispatch(updateSequence(SigninEvent.RESET_SEQUENCE))}>Cancel</IonButton>

        </IonContent>
    )

}

export default SelectSigninType