export enum stTOOLBOX {
    START,
    REGISTER, 
    ROLL_CALL,
    SITE_HAZARDS,
    SITE_OBSERVATIONS,
    INCIDENT_REPORTS,
    TASKS,
    OTHER_BUSINESS,
    REVIEW
}

export enum ToolboxEvent {
    START_SELECTED,
    REGISTER_SELECTED,
    NEXT_SELECTED,
    BACK_SELECTED,
    CLOSE_SELECTED,
    REVIEW_SELECTED
}