/**
 * State management specific to SWMS page
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import { TaStep, TaStepState, TaUserData } from '../../datastore/models/Ta'
import { getStepRiskScoringComplete } from '../../features/taskAnalysis/helpers/getRiskScoringComplete'
import { stTASKANALYSIS } from '../../features/taskAnalysis/taskAnalysisTypes'
import { TaWorkerEdit } from '../../pages/TaskAnalysis/GeneralDetails'

export interface TaEdit {
    id?: number
    company?: number
    project?: number
    site?: number
    scheduledTask?: number
    creator?: number
    startDate?: string // Store Date as ISO string
    draft?: boolean
    revision?: number
    superseded?: boolean
    supersedes?: number
    userData: TaUserData
    workers: TaWorkerEdit[]
}

export interface PgTaskAnalysisState {
    stTaskAnalysis: stTASKANALYSIS
    taEdit: TaEdit
    taEditDirty: boolean
    selectedHazardCategories: number[]
    selectedStepIndex: number
    selectedHazardIndex: number
    availableCustomHazards: string[]
    savedAsDraft: boolean
    savedAsTemplate: boolean
    reviewAfterEdit: boolean // True if user goes from editing to review screen (as opposed to directly by selecting existing SWMS)
    toolboxMode: boolean
    toolboxTa: number | null
    toolboxProject: number | null
    toolboxSite: number | null
    toolboxTask: number | null

}

const initialState: PgTaskAnalysisState = {
    stTaskAnalysis: stTASKANALYSIS.START,
    taEdit: {
        userData: {
            name: '',
            description: '',
            steps: [],
            ppe: [],
            customPpe: [],
        },
        workers: []
    },
    taEditDirty: false,
    selectedHazardCategories: [],
    selectedStepIndex: 0,
    selectedHazardIndex: 0,
    availableCustomHazards: [],
    savedAsDraft: false,
    savedAsTemplate: false,
    reviewAfterEdit: false,
    toolboxMode: false,
    toolboxTa: null,
    toolboxProject: null,
    toolboxSite: null,
    toolboxTask: null
}

function getStepEdit(state: WritableDraft<PgTaskAnalysisState>) {
    return state.taEdit.userData.steps[state.selectedStepIndex] || null
}

function getHazardEdit(state: WritableDraft<PgTaskAnalysisState>) {
    const stepEdit = getStepEdit(state)
    if (stepEdit) {
        const hazardEdit = stepEdit.hazards[state.selectedHazardIndex]
        return hazardEdit || null
    }
    return null
}

export const pgTaskAnalysis = createSlice({
    name: 'pgTaskAnalysis',
    initialState,
    reducers: {
        /**
        * Replace entire slice with new data
        * (will be called when initialising from persistent storage)  
        */
        setAll(state, action: PayloadAction<PgTaskAnalysisState>) {
            return {
                ...state,
                ...action.payload
            }
        },

        /**
         * Reset state to initial values (e.g. on logout)
         *
         */
        setInitial(state, action: PayloadAction<void>) {
            return initialState
        },

        setTaState(state, action: PayloadAction<stTASKANALYSIS>) {
            // If entering 'Review' state, record whether we're coming from 'Select PPE'
            // (the last page of SWMS edit sequence). If so, review page should show 'Back' button as opposed to 'Edit'
            if (action.payload === stTASKANALYSIS.REVIEW) {
                state.reviewAfterEdit = (state.stTaskAnalysis === stTASKANALYSIS.SELECT_PPE)
            }
            state.stTaskAnalysis = action.payload
            // log.debug('stTaskAnalysis set to ', state.stTaskAnalysis)
        },

        /**
         * Called to either initialise a new (blank) ta or load a template / draft
         */
        setTaEdit(state, action: PayloadAction<TaEdit>) {

            state.taEdit = {
                ...action.payload,
                userData: {
                    ...action.payload.userData,
                    steps: action.payload.userData.steps.map(step => {
                        const updatedStep = { ...step }

                        if (!step.state) {

                            // Set step 'state' if not already set:
                            // - ADD_HAZARD if step has no hazards configured
                            // - COMPLETE if at least one hazard is configured and risk scoring has been completed
                            // - REVIEW_HAZARD otherwise

                            if (step.hazards.length === 0) {
                                updatedStep.state = TaStepState.ADD_HAZARDS
                            } else {
                                if (getStepRiskScoringComplete(step)) {
                                    updatedStep.state = TaStepState.COMPLETE
                                } else {
                                    updatedStep.state = TaStepState.REVIEW_HAZARDS
                                }
                            }

                        }
                        return updatedStep
                    })
                }
            }

            state.savedAsDraft = false
            state.savedAsTemplate = false            
            state.taEditDirty = false
        },

        setTaEditId(state, action: PayloadAction<number | undefined>) {
            state.taEdit.id = action.payload
        },

        setTaSelectedStep(state, action: PayloadAction<number>) {
            state.selectedStepIndex = action.payload
        },

        setTaSelectedHazard(state, action: PayloadAction<number>) {
            state.selectedHazardIndex = action.payload
        },

        /**         
         * Called on form submit from 'general details' page         
         */
        setTaDetails(state, action: PayloadAction<{
            company: number,
            project: number,
            site: number,
            scheduledTask?: number,
            startDate: string,
            name: string,
            description?: string,
        }>) {
            state.taEdit = {
                ...state.taEdit,
                company: action.payload.company,
                project: action.payload.project,
                site: action.payload.site,
                scheduledTask: action.payload.scheduledTask,
                startDate: action.payload.startDate,
                userData: {
                    ...state.taEdit.userData,
                    name: action.payload.name,
                    description: action.payload.description || state.taEdit.userData.description
                }
            }
            state.savedAsDraft = false
            state.savedAsTemplate = false
            state.taEditDirty = true
        },

        setTaEditDirty(state, action: PayloadAction<boolean>) {
            state.taEditDirty = action.payload
            if (action.payload) {
                state.savedAsDraft = false
                state.savedAsTemplate = false
            }
        },

        setTaSteps(state, action: PayloadAction<TaStep[]>) {
            state.taEdit.userData.steps = action.payload
            state.savedAsDraft = false
            state.savedAsTemplate = false
            state.taEditDirty = true
        },

        /**
         *          
         * @param action index to insert new step at, or null to add at end
         */
        addTaStep(state, action: PayloadAction<number | null>) {
            const newStep = {
                text: '',
                hazards: [],
                state: TaStepState.NONE,
            }
            if (action.payload === null) {
                state.taEdit.userData.steps.push(newStep)
                state.selectedStepIndex = state.taEdit.userData.steps.length - 1
            } else {
                state.taEdit.userData.steps.splice(action.payload, 0, newStep)
                state.selectedStepIndex = action.payload
            }
            state.savedAsDraft = false
            state.savedAsTemplate = false
            state.taEditDirty = true
        },

        /**   
         * @param action  payload = step index to delete
         */
        deleteTaStep(state, action: PayloadAction<number>) {
            state.taEdit.userData.steps.splice(action.payload, 1)
            state.savedAsDraft = false
            state.savedAsTemplate = false
            state.taEditDirty = true
        },

        moveTaStep(state, action: PayloadAction<{ from: number, to: number }>) {
            const step = state.taEdit.userData.steps.splice(action.payload.from, 1)[0]
            state.taEdit.userData.steps.splice(action.payload.to, 0, step)
            state.savedAsDraft = false
            state.savedAsTemplate = false
            state.taEditDirty = true
        },

        setTaStepText(state, action: PayloadAction<{ stepIndex: number, text: string }>) {
            if (action.payload.stepIndex < state.taEdit.userData.steps.length) {
                state.taEdit.userData.steps[action.payload.stepIndex].text = action.payload.text
            }
            state.savedAsDraft = false
            state.savedAsTemplate = false
            state.taEditDirty = true
        },

        setTaStepEditState(state, action: PayloadAction<TaStepState>) {
            const stepEdit = getStepEdit(state)
            if (stepEdit) {
                stepEdit.state = action.payload
                state.savedAsDraft = false
                state.savedAsTemplate = false
                state.taEditDirty = true
            }
        },

        setSelectedHazardCategories(state, action: PayloadAction<number[]>) {
            state.selectedHazardCategories = action.payload
        },

        addTaHazard(state, action: PayloadAction<{ hazardTypeId: number | null, customHazardType: string }>) {

            const { hazardTypeId, customHazardType } = action.payload

            const stepEdit = getStepEdit(state)
            if (!stepEdit) {
                return
            }
            // Do nothing if the hazard we're trying to add already exists
            if (hazardTypeId && stepEdit.hazards.filter(hazard => hazard.hazardTypeId === hazardTypeId).length) {
                return
            }
            if (customHazardType && stepEdit.hazards.filter(hazard => hazard.customHazardType === customHazardType).length) {
                return
            }

            const newHazard = {
                hazardTypeId: action.payload.hazardTypeId,
                customHazardType: action.payload.customHazardType,
                irConsequence: 0,
                irLikelyhood: 0,
                rrConsequence: 0,
                rrLikelyhood: 0,
                controls: [],
                customControls: [],
                needsReview: true,
            }
            stepEdit.hazards.push(newHazard)
            stepEdit.state = TaStepState.REVIEW_HAZARDS
            state.selectedHazardIndex = stepEdit.hazards.length - 1
            state.savedAsDraft = false
            state.savedAsTemplate = false
            state.taEditDirty = true
        },

        deleteTaHazard(state, action: PayloadAction<{ hazardTypeId: number | null, customHazardType: string }>) {

            const { hazardTypeId, customHazardType } = action.payload
            const stepEdit = getStepEdit(state)
            if (!stepEdit) {
                return
            }

            const preDeleteCount = stepEdit.hazards.length

            if (hazardTypeId) {
                stepEdit.hazards = stepEdit.hazards.filter(hazard => hazard.hazardTypeId !== hazardTypeId)
            } else if (customHazardType) {
                stepEdit.hazards = stepEdit.hazards.filter(hazard => hazard.customHazardType !== customHazardType)
            }

            if (stepEdit.hazards.length < preDeleteCount) {
                // Mark taEdit as dirty ONLY if we actually deleted something
                state.savedAsDraft = false
                state.savedAsTemplate = false
                state.taEditDirty = true
                if (stepEdit.hazards.length === 0) {
                    stepEdit.state = TaStepState.ADD_HAZARDS
                }
            }
        },

        setTaHazardControls(state, action: PayloadAction<number[]>) {
            const hazardEdit = getHazardEdit(state)
            if (hazardEdit) {
                hazardEdit.controls = action.payload
                state.savedAsDraft = false
                state.savedAsTemplate = false
                state.taEditDirty = true
            }
        },

        setTaHazardCustomControls(state, action: PayloadAction<string[]>) {
            const hazardEdit = getHazardEdit(state)
            if (hazardEdit) {
                hazardEdit.customControls = action.payload
                state.savedAsDraft = false
                state.savedAsTemplate = false
                state.taEditDirty = true
            }
        },

        setTaHazardIrConsequence(state, action: PayloadAction<number>) {
            const hazardEdit = getHazardEdit(state)
            if (hazardEdit) {
                hazardEdit.irConsequence = action.payload
                state.savedAsDraft = false
                state.savedAsTemplate = false
                state.taEditDirty = true
            }
        },

        setTaHazardIrLikelyhood(state, action: PayloadAction<number>) {
            const hazardEdit = getHazardEdit(state)
            if (hazardEdit) {
                hazardEdit.irLikelyhood = action.payload
                state.savedAsDraft = false
                state.savedAsTemplate = false
                state.taEditDirty = true
            }
        },

        setTaHazardRrConsequence(state, action: PayloadAction<number>) {
            const hazardEdit = getHazardEdit(state)
            if (hazardEdit) {
                hazardEdit.rrConsequence = action.payload
                state.savedAsDraft = false
                state.savedAsTemplate = false
                state.taEditDirty = true
            }
        },

        setTaHazardRrLikelyhood(state, action: PayloadAction<number>) {
            const hazardEdit = getHazardEdit(state)
            if (hazardEdit) {
                hazardEdit.rrLikelyhood = action.payload
                state.savedAsDraft = false
                state.savedAsTemplate = false
                state.taEditDirty = true
            }
        },

        setTaHazardNeedsReview(state, action: PayloadAction<boolean>) {
            const hazardEdit = getHazardEdit(state)
            if (hazardEdit) {
                hazardEdit.needsReview = action.payload
            }
        },

        setTaPpe(state, action: PayloadAction<number[]>) {
            state.taEdit.userData.ppe = action.payload
            state.savedAsDraft = false
            state.savedAsTemplate = false
            state.taEditDirty = true
        },

        setTaCustomPpe(state, action: PayloadAction<string[]>) {
            state.taEdit.userData.customPpe = action.payload
            state.savedAsDraft = false
            state.savedAsTemplate = false
            state.taEditDirty = true
        },

        setTaWorkers(state, action: PayloadAction<TaWorkerEdit[]>) {
            state.taEdit.workers = action.payload
            state.savedAsDraft = false
            state.savedAsTemplate = false
            state.taEditDirty = true
        },

        setTaWorker(state, action: PayloadAction<{ index: number, worker: Partial<TaWorkerEdit> }>) {
            // Combine new worker data with existing so we can selectively set fields without losing id etc
            state.taEdit.workers[action.payload.index] = {
                ...state.taEdit.workers[action.payload.index],
                ...action.payload.worker
            }
            state.savedAsDraft = false
            state.savedAsTemplate = false
            state.taEditDirty = true
        },

        setTaDraft(state, action: PayloadAction<boolean>) {
            state.taEdit.draft = action.payload
        },

        setTaSavedAsDraft(state, action: PayloadAction<void>) {
            state.savedAsDraft = true
        },

        setTaSavedAsTemplate(state, action: PayloadAction<void>) {
            state.savedAsTemplate = true
        },

        setTaRevision(state, action: PayloadAction<number>) {
            state.taEdit.revision = action.payload
        },

        setTaSuperseded(state, action: PayloadAction<boolean>) {
            state.taEdit.superseded = action.payload
        },

        setTaSupersedes(state, action: PayloadAction<number>) {
            state.taEdit.supersedes = action.payload
        },

        addAvailableCustomHazard(state, action: PayloadAction<string>) {
            if (!state.availableCustomHazards.includes(action.payload)) {
                state.availableCustomHazards = state.availableCustomHazards.concat([action.payload]).sort((a, b) => a.localeCompare(b))
            }
        },

        deleteAvailableCustomHazard(state, action: PayloadAction<string>) {
            state.availableCustomHazards = state.availableCustomHazards.filter(hazard => hazard !== action.payload)
        },

        setTaToolboxMode(state, action: PayloadAction<{ mode: boolean, ta?: number, project?: number | null, site?: number | null, task?: number }>) {

            if (action.payload.mode) {
                // Entering 'toolbox mode'
                if (action.payload.ta) {
                    // If id of an existing ta was supplied, we're in 'review ta' mode
                    state.stTaskAnalysis = stTASKANALYSIS.REVIEW
                } else {
                    // We're in 'create new ta' mode
                    state.stTaskAnalysis = stTASKANALYSIS.START
                }
                state.reviewAfterEdit = false
                state.toolboxMode = true
                state.toolboxTa = action.payload.ta || null
                state.toolboxProject = action.payload.project || null
                state.toolboxSite = action.payload.site || null
                state.toolboxTask = action.payload.task || null

            } else {
                // Exiting 'toolbox mode' - return to start page
                state.stTaskAnalysis = stTASKANALYSIS.START
                state.toolboxMode = false
                state.toolboxTa = null
                state.toolboxProject = null
                state.toolboxSite = null
                state.toolboxTask = null
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const {
    setAll,
    setInitial,
    setTaState,
    setTaEdit,
    setTaEditId,
    setTaSelectedStep,
    setTaSelectedHazard,
    setTaDetails,
    setTaEditDirty,
    setTaSteps,
    setTaStepEditState,
    setSelectedHazardCategories,
    addTaHazard,
    deleteTaHazard,
    setTaHazardControls,
    setTaHazardCustomControls,
    setTaHazardIrConsequence,
    setTaHazardIrLikelyhood,
    setTaHazardRrConsequence,
    setTaHazardRrLikelyhood,
    setTaHazardNeedsReview,
    setTaPpe,
    setTaCustomPpe,
    setTaWorkers,
    setTaWorker,
    setTaDraft,
    setTaSavedAsDraft,
    setTaSavedAsTemplate,
    setTaRevision,
    setTaSuperseded,
    setTaSupersedes,
    addAvailableCustomHazard,
    deleteAvailableCustomHazard,
    setTaToolboxMode

} = pgTaskAnalysis.actions

export default pgTaskAnalysis.reducer