import { PhotoData } from '../components/form/CameraInput'
import { DocInfo } from '../datastore/models'
import { AppDispatch, RootState } from '../redux/store'
import { deleteDoc } from './deleteDoc'
import { mimeTypeToExt } from './mimeTypeToExt'
import { saveDoc } from './saveDoc'

/**
 * Save / update a photo in backend (typically to match user actions in a form, i.e. adding or removing a photo from a record
 * 
 * @param photo         'PhotoData' object as used in form buffer
 * @param companyId     Company to save photo under
 * @param fileName      Filename to use in docInfo record (extension will be added based on blob mime type)
 * @returns 
 */
export const savePhoto = (photo: PhotoData | undefined, fileName: string, docInfo: Partial<DocInfo>
) => async (dispatch: AppDispatch, getState: () => RootState) => {

    // Photo doesn't exist - do nothing
    if (!photo || !docInfo.company) {
        return
    }

    // Photo has been deleted in form - delete photo document
    if (photo.deleted && photo.docId) {
        dispatch(deleteDoc(photo.docId))
        return
    }

    // New photo that hasn't been saved yet - save and return new id
    if (photo.dataUrl && !photo.docId) {

        const blobData = await (await fetch(photo.dataUrl as RequestInfo)).blob()

        return await saveDoc(blobData, {
            ...docInfo as DocInfo,
            fileName: `${fileName}.${mimeTypeToExt(blobData.type)}`,
            path: 'images'

        })(dispatch, getState)
    }

    // None of the above - assume photo is unchanged, just return existing id
    return photo.docId
}
