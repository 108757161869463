import { IonButton, IonFooter } from "@ionic/react"
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { selectTaEdit } from '../../redux/selectors/tas'
import { saveTa } from '../../features/taskAnalysis/saveTa'

import "./TaskAnalysisNav.css"
import { TaEvent, updateSequence } from "../../features/taskAnalysis/taskAnalysisSequence"

const TaskAnalysisNav: React.FC = () => {
    const dispatch = useAppDispatch()
    const taEdit = useAppSelector(selectTaEdit)
    const savedAsDraft = useAppSelector(state=>state.pgTaskAnalysis.savedAsDraft)

    const saveAsDraft = () => {
        dispatch(saveTa())
        dispatch(updateSequence(TaEvent.START_SELECTED))
    }

    return (
        <IonFooter className='TA-nav-footer'>
            <IonButton onClick={() => dispatch(updateSequence(TaEvent.BACK_SELECTED))}>BACK</IonButton>
            <IonButton disabled={!taEdit.draft || savedAsDraft} onClick={saveAsDraft}>Save Draft</IonButton>
            <IonButton onClick={() => dispatch(updateSequence(TaEvent.NEXT_SELECTED))}>NEXT</IonButton>
        </IonFooter>
    )
}

export default TaskAnalysisNav