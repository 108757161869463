import { AppDispatch, RootState } from '../../redux/store'
import { setTaEditDirty, setTaSavedAsTemplate } from '../../redux/slices/pgTaskAnalysis'
import { selectTaEdit } from '../../redux/selectors/tas'
import { saveResource } from '../../datastore'
import { setAlert } from '../../utils/alert'

export const saveTaTemplate = (companyId: number, categoryId: number | null, templateId: number | undefined, templateName: string) => (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState()
    const taEdit = selectTaEdit(state)

    const doSave = () => {
        dispatch(saveResource({
            id: templateId,
            company: companyId,
            name: templateName,
            templateData: {
                name: taEdit.userData.name,
                description: taEdit.userData.description,
                steps: taEdit.userData.steps,
                ppe: taEdit.userData.ppe,
                customPpe: taEdit.userData.customPpe,
            },
            category: categoryId || undefined
        }, 'taTemplates'))

        dispatch(setTaSavedAsTemplate())
        dispatch(setTaEditDirty(false))
    }

    if (templateId) {
        dispatch(setAlert(
            'Existing Template',
            'Template already exists, do you want to overwrite it?',
            [{
                text: 'Cancel',
                role: 'cancel',
                handler: () => true
            },
            {
                text: "Overwrite",
                handler: () => {
                    doSave()
                    return true
                }
            }]
        ))
    } else {
        doSave()
    }
}