import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from '../../redux/store'
import { getResource, getResources } from '../../datastore'
import { HazardCategory, HazardType, JobCategory } from '../../datastore/models'
import TaTemplate from '../../datastore/models/TaTemplate'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'

export interface CategoryContents {
    subcategories: {
        id: number | null,
        categoryText: string,
        contents?: CategoryContents
    }[],
    items: {
        companyId: number,
        id: number,
        readOnly?:boolean,
        itemText: string,
        detail: string
    }[]
}

const selectAllHazardCategories = (state: RootState) => state.resources.hazardCategories
const selectAllJobCategories = (state: RootState) => state.resources.jobCategories

const selectHazardCategoryContentsResources = selectResourcesMemo(['hazardCategories', 'hazardTypes'])
const selectHazardCategoryContentsParams = selectParamsMemo()

/**
 * Get all hazards and / or subcategories of a specified hazard category
 */
export const selectHazardCategoryContents = createSelector([

    selectHazardCategoryContentsResources,
    selectHazardCategoryContentsParams]
    , (resources, params): CategoryContents => {

        const subcategories =
            getResources<HazardCategory>( resources, 'hazardCategories', { eqFilter: { parent: params.categoryId } })
                .map(category => ({
                    id: category.id,
                    categoryText: category.categoryText,
                    contents: params.recurse ?
                        selectHazardCategoryContents(({ resources } as unknown) as RootState, { categoryId: category.id })
                        : undefined
                }))
                .sort((a, b) => a.categoryText.localeCompare(b.categoryText))

        const items =
            getResources<HazardType>( resources, 'hazardTypes', { eqFilter: { category: params.categoryId } })
                .map(hazardType => ({
                    companyId: 1,
                    id: hazardType.id,                    
                    itemText: hazardType.hazardText,
                    detail: hazardType.detail || '',
                }))
                .sort((a,b) => (a.itemText.localeCompare(b.itemText)))

        return {
            subcategories,
            items
        }
    })

const selectJobCategoryContentsResources = selectResourcesMemo(['jobCategories', 'companies', 'taTemplates'])
const selectJobCategoryContentsParams = selectParamsMemo()
// TODO: generalise this - could apply to other things besides ta templates?

export const selectJobCategoryContents = createSelector([
    selectJobCategoryContentsResources,
    selectJobCategoryContentsParams]
    , (resources, params): CategoryContents => {

        const { categoryId, companyId } = params

        const subcategories =
            getResources<JobCategory>( resources, 'jobCategories', { eqFilter: { parent: categoryId } })
                .map(category => ({
                    id: category.id,
                    categoryText: category.categoryText
                }))

        const itemFilter: Record<string, number | null> = {
            category: categoryId
        }
        if (companyId) {
            itemFilter.company = companyId
        }
        const items =
            getResources<TaTemplate>( resources, 'taTemplates', { eqFilter: itemFilter })
                .map(taTemplate => ({
                    companyId: taTemplate.company,
                    id: taTemplate.id || 0,
                    readOnly: taTemplate.company === 1,
                    itemText: taTemplate.name,
                    detail: '',
                }))
                .filter(item => !(item.readOnly && item.itemText === 'default'))

        return {
            subcategories,
            items
        }
    })


const selectCategoryPath = (
    selectAllCategories: (state: RootState) => any,
    selectParams: (state: RootState, id: number | null) => number | null,
    topLevelText: string) =>

    createSelector([selectAllCategories, selectParams], (allCategories, paramCategoryId) => {
        let thisCategoryId = paramCategoryId
        const categoryPath: { id: number | null, categoryText: string }[] = []

        while (thisCategoryId) {
            const category = getResource(allCategories, thisCategoryId) as { id: number, localId: number, categoryText: string, parent: number }
            if (!category) {
                break
            }
            categoryPath.unshift({
                id: category.id,
                categoryText: category.categoryText
            })
            thisCategoryId = category.parent
        }
        categoryPath.unshift({
            id: null,
            categoryText: topLevelText
        })

        return categoryPath
    })

/**
* Returns an array of category levels above and including the specified category (for use in building breadcrumbs etc)
*/
const selectHazardCategoryPathParams = (state: RootState, categoryId: number | null) => categoryId
export const selectHazardCategoryPath = selectCategoryPath(selectAllHazardCategories, selectHazardCategoryPathParams, /*"Select a category of hazard:"*/'')

const selectJobCategoryPathParams = (state: RootState, categoryId: number | null) => categoryId
export const selectJobCategoryPath = selectCategoryPath(selectAllJobCategories, selectJobCategoryPathParams, 'All Job Categories')


