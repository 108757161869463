import { AppDispatch, RootState } from "../../redux/store"
import { selectSiteUsers } from "../../redux/selectors/users"
import { requestResources } from "../../datastore"
import { useTask } from "../../hooks/useTask"
import { ResourceEvent } from "../../datastore/types"
import { useAppSelector } from "../../redux/hooks"

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results
 *
 * @returns  requested resources
 */
export const useRequestUsersBySite = (siteId: number | null) => {
  const taskResult = useTask(
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      const result = await requestResources("siteUsers", {
        eqFilter: { site: siteId },
        include: ["user"]
      })(dispatch, getState, services)

      //    const state = getState()
      return {
        isSuccess: result !== ResourceEvent.RESOURCE_LOAD_ERROR
        /*        
        data: {
          users: selectSiteUsers(state, { siteId: siteId })
        }
*/
      }
    },
    [siteId],
    !!siteId
  )

  return {
    //users: (taskResult.data?.users || []) as User[],
    // Note: select users here (rather than in task) so that we get whatever we have in the store straight away
    //users: useAppSelector(state => selectSiteUsers(state, { siteId })),
    siteUsers: useAppSelector((state) =>
    selectSiteUsers(state, { siteId })),
    isLoading: taskResult.isLoading,
    isSuccess: taskResult.isSuccess,
    isError: taskResult.isError
  }
}
