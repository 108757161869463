const ppeTypes = {
  table: 'ppe_types',
  idKey: 'id',

  attrs: [
    'name',
    'icon',
    'description',
    'creationDate',
    'lastModifiedDate'
  ],

  relationships: [   
    {
      type: 'companies',
      key: 'company',
    },
  ],
}

export default ppeTypes