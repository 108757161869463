import { AppDispatch, RootState } from "../../redux/store"
import { requestResources } from "../../datastore"
import { setRequestComplete, setRequestStart } from "../../redux/slices/resourceMeta"
import { selectProjects } from "../../redux/selectors/projects"

export const requestSiteProjects = (params: { [key: string]: any }) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const { siteId, requestKey } = params

    if (requestKey) {
        dispatch(setRequestStart({ requestKey }))
    }

    const result = await requestResources('projectSites', {
        eqFilter: { site: siteId },
        include: ['project']
    })(dispatch, getState, services)


    if (requestKey) {
        dispatch(setRequestComplete({ requestKey, resourceEvent: result }))
    }
    return result
}

export const getSiteProjects = (params: { [key: string]: any }) => (dispatch: AppDispatch, getState: () => RootState) => {
    const siteId = params.siteId
    const state = getState()

    return {
        projects: selectProjects(state, { siteId })
    }
}