import { IonButtons, IonMenuButton, IonToolbar } from '@ionic/react';
import { useAppDispatch } from '../redux/hooks'
import { setRedirectPath } from '../redux/slices/temp';
import './NavBar.css';

interface NavProps {
    pageTitle?: string,
    pageTitleSmall?: string,
    tapeColor?: string,
    iconPath?: string,
    homeAction?: () => void
}

const NavBar: React.FC<NavProps> = ({ pageTitle, pageTitleSmall, tapeColor, iconPath, homeAction }) => {
    const dispatch = useAppDispatch()

    return (
        <>
            <IonToolbar id="nav-bar">
                <div id="title-container">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={homeAction ? homeAction : () => dispatch(setRedirectPath('/home'))}>
                        <div id="icon-grid">
                            {iconPath
                                ? <>
                                    <img alt='cone' className="lower-icon" src="./assets/ui_images/oss_cone.svg" />
                                    <div className="upper-icon">
                                        <img alt='' src={iconPath}></img>
                                    </div>
                                </>
                                : <>
                                    <img alt='cone' className="full-icon" src="./assets/ui_images/oss_cone.svg" />
                                </>
                            }
                        </div>
                    </a>
                    {pageTitle
                        ? <h1 id="page-title">{pageTitle}</h1>
                        : pageTitleSmall
                            ? <h2 id="page-title">{pageTitleSmall}</h2>
                            : <img alt='OSS Title' id="oss-title-text" src="./assets/ui_images/attention_safety_title_black.svg"></img>                        
                    }
                </div>
                <IonButtons slot="end">
                    <IonMenuButton id="menu-button">
                        {/* <IonIcon slot="icon-only" icon={menu}></IonIcon> */}
                        <div id="lines-container">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </IonMenuButton>
                </IonButtons>
            </IonToolbar>
            <div id="divider-tape" style={{ "backgroundColor": tapeColor }}></div>
        </>
    );
}

export default NavBar;