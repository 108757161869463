/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import Axios, { AxiosError } from 'axios'
import { DocInfo } from '../datastore/models'
import { getApiRoot } from '../helpers/getApiRoot'
import { ApiResult } from '../interfaces/api'
import { getAccessToken } from './accessToken'
import { updateOnlineStatus } from './onlineStatus'

export async function addDocument(docInfo: DocInfo, fileData: any, options = {})
    : Promise<{ result: ApiResult, status: number, responseData?: DocInfo | null }> {

    try {
        const formData = new FormData()
        formData.append('doc-info', JSON.stringify(docInfo))
        formData.append('file', fileData, docInfo.fileName)

        const response = await Axios.post(`${getApiRoot()}documents/${docInfo.company}`, formData, {
            ...options,
            headers: {
                'Authorization': `Bearer ${getAccessToken()}`,
                "Content-Type": "multipart/form-data",
            },
        })
        updateOnlineStatus(ApiResult.SUCCESS)
        return {            
            result: ApiResult.SUCCESS,            
            responseData: response.data,
            status: response.status
        }
    } catch (err: any | AxiosError) {
        if (Axios.isAxiosError(err) && err.response) {
            // API responded with error code
            if (err.response.status === 401) {
                // We will get 401 unauthorized if the token is missing or invalid
                // Note API will also return 403 for requests where the user is authed but doesn't have permission on a specific resource
                return { result: ApiResult.AUTH_ERROR, status: 401 }

            } else if (err.response.status >= 400 && err.response.status <= 499) {
                return { result: ApiResult.CLIENT_ERROR, status: err.response.status}
            }
            return { result: ApiResult.SERVER_ERROR, status: err.response.status}

        } else {
            // Request failed or no response from API
            // Probably means backend is down or no internet connection
            updateOnlineStatus(ApiResult.NETWORK_ERROR)
            return { result: ApiResult.NETWORK_ERROR, status: 0 }
        }
    }
}