import React from 'react'
import { FieldError, UseFormWatch, UseFormSetValue } from "react-hook-form"
import { IonIcon, IonLabel } from '@ionic/react'
import { camera } from 'ionicons/icons'
import { useCamera } from '../../hooks/Camera'
import './CameraInput.css'

export interface PhotoData {
    docId?: number
    dataUrl?: string     // Store image data as dataUrl so that we can keep in edit buffer - redux doesn't like blobs
    deleted?: boolean
}

interface InputProps {
    id?: string
    label?: string
    fieldName: string
    placeholder?: string
    watch: UseFormWatch<any>
    setValue: UseFormSetValue<any>
    error?: FieldError
}

const CameraInput: React.FC<InputProps> = ({ id, label, fieldName, placeholder, watch, setValue, error }) => {

    const { takePhoto } = useCamera()
    const value = watch(fieldName)
    const onChange = (result: { dataUrl?: string }) => {
        setValue(fieldName, result, { shouldDirty: true })
    }

    return (
        <div id={id}>
            {((!value?.dataUrl && !value?.docId) || value?.deleted) ?
                <div className="camera-input-container" onClick={async () => {
                    const photo = await takePhoto()
                    if (photo) {
                        onChange({ dataUrl: photo.dataUrl })
                    }
                }}>
                    <IonIcon size="large" icon={camera} />
                    {label ? <IonLabel class="oss-input-label" style={{ "fontStyle": "italic" }}>
                        {label}</IonLabel> : <></>}
                    {placeholder ? <span>{placeholder}</span> : <></>}
                </div>
                : <></>
            }

            <div className="oss-input-error-message">{error?.message}</div>
        </div>
    )
}

export default CameraInput