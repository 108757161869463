import { useRef } from "react"
import { AppDispatch, RootState } from "../../redux/store"
import { getDoc } from "../../docstore"
import { blobToBase64 } from "../../helpers/blobToBase64"
import { requestDocs } from "../../docstore/requestDocs"
import { useTask } from "../../hooks/useTask"
import { getResource } from "../../datastore"
import { DocInfo } from "../../datastore/models"
import { pdfToImage } from "./pdfToImage"

/**
 * Hook to perform the following:
 *
 * - Retrieve a collection of images from supplied ids and store in component state
 * - Return 'getImgData' function, which containing page can call to get image data for display
 *
 */
export const useImages = (imageIds: { savedId: number }[]) => {
  const imgData = useRef<Record<string, string>>({})

  const taskResult = useTask(
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      const ids = imageIds.map((image) => image.savedId)
      // add blank 'placeholders' to imgData for any images we don't already know about
      ids.forEach((id) => {
        const key = id.toString()
        if (!imgData.current.hasOwnProperty(key)) {
          imgData.current[key] = ""
        }
      })

      // request data for images
      const imagesToGet = Object.keys(imgData.current)
        .filter((key) => !imgData.current[key])
        .map((key) => +key)

      await requestDocs({ inFilter: { id: imagesToGet } })(
        dispatch,
        getState,
        services
      )

      await Promise.all(
        imagesToGet.map(async (id) => {
          const docInfo = getResource<DocInfo>(getState().resources.docInfo,id)
          const extension = docInfo?docInfo.fileName.split(".").pop():''      
          const docResult = await getDoc(id)(dispatch, getState)
          if (extension === "pdf") {
            imgData.current[id.toString()] = await pdfToImage(await blobToBase64(docResult.docBlob))
          } else {
            imgData.current[id.toString()] = await blobToBase64(docResult.docBlob)
          }
        })
      )

      return {
        isSuccess: true,
        data: {}
      }
    },
    [imageIds],
    !!imageIds?.length
  )

  return {
    getImgData: (id: number) => {
      const key = id.toString()
      if (!imgData.current[key] || imgData.current[key].length < 6) {
        return null
      }
      return imgData.current[key]
    },
    imagesReady: taskResult.isSuccess,
    isLoading: taskResult.isLoading,
    isError: taskResult.isError
  }
}
