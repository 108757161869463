import { ResourceEvent } from "./types";

/**
 * Derive a single overall 'ResourceEvent' from the results of a collection of resource requests
 * 
 * @param primary Result of requesting the primary resource to which other resources are related; set to ResourceEvent.RESOURCE_VALID if resources are unrelated
 * @param related Array of results from requesting one or more resources related to the primary resource
 * @returns 
 */
export const resourceEventCombined = (primary:ResourceEvent, related:ResourceEvent[]):ResourceEvent => {
    if (primary !== ResourceEvent.RESOURCE_VALID) {
        // If we didn't get 'RESOURCE_VALID' for the primary resource then we don't really care about related resources
        return primary
    }    
    if (related.filter(result => result === ResourceEvent.RESOURCE_LOAD_ERROR).length) {
        // If any of the related resource requests returned an error then combined result is error
        return ResourceEvent.RESOURCE_LOAD_ERROR
    }
    return ResourceEvent.RESOURCE_VALID
}