import { formatResource } from './json-api/helpers/formatResource'
import { LOCAL_ID_START, queueWrite } from '../redux/slices/resourceMeta'
import { removeResource, ResourceType } from '../redux/slices/resources'
import { AppDispatch, RootState } from '../redux/store'
import { getResourceIds } from './getResourceIds'
import { processWriteQueue } from './saveResource'
import log from 'loglevel'

export const deleteResource = (resourceId: number | undefined, type: ResourceType) => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()

    let { keyId } = getResourceIds(state.resources[type as keyof typeof state.resources], resourceId)
    if (!keyId) {
        log.warn(`deleteResource:${type} id ${resourceId} doesn't exist`)
        return
    }

    // Delete local state copy of resource
    dispatch(removeResource({ resourceType: type, id: keyId.toString() }))

    if (keyId < LOCAL_ID_START) {

        const normalizedResource = formatResource({ id: keyId, deleted: true }, type, keyId)

        // Add resource to queue for writing to API
        dispatch(queueWrite(normalizedResource))

        dispatch(processWriteQueue())
    }
}