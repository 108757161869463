import {
  IonButton,
  IonContent,
  IonHeader,
  IonItemDivider,
  IonList,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react"
import log from "loglevel"

import { useAppSelector, useAppDispatch } from "../redux/hooks"
import { login, logout } from "../features/auth"
import { signoutWithConfirm } from "../features/signin/signout"
import { selectSignedinSiteId } from "../redux/selectors/signins"
import { selectSite } from "../redux/selectors/sites"
import { setRedirectPath } from "../redux/slices/temp"
import "./Menu.css"
import { setAlert } from "../utils/alert"
import { closeToolbox } from "../features/toolbox/closeToolbox"
import { stTOOLBOX } from "../features/toolbox/toolboxTypes"
import { openInBrowser } from "../services/DocumentBrowser"

const Menu: React.FC = () => {
  const { user } = useAppSelector((state) => state.app)
  const { stToolbox } = useAppSelector((state) => state.pgToolbox)
  const signedinSiteId = useAppSelector((state) => selectSignedinSiteId(state))
  const site = useAppSelector((state) =>
    selectSite(state, { siteId: signedinSiteId || undefined })
  )
  const dispatch = useAppDispatch()

  let userString = "Not logged in"

  if (user) {
    if (user.isGuest) {
      userString = `Signed in as ${user.firstName} ${user.lastName} (guest)`
    } else {
      userString = `Logged in as ${user.firstName} ${user.lastName}`
    }
  }

  const logoutSelected = () => {
    // TODO: other confirmation prompts, i.e. unsaved data that will be lost

    if (
      stToolbox !== stTOOLBOX.START &&
      stToolbox !== stTOOLBOX.REGISTER &&
      stToolbox !== stTOOLBOX.REVIEW
    ) {
      // User is trying to sign out while part way through a toolbox meeting
      dispatch(
        setAlert(
          "Toolbox in Progress",
          "You have a toolbox meeting in progress, if you log out it will be closed",
          [
            {
              text: "Cancel",
              role: "cancel"
            },
            {
              text: "Close Toolbox and Log Out",
              handler: () => {
                dispatch(closeToolbox())
                dispatch(logout())
                return true
              }
            }
          ]
        )
      )
    } else {
      log.info(`logout initiated by user ${user!.firstName} ${user!.lastName}`)
      dispatch(logout())
    }
  }

  return (
    <IonMenu
      id="side-menu"
      menuId="side-menu"
      contentId="router-outlet"
      side="end"
      type="overlay"
    >
      <IonHeader>
        <div className='safe-area-top'></div>
      </IonHeader>
      <IonContent>
        {/* Profile */}
        <IonList class="menu-list">
          <h1>Profile</h1>
          <p>{userString}</p>

          {/* Display logout if non-guest user is logged in */}
          {user && !user.isGuest ? (
            <IonMenuToggle autoHide={false}>
              <IonButton className="menu-button" onClick={logoutSelected}>Log Out</IonButton>
            </IonMenuToggle>
          ) : (
            <></>
          )}
          {/* Display login if no user is logged in */}
          {!user ? (
            <IonMenuToggle autoHide={false}>
              <IonButton className="menu-button" onClick={() => dispatch(login())}>Log In</IonButton>
            </IonMenuToggle>
          ) : (
            <></>
          )}

          {/* Display user settings if non-guest user is logged in */}
          {/* 
                    {user && !user.isGuest ?
                        <IonMenuToggle autoHide={false}>
                            <IonButton>User settings</IonButton>
                        </IonMenuToggle>
                        : <></>
                    }
                    */}

          {/* Special testing options */}
          {/* 
                    {user && user.id === 6 ?
                        <>
                            <IonMenuToggle autoHide={false}>
                                <IonButton onClick={() => setAccessToken('BadToken')}>Expire Token</IonButton>
                            </IonMenuToggle>                            
                        </>
                        : <></>
                     }
                    */}
          <IonItemDivider></IonItemDivider>
        </IonList>
        {/* Site */}
        <IonList class="menu-list">
          <h1>Site</h1>
          {site ? (
            <>
              <p>
                Signed into site:
                <br />
                {site.siteName}
              </p>
              <IonMenuToggle autoHide={false}>
                <IonButton
                  className="menu-button"
                  onClick={() => dispatch(signoutWithConfirm(site?.siteName))}
                >
                  Tap to sign out of site
                </IonButton>
              </IonMenuToggle>
            </>
          ) : (
            <>
              <p>Not signed into a site</p>
              <IonMenuToggle autoHide={false}>
                <IonButton className="menu-button" onClick={() => dispatch(setRedirectPath("/signin"))}>
                  Tap to sign in to site
                </IonButton>
              </IonMenuToggle>
            </>
          )}
          <IonItemDivider></IonItemDivider>
        </IonList>

        <IonList class="menu-list">
          <h1>Help</h1>
          <IonButton
            className="menu-button-help"
            onClick={() => {
              openInBrowser("https://buildaprice.zendesk.com/hc/en-us/sections/7230765097871-On-Site-Safety-App")
            }}
          >
            Tap for APP HELP
          </IonButton>

          <p>Have questions? Tap to access our online searchable HELP guide</p>

        </IonList>

        {/* App */}
        {/* 
                <IonList class="menu-list">
                    <h1>App</h1>
                    <p>App settings</p>
                    <IonMenuToggle autoHide={false}>
                        <IonButton>Notifications</IonButton>
                    </IonMenuToggle>
                    <IonItemDivider></IonItemDivider>
                </IonList>
                */}
      </IonContent>
    </IonMenu>
  )
}

export default Menu
