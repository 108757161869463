/**
 * Check whether an id matches a given resource.
 * 
 * If comparing ids of locally - generated resources, values may be local IDs or 'real' ids - values need to be 
 * checked against both
 * @param id - id to test
 * @param resource - resource object to compare against, may be LocalResourceData or any flattened resource object (i.e. with id and / or localId attributes)
 * @returns 
 */
 export const idMatchesResource = (id:number | null | undefined, resource:any) => {
    if (!id || !(typeof resource === 'object')) {
        return false
    }
    return (resource.id && id === +resource.id) || (!isNaN(resource.localId) && id === resource.localId)
}