import { IonContent, IonPage } from "@ionic/react"
import log from "loglevel"
import { useEffect } from "react"
import { handleLoginCallback } from "../features/auth/handleLoginCallback"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { setRedirectPath } from "../redux/slices/temp"
import "./Splash.css"

const Callback: React.FC = () => {
  const dispatch = useAppDispatch()
  const { appOpenUrl, currentPath } = useAppSelector((state) => state.temp)

  useEffect(() => {
    if (currentPath === "/callback") {
      if (appOpenUrl) {
        dispatch(handleLoginCallback())
      } else {
        log.warn("Callback page loaded with no appOpenUrl")
        dispatch(setRedirectPath("/signin"))
      }
    }
  }, [currentPath, appOpenUrl])

  return (
    <IonPage>
      <IonContent fullscreen id="splash-content">
        <div id="splash-container">
          {/* Cone logo */}
          <img alt="cone" src="./assets/ui_images/oss_cone.svg"></img>
          <div id="tape-title">
            {/* Tape divider */}
            <div></div>
            {/* OSS text */}
            <img
              alt="Attention Safety - Go Home Safe Every Day"
              src="./assets/ui_images/attention_safety_title_white.svg"
            ></img>
          </div>
          <br />
          <br />
          <div className="login-text">Logging In...</div>
          {/* Builda Price logo */}
          <div id="bp-logo-grid">
            {/* <span id="bp-advert-text">Brought to you by</span>
                        <div id="bp-advert-icon">
                            <img alt="" src="./assets/ui_images/Builda_Price_Logo_Icon.png"></img>
                        </div>
                        <div id="bp-advert-title">
                            <img alt="Builda Price" src="./assets/ui_images/Builda_Price_Logo_Text.png"></img>
                        </div> */}
            <img
              alt="Brought to you by Builda Price"
              src="./assets/ui_images/BP_Brought_to_you_Logo_white.svg"
            ></img>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Callback
