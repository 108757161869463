import { AppDispatch, RootState } from "../../redux/store"
import { requestResources } from "../../datastore"
import { selectSite } from "../../redux/selectors/sites"
import { selectCompany } from "../../redux/selectors/companies"
import { setRequestComplete, setRequestStart } from "../../redux/slices/resourceMeta"

export const requestSite = (params: { [key: string]: any }) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const { siteId, requestKey } = params

    if (requestKey) {
        dispatch(setRequestStart({ requestKey }))
    }

    const result = await requestResources('sites', { eqFilter: { id: siteId } })(dispatch, getState, services)

    if (requestKey) {
        dispatch(setRequestComplete({ requestKey, resourceEvent: result }))
    }
    return result
}

export const getSite = (params: { [key: string]: any }) => (dispatch: AppDispatch, getState: () => RootState) => {
    const siteId = params.siteId
    const state = getState()

    const site = selectSite(state, { siteId })
    if (!site) {
        return null
    }
    const company = selectCompany(state, { companyId: site.company })

    return {
        site: {
            ...site,
            company
        }
    }
}