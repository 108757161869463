import { isValid, parseJSON } from "date-fns"

/**
 * 'safe' date string parsing - parse string-formatted date without breaking if string is malformed / missing / null etc
 * 
 * @param date Should normally be ISO formatted date string
 * @returns Date or null if date string missing / not valid
 */
const parseDate = (date:string | Date | null | undefined) => {
    if (!date) {
        return null
    }
    const dtDate = parseJSON(date)
    if (isValid(dtDate)) {
        return dtDate
    }
    return null
}

export default parseDate