import { useEffect, useRef, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react" ///swiper-react.js";
import { Zoom } from "swiper"
import "swiper/swiper.min.css"
//import "swiper/modules/zoom/zoom.min.css";
//import "swiper/zoom.min.css";
import "./ImgDocContainer.css"
import { blobToBase64 } from "../helpers/blobToBase64"

interface ContainerProps {
  docBlob: Blob
  setUserData?: (userData: Record<string, any>) => void
  setIsValid?: (isValid: boolean) => void
  setIsLoading?: (isLoading: boolean) => void
}

const ImgDocContainer: React.FC<ContainerProps> = ({
  docBlob,
  setUserData,
  setIsValid,
  setIsLoading
}) => {
  const [docData, setDocData] = useState<string>()
  const componentMounted = useRef(true)

  useEffect(() => {
    ;(async () => {
      if (docBlob) {
        const base64Data = await blobToBase64(docBlob)
        if (componentMounted.current) {
          setDocData(base64Data as string)
          if (setIsLoading) {
            setIsLoading(false)
          }
        }
      }
    })()

    // Set user data to nothing, set to 'always valid'
    if (setUserData) {
      setUserData({})
    }
    if (setIsValid) {
      setIsValid(true)
    }
  }, [docBlob, setIsLoading, setIsValid, setUserData])

  // Reset 'component mounted' on unmount
  useEffect(() => {
    componentMounted.current = true
    return () => {
      componentMounted.current = false
    }
  }, [])

  return (
    <Swiper zoom={true} modules={[Zoom]} className="img-doc-container">
      <SwiperSlide>
        <div className="swiper-zoom-container">
          <img alt="" src={docData}></img>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}

export default ImgDocContainer
