import { format, parseJSON } from 'date-fns'
import { IonButton, IonContent, IonFooter } from "@ionic/react"

import { useAppDispatch, useAppSelector } from '../../redux/hooks'

import { SelectList } from '../../components/SelectList'

import { updateSequence } from '../../features/toolbox/toolboxSequence'
import { useState } from 'react'
import { selectSignedinSiteId } from '../../redux/selectors/signins'

import { useSelectToolboxResources } from '../../features/toolbox/resourceRequests/useSelectToolboxResources'
import { setToolboxReviewId } from '../../redux/slices/pgToolbox'
import { ToolboxEvent } from '../../features/toolbox/toolboxTypes'

export const Register: React.FC = () => {
    const dispatch = useAppDispatch()

    const signedinSiteId = useAppSelector(selectSignedinSiteId)
    const [currentSiteOnly, setCurrentSiteOnly] = useState<boolean>(true)

    const { toolboxes } = useSelectToolboxResources(currentSiteOnly ? signedinSiteId : null)

    const selectToolbox = (toolboxId: number | null) => {

        if (toolboxId) {
            dispatch(setToolboxReviewId(toolboxId))
            dispatch(updateSequence(ToolboxEvent.REVIEW_SELECTED))
        }
    }

    // TODO: should toolbox have a homeIconAction?
    //useHomeIconHandler('/task-analysis', () => dispatch(homeIconAction()))

    return (
        <>
            <IonContent class="content-padding">

                <SelectList
                    title='Select a meeting to view:'
                    listData={toolboxes.map(toolbox => ({
                        id: toolbox.id,
                        text: format(parseJSON(toolbox.openDateTime), 'EEE dd MMM yyyy HH:mm')
                    }))}
                    selectOption={selectToolbox}
                />

            </IonContent>
            <IonFooter class="toolbox-nav">
                <IonButton onClick={() => dispatch(updateSequence(ToolboxEvent.START_SELECTED))}>CANCEL</IonButton>
                {signedinSiteId ? <>
                    <IonButton color={currentSiteOnly?'success':'light'} onClick={() => setCurrentSiteOnly(true)}>Current Site</IonButton>
                    <IonButton color={currentSiteOnly?'light':'success'}onClick={() => setCurrentSiteOnly(false)}>All Sites</IonButton>
                </> : <></>}
            </IonFooter>
        </>)
}

