const allTypes= [
    {type: 'image/png', ext:'png'},
    {type: 'image/jpeg', ext:'jpeg'},
    {type: 'image/gif', ext:'gif'},
    {type: 'image/svg+xml', ext:'svg'},
]

export const mimeTypeToExt = (mimeType:string) => {
    const matchTypes = allTypes.filter(type => type.type===mimeType)

    return matchTypes.length?matchTypes[0].ext:'unknown'
    
}