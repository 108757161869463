import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { IonButton, IonContent, IonFooter } from "@ionic/react"

import { useHomeIconHandler } from '../../hooks/homeIconHandler'
import { homeIconAction } from '../../features/taskAnalysis/homeIconAction'
import { SelectList } from '../../components/SelectList'
import { useActiveResources } from '../../features/taskAnalysis/resourceRequests/useActiveResources'
import { loadTa } from '../../features/taskAnalysis/loadTa'

import { TaEvent, updateSequence } from '../../features/taskAnalysis/taskAnalysisSequence'
import { useState } from 'react'
import { selectSignedinSiteId } from '../../redux/selectors/signins'
import { format, parseJSON } from 'date-fns'
import { get } from 'lodash'

export const ActiveRegister: React.FC = () => {
    const dispatch = useAppDispatch()

    const signedinSiteId = useAppSelector(selectSignedinSiteId)
    const [currentSiteOnly, setCurrentSiteOnly] = useState<boolean>(true)

    const { activeTas } = useActiveResources(currentSiteOnly ? signedinSiteId : null)

    const selectTa = (taId: number | null) => {

        if (taId) {
            dispatch(loadTa(taId, false))
        }
    }

    useHomeIconHandler('/task-analysis', () => dispatch(homeIconAction()))

    return (
        <>
            <IonContent class="content-padding">

                <SelectList
                    title='Select a SWMS:'
                    listData={activeTas.map(ta => ({
                        id: ta.id,
                        text: `${format(parseJSON(ta.startDate), 'EEE dd MMM yyyy')}:${get(ta, 'userData.name')}${ta.revision ? ` (rev ${ta.revision})` : ''}`
                    }))}
                    selectOption={selectTa}
                />

            </IonContent>
            <IonFooter className='TA-nav-footer'>
                <IonButton onClick={() => dispatch(updateSequence(TaEvent.START_SELECTED))}>CANCEL</IonButton>
                {signedinSiteId ? <>
                    <IonButton color={currentSiteOnly?'success':'light'} onClick={() => setCurrentSiteOnly(true)}>Current Site</IonButton>
                    <IonButton color={currentSiteOnly?'light':'success'}onClick={() => setCurrentSiteOnly(false)}>All Sites</IonButton>
                </> : <></>}
            </IonFooter>
        </>)
}

