import { ResourceType } from "../redux/slices/resources"

export enum ResourceEvent {
    NONE,
    RESOURCE_LOAD_ERROR,  // Error attempting to get resource from api (auth, network failure, etc) 
    NO_RESOURCE_FOUND,    // Successfully connected to api but resource doesn't exist
    RESOURCE_VALID        // valid copy of requested resource stored in state (either we got it sucessfully from api or we already had a valid offline copy)
}

export interface ResourceFilters {
    eqFilter?: { [key: string]: number | string | boolean | null | undefined },
    neqFilter?: { [key: string]: number | string | boolean | null | undefined },
    inFilter?: { [key: string]: (number | string | null | undefined)[] },
    periodFilter?: { attr: string, periodStart?: Date | string, periodEnd?: Date | string },
}

export interface ResourceUpdateStatus {
    validTo?: string
    lastUpdated?: string
    lastUpdateResult?: ResourceEvent
    autoRefreshSeconds?: number
    minRefreshSeconds?: number
    autoRequestFilter?: ResourceFilters
    autoRequestRelated?: ResourceType[]
    changeDetectFilter?: ResourceFilters
    expireAfterDays?: number
}

export enum RequestStatus {
    NONE,
    IN_PROGRESS,
    RESOURCE_LOAD_ERROR,  // Error attempting to get resource from api (auth, network failure, etc) 
    NO_RESOURCE_FOUND,    // Successfully connected to api but resource doesn't exist
    RESOURCE_VALID        // valid copy of requested resource stored in state (either we got it sucessfully from api or we already had a valid offline copy)
}

export interface Relationship {
    id: string,
    type: string
}