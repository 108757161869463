/**
 * Simple extraction of content of a specified tag from an html document - used to obtain 'head' and 'body' tag contents
 * 
 * Limitations:
 * - Finds first instance of tag only 
 * - Assumes no space between opening < and start of tag name
 * - Assumes first > after < is end of tag (i.e. cannot use attribute containing '>')
 * - Assumes no space in closing tag
 * 
 * @param html Full html document to extract tag from
 * @param tag name of tag, e.g. 'body'
 * @returns content of tag (without the tag itself)
 */
 export const extractTagContent = (html: string, tag: string) => {
    const openStart = html.indexOf(`<${tag}`)
    const openEnd = html.indexOf('>', openStart)

    const closeStart = html.indexOf(`</${tag}>`);
  
    if (openStart > -1 && openEnd > -1 && closeStart > -1) {
      return html.slice(openEnd + 1, closeStart);
    } else {
      return '';
    }
  };

  /**
   * Extract the opening tag itself (i.e. including attributes)
   */
  export const extractTag = (html: string, tag: string) => {
    const openStart = html.indexOf(`<${tag}`)
    const openEnd = html.indexOf('>', openStart)

    if (openStart > -1 && openEnd > -1) {
        return html.slice(openStart, openEnd + 1);
      } else {
        return '';
      }
  }