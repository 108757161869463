import { camelCase, get } from 'lodash'
import { Attributes } from "@disruptph/json-api-normalizer"
import log from 'loglevel'

import { AppDispatch, RootState } from '../redux/store'
import { LOCAL_ID_START, queueWrite, setWriteInProgress, setWriteResult } from '../redux/slices/resourceMeta'
import { getResourceIds } from './getResourceIds'
import { ApiService, ApiResult } from "../interfaces/api"
import { removeResource, ResourceType, setResource } from "../redux/slices/resources"
import { formatResource } from './json-api/helpers/formatResource'
import { getUpdatedRelationships } from './helpers/getUpdatedRelationships'
import { createReactor } from "../redux/selectors/helpers/createReactor"
import { getNextId } from './getNextId'
import { writeDocToBackend } from '../docstore/saveDoc'
import { DocInfo } from "./models"
import { setAlert } from "../utils/alert"

/**
 * Save a resource, both to redux store and back end
 * 
 * Note - this currently only handles a single resource item
 * 
 * holdProcessing: if true, add request to the queue but don't dispatch processWriteQueue
 * Needed when saving a document, as writeDocToBackend needs to call saveResource (to get id), 
 * write the document itself to storage, THEN dispatch processWriteQueue
 */
export const saveResource = (resource: any, type: string, holdProcessing?: boolean) => (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState()

    let { keyId, localId } = getResourceIds(state.resources[type as keyof typeof state.resources], resource.id)

    if (!keyId) {
        // No id supplied - generate a new one
        const newId = getNextId(dispatch, getState)
        keyId = newId
        localId = newId
    }

    const normalizedResource = formatResource(resource, type, keyId)

    if (!Object.keys(normalizedResource).length) {
        // Empty object - nothing to save
        log.error('saveResource: no resource type to save')
        return
    }

    const keys = Object.keys(normalizedResource[type])
    if (!keys.length) {
        // No resource - nothing to save
        log.error('saveResource: no resource to save')
        return
    }

    if (keys.length > 1) {
        // Attempt to save more than one resource
        log.error('saveResource: Attempt to save more than one resource at a time')
        return
    }

    const resourceItem = normalizedResource[type][keys[0]]
    const sKeyId = keyId.toString()

    const normalizedResourceLocalId = {
        [type]: {
            [sKeyId]: {
                type,
                id: sKeyId,
                attributes: {
                    ...resourceItem.attributes,
                    lastModifiedDate: new Date().toISOString()
                },
                relationships: getUpdatedRelationships(resourceItem.relationships, getState),
                localId: localId
            }
        }
    }

    // write resource to redux store (including localId field)
    dispatch(setResource(normalizedResourceLocalId))

    // Add resource to queue for writing to API
    dispatch(queueWrite(normalizedResource))

    if (!holdProcessing) {
        dispatch(processWriteQueue())
    }

    return Number(keys[0]) || undefined
}

const updateReportImageIds = (attributes: Attributes, getState: () => RootState) => {

    try {
        const savedImages = get(attributes.userData, ['images'])

        const images = savedImages ? savedImages.map((image: { savedId: number }) => {
            const { keyId } = getResourceIds(getState().resources.docInfo, image.savedId)
            return {
                savedId: image.savedId,
                id: keyId
            }
        }) : []

        return {
            ...attributes,
            userData: {
                ...attributes.userData,
                images
            }
        }
    } catch (e) {
        // If for some reason we couldn't find / parse / stringify userData, return original attributes unchanged
        return attributes
    }
}

/**
 * Performs queued writes to API
 * 
 * Processes items until:
 * - the queue is empty
 * - a request fails
 * 
 * Dispatched when:
 * - an action writes a resource
 * - periodically to retry any writes that have previously failed
 */
export const processWriteQueue = () => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    const api: ApiService = services.api

    let state = getState()
    let { writeInProgress, writeQueueHead } = state.resourceMeta

    // Don't attempt any writes if: 
    // - a write is already in progress (could mean function has been called a second time before completing)
    // - startup in progress (may not have a token yet)
    // - no user logged in (api call will fail if no token...)
    // - we're offline (no internet connection)
    if (writeInProgress || state.temp.startupInProgress || !state.app.user || !api.isOnline()) {
        return
    }

    while (writeQueueHead) {

        dispatch(setWriteInProgress())

        const type = Object.keys(writeQueueHead)[0] as ResourceType
        const key = Object.keys(writeQueueHead[type])[0]

        if (!type || !key) {
            log.error('Error - bad entry in resource write queue')
            // Pretend entry was written successfully so it will be discarded
            dispatch(setWriteResult(true))
            writeQueueHead = getState().resourceMeta.writeQueueHead
            continue
        }

        state = getState()

        const { keyId, localId } = getResourceIds(state.resources[camelCase(type) as keyof typeof state.resources], +key)
        if (!keyId) {
            log.error('Error - bad entry in resource write queue, type:', type, 'key:', key)
            // Pretend entry was written successfully so it will be discarded
            dispatch(setWriteResult(true))
            writeQueueHead = getState().resourceMeta.writeQueueHead
            continue
        }
        const sKeyId = keyId.toString()
        const queueHead = writeQueueHead[type][key]
        let saveResourceResult

        // Special case - if saving document, save doc info and document itself together via documents api
        // rather than saving docInfo record explicitly
        if (type === 'docInfo' && !queueHead.attributes.deleted) {
            saveResourceResult = await writeDocToBackend({
                id: keyId,
                ...queueHead.attributes,
                company: get(queueHead, ['relationships', 'company', 'data', 'id']),
                project: get(queueHead, ['relationships', 'project', 'data', 'id']),
                site: get(queueHead, ['relationships', 'site', 'data', 'id'])
            } as DocInfo, dispatch, getState, services)

        } else {

            // Special case - if saving notification, need to add 'real' ids for images referenced in reportData
            // Can only do this here as 'real' ids are not known until images have been saved
            const attributes = (type as string === 'notifications')
                ? updateReportImageIds(queueHead.attributes, getState)
                : queueHead.attributes

            const relationships = getUpdatedRelationships(queueHead.relationships, getState)
            if (Object.keys(relationships).some(key => +get(relationships, [key, 'data', 'id']) >= LOCAL_ID_START)) {
                saveResourceResult = {
                    result: ApiResult.CLIENT_ERROR
                }
                log.error('Resource write failed, type:', type, 'key:', key, ' - id of one or more related resources not available')

            } else {
                const saveResource = {
                    [type]: {
                        [sKeyId]: {
                            id: sKeyId,
                            type,
                            attributes,
                            relationships: relationships
                        }
                    }
                }

                if (keyId && keyId < LOCAL_ID_START) {
                    // Resource has a database id => it already exists on server
                    saveResourceResult = await api.apiPatchResource(saveResource)
                } else {
                    // Resource has local id or no id => new resource, needs to be added rather than updated
                    saveResourceResult = await api.apiPostResource(saveResource)
                }

            }
        }

        if (saveResourceResult?.result === ApiResult.NETWORK_ERROR) {
            // Request failed due to a network error:
            // -> set result as 'false' so the request will be left on the queue and retried.
            // (If it failed for some other reason we will continue with the next request regardless - we don't want to risk the write queue 
            // being blocked forever by a request that can't succeed)
            dispatch(setWriteResult(false))
            break
        }

        if (saveResourceResult?.result === ApiResult.SUCCESS) {
            // Resource was successfully saved to database
            // -> add the previous id as 'localId' before updating in local state
            const resourceOfType = get(saveResourceResult, ['responseData', type], {})
            const ids = Object.keys(resourceOfType)
            if (ids.length) {
                const savedId = ids[0]
                const savedResource = get(resourceOfType, savedId)

                if (typeof savedResource === 'object' && !savedResource.attributes.deleted) {
                    const updatedResource = {
                        [type]: {
                            [savedId]: {
                                ...savedResource,
                                localId
                            }
                        }
                    }

                    // Update resources slice with new version of resource returned by API
                    dispatch(setResource(updatedResource))

                    // If resource might have been previously in the store under the previous local id,
                    // delete the old version
                    if (key !== savedId) {
                        dispatch(removeResource({ resourceType: type, id: key }))
                    }
                }
            }
        } else {
            log.error(`processWriteQueue: failed to save resource of type ${type}, result:${saveResourceResult?.result}`)
            showWarningMessage(type, dispatch)
        }

        // Flag that request succeeded (clears writeInProgress flag and shifts next queue item into writeQueueHead)
        dispatch(setWriteResult(true))
        writeQueueHead = getState().resourceMeta.writeQueueHead

    }
}

const showWarningMessage = (resourceType: ResourceType, dispatch: AppDispatch) => {

    switch (resourceType) {
        case 'incidents':
            dispatch(setAlert(
                'Save Error',
                'There was a problem saving the incident report'
            ))
            break

        case 'siteObservations':
            dispatch(setAlert(
                'Save Error',
                'There was a problem saving the site observation'
            ))
            break

        case 'tas':
            dispatch(setAlert(
                'Save Error',
                'There was a problem saving the SWMS'
            ))
            break

        case 'toolboxes':
            dispatch(setAlert(
                'Save Error',
                'There was a problem saving the toolbox meeting'
            ))
            break
    }
}

    /**
     * Write queue processing 'Reactor' 
     * 
     * Will run every 10 seconds on change in temp.appTime
     * 
     * @returns thunk to be dispached if an update is due
     */
    export const updateWriteQueueReactor = createReactor(
        [(state: RootState) => state.temp.appTime],
        (appTime) => { return processWriteQueue() }
    )