import { useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react"
import { useRef } from "react"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { setRedirectPath } from "../redux/slices/temp"
import log from "loglevel"

/**
 * Hook to set maximum time that a page can be displayed
 * 
 * For use on spash screen / logging out screen which should only be displayed 
 * briefly between states - ensures UI doesn't get stuck on a page user can't get out of 
 * if an event the page is waiting for doesn't occur
 * 
 * @param timeout Maximum time in ms that page should be displayed
 * @param goToPath Path to change to if timeout is exceeded
 */
export const usePageTimeout = (timeout: number, goToPath: string) => {
  const dispatch = useAppDispatch()

  const { currentPath } = useAppSelector((state) => state.temp)
  const pageShown = useRef(false)

  useIonViewWillEnter(() => {
    if (!pageShown.current) {
      pageShown.current = true
      setTimeout(() => {
        if (pageShown.current) {
          log.warn(`Page ${currentPath} timed out`)
          dispatch(setRedirectPath(goToPath))
        }
      }, timeout)
    }
  })

  useIonViewWillLeave(() => {
    pageShown.current = false
  })
}
