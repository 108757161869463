const hazardCategories = {
    table: 'hazard_categories',
    idKey: 'id',
  
    attrs: [        
      'company',
      'categoryText',
      'detail',
      'creationDate',
      'lastModifiedDate',
      'deleted'
    ],
  
    relationships: [ 
      {
        type: 'hazard-categories',
        key: 'parent',
      }
    ],
  }
  export default hazardCategories