const users = {
  table: 'users',
  idKey: 'id',

  attrs: [
    'sub',
    'firstName',
    'lastName',    
    'phone',  
    'email',
    'isGuest',
    'guestCompany',
    'logLevels',
    'linkedUser',
    'linkedWorker',
    'companies',
    'projects',
    'superviseProjects',
    'termsAgreedDate',
    'creationDate',
    'lastModifiedDate',    
    'active',
    'deleted'
  ],

  relationships: [],
}

export default users