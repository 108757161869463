import { createSelector } from '@reduxjs/toolkit'
import { DocInfo } from '../../datastore/models'
import { LoadStatus } from '../../redux/slices/docMeta'
import type { RootState } from '../../redux/store'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { getResource, getResources } from '../../datastore'
import { getDocLoadStatus } from '../../docstore'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'

const selectAllDocInfo = (state: RootState) => state.resources.docInfo

const selectDocInfoResources = selectResourcesMemo(['companies', 'docInfo'])
const selectDocInfoParams = selectParamsMemo()
/**
 * Gets list of docInfo entries, as an array
 * If company supplied, will filter results to the specified company
 * 
 * @param state 
 * @returns DocInfo[]
 */
export const selectDocInfo = createSelector([selectDocInfoResources, selectDocInfoParams], (resources, params) => {

    if (params.docInfoId) {    
        const docListItem = getResource<DocInfo>(resources.docInfo, params.docInfoId)
        return docListItem? [docListItem] : []
    }
    return getResources<DocInfo>(resources, 'docInfo', {eqFilter:{company :params.companyId}})
})

const selectAllDocStatus = (state: RootState) => state.docMeta.docStatus
/**
 * Gets list of all docInfo entries whos documents are loaded, as an array
 * If company supplied, will filter results to the specified company
 * @param state 
 * @returns DocInfo[]
 */
export const selectLoadedDocInfo = createSelector([selectAllDocInfo, selectDocInfo, selectAllDocStatus], (allDocInfo, docInfo, allDocStatus) => {
    if (!docInfo || !allDocStatus) {
        return []
    }
    return docInfo.filter(doc => getDocLoadStatus(allDocInfo, allDocStatus, doc.id!) === LoadStatus.COMPLETE)
})

const selectDocInfoId = (state:RootState, id:number) => id
/**
 * Gets 'loaded' status for a specified DocInfo record
 */
export const selectDocInfoLoaded = createSelector([selectAllDocInfo, selectAllDocStatus, selectDocInfoId],(allDocInfo, allDocStatus, docInfoId) => {

    return getDocLoadStatus(allDocInfo, allDocStatus, docInfoId) === LoadStatus.COMPLETE
})

/**
 * Returns true if all documents required for signin have been loaded
 * @param state 
 * @returns 
 */
const selectSiteSigninDocList = (state: RootState) => state.pgSignin.docList
export const selectAllSiteSigninDocsLoaded = createSelector([selectAllDocInfo, selectAllDocStatus, selectSiteSigninDocList], 
    (allDocInfo, allDocStatus, siteSigninDocList) => {
    try {
        const incompleteDocs = siteSigninDocList.filter(doc => getDocLoadStatus(allDocInfo, allDocStatus, doc.docInfo) !== LoadStatus.COMPLETE)
        return (incompleteDocs.length === 0)

    } catch {
        return false
    }
})

/**
 * Returns true if no documents required for signin are still loading
 * (i.e. all either succeeded or failed)
 * @param state 
 * @returns 
 */
export const selectNoSiteSigninDocsLoading = createSelector([selectAllDocInfo, selectAllDocStatus, selectSiteSigninDocList],
    (allDocInfo, allDocStatus, siteSigninDocList) => {
    try {
        const docsLoading = siteSigninDocList.filter(doc => {
            const loadStatus = getDocLoadStatus(allDocInfo, allDocStatus, doc.docInfo)
            return loadStatus !== LoadStatus.LOAD_ERROR && loadStatus !== LoadStatus.COMPLETE
        })
        return docsLoading.length === 0
    } catch {
        return false
    }
})