import { useAppSelector, useAppDispatch } from "../../redux/hooks"
import {
  IonButton,
  IonContent,
  IonFooter,
  //IonToast,
  useIonModal
} from "@ionic/react"
import { taReturnToToolbox } from "../../features/taskAnalysis/taskAnalysisToolbox"
import { SelectCategoryItem } from "../../components/SelectCategoryItem"
import {
  selectJobCategoryContents,
  selectJobCategoryPath
} from "../../redux/selectors/categories"
import {
  selectTaTemplate,
  selectTaTemplateFromName
} from "../../redux/selectors/taTemplates"
import DocContainer from "../../components/DocContainer"
import { selectTaEdit, selectTaEditLocked } from "../../redux/selectors/tas"
import { useHomeIconHandler } from "../../hooks/homeIconHandler"
import { homeIconAction } from "../../features/taskAnalysis/homeIconAction"
import { saveTa } from "../../features/taskAnalysis/saveTa"
import {
  TaEvent,
  updateSequence
} from "../../features/taskAnalysis/taskAnalysisSequence"
import { editTa } from "../../features/taskAnalysis/editTa"
import { setTaActive } from "../../features/taskAnalysis/setTaActive"
import {
  selectCanCreateAnyTa,
  selectCanEditTa
} from "../../redux/selectors/abilities"
import { useReviewResources } from "../../features/taskAnalysis/resourceRequests/useReviewResources"
import { SelectList } from "../../components/SelectList"
import { saveTaTemplate } from "../../features/taskAnalysis/saveTaTemplate"
import { useRef, useState } from "react"
import { resendTa } from "../../features/taskAnalysis/resendTa"

import "./Review.css"
import log from "loglevel"
import { useTemplateDoc } from "../../features/commonResourceRequests/useTemplateDoc"


export const Review: React.FC = () => {
  const dispatch = useAppDispatch()

  const { taEditDirty, savedAsDraft, reviewAfterEdit, toolboxMode, toolboxTa } =
    useAppSelector((state) => state.pgTaskAnalysis)
  const taEdit = useAppSelector(selectTaEdit)
  const taLocked = useAppSelector(selectTaEditLocked)

  const canEditTa = useAppSelector((state) => selectCanEditTa(state, taEdit))
  const canCreateTa = useAppSelector((state) => selectCanCreateAnyTa(state))

  const [actionsPopup, setActionsPopup] = useState(false)

  const { availableCompanies } = useReviewResources()

  const saveTemplateCompany = useRef<number>()

  const saveTaTemplateModalOk = (
    companyId: number | null,
    categoryId: number | null,
    itemId: number | undefined,
    itemName: string
  ) => {
    dismissTemplateModal()
    dispatch(
      saveTaTemplate(
        saveTemplateCompany.current || 0,
        categoryId,
        itemId,
        itemName
      )
    )
  }

  const saveTaTemplateModalCancel = () => {
    dismissTemplateModal()
  }

  const [presentTemplateModal, dismissTemplateModal] = useIonModal(SelectCategoryItem, {
    selectOnly: false,
    itemType: "Template",
    initialItemName: taEdit.userData.name,
    selectCategoryPath: selectJobCategoryPath,
    selectCategoryContents: selectJobCategoryContents,
    selectItem: selectTaTemplate,
    selectItemFromName: selectTaTemplateFromName,
    setResult: saveTaTemplateModalOk,
    cancel: saveTaTemplateModalCancel,
    companyId: saveTemplateCompany
  })

  const companyModalOk = (companyId: number | null) => {
    dismissCompanyModal()
    if (companyId) {
      saveTemplateCompany.current = companyId
      presentTemplateModal()
    }
  }

  const modalCancel = () => {
    dismissCompanyModal()
  }

  const [presentCompanyModal, dismissCompanyModal] = useIonModal(SelectList, {
    title: "Select company to save this template under:",
    listData:
      availableCompanies?.map((company) => ({
        text: company.companyName,
        id: company.id
      })) || [],
    selectOption: companyModalOk,
    dismiss: modalCancel,
    padTop: true
  })

  const saveAsTemplate = () => {

    if (availableCompanies.length === 1) {
      // There's only one available company that user has 'create' permission on...
      saveTemplateCompany.current = availableCompanies[0].id
      presentTemplateModal()

    } else if (availableCompanies.length > 1) {
      // User has access to multiple companies - select which one to save under
      presentCompanyModal()

    } else {
      log.error('saveAsTemplate: no available company to save under')
    }

  }

  const saveAsDraft = () => {
    dispatch(saveTa())
    dispatch(updateSequence(TaEvent.START_SELECTED))
  }
  const { templateDoc } = useTemplateDoc(taEdit.company, "taskAnalysis")

  useHomeIconHandler("/task-analysis", () => dispatch(homeIconAction()))

  const toolboxReview = toolboxMode && toolboxTa

  return (
    <>
      <IonContent>
        <h1 style={{ padding: "10px 0 5px 5px" }}>Review:</h1>
        <DocContainer
          docId={templateDoc}
          selectorParams={{ taEdit: true }}
          refreshTrigger={taEdit}
        />
      </IonContent>
      <IonFooter className="TA-nav-footer">
        {reviewAfterEdit && !taLocked ? (
          <IonButton
            onClick={() => dispatch(updateSequence(TaEvent.BACK_SELECTED))}
          >
            BACK
          </IonButton>
        ) : (
          <>
            {canEditTa ? (
              <IonButton onClick={() => dispatch(editTa())}>Edit</IonButton>
            ) : (
              <IonButton disabled={true}>BACK</IonButton>
            )}
          </>
        )}
        <IonButton onClick={() => setActionsPopup(!actionsPopup)}>Actions</IonButton>

        {!toolboxReview && !taEditDirty ? (
          <IonButton
            onClick={() => dispatch(updateSequence(TaEvent.START_SELECTED))}
          >
            Back to Start
          </IonButton>
        ) : (
          <></>
        )}
      </IonFooter>

      {/* Popup menu used to show additional options that don't fit in footer */}
      <div id="review-popover-fullscreen" style={{ "display": actionsPopup ? "flex" : "none" }}>
        <div>
          {!taEdit.draft &&
            !taEditDirty &&
            taEdit.workers.filter(
              (worker) => !worker.deleted && !worker.signature
            ).length ? (
            // User can go to sign on screen if:
            // - SWMS is active (non-draft), AND
            // - SWMS hasn't been edited since 'Use' was selected, AND
            // - There are workers who haven't yet signed on
            <IonButton
              onClick={() => dispatch(updateSequence(TaEvent.NEXT_SELECTED))}
            >
              Add Signature
            </IonButton>
          ) : (
            <></>
          )}

          {canEditTa ? (
            // user can edit THIS ta (i.e. they either created it or they're an admin / supervisor)
            <>
              {taEdit.draft ? (
                // SWMS is currently a draft, user can:
                // - save it as a draft if not already saved
                // - 'Use' it (i.e. make it active)                
                <>
                  {!savedAsDraft ? (
                    <IonButton onClick={saveAsDraft}>Save Draft</IonButton>
                  ) : (
                    <></>
                  )
                  }
                  <IonButton onClick={() => dispatch(setTaActive())}>
                    Use this SWMS
                  </IonButton>
                </>

              ) : // SWMS is currently active:
                taEditDirty ? (
                  // SWMS has been edited since 'Use' was selected - can save and resend
                  <IonButton onClick={() => {
                    setActionsPopup(false);
                    dispatch(resendTa());
                  }}>
                    Save and Re-Send
                  </IonButton>
                ) : (
                  // SWMS has NOT been edited - can resend
                  <IonButton onClick={() => {
                    setActionsPopup(false);
                    dispatch(resendTa());
                  }}>
                    Re-Send SWMS
                  </IonButton>
                )}
            </>
          ) : (
            <></>
          )}

          {canCreateTa && !toolboxReview ?
            // Can create ANY ta => can save this one as a template
            (
              <IonButton onClick={saveAsTemplate}>
                Save as Template
              </IonButton>
            ) : (
              <></>
            )
          }

          {toolboxMode ? (
            <IonButton
              expand="block"
              onClick={() => dispatch(taReturnToToolbox())}
            >
              Return to Toolbox
            </IonButton>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  )
}
