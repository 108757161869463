const tbAttendees = {
  table: 'tb_attendees',
  idKey: 'id',

  attrs: [
    'creationDate',
    'lastModifiedDate',
    'deleted'
  ],

  relationships: [
    {
      type: 'companies',
      key: 'company',
    },   
    {
      type: 'toolboxes',
      key: 'toolbox',
    },
    {
      type: 'users',
      key: 'user',
    }
  ],
}

export default tbAttendees