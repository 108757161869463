import log, { LogLevelDesc, getLogger } from "loglevel"
import { ResourceData } from "@disruptph/json-api-normalizer"
import api from "../api"
import { ApiResult } from "../interfaces/api"

/**
 * Logging uses 'loglevel' package client side, with a 'plugin' to write logs to our api 'log' endpoint
 * log endpoint is json-api interface with no permissions that only has access to app_log resource
 *
 * https://www.loggly.com/blog/best-practices-for-client-side-logging-and-error-handling-in-react/
 * https://github.com/pimterry/loglevel
 */

interface LogEntry {
  logTime: Date
  level: number
  logText: string
  logMeta?: any
}

const levels = {
  trace: 1,
  debug: 2,
  info: 3,
  warn: 4,
  error: 5
}

// buffer can be used to hold log entries in memory for sending later, if no user logged in or no internet connection
const MAX_BUFFER_ENTRIES = 500
const buffer: LogEntry[] = []

// Assume we can contact the logging endpoint until we know otherwise:
// - if logging api call fails, this will be set to false and log entries will be buffered
// - will be set to true once api is online with token
let loggingOnline = true
let logUserId = ""

export const setLogUserId = (id: string) => {
  logUserId = id
}

export const initLogging = () => {
  // Add back end logging for global logger
  addBackendLoggingPlugin(log)

  // Set level for any non module-specific logging
  log.setLevel("warn")

  // Can use getLogger to create a separate logger per module if we want different logging levels for different modules
  // Calling (e.g.) getLogger('auth') here will return a reference to the same logger as when it's called in auth module.
  //
  // Add back end logging for any such module-specific loggers in use, and set default logging level
  // Would like to both instantiate and attach backend logging plugin from the module, but this causes a circular import...

  addBackendLoggingPlugin(getLogger("auth"))
  getLogger("auth").setLevel("info")

  addBackendLoggingPlugin(getLogger("signin"))
  getLogger("signin").setLevel("warn")

  addBackendLoggingPlugin(getLogger("resourceRequests"))
  getLogger("resourceRequests").setLevel("warn")
}

export const addBackendLoggingPlugin = (logger: log.Logger) => {
  // Add a plugin to loglevel to write log entries to back end
  const originalFactory = logger.methodFactory
  logger.methodFactory = function (methodName, logLevel, loggerName) {
    const rawMethod = originalFactory(methodName, logLevel, loggerName)

    let level = levels[methodName as keyof typeof levels]
    if (typeof level === "undefined") {
      level = 1
    }

    return function () {
      // Original log function is assumed to be variadic, however 'saveLog' action is expecting a single string
      // -> combine arguments
      let messages = []
      let message = ""
      for (let i = 0; i < arguments.length; i++) {
        messages.push(arguments[i])
        if (arguments[i] === undefined) {
          message = message + "[undefined] "
        } else if (arguments[i] === null) {
          message = message + "[nul] "
        } else {
          message = message + arguments[i].toString() + " "
        }
      }

      // If connection to server is good and there is a logged in user, we should be able to log - set logging online
      if (api.isOnline() && api.hasToken()) {
        loggingOnline = true
      }

      // If online, write entry to log endpoint,
      if (loggingOnline) {
        if (buffer.length) {
          saveBufferedLogs()
        }
        saveLog(new Date(), level, message)
      } else {
        // Otherwise, buffer it to send later
        if (buffer.length < MAX_BUFFER_ENTRIES) {
          buffer.push({ logTime: new Date(), level, logText: message })
        }
      }

      // Run original logger code
      rawMethod.apply(undefined, messages)
    }
  }
}

const saveLog = async (
  logTime: Date,
  level: number,
  logText: string,
  logMeta?: any
) => {
  const resourceData = {
    id: undefined as unknown,
    type: "app-log",
    attributes: {
      logTime: logTime.toISOString(),
      level,
      logText,
      logMeta
    },
    relationships: logUserId
      ? {
        user: {
          data: {
            type: "users",
            id: logUserId
          }
        }
      }
      : {}
  } as ResourceData

  const normalizedData = {
    appLog: {
      newEntry: resourceData
    }
  }
  const result = await api.addLogEntry(normalizedData)
  if (result.result !== ApiResult.SUCCESS) {
    if (buffer.length < MAX_BUFFER_ENTRIES) {
      buffer.push({ logTime: new Date(), level, logText })
    }
    loggingOnline = false
  }
}

export const saveBufferedLogs = () => {
  while (buffer.length) {
    const entry = buffer.shift()
    if (entry) {
      saveLog(entry.logTime, entry.level, entry.logText, entry.logMeta)
    }
  }
}

/**
 * Turn on logging specified for a particular user
*/
export const setLogLevels = (logLevels?: Record<string, LogLevelDesc>) => {

  if (logLevels) {
    try {
      Object.keys(logLevels).forEach(key => {
        if (key === 'default') {
          log.setLevel(logLevels.default)
        } else {
          getLogger(key).setLevel(logLevels[key] as LogLevelDesc)
        }
      })
    } catch (e) {
      log.error('setLogLevels:Invalid log level')
    }
  }
}