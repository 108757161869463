import { AppDispatch, RootState } from '../../redux/store'
import { setAlert } from '../../utils/alert'
import { saveTa } from './saveTa'
import { toolboxReviewTaDone } from '../toolbox/toolboxIntegration'
import { getResource } from '../../datastore'
import { Ta } from '../../datastore/models'
import { sendTaNotification } from './sendTaNotification'

export const resendTa = () => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setAlert(
        'Confirm Re-Send SWMS',
        'Completed SWMS will be emailed to supervisor and all workers',
        [{
            text: 'Cancel',
            role: 'cancel',
            handler: () => true
        },
        {
            text: 'Continue',
            handler: () => {                
                dispatch(saveTa())
                const state = getState()
                const taId = state.pgTaskAnalysis.taEdit.id
                const toolboxMode = state.pgTaskAnalysis.toolboxMode
                if (taId) {
                    dispatch(sendTaNotification(taId))

                    if (toolboxMode) {
                        // get whole ta so we have updated id and localId
                        const ta = getResource<Ta>(state.resources.tas, taId || 0)
                        if (ta) {
                            dispatch(toolboxReviewTaDone(ta))
                        }
                    }
                }                 
                return true
            }
        }]
    ))
}
