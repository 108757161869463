import { NormalizedData } from '@disruptph/json-api-normalizer'
import { isAfter, parseJSON } from 'date-fns'
import _ from 'lodash'
import { AppDispatch, RootState } from '../../redux/store'
import { removeResource, ResourceType, setResource } from '../../redux/slices/resources'
import { localStore } from '../../storage/storage'
import { clearDocStatus } from '../../redux/slices/docMeta'

/**
 * Process resources received from API:
 * 
 * - If incoming resource is flagged as deleted, delete any corresponding local copy
 * - If incoming resource isn't newer than local copy, ignore
 * - Otherwise update local copy
 * 
 * Returns hash of the most recent 'lastModifiedDate' for each resource type in 'resources', used to set the 'data valid to' date
 * when updating cache
 * 
 */
export const processReceivedResources = (resources: NormalizedData, dispatch: AppDispatch, getState: () => RootState) => {

    if (!_.isObject(resources)) {
        return
    }
    const result: { [key: string]: { mostRecentModified: Date } } = {}

    for (const type in resources) {
        const resourcesOfType = resources[type]
        const existingResourcesOfType = getState().resources[type as ResourceType]
        if (!_.isObject(resourcesOfType)) {
            continue
        }

        const resourcesToUpdate: { [key: string]: any } = {}

        let mostRecentModified = new Date(0)
        for (const key in resourcesOfType) {

            const resource = resourcesOfType[key]
            if (!_.isObject(resource)) {
                continue
            }

            // Find which of the returned resources has the most recent 'lastModifiedDate'
            const lastModifiedDate = parseJSON(resource.attributes.lastModifiedDate)
            if (isAfter(lastModifiedDate, mostRecentModified)) {
                mostRecentModified = lastModifiedDate
            }

            const existingResource = _.get(existingResourcesOfType, [key])
            if (existingResource) {
                // A local copy of resource already exists...
                if (resource.attributes.deleted) {
                    // The incoming version is flagged as deleted - delete local copy
                    dispatch(removeResource({ resourceType: type as ResourceType, id: key }))

                    // If it's a docInfo record, delete actual document as well
                    if (type === 'docInfo') {
                        localStore.remove(`docs/${key}`)
                        dispatch(clearDocStatus(key))
                    }

                } else {
                    if (isAfter(parseJSON(resource.attributes.lastModifiedDate), parseJSON(existingResource.attributes.lastModifiedDate))) {
                        // The incoming version is newer than the existing version - replace
                        resourcesToUpdate[key] = resource
                    }
                }
            } else {
                // No local copy of resource exists - add the incoming version as long as it's not deleted
                if (!resource.attributes.deleted) {
                    resourcesToUpdate[key] = resource
                }
            }
        }
        result[type] = {
            mostRecentModified
        }
        dispatch(setResource({ [type]: resourcesToUpdate }))
    }
    return result
}