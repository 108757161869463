export enum TaStepState {
    NONE,
    ADD_HAZARDS,
    REVIEW_HAZARDS,
    COMPLETE
}

export interface TaHazard {
    hazardTypeId: number | null
    customHazardType: string
    irConsequence: number
    irLikelyhood: number
    rrConsequence: number
    rrLikelyhood: number
    controls: number[]
    customControls: string[]
    needsReview: boolean
}

export interface TaStep {
    text: string
    placeholder?: string
    state: TaStepState
    hazards: TaHazard[]
}

export interface TaUserData {
    name: string
    description: string
    steps: TaStep[]
    ppe: number[]
    customPpe: string[]

}

export default interface Ta {
    id:number
    localId?: number
    company: number
    project: number
    site: number
    scheduledTask: number
    creator: number
    startDate: string
    draft:boolean
    revision: number
    superseded: boolean
    supersedes: number
    userData: TaUserData
    lastModifiedDate: string
}