import { Attributes, NormalizedData } from "@disruptph/json-api-normalizer"
import _ from 'lodash'

/*
JSON-API Format:

{
  "links":{"self":"http://localhost/api/v1/resources/hazard-categories"},

  "data":[{
    "id":"18",
    "type":"hazard-categories",
    "attributes":{
      "categoryText":"Asbestos May Be Present",
      "detail":"Demolition\\Asbestos",
      "creationDate":"2022-04-05T09:19:01.596Z",
      "lastModifiedDate":"2022-04-05T09:19:01.596Z"
    },
    "relationships":{
      "parent":{
        "data":{
          "id":"17",
          "type":"hazard-categories"
        } 
      }
    }
  }],
"included":[]}

NormalizedData format (used in Redux store):

  hazardCategories {   
      '18': {
        id: '18',
        type: 'hazardCategories',
        attributes: {
          categoryText: 'Asbestos May Be Present',
          detail: 'Demolition\\Asbestos',
          creationDate: '2022-04-05T09:19:01.596Z',
          lastModifiedDate: '2022-04-05T09:19:01.596Z'
        },
        relationships: {
          parent: {
            data: {
              id: '17',
              type: 'hazardCategories'
            }
          }
        }
      }
    }
  */

interface Relationship {
  id: string;
  type: string;
}

// Our version of ResourceData from json-api-normalizer, with id made optional so we can remove it when required
interface DenormResourceData<A extends Attributes = Attributes>  {
  id?:string
  type:string
  attributes: A
    relationships: {
        [entity: string]: {
            data: Relationship | Relationship[] | null
        }
    }
}

/**
 * Simple 'denormalization', i.e. converts resource data from store back to json-api format for writing to API
 *
 * @param resources
 * @returns 
 */
export function deNormalize(resources: NormalizedData) {

  const denormResources: DenormResourceData[] = []

  for (const type in resources) {

    const resourcesOfType = resources[type]

    for (const resourceKey in resourcesOfType) {

      const resource = resourcesOfType[resourceKey]

      const denormRelationships: {
        [entity: string]: {
          data: {
            id: string;
            type: string;
          } | null
        }
      } = {}
      for (const relationship in resource.relationships) {
        denormRelationships[relationship] = {
          data: (resource.relationships[relationship].data
          ?{
            id: (resource.relationships[relationship].data as Relationship).id,
            // JSON-API resource types are kebab case; NormalizedData format uses camel case
            type: _.kebabCase((resource.relationships[relationship].data as Relationship).type)
          }
          :null)
        }
      }
      
      denormResources.push({
        id:resource.id,
        type: _.kebabCase(type),                
        attributes: resource.attributes,
        relationships: denormRelationships
      })
    }    
  }

  return {
    data: denormResources
  }
}