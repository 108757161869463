import { AppDispatch, RootState } from '../../redux/store'
import { setToolboxState } from '../../redux/slices/pgToolbox'
import { stTOOLBOX, ToolboxEvent } from './toolboxTypes'
import { deleteToolbox } from './deleteToolbox'

    export const updateSequence = (event?: ToolboxEvent) => (dispatch: AppDispatch, getState: () => RootState) => {

    let state = getState()

    const { stToolbox } = state.pgToolbox    
    const { startupInProgress } = state.temp

    // Wait for startup logic to complete before updating sequence
    if (startupInProgress) {
        return
    }

    // If toolbox is closed, return to start regardless of state
    if (event === ToolboxEvent.CLOSE_SELECTED) {
        dispatch(setToolboxState(stTOOLBOX.START))
    }

    switch (stToolbox) {

        case stTOOLBOX.START:

            if (event === ToolboxEvent.NEXT_SELECTED) {
                dispatch(setToolboxState(stTOOLBOX.ROLL_CALL))
            }            

            if (event === ToolboxEvent.REGISTER_SELECTED) {
                dispatch(setToolboxState(stTOOLBOX.REGISTER))
            }
            break

        case stTOOLBOX.REGISTER:
            if (event === ToolboxEvent.START_SELECTED) {                
                dispatch(setToolboxState(stTOOLBOX.START))
            }         

            if (event === ToolboxEvent.REVIEW_SELECTED) {
                dispatch(setToolboxState(stTOOLBOX.REVIEW))
            }
            break

        case stTOOLBOX.ROLL_CALL:

            if (event === ToolboxEvent.BACK_SELECTED) {                
                dispatch(deleteToolbox)
                dispatch(setToolboxState(stTOOLBOX.START))
            }

            if (event === ToolboxEvent.NEXT_SELECTED) {                                
                dispatch(setToolboxState(stTOOLBOX.SITE_HAZARDS))
            }
            break

        case stTOOLBOX.SITE_HAZARDS:

            if (event === ToolboxEvent.BACK_SELECTED) {
                dispatch(setToolboxState(stTOOLBOX.ROLL_CALL))
            }

            if (event === ToolboxEvent.NEXT_SELECTED) {
                dispatch(setToolboxState(stTOOLBOX.SITE_OBSERVATIONS))
            }
            break

        case stTOOLBOX.SITE_OBSERVATIONS:

            if (event === ToolboxEvent.BACK_SELECTED) {
                dispatch(setToolboxState(stTOOLBOX.SITE_HAZARDS))
            }
            if (event === ToolboxEvent.NEXT_SELECTED) {
                dispatch(setToolboxState(stTOOLBOX.INCIDENT_REPORTS))
            }
            break

        case stTOOLBOX.INCIDENT_REPORTS:

            if (event === ToolboxEvent.BACK_SELECTED) {
                dispatch(setToolboxState(stTOOLBOX.SITE_OBSERVATIONS))
            }
            if (event === ToolboxEvent.NEXT_SELECTED) {
                dispatch(setToolboxState(stTOOLBOX.TASKS))
            }
            break

        case stTOOLBOX.TASKS:

            if (event === ToolboxEvent.BACK_SELECTED) {
                dispatch(setToolboxState(stTOOLBOX.INCIDENT_REPORTS))
            }
            if (event === ToolboxEvent.NEXT_SELECTED) {
                dispatch(setToolboxState(stTOOLBOX.OTHER_BUSINESS))
            }
            break

        case stTOOLBOX.OTHER_BUSINESS:

            if (event === ToolboxEvent.BACK_SELECTED) {
                dispatch(setToolboxState(stTOOLBOX.TASKS))
            }
            break

        case stTOOLBOX.REVIEW:

            if (event === ToolboxEvent.START_SELECTED) {
                dispatch(setToolboxState(stTOOLBOX.START))
            }
            break

        default:
            dispatch(setToolboxState(stTOOLBOX.START))
    }
}