const siteObservationActions = {
  table: 'site_observation_actions',
  idKey: 'id',

  attrs: [
    'observationDate',
    'actionText',
    'completedDate',    
    'creationDate',
    'lastModifiedDate',
    'deleted'
  ],

  relationships: [   
    {      
      type: 'companies',
      key: 'company',
    },
    {      
      type: 'site-observations',
      key: 'observation',
    },    
    {      
      type: 'users',
      key: 'creator',
    },
    {      
      type: 'users',
      key: 'assignedTo',
    }
  ],
}

export default siteObservationActions
