import { formatResource } from '../../datastore/json-api/helpers/formatResource'
import { User } from '../../datastore/models'
import { setAppUser, UserPermissions } from '../../redux/slices/app'
import { setResource } from '../../redux/slices/resources'
import { RootState, AppDispatch } from '../../redux/store'
import { setLogUserId } from '../../utils/log'

/**
 * Called to add current user data to store on login
 * 
 * writes user to both app.user and resources.users
 */
export const setUser = (opts: { user: User, permissions: UserPermissions, sub?: string } | null) => (dispatch: AppDispatch, getState: () => RootState) => {

    if (opts) {
        const normalisedUser = formatResource(opts.user, 'users', opts.user.id)
        dispatch(setResource(normalisedUser))
        setLogUserId(opts.user.id.toString())
    } else {
        setLogUserId('')
    }
    dispatch(setAppUser(opts))    
}