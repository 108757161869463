const jobCategories = {
    table: 'job_categories',
    idKey: 'id',
  
    attrs: [        
      'company',
      'categoryText',
      'detail',
      'creationDate',
      'lastModifiedDate'
    ],
  
    relationships: [ 
      {
        type: 'job-categories',
        key: 'parent',
      }
    ],
  }
  
  export default jobCategories