import { createSelector } from "@reduxjs/toolkit"
import Site from "../../datastore/models/Site"
import { getResource, getResources } from "../../datastore"
import { selectParamsMemo } from "./helpers/selectParamsMemo"
import { selectResourcesMemo } from "./helpers/selectResourcesMemo"
import { Project, ProjectSite } from "../../datastore/models"
import { uniq } from "lodash"
import { GetResourcesOptions } from "../../datastore/getResources"

const selectSitesResources = selectResourcesMemo(["sites", "companies"])
const selectSiteParams = selectParamsMemo()
/**
 * Get a site, either by id or signin code
 */
export const selectSite = createSelector(
  [selectSitesResources, selectSiteParams],
  (resources, params) => {
    // Check whether siteId was supplied directly
    const site = getResource<Site>(resources.sites, params.siteId)
    if (site) {
      return site
    }

    // If not, check for supplied signinCode and look up corresponding siteId
    if (params.signinCode) {
      const sites = getResources<Site>(resources, "sites", {
        eqFilter: { signinCode: params.signinCode }
      })
      if (sites.length) {
        return sites[0]
      }
    }
    return null
  }
)

/**
 * Get open sites
 * Not currently using 'open' status of the site itself - get 'open' projects and find corresponding sites
 */
const selectOpenSitesResources = selectResourcesMemo([
  "companies",
  "projects",
  "projectSites",
  "sites"
])
const selectOpenSitesParams = selectParamsMemo()
export const selectOpenSites = createSelector(
  [selectOpenSitesResources, selectOpenSitesParams],
  (resources, params) => {
    let opts: GetResourcesOptions = {
      eqFilter: { status: "OPEN" }
    }
    if (params.companyIds) {
      opts.inFilter = { company: params.companyIds }
    }

    const projects = getResources<Project>(resources, "projects", opts)
    const projectIds = projects.map((project) => project.id)
    const projectSites = getResources<ProjectSite>(resources, "projectSites", {
      inFilter: { project: projectIds }
    })
    const siteIds = uniq(projectSites.map((projectSite) => projectSite.site))
    const sites = getResources<Site>(resources, "sites", {
      inFilter: { id: siteIds }
    })

    return sites
  }
)
