import { AppDispatch, RootState } from "../../../redux/store"
import { useAppSelector } from "../../../redux/hooks"
import { requestResourcesWithRelated } from "../../../datastore"
import { selectProjectSites } from "../../../redux/selectors/projectSites"
import { selectCanCreateToolboxProjectSites } from "../../../redux/selectors/abilities"
import { useTask } from "../../../hooks/useTask"
import { ResourceEvent } from "../../../datastore/types"

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results
 *
 */
export const useRequestAvailableProjectSites = (refresh?: any) => {

  const taskResult = useTask(
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      const result = await requestResourcesWithRelated(
        "projects",
        { eqFilter: { status: "OPEN" } },
        ["projectSites.sites"]
      )(dispatch, getState, services)

      return {
        isSuccess: result !== ResourceEvent.RESOURCE_LOAD_ERROR
      }
    },
    [refresh]
  )

  const projectSites = useAppSelector((state) => selectProjectSites(state, {}))
  return {
    availableProjectSites: useAppSelector((state) =>
      selectCanCreateToolboxProjectSites(state, projectSites)
    ),
    isLoading: taskResult.isLoading,
    isSuccess: taskResult.isSuccess,
    isError: taskResult.isError
  }
}
