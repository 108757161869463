import { AlertButton } from "@ionic/core";
import { setAlertHeader, setAlertMessage, setAlertResponse } from '../redux/slices/temp'
import { AppDispatch, RootState } from '../redux/store'

var alertButtons:AlertButton[] = []

export const setAlert = ( header: string, message: string, buttons?:AlertButton[] ) => (dispatch: AppDispatch, getState: () => RootState) => {

    alertButtons = buttons || [
        {
            text:'Ok',
            role: 'cancel',
            handler: () => {
                dispatch(setAlertResponse('ok'))
                return true
              }        
        }
    ]
    dispatch(setAlertHeader(header))
    dispatch(setAlertMessage(message))
}

export const getAlertButtons = () => alertButtons

