import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { IonButton, IonCol, IonContent, IonFooter, IonGrid, IonRow } from "@ionic/react"
import { useHomeIconHandler } from '../../hooks/homeIconHandler'
import { homeIconAction } from '../../features/incidentReports/homeIconAction'
import { createIncident } from '../../features/incidentReports/createIncident'
import { updateSequence } from '../../features/incidentReports/incidentReportsSequence'
import { IncidentReportEvent } from '../../features/incidentReports/incidentReportsTypes'
import { IncidentType } from '../../datastore/models/Incident'
import IncidentTypeTile from './IncidentTypeTile'

import './SelectIncidentType.css'

export const SelectIncidentType: React.FC = () => {
    const dispatch = useAppDispatch()
    const { toolboxMode, toolboxSite } = useAppSelector(state => state.pgIncidentReports)

    const typeSelected = (type: IncidentType) => {
        if (toolboxMode && toolboxSite) {
            dispatch(createIncident(type, toolboxSite))    
        } else {
            dispatch(createIncident(type))
        }
    } 

    useHomeIconHandler('/incidents', () => dispatch(homeIconAction()))

    return (
        <>
            <IonContent class="content-padding">  
                <h2>What type of incident are you reporting?</h2>
                <IonGrid fixed={true} className="app-grid">
                    <IonRow>
                        <IonCol>
                            <div id="incident-type-grid">
                                {/* NOT reported to Worksafe */}
                                <IncidentTypeTile
                                    reportType="unreported"
                                    type="NearMiss"
                                    action={(type: IncidentType) => typeSelected(type)}
                                />
                                <IncidentTypeTile
                                    reportType="unreported"
                                    type="MinorInjury"
                                    action={(type: IncidentType) => typeSelected(type)}
                                />
                                <IncidentTypeTile
                                    reportType="unreported"
                                    type="ModerateInjury"
                                    action={(type: IncidentType) => typeSelected(type)}
                                />

                                <span className="unreported explanation">
                                    The above incident types DO NOT REQUIRE reporting to Worksafe.
                                    You must keep your own report for your Company record.
                                </span>

                                <div id="vertical-divider">

                                </div>

                                {/* Report to Worksafe */}

                                <IncidentTypeTile
                                    reportType="reported"
                                    type="SeriousIllness"
                                    row='1'
                                    action={(type: IncidentType) => typeSelected(type)}
                                />
                                <IncidentTypeTile
                                    reportType="reported"
                                    type="SeriousInjury"
                                    row='2'
                                    action={(type: IncidentType) => typeSelected(type)}
                                />
                                <IncidentTypeTile
                                    reportType="reported"
                                    type="HiRiskIncident"
                                    row='3'
                                    action={(type: IncidentType) => typeSelected(type)}
                                />
                                <IncidentTypeTile
                                    reportType="reported"
                                    type="Death"
                                    row='4'
                                    action={(type: IncidentType) => typeSelected(type)}
                                />

                                <span className="reported explanation">
                                    The above incident types MUST BE reported to Worksafe.
                                    You must keep your own report for your Company record.
                                </span>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter class="incidents-nav">
                <IonButton onClick={() => dispatch(updateSequence(IncidentReportEvent.BACK_SELECTED))}
                    style={{ "gridColumnStart": "1" }}>Back</IonButton>
            </IonFooter>
        </>)
}

