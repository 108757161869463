import { Fragment } from "react"
import _ from "lodash"
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
    IonButton,
    IonContent,
    IonFooter,
    IonItemDivider,
    IonList
} from "@ionic/react"
import { useAppSelector, useAppDispatch } from '../../redux/hooks'

import TextArea from "../../components/form/TextArea"

import { useHomeIconHandler } from "../../hooks/homeIconHandler"
import { homeIconAction } from "../../features/incidentReports/homeIconAction"
import { IncidentReportEvent } from "../../features/incidentReports/incidentReportsTypes"
import { updateSequence } from "../../features/incidentReports/incidentReportsSequence"
import { setIncidentReportActions, setIncidentReportEdit } from "../../redux/slices/pgIncidentReports"

import IncidentAction from "../../datastore/models/IncidentAction"
import SelectListInput from "../../components/form/SelectListInput"
import { notifyIncident } from "../../features/incidentReports/notifyIncident"
import { closeIncident } from "../../features/incidentReports/closeIncident"
import { useRequestSupervisorsBySite } from "../../features/commonResourceRequests/useRequestSupervisorsBySite"

export type IncidentActionEdit = Partial<IncidentAction> & { dirty?: boolean }

type Inputs = {
    actions: IncidentActionEdit[]
    actionVerifier: number | null
}

const schema = yup.object().shape({
    actions: yup.array().of(
        yup.object().shape({
            actionText: yup.string().when('deleted', {
                is: (deleted?: boolean) => !deleted,
                then: yup.string().required('Please enter the action that will be taken'),
            }),
            deleted: yup.boolean()
        })
    )
}).required()

export const Actions: React.FC = () => {
    const dispatch = useAppDispatch()
    const { toolboxMode, incidentReportEdit, dirty } = useAppSelector(state => state.pgIncidentReports)
    const userId = useAppSelector(state => state.app.user?.id)

    const { supervisors } = useRequestSupervisorsBySite(incidentReportEdit.site)

    const { handleSubmit, getValues, watch, setValue, control, formState: { errors, dirtyFields, isDirty } } = useForm<Inputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            actions: incidentReportEdit.actions,
            actionVerifier: incidentReportEdit.actionVerifier || (supervisors.length === 1 ? supervisors[0].id : null)
        }
    })

    const { fields, append, update } = useFieldArray({
        control,
        name: "actions",
        keyName: "ufaId", // defaults to "id" - changed so as not to overwrite resource id
    })

    const addAction = () => {
        append({
            creator: userId
        })
    }

    const deleteAction = (index: number) => {
        const item = fields[index]
        if (item) {
            update(index, {
                ...item,
                deleted: true
            })
        }
    }

    const formSubmitBack = (data: any) => {
        formSubmit(data)
        dispatch(updateSequence(IncidentReportEvent.BACK_SELECTED))
    }

    const formSubmitNotify = (data: any) => {
        formSubmit(data)
        dispatch(notifyIncident())
    }

    const formSubmitCloseout = (data: any) => {
        formSubmit(data)
        dispatch(closeIncident())
    }

    const formSubmitHome = (data: any) => {
        formSubmit(data)
        dispatch(homeIconAction())
    }
    useHomeIconHandler('/incidents', handleSubmit(formSubmitHome))

    const formSubmit = (data: any) => {

        const updatedActions = data.actions.map((action: IncidentActionEdit, i: number) => {
            const itemDirtyFields = dirtyFields?.actions ? dirtyFields.actions[i] : undefined
            const dirty = !!(
                itemDirtyFields &&
                (itemDirtyFields.actionText || itemDirtyFields.verifiedText || itemDirtyFields.deleted))
            return {
                ...action,
                dirty: action.dirty || dirty
            } as IncidentActionEdit
        })

        const actionsDirty = !!updatedActions.filter((action: IncidentActionEdit) => action.dirty).length
        if (actionsDirty) {
            dispatch(setIncidentReportActions(updatedActions))
        }

        if (dirtyFields.actionVerifier || actionsDirty) {
            dispatch(setIncidentReportEdit({
                actionVerifier: data.actionVerifier
            }))
        }
    }

    return (
        <>
            <IonContent class="content-padding">
                <form id="incident-details-form">
                    <h3>What actions will be taken to prevent similar future incidents?</h3>
                    <IonList id="incident-actions-list" lines="none">
                        {fields?.map((action, i) => ({ ...action, fieldIndex: i })).filter(action => !action.deleted).map(action => (
                            <Fragment key={action.ufaId}>

                                <TextArea
                                    fieldName={`actions[${action.fieldIndex}].actionText`}
                                    inputMode="text"
                                    placeholder='Action to be taken'
                                    rows={2}
                                    watch={watch}
                                    setValue={setValue}
                                    error={_.get(errors, ['actions', action.fieldIndex, 'actionText'])}
                                />
                                {getValues().actionVerifier === userId
                                    ? <>
                                        <div>
                                            <p>Action verified as implemented and effective:</p>
                                        </div>
                                        <TextArea
                                            fieldName={`actions[${action.fieldIndex}].verifiedText`}
                                            inputMode="text"
                                            placeholder='Describe how you have verified that the action has been implemented and is effective'
                                            rows={2}
                                            watch={watch}
                                            setValue={setValue}
                                            error={_.get(errors, ['actions', action.fieldIndex, 'verifiedText'])}
                                        />
                                    </>
                                    : <></>
                                }
                                <IonButton onClick={() => deleteAction(action.fieldIndex)}>
                                    Delete action
                                </IonButton>
                                <IonItemDivider />

                            </Fragment>
                        ))}
                    </IonList>

                    <div className="flex centre-row">
                        <IonButton onClick={addAction}>
                            ADD action
                        </IonButton>
                    </div>

                    <h3>Name of person assigned to verify actions for effectiveness:</h3>

                    <SelectListInput
                        className="modal-input"
                        title="Select person to verify actions"
                        placeholder="Choose Supervisor"
                        listData={supervisors?.map(user => ({ text: `${user.firstName} ${user.lastName}`, id: user.id })) || []}
                        fieldName="actionVerifier"
                        watch={watch}
                        setValue={setValue}
                        error={errors.actionVerifier}
                    />

                    {toolboxMode ?
                        <IonButton expand="block" onClick={handleSubmit(formSubmitHome)}>Return to Toolbox Meeting</IonButton> : <></>}

                </form>

            </IonContent>

            <IonFooter class="incidents-nav">
                <IonButton onClick={handleSubmit(formSubmitBack)}>Back</IonButton>
                <IonButton disabled={!dirty && !isDirty} onClick={handleSubmit(formSubmitNotify)}>Submit</IonButton>
                {getValues().actionVerifier === userId
                    ? <IonButton onClick={handleSubmit(formSubmitCloseout)}>Close Out</IonButton>
                    : <></>
                }
            </IonFooter>
        </>)
}