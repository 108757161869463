/**
 * Convert pdf to image using pdf.js
 * (assumes pdf is single page)
 * 
 * @param pdfData pdf in dataUrl format
 * @returns png in dataUrl format
 */
export const pdfToImage = async (pdfData: string) => {
  const pdfJS = await import("pdfjs-dist/build/pdf")

  pdfJS.GlobalWorkerOptions.workerSrc =
    window.location.origin + "/pdf.worker.min.js"

  const pdf = await pdfJS.getDocument(pdfData).promise
  // Get first page of pdf
  const page = await pdf.getPage(1)

  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")

  // Set canvas to dimensions of pdf
  canvas.width = page.view[2]
  canvas.height = page.view[3]
  canvas.style.width = page.view[2] + "px"
  canvas.style.height = page.view[3] + "px"

  const viewport = page.getViewport({ scale: 1 })

  const renderContext = {
    canvasContext: context,
    viewport: viewport
  }

  // render pdf to temporay canvas
  const renderTask = page.render(renderContext)
  await renderTask.promise

  // get image from canvas
  const image = canvas.toDataURL()
  return image
}
