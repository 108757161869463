const siteHazards = {
  table: 'site_hazards',
  idKey: 'id',

  attrs: [
    'detail',
    'customHazard',    
    'controls',
    'creationDate',
    'lastModifiedDate',  
    'deleted' 
  ],

  relationships: [
    {
      type: 'companies',
      key: 'company',
    },
    {
      type: 'sites',
      key: 'site',
    },
    {
      type: 'hazard-types',
      key: 'hazardType',
    },
  ],
}
export default siteHazards