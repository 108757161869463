import { AppDispatch, RootState } from '../../redux/store'
import { deleteResource, saveResource, getResources } from '../../datastore'
import TbTask from '../../datastore/models/TbTask'
import { initialiseToolboxTasks } from '../../redux/slices/pgToolbox'
import { checklistItemsToAdd, checklistItemsToDelete } from './resourceChecklist'

export const saveTbTasks = (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState()
    const { toolboxEdit, tasksList } = state.pgToolbox

    // Find items that have just been de-selected
    // => delete corresponding resources
    checklistItemsToDelete(tasksList).forEach(taskId => {
        const tasksToDelete = getResources<TbTask>(state.resources, 'tbTasks', { eqFilter: { toolbox: toolboxEdit.id, task: taskId } })
        // Should be only a single resource, but if there are multiple records for the same task, delete them all
        tasksToDelete.forEach(task => dispatch(deleteResource(task.id, 'tbTasks')))
    })

    // Find items that have just been selected
    // => Save as new 'tbTasks' resources
    checklistItemsToAdd(tasksList).forEach(taskId => {
        dispatch(saveResource({
            company: toolboxEdit.company,
            toolbox: toolboxEdit.id,
            task: taskId
        }, 'tbTasks'))
    })

    // Set preEdit to match checked now that changes have been saved
    dispatch(initialiseToolboxTasks(tasksList.checked.map(item => item.id)))
}