import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { IonButton, IonContent, IonList } from "@ionic/react"
import { TaStepState } from '../../datastore/models/Ta'
import { selectTaEdit } from '../../redux/selectors/tas'
import TaskAnalysisNav from './TaskAnalysisNav'
import './StepListHazards.css'
import TaskHeading from './TaskHeading'
import { useHomeIconHandler } from '../../hooks/homeIconHandler'
import { homeIconAction } from '../../features/taskAnalysis/homeIconAction'
import { setTaSelectedStep } from '../../redux/slices/pgTaskAnalysis'
import { TaEvent, updateSequence } from '../../features/taskAnalysis/taskAnalysisSequence'

export const StepListHazards: React.FC = () => {
    const dispatch = useAppDispatch()
    const taEdit = useAppSelector(selectTaEdit)

    useHomeIconHandler('/task-analysis', () => dispatch(homeIconAction()))

    const editStepSelected = (index:number) => {
        dispatch(setTaSelectedStep(index))
        dispatch(updateSequence(TaEvent.EDIT_STEP_SELECTED))
    }

    return (
        <>
            <IonContent className="content-padding">
                <TaskHeading />
                <IonList id="step-hazards-list">
                    {taEdit.userData.steps?.map(({ text, state }, i) => {
                        let buttonText
                        let buttonColor
                        switch (state) {

                            case TaStepState.REVIEW_HAZARDS:
                                buttonText = 'REVIEW associated HAZARD'
                                buttonColor = 'warning'
                                break

                            case TaStepState.COMPLETE:
                                buttonText = 'HAZARDS Controlled'
                                buttonColor = 'success'
                                break

                            default:
                                buttonText = 'Add an associated HAZARD'
                                buttonColor = 'danger'
                        }
                        return (
                            <div key={i}>

                                <div>
                                    <h3>{i + 1}:</h3>
                                    <h3>{text}</h3>
                                </div>
                                <IonButton
                                    color={buttonColor}
                                    onClick={() => editStepSelected(i)}
                                >
                                    {buttonText}
                                </IonButton>
                            </div>
                        )
                    })}
                </IonList>
            </IonContent>
            <TaskAnalysisNav />
        </>
    )
}
