import _ from 'lodash'
import { eqFilter, inFilter, neqFilter, periodFilter } from './filters'
import { ResourceFilters } from '../../types'

export interface BuildResourcesRequestOptions extends ResourceFilters {
    modifiedSince?: string
    include?: string[]
}

export const buildResourcesRequest = (resourceType: string, opts: BuildResourcesRequestOptions) => {

    let requestString = _.kebabCase(resourceType)

    // 'Adjust' eqFilter:
    const eqFilterAdjusted = opts.modifiedSince
        // If there's a 'modifiedSince' time, leave as - is
        ? opts.eqFilter
        // Otherwise, if there's an id field, remove it
        : opts.eqFilter
            ? Object.keys(opts.eqFilter).filter(key => key !== 'id').reduce((eqFilterNonId: { [key: string]: number | string | boolean | null | undefined }, key) => {
                eqFilterNonId[key] = opts.eqFilter![key]
                return eqFilterNonId
            }, {})
            : null

    // If filtering on resource id, can specify it directly as url segment rather than query string
    // HOWEVER don't use this approach if also specifying 'modifiedSince', as it will return a 404 error
    // if the modifiedSince criteria isn't met => prefer to specify the id via eqFilter in this case
    if (opts.eqFilter?.id && !opts.modifiedSince) {
        requestString = `${requestString}/${opts.eqFilter?.id}`
    }

    let filter = ''

    if (eqFilterAdjusted) {
        filter = `${filter}${eqFilter(eqFilterAdjusted)}`
    }

    if (opts.neqFilter) {
        filter = `${filter}${neqFilter(opts.neqFilter)}`
    }

    if (opts.inFilter) {
        filter = `${filter}${inFilter(opts.inFilter)}`
    }

    if (opts.periodFilter) {
        filter = `${filter}${periodFilter(opts.periodFilter.attr, { periodStart: opts.periodFilter.periodStart, periodEnd: opts.periodFilter.periodEnd })}`
    }

    // If we have a 'dataValidTo' time for existing data, filter request to only resources updated after that time
    if (opts.modifiedSince) {
        filter = `${filter}(lastModifiedDate,:gt,\`${opts.modifiedSince}\`)`
    }

    if (filter) {
        requestString = `${requestString}?filter=${filter}`
    }

    if (opts.include) {
        requestString = `${requestString}${filter ? '&' : '?'}include=${opts.include}`
    }

    return requestString
}