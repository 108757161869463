/**
 * Add image path to an image filename
 * Used where a resource stores the name only (e.g. an icon), and the actual location may vary
 * depending on context
 *
 */
const addImagePath = (imageName:string) => {
    return `./assets/ui_images/${imageName}`
}

export default addImagePath