import { AppDispatch, RootState } from '../../redux/store'

import { setRedirectPath } from '../../redux/slices/temp'
import { selectIncident } from '../../redux/selectors/incidents'

import { setIncidentReportToolboxMode } from '../../redux/slices/pgIncidentReports'
import { updateSequence } from './incidentReportsSequence'
import { IncidentReportEvent, stINCIDENT_REPORTS } from './incidentReportsTypes'
import { saveIncident } from './saveIncident'
import { toolboxReviewIncidentDone } from '../toolbox/toolboxIntegration'
import { loadIncident } from './loadIncident'

/************** Toolbox Integration ***************/

/**
 * Dispatched from toolbox page to initiate incident review / edit during toolbox meeting
 */
export const incidentReportToolboxReviewSelected = (incidentId: number) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    const { stIncidentReports, dirty } = getState().pgIncidentReports    
    
    if ((stIncidentReports === stINCIDENT_REPORTS.DETAILS_1 ||
        stIncidentReports === stINCIDENT_REPORTS.WORKERS_INVOLVED ||
        stIncidentReports === stINCIDENT_REPORTS.DETAILS_2 ||
        stIncidentReports === stINCIDENT_REPORTS.ACTIONS) && dirty) {
        // If an incident is in the process of being edited, save it
        await saveIncident()(dispatch, getState)
    }        
    await loadIncident(incidentId)(dispatch, getState, services)

    dispatch(setIncidentReportToolboxMode({ mode: true, incident: incidentId }))
    dispatch(setRedirectPath('/incidents'))
}

/**
 * Dispatched from toolbox page to initiate creating an incident report during toolbox meeting
 */
export const incidentReportToolboxCreateSelected = (siteId?: number) => async (dispatch: AppDispatch, getState: () => RootState) => {

    const { stIncidentReports, dirty } = getState().pgIncidentReports

    if ((stIncidentReports === stINCIDENT_REPORTS.DETAILS_1 ||
        stIncidentReports === stINCIDENT_REPORTS.WORKERS_INVOLVED ||
        stIncidentReports === stINCIDENT_REPORTS.DETAILS_2 ||
        stIncidentReports === stINCIDENT_REPORTS.ACTIONS) && dirty) {
        // If an observation is in the process of being edited, save it
        await saveIncident()(dispatch, getState)
    }

    dispatch(setIncidentReportToolboxMode({ mode: true, site: siteId }))
    dispatch(updateSequence(IncidentReportEvent.INCIDENT_TYPE_SELECTED))
    dispatch(setRedirectPath('/incidents'))
}

/**
 * Dispatched to return to toolbox page when in toolbox mode:
 * - From 'return to toolbox' buttons
 * - From navbar home icon
 */
export const incidentReportReturnToToolbox = (incidentId: number | null) => async (dispatch: AppDispatch, getState: () => RootState) => {

    // read back incident to get updated id and localId
    const incident = selectIncident(getState(), { incidentId })

    if (incident?.id) {
        dispatch(toolboxReviewIncidentDone({ id: incident.id, localId: incident.localId }))
    }
    dispatch(setIncidentReportToolboxMode({ mode: false }))
    dispatch(setRedirectPath('/toolbox'))
}