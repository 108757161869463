import { IonButton, IonButtons, IonIcon, IonToolbar } from '@ionic/react';
import { arrowBackCircle } from 'ionicons/icons';
import { useAppDispatch } from '../redux/hooks'
import { updateSequence } from '../features/signin/signinSequence';
import { SigninEvent } from '../features/signin/signinTypes';
import './AltNavBar.css';

interface AltNavProps { hideBackButton?: boolean, tapeColor?: string, pageTitle?: string }

const AltNavBar: React.FC<AltNavProps> = ({ hideBackButton, tapeColor, pageTitle }) => {
    const dispatch = useAppDispatch()

    return (
        <>
            <IonToolbar id="alt-nav-bar">
                {/* <div id="title-container"> */}
                    {hideBackButton
                        ? <></>
                        : <IonButtons slot="start">
                            <IonButton onClick={() => dispatch(updateSequence(SigninEvent.RESET_SEQUENCE))}>
                                <IonIcon icon={arrowBackCircle} slot="icon-only"></IonIcon>
                            </IonButton>
                        </IonButtons>
                    }
                    {pageTitle
                        ? <h1 id="page-title">{pageTitle}</h1>
                        : <></>
                    }
                {/* </div> */}
            </IonToolbar>
            <div className="divider-tape" style={{ "backgroundColor": tapeColor }}></div>
        </>
    );
}

export default AltNavBar;