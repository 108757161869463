/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import Axios, { AxiosError } from "axios"
import { ApiResult } from "../interfaces/api"
import { getApiRoot } from "../helpers/getApiRoot"
import { setAccessToken } from "./accessToken"

export const logout = async (): Promise<ApiResult> => {
  setAccessToken("")

  try {
    await Axios.post(`${getApiRoot()}auth/logout`)
    return ApiResult.SUCCESS
    
  } catch (err: any | AxiosError) {
    if (Axios.isAxiosError(err) && err.response) {
      // API responded with error code
      return ApiResult.SERVER_ERROR
    } else {
      // Request failed or no response from API
      // Probably means backend is down or no internet connection
      return ApiResult.NETWORK_ERROR 
    }
  }  
}
