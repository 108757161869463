import { useState } from "react"
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage
} from "@ionic/react"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { logout } from "../features/auth"
import { initialiseSigninState } from "../features/signin/initialiseSigninState"
import { setTermsAgreed } from "../features/startup/setTermsAgreed"
import DocContainer from "../components/DocContainer"
import { selectParam } from "../redux/selectors/parameters"
import "./AcceptTerms.css"

const AcceptTerms: React.FC = () => {
  const dispatch = useAppDispatch()
  const termsDoc = useAppSelector(selectParam("termsDoc"))

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [numPages, setNumPages] = useState<number>(1)
  const [pageNum, setPageNum] = useState<number>(1)

  const acceptSelected = () => {
    dispatch(setTermsAgreed())
    dispatch(initialiseSigninState())
  }

  const rejectSelected = () => {
    dispatch(logout())
  }

  return (
    <IonPage>
      <IonHeader>
        <div className="safe-area-top"></div>
      </IonHeader>
      <IonContent class="normal-page">
        <div>
          <DocContainer
            docId={+termsDoc}
            selectorParams={{}}
            setIsLoading={setIsLoading}
            setNumPages={setNumPages}
            pageNum={pageNum}
          />
        </div>
      </IonContent>
      <IonFooter class={numPages > 1 ? "accept-terms-paged-nav" : "accept-terms-nav"}>
        {numPages > 1 ? (
          <>
            <IonButton
              disabled={pageNum === 1}
              onClick={() => setPageNum(pageNum - 1)}
            >
              {"<"}
            </IonButton>
            <IonButton
              disabled={pageNum === numPages}
              onClick={() => setPageNum(pageNum + 1)}
            >
              {">"}
            </IonButton>
          </>
        ) : (
          <></>
        )}

        <IonButton disabled={isLoading} onClick={acceptSelected}>
          Accept Terms
        </IonButton>
        <IonButton onClick={rejectSelected}>Cancel</IonButton>
      </IonFooter>
    </IonPage>
  )
}

export default AcceptTerms
