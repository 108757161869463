import { createSelector } from '@reduxjs/toolkit'
import { getResources } from '../../datastore'
import { SiteObservationAction } from '../../datastore/models'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'

const selectResources = selectResourcesMemo(['siteObservations', 'siteObservationActions'])
const selectParams = selectParamsMemo()

export const selectSiteObservationActions = createSelector([selectResources, selectParams], (resources, params) => {
    
    const eqFilter = params.siteObservationId?{ observation: params.siteObservationId }:{}

    return getResources<SiteObservationAction>(resources, 'siteObservationActions', { eqFilter })    
})
