import { AppDispatch, RootState } from '../../redux/store'
import { deleteResource, saveResource, getResources } from '../../datastore'
import { TbAttendee } from '../../datastore/models'
import { initialiseToolboxAttendees } from '../../redux/slices/pgToolbox'
import { checklistItemsToAdd, checklistItemsToDelete } from './resourceChecklist'

export const saveTbAttendees = (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState()
    const { toolboxEdit, attendeesList } = state.pgToolbox

    // Find users that have just been de-selected as attendees
    // => delete corresponding 'tbAttendees' resources
    checklistItemsToDelete(attendeesList).forEach(userId => {
        const attendeesToDelete = getResources<TbAttendee>(state.resources, 'tbAttendees', { eqFilter: { toolbox: toolboxEdit.id, user: userId } })
        // Should be only a single resource, but if there are multiple records for the same user, delete them all
        attendeesToDelete.forEach(attendee => dispatch(deleteResource(attendee.id, 'tbAttendees')))
    })

    // Find users that have just been selected as attendees
    // => Save as new 'tbAttendees' resources
    checklistItemsToAdd(attendeesList).forEach(userId => {
        dispatch(saveResource({
            company: toolboxEdit.company,
            toolbox: toolboxEdit.id,
            user: userId
        }, 'tbAttendees'))
    })

    // Set preEdit to match checked now that changes have been saved
    dispatch(initialiseToolboxAttendees(attendeesList.checked.map(item=>item.id)))
}