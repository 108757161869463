/**
 * State management specific to 'toolbox meeting' page
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PhotoData } from '../../components/form/CameraInput'
import { stTOOLBOX } from '../../features/toolbox/toolboxTypes'

import { ResourceChecklist, setChecklistItem } from '../../features/toolbox/resourceChecklist'
import { ToolboxMeetingData } from '../../datastore/models/Toolbox'

export interface ToolboxEdit {
    id?: number
    company?: number
    site?: number
    project?: number
    creator?: number
    groupPhoto?: PhotoData
    meetingData: ToolboxMeetingData
    openDateTime?: string
    closeDateTime?: string
}

export interface PgToolboxState {
    stToolbox: stTOOLBOX
    reviewToolboxId?: number        // ID of toolbox being reviewed
    dirty: boolean

    attendeesList: ResourceChecklist,
    siteHazardsList: ResourceChecklist,
    observationsList: ResourceChecklist,
    incidentsList: ResourceChecklist,
    tasksList: ResourceChecklist,
    tasList: ResourceChecklist,

    toolboxEdit: ToolboxEdit,
}

const initialState: PgToolboxState = {
    stToolbox: stTOOLBOX.START,
    dirty: false,

    attendeesList: { preEdit: [], checked: [] },
    siteHazardsList: { preEdit: [], checked: [] },
    observationsList: { preEdit: [], checked: [] },
    incidentsList: { preEdit: [], checked: [] },
    tasksList: { preEdit: [], checked: [] },
    tasList: { preEdit: [], checked: [] },

    toolboxEdit: {
        meetingData: {
            rollCallComments: '',
            siteHazardsComments: '',
            observationsComments: '',
            incidentsComments: '',
            tasksComments: ''
        }
    }
}

export const pgToolbox = createSlice({
    name: 'pgToolbox',
    initialState,
    reducers: {

        /**
        * Replace entire slice with new data
        * (will be called when initialising from persistent storage)  
        * - merge with initial state in case no saved copy or new items have been added since last save
        */
        setAll(state, action: PayloadAction<PgToolboxState>) {
            return {
                ...state,
                ...action.payload
            }
        },

        /**
        * Reset state to initial values (e.g. on logout)
        *
        */
        setInitial(state, action: PayloadAction<void>) {
            return initialState
        },

        setToolboxState(state, action: PayloadAction<stTOOLBOX>) {
            state.stToolbox = action.payload
        },
        /*
                setToolboxProjectSite(state, action: PayloadAction<{projectId:number | null, siteId: number | null}>) {
                    state.projectId = action.payload.projectId
                    state.siteId = action.payload.siteId
                },
        */
        setToolboxReviewId(state, action: PayloadAction<number>) {
            state.reviewToolboxId = action.payload
        },

        /**
         * Initialise toolbox edit buffer (either with blank / default data or with an existing toolbox meeting record)         
         */
        initialiseToolboxEdit(state, action: PayloadAction<Partial<ToolboxEdit>>) {
            state.toolboxEdit = {
                ...initialState.toolboxEdit,
                ...action.payload
            }
            state.dirty = false
        },

        /**
         * Update toolbox edit buffer by merging new data with it 
         * (can be used to selectively set different attributes such as section comments)         
         */
        setToolboxEdit(state, action: PayloadAction<Partial<ToolboxEdit>>) {
            state.toolboxEdit = {
                ...state.toolboxEdit,
                ...action.payload
            }
            state.dirty = true
        },

        setToolboxMeetingData(state, action: PayloadAction<Partial<ToolboxMeetingData>>) {
            state.toolboxEdit.meetingData = {
                ...state.toolboxEdit.meetingData,
                ...action.payload
            }
            state.dirty = true
        },

        initialiseToolboxAttendees(state, action: PayloadAction<number[]>) {
            state.attendeesList = {
                preEdit: [...action.payload],
                checked: action.payload.map(id => ({ id }))
            }
        },

        setToolboxAttendee(state, action: PayloadAction<{ attendee: { id?: number, localId?: number }, newState: boolean }>) {
            if (action.payload.attendee.id) {
                state.attendeesList.checked = setChecklistItem(state.attendeesList.checked, action.payload.attendee, action.payload.newState)
                state.dirty = true
            }
        },

        initialiseToolboxSiteHazards(state, action: PayloadAction<number[]>) {
            state.siteHazardsList = {
                preEdit: [...action.payload],
                checked: action.payload.map(id => ({ id }))
            }
        },

        setToolboxSiteHazard(state, action: PayloadAction<{ hazard: { id?: number, localId?: number }, newState: boolean }>) {
            if (action.payload.hazard.id) {
                state.siteHazardsList.checked = setChecklistItem(state.siteHazardsList.checked, action.payload.hazard, action.payload.newState)
                state.dirty = true
            }
        },

        initialiseToolboxObservations(state, action: PayloadAction<number[]>) {
            state.observationsList = {
                preEdit: [...action.payload],
                checked: action.payload.map(id => ({ id }))
            }
        },

        setToolboxObservation(state, action: PayloadAction<{ observation: { id?: number, localId?: number }, newState: boolean }>) {
            if (action.payload.observation.id) {
                state.observationsList.checked = setChecklistItem(state.observationsList.checked, action.payload.observation, action.payload.newState)
                state.dirty = true
            }
        },

        initialiseToolboxIncidents(state, action: PayloadAction<number[]>) {
            state.incidentsList = {
                preEdit: [...action.payload],
                checked: action.payload.map(id => ({ id }))
            }
        },

        setToolboxIncident(state, action: PayloadAction<{ incident: { id?: number, localId?: number }, newState: boolean }>) {
            if (action.payload.incident.id) {
                state.incidentsList.checked = setChecklistItem(state.incidentsList.checked, action.payload.incident, action.payload.newState)
                state.dirty = true
            }
        },

        initialiseToolboxTasks(state, action: PayloadAction<number[]>) {
            state.tasksList = {
                preEdit: [...action.payload],
                checked: action.payload.map(id => ({ id }))
            }
        },

        setToolboxTask(state, action: PayloadAction<{ task: { id?: number, localId?: number }, newState: boolean }>) {
            if (action.payload.task.id) {
                state.tasksList.checked = setChecklistItem(state.tasksList.checked, action.payload.task, action.payload.newState)
                state.dirty = true
            }
        },

        initialiseToolboxTas(state, action: PayloadAction<number[]>) {
            state.tasList = {
                preEdit: [...action.payload],
                checked: action.payload.map(id => ({ id }))
            }
        },

        setToolboxTa(state, action: PayloadAction<{ ta: { id?: number, localId?: number }, newState: boolean }>) {
            if (action.payload.ta.id) {
                state.tasList.checked = setChecklistItem(state.tasList.checked, action.payload.ta, action.payload.newState)
                state.dirty = true
            }
        },

        setToolboxDirty(state, action: PayloadAction<boolean>) {
            state.dirty = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const {
    setAll,
    setInitial,
    setToolboxState,
    setToolboxReviewId,
    initialiseToolboxEdit,
    setToolboxEdit,
    setToolboxMeetingData,
    initialiseToolboxAttendees,
    setToolboxAttendee,
    initialiseToolboxSiteHazards,
    setToolboxSiteHazard,
    initialiseToolboxObservations,
    setToolboxObservation,
    initialiseToolboxIncidents,
    setToolboxIncident,
    initialiseToolboxTasks,
    setToolboxTask,
    initialiseToolboxTas,
    setToolboxTa,
    setToolboxDirty
} = pgToolbox.actions

export default pgToolbox.reducer