const siteContacts = {
  table: 'site_contacts',
  idKey: 'id',

  attrs: [
    'title',
    'contactType',
    'showOnBoard',
    'startDate',
    'endDate',
    'creationDate',
    'lastModifiedDate',
    'deleted'
  ],

  relationships: [
    {
      type: 'companies',
      key: 'company',
    },  
    {
      type: 'users',
      key: 'user',
    },
    {
      type: 'sites',
      key: 'site',
    }
  ],
}

export default siteContacts