const tbIncidents = {
  table: 'tb_incidents',
  idKey: 'id',

  attrs: [
    'creationDate',
    'lastModifiedDate',
    'deleted'
  ],

  relationships: [ 
    {
      type: 'companies',
      key: 'company',
    },   
    {
      type: 'toolboxes',
      key: 'toolbox',
    },
    {
      type: 'incidents',
      key: 'incident',
    }
  ],
}

export default tbIncidents