import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ResourceEvent } from '../../datastore/types'
import { DocRequestStatus } from '../../docstore/types'

export enum LoadStatus {
    NONE,
    LOAD_PENDING,
    SAVE_PENDING,
    LOADING,
    SAVING,
    LOAD_ERROR,
    SAVE_ERROR,
    COMPLETE
}

export interface DocStatus {
    loadStatus?: LoadStatus
    lastRequestTime?: string,
    lastModifiedDate?: string   // lastModifiedDate from docInfo (so we can detect if the document was updated on the server)
    lastLoaded?: string         // Last time document was loaded from backend
    lastUsed?: string
}

export interface DocMetaState {
    docStatus: { [key: string]: DocStatus }

    activeRequests: {
        [key: string]: {
            docIds: number[]
            requestStartTime: string
            status: DocRequestStatus
        }
    }
}

const initialState: DocMetaState = {
    docStatus: {},
    activeRequests: {}
}

export const docMeta = createSlice({
    name: 'docMeta',
    initialState,
    reducers: {
        /**
        * Replace entire slice with new data
        * (will be called when initialising from persistent storage)  
        */
        setAll(state, action: PayloadAction<DocMetaState>) {
            return {
                ...state,
                ...action.payload,
                // Don't use persisted activeRequests (should always be reset at startup)
                activeRequests: {}
            }
        },

        setDocStatus(state, action: PayloadAction<{ docId: number, docStatus: DocStatus }>) {

            state.docStatus[action.payload.docId] = {
                ...state.docStatus[action.payload.docId],
                ...action.payload.docStatus
            }
            if (action.payload.docStatus.loadStatus === LoadStatus.COMPLETE || action.payload.docStatus.loadStatus === LoadStatus.LOAD_ERROR) {
                // See if there's an active document request which includes this document
                let requestKey
                for (const key in state.activeRequests) {
                    const request = state.activeRequests[key]
                    if (request.status === DocRequestStatus.IN_PROGRESS && request.docIds.includes(action.payload.docId)) {
                        requestKey = key
                    }
                }

                if (requestKey) {
                    const allRequestDocs = state.activeRequests[requestKey].docIds
                    const completedDocs = allRequestDocs.filter(docId => state.docStatus[docId]?.loadStatus === LoadStatus.COMPLETE)
                    const failedDocs = allRequestDocs.filter(docId => state.docStatus[docId]?.loadStatus === LoadStatus.LOAD_ERROR)                

                    if (allRequestDocs.length === completedDocs.length) {
                        // All docs loaded sucessfully - flag request as complete / valid
                        docMeta.caseReducers.setDocRequestComplete(state, {
                            payload: { requestKey: requestKey, resourceEvent: ResourceEvent.RESOURCE_VALID },
                            type: 'docMeta/setDocRequestComplete'
                        })
                    } else if (allRequestDocs.length === completedDocs.length + failedDocs.length) {
                        // all doc requests completed but at least some failed to load sucessfully - flag request as complete / load error
                        docMeta.caseReducers.setDocRequestComplete(state, {
                            payload: { requestKey: requestKey, resourceEvent: ResourceEvent.RESOURCE_LOAD_ERROR },
                            type: 'docMeta/setDocRequestComplete'
                        })
                    }
                }
            }
        },

        clearDocStatus(state, action: PayloadAction<string>) {
            delete state.docStatus[action.payload]
        },

        setDocRequestStart(state, action: PayloadAction<{ requestKey: string, docIds: number[] }>) {
            state.activeRequests[action.payload.requestKey] = {
                docIds: action.payload.docIds,
                status: DocRequestStatus.IN_PROGRESS,
                requestStartTime: new Date().toISOString()
            }
        },

        setDocRequestComplete(state, action: PayloadAction<{ requestKey: string, resourceEvent: ResourceEvent }>) {
            if (state.activeRequests[action.payload.requestKey]) {

                switch (action.payload.resourceEvent) {
                    case ResourceEvent.NO_RESOURCE_FOUND:
                        state.activeRequests[action.payload.requestKey].status = DocRequestStatus.NO_DOC_FOUND
                        break

                    case ResourceEvent.RESOURCE_LOAD_ERROR:
                        state.activeRequests[action.payload.requestKey].status = DocRequestStatus.DOC_LOAD_ERROR
                        break

                    case ResourceEvent.RESOURCE_VALID:
                        state.activeRequests[action.payload.requestKey].status = DocRequestStatus.DOC_VALID
                        break

                }
            }
        },

        removeDocRequest(state, action: PayloadAction<string>) {
            delete state.activeRequests[action.payload]
        }

    }
})

// Action creators are generated for each case reducer function
export const { setDocStatus, clearDocStatus, setDocRequestStart, setDocRequestComplete, removeDocRequest } = docMeta.actions

export default docMeta.reducer