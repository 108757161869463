import { IonButton, IonContent, IonFooter } from "@ionic/react"
import { useAppSelector, useAppDispatch } from '../../redux/hooks'

import { siteObservationReturnToToolbox } from '../../features/siteObservations/siteObservationsToolbox'
import DocContainer from "../../components/DocContainer"
import { selectSiteObservation } from "../../redux/selectors/siteObservations"
import { useHomeIconHandler } from "../../hooks/homeIconHandler"
import { homeIconAction } from "../../features/siteObservations/homeIconAction"
import { updateSequence } from "../../features/siteObservations/siteObservationsSequence"
import { SiteObservationEvent } from "../../features/siteObservations/siteObservationsTypes"
import { useTemplateDoc } from "../../features/commonResourceRequests/useTemplateDoc"

export const Review: React.FC = () => {
    const dispatch = useAppDispatch()

    const { toolboxMode, reviewSiteObservationId } = useAppSelector(state => state.pgSiteObservations)
    const reviewObservation = useAppSelector(state => selectSiteObservation(state, { siteObservationId: reviewSiteObservationId }))

    const { templateDoc } = useTemplateDoc(reviewObservation?.company, 'siteObservation')
    useHomeIconHandler('/observations', () => dispatch(homeIconAction()))

    return (
        <>
            <IonContent class="normal-page">
                <DocContainer
                    docId={templateDoc}
                    selectorParams={{ siteObservationId: reviewSiteObservationId }}
                />
            </IonContent>

            <IonFooter class="observations-nav">
                {toolboxMode
                    ? <IonButton style={{ "gridColumnStart": "1" }}
                        onClick={() => {
                            dispatch(siteObservationReturnToToolbox(reviewSiteObservationId || null))
                            dispatch(updateSequence(SiteObservationEvent.BACK_SELECTED))
                        }}
                    >
                        Return to Toolbox Meeting
                    </IonButton>
                    :
                    <IonButton style={{ "gridColumnStart": "1" }} onClick={() => dispatch(updateSequence(SiteObservationEvent.BACK_SELECTED))}>Back</IonButton>
                }
            </IonFooter>
        </>)
}
