const hazardTypeControls = {
  table: 'hazard_type_controls',
  idKey: 'id',

  attrs: [ 
    'creationDate',
    'lastModifiedDate',
    'deleted'
  ],

  relationships: [   
    {
      type: 'hazard-types',
      key: 'hazardType',
    },
    {
      type: 'controls',
      key: 'control',
    },
  ],
}

export default hazardTypeControls