import {
    IonHeader,
    IonPage,
} from '@ionic/react'

import React from 'react'

import NavBar from '../../components/NavBar'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'

import { stTASKANALYSIS } from '../../features/taskAnalysis/taskAnalysisTypes'

import { Start } from './Start'
import { GeneralDetails } from './GeneralDetails'
import { StepListCreate } from './StepListCreate'
import { StepListHazards } from './StepListHazards'
import { Review } from './Review'
import { Signoff } from './Signoff'

import { SelectControls } from './SelectControls'
import { SelectPpe } from './SelectPpe'
import { SelectHazardCategories } from './SelectHazardCategories'
import { SelectHazards } from './SelectHazards'
import { IrScore } from './IrScore'
import { RrScore } from './RrScore'
import { SelectTaTemplateModal } from './SelectTaTemplateModal'
import { useModalManager } from '../../hooks/modalManager'
import { setHomeIconEvent } from '../../redux/slices/temp'
import { DraftRegister } from './DraftRegister'
import { ActiveRegister } from './ActiveRegister'

const TaskAnalysis: React.FC = () => {

    const { stTaskAnalysis, toolboxMode } = useAppSelector((state) => state.pgTaskAnalysis)
    const dispatch = useAppDispatch()
    const { setModalFns, presentModal } = useModalManager()

    function renderSwitch() {
        switch (stTaskAnalysis) {

            case stTASKANALYSIS.START:
                return <Start presentModal={presentModal} />

            case stTASKANALYSIS.DRAFT_REGISTER:
                return <DraftRegister />

            case stTASKANALYSIS.ACTIVE_REGISTER:
                return <ActiveRegister />

            case stTASKANALYSIS.GENERAL_DETAILS:
                return <GeneralDetails />

            case stTASKANALYSIS.STEP_LIST_CREATE:
                return <StepListCreate />

            case stTASKANALYSIS.STEP_LIST_HAZARDS:
                return <StepListHazards />

            case stTASKANALYSIS.SELECT_HAZARD_CATEGORIES:
                return <SelectHazardCategories />

            case stTASKANALYSIS.SELECT_HAZARDS:
                return <SelectHazards />

            case stTASKANALYSIS.INITIAL_RISK_SCORE:
                return <IrScore />

            case stTASKANALYSIS.SELECT_CONTROLS:
                return <SelectControls />

            case stTASKANALYSIS.RESIDUAL_RISK_SCORE:
                return <RrScore />

            case stTASKANALYSIS.SELECT_PPE:
                return <SelectPpe />

            case stTASKANALYSIS.REVIEW:
                return <Review />

            case stTASKANALYSIS.SIGNOFF:
                return <Signoff />
        }
    }

    return (
        <IonPage className="normal-page">
            <IonHeader>
                <NavBar
                    pageTitle="SWMS"
                    iconPath={toolboxMode ? "/assets/ui_images/speech_bubble.svg" : undefined}
                    homeAction={() => dispatch(setHomeIconEvent(true))}
                />
            </IonHeader>

            {renderSwitch()}

            {/* The following components set up useModal hooks and then call 'setModalFns' to give us the corresponding 'present' and 'dismiss' fns */}
            <SelectTaTemplateModal setModalFns={setModalFns('selectTaTemplate')} />

        </IonPage>
    )
}

export default TaskAnalysis
