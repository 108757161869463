
import { IonButton, IonContent, IonFooter, IonItem, IonItemOption, IonList } from '@ionic/react'
import { SlidingItem } from './SlidingItem'


interface SelectListProps {
    title: string
    listData: { text: string, id: number }[]
    selectOption: (seletedId: number) => void
    deleteOption?: (seletedId: number) => void
    dismiss?: () => void
    padTop?: boolean
}

export const SelectList: React.FC<SelectListProps> = ({ title, listData, selectOption, deleteOption, dismiss, padTop }) => {

    return (
        <>
            <IonContent force-overscroll="false" class='content-padding'>
                {padTop ? <div className='safe-area-top'></div> : <></>}
                <h2>{title}</h2>
                <IonList>
                    {listData.map((item, i) => (
                        deleteOption ?
                            <SlidingItem
                                key={item.id}
                                itemContent={
                                    <span onClick={() => selectOption(item.id)}>{item.text}</span>
                                }
                                optionsContent={
                                    <IonItemOption color="danger" onClick={() => deleteOption(item.id)}>Delete</IonItemOption>
                                }
                            />
                            : <IonItem key={item.id} onClick={() => selectOption(item.id)}>
                                <span>{item.text}</span>
                            </IonItem>
                    )
                    )}
                </IonList>

            </IonContent>
            {dismiss
                ? <IonFooter>
                    <div className="flex centre-row safe-area-bottom">
                        <IonButton onClick={dismiss}>Cancel</IonButton>
                    </div>
                </IonFooter>
                : <></>}
        </>
    )
}