import { createSelector } from '@reduxjs/toolkit'
import { getResources } from '../../datastore'
import { DocInfo, Site } from '../../datastore/models'
import { RootState } from '../../redux/store'
import { includesSomeClaimsAboutCompany, includesSomeClaimsAboutSite } from './abilities'
import { selectCompanies } from './companies'

import { selectParamsMemo } from './helpers/selectParamsMemo'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'
import { selectOpenSites } from './sites'

export interface FolderInfo {
    key: string,
    folderName: string,
    subfolders: FolderInfo[],
    items: {
        id: number,
        itemText: string
    }[]
}

/*
path = companies/[companyId]/sites/[siteId]
*/

const selectPermissions = (state: RootState) => state.app.permissions
const selectUser = (state: RootState) => state.app.user
const selectResources = selectResourcesMemo(['companies', 'projects', 'projectSites', 'sites', 'signinDocs', 'docInfo'])
const selectParams = selectParamsMemo()

export const selectFolderContents = createSelector([
    selectCompanies,
    selectOpenSites,
    selectPermissions,
    selectUser,
    selectResources,
    selectParams]
    , (companies, openSites, permissions, user, resources, params): FolderInfo => {

        // Normally we want to select only sites that are open, but this can only be determined from projects and projectSites
        // resources, which are not available if user has signed in to a site they don't have permission for (e.g. guest login)
        // => if no open sites found, fall back to whatever site record(s) we do have in the store - this will be a single record for 
        // the site user is signed in to
        const sites = openSites.length?openSites:getResources<Site>(resources, 'sites')

        if (!sites.length) {
            return {
                key: '',
                folderName: '',
                subfolders: [],
                items: []
            }
        }

        const pathSegments = (params.path || '').split('/')

        const company = (pathSegments[0] === 'companies' && pathSegments.length > 1) ? companies.find(company => +company.id === +pathSegments[1]) : null
        const site = (pathSegments[2] === 'sites' && pathSegments.length > 3) ? sites.find(site => +site.id === +pathSegments[3]) : null

        /***** 'all companies' level ******/
        if (pathSegments.length === 1 && pathSegments[0] === 'companies') {

            const globalDocInfo = getResources<DocInfo>(resources, 'docInfo', {
                eqFilter: { company: 1, path: 'company', showInApp: true }
            })

            const items = globalDocInfo
                .map(docInfo => ({ id: docInfo.id, itemText: docInfo.fileName }))
            return {
                key: 'companies',
                folderName: 'All Documents',
                subfolders: companies.map(company => ({
                    key: company.id.toString(),
                    folderName: company.companyName || '',
                    subfolders: [],
                    items: []
                })),
                items
            }
        }

        /**** specific company level (currently just 'sites' folder; could add others?) ******/
        if (pathSegments.length === 2 && company) {

            const companyDocInfo = getResources<DocInfo>(resources, 'docInfo', {
                //inFilter: { company: [company.id, 1] },
                eqFilter: {  company: company.id, path: 'company', showInApp: true }
            })
            const items = companyDocInfo
                .map(docInfo => ({ id: docInfo.id, itemText: docInfo.fileName }))

            return {
                key: (company?.id || 0).toString(),
                folderName: company?.companyName || '',
                subfolders: [{
                    key: 'sites',
                    folderName: 'sites',
                    subfolders: [],
                    items: []
                }],
                items
            }
        }

        /****** Contents of 'sites' folder (list of specific sites) *****/
        if (pathSegments.length === 3 && company && pathSegments[2] === 'sites') {

            return {
                key: 'sites',
                folderName: 'sites',
                subfolders: sites.filter(site => site.company === company?.id).map(site => ({
                    key: site.id.toString(),
                    folderName: site.siteName,
                    subfolders: [],
                    items: []
                })),
                items: []
            }
        }

        /*********** files for a specific site **********/
        if (pathSegments.length === 4 && company && site) {

            // Shouldn't really need to check permission here as back end will only give us documents we have access to...
            // this is to allow for docs like 'personnel on site' that are readable at the company level but should only be
            // displayed for specific sites where we have 'supervisor' access
            const canViewSupervise = site ? (
                user?.isAdmin || 
                includesSomeClaimsAboutCompany(permissions, site.company, ['canSuperviseHealthAndSafety', 'canManageHealthAndSafety', 'canManageCompany']) ||
                includesSomeClaimsAboutSite(permissions, site.id, ['canSuperviseHealthAndSafety','canManageHealthAndSafety'])
            ) : false

            // global or company 'site' docs that pertain to any site (NOT a specific site)
            const genericSiteDocInfo = getResources<DocInfo>(resources, 'docInfo', {
                eqFilter: { showInApp: true },
                inFilter: { company: [company.id, 1], path: ['site'] }
            }).filter(docInfo => !docInfo.site)

            // docs for this specific site 
            const siteDocInfo = getResources<DocInfo>(resources, 'docInfo', {
                eqFilter: { site: site?.id, showInApp: true },
                inFilter: { path: ['site'] }
            })

            const items = genericSiteDocInfo.concat(siteDocInfo)
                .filter(docInfo => canViewSupervise || docInfo.viewAccess !== 'canSuperviseHealthAndSafety')
                .map(docInfo => ({ id: docInfo.id, itemText: docInfo.fileName }))
            return {
                key: (site?.id || 0).toString(),
                folderName: site?.siteName || '',
                subfolders: [],
                items
            }
        }

        return {
            key: '',
            folderName: '',
            subfolders: [],
            items: []
        }
    })