const incidentWorkers = {
  table: 'incident_workers',
  idKey: 'id',

  attrs: [  
    'injuryType',    
    'injuryDescription',    
    'creationDate',
    'lastModifiedDate',
    'deleted'
  ],

  relationships: [    
    {
      type: 'companies',
      key: 'company',
    },    
    {
      type: 'incidents',
      key: 'incident',
    },
    {
      type: 'users',
      key: 'user',
    },
    {
      type: 'docInfo',
      key: 'photo',
    }

  ],
}

export default incidentWorkers