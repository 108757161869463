import { Subscription } from 'rxjs';
import { isPlatform } from '@ionic/react';
import { App } from '@capacitor/app';
import { AuthActions, AuthService } from 'ionic-appauth';
import { AxiosRequestor } from './AxiosRequestor';
import { CapacitorRequestor } from './CapacitorRequestor';
import { CapacitorBrowser, DefaultBrowser } from './CapacitorBrowser';
import { CapacitorSecureStorage } from './CapacitorSecureStorage';

import env from "../config"

export interface TokenResponse {
    accessToken: string;
    expiresIn: number | undefined;
    refreshToken: string | undefined;
    scope: string | undefined;
    idToken: string | undefined;
    issuedAt: number;
}

var authService: AuthService

export const getAuthService = () => authService
export const initAuthService = () => authService = Auth.Instance

export class Auth {

    private static authService: AuthService | undefined;

    private static buildAuthInstance() {

        const redirectUri = isPlatform("capacitor") ? (env.appCallbackUri || '') : window.location.origin + "/callback";
        const postLogoutRedirectUri = isPlatform("capacitor") ? (env.appLogoutUri || '') : window.location.origin + "/splash";
        const scopes =
            "openid profile healthandsafety api.buildaprice.entities.read api.buildaprice.users.read api.buildaprice.workers.read api.buildaprice.projects.read api.buildaprice.tasks.read"

        const browser = isPlatform('capacitor') ? new CapacitorBrowser() : new DefaultBrowser();
        const requestor = isPlatform('capacitor') ? new CapacitorRequestor() : new AxiosRequestor();

        const authService = new AuthService(browser, new CapacitorSecureStorage(), requestor);
        authService.authConfig = {
            client_id: env.portalClientId || "",
            server_host: env.portalAuthUrl || "",
            redirect_url: redirectUri,
            end_session_redirect_url: postLogoutRedirectUri,
            scopes: scopes,
            pkce: true
        }

        if (isPlatform('capacitor')) {
            App.addListener('appUrlOpen', (data: any) => {
                if ((data.url).indexOf(authService.authConfig.redirect_url) === 0) {
                    authService.authorizationCallback(data.url);
                } else {
                    authService.endSessionCallback();
                }
            });
        }

        authService.init();
        return authService;
    }

    public static get Instance(): AuthService {
        if (!this.authService) {
            this.authService = this.buildAuthInstance();
        }

        return this.authService;
    }
}

/**
 * The following utility functions convert ionic-appauth subscriptions into promises
 * 
 * When authService.events$.subscribe is called it will typically give us the previous event 
 * which we don't usually want (we want the event resulting from whatever action we're performing)
 * -> functions work as follows:
 * - set 'drop'bit
 * - create subscription with handler that will ignore ('drop') events as long as drop bit is set. This will
 *   discard any pre-existing events
 * - clear 'drop' bit
 * - Perform required action (signIn etc)
 * - Promise will then resolve / reject based on new event resulting from action
 */

export const authLogin = async (): Promise<void> => {

    return new Promise(async (resolve, reject) => {

        let drop = true
        let sub: Subscription

        sub = authService.events$.subscribe(async (action) => {
            if (!drop) {
                if (action.action === AuthActions.SignInSuccess) {
                    sub.unsubscribe();
                    resolve()
                }

                if (action.action === AuthActions.SignInFailed) {
                    sub.unsubscribe();
                    reject(new Error(action.error))
                }
            }
        })

        // Ensure promise resolves if subscription fails to return a result
        // Note there will normally be no result if we're waiting for the user to log in - 
        // we'll get the result via the callback rather than here
        setTimeout(() => reject(new Error('Timeout')), 5000)

        drop = false
        try {
            await authService.signIn()
        } catch (err) { }
    })
}


export const authGetCallbackTokenResponse = async (url: string): Promise<TokenResponse> => {

    return new Promise((resolve, reject) => {

        let drop = true
        let sub: Subscription

        sub = authService.events$.subscribe(async (action) => {
            if (!drop) {
                if (action.action === AuthActions.SignInSuccess) {
                    sub.unsubscribe();
                    if (action.tokenResponse) {
                        resolve(action.tokenResponse)
                    } else {
                        reject(new Error('Token response missing'))
                    }
                }

                if (action.action === AuthActions.SignInFailed) {
                    sub.unsubscribe();
                    reject(new Error(action.error))
                }
            }
        })

        // Ensure promise resolves if subscription fails to return a result
        setTimeout(() => reject(new Error('Timeout, no response')), 5000)

        drop = false
        authService.authorizationCallback(url)
    });
}

export interface AuthUser {
    first_name: string
    last_name: string
    sub: string
}

export const authGetUser = async (): Promise<AuthUser> => {

    return new Promise(async (resolve, reject) => {

        let drop = true
        let sub: Subscription

        sub = authService.events$.subscribe(async (action) => {
            if (!drop) {
                if (action.action === AuthActions.LoadUserInfoSuccess) {

                    sub.unsubscribe();
                    if (action.user) {
                        resolve(action.user)
                    } else {
                        reject(new Error('Auth user missing'))
                    }
                }

                if (action.action === AuthActions.LoadUserInfoFailed) {
                    sub.unsubscribe();
                    reject(new Error(action.error))
                }
            }

        })

        // Ensure promise settles if subscription fails to return a result
        setTimeout(() => reject(new Error('Timeout, no response')), 5000)

        drop = false
        try {
            await authService.loadUserInfo()
        } catch (err) { }
    })
}

export const authRefreshToken = async (): Promise<TokenResponse> => {

    return new Promise(async (resolve, reject) => {

        let drop = true
        let sub: Subscription

        sub = authService.events$.subscribe(async (action) => {
            if (!drop) {
                if (action.action === AuthActions.RefreshSuccess) {
                    sub.unsubscribe();
                    if (action.tokenResponse) {
                        resolve(action.tokenResponse)
                    } else {
                        reject(new Error('Token response missing'))
                    }
                }

                if (action.action === AuthActions.RefreshFailed) {
                    sub.unsubscribe();
                    reject(new Error(action.error))
                }
            }
        })

        // Ensure promise resolves if subscription fails to return a result
        setTimeout(() => reject(new Error('Timeout, no response')), 5000)

        drop = false
        try {
            await authService.refreshToken()
        } catch (err) { }
    })
}