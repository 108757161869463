import { NormalizedData } from "@disruptph/json-api-normalizer"
import { User, UnsavedUser, DocInfo, UnsavedDocInfo } from "../datastore/models"
import { UserPermissions } from "../redux/slices/app"

export enum ApiResult {
  NONE,
  SUCCESS,
  SERVER_ERROR,
  CLIENT_ERROR,
  AUTH_ERROR,
  NETWORK_ERROR
}

export interface ApiService {
  apiPing: () => Promise<ApiResult>
  isOnline: () => boolean
  setOnline: (online: boolean) => void

  getSiteToken: (signinCode: string) => Promise<ApiResult>
  setAccessToken: (newToken:string) => void
  hasToken: () => boolean

  loginGuest: (
    signinCode: string,
    guestUser: UnsavedUser
  ) => Promise<{ result: ApiResult; userId?: number, accessToken: string }>
  getCurrentUser: () => Promise<{
    result: ApiResult
    responseData: {
      user: User
      permissions: UserPermissions      
    } | null
  }>
  getUpdateUser: () => Promise<{
    result: ApiResult
    responseData: {
      user: User
      permissions: UserPermissions      
    } | null
  }>
  logout: (origin: string) => Promise<ApiResult>

  apiGetResource: (
    endpoint: string
  ) => Promise<{ result: ApiResult; responseData?: NormalizedData | null }>
  apiPostResource: (
    resource: NormalizedData
  ) => Promise<{ result: ApiResult; responseData?: NormalizedData | null }>
  apiPatchResource: (
    resource: NormalizedData
  ) => Promise<{ result: ApiResult; responseData?: NormalizedData | null }>

  getDocument: (
    endpoint: string,
    options?: {}
  ) => Promise<{ result: ApiResult; responseData?: any }>
  addDocument: (
    docInfo: DocInfo | UnsavedDocInfo,
    docData: any,
    options?: {}
  ) => Promise<{
    result: ApiResult
    status?: number
    responseData?: DocInfo | null
  }>

  addLogEntry: (logEntry: NormalizedData) => Promise<{ result: ApiResult }>
}
