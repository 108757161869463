const tasks = {
    table: 'tasks',
    idKey: 'id',

    attrs: [        
        'startDate', //!! Not in BP
        'taskName',
        'description',
        'status',
        'path',
        'taskType',
        'parent',
        'linkedTask',        
        'creationDate',
        'lastModifiedDate'
    ],

    relationships: [
        {
            type: 'companies',
            key: 'company',
        },
        {
            type: 'projects',
            key: 'project',            
        }        
    ],
}

export default tasks