import { login } from './login'
import { loginGuest } from './loginGuest'
import { logout } from './logout'
import { restoreExistingSession } from './restoreExistingSession'

enum UserCheckStatus {
    LOGGED_OUT,
    LOGGED_IN_NEW,      // User has just completed login
    LOGGED_IN_PREV,     // User was previously logged in
    NETWORK_FAILED
}

export {
    UserCheckStatus,
    login,
    logout,
    loginGuest,
    restoreExistingSession    
}