import { AppDispatch, RootState } from '../../redux/store'
import { saveResource } from '../../datastore'
import { selectUsers } from '../../redux/selectors/users'
import reportRequests from '../reportRequests'
import log from 'loglevel'

export const sendIncidentNotification = (incidentId: number, recipientIds: number [], previouslyNotified: boolean) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    const state = getState()

    const user = state.app.user
    // Assumes that recipients are also siteContacts and have therefore already been requested
    const recipients = selectUsers(state, { userIds: recipientIds })   

    await reportRequests.incident.request({ incidentId })(dispatch, getState, services)
    const reportData = reportRequests.incident.get({ incidentId })(dispatch, getState)

    if (!recipients || !recipients.length) {
       log.error(`sendIncidentNotification: no recipients found`)
       return
    }

    if (!reportData) {
        log.error(`sendIncidentNotification: could not get report data incident ${incidentId}`)
        return
    }

    dispatch(saveResource({
        company: reportData.incident.company,
        notificationType: 'Incident',
        initiator: user?.id,
        recipients: recipients.map(recipient => ({
            userId: recipient.id,
        })),
        userData: {
            ...reportData,
            images: reportData.incident.images,
            emailBody: previouslyNotified ? 'The attached incident report has been updated - please review and take any corrective actions required':''
        }
    }, 'notifications'))

}