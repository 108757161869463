export interface DocData {
    docBlob: Blob
    docExt: string
}

export enum DocRequestStatus {
    NONE,
    IN_PROGRESS,
    DOC_LOAD_ERROR,  // Error attempting to get resource from api (auth, network failure, etc) 
    NO_DOC_FOUND,    // Successfully connected to api but document doesn't exist
    DOC_VALID        // valid copy of requested document stored in state / local storage (either we got it sucessfully from api or we already had a valid offline copy)
}