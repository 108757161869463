import { AppDispatch, RootState } from "../../redux/store"
import { requestResources } from "../../datastore"
import { selectSite } from "../../redux/selectors/sites"
import { setRequestComplete, setRequestStart } from "../../redux/slices/resourceMeta"
import { resourceEventCombined } from "../../datastore/resourceEventCombined"
import { selectHighRiskWork } from "../../redux/selectors/highRiskWork"

export const requestSiteHighRiskWork = (params: { [key: string]: any }) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const { siteId, requestKey } = params

    if (requestKey) {
        dispatch(setRequestStart({ requestKey }))
    }

    const results = await Promise.all([
        requestResources('sites', { eqFilter: { id: siteId } })(dispatch, getState, services),
        requestResources('highRiskWork', {})(dispatch, getState, services)
    ])

    const requestResult = resourceEventCombined(results[0], [results[1]])
    if (requestKey) {
        dispatch(setRequestComplete({ requestKey, resourceEvent: requestResult}))
    }
    return requestResult
}

export const getSiteHighRiskWork = (params: { [key: string]: any }) => (dispatch: AppDispatch, getState: () => RootState) => {
    const siteId = params.siteId
    const state = getState()

    const site = selectSite(state, { siteId })    
    if (!site) {
        return null
    }
        
    const stdHighRiskWork = selectHighRiskWork(state, {}) 
    return { 
        highRiskWork: stdHighRiskWork.map(item => ({
            text: item.text,
            detail: item.detail,
            isSelected: !!site.highRiskWork.find(siteItem => siteItem.typeId === item.id)
        })).concat(
            site.highRiskWork.filter(item => !item.typeId)
                .map(item => ({
                    text: item.customText || '',
                    detail: '',
                    isSelected: true
                }))
        )
     }    
}