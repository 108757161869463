const notifications = {
  table: 'notifications',
  idKey: 'id',

  attrs: [    
    'notificationType',
    'recipients',
    'status',    
    'userData',
    'creationDate',
    'lastModifiedDate',    
  ],

  relationships: [ 
    {
      type: 'companies',
      key: 'company',
    },  
    {      
      type: 'users',
      key: 'initiator',
    },
    {
      type: 'doc-info',
      key: 'templateDoc'
    }
  ],
}

export default  notifications