import { isBefore, isValid, parseJSON } from "date-fns"
import { LocalResourceData } from '../../redux/slices/resources'

export const createPeriodFilter = (params: { attr: string, periodStart?: Date | string, periodEnd?: Date | string }) => {

    const from = params.periodStart?parseJSON(params.periodStart):null
    const to = params.periodEnd?parseJSON(params.periodEnd):null
    
    return (resource: LocalResourceData/*<unknown>*/) => {

        const testDate = parseJSON(resource?.attributes ? resource.attributes[params.attr] : null)

        if (!isValid(testDate)) {
            // If no attribute name, or attribute value is not a valid date, filter will never match
            return false
        }

        if (!from && !to) {
            // If no from / to dates provided, filter will aways match 
            return true
        }

        return (!from || !isBefore(testDate, from)) && (!to || isBefore(testDate, to))

    }
}