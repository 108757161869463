const appLog = {
  table: 'app_log',
  idKey: 'id',

  attrs: [
    'logTime',
    'level',
    'logText',    
    'logMeta'
  ],

  relationships: [           
    {
      type: 'users',
      key: 'user',
    },
  ],
}

export default appLog