const notifiableWork = {
  table: 'notifiable_work',
  idKey: 'id',

  attrs: [
    'text',
    'detail',
    'exclusions',
    'creationDate',
    'lastModifiedDate',
    'deleted'   
  ],

  relationships: [],
  }

export default notifiableWork
  