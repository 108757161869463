import companies from './companies'
import users from './users'
import projects from './projects'
import sites from './sites'
import projectSites from './project-sites'
import docInfo from './doc-info'
import signinDocs from './signin-docs'
import signins from './signins'
import siteContacts from './site-contacts'
import ppeTypes from './ppe-types'
import sitePpe from './site-ppe'
import controls from './controls'
import hazardTypes from './hazard-types'
import hazardTypeControls from './hazard-type-controls'
import hazardCategories from './hazard-categories'
import jobCategories from './job-categories'
import taTemplates from './ta-templates'
import tas from './tas'
import appLog from './app-log'
import notifications from './notifications'
import taWorkers from './ta-workers'
import siteHazards from './site-hazards'
import tbAttendees from './tb-attendees'
import toolboxes from './toolboxes'
import inductions from './inductions'
import tasks from './tasks'
import tbTasks from './tb-tasks'
import tbTas from './tb-tas'
import tbOther from './tb-other'
import siteObservations from './site-observations'
import siteObservationActions from './site-observation-actions'
import incidents from './incidents'
import incidentWorkers from './incident-workers'
import tbObservations from './tb-observations'
import tbIncidents from './tb-incidents'
import incidentActions from './incident-actions'
import tbSiteHazards from './tb-site-hazards'
import notifiableWork from './notifiable-work'
import regions from './regions'
import siteUsers from './site-users'
import parameters from './parameters'

// Names that types are exported as here determine: 
// - the endpoints they are exposed as
// - the 'type' field in the JSON document
const models = {
  companies,
  users,
  inductions,
  parameters,
  projects,
  sites,
  'doc-info':docInfo,
  'ppe-types':ppeTypes,
  'project-sites':projectSites,  
  'signin-docs':signinDocs,
  signins,
  'site-contacts': siteContacts,
  'site-users': siteUsers,
  'site-ppe':sitePpe,
  controls,
  'hazard-types': hazardTypes,
  'hazard-type-controls' : hazardTypeControls,
  'hazard-categories' : hazardCategories,
  'job-categories' : jobCategories,
  'ta-templates' : taTemplates,
  tas,
  'app-log': appLog,
  'notifiable-work': notifiableWork,
  notifications,
  'ta-workers' : taWorkers,
  'site-hazards' : siteHazards,
  toolboxes,
  'tb-attendees' : tbAttendees,
  'tb-site-hazards' : tbSiteHazards,
  'tb-observations': tbObservations,
  'tb-incidents' : tbIncidents,
  'tb-tasks': tbTasks,
  'tb-tas': tbTas,
  'tb-other': tbOther,
  tasks,
  'site-observations': siteObservations,
  'site-observation-actions': siteObservationActions,
  incidents,
  'incident-workers': incidentWorkers,
  'incident-actions': incidentActions,
/*
  products,
  orders,
  lineItems,
  vendors,
*/
  regions
}

export default models