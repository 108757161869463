import { createSelector } from "@reduxjs/toolkit"
import { RequestStatus } from "../../datastore/types"
import { RootState } from "../../redux/store"
import { selectParamsMemo } from "./helpers/selectParamsMemo"

const selectAllActiveRequests = (state: RootState) =>
  state.resourceMeta.activeRequests
const selectRequestStatusParams = selectParamsMemo()
export const selectRequestStatus = createSelector(
  [selectAllActiveRequests, selectRequestStatusParams],
  (allActiveRequests, params) => {
    if (!allActiveRequests || !params.requestKey) {
      return null
    }

    const requestMeta = allActiveRequests[params.requestKey]
    if (!requestMeta) {
      return null
    }

    return requestMeta.status
  }
)

export const selectRequestResult = createSelector(
  [selectAllActiveRequests, selectRequestStatusParams],
  (allActiveRequests, params) => {
    if (!allActiveRequests || !params.requestKey) {
      return { status: RequestStatus.NONE }
    }

    const requestMeta = allActiveRequests[params.requestKey]
    if (!requestMeta) {
      return { status: RequestStatus.NONE }
    }

    return {
      status: requestMeta.status
    }
  }
)

const selectRequestInProgressParams = selectParamsMemo()
/**
 * Determine whether a given request is already in progress by searching for requestType and options in activeRequests
 * (used for request de-duping logic)
 */
export const selectRequestInProgress = createSelector(
  [selectAllActiveRequests, selectRequestInProgressParams],
  (allActiveRequests, params) => {
    const { resourceType, opts } = params
    if (!resourceType || !opts) {
      return null
    }
    for (const requestKey of Object.keys(allActiveRequests)) {
      const activeRequest = allActiveRequests[requestKey]
      if (
        activeRequest.status === RequestStatus.IN_PROGRESS &&
        activeRequest.resourceType === resourceType &&
        activeRequest.opts === JSON.stringify(opts)
      ) {
        return requestKey
      }
    }
    return null
  }
)

const selectAllResources = (state: RootState) => state.resources
const selectResourceUpdateStatus = (state: RootState) =>
  state.resourceMeta.resourceUpdateStatus
const selectResourceMetaValidParams = selectParamsMemo()
/**
 * Test whether resourceMeta 'cache' status is valid for a given resource type
 * (used at startup to try to detect if our saved cache status says we have something in the cache when in fact we don't,
 * e.g. due to indexDB write failure / corruption)
 * 
 * currently looks for:
 * - resource slice not empty (i.e. we've successfully read at least one record)
 * - If cache is specified by explicit id(s) (e.g. pre-loaded document list), existing records for all ids
 * 
 * This is not exhaustive, but should catch at least some issues
 */
export const selectResourceUpdateStatusValid = createSelector(
  [
    selectAllResources,
    selectResourceUpdateStatus,
    selectResourceMetaValidParams
  ],
  (resources, resourceUpdateStatus, params) => {
    const { type } = params
    if (!type) {
      // no resource type supplied
      return false
    }

    const resource = resources[type as keyof typeof resources]
    const resourceStatus = resourceUpdateStatus[type as keyof typeof resources]

    if (!resource || !resourceStatus) {
      // Invalid resource type
      return false
    }

    if (!resourceStatus.lastUpdated) {
      // Resource has never been read
      return false
    }

    if (!Object.keys(resource).length) {
      // resource currently has no data
      return false
    }

    // If autoRequestFilter specifies resources explicitly by id, look for resources matching all ids listed
    const explicitIds =
      resourceStatus.autoRequestFilter?.inFilter?.id ||
      (resourceStatus.autoRequestFilter?.eqFilter?.id
        ? [resourceStatus.autoRequestFilter?.eqFilter?.id]
        : [])
    explicitIds.forEach((id) => {
      if (!resource[id as keyof typeof resource]) {
        return false
      }
    })
    // If none of the above, hopefully our cache status is good
    return true
  }
)
