import { AppDispatch, RootState } from '../redux/store'
import { ResourceEvent } from '../datastore/types'
import { requestDocs } from '../docstore/requestDocs'
import { getDoc } from '../docstore'
import { useTask } from '../hooks/useTask'
import { DocData } from './types'
import log from 'loglevel'

export const useDocument = (docId?: number | null) => {

    const taskResult = useTask(
        async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

            const requestDocResult = await requestDocs({ inFilter: { id: [docId] } })(dispatch, getState, services)

            if (requestDocResult !== ResourceEvent.RESOURCE_VALID) {
                log.debug(`useDocument request document ${docId} failed`)
            }
            const docResult = await getDoc(docId || 0)(dispatch, getState)
            if (docResult.isError) {
                return { isSuccess: false }
            }
            return {
                isSuccess: true,
                data: { docBlob: docResult.docBlob, docExt: docResult.docExt }
            }
        }, [docId], !!docId
    )
    return {
        docData: taskResult.data as DocData,
        isLoading: taskResult.isLoading,
        isSuccess: taskResult.isSuccess,
        isError: taskResult.isError,
    }
}