import { AppDispatch, RootState } from "../../redux/store"
import { useTask } from "../../hooks/useTask"
import { DocData } from "../../docstore/types"
import { parseTemplate } from "./parseTemplate"
import log from "loglevel"
import { getReportData } from "./getReportData"

export const useDocumentContent = (
  docData: DocData | undefined | null,
  selectorParams: Record<string, any>
) => {
  const taskResult = useTask(
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      if (!docData?.docBlob) {
        log.warn("useDocumentContent called with no docData")
        return { isSuccess: false }
      }

      const docHtml = await docData.docBlob.text()

      const {
        interactive,
        incompleteMessage,
        title,
        styleNode,
        selectorNames,
        head,
        body
      } = parseTemplate(docHtml)

      const reportData = await getReportData(
        dispatch,
        getState,
        services,
        selectorNames || [],
        selectorParams
      )

      return {
        isSuccess: true,
        data: {
          docHtml,
          interactive,
          incompleteMessage,
          title,
          styleNode,
          head,
          body,
          reportData
        }
      }
    },
    [docData],
    !!docData
  )
  return {
    docHtml: taskResult.data?.docHtml || null,
    interactive: taskResult.data?.interactive || false,
    incompleteMessage: taskResult.data?.incompleteMessage || null,
    title: taskResult.data?.title || "",
    styleNode: taskResult.data?.styleNode || null,
    head: taskResult.data?.head || null,
    body: taskResult.data?.body || null,
    reportData: taskResult.data?.reportData || null,
    isLoading: taskResult.isLoading,
    isSuccess: taskResult.isSuccess,
    isError: taskResult.isError
  }
}
