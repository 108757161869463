import { AppDispatch, RootState } from '../../../redux/store'
import { requestResources } from '../../../datastore'
import { selectRequestStatus } from '../../../redux/selectors/resourceMeta'
import { ApiResult, ApiService } from '../../../interfaces/api'
import { setRequestComplete, setRequestStart } from '../../../redux/slices/resourceMeta'
import { ResourceEvent } from '../../../datastore/types'
import { getLogger } from 'loglevel'

const log = getLogger('signin')

export const requestSiteBySigninCode = (signinCode: string) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const api: ApiService = services.api

    const user = getState().app.user

    const requestKey = 'siteBySigninCode'

    dispatch(setRequestStart({ requestKey }))

    log.debug('requestSiteBySigninCode: requesting site token')

    // If no user currently logged in, request a site access token so we can get site signin details
    if (!user) {
        const result = await api.getSiteToken(signinCode)
        switch (result) {
            case ApiResult.SUCCESS:
                // If success, good to continue
                break

            case ApiResult.NETWORK_ERROR:

                log.debug('requestSiteBySigninCode: network error requesting site token')

                dispatch(setRequestComplete({ requestKey, resourceEvent: ResourceEvent.RESOURCE_LOAD_ERROR }))
                return result

            default:

                log.debug('requestSiteBySigninCode: failed to get site token')

                dispatch(setRequestComplete({ requestKey, resourceEvent: ResourceEvent.NO_RESOURCE_FOUND }))
                return result
        }
    };

    return await (requestResources('sites', {
        requestKey,
        eqFilter: { signinCode },
        include: ['company']
    }))(dispatch, getState, services)
}

export const requestSiteBySigninCodeResult = (state: RootState) => {
    return selectRequestStatus(state, { requestKey: 'siteBySigninCode' })
}