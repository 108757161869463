import { useAppSelector, useAppDispatch } from "../../redux/hooks"

import {
  addAvailableCustomHazard,
  addTaHazard,
  deleteAvailableCustomHazard,
  deleteTaHazard,
  setTaSelectedHazard,
  setTaStepEditState
} from "../../redux/slices/pgTaskAnalysis"
import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonItemDivider,
  IonItemOption,
  IonList
} from "@ionic/react"
import { useEffect, useMemo, useRef, useState } from "react"

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import TextArea from "../../components/form/TextArea"
import { selectStepEdit, selectTaEdit } from "../../redux/selectors/tas"
import { genericTextRegex } from "../../helpers/regex"
import { SlidingItem } from "../../components/SlidingItem"
import { selectHazardCategoriesContents } from "../../redux/selectors/taHazardSelection"

import "./SelectHazards.css"
import CategoryLevel from "../../components/CategoryLevel"
import { getRiskScoringComplete } from "../../features/taskAnalysis/helpers/getRiskScoringComplete"
import { setAlert } from "../../utils/alert"
import TaskHeading from "./TaskHeading"
import { useHomeIconHandler } from "../../hooks/homeIconHandler"
import { homeIconAction } from "../../features/taskAnalysis/homeIconAction"
import {
  TaEvent,
  updateSequence
} from "../../features/taskAnalysis/taskAnalysisSequence"
import { get } from "lodash"
import { arrowForward } from "ionicons/icons"
import { saveTa } from "../../features/taskAnalysis/saveTa"
import { TaStepState } from "../../datastore/models/Ta"
import ASCheckbox from "../../components/ASCheckbox"

type Inputs = {
  hazardText: string
}

const schema = yup
  .object()
  .shape({
    hazardText: yup
      .string()
      .matches(genericTextRegex, {
        message: "Invalid characters",
        excludeEmptyString: true
      })
      .max(250, "Too many characters")
  })
  .required()

export const SelectHazards: React.FC = () => {
  const dispatch = useAppDispatch()
  const {
    selectedStepIndex,
    selectedHazardCategories,
    availableCustomHazards,
    savedAsDraft
  } = useAppSelector((state) => state.pgTaskAnalysis)
  const taEdit = useAppSelector(selectTaEdit)
  const stepEdit = useAppSelector(selectStepEdit)

  const hazardCategories = useAppSelector(
    selectHazardCategoriesContents(selectedHazardCategories)
  )

  // Array of hazardTypeIds of hazards with risk scoring complete
  const [completedHazards, setCompletedHazards] = useState<number[]>([])

  // Array of custom hazards with risk scoring complete
  const [completedCustomHazards, setCompletedCustomHazards] = useState<
    string[]
  >([])

  useEffect(() => {
    setCompletedHazards(
      stepEdit?.hazards.reduce((completedHazards: number[], hazard) => {
        if (hazard.hazardTypeId && getRiskScoringComplete(hazard)) {
          return completedHazards.concat([hazard.hazardTypeId])
        } else {
          return completedHazards
        }
      }, []) || []
    )

    setCompletedCustomHazards(
      stepEdit?.hazards.reduce((completedHazards: string[], hazard) => {
        if (!hazard.hazardTypeId && getRiskScoringComplete(hazard)) {
          return completedHazards.concat([hazard.customHazardType])
        } else {
          return completedHazards
        }
      }, []) || []
    )
  }, [stepEdit?.hazards])

  const setHazard = (
    hazardTypeId: number | null,
    customHazardType: string,
    useHazard: boolean
  ) => {
    if (useHazard) {
      dispatch(addTaHazard({ hazardTypeId, customHazardType }))
    } else {
      const hazardIndex =
        stepEdit?.hazards.findIndex(
          (hazard) =>
            (hazardTypeId && hazard.hazardTypeId === hazardTypeId) ||
            (customHazardType && hazard.customHazardType === customHazardType)
        ) ?? -1
      const hazard = stepEdit?.hazards[hazardIndex]

      if (hazard) {
        if (getRiskScoringComplete(hazard)) {
          dispatch(
            setAlert(
              "Remove Hazard?",
              `You've already assigned controls for this hazard, do you want to:`,
              [
                {
                  text: "Cancel",
                  role: "cancel",
                  handler: () => true
                },
                {
                  text: "Review Controls",
                  handler: () => {
                    dispatch(setTaSelectedHazard(hazardIndex))
                    dispatch(updateSequence(TaEvent.EDIT_HAZARD_SELECTED))
                    return true
                  }
                },
                {
                  text: "Remove Hazard",
                  handler: () => {
                    dispatch(deleteTaHazard({ hazardTypeId, customHazardType }))
                    return true
                  }
                }
              ]
            )
          )
        } else {
          dispatch(deleteTaHazard({ hazardTypeId, customHazardType }))
        }
      }
    }
  }
  /*
    const toggleCustomHazard = (customHazardType: string) => {
        const useHazard = !(stepEdit?.hazards.find(hazard => (customHazardType && hazard.customHazardType === customHazardType)))
        setHazard(null, customHazardType, useHazard)
    }
*/
  const listRef = useRef<HTMLIonListElement>(null)

  const deleteCustomHazard = (hazardText: string) => {
    listRef?.current?.closeSlidingItems()
    dispatch(deleteAvailableCustomHazard(hazardText))
    dispatch(
      deleteTaHazard({ hazardTypeId: null, customHazardType: hazardText })
    )
  }

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      hazardText: ""
    }
  })

  const newCustomHazardText = watch("hazardText")

  /**
   * Form submit adds a new custom hazard to the list available, and selects it
   */
  const formSubmit = (data: any) => {
    dispatch(addAvailableCustomHazard(data.hazardText))
    setHazard(null, data.hazardText, true)
    setValue("hazardText", "")
  }

  const nextSelected = () => {
    if (stepEdit?.hazards.length === 0) {
      dispatch(
        setAlert(
          "No hazards selected",
          "Are you sure there are no hazards present in this job step?",
          [
            {
              text: "Cancel",
              role: "cancel",
              handler: () => true
            },
            {
              text: "I'm sure",
              handler: () => {
                dispatch(setTaStepEditState(TaStepState.COMPLETE))
                dispatch(updateSequence(TaEvent.NEXT_SELECTED))
                return true
              }
            }
          ]
        )
      )
    } else {
      if (nextHazardIndex !== null) {
        dispatch(setTaSelectedHazard(nextHazardIndex))
        dispatch(updateSequence(TaEvent.EDIT_HAZARD_SELECTED))
      } else {
        dispatch(setTaStepEditState(TaStepState.COMPLETE))
        dispatch(updateSequence(TaEvent.NEXT_SELECTED))
      }
    }
  }

  /**
   * Determine which hazard will be next for risk scoring / mitigation
   *
   * Search in order of available selections rather than stepEdit.hazards so that the user
   * will see hazards being mitigated from top to bottom (rather than in the order they selected them)
   */
  const nextHazardIndex = useMemo(() => {
    if (!stepEdit) {
      return null
    }

    // For each hazard category in list...
    for (const category of hazardCategories) {
      // For each hazard in category...
      for (const hazardType of category.contents.items) {
        // ... check if hazard has been selected
        const i = stepEdit.hazards.findIndex(
          (hazardSelection) => hazardSelection.hazardTypeId === hazardType.id
        )
        // If it has, and is either not risk-scored/mitigated or is flagged for review, then it's the one
        // we're scoring next
        if (
          i > -1 &&
          (!getRiskScoringComplete(stepEdit.hazards[i]) ||
            stepEdit.hazards[i].needsReview)
        ) {
          return i
        }
      }
    }

    // If all standard hazard types done, check custom hazards
    for (const customHazard of availableCustomHazards) {
      const i = stepEdit.hazards.findIndex(
        (hazardSelection) => hazardSelection.customHazardType === customHazard
      )
      if (
        i > -1 &&
        (!getRiskScoringComplete(stepEdit.hazards[i]) ||
          stepEdit.hazards[i].needsReview)
      ) {
        return i
      }
    }

    return null
  }, [stepEdit])

  const saveAsDraft = () => {
    dispatch(saveTa())
    dispatch(updateSequence(TaEvent.START_SELECTED))
  }

  useHomeIconHandler("/task-analysis", () => dispatch(homeIconAction()))

  const nextHazard =
    nextHazardIndex !== null
      ? get(stepEdit, ["hazards", nextHazardIndex])
      : null
  const arrowHazardType = nextHazard ? nextHazard.hazardTypeId : null
  const arrowCustomHazard = nextHazard ? nextHazard.customHazardType : null

  return (
    <>
      <IonContent className="content-padding">
        <TaskHeading stepNumber={true} />
        <IonItemDivider />
        <h3 id="step-name">{`${selectedStepIndex + 1}. ${stepEdit?.text}`}</h3>
        <IonItemDivider />
        <h2 id="hazard-question">
          Which of these Hazards (if any) could be present in this Task Step?
        </h2>

        <div className="tree-grid">
          {hazardCategories.map((category) => (
            <CategoryLevel
              key={category.id}
              categoryText={category.categoryText}
              categoryContents={category.contents}
              indent={1}
              selectedIds={
                stepEdit?.hazards.map((hazard) => hazard.hazardTypeId) || []
              }
              greenIds={completedHazards}
              arrowId={arrowHazardType}
              selectFn={(hazardTypeId: number, selected: boolean) =>
                setHazard(hazardTypeId, "", selected)
              }
            />
          ))}
        </div>

        {hazardCategories.length && availableCustomHazards.length ? (
          <IonItemDivider />
        ) : (
          <></>
        )}

        <IonList ref={listRef} id="custom-hazards-list" className="hazard-list">
          {availableCustomHazards.map((hazardText, i) => (
            <SlidingItem
              key={i}
              lines="none"
              itemContent={
                <>
                  <div className="tree-grid">
                    {hazardText === arrowCustomHazard ? (
                      <IonIcon
                        className={`col-1-arrow`}
                        icon={arrowForward}
                        size="small"
                      />
                    ) : (
                      <></>
                    )}
                    <ASCheckbox
                      containerClass={`col-2-cb`}
                      color={completedCustomHazards.includes(hazardText) ? "green" : "grey"}
                      checked={!!stepEdit?.hazards.find(hazard => hazard.customHazardType === hazardText)}
                      onClick={(state) => setHazard(null, hazardText, state)}
                      label={hazardText}
                    />
                  </div>
                </>
              }
              optionsContent={
                <IonItemOption
                  color="danger"
                  onClick={() => deleteCustomHazard(hazardText)}
                >
                  Delete
                </IonItemOption>
              }
            />
          ))}
        </IonList>

        <form onSubmit={handleSubmit(formSubmit)} id="custom-hazard-entry">
          <h2>Add a custom Hazard:</h2>
          <div>
            <TextArea
              fieldName="hazardText"
              inputMode="text"
              rows={1}
              watch={watch}
              setValue={setValue}
              errors={errors}
            />
            <IonButton
              type="submit"
              disabled={
                newCustomHazardText === "" ||
                availableCustomHazards.includes(newCustomHazardText)
              }
            >
              ADD
            </IonButton>
          </div>
        </form>
      </IonContent>
      <IonFooter className="TA-nav-footer">
        <IonButton
          onClick={() => dispatch(updateSequence(TaEvent.BACK_SELECTED))}
        >
          BACK
        </IonButton>
        <IonButton
          disabled={!taEdit.draft || savedAsDraft}
          onClick={saveAsDraft}
        >
          Save Draft
        </IonButton>
        <IonButton onClick={nextSelected}>NEXT</IonButton>
      </IonFooter>
    </>
  )
}
