import { AppDispatch, RootState } from '../../../redux/store'
import { requestResources } from '../../../datastore'
import { selectRequestStatus } from '../../../redux/selectors/resourceMeta'

export const requestInductions = (params: { [key: string]: any }) => (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    const { userId, siteId } = params

    return (requestResources('inductions', {
        requestKey: 'inductionsOnDemand',
        eqFilter: { user: userId, site: siteId },
        periodFilter: { attr: 'inductionDate', periodStart: params.periodStart, periodEnd: params.periodEnd }
    }))(dispatch, getState, services)
}

export const requestInductionsResult = (state: RootState) => {
    return selectRequestStatus(state, { requestKey: 'inductionsOnDemand' })
}