import { App as CapacitorApp } from '@capacitor/app';
import { isPlatform } from '@ionic/react';

interface ASUrl {
    protocol: string
    host: string
    pathname: string
    href: string
    search: string
}

/**
 * Get url app was either initially launched with or opened with via a universal/custom url scheme url
 * 
 * @param newUrl if called from 'open' event, set to url received with event
 * @returns 'ÁSUrl' object - same fields as js URL but maintains host / pathname split which URL won't do for custom URL scheme
 */
export const getAppOpenUrl = async (newUrl?: string): Promise<ASUrl> => {

    let url = newUrl ? new URL(newUrl) : new URL(window.location.href)

    if (isPlatform("capacitor")) {
        if (!newUrl) {
            const launchUrl = await CapacitorApp.getLaunchUrl()
            if (launchUrl) {
                url = new URL(launchUrl.url)
            }
        }

        // Chrome / Android implementation of URL object will only separate 'host' and 'pathname' for url with standard protocol (http:, https:, etc)
        // => If there's no url.host then this is a custom url, so split host and pathname ourselves
        if (!url.host && url.pathname.startsWith('//')) {
            const host = url.pathname.slice(2, url.pathname.indexOf('/', 2))
            return {
                protocol: url.protocol,
                host,
                pathname: url.pathname.slice(host.length + 2),
                href: url.href,
                search: url.search
            }

        }
    }

    return {
        protocol: url.protocol,
        host: url.host,
        pathname: url.pathname,
        href: url.href,
        search: url.search
    }
}