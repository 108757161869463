const tas = {
  table: 'tas',
  idKey: 'id',

  attrs: [
    'startDate',
    'draft',
    'userData',
    'revision',
    'superseded',
    'creationDate',
    'lastModifiedDate',  
    'deleted'
  ],

  relationships: [ 
    {
      type: 'companies',
      key: 'company',
    },  
    {
      type: 'projects',
      key: 'project',
    },
    {
      type: 'sites',
      key: 'site',
    },
    {
      type: 'tasks',
      key: 'scheduledTask',
    },
    {
      type: 'users',
      key: 'creator',
    },
     {
      type: 'tas',
      key: 'supersedes',
    }
  ],
}

export default tas