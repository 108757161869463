import log from "loglevel"
import { AppDispatch, RootState } from "../../../redux/store"
import { selectCompanies } from '../../../redux/selectors/companies'
import { selectOpenSites } from '../../../redux/selectors/sites'
import { requestResources, requestResourcesWithRelated } from '../../../datastore'
import { ResourceEvent } from '../../../datastore/types'
import { resourceEventCombined } from '../../../datastore/resourceEventCombined'
import { useTask } from "../../../hooks/useTask"
import { Company, Site } from "../../../datastore/models"

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results 
 * 
 * @returns  requested resources 
 */

export const useDocumentsResources = (pageActive: boolean) => {
    const taskResult = useTask(
        async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

            const results = await Promise.all([
                await requestResources('companies', {})(dispatch, getState, services),
                await requestResourcesWithRelated('projects', {
                    eqFilter: { status: 'OPEN' }
                }, ['projectSites.sites'])(dispatch, getState, services)
            ])

            if (resourceEventCombined(ResourceEvent.RESOURCE_VALID, results) === ResourceEvent.RESOURCE_LOAD_ERROR) {
                log.debug('useDocumentsResources request error')
                return { isSuccess: false }
            }

            const state = getState()
            return {
                isSuccess: true,
                data: {
                    companies: selectCompanies(state, {}),
                    sites: selectOpenSites(state, {}),
                }
            }
        }, [], pageActive)

    return {
        companies: taskResult.data?.companies as Company[],
        sites: taskResult.data?.sites as Site[],
        isLoading: taskResult.isLoading,
        isSuccess: taskResult.isSuccess,
        isError: taskResult.isError,
    }
}