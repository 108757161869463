import { createSelector } from '@reduxjs/toolkit'
import { Control, HazardType } from '../../datastore/models'
import type { RootState } from '../../redux/store'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { selectStepEdit } from './tas'
import { getResource } from '../../datastore'
import HazardTypeControl from '../../datastore/models/HazardTypeControl'

// 'Select Controls' uses:
// - Select a specific control by ID to display it's text
// - Select a subset of controls by id corresponding to a hazard type (for hazard / control selection screen)

const selectAllControls = (state: RootState) => state.resources.controls

const selectAllHazardTypeControls = (state: RootState) => state.resources.hazardTypeControls
const selectControlsByHazardType = createSelector([selectAllHazardTypeControls], (allHazardTypeControls) => {
    if (allHazardTypeControls) {
        let controlsByHazardType: { [key: string]: number[] } = {}
        for (const id in allHazardTypeControls) {
            const hazardTypeControl = getResource<HazardTypeControl>(allHazardTypeControls, +id)
            if (hazardTypeControl) {
                if (controlsByHazardType[hazardTypeControl.hazardType]) {
                    controlsByHazardType[hazardTypeControl.hazardType].push(hazardTypeControl.control)
                } else {
                    controlsByHazardType[hazardTypeControl.hazardType] = [hazardTypeControl.control]
                }
            }
        }
        return controlsByHazardType
    }
    return {}
})

const selectAllHazardTypes = (state: RootState) => state.resources.hazardTypes
export const selectCurrentStepHazards = createSelector([
    selectAllHazardTypes,
    selectAllControls,
    selectStepEdit],
    (allHazardTypes, allControls, stepEdit) => {

        if (!allHazardTypes || !allControls) {
            // No hazard data available...
            return []
        }

        return stepEdit?.hazards.map(hazard => {
            // set defaults to return if no hazard type has been selected yet (or hazard data is unavailable / not loaded)
            let category = 0
            let hazardText = 'No hazard type selected'
            let detail = ''
            let controls: string[] = []
            if (hazard.hazardTypeId === null) {
                // No hazardTypeId - could be a custom hazard
                if (hazard.customHazardType) {
                    hazardText = hazard.customHazardType
                }
            } else {
                // Hazard type has been selected...
                const hazardType = allHazardTypes[hazard.hazardTypeId]
                if (hazardType) {
                    hazardText = hazardType.attributes.hazardText
                    detail = hazardType.attributes.detail
                }
            }

            // Get control texts from ids, with check that control data is available
            controls = hazard.controls.reduce((acc: string[], currentId) => {
                const control = allControls[currentId]
                if (control) {
                    acc.push(control.attributes.controlText)
                }
                return acc
            }, [])
                .concat(hazard.customControls)

            return {
                category,
                hazardText,
                detail,
                irConsequence: hazard.irConsequence,
                irLikelyhood: hazard.irLikelyhood,
                rrConsequence: hazard.rrConsequence,
                rrLikelyhood: hazard.rrLikelyhood,
                controls
            }
        }) || []
    }
)

const selectHazardCategoryParams = selectParamsMemo()
/**
 * Get the category a given hazard type belongs to
 */
export const selectHazardCategory = createSelector([selectAllHazardTypes, selectHazardCategoryParams], (allHazardTypes, params) => {

    const hazardType = getResource<HazardType>(allHazardTypes, params.hazardTypeId)
    if (hazardType) {
        return hazardType.category || null
    }
    return null
})


const selectHazardTypeParams = selectParamsMemo()
/**
 * Given a hazardTypeId, get the hazardType and it's possible controls
 */
export const selectHazardType = createSelector([selectAllHazardTypes, selectAllControls, selectControlsByHazardType, selectHazardTypeParams],
    (allHazardTypes, allControls, controlsByHazardType, params) => {
        const hazardTypeId = params.hazardTypeId

        if (hazardTypeId && allHazardTypes && allControls && controlsByHazardType) {
            const hazardType = allHazardTypes[hazardTypeId]
            if (hazardType) {
                const controlIds = controlsByHazardType[hazardTypeId]
                const controls = controlIds ? controlIds.reduce((acc, id) => {
                    const control = allControls[id]
                    if (control) {
                        acc.push({
                            id: id,
                            hierarchy: null,
                            controlText: control.attributes.controlText,
                            controlTextShort:control.attributes.controlTextShort || control.attributes.controlText,
                            detail: control.attributes.detail
                        })
                    }
                    return acc
                }, [] as Control[]) : []

                return {
                    hazardType: hazardTypeId,
                    hazardText: allHazardTypes[hazardTypeId].attributes.hazardText,
                    hazardTextShort: allHazardTypes[hazardTypeId].attributes.hazardTextShort || allHazardTypes[hazardTypeId].attributes.hazardText,
                    detail: allHazardTypes[hazardTypeId].attributes.detail,
                    controls: controls
                }
            }
        }
        return null
    })