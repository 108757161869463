import log from "loglevel"
import { ResourceEvent } from "./types"

/**
 * Simple 'subscription' mechanism to make result of an in-progress request available as a promise
 * Used when duplicate requests occur so that subsequent requests can await the result of the initial
 * request and avoid redundant requests to backend
 */

interface RequestSubscription {
  requestKey: string
  callback: (result: ResourceEvent) => void
  complete: boolean
}

var subscriptions: RequestSubscription[] = []

/**
 * Given the request key of a pending request, returns a promise that will resolve to the request result
 */
export const pendingRequestResult = async (
  requestKey: string
): Promise<ResourceEvent> => {    
  return new Promise((resolve, reject) => {
    const subscription = {
      requestKey,
      callback: (result: ResourceEvent) => resolve(result),
      complete: false
    }
    subscriptions.push(subscription)

    setTimeout(() => {
      if (!subscription.complete) {
        log.error(`Request subscription timed out, requestKey:${requestKey}`)
        subscription.callback(ResourceEvent.RESOURCE_LOAD_ERROR)
        subscriptions = subscriptions.filter(sub => sub !== subscription)
      }
    }, 30000)

  })
}

/**
 * Called by setRequestComplete reducer on completion of a request
 */
export const updateRequestSubscriptions = (
  requestKey: string,
  result: ResourceEvent
) => {

  const reqSubscriptions = subscriptions.filter(
    (sub) => sub.requestKey === requestKey
  )
  reqSubscriptions.forEach((sub) => {    
    sub.callback(result)
    sub.complete = true
    subscriptions = subscriptions.filter((subscription) => subscription !== sub)
  })
}
