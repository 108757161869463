import jsQR from "jsqr"
import log from "loglevel"
import { MutableRefObject, useEffect, useRef, useState } from "react"

const TICK_INTERVAL = 100

export const useQrScan = (
  canvas: MutableRefObject<HTMLCanvasElement | null>,
  callback: (result: string, scanData: string) => boolean
) => {
  // The following use useRef (rather than useState) because they are used in 'tick' function, which is called by setTimeout
  // (i.e. outside the scope of the hook)
  const componentMounted = useRef(true)
  const video = useRef<HTMLVideoElement>(document.createElement("video"))
  const canvasContext = useRef<CanvasRenderingContext2D>()
  const lastReadyState = useRef<number>()
  const notReadyCount = useRef<number>(0)
  const restartVideo = useRef(false)
  const videoRunning = useRef(false)
  const exitTick = useRef(false)

  // These need to be useState so they will trigger a rerender if changed
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    componentMounted.current = true
    log.debug("useQrScan:component mounted")
    setTimeout(tick, 10)

    // Reset 'component mounted' on unmount
    return () => {
      log.debug("useQrScan:component unmounted")
      stopVideo()
      componentMounted.current = false
    }
  }, [])

  const startVideo = async () => {
    log.debug("QRScanner: entering startVideo")
    notReadyCount.current = 0
    if (navigator.mediaDevices) {
      try {
        // If we don't have camera permission, this line will block until user accepts 
        // permission prompt. It will throw an error if camera is unavailable or permission isn't granted
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "environment" }
        })
        video.current.srcObject = stream
        video.current.setAttribute("playsinline", "true") // required to tell iOS safari we don't want fullscreen
        await video.current.play()
        videoRunning.current = true
        log.debug("QRScanner: startVideo complete")
      } catch (error) {
        videoRunning.current = false
        const errorName = (error as DOMException)?.name

        if (errorName === "NotAllowedError") {
          callback("No permission to access camera", "")
          setIsLoading(false)
          setIsError(true)
          // Unrecoverable - exit tick loop
          exitTick.current = true
        } else if (errorName === "NotFoundError") {
          callback("Cannot access media devices", "")
          setIsLoading(false)
          setIsError(true)
          // Unrecoverable - exit tick loop
          exitTick.current = true
        } else {
          // Could be a transient error - allow to continue and retry
          log.debug("QRScanner: startVideo threw error:", error)
        }
      }
    } else {
      // navigator.mediaDevices unavailable, e.g. due to running over insecure connection
      log.debug("QRScanner: startVideo cannot access media devices")
      callback("Cannot access media devices", "")
      setIsLoading(false)
      setIsError(true)
      // Unrecoverable - exit tick loop
      exitTick.current = true
    }
  }

  const stopVideo = () => {
    const stream = video.current.srcObject as MediaStream
    if (stream) {
      stream.getTracks().forEach(function (track) {
        if (track.readyState === "live") {
          track.stop()
        }
      })
      video.current.srcObject = null
    }
    videoRunning.current = false
  }

  const tick = async () => {
    if (!componentMounted.current) {
      log.debug("useQrScan: tick exited")
      return
    }

    if (!canvas.current) {
      log.debug("useQrScan: tick didn't find canvas")
      return
    }

    if (exitTick.current) {
      return
    }

    if (!canvasContext.current) {
      canvasContext.current = canvas.current.getContext("2d", {
        willReadFrequently: true
      }) as CanvasRenderingContext2D
    }

    if (restartVideo.current) {
      stopVideo()
      restartVideo.current = false
    }

    if (!videoRunning.current) {
      await startVideo()
      setTimeout(tick, TICK_INTERVAL)
      return
    }

    try {
      // video readyState will have values:
      // - video.current.HAVE_ENOUGH_DATA when video stream is working normally
      // - video.current.HAVE_NOTHING when starting up OR if video has stopped for some reason (e.g. app being minimised)
      if (video.current.readyState === video.current.HAVE_ENOUGH_DATA) {
        setIsLoading(false)
        notReadyCount.current = 0

        canvas.current.height = video.current.videoHeight
        canvas.current.width = video.current.videoWidth
        canvasContext.current.drawImage(
          video.current,
          0,
          0,
          canvas.current.width,
          canvas.current.height
        )
        const imageData = canvasContext.current.getImageData(
          0,
          0,
          canvas.current.width,
          canvas.current.height
        )
        let code
        try {
          code = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: "dontInvert"
          })
        } catch (e) {
          // jsQR throws a 'range error' now and then - ignore and keep scanning
          // log.error('Exception thrown calling jsQR:', e)
        }
        if (code) {
          callback("Ok", code.data)
        }
      } else {
        notReadyCount.current++

        // If video readyState was previously HAVE_ENOUGH_DATA but isn't now, we've lost the video feed - can be due to
        // losing camera permission as a result of app being minimised and re-opened
        // -> attempt to restart
        if (lastReadyState.current === video.current.HAVE_ENOUGH_DATA) {
          log.debug(
            "useQRScan: video.readyState no longer HAVE_ENOUGH_DATA - restarting"
          )
          restartVideo.current = true
        }

        // If for some reason we don't see readyState HAVE_ENOUGH_DATA approx. 3 seconds after mounting,
        // try a restart
        if (notReadyCount.current * TICK_INTERVAL > 3000) {
          log.warn(
            "useQRScan: timed out waiting for video.readyState HAVE_ENOUGH_DATA - restarting"
          )
          restartVideo.current = true
        }
      }

      lastReadyState.current = video.current.readyState

      setTimeout(tick, TICK_INTERVAL)
    } catch (e) {
      log.error("Exception thrown in QRScanner tick function:", e)
    }
  }

  return { isLoading, isError }
}
