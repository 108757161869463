import { AppDispatch, RootState } from "../../redux/store"
import { requestResources } from "../../datastore"
import { resourceEventCombined } from "../../datastore/resourceEventCombined"
import { setRequestComplete, setRequestStart } from "../../redux/slices/resourceMeta"
import { isToday, parseJSON, sub } from "date-fns"
import { selectSignedinUsers } from "../../redux/selectors/signins"

export const requestPersonnelOnSite = (params: { [key: string]: any }) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const { siteId, requestKey } = params

    if (requestKey) {
        dispatch(setRequestStart({ requestKey }))
    }

    const results = await Promise.all([
        requestResources('signins', {
            eqFilter: { site: siteId },
            periodFilter: { attr: 'signinTime', periodStart: sub(new Date(), { days: 2 }) },
            include: ['user']
        })(dispatch, getState, services)
    ])
    const requestResult = resourceEventCombined(results[0], results.slice(1))
    if (requestKey) {
        dispatch(setRequestComplete({ requestKey, resourceEvent: requestResult }))
    }
    return requestResult
}

export const getPersonnelOnSite = (params: { [key: string]: any }) => (dispatch: AppDispatch, getState: () => RootState) => {
    const siteId = params.siteId
    const state = getState()

    const signedInUsers = selectSignedinUsers(state, { siteId }).map(user => (
        {
            ...user,
            signedInToday: isToday(parseJSON(user.signinTime))
        }
    ))

    const reportData = {        
        signedInUsers
    }
    return reportData
}