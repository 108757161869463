import { createSelector } from "@reduxjs/toolkit"
import { isAfter, parseJSON } from "date-fns"
import { getResource } from "../../datastore"
import { Induction } from "../../datastore/models"
import { RootState } from "../store"
import { selectParamsMemo } from "./helpers/selectParamsMemo"

const selectAllInductions = (state: RootState) => state.resources.inductions
const selectLatestInductionParams = selectParamsMemo()
/**
 * Gets most recent induction for a given site, user, and type
  * 
 * @param state 
 * @returns Induction
 */
export const selectLatestInduction = createSelector([selectAllInductions, selectLatestInductionParams], (allInductions, params) => {

    const siteId = params.siteId || null
    const userId = params.userId || null
    const inductionType = params.inductionType || null

    if (allInductions) {
        const latestInduction = Object.keys(allInductions).reduce((latestInduction: Induction | null, currentId: string) => {

            const testInduction = getResource<Induction>(allInductions, +currentId)
            if (testInduction &&
                (siteId === null || +testInduction!.site === siteId) &&
                (userId === null || +testInduction!.user === userId) &&
                (inductionType === null || testInduction!.inductionType === inductionType) &&
                (!latestInduction || isAfter(parseJSON(testInduction.inductionDate), parseJSON(latestInduction.inductionDate)))
            ) {
                return testInduction
            }
            return latestInduction

        }, null)
        return latestInduction

    }
    return null
})