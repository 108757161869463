import { AppDispatch, RootState } from "../../redux/store"
import { requestResources } from "../../datastore"
import {
  setRequestComplete,
  setRequestStart
} from "../../redux/slices/resourceMeta"
import { selectSitePlans } from "../../redux/selectors/signinDocs"

export const requestSitePlans =
  (params: { [key: string]: any }) =>
  async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const { siteId, requestKey } = params

    if (requestKey) {
      dispatch(setRequestStart({ requestKey }))
    }

    const result = await requestResources("signinDocs", {
      eqFilter: { site: siteId, docType: "SitePlan" },
      include:['docInfo']
    })(dispatch, getState, services)

    if (requestKey) {
      dispatch(setRequestComplete({ requestKey, resourceEvent: result }))
    }
    return result
  }

export const getSitePlans =
  (params: { [key: string]: any }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()

    const signinDocs = selectSitePlans(state, params)

    return {
      sitePlans: {
        docs: signinDocs.sort((a, b) => a.order - b.order),
        images: signinDocs.map((doc) => ({ savedId: doc.docInfo }))
      }
    }
  }
