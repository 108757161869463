/**
 * 'Resource Checklist' helper functions
 * 
 * Used when editing toolbox, to set up links between toolbox and other resources (tas, site observation records, etc)
 * that the toolbox is referencing
 * 
 * When editing a toolbox meeting, user is presented with checkbox lists of items that might be discussed, and checks the 
 * ones to be recorded against the meeting. The resulting selections are saved as rows in bridge tables (tb_tas, tb_observations, etc)
 * when the form is submitted.
 * 
 * - On loading form, both the 'preEdit' and 'checked' arrays are populated with existing rows from the bridge table
 * - The list of available resources is compared against the 'checked' array to determine which items are displayed as checked
 * - When a new item is checked, it's id and localId is added to the 'checked' array (or removed if it is unchecked)
 * - When the form is submitted, the 'preEdit' and 'checked' arrays are compared to determine which rows need to be added to
 *   or deleted from the bridging table
 * 
 * The checked array requires both 'id' and 'localId' because a newly created resource could be written to the back end while
 * the page is being edited, i.e. the preEdit array may contain the localId while the corresponding 'checked' entry uses
 * the 'real' id -> need to be able to determine that they refer to the same item
 */

interface CheckedResource {
    id: number
    localId?: number
}

/**
 *  Interface for checklist structure
 *  (instances will be contained in redux store)
 */
export interface ResourceChecklist {
    preEdit: number[]
    checked: CheckedResource[]
}

/**
 * Determine whether a given resource is currently 'checked'
 * (used to drive checkbox state)
 */
export const inChecklist = (checklist: ResourceChecklist, resource: { id?: number, localId?: number }) => (
    !!checklist.checked.find(item => item.id === resource.id || item.id === resource.localId)
)

/**
 * Add or remove a given resource from the 'checked' list 
 * (will be called from redux reducers)
 */
export const setChecklistItem = (checkedList: CheckedResource[], resource: { id?: number, localId?: number }, state: boolean) => {
    if (state && !checkedList.find(item => item.id === resource.id || item.id === resource.localId) && resource.id) {
        return checkedList.concat({ id: resource.id, localId: resource.localId })
    }

    if (!state && checkedList.find(item => item.id === resource.id || item.id === resource.localId)) {
        return checkedList.filter(item => item.id !== resource.id && item.id !== resource.localId)
    }

    return checkedList
}

/**
 * Get items that have been newly 'checked' (i.e. resources to add to bridging table) 
 */
export const checklistItemsToAdd = (checklist: ResourceChecklist) => (
    checklist.checked.filter(item => !checklist.preEdit.includes(item.id) && !((item.localId && checklist.preEdit.includes(item.localId))))
        .map(item => item.id)
)

/**
 * Get items that have been 'unchecked' (i.e. resources to delete from bridging table) 
 */
export const checklistItemsToDelete = (checklist: ResourceChecklist) => (
    checklist.preEdit.filter(id => !checklist.checked.find(item => item.id === id || item.localId === id))
)