import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { AppDispatch, RootState } from '../redux/store'
import { useAppDispatch } from '../redux/hooks'

interface TaskFnResult {
    isSuccess: boolean
    data?: any
}

interface TaskResult extends TaskFnResult {
    isLoading: boolean
    isError: boolean
}

type TaskFn = (dispatch: AppDispatch, getState: () => RootState, services: any) => Promise<TaskFnResult>

/**
 * Hook for running an async 'task' and returning result, with 'isLoading / isSuccess / isError' flags
 * 
 * - Call with an async function performing one or more async operations
 * - Runs supplied function in background (via a dispatch) and returns 'isLoading / isError / isSuccess' flags based on it's completion status.
 *   If 'isSuccess' will return whatever 'data' taskFn returns, otherwise 'data' will be null
 * 
 * @param taskFn        - Task function to run - should be thunk returning 'TaskFnResult' (i.e. success status plus data)
 * @param dependencies  - Dependencies array - task function will be called on any changes
 * @param enable        - Optional 'Enabled' condition - if false request function is not called. Also functions as a dependency, i.e. false to true
 *                        transition will trigger request function to run
 * @returns             - { data, isLoading, isError, isSuccess } hash
 */

export const useTask = (
    taskFn: TaskFn,
    dependencies: any[],
    enable: boolean = true

) => {
    const dispatch = useAppDispatch()

    const [taskResult, setTaskResult] = useState<TaskResult>({
        isLoading: false,
        isSuccess: false,
        isError: false,
    })

    const version = useRef<number>(0)

    const isMounted = useRef<boolean>(true)

    // Flag if component is unmounted (i.e. we leave the page)
    // Note need to explicitly set on mount due to react 18 double-calling of useEffect
    // (needs to have empty dependency array to be called only on mount / unmount)
    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])

    // Initiate request(s) on change in dependencies
    useEffect(() => {
        if (enable) {
            setTaskResult({
                isLoading: true,
                isSuccess: false,
                isError: false
            })
            version.current++
            dispatch(runTask(taskFn, isMounted, processResult, version.current))
        }
    }, [...dependencies, enable])

    const processResult = (taskFnResult: TaskFnResult, runVersion: number) => {

        // If task has been re-run since this instance was started, version.current will be greater
        // -> if so just discard the result of this run
        if (runVersion === version.current) {

            setTaskResult({
                isLoading: false,
                isSuccess: taskFnResult.isSuccess,
                isError: !taskFnResult.isSuccess,
                data: taskFnResult.isSuccess ? taskFnResult.data : null
            })
        }
    }
    return taskResult
}

const runTask = (
    taskFn: TaskFn, isMounted: MutableRefObject<boolean>,
    processResult: (result: TaskFnResult, version: number) => void,
    version: number
) => (

    async function (dispatch: AppDispatch, getState: () => RootState, services: any) {

        const taskFnResult = await taskFn(dispatch, getState, services)

        if (isMounted.current) {
            processResult(taskFnResult, version)
        }
    }
)
