import { AppDispatch, RootState } from "../../redux/store"
import { requestResources } from "../../datastore"
import { selectSitePpe } from "../../redux/selectors/sitePpe"
import { setRequestComplete, setRequestStart } from "../../redux/slices/resourceMeta"
import { resourceEventCombined } from "../../datastore/resourceEventCombined"
import { selectPpeTypes } from "../../redux/selectors/ppeTypes"

export const requestSitePpe = (params: { [key: string]: any }) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const { siteId, requestKey } = params

    if (requestKey) {
        dispatch(setRequestStart({ requestKey }))
    }

    const results = await Promise.all([
        requestResources('sitePpe', { eqFilter: { site: siteId } })(dispatch, getState, services),
        requestResources('ppeTypes', {})(dispatch, getState, services)
    ])

    const requestResult = resourceEventCombined(results[0], results.slice(1))
    if (requestKey) {
        dispatch(setRequestComplete({ requestKey, resourceEvent: requestResult}))
    }
    return requestResult
}

export const getSitePpe = (params: { [key: string]: any }) => (dispatch: AppDispatch, getState: () => RootState) => {
    const siteId = params.siteId
    const state = getState()

    const sitePpe = selectSitePpe(state, { siteId })
    const ppeTypes = selectPpeTypes(state)
    
    return {
        ppeSelections: ppeTypes.map(ppeType => {
                const siteSelection = sitePpe.find(item => item.id === ppeType.id)
                return {
                name: ppeType.name,
                detail: siteSelection?.detail || '',
                icon: ppeType.icon,
                isSelected: !!siteSelection
            }
        }),

        customPpe: sitePpe.filter(item => item.customPpe).map(item => ({
            customPpe: item.customPpe,
            detail: item.detail || ''
        }))
    } 
}