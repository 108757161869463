const incidents = {
  table: 'incidents',
  idKey: 'id',

  attrs: [  
    'incidentDate',
    'incidentType',    
    'description',
    'incidentData',
    'notifiedDate',
    'closedDate',
    'creationDate',
    'lastModifiedDate',
    'deleted'
  ],

  relationships: [    
    {
      type: 'companies',
      key: 'company',
    },
    {
      type: 'sites',
      key: 'site',
    },
    {
      type: 'projects',
      key: 'project',
    },
    {
      type: 'users',
      key: 'reporter',
    },
    {
      type: 'users',
      key: 'actionVerifier',
    },
    {
      type: 'doc-info',
      key: 'photo',
    }    
  ],
}

export default incidents