import { useEffect, useRef } from "react"

import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonList
  //     IonRefresher,
  //     IonRefresherContent
} from "@ionic/react"

import { format, parseJSON } from "date-fns"
import { useAppSelector, useAppDispatch } from "../../redux/hooks"

import {
  setToolboxAttendee,
  setToolboxEdit,
  setToolboxMeetingData
} from "../../redux/slices/pgToolbox"

//import { arrowDownCircleOutline } from 'ionicons/icons'

import { setAlert } from "../../utils/alert"
import TextArea from "../../components/form/TextArea"

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { saveToolbox } from "../../features/toolbox/saveToolbox"
import CameraInput, { PhotoData } from "../../components/form/CameraInput"
import PhotoDisplay from "../../components/form/PhotoDisplay"
import { inChecklist } from "../../features/toolbox/resourceChecklist"
import { useRollCallResources } from "../../features/toolbox/resourceRequests/useRollCallResources"
import { updateSequence } from "../../features/toolbox/toolboxSequence"
import { ToolboxEvent } from "../../features/toolbox/toolboxTypes"
import SelectListInput from "../../components/form/SelectListInput"
import log from "loglevel"
import ASCheckbox from "../../components/ASCheckbox"
import { selectProjectSite } from "../../redux/selectors/projectSites"
import { useRequestAvailableProjectSites } from "../../features/toolbox/resourceRequests/useRequestAvailableProjectSites"
import { location } from "ionicons/icons"
import { useRequestCompanies } from "../../features/toolbox/resourceRequests/useRequestAvailableCompanies"
import { uniq } from "lodash"
import { saveTbAttendees } from "../../features/toolbox/saveTbAttendees"

export interface TbRollcallEdit {
  companyId: number
  projectSiteId: number | null
  comments: string
  groupPhoto: PhotoData
}

const schema = yup
  .object()
  .shape({
    projectSiteId: yup.number().required("Please select a project"),
    companyId: yup.number().when("projectSiteId", {
      is: (projectSiteId?: number) => projectSiteId === -1,
      then: yup.number().required("Please select a company")
    }),

    comments: yup.string()
  })
  .required()

export const RollCall: React.FC = () => {
  const dispatch = useAppDispatch()

  const { toolboxEdit, attendeesList, dirty } = useAppSelector(
    (state) => state.pgToolbox
  )
  const { availableProjectSites } = useRequestAvailableProjectSites()
  const { companies } = useRequestCompanies(uniq(availableProjectSites.map(projectSite => projectSite.company)))

  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors, isDirty, dirtyFields }
  } = useForm<TbRollcallEdit>({
    resolver: yupResolver(schema),
    defaultValues: {
      companyId: toolboxEdit.company,
      projectSiteId:
        toolboxEdit.project === -1 // -1 = all company projects
          ? -1
          : availableProjectSites.find(
              (projectSite) =>
                projectSite.projectId === toolboxEdit.project &&
                projectSite.siteId === toolboxEdit.site
            )?.id,
      comments: toolboxEdit.meetingData.rollCallComments,
      groupPhoto: toolboxEdit.groupPhoto
    }
  })

  const selectedCompanyId = watch("companyId")

  const selectedProjectSiteId = watch("projectSiteId")
  const selectedProjectSite = useAppSelector((state) =>
    selectProjectSite(state, { projectSiteId: selectedProjectSiteId })
  )

  const selectedSiteId = selectedProjectSite?.siteId || null

  //  const [refresh, setRefresh] = useState(0)
  const { availableUsers, isLoading } = useRollCallResources(
    selectedSiteId,
    selectedProjectSiteId === -1 ? selectedCompanyId : null /*, refresh*/
  )

  const formSubmitNext = (data: any) => {
    const projectSite = availableProjectSites.find(
      (projectSite) => projectSite.id === data.projectSiteId
    )
    if (projectSite || data.projectSiteId === -1) {
      // -1 = all company projects
      if (isDirty || !toolboxEdit.id) {
        dispatch(
          setToolboxEdit({
            company: data.companyId,
            site: projectSite ? projectSite.siteId : undefined,
            project: projectSite ? projectSite.projectId : -1
          })
        )

        if (dirtyFields?.groupPhoto) {
          dispatch(
            setToolboxEdit({
              groupPhoto: data.groupPhoto
            })
          )
        }
        dispatch(setToolboxMeetingData({ rollCallComments: data.comments }))
        dispatch(saveToolbox)
      }
      if (toolboxEdit.id) {
        // If we have an id (i.e. this is not the first time toolbox has been saved),
        // can save attendees from here (initial call to saveToolbox will also call saveTbAttendees)
        dispatch(saveTbAttendees)
      }
      
      dispatch(updateSequence(ToolboxEvent.NEXT_SELECTED))
    } else {
      log.error("Toolbox Rollcall save failed, unable to find projectSite")
    }
  }

  const cancelSelected = () => {
    if (dirty || isDirty || toolboxEdit.groupPhoto?.docId) {
      dispatch(
        setAlert(
          "Cancel meeting",
          "You've made changes to this meeting, are you sure you want to cancel?",
          [
            {
              text: "Continue meeting",
              role: "cancel",
              handler: () => true
            },
            {
              text: "Cancel meeting",
              handler: () => {
                dispatch(updateSequence(ToolboxEvent.BACK_SELECTED))
                return true
              }
            }
          ]
        )
      )
    } else {
      dispatch(updateSequence(ToolboxEvent.BACK_SELECTED))
    }
  }

  // projectSites read on page load - set it when we receive it if we didn't have it when createToolbox was called
  useEffect(() => {
    if (!getValues("projectSiteId") && availableProjectSites.length === 1) {
      setValue("projectSiteId", availableProjectSites[0].id)
    }
  }, [availableProjectSites])

  const refresherRef = useRef<HTMLIonRefresherElement>(null)
  useEffect(() => {
    if (!isLoading) {
      refresherRef.current?.complete()
    }
  }, [isLoading])

  return (
    <>
      <IonContent class="content-padding">
        <h2>Project:</h2>
        <SelectListInput
          className="modal-input"
          title="Select project that toolbox meeting will be for"
          placeholder="Project (tap to select)"
          listData={(
            availableProjectSites?.map((projectSite) => ({
              text: `${projectSite.siteName}${
                projectSite.siteName !== projectSite.projectName
                  ? ` - ${projectSite.projectName}`
                  : ""
              }`,
              id: projectSite.id
            })) || []
          ).concat([{ text: "All Active Projects", id: -1 }])}
          fieldName="projectSiteId"
          watch={watch}
          setValue={setValue}
          error={errors.projectSiteId}
          // disable edit if only one site available
          disabled={
            !!getValues("projectSiteId") &&
            (!availableProjectSites || availableProjectSites.length < 2)
          }
        />

        {selectedProjectSiteId === -1 ? (
          <>
            <h2>Company:</h2>
            <SelectListInput
              className="modal-input"
              title="Select company that toolbox meeting will be for"
              placeholder="Company (tap to select)"
              listData={
                companies?.map((company) => ({
                  text: company.companyName,
                  id: company.id
                })) || []
              }
              fieldName="companyId"
              watch={watch}
              setValue={setValue}
              error={errors.companyId}
            />
          </>
        ) : (
          <></>
        )}

        <h2 id="attendee-heading">Who's attending this meeting?</h2>

        {/* <IonRefresher
            ref={refresherRef}
            slot="fixed"
            onIonRefresh={() => setRefresh(refresh+1)}
        >
            <IonRefresherContent
                pullingIcon={arrowDownCircleOutline}
                pullingText="Pull to refresh"
                refreshingSpinner="circles"
                refreshingText="Refreshing..."
            />
        </IonRefresher> */}

        <form>
          <IonList>
            <IonAccordionGroup>
              {availableUsers?.map((user, i) => (
                <IonAccordion key={user.id}>
                  <IonItem slot="header">
                    {user.signinTime ? (
                      <IonIcon className="location" icon={location}></IonIcon>
                    ) : (
                      <div className="icon-space"></div>
                    )}
                    <ASCheckbox
                      checked={inChecklist(attendeesList, user)}
                      onClick={(checked) =>
                        dispatch(
                          setToolboxAttendee({
                            attendee: user,
                            newState: checked
                          })
                        )
                      }
                      label={`${user.firstName} ${user.lastName}`}
                    />
                  </IonItem>
                  <IonList slot="content">
                    <IonItem>
                      <div className="flex-column">
                        {user.signinTime ? (
                          <>
                            <p>
                              Sign in time:{" "}
                              {format(
                                parseJSON(user.signinTime),
                                "dd MMM yyy HH:mm:ss"
                              )}
                            </p>
                            <p>Signed in as {user.signinType}</p>
                          </>
                        ) : (
                          <p>Not signed in</p>
                        )}
                      </div>
                    </IonItem>
                  </IonList>
                </IonAccordion>
              ))}
            </IonAccordionGroup>
          </IonList>

          {/* <IonButton >Add Attendee</IonButton> */}

          <CameraInput
            fieldName="groupPhoto"
            label="Add photo"
            watch={watch}
            setValue={setValue}
          />

          <PhotoDisplay
            fieldName="groupPhoto"
            watch={watch}
            setValue={setValue}
          />

          <TextArea
            fieldName="comments"
            inputMode="text"
            placeholder="COMMENTS - Any extra details you want to record"
            rows={6}
            watch={watch}
            setValue={setValue}
            error={errors.comments}
          />
          <div className="keyboard-space"></div>
        </form>
      </IonContent>

      <IonFooter class="toolbox-nav">
        <IonButton onClick={cancelSelected}>Cancel</IonButton>
        <IonButton onClick={handleSubmit(formSubmitNext)}>Next</IonButton>
      </IonFooter>
    </>
  )
}
