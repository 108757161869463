import { AppDispatch, RootState } from "../../redux/store"
import { setRequestComplete, setRequestStart } from "../../redux/slices/resourceMeta"
import { ResourceEvent } from "../../datastore/types"

export const requestLoggedInUser = (params: { [key: string]: any }) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const { requestKey } = params

    if (requestKey) {
        dispatch(setRequestStart({ requestKey}))
        dispatch(setRequestComplete({ requestKey, resourceEvent: ResourceEvent.RESOURCE_VALID }))
    }
    return ResourceEvent.RESOURCE_VALID
}

export const getLoggedInUser = (params: { [key: string]: any }) => (dispatch: AppDispatch, getState: () => RootState) => {

    const user = getState().app.user

    return {
        loggedInUser: {
            firstName: user ? user.firstName : '',
            lastName: user ? user.lastName : ''
        }
    }
}