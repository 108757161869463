import { createSelector } from "@reduxjs/toolkit"
import SigninDoc from "../../datastore/models/SigninDoc"
import { selectParamsMemo } from "./helpers/selectParamsMemo"
import { getResource, getResources } from "../../datastore"
import { selectResourcesMemo } from "./helpers/selectResourcesMemo"
import { DocInfo } from "../../datastore/models"

const selectResources = selectResourcesMemo(["sites", "signinDocs"])
const selectParams = selectParamsMemo()
export const selectSigninDocs = createSelector(
  [selectResources, selectParams],
  (resources, params) => {
    if (params.siteId) {
      const signinDocs = getResources<SigninDoc>(resources, "signinDocs", {
        eqFilter: { site: params.siteId }
      })
      if (params.showUnused) {
        return signinDocs.sort((doc1, doc2) => doc1.order - doc2.order)
      } else {
        return signinDocs
          .filter(
            (signinDoc) =>
              signinDoc.showFrequencyWorker !== "never" ||
              signinDoc.showFrequencyVisitor !== "never" ||
              signinDoc.showFrequencyDriver !== "never"
          )
          .sort((doc1, doc2) => doc1.order - doc2.order)
      }
    }
    return []
  }
)

const selectSitePlanResources = selectResourcesMemo([
  "sites",
  "signinDocs",
  "docInfo"
])
const selectSitePlanParams = selectParamsMemo()
export const selectSitePlans = createSelector(
  [selectSitePlanResources, selectSitePlanParams],
  (resources, params) => {
    if (params.siteId) {
      const signinDocs = getResources<SigninDoc>(resources, "signinDocs", {
        eqFilter: { site: params.siteId, docType: "SitePlan" }
      })
      return signinDocs.map((signinDoc) => {
        const docInfo = getResource<DocInfo>(
          resources["docInfo"],
          signinDoc.docInfo
        )
        return {
          docInfo: signinDoc.docInfo,
          title: signinDoc.title,
          fileName: docInfo?.fileName,
          order: signinDoc.order
        }
      })
    }
    return []
  }
)
