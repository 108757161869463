import { AppDispatch, RootState } from '../../redux/store'
import { selectSiteObservation } from '../../redux/selectors/siteObservations'
import { selectSiteObservationActions } from '../../redux/selectors/siteObservationActions'
import { initialiseSiteObservationEdit, setSiteObservationReviewId } from '../../redux/slices/pgSiteObservations'
import { requestSiteObservation } from './resourceRequests/requestObservation'
import { updateSequence } from './siteObservationsSequence'
import { SiteObservationEvent } from './siteObservationsTypes'
import log from 'loglevel'
import { setAlert } from '../../utils/alert'
import { selectCanEditObservationAsObserver, selectCanEditObservationAsSupervisor } from '../../redux/selectors/abilities'
import { hideLoadingSpinner, showLoadingSpinner } from '../../redux/slices/temp'

export const loadObservation = (siteObservationId?: number) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    if (siteObservationId) {

        dispatch(showLoadingSpinner('Loading Site Observation...'))

        await requestSiteObservation({ siteObservationId })(dispatch, getState, services)

        const siteObservation = selectSiteObservation(getState(), { siteObservationId })
        if (siteObservation) {

            if ((selectCanEditObservationAsObserver(getState(), siteObservation) && !siteObservation.observerClosedDate) ||
                (selectCanEditObservationAsSupervisor(getState(), siteObservation) && !siteObservation.supervisorClosedDate)) {
                // Observation is still open for editing - load it and go to 'details' page
                const actions = selectSiteObservationActions(getState(), { siteObservationId })
                dispatch(initialiseSiteObservationEdit({
                    details: {
                        ...siteObservation,
                        photoBefore: siteObservation.photoBefore ? { docId: siteObservation.photoBefore } : undefined,
                        photoAfter: siteObservation.photoAfter ? { docId: siteObservation.photoAfter } : undefined,
                    },
                    actions,
                }))
                dispatch(updateSequence(SiteObservationEvent.DETAILS_SELECTED))

            } else {
                // Observation is closed out / not editable by this user - display it in 'review' format
                dispatch(setSiteObservationReviewId(siteObservationId))
                dispatch(updateSequence(SiteObservationEvent.REVIEW_SELECTED))
            }

        } else {
            dispatch(setAlert(
                'Failed to load Site Observation',
                'Internet connection may be lost'
            ))
        }

        dispatch(hideLoadingSpinner())

    } else {
        log.error('loadObservation called with no observationId')
    }
}