const taTemplates = {
  table: 'ta_templates',
  idKey: 'id',

  attrs: [
    'name',
    'templateDoc',
    'templateData',
    'creationDate',
    'lastModifiedDate',  
  ],

  relationships: [   
    {
      type: 'companies',
      key: 'company',
    },
    {
      type: 'job-categories',
      key: 'category',
    },
  ],
}

export default taTemplates