import { deleteResource, saveResource, getResources } from '../../datastore'
import TbTa from '../../datastore/models/TbTa'
import { initialiseToolboxTas } from '../../redux/slices/pgToolbox'
import { AppDispatch, RootState } from '../../redux/store'
import { checklistItemsToAdd, checklistItemsToDelete } from './resourceChecklist'

export const saveTbTas = (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState()  
    const { toolboxEdit, tasList } = state.pgToolbox

    // Find items that have just been de-selected
    // => delete corresponding resources
    checklistItemsToDelete(tasList).forEach(taId => {
        const tasToDelete = getResources<TbTa>(state.resources, 'tbTas', { eqFilter: { toolbox: toolboxEdit.id, ta: taId } })
        // Should be only a single resource, but if there are multiple records for the same ta, delete them all
        tasToDelete.forEach(task => dispatch(deleteResource(task.id, 'tbTas')))
    })

    // Find items that have just been selected
    // => Save as new 'tbTas' resources
    checklistItemsToAdd(tasList).forEach(taId => {
        dispatch(saveResource({
            company: toolboxEdit.company,
            toolbox: toolboxEdit.id,
            ta: taId
        }, 'tbTas'))
    })

    // Set preEdit to match checked now that changes have been saved
    dispatch(initialiseToolboxTas(tasList.checked.map(item=>item.id)))
}