import { addDays } from 'date-fns'
import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { requestSiteObservations } from './requestObservations'
import { selectSiteObservations } from '../../../redux/selectors/siteObservations'

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results 
 * 
 * @returns  requested resources 
 */
export const useRegisterResources = (siteId: number | null) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(requestSiteObservations({ siteId, periodStart: addDays(new Date(), -30) }))
    }, [siteId])

    return {
        observations: useAppSelector(state => selectSiteObservations(state, siteId ? { siteId } : {}))
    }

}