import { createSelector } from '@reduxjs/toolkit'
import { getResources } from '../../datastore'
import { IncidentAction } from '../../datastore/models'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'

const selectResources = selectResourcesMemo(['incidents', 'incidentActions'])
const selectParams = selectParamsMemo()

export const selectIncidentActions = createSelector([selectResources, selectParams], (resources, params) => {
    
    const eqFilter = params.incidentId?{ incident: params.incidentId }:{}

    return getResources<IncidentAction>(resources, 'incidentActions', { eqFilter })    
})
