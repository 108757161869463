import { IonButton, IonContent, IonFooter, IonItemOption, IonList } from "@ionic/react"
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import TextArea from "../../components/form/TextArea"
import { SlidingItem } from "../../components/SlidingItem"
import { saveTbOther } from '../../features/toolbox/saveTbOther'
import { setToolboxDirty } from "../../redux/slices/pgToolbox"
import { setAlert } from "../../utils/alert"
import CameraInput, { PhotoData } from "../../components/form/CameraInput"
import PhotoDisplay from "../../components/form/PhotoDisplay"
import { TbOther } from "../../datastore/models"
import { useOtherBusinessResources } from "../../features/toolbox/resourceRequests/useOtherBusinessResources"
import { closeToolbox } from "../../features/toolbox/closeToolbox"
import { updateSequence } from "../../features/toolbox/toolboxSequence"
import { ToolboxEvent } from "../../features/toolbox/toolboxTypes"

import './OtherBusiness.css'

export type TbOtherEdit = Omit<Partial<TbOther>, 'photo'> & {
    photo?: PhotoData,
}

type Inputs = {
    items: TbOtherEdit[]
}

const schema = yup.object().shape({
    items: yup.array().of(
        yup.object().shape({
            heading: yup.string().when(['deleted'], {
                is: (deleted?: boolean) => !deleted,
                then: yup.string().required('Item needs a heading'),
            }),
            text: yup.string(),
            deleted: yup.boolean()
        })
    )
}).required()

export const OtherBusiness: React.FC = () => {
    const dispatch = useAppDispatch()
    const { toolboxEdit } = useAppSelector(state => state.pgToolbox)
    const { tbOtherItems } = useOtherBusinessResources(toolboxEdit.id || null)

    const { handleSubmit, setValue, watch, control, formState: { errors, dirtyFields } } = useForm<Inputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            items: tbOtherItems.map(item => ({
                id: item.id,
                heading: item.heading,
                text: item.text,
                photo: item.photo
                    ? { docId: item.photo }
                    : undefined
            }))
        }
    })

    const { fields, append, update } = useFieldArray({
        control,
        name: "items",
        keyName: "ufaId", // defaults to "id" - changed so as not to overwrite resource id
    })

    const addItem = () => {
        append({
            heading: '',
            text: '',
        })
    }

    const deleteItem = (index: number) => {
        const item = fields[index]
        if (item) {
            update(index, {
                ...item,
                deleted: true
            })
        }
    }

    const formSubmitBack = (data: any) => {
        formSubmit(data)
        dispatch(updateSequence(ToolboxEvent.BACK_SELECTED))
    }

    const formSubmitClose = (data: any) => {
        dispatch(setAlert(
            'Close meeting',
            "Finish and close the meeting?",
            [{
                text: 'Cancel',
                role: 'cancel',
                handler: () => true
            },
            {
                text: "Close the meeting",
                handler: () => {
                    formSubmit(data)
                    dispatch(closeToolbox())
                    return true
                }
            }]
        ))
    }

    const formSubmit = (data: any) => {

        data.items.forEach((item: TbOtherEdit, i: number) => {
            const itemDirtyFields = dirtyFields?.items ? dirtyFields.items[i] : undefined

            if ((item.photo?.dataUrl && !item.photo?.docId) ||
                item.photo?.deleted ||
                (itemDirtyFields && (itemDirtyFields.heading || itemDirtyFields.text || itemDirtyFields.deleted))) {
                dispatch(saveTbOther(item))
                dispatch(setToolboxDirty(true))
            }
        })
    }

    return (
        <>
            <IonContent class="content-padding">
                <h2>Other Business</h2>
                <p>Add any other items for discussion here</p>

                <form>
                    <IonList id="discussion-items-list" lines="none">
                        {fields?.map((item, i) => ({ ...item, fieldIndex: i })).filter(item => !item.deleted).map(item => {

                            return (
                                <SlidingItem
                                    key={item.ufaId}
                                    itemContent={
                                        <div className="discussion-item ">

                                            <TextArea
                                                fieldName={`items[${item.fieldIndex}].heading`}
                                                placeholder="New Discussion Item"
                                                inputMode="text"
                                                rows={1}
                                                watch={watch}
                                                setValue={setValue}
                                                error={(errors?.items && errors.items[item.fieldIndex]) ? errors.items[item.fieldIndex]?.heading : undefined}

                                            />

                                            <CameraInput
                                                fieldName={`items[${item.fieldIndex}].photo`}
                                                label="Add photo"
                                                watch={watch}
                                                setValue={setValue}
                                            />
                                            <PhotoDisplay
                                                fieldName={`items[${item.fieldIndex}].photo`}
                                                watch={watch}
                                                setValue={setValue}
                                            />

                                            <TextArea
                                                inputMode="text"
                                                placeholder="Enter any details of discussion"
                                                rows={1}
                                                fieldName={`items[${item.fieldIndex}].text`}
                                                watch={watch}
                                                setValue={setValue}
                                                error={(errors?.items && errors.items[item.fieldIndex]) ? errors.items[item.fieldIndex]?.text : undefined}
                                            />
                                        </div>
                                    }
                                    optionsContent={
                                        <IonItemOption color="danger" onClick={() => deleteItem(item.fieldIndex)}>Delete</IonItemOption>
                                    }
                                />
                            )
                        }
                        )}

                    </IonList>
                    <IonButton onClick={() => addItem()}>Add Item</IonButton>
                </form>

            </IonContent>

            <IonFooter class="toolbox-nav">
                <IonButton onClick={handleSubmit(formSubmitBack)}>Back</IonButton>
                <IonButton onClick={handleSubmit(formSubmitClose)}>Close Meeting</IonButton>
            </IonFooter>

        </>)

}
