import { useAppSelector } from "../../../redux/hooks"
import { getResources, requestResourcesWithRelated } from "../../../datastore"
import { sub } from "date-fns"

import { AppDispatch, RootState } from "../../../redux/store"
import { requestResources } from "../../../datastore"
import { selectSignedinUsers } from "../../../redux/selectors/signins"
import { selectProjectSites } from "../../../redux/selectors/projectSites"
import { selectSiteUsers } from "../../../redux/selectors/users"
import { useTask } from "../../../hooks/useTask"
import { ResourceEvent } from "../../../datastore/types"
import { resourceEventCombined } from "../../../datastore/resourceEventCombined"
import { Project, ProjectSite } from "../../../datastore/models"
import { uniq, uniqBy } from "lodash"


/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results
 *
 */
export const useRollCallResources = (
  siteId: number | null,
  companyId: number | null,
  refresh?: any
) => {
  const taskResult = useTask(
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      if (companyId) {
        // Requesting all available users for a company:

        // Request projectSites / sites for all open projects
        const projectsResult = await requestResourcesWithRelated(
          "projects",
          { eqFilter: { status: "OPEN" } },
          ["projectSites.sites"]
        )(dispatch, getState, services)

        const projects = getResources<Project>(
          getState().resources,
          "projects",
          { eqFilter: { company: companyId, status: "OPEN" } }
        )
        const projectSites = getResources<ProjectSite>(
          getState().resources,
          "projectSites",
          { inFilter: { project: projects.map((project) => project.id) } }
        )
        const siteIds = uniq(
          projectSites.map((projectSite) => projectSite.site)
        )

        // Request users signed in to sites of all open projects belonging to company
        const results = await Promise.all([
          requestResources("signins", {
            inFilter: { site: siteIds },
            periodFilter: {
              attr: "signinTime",
              periodStart: sub(new Date(), { days: 2 })
            },
            include: ["user"]
          })(dispatch, getState, services),

          // Request site users of sites of all open projects belonging to company
          requestResources("siteUsers", {
            inFilter: { site: siteIds },
            include: ["user"]
          })(dispatch, getState, services)
        ])

        return {
          isSuccess:
            resourceEventCombined(projectsResult, results) !==
            ResourceEvent.RESOURCE_LOAD_ERROR
        }

      } else {
        // No company specified:
        // request users signed in to selected site
        const results = await Promise.all([
          requestResources("signins", {
            eqFilter: { site: siteId },
            periodFilter: {
              attr: "signinTime",
              periodStart: sub(new Date(), { days: 2 })
            },
            include: ["user"]
          })(dispatch, getState, services),

          // request site users of selected site
          requestResources("siteUsers", {
            eqFilter: { site: siteId },
            include: ["user"]
          })(dispatch, getState, services)
        ])

        return {
          isSuccess:
            resourceEventCombined(ResourceEvent.RESOURCE_VALID, results) !==
            ResourceEvent.RESOURCE_LOAD_ERROR
        }
      }
    },
    [siteId, companyId, refresh],
    !!siteId || !!companyId
  )

  const siteIds = useAppSelector((state) =>
    selectProjectSites(state, { companyId })
  ).map((projectSite) => projectSite.siteId)

  const signedInUsers = useAppSelector((state) =>
    selectSignedinUsers(state, siteId ? { siteId } : { siteIds })
  )
  const siteUsers = useAppSelector((state) =>
    selectSiteUsers(state, siteId ? { siteId } : { siteIds })
  )

  return {
    availableUsers:
      siteId || companyId ? uniqBy(signedInUsers.concat(siteUsers), "id") : [],

    isLoading: taskResult.isLoading,
    isSuccess: taskResult.isSuccess,
    isError: taskResult.isError
  }
}
