const signins = {
  table: 'signins',
  idKey: 'id',

  attrs: [
    'signinType',
    'signinTime',
    'signoutTime',
    //'signinLocation',
    //'signoutLocation',
    'creationDate',
    'lastModifiedDate'
  ],

  relationships: [    
    {
      type: 'companies',
      key: 'company',
    },
    {
      type: 'sites',
      key: 'site',
    },
    {
      type: 'users',
      key: 'user',
    },    
  ],
}

export default signins