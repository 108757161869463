import { AppDispatch, RootState } from "../../redux/store"
import { requestResources } from "../../datastore"
import { useTask } from "../../hooks/useTask"
import { ResourceEvent } from "../../datastore/types"
import { useAppSelector } from "../../redux/hooks"
import { selectTemplateDoc } from "../../redux/selectors/companies"

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results
 *
 * @returns  requested resources
 */
export const useTemplateDoc = (companyId: number | undefined, templateName: string) => {
  const taskResult = useTask(
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      const result = await requestResources("companies", {
        eqFilter: { id: companyId }
      })(dispatch, getState, services)

      return {
        isSuccess: result !== ResourceEvent.RESOURCE_LOAD_ERROR,
      }
    },
    [companyId, templateName], !!companyId && !!templateName
  )

  return {
    // Note: select template doc here (rather than in task) so that we get whatever we have in the store straight away
    // (normally company record will have already been loaded)
    templateDoc: useAppSelector(state => selectTemplateDoc(state, { companyId, templateName })),
    isLoading: taskResult.isLoading,
    isSuccess: taskResult.isSuccess,
    isError: taskResult.isError
  }
}
