import * as yup from 'yup'

import { isBefore, isValid } from "date-fns"
import dateToISOString from "../helpers/dateToIsoString"
import parseDate from "../helpers/parseDate"

const transformFn = (value: any, originalValue: any) => {
    const txVal = dateToISOString(parseDate(originalValue))
    return txVal
}

interface Options {
    min?: Date,
    minMsg?: string,
    max?: Date,
    maxMsg?: string,
    nullable?: boolean,
    message?: string
}

export const initISODateValidator = () => {

    yup.addMethod(yup.string, "isISODate",
        function (options: Options) {
            const {
                min,
                minMsg,
                max,
                maxMsg,
                nullable,
                message
            } = options || {}

            return this.test('isISODate', message || 'Invalid Date', function (this: any, value: string | undefined | null) {
                const { path, createError } = this

                const dtValue = value ? parseDate(value) : null
                if (dtValue && isValid(dtValue)) {
                    if (min && isBefore(dtValue, min)) {
                        return createError({ path, message: minMsg })
                    }
                    if (max && !isBefore(dtValue, max)) {
                        return createError({ path, message: maxMsg })
                    }
                    // Within limits, or limit(s) not provided - valid
                    return true
                }
                // value missing or not a valid date (if nullable we will still treat as valid) 
                return nullable ? true : createError({ path, message })

                // The following actually run prior to validation:
                // - transform ensures that any input is parsed into (and returned as) either a valid ISO string or null
                // - nullable prevents any resulting null from triggering a default 'invalid string' error
                //   (if date is not actually nullable we will generate our own error above)
            }).transform(transformFn).nullable()
        }
    )
}