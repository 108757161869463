import { getLogger } from "loglevel"
import _, { get, uniq } from "lodash"

import { AppDispatch, RootState } from "../../redux/store"
import { ResourceEvent } from "../types"
import { ResourceType } from "../../redux/slices/resources"
import { GetResourcesOptions } from "../getResources"
import { requestResources, RequestResourcesOptions } from "../requestResources"
import { getRelationNames } from "../json-api/helpers/getRelationNames"
import {
  initialiseResourceStatus,
  LOCAL_ID_START
} from "../../redux/slices/resourceMeta"
import { add, parseJSON } from "date-fns"
import { filterResources } from "./filterResources"

const log = getLogger("resourceRequests")

/**
 * Called when response received for a resource with 'autoRequestRelated' set
 *
 * @param resourceType  Base resource type
 * @param opts          Original request options
 * @param related       Array of related resource types to auto-update
 * @returns
 */

export const autoRequestRelatedResources =
  (
    resourceType: ResourceType,
    opts: RequestResourcesOptions,
    related: ResourceType[]
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    if (!related) {
      log.error(
        "autoRequestRelatedResources:no related resources specified, resourceType ",
        resourceType
      )
    }
    // Get existing base resource records
    const baseResources = filterResources(
      getState().resources,
      resourceType,
      opts as GetResourcesOptions
    ).filter((resource) => +resource.id < LOCAL_ID_START)

    log.debug(
      `autoRequestRelatedResources: updating related resources for ${resourceType}`
    )

    related.forEach(async (relationType) => {
      let oneToMany = false
      let relationNames = []
      let resourceIds: number[] = []
      // Look for a relationship defined on the related resource that points to the base resource
      // ('1 to many' relationship for base resource)
      relationNames = getRelationNames(relationType, resourceType, true)
      if (relationNames.length) {
        oneToMany = true
        // There should be only a single 'relationName'- multiple relationships
        // defined on the same related resource type are not supported
        if (relationNames.length > 1) {
          log.error(
            `autoRequestRelatedResources:Error - resource type ${relationType} has more than one relationship to base type ${resourceType}`
          )
        }
        // ids of base resource records to match related records against
        resourceIds = baseResources.map((resource) => +(resource as any).id)
      } else {
        // Look for a relationship defined on the base resource that points to the related resource
        // ('1 to 1' relationship)
        relationNames = getRelationNames(resourceType, relationType, true)
        // unique related resource ids specified by relationship(s) defined on base resource records
        // Note there may be more than one relationName if base resource type has more than one relationship to the same
        // related resource type (e.g. multiple users)
        resourceIds = uniq(
          relationNames.reduce((acc: number[], relationName) => {
            return acc.concat(
              baseResources.reduce((acc: number[], resource) => {
                const relationshipId: string = get(resource, [
                  "relationships",
                  relationName,
                  "data",
                  "id"
                ])
                return relationshipId ? acc.concat([+relationshipId]) : acc
              }, [])
            )
          }, [])
        )
      }
      const relationStatus =
        getState().resourceMeta.resourceUpdateStatus[relationType]

      const relatedInFilter = relationStatus?.autoRequestFilter?.inFilter || {}
      const relatedInfilterIds =
        (oneToMany ? relatedInFilter[relationNames[0]] : relatedInFilter.id) ||
        []

      if (
        relatedInfilterIds.length !== resourceIds.length ||
        _.xor(relatedInfilterIds, resourceIds).length !== 0
      ) {
        // There has been a change in the list of base resources
        const relationValidTo = relationStatus?.validTo

        // Identify which of the base resource ids are new
        const newIds = resourceIds.filter(
          (resourceId) => !relatedInfilterIds.includes(resourceId)
        )
        
        let reqNewResult = ResourceEvent.RESOURCE_VALID
        if (newIds.length && relationValidTo) {
          // There are new ids in the base resource list, plus existing items that we've previously
          // requested up to 'validTo' time
          // -> request just the new items up to the same time. periodFilter is end time - exclusive, so
          //  add one second to ensure there isn't any gap
          // This should result in a 'full' request for the new ids as they aren't yet in the current autoRequestFilter
          log.debug(
            `autoRequestRelatedResources: requesting related ${relationType} for new ${resourceType} ids:${newIds} `
          )

          reqNewResult = await requestResources(relationType as ResourceType, {
            inFilter: oneToMany
              ? { [relationNames[0]]: newIds }
              : { id: newIds },
            periodFilter: {
              attr: "lastModifiedDate",
              periodEnd: add(parseJSON(relationValidTo), { seconds: 1 })
            }
          })(dispatch, getState, services)
        }

        if (
          reqNewResult !== ResourceEvent.NO_RESOURCE_FOUND &&
          reqNewResult !== ResourceEvent.RESOURCE_VALID
        ) {
            // If request for new resources failed, clear 'valid to' time
            // - following request will then be a full request to refresh everything
            dispatch(
                initialiseResourceStatus({
                  resourceType,
                  status: {
                    validTo: ''
                  }
                })
              )
        }
        // Update related resource filter to match actual set of base resources
        dispatch(
          initialiseResourceStatus({
            resourceType: relationType,
            status: {
              autoRequestFilter: {
                inFilter: oneToMany
                  ? { [relationNames[0]]: resourceIds }
                  : { id: resourceIds }
              }
            }
          })
        )
      }
      log.debug(
        `autoRequestRelatedResources: Updating all related ${relationType} resources`
      )

      // Finally, make a request for all items - this should result in an incremental request for only items modified from 'validTo' time to the present
      // (or a full request for everything if we don't have any previous data)
      requestResources(relationType as ResourceType, {
        inFilter: oneToMany
          ? { [relationNames[0]]: resourceIds }
          : { id: resourceIds }
      })(dispatch, getState, services)

      if (!relationNames.length) {
        log.error(
          `autoRequestRelatedResources: unknown relation type '${relationType} on resourceType ${resourceType}`
        )
      }
    })
  }
