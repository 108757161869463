import { Drivers, Storage } from '@ionic/storage'

const localStore = new Storage({
    name: '__mydb',
    driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
  });

async function initLocalStore() {
    await localStore.create()
}

/*
import { get, set, del, clear } from 'idb-keyval';

async function initLocalStore() {}

const localStore = {
    get,
    set,
    remove: del,
    clear
}
*/
export { 
    initLocalStore,
    localStore
}