export enum stSITE_OBSERVATIONS {
    START,
    REGISTER,
    DETAILS,
    ACTIONS,
    REVIEW
}

export enum SiteObservationEvent {
    START_SELECTED,
    REGISTER_SELECTED,
    DETAILS_SELECTED,
    NEXT_SELECTED,
    BACK_SELECTED,
    REVIEW_SELECTED
}