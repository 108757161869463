import type { RootState } from '../../../redux/store'

/**
* Dummy input selector used to supply additional parameters to selectors generated by 'createSelector'
* (see https://redux.js.org/usage/deriving-data-selectors)
*
* Basic function:
* const selectParams = (state: RootState, params: { [key: string]: any }) => params
*
* params is an object containing an arbitrary set of named values - this allows the same params object to supply 
* parameters to a set of selectors that each require different parameters. This will occur when these selectors 
* are themselves used as input selectors to a combined 'document data selector' and so must share a common 'selectParams' selector
*
* If a function like the one above is called via 'useAppSelector' from a component it will return a new params object every render, even
* if the actual parameters haven't changed - this will break the memoization implemented by 'createSelector'. To avoid this the following function
* can be used to memoize the params object itself.
*/

export const selectParamsMemo = () => {

    let paramsMemo: { [key: string]: any } = {}

    return (state: RootState, params: { [key: string]: any }) => {

        const paramsMemoCount = Object.keys(paramsMemo).length

        if (!params || typeof params !== 'object') {
            // No valid 'params' supplied - treat as empty object, replace memoized version if it wasn't empty
            if (paramsMemoCount > 0) {
                paramsMemo = {}
            }

        } else {

            const paramsCount = Object.keys(params).length
            if (paramsCount !== paramsMemoCount) {
                // Supplied params object has different number of keys to previous one -> treat as different
                // (this will occur if the same selector is used with and without parameters in different contexts)
                paramsMemo = params
                
            } else {

                // Typical case - we have a params object with the same number of keys as the memoized version
                // -> iterate through keys to see if any are different
                for (const param in params) {
                    if (params[param] !== paramsMemo[param]) {
                        // At least one parameter is different to memoized version
                        // -> replace previous version with this one
                        paramsMemo = params
                        break
                    }
                }
            }
        }
        return paramsMemo
    }
}