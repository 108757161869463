import { getRiskBand } from '../helpers/riskScore';
import './RiskIndicator.css'

interface RiskIndicatorProps {
    text:string,
    score:number | null,
    classSuffix?:string
 }

const RiskIndicator: React.FC<RiskIndicatorProps> = ( {text, score, classSuffix} ) => {
    
    const band = getRiskBand(score)
    const colourAtr = band?`var(--matrix${band})`:'gray'

    const className = classSuffix?`risk-indicator-${classSuffix}`: 'risk-indicator'

    return (
        <div className={className} style={{ backgroundColor: colourAtr }}>
            {`${text}${score || ''}`}
        </div>
    )
}

export default RiskIndicator;