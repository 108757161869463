import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { IonButton, IonContent, IonFooter } from "@ionic/react"

import DocContainer from '../../components/DocContainer'

import { selectToolbox } from '../../redux/selectors/toolboxes'
import { updateSequence } from '../../features/toolbox/toolboxSequence'
import { ToolboxEvent } from '../../features/toolbox/toolboxTypes'
import { useTemplateDoc } from '../../features/commonResourceRequests/useTemplateDoc'

export const Review: React.FC = () => {
    const dispatch = useAppDispatch()
    const { reviewToolboxId } = useAppSelector(state => state.pgToolbox)
    const reviewToolbox = useAppSelector(state => selectToolbox(state, { toolboxId: reviewToolboxId }))
    const { templateDoc } = useTemplateDoc(reviewToolbox?.company, 'toolbox')

    return (
        <>
            <IonContent>
                <DocContainer
                    docId={templateDoc}
                    selectorParams={{ toolboxId: reviewToolboxId }}
                />
            </IonContent>

            <IonFooter class="toolbox-nav">
                <IonButton onClick={() => dispatch(updateSequence(ToolboxEvent.START_SELECTED))}>Back to Start</IonButton>
            </IonFooter>

        </>)
}

