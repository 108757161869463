import { isEmpty } from "lodash"
import { AppDispatch, RootState } from "../../../redux/store"
import { requestResources, requestResourcesWithRelated } from "../../../datastore"
import { ResourceEvent } from "../../../datastore/types"
import { resourceEventCombined } from "../../../datastore/resourceEventCombined"
import { setRequestComplete, setRequestStart } from "../../../redux/slices/resourceMeta"

export const requestTa = (params: { [key: string]: any }) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    const { taId, requestKey } = params
    const state = getState()

    if (requestKey) {
        dispatch(setRequestStart({ requestKey }))
    }

    const results = await Promise.all([
        // Get tasks via include to use ta permissions 
        requestResourcesWithRelated('tas', { eqFilter: { id: taId }, include: ['scheduledTask'] }, ['projects', 'sites'])(dispatch, getState, services),
        // The following should have been automatically requested by background update, but if not, request them
        isEmpty(state.resources.hazardTypes) ? requestResources('hazardTypes', {})(dispatch, getState, services) : ResourceEvent.RESOURCE_VALID,
        isEmpty(state.resources.controls) ? requestResources('controls', {})(dispatch, getState, services) : ResourceEvent.RESOURCE_VALID,
        isEmpty(state.resources.ppeTypes) ? requestResources('ppeTypes', {})(dispatch, getState, services) : ResourceEvent.RESOURCE_VALID,
        // Get users via include to use taWorker permissions 
        requestResources('taWorkers', { eqFilter: { ta: taId }, include: ['user'] })(dispatch, getState, services)
    ])
    const requestResult = resourceEventCombined(results[0], results.slice(1))

    if (requestKey) {
        dispatch(setRequestComplete({ requestKey, resourceEvent: requestResult }))
    }
    return requestResult
}
