import { Site } from "../../../datastore/models";

/**
 * Format address as '1 someStreet, someSuburb, someCity'
 *
 * (similar to location services 'Label' format but shorter for display where postcode / country not required)
 * Note that only 'region' and 'country' are required - other fields may or may not be present if address was entered manually
 * Also fields may be duplicated, e.g. suburb and city may be the same for a small town
 *
 * @param _format 'SHORT' TODO: specify different formats if required (just one for now)
 * @returns comma separated string of address parts
 */
export const formatAddress = (site: Site, _format: string) => {
  let result = `${site.addressNumber || ''}${
    site.addressNumber && site.street ? ' ' : ''
  }${site.street || ''}`;

  result += result ? ', ' : '';

  // 2nd part - use suburb if present, otherwise substitute city / region
  result += site.suburb || site.city || site.region;
  // 3rd part - use city if it's present and different to suburb
  if (site.suburb && site.city && site.suburb !== site.city) {
    result += result ? ', ' : '';
    result += site.city;
  }
  return result;
};
