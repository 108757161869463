import { IonCard, IonCol, IonContent, IonGrid, IonRow } from "@ionic/react"
import { useAppSelector, useAppDispatch } from '../../redux/hooks'

import { setAlert } from "../../utils/alert"

import { createToolbox } from "../../features/toolbox/createToolbox"
import { updateSequence } from "../../features/toolbox/toolboxSequence"
import { ToolboxEvent } from "../../features/toolbox/toolboxTypes"
import { selectCanCreateAnyToolbox } from "../../redux/selectors/abilities"

import './Start.css'
import { useStartResources } from "../../features/toolbox/resourceRequests/useStartResources"

export const Start: React.FC = () => {
    const dispatch = useAppDispatch()
    const canCreateToolbox = useAppSelector(state => selectCanCreateAnyToolbox(state))
    const currentPath = useAppSelector(state => state.temp.currentPath)

    useStartResources(currentPath === '/toolbox')

    const startNewSelected = () => {

        if (canCreateToolbox) {
            dispatch(createToolbox)
        } else {
            dispatch(setAlert(
                'Insufficient Access',
                "You don't have sufficient access to run a toolbox meeting",                    
                [{
                    text: 'Ok',
                    handler: () => (true)
                }]
            ))
        }
    }

    return (
        <>
            <IonContent class="content-padding">
                <IonGrid fixed={true} className="app-grid">
                    <IonRow>
                        <IonCol>
                            <div id="toolbox-start-grid">
                                <IonCard
                                    button={true}
                                    onClick={startNewSelected}
                                >
                                    <div>
                                        <span>Start a</span>
                                        <span>Toolbox Talk</span>
                                    </div>
                                </IonCard>
                                <IonCard button={true} onClick={() => dispatch(updateSequence(ToolboxEvent.REGISTER_SELECTED))}>
                                    <div>
                                        <span>Review previous</span>
                                        <span>Toolbox Talk</span>
                                    </div>
                                </IonCard>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </>)
}