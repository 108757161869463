import { addDays } from 'date-fns'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { requestResources } from '../../../datastore'
import { selectToolboxes } from '../../../redux/selectors/toolboxes'

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results 
 * 
 * @returns  requested resources 
 */

// TODO: do we need a refresher?
// Consider refresh on change to currentPath (i.e. switch to home screen and back)?
export const useSelectToolboxResources = (siteId: number | null) => {
    const dispatch = useAppDispatch()

    const periodStart = addDays(new Date(), -7)

    useEffect(() => {

        dispatch(requestResources('toolboxes', {
            periodFilter: { attr: 'openDateTime', periodStart },
            eqFilter: siteId ? { site: siteId } : {}
        }))

    }, [siteId])

    const toolboxes = useAppSelector(state => selectToolboxes(state, { periodStart, siteId }))
    return {
        // filter out toolboxes with no close time - these are either still in progress or were not closed properly
        // (i.e. user shut down their phone with meeting still open)
        toolboxes: toolboxes.filter(toolbox => toolbox.closeDateTime)
    }
}