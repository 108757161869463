import { createSelector } from '@reduxjs/toolkit'
import { getResources } from '../../datastore'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'
import { HighRiskWork } from '../../datastore/models/highRiskWork'

const selectHighRiskWorkResources = selectResourcesMemo(['highRiskWork']) 
const selectHighRiskWorkParams = selectParamsMemo()

/**
 * Get array of high risk work items
 * 
 */
export const selectHighRiskWork = createSelector([selectHighRiskWorkResources, selectHighRiskWorkParams], (resources, params) => {
    const opts = params.itemIds
        ? { inFilter: { id: params.itemIds } }
        : {}
    return getResources<HighRiskWork>(resources, 'highRiskWork', opts)
})


