import { AppDispatch, RootState } from '../../redux/store'
import { saveResource } from '../../datastore'
import { selectUser } from '../../redux/selectors/users'
import reportRequests from '../reportRequests'
import log from 'loglevel'

export const sendObservationNotification = (siteObservationId: number, recipientId: number) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    const state = getState()

    const user = state.app.user
    const supervisor = selectUser(state, { userId: recipientId })

    await reportRequests.siteObservation.request({ siteObservationId })(dispatch, getState, services)
    const reportData = reportRequests.siteObservation.get({ siteObservationId })(dispatch, getState)

    if (!supervisor) {
        log.error(`sendObservationNotification: failed to find recipient ${recipientId}`)
        return
    }

    if (!reportData) {
        log.error(`sendObservationNotification: could not get report data observation ${siteObservationId}`)
        return
    }

    dispatch(saveResource({
        company: reportData.siteObservation.company,
        notificationType: 'SiteObservation',
        initiator: user?.id,
        recipients: [{
            userId: supervisor.id,
        }],
        userData: {
            ...reportData,
            images: reportData.siteObservation.images
        }
    }, 'notifications'))
}