import log from "loglevel"

import { AppDispatch, RootState } from "../../redux/store"

import { ApiResult } from "../../interfaces/api"
import {
  selectIsSignedIn,
  selectSignedinSiteId
} from "../../redux/selectors/signins"
import { setSigninCode } from "../../redux/slices/pgSignin"
import { setRedirectPath, setStartupInProgress } from "../../redux/slices/temp"
import { logout } from "../auth"
import { ResourceEvent } from "../../datastore/types"
import { isValidSigninCode, updateSequence } from "../signin/signinSequence"
import { SigninEvent, stSIGNIN } from "../signin/signinTypes"
import { requestLatestSignin } from "../../features/signin/resourceRequests/requestLatestSignin"
import { requestSiteBySigninCode } from "../../features/signin/resourceRequests/requestSiteBySigninCode"
import { getResource, requestResources } from "../../datastore"
import { initialiseSiteResourceUpdate } from "../resources/siteResourceUpdate"
import { requestSigninSiteData } from "../signin/resourceRequests/requestSigninSiteData"
import { Site } from "../../datastore/models"

export const initialiseSigninState =
  () =>
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      const api = services.api
      const state = getState()
      const user = state.app.user
      const { isNewLogin, appOpenUrl } = state.temp
      const url = new URL(appOpenUrl)
      const urlParams = new URLSearchParams(url.search)
      let urlSigninCode = urlParams.get("signinCode") as string
      if (
        !appOpenUrl ||
        appOpenUrl.search("/signin") === -1 ||
        !isValidSigninCode(urlSigninCode)
      ) {
        urlSigninCode = ""
      }

      // Next page to go to after process is complete
      // go to signin page by default if we don't opt for anywhere else
      let nextRoute = "/signin"

      // Assume not signed in until we discover otherwise
      let isSignedIn = false

      // Flag indicating we will set signin sequence to 'complete' (i.e. if we confirm a user's still signed in)
      // Assume we won't until we know we will
      let setSigninComplete = false

      // Flag indicating user logged in during signin sequence
      // Assume they didn't until we know they did
      let loginDuringSignin = false

      // If a user is logged in, get their latest signin record to determine whether they are currently signed in to a site
      // Note - if user is a guest they cannot read signin record from API, this will just check whether a record exists in offline storage
      if (user) {
        await requestLatestSignin(user.id!)(dispatch, getState, { api })
        const state = getState()
        isSignedIn = selectIsSignedIn(state)
        if (isSignedIn) {
          const signinSiteId = selectSignedinSiteId(state)
          if (signinSiteId) {
            await requestResources("sites", { eqFilter: { id: signinSiteId } })(
              dispatch,
              getState,
              { api }
            )
            // If user was previously signed in but we can't read site record then treat them as signed out
            // (unusual case but could happen if users permissions changed while signed in)
            const site = getResource<Site>(
              getState().resources.sites,
              signinSiteId
            )
            if (!site) {
              log.error(
                "startup Error - signed into site but can't get site details"
              )
              isSignedIn = false
            }
          } else {
            log.error(
              "startup Error - signed into site but can't get site details"
            )
            isSignedIn = false
          }
        }
      }

      if (urlSigninCode) {
        // If no user currently logged in then API will need temporary 'site token' to get public site data
        if (!user) {
          const result = await api.getSiteToken(urlSigninCode)
          // If we couldn't get token for any reason, abandon attempt to use urlSigninCode
          if (result !== ApiResult.SUCCESS) {
            urlSigninCode = ""
            log.warn("Got urlSigninCode but couldn't get site token")
          } else {
            log.debug("Got urlSigninCode and site token")
          }
        }
        if (urlSigninCode) {
          const requestSiteStatus = await requestSiteBySigninCode(urlSigninCode)(
            dispatch,
            getState,
            { api }
          )

          if (requestSiteStatus !== ResourceEvent.RESOURCE_VALID) {
            // Couldn't get site info (will proceed as if no code was passed - need error message?)
            urlSigninCode = ""
          }
        }
      }

      if (!urlSigninCode) {
        if (user) {
          if (user.isGuest) {
            if (isSignedIn) {
              setSigninComplete = true
              nextRoute = "/home"
            } else {
              // Guest cannot remain logged in if not signed in => log them out
              log.warn(
                "Guest user automatically logged out due to not being signed in to a site"
              )
              dispatch(logout())
            }
          } else {
            // NOT a guest user...
            const state = getState()

            if (state.pgSignin.stSignin === stSIGNIN.GET_USER) {
              // user logged in part way through signin sequence
              loginDuringSignin = true
              // Reload site data (it should have been persisted from previous request, but just in case it wasn't...)
              await requestSiteBySigninCode(state.pgSignin.signinCode)(
                dispatch,
                getState,
                { api }
              )
            } else {
              // Logged in user was already signed in to a site - set signin sequence to match
              if (isSignedIn) {
                setSigninComplete = true
              }

              // User has re-opened app while still logged in AND signed into a site, OR has just completed login
              // -> send them to home page
              // (otherwise, i.e. if an already logged in user has re-opened the app,
              // leave them on signin page in case they want to scan in immediately)
              if (isSignedIn || isNewLogin) {
                nextRoute = "/home"
              }
            }
          }
        }
      }

      // If we've confirmed that user is still signed in from previous session, set
      // sequence to 'Complete'
      // Otherwise reset sequence to 'Initialise', UNLESS user logged in part way
      // through signin sequence (in which case we want to put them back where they left off)
      if (setSigninComplete) {
        dispatch(updateSequence(SigninEvent.SET_COMPLETE))
      } else if (!loginDuringSignin) {
        dispatch(updateSequence(SigninEvent.RESET_SEQUENCE))
      }

      if (urlSigninCode) {
        dispatch(setSigninCode(urlSigninCode))
      }

      if (user) {
        let siteId = null
        if (isSignedIn) {
          siteId = selectSignedinSiteId(getState())
        }

        if (loginDuringSignin) {
          siteId = getState().pgSignin.signinSiteId
        }

        if (siteId) {
          dispatch(initialiseSiteResourceUpdate(siteId))
          dispatch(requestSigninSiteData(siteId))
        }
      }

      // Aiming for the splash screen to be displayed for around 1.5 seconds
      // (not reliable due to varying delays in startup process, but splash screen isn't that important...)
      const splashDelay = Math.max(
        1500 - Date.now() + getState().temp.splashStartTime,
        0
      )

      setTimeout(() => {
        dispatch(setRedirectPath(nextRoute))
        dispatch(setStartupInProgress(false))
      }, splashDelay)
    }
