const siteObservations ={
  table: 'site_observations',
  idKey: 'id',

  attrs: [
    'observationDate',
    'description',
    'observerClosedDate',
    'supervisorClosedDate',
    'creationDate',
    'lastModifiedDate',
    'deleted'
  ],

  relationships: [
    {
      type: 'companies',
      key: 'company',
    },
    {
      type: 'sites',
      key: 'site',
    },
    {
      type: 'projects',
      key: 'project',
    },
    {
      type: 'users',
      key: 'observer',
    },
    {
      type: 'users',
      key: 'notifiedSupervisor',
    },
    {
      type: 'users',
      key: 'closingSupervisor',
    },
    {
      type: 'doc-info',
      key: 'photoBefore',
    },
    {
      type: 'doc-info',
      key: 'photoAfter',
    },
    {
      type: 'site-observation-actions',
      key: 'actions',
      via: {
        table: 'site_observation_actions',
        pk: 'id',
        fk: 'observation'
      }
    }
  ],
}

export default siteObservations
