import { createSelector } from '@reduxjs/toolkit'
import { getResource, getResources } from '../../datastore'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { Task } from '../../datastore/models'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'

const selectTasksResources = selectResourcesMemo(['tasks', 'projects'])
const selectTasksParams = selectParamsMemo()

/**
 * Get array of available tasks for a given project and time range
 * 
 */
export const selectTasks = createSelector([selectTasksResources, selectTasksParams], (resources, params) => {

    const periodFilter = { attr: 'startDate', periodStart: params.periodStart, periodEnd: params.periodEnd }

    const eqFilter: { [key: string]: number } = {}
    if (params.projectId) {
        eqFilter.project = params.projectId
    }
    return getResources<Task>(resources, 'tasks', { periodFilter, eqFilter })
})

const selectTaskParams = selectParamsMemo()

/**
 * Get single task by id
 * 
 */
export const selectTask = createSelector([selectTasksResources, selectTaskParams], (resources, params) => {
    return getResource<Task>(resources.tasks, params.taskId)
})

