import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { requestResources } from '../../../datastore'
import { selectCompanies } from '../../../redux/selectors/companies'

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results 
 * 
 * @returns  requested resources 
 */
export const useReviewResources = () => {
    const dispatch = useAppDispatch()

    const permissions = useAppSelector(state => state.app.permissions)
    const isAdmin = useAppSelector(state => state.app.user?.isAdmin)
    const createCompanies = permissions.companyAttributes
        .filter(attribute => ['canCreateHealthAndSafety', 'canManageHealthAndSafety', 'canManageCompany'].includes(attribute.attribute))
        .map(attribute => +attribute.company)

    useEffect(() => {
        // if user is admin then createCompanies will be empty - just request all available companies
        dispatch(requestResources('companies', isAdmin?{}:{ inFilter: { id: createCompanies } }))
    }, [])

    return {
        availableCompanies: useAppSelector(state => selectCompanies(state, isAdmin?{}:{companyIds:createCompanies}))
    }
}