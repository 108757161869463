import React, { useEffect, useRef, useState } from "react"
import { IonButton, IonContent } from "@ionic/react"
import { Contact } from "../datastore/models/User"
import SignaturePad from "signature_pad"

import "./SignatureModal.css"

interface SignatureModalProps {
  title: string
  message: string
  contact: Contact
  sigData: string
  setSignature: (sigData: string) => void
  dismiss: () => void
}

const SignatureModal: React.FC<SignatureModalProps> = ({
  title,
  message,
  contact,
  sigData,
  setSignature,
  dismiss
}) => {
  const [signaturePad, setSignaturePad] = useState<SignaturePad>()

  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const canvasContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    // If device is rotated, close signature modal (need user to re-open it to resize canvas correctly)
    window.addEventListener("orientationchange", dismiss, false)
    return () => window.removeEventListener("orientationchange", dismiss, false)
  }, [])

  useEffect(() => {

    if (canvasRef.current && !signaturePad) {
      setSignaturePad(new SignaturePad(canvasRef.current))
    }

    if (canvasRef.current && canvasContainerRef.current) {
      const styles = getComputedStyle(canvasContainerRef.current)
      canvasRef.current.width =
        parseInt(styles.getPropertyValue("width"), 10) - 2
      canvasRef.current.height =
        parseInt(styles.getPropertyValue("height"), 10) - 2
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [canvasRef, canvasContainerRef])

  const undo = () => {
    const data = signaturePad?.toData()
    if (data) {
      data.pop() // remove the last dot or line
      signaturePad?.fromData(data)
    }
  }

  const save = () => {
    const data = signaturePad?.toData()
    if (data?.length) {
      const sigData = signaturePad?.toDataURL()
      if (sigData) {
        setSignature(sigData)
      }
    }
  }

  return (
    <IonContent
      id="signature-page"
      class="content-padding"
      force-overscroll="false"
    >
      <div className='safe-area-top'></div>
      <h1>{title}</h1>
      <p>{message}</p>
      <div className="signature-name-container">
        <h3>Signed By:</h3>
        <p>{`${contact.firstName} ${contact.lastName}`} </p>
      </div>
      <div className="signature-lower-container">
        <div ref={canvasContainerRef} className="signature-pad">
          <canvas ref={canvasRef} width="300" height="150"></canvas>
        </div>
        <div className="signature-button-container">
          <IonButton onClick={() => signaturePad?.clear()}>Clear</IonButton>
          <IonButton onClick={() => undo()}>Undo</IonButton>
          <IonButton onClick={() => save()}>Save</IonButton>
          <IonButton onClick={dismiss}>Cancel</IonButton>
        </div>
      </div>
    </IonContent>
  )
}

export default SignatureModal
