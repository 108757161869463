import { AppDispatch, RootState } from '../../redux/store'
import { updateSequence } from './siteObservationsSequence'
import { siteObservationReturnToToolbox } from './siteObservationsToolbox'
import { SiteObservationEvent } from './siteObservationsTypes'

/**
 * Called on any function that ends editing and returns user to start screen (i.e. close/notify/submit/cancel)
 * 
 * If in toolbox mode, returns to toolbox meeting
 */
export const returnToStart = () => (dispatch: AppDispatch, getState: () => RootState) => {

    const { toolboxMode, siteObservationEdit } = getState().pgSiteObservations

    if (toolboxMode) {        
        dispatch(siteObservationReturnToToolbox(siteObservationEdit.details?.id || null))
    }
    dispatch(updateSequence(SiteObservationEvent.START_SELECTED))

}