import { AppDispatch, RootState } from '../../redux/store'
import { saveResource } from '../../datastore'
import reportRequests from '../reportRequests'
import log from 'loglevel'

export const sendTaNotification = (taId: number) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    const user = getState().app.user

    const reportData = reportRequests.ta.get({ taEdit: true })(dispatch, getState)

    if (!reportData) {
        log.error(`sendTaNotification:could not get report data ta ${taId}`)
        return
    }

    dispatch(saveResource({
        company: reportData.ta.company,
        notificationType: 'TaskAnalysis',
        initiator: user?.id,
        recipients: reportData.ta.workers.map(worker => ({
            userId: worker.userId
        })),        
        userData: reportData,
    }, 'notifications'))
}