import { AppDispatch, RootState } from '../../../redux/store'
import { requestResourcesWithRelated } from '../../../datastore'
import { ResourceEvent } from '../../../datastore/types'
import { setRequestComplete, setRequestStart } from '../../../redux/slices/resourceMeta'

/**
 * Requests specified site observation with site, users, and action records
 * @param params { siteObservationId }
 * @returns 
 */
export const requestSiteObservation = (params: { [key: string]: any }) =>
    async (dispatch: AppDispatch, getState: () => RootState, services: any): Promise<ResourceEvent> => {
        const { siteObservationId, requestKey } = params

        if (requestKey) {
            dispatch(setRequestStart({ requestKey }))
        }

        const requestResult = await requestResourcesWithRelated('siteObservations', {
            eqFilter: { id: siteObservationId },
            include:['observer', 'notifiedSupervisor', 'closingSupervisor']
        },['sites', 'siteObservationActions'])(dispatch, getState, services)

        if (requestKey) {
            dispatch(setRequestComplete({ requestKey, resourceEvent: requestResult }))
        }
        return requestResult
    }
