import _ from "lodash";
import { ResourceFilters } from "../../../datastore/types";

export const setDynamicFilters = (filters: ResourceFilters | null | undefined, substitutions: { [key: string]: number | string | boolean | null | undefined }) => {

    if (!filters) {
        return
    }

    const substitute = (field: any) => {
        if (typeof field === 'string' && _.startsWith(field, '%') && _.endsWith(field, '%')) {
            return substitutions[_.trim(field, '%')]
        }
        return field
    }

    const newFilters = {
        ...filters
    }

    if (filters.eqFilter) {
        newFilters.eqFilter = Object.keys(filters.eqFilter).reduce((newFilter, key) => {
            return {
                ...newFilter,
                [key]: substitute(newFilter[key])
            }
        }, { ...filters.eqFilter })
    }

    if (filters.periodFilter) {
        newFilters.periodFilter = {
            attr: filters.periodFilter.attr,
            periodStart: substitute(filters.periodFilter.periodStart),
            periodEnd: substitute(filters.periodFilter.periodEnd)
        }
    }

    return newFilters
}

