import { IonButton, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding } from "@ionic/react"
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons"
import './SlidingItem.css'

interface SlidingItemProps {
    itemContent:any
    optionsContent:any
    lines?: "full" | "inset" | "none"
    slot?: string
}

/**
 * Sliding item - wraps typical IonItemSliding setup, but adds slide / unslide buttons which may be used as an 
 * alternative to actually sliding the control, and give user a visual cue that the sliding menu is there
 * 
 * @param itemContent - tsx expression to show in body of item
 * @param optionsContent - tsx expression for content of sliding options menu
 * 
 */

export const SlidingItem: React.FC<SlidingItemProps> = ({itemContent, optionsContent, lines, slot}) => {

    const slidingItemToggle = async (e: any) => {
        const thisEl = e.currentTarget as HTMLElement

        // Assumes the element generating the event is contained within either an IonItem or IonItemOptions,
        // which in turn is within an IonItemSliding
        const itemSlidingEl = thisEl.parentElement?.parentElement as HTMLIonItemSlidingElement
        if (itemSlidingEl && itemSlidingEl.nodeName === "ION-ITEM-SLIDING") {
            const openAmount = await itemSlidingEl.getOpenAmount()
            if (openAmount === 0) {
                itemSlidingEl.open('end')
            } else {
                itemSlidingEl.close()
            }
        }
    }

    return (
        <IonItemSliding className="sliding-item" slot={slot}>
            <IonItem 
                lines={lines}
                className="item-row-with-menu"                 
            >
                {itemContent}
                
                <IonButton
                    className="open-button"
                    fill="clear"
                    size="small"
                    onClick={(e) => slidingItemToggle(e)}
                >
                    <IonIcon icon={chevronForwardOutline}></IonIcon>
                </IonButton>
    
            </IonItem>

            <IonItemOptions className="options-container" side="end">
                <IonItemOption color="light" onClick={(e) => slidingItemToggle(e)} >
                    <IonIcon icon={chevronBackOutline} />
                </IonItemOption>
               {optionsContent}
            </IonItemOptions>

        </IonItemSliding>
    )
}