import { AppDispatch, RootState } from '../../redux/store'
import {
    setToolboxIncident,
    setToolboxObservation,
    setToolboxTa
} from '../../redux/slices/pgToolbox'

/**
 * Called from site observations page to signal to toolbox page that review is complete
 */
export const toolboxReviewObservationDone = (observation:{id:number, localId?:number}) => (dispatch: AppDispatch, getState: () => RootState) => {

    if (observation.id) {
        // Add the observation we've just reviewed / created to the current selection
        dispatch(setToolboxObservation({ observation, newState: true }))
    }
}

/**
 * Called from incident reports page to signal to toolbox page that review is complete
 */
export const toolboxReviewIncidentDone = (incident:{id:number, localId?:number}) => (dispatch: AppDispatch, getState: () => RootState) => {

    if (incident.id) {
        // Add the incident we've just reviewed / created to the current selection
        dispatch(setToolboxIncident({ incident, newState: true }))
    }
}

/**
 * Called from SWMS page to signal to toolbox page that a SWMS has been reviewed 
 */
export const toolboxReviewTaDone = (ta:{id:number, localId?:number}) => (dispatch: AppDispatch, getState: () => RootState) => {

    if (ta.id) {
        // Add the ta we've just reviewed / created to the current selection
        dispatch(setToolboxTa({ ta, newState: true }))
    }
}