import { addDays } from 'date-fns'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { selectIncidents } from '../../../redux/selectors/incidents'
import { requestIncidents } from './requestIncidents'

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results 
 * 
 * @returns  requested resources 
 */
export const useRegisterResources = (siteId: number | null) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(requestIncidents({ siteId, periodStart: addDays(new Date(), -30) }))
    }, [siteId])

    return {
        incidents: useAppSelector(state => selectIncidents(state, siteId ? { siteId } : {}))
    }

}