import { deleteResource, getResources } from '../../datastore'
import { TbAttendee, TbIncident, TbObservation, TbOther, TbTa, TbTask } from '../../datastore/models'
import TbSiteHazard from '../../datastore/models/TbSiteHazard'
import { deleteDoc } from '../../docstore/deleteDoc'
import { AppDispatch, RootState } from '../../redux/store'

export const deleteToolbox = (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const toolboxEdit = state.pgToolbox.toolboxEdit

    if (toolboxEdit.id) {

        getResources<TbAttendee>(state.resources, 'tbAttendees', { eqFilter: { toolbox: toolboxEdit.id } })
            .forEach(attendee => dispatch(deleteResource(attendee.id, 'tbAttendees')))

        getResources<TbSiteHazard>(state.resources, 'tbSiteHazards', { eqFilter: { toolbox: toolboxEdit.id } })
            .forEach(siteHazard => dispatch(deleteResource(siteHazard.id, 'tbSiteHazards')))

        getResources<TbObservation>(state.resources, 'tbObservations', { eqFilter: { toolbox: toolboxEdit.id } })
            .forEach(observation => dispatch(deleteResource(observation.id, 'tbObservations')))

        getResources<TbIncident>(state.resources, 'tbIncidents', { eqFilter: { toolbox: toolboxEdit.id } })
            .forEach(incident => dispatch(deleteResource(incident.id, 'tbIncidents')))

        getResources<TbTask>(state.resources, 'tbTasks', { eqFilter: { toolbox: toolboxEdit.id } })
            .forEach(task => dispatch(deleteResource(task.id, 'tbTasks')))

        getResources<TbTa>(state.resources, 'tbTas', { eqFilter: { toolbox: toolboxEdit.id } })
            .forEach(ta => dispatch(deleteResource(ta.id, 'tbTas')))

        getResources<TbOther>(state.resources, 'tbOther', { eqFilter: { toolbox: toolboxEdit.id } })
            .forEach(tbOther => {
                if (tbOther.photo) {
                    dispatch(deleteDoc(tbOther.photo))
                }
                dispatch(deleteResource(tbOther.id, 'tbOther'))
            })

        if (toolboxEdit.groupPhoto?.docId) {
            dispatch(deleteDoc(toolboxEdit.groupPhoto.docId))
        }
        dispatch(deleteResource(toolboxEdit.id, 'toolboxes'))
    }
}