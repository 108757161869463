import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { debounce } from 'lodash'

import reactors from './reactors'

import appReducer from './slices/app'
import tempReducer, { setAppTime } from './slices/temp'
import pgSigninReducer from './slices/pgSignin'
import pgTaskAnalysisReducer from './slices/pgTaskAnalysis'
import pgToolboxReducer from './slices/pgToolbox'
import pgSiteObservationsReducer from './slices/pgSiteObservations'
import pgIncidentReportsReducer from './slices/pgIncidentReports'
import pgDocumentsReducer from './slices/pgDocuments'
import resourcesReducer from './slices/resources'
import resourceMetaReducer from './slices/resourceMeta'
import docMetaReducer from './slices/docMeta'
import { persistMiddleware } from './persistence'

import api from '../api'

const rootReducer = combineReducers({
  // Capture date/time of every action - can be used to trigger time-based background processing
  //appTime: Date.now,
  app: appReducer,
  temp: tempReducer,
  pgSignin: pgSigninReducer,
  pgTaskAnalysis: pgTaskAnalysisReducer,
  pgToolbox: pgToolboxReducer,
  pgSiteObservations: pgSiteObservationsReducer,
  pgIncidentReports: pgIncidentReportsReducer,
  pgDocuments: pgDocumentsReducer,
  resources: resourcesReducer,
  resourceMeta: resourceMetaReducer,
  docMeta: docMetaReducer,
})

// Testing
// TODO: add this to services if it works
// !! Don't use; crashes safari
//networkState.init()

const services = {
  api: api
}

export const store = configureStore({
  reducer: rootReducer,  
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persistence/heldAction']
    },
    thunk: {
      extraArgument: services
    }
  })
  .concat(persistMiddleware)
  
})

export function setStoreSubscriptions() {

  // Subscribe 'Reactors' - these are selectors that automatically dispatch further actions based on specific state changes
  // (e.g. updating state machines)
  // Idea based on https://read.reduxbook.com/markdown/part2/10-reactive-state.html

  store.subscribe(() => {
    const state = store.getState()

    for (const reactor of reactors) {
      const reaction: any = reactor(state)
      if (reaction) {
        // requestIdleCallback used to prevent nested dispatch calls which may cause performance issues
        window.requestIdleCallback(() => {
          store.dispatch(reaction)
        })
      }
    }

    // a simple function that dispatches an idle action
    const idleDispatcher = () => {
      //store.dispatch({ type: 'APP_IDLE' })
      store.dispatch(setAppTime())
    }

    // create a version of the function that is
    // deBounced to 10 seconds
    const deBounced = debounce(idleDispatcher, 10000)

    // Now this will run *each time* something
    // is dispatched. But once it's been 30 seconds
    // since something has happened. It will cause
    // its *own* dispatch. Which then start the cycle
    // over again.
    store.subscribe(deBounced)    
  })
}

// Infer the `RootState` and `AppDispatch` types from the store itself
// Note must get RootState from rootReducer rather than directly from the store as this can result in a circular dependency
export type RootState = ReturnType<typeof rootReducer>
// Inferred type
export type AppDispatch = typeof store.dispatch