import React from 'react'
import { FieldError, FieldErrors, UseFormWatch, UseFormSetValue } from "react-hook-form"
import { IonTextarea } from '@ionic/react'
import { IonTextareaCustomEvent, TextareaChangeEventDetail } from '@ionic/core'
import { get } from 'lodash'

interface InputProps {
    id?: string
    label?: string
    fieldName: string
    inputMode?: "email" | "url" | "search" | "text" | "none" | "tel" | "numeric" | "decimal"
    placeholder?: string
    rows?: number
    watch: UseFormWatch<any>
    setValue: UseFormSetValue<any>
    errors?: FieldErrors // Use if whole 'errors' object passed 
    error?: FieldError // Use if just this field's error is passed (may need for array fields)
}

const TextArea: React.FC<InputProps> = ({ id, label, fieldName, inputMode, placeholder, rows, watch, setValue, errors, error }) => {

    const fieldError = errors && errors[fieldName] ? errors[fieldName] : error
    const value = watch(fieldName)

    const onChange = (event: IonTextareaCustomEvent<TextareaChangeEventDetail>) => {
        setValue(fieldName, get(event, 'currentTarget.value'), { shouldDirty: true })
    }

    return (
        <div id={id}>
            {label ? <span>{label}</span> : <></>}

            <IonTextarea
                inputmode={inputMode}
                style={fieldError ? { borderColor: "red" } : {}}
                class="oss-input"
                value={value}
                placeholder={placeholder}
                rows={rows}
                autoGrow={true}
                onIonInput={onChange}
            />

            <div className="oss-input-error-message">{fieldError?.message}</div>
        </div>
    )

}

export default TextArea