import { createSelector } from "@reduxjs/toolkit"
import Project from "../../datastore/models/Project"
import Site from "../../datastore/models/Site"
import { ProjectSite } from "../../datastore/models"
import { getResource, getResources } from "../../datastore"
import { selectParamsMemo } from "./helpers/selectParamsMemo"
import { selectResourcesMemo } from "./helpers/selectResourcesMemo"

export interface FlattenedProjectSite {
  id: number
  company: number
  projectId: number
  projectName: string
  siteId: number
  siteName: string
}

const selectProjectSitesResources = selectResourcesMemo([
  "companies",
  "projects",
  "sites",
  "projectSites"
])
const selectProjectSitesParams = selectParamsMemo()
export const selectProjectSites = createSelector(
  [selectProjectSitesResources, selectProjectSitesParams],
  (resources, params) => {
    const eqFilter: { [key: string]: number } = {}
    
    if (params.companyId) {
      eqFilter.company = params.companyId
    }

    if (params.siteId) {
      eqFilter.site = params.siteId
    }

    // If not selecting a specific project, filter to only open projects by default
    const inFilter = params.projectId
    ? { project: [params.projectId] }
    : { project: getResources<Project>(resources, "projects", { eqFilter:{status: params.status || 'OPEN'} }).map(project => project.id)}
    
    const projectSites = getResources<ProjectSite>(resources, "projectSites", {
      eqFilter,
      inFilter
    })

    return projectSites.map((projectSite) => {
      const project = getResource<Project>(
        resources.projects,
        projectSite.project
      )
      const site = getResource<Site>(resources.sites, projectSite.site)

      return {
        id: projectSite.id,
        company: projectSite.company,
        projectId: projectSite.project,
        projectName: project?.projectName || "",
        siteId: projectSite.site,
        siteName: site?.siteName || ""
      } as FlattenedProjectSite
    })
  }
)

const selectProjectSiteParams = selectParamsMemo()
export const selectProjectSite = createSelector(
  [selectProjectSitesResources, selectProjectSiteParams],
  (resources, params) => {
    const projectSite = getResource<ProjectSite>(
      resources.projectSites,
      params.projectSiteId
    )
    const project = getResource<Project>(
      resources.projects,
      projectSite?.project || 0
    )
    const site = getResource<Site>(resources.sites, projectSite?.site || 0)

    return projectSite && project && site
      ? ({
          id: projectSite.id,
          company: projectSite.company,
          projectId: projectSite.project,
          projectName: project?.projectName || "",
          siteId: projectSite.site,
          siteName: site?.siteName || ""
        } as FlattenedProjectSite)
      : null
  }
)

const selectOpenProjectSitesResources = selectResourcesMemo([
  "projects",
  "projectSites"
])

export const selectOpenProjectSites = createSelector(
  [selectOpenProjectSitesResources],
  (resources) => {
    const projects = getResources<Project>(resources, "projects", {
      eqFilter: { status: "OPEN" }
    })
    const projectIds = projects.map((project) => project.id)
    const projectSites = getResources<ProjectSite>(resources, "projectSites", {
      inFilter: { project: projectIds }
    })

    return projectSites
  }
)
