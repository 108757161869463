import { AppDispatch, RootState } from '../../redux/store'
import { setSiteObservationDetails } from '../../redux/slices/pgSiteObservations'
import { setAlert } from '../../utils/alert'
import { returnToStart } from './returnToStart'
import { saveObservation } from './saveObservation'

export const closeObservation = (isObserver: boolean, isSupervisor: boolean) => (dispatch: AppDispatch, getState: () => RootState) => {
    const { siteObservationEdit: { actions } } = getState().pgSiteObservations

    if (actions.filter(action => !action.deleted && !action.completedDate).length) {
        dispatch(setAlert(
            'Not all actions completed',
            'Before closing out the observation, you need to record the date that each action was completed'
        ))
        return
    }

    dispatch(setAlert(
        'Close Out Observation',
        'By continuing, you are declaring that, to the best of your knowledge, the hazard has been made safe and no further action is required',
        [{
            text: 'Cancel',
            role: 'cancel',
            handler: () => true
        },
        {
            text: 'Continue',
            handler: () => {

                if (isObserver) {
                    dispatch(setSiteObservationDetails({ observerClosedDate: new Date().toISOString() }))
                }
                if (isSupervisor) {
                    dispatch(setSiteObservationDetails({ supervisorClosedDate: new Date().toISOString() }))
                }

                ; (async () => {
                    // Need to await save, following processes read saved version
                    await saveObservation()(dispatch, getState)
                    dispatch(returnToStart())
                })()
                return true
            }
        }]
    ))
}