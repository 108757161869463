import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { requestResources } from '../../../datastore'
import { selectActiveTas } from '../../../redux/selectors/tas'
import { addDays } from 'date-fns'

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results 
 * 
 * @returns  requested resources 
 */
export const useActiveResources = (siteId: number | null) => {
    const dispatch = useAppDispatch()

    const periodStart = addDays(new Date(), -7)    

    useEffect(() => {
        dispatch(requestResources('tas', {
            neqFilter: { draft: true, superseded: true },
            periodFilter: {attr: 'startDate', periodStart},            
        }))
    }, [])

    return {
        activeTas: useAppSelector(state => selectActiveTas(state, { periodStart, siteId }))
    }
}