import { NormalizedData } from '@disruptph/json-api-normalizer'
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import Axios, { AxiosError } from 'axios'
import { deNormalize } from '../datastore/json-api/helpers/denormalize'
import { getApiRoot } from '../helpers/getApiRoot'
import { ApiResult } from '../interfaces/api'
import { getAccessToken } from './accessToken'
import { updateOnlineStatus } from './onlineStatus'

export async function addLogEntry(logEntry: NormalizedData) {

    const denormalized = deNormalize(logEntry)
    if (!denormalized) {
        return {
            result: ApiResult.CLIENT_ERROR,
        }
    }

    try {
        await Axios.post(`${getApiRoot()}log/app-log`, denormalized, {
            headers: {
                'Authorization': `Bearer ${getAccessToken()}`,
                'content-type': 'application/vnd.api+json'
            }
        })
        updateOnlineStatus(ApiResult.SUCCESS)
        return {
            result: ApiResult.SUCCESS
        }
    } catch (err: any | AxiosError) {
        if (Axios.isAxiosError(err) && err.response) {
            // API responded with error code
            if (err.response.status === 401) {
                // We will get 401 unauthorized if the token is missing or invalid                
                return { result: ApiResult.AUTH_ERROR }

            } else if (err.response.status >= 400 && err.response.status <= 499) {
                return { result: ApiResult.CLIENT_ERROR }
            }
            return { result: ApiResult.SERVER_ERROR }

        } else {
            // Request failed or no response from API
            // Probably means backend is down or no internet connection
            updateOnlineStatus(ApiResult.NETWORK_ERROR)
            return { result: ApiResult.NETWORK_ERROR }
        }
    }
}