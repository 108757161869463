import { IonButton, IonContent, IonIcon, IonItem, IonItemDivider, IonItemOption, IonLabel, IonList } from '@ionic/react'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { selectPpeTypes } from '../../redux/selectors/ppeTypes'
import { setTaCustomPpe, setTaPpe } from '../../redux/slices/pgTaskAnalysis'
import TextArea from '../../components/form/TextArea'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import './SelectPpe.css'
import { genericTextRegex } from '../../helpers/regex'
import { useRef } from 'react'
import { SlidingItem } from '../../components/SlidingItem'
import { selectTaEdit } from '../../redux/selectors/tas'
import TaskAnalysisNav from './TaskAnalysisNav'
import TaskHeading from './TaskHeading'
import { useHomeIconHandler } from '../../hooks/homeIconHandler'
import { homeIconAction } from '../../features/taskAnalysis/homeIconAction'
import { checkmark } from 'ionicons/icons'

type Inputs = {
    ppeText: string
}

const schema = yup.object().shape({
    ppeText: yup.string()
        .matches(genericTextRegex, { message: 'Invalid characters', excludeEmptyString: true })
        .max(100, 'Too many characters'),
}).required()

export const SelectPpe: React.FC = () => {
    const dispatch = useAppDispatch()
    const taEdit = useAppSelector(selectTaEdit)
    const ppeTypes = useAppSelector(selectPpeTypes)

    const { handleSubmit, watch, setValue, formState: { errors, isDirty } } = useForm<Inputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            ppeText: ''
        }
    });

    const togglePpeType = (ppeTypeId: number) => {
        if (!taEdit.userData.ppe.includes(ppeTypeId)) {
            dispatch(setTaPpe(taEdit.userData.ppe.concat([ppeTypeId])))
        } else {
            dispatch(setTaPpe(taEdit.userData.ppe.filter(ppeType => ppeType !== ppeTypeId)))
        }
    }

    const formSubmit = (data: any) => {
        if (isDirty) {
            dispatch(setTaCustomPpe(taEdit.userData.customPpe.concat([data.ppeText])))
        }
        setValue('ppeText', '')
    }

    const listRef = useRef<HTMLIonListElement>(null)

    const deleteCustomPpe = (index: number) => {
        listRef?.current?.closeSlidingItems()
        dispatch(setTaCustomPpe(taEdit.userData.customPpe.filter((item, i) => i !== index)))
    }

    useHomeIconHandler('/task-analysis', () => dispatch(homeIconAction()))

    return (
        <>
            <IonContent className="content-padding">
                <TaskHeading />
                <IonItemDivider />
                <h3 id="step-name">Select PPE to be used for this Task</h3>
                <IonItemDivider />
                <IonList ref={listRef} id="ppe-list">
                    {ppeTypes.map(ppeType => {
                        const isSelected = taEdit.userData.ppe.includes(ppeType.id)
                        return (
                            <IonItem
                                className={isSelected ? 'ppe-item-selected' : 'ppe-item'}
                                key={ppeType.id}
                                onClick={() => togglePpeType(ppeType.id)}
                            >
                                <div>
                                    <img
                                        className="ppe-icon"
                                        alt={isSelected ? 'Selected PPE Item' : 'Deselected PPE Item'}
                                        src={`./assets/ui_images/${isSelected ? 'ppe-orange/' : ''}${ppeType.icon}`}
                                    />
                                    <IonLabel>{ppeType.name}</IonLabel>
                                    {isSelected ? <IonIcon icon={checkmark} size="large" /> : <></>}
                                </div>

                            </IonItem>
                        )
                    }
                    )}
                    {taEdit.userData.customPpe.map((ppeItem, i) => {

                        return (

                           <SlidingItem
                                key={i}
                                itemContent={<span>{ppeItem}</span>}
                                optionsContent={<IonItemOption color="danger" onClick={() => deleteCustomPpe(i)}>Delete</IonItemOption>}
                           />
                        )
                    })}
                </IonList>

                <form onSubmit={handleSubmit(formSubmit)} id="custom-hazard-entry">
                    <h2 id="not-orange">Add a PPE Item:</h2>
                    <div>
                        <TextArea
                            fieldName="ppeText"
                            inputMode="text"
                            rows={1}
                            watch={watch}
                            setValue={setValue}
                            errors={errors}
                        />
                        {/* NOTE: Missing check for text to be entered */}
                        <IonButton type="submit" onClick={() => { }}>Add</IonButton>
                    </div>
                </form>

            </IonContent>
            <TaskAnalysisNav />
        </>
    )
}

