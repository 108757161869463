import { AppDispatch, RootState } from "../../redux/store"
import { setSelectedDocIndex, setSelectedDocTitle } from "../../redux/slices/pgSignin"

export const selectPreviousSigninDoc = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { docList, selectedDocIndex } = getState().pgSignin

    if (selectedDocIndex !== null) {
        if (selectedDocIndex > 0) {
            dispatch(setSelectedDocIndex(selectedDocIndex - 1))
            dispatch(setSelectedDocTitle(docList[selectedDocIndex - 1].title))
        }
    }
}