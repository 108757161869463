import log from "loglevel"
import { isAfter } from "date-fns"

import { AppDispatch, RootState } from "../../redux/store"
import { setNextRefreshTime } from "../../redux/slices/temp"
import { ApiService } from "../../interfaces/api"
import { authRefreshToken } from "../../services/Auth"

const CHECK_INTERVAL = 60000

export const startTokenRefresh = (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const api: ApiService = services.api
    setTimeout(doTokenRefresh(dispatch, getState, api), CHECK_INTERVAL)
}

export const setNextTokenRefresh = (expiresInSeconds?: number) => (dispatch: AppDispatch, getState: () => RootState) => {
    const refreshSeconds = expiresInSeconds ? expiresInSeconds - 300 : 1800
    dispatch(setNextRefreshTime(new Date().getTime() + refreshSeconds * 1000))
}

const doTokenRefresh = (dispatch: AppDispatch, getState: () => RootState, api: ApiService) => async () => {
    try {
        const state = getState()

        if (state.app.user && api.hasToken() && isAfter(new Date(), new Date(state.temp.nextRefreshTime))) {
            const tokenResponse = await authRefreshToken()
            if (tokenResponse?.accessToken) {
                api.setAccessToken(tokenResponse.accessToken)
                dispatch(setNextTokenRefresh(tokenResponse.expiresIn))
            }
        }
    } catch (err) {
        log.info(`Token refresh failed:${err}`)
    }

    setTimeout(doTokenRefresh(dispatch, getState, api), CHECK_INTERVAL)
}