const tbObservations = {
  table: 'tb_observations',
  idKey: 'id',

  attrs: [
    'creationDate',
    'lastModifiedDate',
    'deleted'
  ],

  relationships: [   
    {
      type: 'companies',
      key: 'company',
    }, 
    {
      type: 'toolboxes',
      key: 'toolbox',
    },
    {
      type: 'site-observations',
      key: 'observation',
    }
  ],
}

export default tbObservations