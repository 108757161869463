import { createSelector } from "@reduxjs/toolkit"
import { selectParamsMemo } from "./helpers/selectParamsMemo"
import { getResource, getResources } from "../../datastore"
import {
  Control,
  HazardCategory,
  HazardType,
  SiteHazard
} from "../../datastore/models"
import { selectResourcesMemo } from "./helpers/selectResourcesMemo"

const selectResources = selectResourcesMemo([
  "sites",
  "siteHazards",
  "hazardCategories",
  "hazardTypes",
  "controls"
])
const selectParams = selectParamsMemo()

export type SiteHazardWithControls = {
  id: number
  categoryText: string
  hazardText: string
  hazardTextShort: string
  hazardTypeDetail: string
  detail: string
  controls: {
    controlText: string
    controlTextShort: string
    controlTypeDetail: string
    detail: string | undefined
  }[]
}

/**
 * 'site hazard report' selector - used to populate site induction page etc.
 * Expands raw record data to include related resources as required, i,e. hazard / control texts
 *
 */
export const selectSiteHazardReport = createSelector(
  [selectResources, selectParams],
  (resources, params) => {
    const siteHazards = params.siteIds
      ? getResources<SiteHazard>(resources, "siteHazards", {
          inFilter: { site: params.siteIds }
        })
      : getResources<SiteHazard>(resources, "siteHazards", {
          eqFilter: { site: params.siteId }
        })

    return siteHazards.reduce((acc:SiteHazardWithControls[], hazard: SiteHazard) => {
      let categoryText = "Custom"
      let hazardText = ""
      let hazardTextShort = ""
      let hazardTypeDetail = ""
      if (hazard.hazardType) {
        const hazardType = getResource<HazardType>(
          resources.hazardTypes,
          hazard.hazardType
        )
        if (hazardType) {
          hazardText = hazardType.hazardText
          hazardTextShort = hazardType.hazardTextShort || hazardType.hazardText
          hazardTypeDetail = hazardType.detail || ""
          categoryText =
            getResource<HazardCategory>(
              resources.hazardCategories,
              hazardType.category
            )?.categoryText || ""
        }
      } else {
        hazardText = hazard.customHazard || ""
        hazardTextShort = hazardText
      }

      if (
        acc.find(
          (existing) =>
            (existing.hazardText &&
              existing.hazardText === hazardText)
        )
      ) {
        // We've already foud a site hazard that matches either the hazard type or custom hazard name of this one
        // -> discard so we don't return duplicates
        //
        // Normal hazard list for a site will not contain duplicates - this will only occur if we're collecting
        // hazards across a group of sites for an 'All Projects' toolbox meeting.
        //
        // This may remove siteHazards that are the same hazard but with different controls - not ideal but will
        // do to keep the toolbox page manageable
        return acc
      }
      return acc.concat([
        {
          id: hazard.id,
          categoryText,
          hazardText,
          hazardTextShort,
          hazardTypeDetail,
          detail: hazard.detail,
          controls: hazard.controls.map((control) => {
            let controlText = ""
            let controlTextShort = ""
            let controlTypeDetail = ""
            if (control.control) {
              const controlType = getResource<Control>(
                resources.controls,
                control.control
              )
              if (controlType) {
                controlText = controlType.controlText
                controlTextShort =
                  controlType.controlTextShort || controlType.controlText
                controlTypeDetail = controlType.detail || ""
              }
            } else {
              controlText = control.customControl || ""
              controlTextShort = controlText
            }
            return {
              controlText,
              controlTextShort,
              controlTypeDetail,
              detail: control.detail
            }
          })
        }
      ])
    }, [])
  }
)
