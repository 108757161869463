import { getLogger } from "loglevel"
import { get, isEmpty } from "lodash"

import { AppDispatch, RootState } from "../redux/store"
import { ApiService, ApiResult } from "../interfaces/api"
import { logout } from "../features/auth/"
import { ResourceEvent } from "./types"
import { processReceivedResources } from "./helpers/processReceivedResources"
import {
  removeRequest,
  setRequestComplete,
  setRequestStart,
  updateResourceStatus
} from "../redux/slices/resourceMeta"
import { buildResourcesRequest } from "./json-api/helpers/buildResourceRequest"
import { getNextId } from "./getNextId"
import { ResourceFilters } from "./types"
import { ResourceType } from "../redux/slices/resources"
import { idMapFilters } from "./helpers/idMapFilters"
import { cacheAdjustFilters } from "./helpers/cacheAdjustFilters"
import { autoRequestRelatedResources } from "./helpers/autoRequestRelatedResources"
import { differenceInSeconds, parseJSON } from "date-fns"
import { NormalizedData } from "@disruptph/json-api-normalizer"
import { processChangeDetectedResources } from "./helpers/processChangeDetectedResources"
import { setLastRequestedDate } from "./helpers/setLastRequestedDate"
import { requestIsDuplicate } from "./helpers/requestIsDuplicate"
import { selectRequestInProgress } from "../redux/selectors/resourceMeta"
import { pendingRequestResult } from "./requestResultSubscription"

const log = getLogger("resourceRequests")

export interface RequestResourcesOptions extends ResourceFilters {
  requestKey?: string
  include?: string[]
}

/**
 * Master 'request resources' function, called by all other resource - specific request functions
 */

export const requestResources =
  (resourceType: ResourceType, opts: RequestResourcesOptions) =>
    async (
      dispatch: AppDispatch,
      getState: () => RootState,
      services: any
    ): Promise<ResourceEvent> => {
      const api: ApiService = services.api

      // If caller supplied a request key, use it, otherwise use the next free localId
      const requestKey =
        opts.requestKey || getNextId(dispatch, getState).toString()

      // console.log('requestResources setRequestStart-requestKey:', requestKey, 'resourceType:', resourceType)

      // Find any localIds in filters, and map them (if possible) to real ids
      const mappedOpts = idMapFilters(resourceType, opts, getState)

      if (!mappedOpts) {
        // Filters reference local ids that don't map to real ids - whatever is being requested is locally generated and
        // has not yet been written to server. Don't make any request to api, just return 'RESOURCE_VALID'

        log.debug(`requestResources:requested ${resourceType}, localIds only`)

        if (opts.requestKey) {
          dispatch(setRequestStart({ requestKey }))
          dispatch(
            setRequestComplete({
              requestKey,
              resourceEvent: ResourceEvent.RESOURCE_VALID
            })
          )
        }
        return ResourceEvent.RESOURCE_VALID
      }

      const resourceStatus =
        getState().resourceMeta.resourceUpdateStatus[resourceType]

      // Test whether this request is for specific resources that have already been requested very recently - if so return 'RESOURCE_VALID'
      // without making the request      
      // TODO: review whether this should use resourceStatus.minRefreshSeconds (or should that only apply to 'auto refresh' type requests)
      if (
        requestIsDuplicate(
          resourceType,
          mappedOpts,
          resourceStatus?.minRefreshSeconds || 1,
          getState
        )
      ) {
        log.debug(
          `requestResources:successive requests to ${resourceType} within ${resourceStatus?.minRefreshSeconds || 1}s, not sent to api`
        )

        if (opts.requestKey) {
          dispatch(setRequestStart({ requestKey }))
          dispatch(
            setRequestComplete({
              requestKey,
              resourceEvent: ResourceEvent.RESOURCE_VALID
            })
          )
        }
        return ResourceEvent.RESOURCE_VALID
      }

      // Test whether this request is identical to one that's already in progress - if so don't make a new request, just wait for the 
      // result of the initial one
      const pendingRequestKey = selectRequestInProgress(getState(), { resourceType, opts })
      if (pendingRequestKey) {
        return await pendingRequestResult(pendingRequestKey)
      }

      // Signal that request is being started
      // 'opts' is used by de-duping logic to determine when the same request is made multiple times
      // - needs to be stringified in case it contains dates which can't be stored in redux
      dispatch(
        setRequestStart({
          requestKey,
          resourceType,
          opts: JSON.stringify(mappedOpts)
        })
      )
      // Record the current 'request version' for this request key
      // (this will tell us if the same request gets made again before this one completes)
      // TODO: review if we need this? Only applicable to explicitly named requests which we may move away from
      const requestVersion = get(
        getState().resourceMeta,
        ["activeRequests", requestKey, "version"],
        0
      )

      // Modify request filters based on what we've previously requested, to avoid re-requesting the same data
      const { canUpdateCache, effectiveFilter, adjustedOpts } =
        cacheAdjustFilters(resourceType, mappedOpts, getState)

      if (canUpdateCache) {
        if (
          resourceStatus?.lastUpdated &&
          resourceStatus?.minRefreshSeconds &&
          differenceInSeconds(new Date(), parseJSON(resourceStatus.lastUpdated)) <
          resourceStatus.minRefreshSeconds &&
          (resourceStatus.lastUpdateResult === ResourceEvent.NO_RESOURCE_FOUND ||
            resourceStatus.lastUpdateResult === ResourceEvent.RESOURCE_VALID)
        ) {
          // If resource has already been successfully updated within the last minRefreshSeconds then don't request again,
          // just return 'RESOURCE_VALID'

          log.debug(
            `requestResources:successive requests to ${resourceType} within ${resourceStatus?.minRefreshSeconds}s, not sent to api`
          )

          dispatch(
            setRequestComplete({
              requestKey,
              resourceEvent: ResourceEvent.RESOURCE_VALID
            })
          )
          if (!opts.requestKey) {
            dispatch(removeRequest(requestKey))
          }
          return ResourceEvent.RESOURCE_VALID
        }
      }

      const requestString = buildResourcesRequest(resourceType, adjustedOpts)

      let { result, resources } = await requestResourceApi(
        dispatch,
        getState,
        api,
        requestString
      )

      let processResult
      if (resources && result === ResourceEvent.RESOURCE_VALID) {
        // Request succeeded...

        if (adjustedOpts.modifiedSince) {
          // This is an incremental request...
          const changeDetectFilter = get(getState().resourceMeta, [
            "resourceUpdateStatus",
            resourceType,
            "changeDetectFilter"
          ])
          if (canUpdateCache && changeDetectFilter) {
            // Request was made using a 'changeDetectFilter', i.e. response may contain items that are outside our scope of interest
            // but which we may need to detect changes
            // -> process accordingly
            const autoRequestFilter = get(getState().resourceMeta, [
              "resourceUpdateStatus",
              resourceType,
              "autoRequestFilter"
            ])
            processResult = processChangeDetectedResources(
              resources,
              resourceType,
              autoRequestFilter,
              dispatch,
              getState
            )
          } else {
            processResult = processReceivedResources(
              resources,
              dispatch,
              getState
            )
          }
        } else {
          // This is a non-incremental request, i.e. we requested the whole range of data we're interested in (not just modified items)
          processResult = processReceivedResources(resources, dispatch, getState)
          // -> remove anything in store that no longer exists on server
          // !! not doing this as it can accidentally remove resources read via 'include' if we don't have direct read permissions
          // Shouldn't be needed:
          // - resources deleted on server should be picked up by 'deleted' flags
          // - resources that have gone out of our scope due to attribute change should be picked up by 'changeDetectFilter'?

          // purgeResources(resources, resourceType, adjustedOpts, dispatch, getState)
        }
      }

      if (
        result === ResourceEvent.NO_RESOURCE_FOUND ||
        result === ResourceEvent.RESOURCE_VALID
      ) {
        if (canUpdateCache) {
          const mostRecentModified = get(processResult, [
            resourceType,
            "mostRecentModified"
          ])

          dispatch(
            updateResourceStatus({
              resourceType,
              resourceEvent: result,
              mostRecentModified: mostRecentModified
                ? mostRecentModified.toISOString()
                : undefined
            })
          )

          // If auto-updating this resource type should auto-update related types, do so
          const autoRequestRelated =
            getState().resourceMeta.resourceUpdateStatus[resourceType]
              ?.autoRequestRelated
          if (autoRequestRelated) {
            dispatch(
              autoRequestRelatedResources(resourceType, opts, autoRequestRelated)
            )
          }
        }

        if (adjustedOpts.modifiedSince) {
          // This is an incremental request:
          // - we can change result to 'resource valid' - there haven't been any changes
          //  but there must have been an existing version
          result = ResourceEvent.RESOURCE_VALID
        }

        // set 'lastRequestedDate' to now for every resource in store that falls within request's range of interest
        setLastRequestedDate(resourceType, effectiveFilter, dispatch, getState)
      } else {
        log.debug(`requestResources:error requesting ${resourceType} from api`)

        dispatch(
          updateResourceStatus({
            resourceType,
            resourceEvent: result
          })
        )
      }

      // Check whether request version is the same as before making the request.
      // If not, then another request with the same requestKey was started after this one
      // -> in this case don't call setRequestComplete because we want calling process to see the result
      // of the more recent request
      if (
        requestVersion ===
        get(getState().resourceMeta, ["activeRequests", requestKey, "version"], 0)
      ) {
        dispatch(setRequestComplete({ requestKey, resourceEvent: result }))
      } else {
        log.debug(
          "requestResources: request key",
          requestKey,
          " superceded by newer version"
        )
      }

      if (!opts.requestKey) {
        dispatch(removeRequest(requestKey))
      }
      return result
    }

const requestResourceApi = async (
  dispatch: AppDispatch,
  getState: () => RootState,
  api: ApiService,
  requestString: string
): Promise<{ result: ResourceEvent; resources?: NormalizedData }> => {
  if (!api.isOnline() || !api.hasToken()) {
    return { result: ResourceEvent.RESOURCE_LOAD_ERROR }
  }

  // console.log(`api.apiGetResource:${requestString}`)

  const getResourceResult = await api.apiGetResource(requestString)

  if (getResourceResult!.result === ApiResult.AUTH_ERROR) {
    log.warn("requestResourceApi: token invalid or expired - user automatically logged out")
    dispatch(logout())
  }

  if (getResourceResult!.result !== ApiResult.SUCCESS) {
    return { result: ResourceEvent.RESOURCE_LOAD_ERROR }
  }

  const resources = getResourceResult.responseData
  if (resources && !isEmpty(resources)) {
    // Signal that loaded resource is valid to use
    return {
      result: ResourceEvent.RESOURCE_VALID,
      resources
    }
  } else {
    return { result: ResourceEvent.NO_RESOURCE_FOUND }
  }
}
