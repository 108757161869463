import { useEffect } from 'react'
import { useIonModal, UseIonModalResult } from '@ionic/react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { SelectList } from '../../components/SelectList'
import { notifyObservation } from '../../features/siteObservations/notifyObservation'
import { useRequestSupervisorsBySite } from '../../features/commonResourceRequests/useRequestSupervisorsBySite'

interface Props {
    setModalFns: (useIonModalResult: UseIonModalResult) => void
}

export const SelectSupervisorModal: React.FC<Props> = ({
    setModalFns
}) => {
    const dispatch = useAppDispatch()
    const { siteObservationEdit } = useAppSelector(state => state.pgSiteObservations)
    const { supervisors } = useRequestSupervisorsBySite(siteObservationEdit.details.site)

    const modalOk = (userId: number | null) => {
        dismissModal()
        if (userId) {
            dispatch(notifyObservation(userId))
        }
    }

    const modalCancel = () => {
        dismissModal()
    }

    const [presentModal, dismissModal] = useIonModal(SelectList, {
        title: 'Select the supervisor to notify:',
        listData: supervisors?.map(user => ({ text: `${user.firstName} ${user.lastName}`, id: user.id })) || [],
        selectOption: modalOk,
        dismiss: modalCancel,
        padTop: true
    })

    useEffect(() => {
        setModalFns([presentModal, dismissModal])
    }, [])

    return (<></>)
}