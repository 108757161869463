/**
 * Check whether an array of resource ids contains a given resource.
 * 
 * If list contains ids of locally - generated resources, values may be local IDs or 'real' ids - values need to be 
 * checked against both
 * @param list - list of resource ids
 * @param resource - resource object to look for, may be LocalResourceData or any flattened resource object (i.e. with id and / or localId attributes)
 * @returns 
 */
 export const listIncludesResource = (list:number[], resource:any) => {
    if (!list || !Array.isArray(list) || !(typeof resource === 'object')) {
        return false
    }
    return (resource.id && list.includes(+resource.id)) || (!isNaN(resource.localId) && list.includes(resource.localId))
}