import { AppDispatch, RootState } from "../../../redux/store"
import { useAppSelector } from "../../../redux/hooks"
import { requestResources } from "../../../datastore"
import { selectTbOther } from "../../../redux/selectors/tbOther"
import { useTask } from "../../../hooks/useTask"
import { ResourceEvent } from "../../../datastore/types"

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results
 *
 */
export const useOtherBusinessResources = (
  toolboxId: number | null,
  refresh?: any
) => {
  const taskResult = useTask(
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      const result = await requestResources("tbOther", {
        eqFilter: { toolbox: toolboxId }
      })(dispatch, getState, services)

      return {
        isSuccess: result !== ResourceEvent.RESOURCE_LOAD_ERROR
      }
    },
    [toolboxId, refresh],
    !!toolboxId
  )

  return {
    tbOtherItems: useAppSelector((state) =>
      selectTbOther(state, { toolboxId })
    ),
    isLoading: taskResult.isLoading,
    isSuccess: taskResult.isSuccess,
    isError: taskResult.isError
  }
}
