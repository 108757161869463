import { IonHeader, IonPage } from "@ionic/react"

import React from "react"

import NavBar from "../../components/NavBar"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"

import { useModalManager } from "../../hooks/modalManager"

import { Start } from "./Start"
import { Register } from "./Register"
import { Details } from "./Details"
import { Actions } from "./Actions"
import { Review } from "./Review"

import { stSITE_OBSERVATIONS } from "../../features/siteObservations/siteObservationsTypes"
import { SelectSupervisorModal } from "./SelectSupervisorModal"
import { setHomeIconEvent } from "../../redux/slices/temp"

const SiteObservations: React.FC = () => {
  const dispatch = useAppDispatch()

  const { stSiteObservations, toolboxMode } = useAppSelector(
    (state) => state.pgSiteObservations
  )
  const currentPath = useAppSelector((state) => state.temp.currentPath)

  const { setModalFns, presentModal } = useModalManager()

  function renderSwitch() {
    switch (stSiteObservations) {
      case stSITE_OBSERVATIONS.START:
        return <Start />

      case stSITE_OBSERVATIONS.REGISTER:
        return <Register />

      case stSITE_OBSERVATIONS.DETAILS:
        return <Details presentModal={presentModal} />

      case stSITE_OBSERVATIONS.ACTIONS:
        return <Actions presentModal={presentModal} />

      case stSITE_OBSERVATIONS.REVIEW:
        return <Review />
    }
  }

  return (
    <IonPage className="normal-page">
      <IonHeader>
        <NavBar
          pageTitle="Site Observations"
          iconPath={
            toolboxMode ? "/assets/ui_images/speech_bubble.svg" : undefined
          }
          homeAction={() => dispatch(setHomeIconEvent(true))}
        />
      </IonHeader>

      {renderSwitch()}

      {/* The following component sets up useModal hooks and then calls 'setModalFns' to give us the corresponding 'present' and 'dismiss' fns */}
      {currentPath === "/observations" ? (
        <SelectSupervisorModal setModalFns={setModalFns("selectSupervisor")} />
      ) : (
        <></>
      )}
    </IonPage>
  )
}

export default SiteObservations
