import { IonCard, IonCol, IonContent, IonGrid, IonRow } from "@ionic/react"
import { useAppDispatch, useAppSelector } from '../../redux/hooks'

import { createObservation } from "../../features/siteObservations/createObservation"
import { useHomeIconHandler } from "../../hooks/homeIconHandler"
import { homeIconAction } from "../../features/siteObservations/homeIconAction"
import { updateSequence } from "../../features/siteObservations/siteObservationsSequence"
import { SiteObservationEvent } from "../../features/siteObservations/siteObservationsTypes"
import { selectCanCreateAnyObservation } from "../../redux/selectors/abilities"

import './SiteObservations.css'
import './Start.css'
import './SiteObservationsNav.css'
import { useStartResources } from "../../features/siteObservations/resourceRequests/useStartResources"

export const Start: React.FC = () => {
    const dispatch = useAppDispatch()
    const canCreateObservation = useAppSelector(state => selectCanCreateAnyObservation(state))
    const currentPath = useAppSelector(state => state.temp.currentPath)

    useStartResources(currentPath === '/observations')

    const startNewSelected = () => {
        dispatch(createObservation())
    }

    useHomeIconHandler('/observations', () => dispatch(homeIconAction()))

    return (
        <>
            <IonContent class="content-padding">
                <IonGrid fixed={true} className="app-grid">
                    <IonRow>
                        <IonCol>
                            <div id="observation-start-grid">
                                <IonCard
                                    button={true}
                                    onClick={startNewSelected}
                                    disabled={!canCreateObservation}
                                >
                                    <div>
                                        <span>Make a NEW</span>
                                        <span>Site Observation</span>
                                    </div>
                                </IonCard>
                                <IonCard button={true} onClick={() => dispatch(updateSequence(SiteObservationEvent.REGISTER_SELECTED))}>
                                    <div>
                                        <span>View Existing</span>
                                        <span>Observations</span>
                                    </div>
                                </IonCard>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </>)
}