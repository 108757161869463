const taWorkers = {
    table: 'ta_workers',
    idKey: 'id',
  
    attrs: [      
      'signature', 
      'isSupervisor',
      'creationDate',
      'lastModifiedDate',   
      'deleted'   
    ],
  
    relationships: [   
      {
        type: 'tas',
        key: 'ta',
      },
      {
        type: 'companies',
        key: 'company',
      },
      {
        type: 'users',
        key: 'user',
      }
    ],
  }
  
  export default taWorkers