import { AppDispatch, RootState } from "../../../redux/store"

import { requestDocs } from "../../../docstore/requestDocs"

import { ResourceEvent } from "../../../datastore/types"
import { useTask } from "../../../hooks/useTask"
import log from "loglevel"

const docPaths = ['company', 'site', 'site/msds']

/**
 * 'useResources' hook
 * This hook requests documents from back end that user is allowed to view
 * (useDocument hook is used to retrieve the document actually being viewed from the store)
 */

export const useRequestDocumentsBySite = (companyId: number | null, siteId: number | null, pageActive: boolean) => {
    const taskResult = useTask(
        async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

            const requestDocsResult = await requestDocs({
                eqFilter: { showInApp: true },
                inFilter: { company: [companyId, 1], path: docPaths }
            })(dispatch, getState, services)

            if (requestDocsResult !== ResourceEvent.RESOURCE_VALID) {
                log.debug(`useRequestDocumentsBySite request returned ${requestDocsResult}`)
            }

            return {
                isSuccess: true
                // Note - task would normally return data here, however in this case page
                // reads docInfo records directly using selectFolderContents (could combine?)
            }
        }, [companyId, siteId], pageActive)

    return {
        isLoading: taskResult.isLoading,
        isSuccess: taskResult.isSuccess,
        isError: taskResult.isError,
    }
}