const incidentActions = {
  table: 'incident_actions',
  idKey: 'id',

  attrs: [  
    'actionText',    
    'verifiedText',    
    'creationDate',
    'lastModifiedDate',
    'deleted'
  ],

  relationships: [    
    {
      type: 'companies',
      key: 'company',
    },    
    {
      type: 'incidents',
      key: 'incident',
    },
    {
      type: 'users',
      key: 'creator',
    }
  ],
}

export default incidentActions