import { deleteResource, saveResource, getResources } from '../../datastore'
import { TbObservation } from '../../datastore/models'
import { initialiseToolboxObservations } from '../../redux/slices/pgToolbox'
import { AppDispatch, RootState } from '../../redux/store'
import { checklistItemsToAdd, checklistItemsToDelete } from './resourceChecklist'

export const saveTbObservations = (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState()  
    const { toolboxEdit, observationsList } = state.pgToolbox

    // Find items that have just been de-selected
    // => delete corresponding resources
    checklistItemsToDelete(observationsList).forEach(observationId => {
        const observationsToDelete = getResources<TbObservation>(state.resources, 'tbObservations', { eqFilter: { toolbox: toolboxEdit.id, observation: observationId } })
        // Should be only a single resource, but if there are multiple records for the same observation, delete them all
        observationsToDelete.forEach(observation => dispatch(deleteResource(observation.id, 'tbObservations')))
    })

    // Find items that have just been selected
    // => Save as new 'tbObservations' resources
    checklistItemsToAdd(observationsList).forEach(observationId => {
        dispatch(saveResource({
            company: toolboxEdit.company,
            toolbox: toolboxEdit.id,
            observation: observationId
        }, 'tbObservations'))
    })

    // Set preEdit to match checked now that changes have been saved
    dispatch(initialiseToolboxObservations(observationsList.checked.map(item=>item.id)))
}