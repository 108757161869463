import { ResourceType } from '../redux/slices/resources'
import { AppDispatch, RootState } from '../redux/store'
import { deleteResource } from './deleteResource'
import { getResourceIds } from './getResourceIds'
import { getResources } from './getResources'
import { getRelationNames } from './json-api/helpers/getRelationNames'


/**
 * Deletes a resource, plus other resources of specified types that have a foreign key relationship to it
 * 
 * e.g. deleteResourceCascade(1, 'ta', ['taWorker']) 
 * 
 * -> delete ta with id 1, plus taWorker resources related to it
 * 
 * @param id            - id of base resource item to be deleted
 * @param type          - type of base resource
 * @param relatedTypes  - array of types of resources to delete that are related to base resource
 * 
 */
export const deleteResourceCascade = (id: number, type: ResourceType, relatedTypes: ResourceType[]) => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()

    let { keyId } = getResourceIds(state.resources[type as keyof typeof state.resources], id)
    if (!keyId) {
        return
    }

    relatedTypes.forEach(relatedType => {
        const relationKeys = getRelationNames(relatedType, type)
        relationKeys.forEach(relationKey => {
            const resources = getResources(state.resources, relatedType, { eqFilter: { [relationKey]: keyId } })
            resources.forEach(resource => dispatch(deleteResource((resource as any).id, relatedType)))
        })
    })
    dispatch(deleteResource(id, type))
}