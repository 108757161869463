import { get, uniq, uniqBy } from "lodash"
import { useEffect, useRef, useState } from "react"
import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList
} from "@ionic/react"
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons"
import { useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import { AppDispatch, RootState } from "../../redux/store"
import { useAppSelector, useAppDispatch } from "../../redux/hooks"

import Input from "../../components/form/Input"
import DateInput from "../../components/form/DateInput"
import TextArea from "../../components/form/TextArea"
import SelectListInput from "../../components/form/SelectListInput"
import SelectListTextArea from "../../components/form/SelectListTextArea"
import { setTaDetails, setTaWorkers } from "../../redux/slices/pgTaskAnalysis"
import { TaWorker } from "../../datastore/models"
import { useGeneralDetailsResources } from "../../features/taskAnalysis/resourceRequests/useGeneralDetailsResources"
import { useHomeIconHandler } from "../../hooks/homeIconHandler"
import { homeIconAction } from "../../features/taskAnalysis/homeIconAction"
import { setAlert } from "../../utils/alert"
import {
  TaEvent,
  updateSequence
} from "../../features/taskAnalysis/taskAnalysisSequence"
import { saveTa } from "../../features/taskAnalysis/saveTa"
import { idMatchesResource } from "../../datastore"
import { selectTaEdit } from "../../redux/selectors/tas"
import { selectTask } from "../../redux/selectors/tasks"
import { selectProjectSite } from "../../redux/selectors/projectSites"
import { selectUsers } from "../../redux/selectors/users"
import { useRequestUsersByProject } from "../../features/commonResourceRequests/useRequestUsersByProject"
import "./GeneralDetails.css"

export type TaWorkerEdit = Omit<Partial<TaWorker>, "company"> & {
  company: number | null // Allow null company in case project isn't initally set
  dirty?: boolean
}

type Inputs = {
  name: string
  startDate: string
  projectSite: number
  scheduledTask: number | null
  description: string
  supervisor: TaWorkerEdit
  workers: TaWorkerEdit[]
}

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Please enter a task name"),
    startDate: yup.string().isISODate(),
    projectSite: yup.number().required("Please select a project"),
    scheduledTask: yup.number().nullable(),
    description: yup.string(),
    workers: yup.array().of(
      yup.object().shape({
        user: yup.number().when("deleted", {
          is: (deleted?: boolean) => !deleted,
          then: yup.number().required("Please select a worker")
        })
      })
    ),
    supervisor: yup.object().shape({
      user: yup.number().when("deleted", {
        is: (deleted?: boolean) => !deleted,
        then: yup.number().required("Please select a supervisor")
      })
    })
  })
  .required()

export const GeneralDetails: React.FC = () => {
  const dispatch = useAppDispatch()

  const { toolboxMode, toolboxProject, taEditDirty, savedAsDraft } =
    useAppSelector((state) => state.pgTaskAnalysis)
  const taEdit = useAppSelector(selectTaEdit)

  const [selectedProject, setSelectedProject] = useState<number | null>(null)

  const {
    //  isLoading,
    availableProjectSites,
    tasks
    //    users
  } = useGeneralDetailsResources(selectedProject)

  const { siteUsers } = useRequestUsersByProject(selectedProject)
  const currentUser = useAppSelector((state) => state.app.user)

  const workerUsers = useAppSelector((state) =>
    selectUsers(state, { userIds: taEdit.workers.map((worker) => worker.user) })
  )
  // Users that might be displayed / selected from - include site users, currently logged in user,
  // and any other users previously selected as SWMS workers
  const availableUsers = uniqBy(
    siteUsers.concat(currentUser ? [currentUser] : [], workerUsers),
    "id"
  )

  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    reset,
    formState: { errors, isDirty, dirtyFields }
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: taEdit.userData.name,
      projectSite: availableProjectSites.find(
        (projectSite) =>
          projectSite.projectId === taEdit?.project &&
          projectSite.siteId === taEdit?.site
      )?.id,
      startDate: taEdit.startDate,
      scheduledTask: taEdit.scheduledTask,
      description: taEdit.userData.description,
      workers: taEdit.workers.filter((worker) => !worker.isSupervisor),
      supervisor: taEdit.workers.find((worker) => worker.isSupervisor)
    }
  })

  const selectedProjectSiteId = watch("projectSite")
  const selectedProjectSite = useAppSelector((state) =>
    selectProjectSite(state, { projectSiteId: selectedProjectSiteId })
  )

  const taskId = watch("scheduledTask")
  const task = useAppSelector((state) => selectTask(state, { taskId }))

  const { fields, append, update } = useFieldArray({
    control,
    name: "workers",
    keyName: "ufaId" // defaults to "id" - changed so as not to overwrite resource id
  })

  const addWorker = () => {
    append({
      company: taEdit.company || null,
      dirty: true
    })
  }

  const deleteWorker = (index: number) => {
    const item = fields[index]
    if (item) {
      update(index, {
        ...item,
        deleted: true
      })
    }
  }

  useEffect(() => {
    setSelectedProject(selectedProjectSite?.projectId || null)
    //     if (selectedProjectSite) {
    if (selectedProject !== task?.project) {
      setValue("scheduledTask", null)
    }

    // TODO: on change of projectSite:
    // - delete any workers not associated with new project
    //        }
  }, [selectedProjectSite])

  // Two versions of form submit used because:
  // - we need to do a submit for both 'save draft' (to reset isDirty so we can detect any further changes)
  //   and 'advance to next page' (to save entries on this one)
  // - handleSubmit executes asynchronously, and so the function it calls needs to contain all the actions
  //   required (otherwise they may execute out of sequence)
  const formSubmitSaveDraft = (data: any) => {
    formSubmit(data)
    dispatch(saveTa())

    dispatch((dispatch: AppDispatch, getState: () => RootState) => {
      // Reset form with updated values (resets 'dirty' status and allows us to detect further changes)
      reset({
        ...data,
        startDate: data.startDate,
        workers: getState().pgTaskAnalysis.taEdit.workers
      })
    })
    // May 2023: decided to go back to start menu on 'save Draft'
    // => means above is probably not necessary
    dispatch(updateSequence(TaEvent.START_SELECTED))
  }

  const formSubmitNext = (data: any) => {
    formSubmit(data)
    dispatch(updateSequence(TaEvent.NEXT_SELECTED))
  }

  const formSubmitHome = (data: any) => {
    formSubmit(data)
    dispatch(homeIconAction())
  }
  useHomeIconHandler("/task-analysis", handleSubmit(formSubmitHome))

  const formSubmit = (data: any) => {
    if (selectedProjectSite) {
      if (isDirty || !data.projectSite) {
        dispatch(
          setTaDetails({
            startDate: data.startDate,
            company: selectedProjectSite.company,
            project: selectedProjectSite.projectId,
            site: selectedProjectSite.siteId,
            scheduledTask: data.scheduledTask,
            name: data.name,
            description: data.description
          })
        )

        const updatedWorkers = [
          {
            ...data.supervisor,
            isSupervisor: true,
            dirty:
              !!dirtyFields.supervisor?.user || dirtyFields.supervisor?.deleted
          } as TaWorkerEdit
        ].concat(
          data.workers.map((worker: TaWorkerEdit, i: number) => {
            const itemDirtyFields = dirtyFields?.workers
              ? dirtyFields.workers[i]
              : undefined
            const dirty = !!(
              itemDirtyFields &&
              (itemDirtyFields.user || itemDirtyFields.deleted)
            )
            return {
              ...worker,
              isSupervisor: false,
              dirty: worker.dirty || dirty
            } as TaWorkerEdit
          })
        )

        if (
          updatedWorkers.filter((worker: TaWorkerEdit) => worker.dirty).length
        ) {
          dispatch(setTaWorkers(updatedWorkers))
        }
      }
    } else {
    }
  }

  const cancel = () => {
    if (isDirty || taEditDirty) {
      dispatch(
        setAlert(
          "Discard changes",
          "You've made changes to this SWMS, are you sure you want to discard them?",
          [
            {
              text: "Cancel",
              role: "cancel",
              handler: () => true
            },
            {
              text: "Discard changes",
              handler: () => {
                dispatch(updateSequence(TaEvent.START_SELECTED))
                return true
              }
            }
          ]
        )
      )
    } else {
      dispatch(updateSequence(TaEvent.START_SELECTED))
    }
  }

  const listRef = useRef<HTMLIonListElement>(null)

  const getPhone = (fieldIndex: number) => {
    const worker = getValues(`workers.${fieldIndex}`)
    return (
      availableUsers.find((user) => idMatchesResource(worker?.user, user))?.phone || ""
    )
  }

  const projectListData = availableProjectSites
    ? availableProjectSites.map((projectSite) => ({
        text: uniq(
          [projectSite.siteName, projectSite.projectName].filter(
            (text) => !!text
          )
        ),
        id: projectSite.id
      }))
    : []

  return (
    <>
      <IonContent class="normal-page">
        <form id="general-details-form" onSubmit={handleSubmit(formSubmit)}>
          <SelectListTextArea
            className="modal-input"
            title="Select the project you're working on:"
            placeholder="PROJECT (tap to select)"
            listData={projectListData}
            fieldName="projectSite"
            watch={watch}
            setValue={setValue}
            error={errors.projectSite}
            // disable edit if:
            // - only one project available
            // - ta has already been saved
            // - in toolbox mode for a specific project
            // (project / site will be fixed to match those of the toolbox meeting)
            disabled={
              (getValues("projectSite") &&
                (!availableProjectSites || availableProjectSites.length < 2)) ||
              !!taEdit.id ||
              (toolboxMode && !!toolboxProject && toolboxProject > 0)
            }
          />

          {tasks && tasks.length && selectedProjectSiteId ? (
            <SelectListInput
              className="modal-input"
              title="Select the project management task that this SWMS relates to:"
              placeholder="PROJECT MANAGEMENT TASK (tap to select)"
              listData={tasks?.map((task) => ({
                text: task.taskName,
                id: task.id
              }))}
              label="Project Task"
              fieldName="scheduledTask"
              watch={watch}
              setValue={setValue}
              error={errors.scheduledTask}
            />
          ) : (
            <></>
          )}

          <Input
            id="task-name"
            label="Task Name"
            placeholder="e.g: CLADDING"
            fieldName="name"
            inputMode="text"
            watch={watch}
            setValue={setValue}
            error={errors.name}
          />

          <DateInput
            fieldName="startDate"
            watch={watch}
            setValue={setValue}
            error={errors.startDate}
          />

          <TextArea
            id="task-description"
            fieldName="description"
            inputMode="text"
            placeholder={
              "TASK DESCRIPTION \n e.g: Applying cladding to exterior walls"
            }
            rows={6}
            watch={watch}
            setValue={setValue}
            error={errors.description}
          />

          <IonList id="people-list" lines="none" ref={listRef}>
            <div className="people-details">
              <span>Supervisor</span>
              <span>Phone</span>
              <IonItem>
                <div>
                  <SelectListInput
                    className="modal-input"
                    title="Select Supervisor"
                    placeholder="Select Supervisor"
                    listData={
                      availableUsers?.map((user) => ({
                        text: `${user.firstName} ${user.lastName}`,
                        id: user.id
                      })) || []
                    }
                    fieldName={`supervisor.user`}
                    watch={watch}
                    setValue={setValue}
                    error={get(errors, ["workers", 0, "user"])}
                  />
                  <p>
                    {availableUsers.find((user) =>
                      idMatchesResource(getValues("supervisor")?.user, user)
                    )?.phone || ""}
                  </p>
                </div>
              </IonItem>
            </div>
            <IonItemDivider></IonItemDivider>

            {fields?.filter((worker) => !worker.deleted).length ? (
              <div className="people-details">
                <span>Worker</span>
                <span>Phone</span>

                {fields
                  ?.map((worker, i) => ({ ...worker, fieldIndex: i }))
                  .filter((worker) => !worker.deleted)
                  .map((worker) => (
                    <IonItemSliding key={worker.fieldIndex}>
                      <IonItem>
                        <div>
                          <SelectListInput
                            className="modal-input"
                            title="Select worker"
                            placeholder="Select Worker"
                            listData={
                              availableUsers?.map((user) => ({
                                text: `${user.firstName} ${user.lastName}`,
                                id: user.id
                              })) || []
                            }
                            fieldName={`workers[${worker.fieldIndex}].user`}
                            watch={watch}
                            setValue={setValue}
                            error={get(errors, [
                              "workers",
                              worker.fieldIndex,
                              "user"
                            ])}
                          />
                          <p>{getPhone(worker.fieldIndex)}</p>
                          <div className="opening-chevron-container flex centre-row">
                            <IonIcon icon={chevronForwardOutline}></IonIcon>
                          </div>
                        </div>
                      </IonItem>
                      <IonItemOptions side="end">
                        <div
                          className="closing-chevron-container flex centre-row"
                          color="light"
                        >
                          <IonIcon icon={chevronBackOutline} />
                        </div>
                        <IonItemOption
                          color="danger"
                          onClick={() => deleteWorker(worker.fieldIndex)}
                        >
                          Delete
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  ))}
              </div>
            ) : (
              <></>
            )}

            <IonButton id="add-worker-btn" onClick={addWorker}>
              Add a WORKER
            </IonButton>
          </IonList>
        </form>
      </IonContent>

      <IonFooter className="TA-nav-footer">
        <IonButton onClick={cancel}>CANCEL</IonButton>
        <IonButton
          disabled={!taEdit.draft || (savedAsDraft && !isDirty)}
          onClick={handleSubmit(formSubmitSaveDraft)}
        >
          Save Draft
        </IonButton>
        <IonButton onClick={handleSubmit(formSubmitNext)}>NEXT</IonButton>
      </IonFooter>
    </>
  )
}
