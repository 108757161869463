import normalize, { NormalizedData } from '@disruptph/json-api-normalizer'
import Axios from 'axios'
import { AXIOS_POST_TIMEOUT } from '.'
import { deNormalize } from '../datastore/json-api/helpers/denormalize'
import { getApiRoot } from '../helpers/getApiRoot'
import { ApiResult } from '../interfaces/api'
import { getAccessToken } from './accessToken'

import { errorCheckedReq } from './helpers/errorCheckedRequest'

export const apiPostResource = (resource: NormalizedData) => {

    const denormalized = deNormalize(resource)
    const endpoint = denormalized?.data[0].type
    if (!denormalized || !endpoint) {
      return {
        result: ApiResult.CLIENT_ERROR,
        responseData: null
      }
    }
  
    // If resources have been assigned local ids, remove them - new resources must be sent with no id
    // so it can be assigned by database
    denormalized.data.forEach(resourceItem => delete resourceItem.id)
  
    return errorCheckedReq(async (endpoint: string, reqData?: any) => {
  
      const response = await Axios.post(`${getApiRoot()}resources/${endpoint}`, reqData, {
        headers: {
          'Authorization': `Bearer ${getAccessToken()}`,
          'content-type': 'application/vnd.api+json'
        },
        timeout: AXIOS_POST_TIMEOUT
      })
      const updatedResource = Object.assign({}, normalize(response.data))
      return {
        result: ApiResult.SUCCESS,
        responseData: updatedResource
      }
    })(endpoint, denormalized)
  }