import _ from 'lodash'
import { LocalResourceData, ResourceMap, ResourcesState, ResourceType } from '../../redux/slices/resources'
import { Relationship } from '../types'
import { getResourceById } from './getResourceById'
import { createPeriodFilter } from './createPeriodFilter'
import { getRelationType } from '../json-api/helpers/getRelationType'
import log from 'loglevel'
import { GetResourcesOptions } from '../getResources'
import { isValid } from 'date-fns'

interface RelEqFilterWorking {
    key: string
    relationType: ResourceType
    id: number | null | undefined
    localId: number | undefined
}

interface RelInFilterWorking {
    key: string
    relationType: ResourceType
    ids: (number | undefined | null)[]
    localIds: (number | undefined)[]
}

const validateId = (id: any) => {
    if (id === null) return null
    const numId = _.toNumber(id)
    return isValid(numId) ? numId : undefined
}

/**
 * Get an array of resources, filtered by supplied criteria. If no criteria supplied, will return all resources of the specified type
 *  
 * params object attributes:
 *  resources   - Can be entire state.resources slice, or a pre-selected subset with just the resources required (typically if used in a selector)
 *                Must contain the base resource identified by 'type', plus linked resources if filters include relationship fields
 *  type        - type of resource to return
 *  fnFilter    - function accepting a single resource item and returning boolean filter pass / fail
 *  eqFilter    - object containing 'equality' filters:
 *                  - key = 'id'/attribute/relationship
 *                  - value = value to match
 *                      For relationships:
 *                          - value = null      => return items which don't have the specified relationship
 *                          - value = undefined => return empty array (treat as invalid) 
 *      
 *  inFilter    - object containing list(s) of attribute values or ids to match:
 *                  - key = name of attribute to match (or 'id' to match ids)
 *                  - value = array of values to match
 * 
 * @returns array of resources
 */
export function filterResources(resources: { [key: string]: ResourceMap } | ResourcesState, type: ResourceType, opts?: GetResourcesOptions) {

    const { fnFilter, eqFilter, neqFilter, inFilter, periodFilter } = opts ? opts : { fnFilter: null, eqFilter: null, neqFilter: null, inFilter: null, periodFilter: null }

    const resourceMap = _.get(resources, type)

    if (!resourceMap) {
        return []
    }

    const resourceMapKeys = Object.keys(resourceMap)
    if (!resourceMapKeys || !resourceMapKeys.length) {
        return []
    }

    const periodFilterFn = periodFilter ? createPeriodFilter(periodFilter) : null

    const eqFilters = splitFilter(resources, type, eqFilter)
    const attrEqFilters = eqFilters.attrFilters
    const relEqFilters = eqFilters.relFilters  

    const neqFilters = splitFilter(resources, type, neqFilter)
    const attrNeqFilters = neqFilters.attrFilters
    const relNeqFilters = neqFilters.relFilters

    let attrInFilters: { [key: string]: (number | string | boolean | undefined | null)[] } = {}
    let relInFilters: RelInFilterWorking[] = []

    if (inFilter) {

        /**** Split inFilters into id / attribute filters and relationship filters ****/

        Object.keys(inFilter).forEach(key => {
            // getRelationType will return empty string if 'key' is an attribute
            if (key === 'id') {
                // key is 'id' - include with attribute filters
                attrInFilters.id = inFilter[key]

            } else {
                const relationType = getRelationType(type, key)

                if (relationType) {
                    // key is a relationship
                    const relInFilterWorking: RelInFilterWorking = {
                        key,
                        relationType,
                        ids: [],
                        localIds: []
                    }

                    const idList = inFilter[key]
                    if (idList && Array.isArray(idList)) {
                        idList.forEach(entry => {
                            const id = validateId(entry)
                            // Get the resource the relationship links to
                            const linkedResource = (typeof id === 'number') ? getResourceById(_.get(resources, relationType), id) : null

                            // null and undefined are allowed values, i.e. not error
                            if (id !== null && typeof id !== 'undefined') {
                                if (typeof id !== 'number') {
                                    log.error('filterResources: inFilter ', key, 'id', id, 'is not a valid id')
                                } else if (!linkedResource) {
                                    log.debug(`filterResources:inFilter could not find ${type} linked resource ${key}[${id}]`)
                                }
                            }
                            relInFilterWorking.ids.push(id === null ? null : linkedResource ? +linkedResource.id : undefined)
                            relInFilterWorking.localIds.push(linkedResource?.localId)
                        })
                    }
                    relInFilters.push(relInFilterWorking)

                } else {
                    // key is an attribute
                    attrInFilters[key] = inFilter[key]
                }
            }
        })
    }

    return resourceMapKeys.reduce((acc: LocalResourceData/*<unknown>*/[], key) => {
        const resource = resourceMap[key]

        let fnFilterMatch = true
        if (fnFilter) {
            fnFilterMatch = fnFilter(resource)
        }

        const attrNeqFilterMatch = Object.keys(attrNeqFilters).reduce((match: boolean, key) => {
            if (key === 'id') {
                if (attrNeqFilters.id && (
                    +resource.id === +attrNeqFilters.id ||
                    resource.localId === +attrNeqFilters.id
                )) {
                    return false
                }
                return match
            }
            if (!resource.attributes) {
                return match
            }
            if (resource.attributes[key] === attrNeqFilters[key]) {
                return false
            }
            return match
        }, true)

        const relNeqFilterMatch = relNeqFilters.reduce((match: boolean, filter) => {

            const relationship = _.get(resource, ['relationships', filter.key, 'data']) as Relationship
            if (!relationship || !filter.id) {
                return match
            }

            if (+relationship.id !== filter.id && +relationship.id !== filter.localId) {
                return match
            }
            return false
        }, true)

        const attrEqFilterMatch = Object.keys(attrEqFilters).reduce((match: boolean, key) => {
            if (key === 'id') {
                if ((!attrEqFilters.id || +resource.id !== +attrEqFilters.id)
                    && (!attrEqFilters.id || !resource.localId || resource.localId !== +attrEqFilters.id)) {
                    return false
                }
                return match
            }
            if (!resource.attributes) {
                return false
            }
            if (resource.attributes[key] !== attrEqFilters[key]) {
                return false
            }
            return match
        }, true)

        const relEqFilterMatch = relEqFilters.reduce((match: boolean, filter) => {

            const relationship = _.get(resource, ['relationships', filter.key, 'data']) as Relationship
            if (!relationship) {
                if (filter.id === null) {
                    return match
                }
                return false
            }
            if (!filter.id) {
                return false
            }

            if (+relationship.id !== filter.id && +relationship.id !== filter.localId) {
                // If this resource doesn't match linked resource by either id or localId, then no relationship match
                return false
            }
            return match
        }, true)
    
        const attrInFilterMatch = Object.keys(attrInFilters).reduce((match: boolean, key) => {
            if (key === 'id') {
                if (!attrInFilters.id.includes(+resource.id) && (!resource.localId || !attrInFilters.id.includes(resource.localId))) {
                    return false
                }
                return match
            }
            if (!resource.attributes) {
                return false
            }
            if (Array.isArray(resource.attributes[key])) {
                
                // If the attribute is an array, then match if any element in inFilter matches any element in attribute
                if (!resource.attributes[key].some((attrValue:string | number | boolean) => attrInFilters[key].includes(attrValue))) {
                    return false
                }
            } else {
                if (!attrInFilters[key].includes(resource.attributes[key])) {
                    return false
                }
            }
            return match
        }, true)

        const relInFilterMatch = relInFilters.reduce((match: boolean, filter) => {

            const relationship = _.get(resource, ['relationships', filter.key, 'data']) as Relationship
            if (!relationship) {
                if (filter.ids.includes(null)) {
                    return match
                }
                return false
            }
            if (!filter.ids.length) {
                return false
            }

            if (!filter.ids.includes(+relationship.id) && !filter.localIds.includes(+relationship.id)) {
                // If this resource doesn't match any linked resources by either id or localId, then no relationship match
                return false
            }
            return match
        }, true)

        const periodFilterMatch = periodFilterFn ? periodFilterFn(resource) : true

        if (fnFilterMatch &&
            attrEqFilterMatch && relEqFilterMatch &&
            attrNeqFilterMatch && relNeqFilterMatch &&
            attrInFilterMatch && relInFilterMatch &&
            periodFilterMatch) {
            acc.push(resource)
        }
        return acc
    }, [])
}

/**  
 * Split a filter object into id / attribute filters and relationship filters
 * 
 * filter may be an eqFilter or a neqfilter
 */
const splitFilter = (resources: { [key: string]: ResourceMap } | ResourcesState, type: ResourceType, filter: GetResourcesOptions['eqFilter'] | null) => {

    let attrFilters: { [key: string]: number | string | boolean | undefined | null } = {}
    let relFilters: RelEqFilterWorking[] = []

    if (filter) {

        Object.keys(filter).forEach(key => {

            if (key === 'id') {
                // key is 'id' - include with attribute filters
                attrFilters.id = filter[key]

            } else {
                // getRelationType will return empty string if 'key' is an attribute
                const relationType = getRelationType(type, key)

                if (relationType) {
                    // key is a relationship
                    const id = validateId(filter[key])
                    // Get the resource the relationship links to
                    const linkedResource = (typeof id === 'number') ? getResourceById(_.get(resources, relationType), id) : null
                    relFilters.push({
                        key,
                        relationType,
                        // id is:
                        // - null if null in original filter,
                        // - linkedResurce.id if linkedResource found,
                        // - undefined if undefined in original filter or linkedResource NOT found  
                        id: id === null ? null : linkedResource ? +linkedResource.id : undefined,
                        localId: linkedResource?.localId
                    })

                    // null and undefined are allowed values, i.e. not error
                    if (id !== null && typeof id !== 'undefined') {
                        if (typeof id !== 'number') {
                            log.error('filterResources:eqFilter ', key, 'id', id, 'is not a valid id')
                        } else if (!linkedResource) {
                            log.debug(`filterResources:eqFilter could not find ${type} linked resource ${key}[${id}]`)
                        }
                    }

                } else {
                    // key is an attribute
                    attrFilters[key] = filter[key]
                }
            }
        })
    }
    return { attrFilters, relFilters }
}