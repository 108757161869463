export enum stINCIDENT_REPORTS {
    START,
    REGISTER,
    INCIDENT_TYPE,
    DETAILS_1,
    WORKERS_INVOLVED,
    DETAILS_2,
    ACTIONS,
    REVIEW
}

export enum IncidentReportEvent {
    START_SELECTED,
    REGISTER_SELECTED,
    INCIDENT_TYPE_SELECTED,
    DETAILS_SELECTED,
    NEXT_SELECTED,
    BACK_SELECTED,
    REVIEW_SELECTED
}

export const incidentTypeTexts = {
    NearMiss: {
        title: 'NEAR MISS',
        subtitle: 'No one is hurt, but they could have been'
    },
    MinorInjury: {
        title: 'MINOR INJURY',
        subtitle: 'Minor injury that can be treated on site'
    },
    ModerateInjury: {
        title: 'MODERATE INJURY',
        subtitle: 'Injury requiring off site medical attention'
    },
    SeriousIllness: {
        title: 'SERIOUS ILLNESS',
        subtitle: 'Injury requiring Hospital / Ambulance'
    },
    SeriousInjury: {
        title: 'SERIOUS INJURY',
        subtitle: 'Injury requiring Hospital / Ambulance'
    },
    HiRiskIncident: {
        title: 'HI RISK INCIDENT',
        subtitle: 'Uncontrolled or unplanned incident'
    },
    Death: {
        title: 'DEATH',
        subtitle: 'Someone has died at work'
    },
}

export const injuryTypeTexts = {
    None: 'No injury - Near Miss only',
    TreatOnSite: 'Treated on site - First Aid',
    MedCentre: 'Taken to Local Medical Centre',
    Hospital: 'Ambulance called - Hospital A&E'
}