import { Browser as CapBrowser } from '@capacitor/browser';
import { isPlatform } from '@ionic/core';

export const openInBrowser = (url: string) => {
    if (isPlatform('capacitor')) {
        CapBrowser.open({
            url: url,
            windowName: '_self',
        });
    } else {
        window.open(url, '_blank');
    }
}