import { LocalResourceData, ResourceMap, ResourcesState, ResourceType } from '../redux/slices/resources'
import { ResourceFilters } from './types'
import { flattenResource } from './helpers/flattenResource'
import { filterResources } from './helpers/filterResources'

export interface GetResourcesOptions extends ResourceFilters {
    fnFilter?: (resource: LocalResourceData/*<any>*/) => boolean
}

/**
 * Get an array of flattened resources, filtered by supplied criteria. If no criteria supplied, will return all resources of the specified type
 *  
 * params object attributes:
 *  resources   - Can be entire state.resources slice, or a pre-selected subset with just the resources required (typically if used in a selector)
 *                Must contain the base resource identified by 'type', plus linked resources if filters include relationship fields
 *  type        - type of resource to return
 *  opts        - options object with attributes:
 *      fnFilter    - function accepting a single resource item and returning boolean filter pass / fail
 *      eqFilter    - object containing 'equality' filters:
 *                  - key = 'id'/attribute/relationship
 *                  - value = value to match
 *                      For relationships:
 *                          - value = null      => return items which don't have the specified relationship
 *                          - value = undefined => return empty array (treat as invalid) 
 *      
 *      inFilter    - object containing list(s) of attribute values or ids to match:
 *                  - key = name of attribute to match (or 'id' to match ids)
 *                  - value = array of values to match
 *      periodFilter - object with name of Date attribute and time range to filter by 
 * 
 * (see ResourceFilters type)
 * 
 * @returns array of 'flattened' resources
 */
export function getResources<Type>(resources: { [key: string]: ResourceMap } | ResourcesState, type: ResourceType, opts?: GetResourcesOptions) {

    return filterResources(resources, type, opts).map(resource => (flattenResource(resource) as unknown) as Type)    
}