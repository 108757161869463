import { AppDispatch, RootState } from '../../../redux/store'
import { requestResourcesWithRelated } from '../../../datastore'

export const requestIncidents = (params: { [key: string]: any }) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const { siteId, includeActions } = params

    return await (requestResourcesWithRelated('incidents', {
        eqFilter: siteId ? { site: siteId } : {},
        periodFilter: { attr: 'incidentDate', periodStart: params.periodStart, periodEnd: params.periodEnd },
    }, includeActions ? ['incidentActions'] : []))(dispatch, getState, services)
}