import { deleteResource, saveResource, getResources } from '../../datastore'
import { TbIncident } from '../../datastore/models'
import { initialiseToolboxIncidents } from '../../redux/slices/pgToolbox'
import { AppDispatch, RootState } from '../../redux/store'
import { checklistItemsToAdd, checklistItemsToDelete } from './resourceChecklist'

export const saveTbIncidents = (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState()  
    const { toolboxEdit, incidentsList } = state.pgToolbox

    // Find items that have just been de-selected
    // => delete corresponding resources
    checklistItemsToDelete(incidentsList).forEach(incidentId => {
        const incidentsToDelete = getResources<TbIncident>(state.resources, 'tbIncidents', { eqFilter: { toolbox: toolboxEdit.id, incident: incidentId } })
        // Should be only a single resource, but if there are multiple records for the same incident, delete them all
        incidentsToDelete.forEach(incident => dispatch(deleteResource(incident.id, 'tbIncidents')))
    })

    // Find items that have just been selected
    // => Save as new 'tbIncidents' resources
    checklistItemsToAdd(incidentsList).forEach(incidentId => {
        dispatch(saveResource({
            company: toolboxEdit.company,
            toolbox: toolboxEdit.id,
            incident: incidentId
        }, 'tbIncidents'))
    })

    // Set preEdit to match checked now that changes have been saved
    dispatch(initialiseToolboxIncidents(incidentsList.checked.map(item=>item.id)))
}