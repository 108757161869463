import { AppDispatch, RootState } from "../../redux/store"
import { TaEvent, updateSequence } from "./taskAnalysisSequence"
import { requestResources, requestResourcesWithRelated } from "../../datastore"
import { setTaEdit, setTaEditDirty } from "../../redux/slices/pgTaskAnalysis"
import {
  selectTaTemplate,
  selectTaTemplateFromName
} from "../../redux/selectors/taTemplates"
import { selectSignedinSiteId } from "../../redux/selectors/signins"
import { selectCanCreateTa } from "../../redux/selectors/abilities"
import { selectOpenProjectSites } from "../../redux/selectors/projectSites"
import log from "loglevel"
import { hideLoadingSpinner, showLoadingSpinner } from "../../redux/slices/temp"

export const createTa =
  (taTemplateId?: number) =>
  async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    let state = getState()
    const { toolboxMode, toolboxProject, toolboxTask } = state.pgTaskAnalysis

    dispatch(showLoadingSpinner("Loading..."))

    let taTemplate
    if (taTemplateId) {
      // User has selected a template from list (must already have the template in store)
      taTemplate = selectTaTemplate(state, taTemplateId)
    } else {
      // Using default template - should be in store but request it anyway in case it isn't
      await requestResources("taTemplates", { eqFilter: { name: "default" } })(
        dispatch,
        getState,
        services
      )
      taTemplate = selectTaTemplateFromName(state, {
        itemName: "default",
        categoryId: null
      })
    }

    const user = state.app.user
    const signedInSiteId = selectSignedinSiteId(state)

    // Get projectSite records, and filter to those for projects this user has create permission for
    await requestResourcesWithRelated("projectSites", {}, [
      "projects",
      "sites"
    ])(dispatch, getState, services)
    state = getState()
    const projectSites = selectOpenProjectSites(state)
    const filteredProjectSites = projectSites.filter((projectSite) =>
      selectCanCreateTa(state, projectSite)
    )

    let defaultProjectSite

    if (filteredProjectSites.length) {
      if (toolboxMode && toolboxProject && toolboxProject > 0) {
        // If in toolbox mode (and not 'all projects' mode), find projectSite matching toolbox project
        const toolboxProjectSites = filteredProjectSites.filter(
          (projectSite) => projectSite.project === toolboxProject
        )
        if (toolboxProjectSites.length) {
          // There should only be one match...
          defaultProjectSite = toolboxProjectSites[0]
        } else {
          log.error(
            "createTa: could not find projectSite matching toolbox project"
          )
        }
      } else if (filteredProjectSites.length === 1) {
        // If there is only one available projectSite, select it by default
        defaultProjectSite = filteredProjectSites[0]
      } else {
        // If multiple projectSites available, look for one that matches the site we're currently signed in to
        const signedinSiteProjects = filteredProjectSites.filter(
          (projectSite) => projectSite.site === signedInSiteId
        )
        if (signedinSiteProjects.length === 1) {
          defaultProjectSite = signedinSiteProjects[0]
        }
      }
    } else {
      log.error("createTa:No projectSite with create permission found")
    }

    dispatch(
      setTaEdit({
        company: defaultProjectSite?.company,
        project: defaultProjectSite?.project,
        site: defaultProjectSite?.site,
        scheduledTask: toolboxMode && toolboxTask ? toolboxTask : undefined,
        creator: user?.id,
        startDate: new Date().toISOString(),
        draft: true,
        userData: {
          name: taTemplate?.templateData.name,
          description: taTemplate?.templateData.description,
          steps: taTemplate?.templateData.steps || [],
          ppe: taTemplate?.templateData.ppe || [],
          customPpe: taTemplate?.templateData.customPpe || []
        },
        // First worker is supervisor - default to current user
        workers: [
          {
            user: user!.id,
            company: null, // No need to set worker company here as it will be set to match selected project on save
            isSupervisor: true
          }
        ]
      })
    )

    if (defaultProjectSite) {
      // Set as dirty if we've set project / site
      // (if project is auto-supplied or set from toolbox, will ensure user is prompted to save / use
      // even if they just click through a fully populated template)
      dispatch(setTaEditDirty(true))
    }

    dispatch(hideLoadingSpinner())

    dispatch(updateSequence(TaEvent.NEXT_SELECTED))
  }
