import { setRedirectPath } from '../../redux/slices/temp'
import { AppDispatch, RootState } from '../../redux/store'
import { saveTa } from './saveTa'

import { taReturnToToolbox } from './taskAnalysisToolbox'

export const homeIconAction = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { toolboxMode, taEditDirty } = getState().pgTaskAnalysis

    if (toolboxMode) {
        // Note taReturnToToolbox will save ta if required
        dispatch(taReturnToToolbox())

    } else {
        // If user has an unsaved edit, save it
        // TODO: confirm we want to save a draft any time user returns to home screen?
        if (taEditDirty) {
            saveTa()(dispatch, getState)
        }
        dispatch(setRedirectPath('/home'))
    }
}