import {
    IonHeader,
    IonPage
} from '@ionic/react';

import React from 'react'

import NavBar from '../../components/NavBar'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'

import { Start } from './Start'
import { Register } from './Register';
import { SelectIncidentType } from './SelectIncidentType'
import { Details1 } from './Details1'
import { WorkersInvolved } from './WorkersInvolved'
import { Details2 } from './Details2';
import { Actions } from './Actions';
import { Review } from './Review';

import { setHomeIconEvent } from '../../redux/slices/temp'
import { stINCIDENT_REPORTS } from '../../features/incidentReports/incidentReportsTypes'

import './IncidentReportNav.css'

const IncidentReports: React.FC = () => {
    const { stIncidentReports, toolboxMode } = useAppSelector((state) => state.pgIncidentReports)
    const dispatch = useAppDispatch()

    function renderSwitch() {
        switch (stIncidentReports) {

            case stINCIDENT_REPORTS.START:
                return <Start />

            case stINCIDENT_REPORTS.REGISTER:
                return <Register />

            case stINCIDENT_REPORTS.INCIDENT_TYPE:
                return <SelectIncidentType/>

            case stINCIDENT_REPORTS.DETAILS_1:
                return <Details1/>

            case stINCIDENT_REPORTS.WORKERS_INVOLVED:
                return <WorkersInvolved />

            case stINCIDENT_REPORTS.DETAILS_2:
                return <Details2/>

            case stINCIDENT_REPORTS.ACTIONS:
                return <Actions/>

            case stINCIDENT_REPORTS.REVIEW:
                return <Review />

        }
    }

    return (
        <IonPage className="normal-page">
            <IonHeader>
                <NavBar
                    pageTitle="Incident Reports"
                    iconPath={toolboxMode ? "/assets/ui_images/speech_bubble.svg" : undefined}
                    homeAction={() => dispatch(setHomeIconEvent(true))}
                />
            </IonHeader>

            {renderSwitch()}

            {/* The following components set up useModal hooks and then call 'setModalFns' to give us the corresponding 'present' and 'dismiss' fns */}
            {/* <SelectSupervisorModal setModalFns={setModalFns('selectSupervisor')} /> */}

        </IonPage>
    )
}

export default IncidentReports