import { IonIcon } from "@ionic/react"
import { arrowForward } from "ionicons/icons"
import React, { Fragment } from "react"
import { CategoryContents } from "../redux/selectors/categories"
import ASCheckbox from "./ASCheckbox"

interface CategoryLevelProps {
  categoryText: string
  categoryContents?: CategoryContents
  indent: number
  selectedIds: (number | null)[]
  greenIds: (number | null)[]
  arrowId?: number | null
  selectFn: (itemId: number, selected: boolean) => void
}

const CategoryLevel: React.FC<CategoryLevelProps> = ({
  categoryText,
  categoryContents,
  indent,
  selectedIds,
  greenIds,
  arrowId,
  selectFn
}) => {
  return (
    <>
      <h3 className={`col-${indent}-item`}>{categoryText}</h3>

      {categoryContents?.subcategories.map((subcategory) => (
        <CategoryLevel
          key={subcategory.id}
          categoryText={subcategory.categoryText}
          categoryContents={subcategory.contents}
          indent={indent + 1}
          selectedIds={selectedIds}
          greenIds={greenIds}
          selectFn={selectFn}
        />
      ))}

      {categoryContents?.items.map((item) => (
        <Fragment key={item.id}>
          {arrowId === item.id ? (
            <IonIcon
              className={`col-${indent}-arrow`}
              icon={arrowForward}
              size="small"
            />
          ) : (
            <></>
          )}

          <ASCheckbox
            containerClass={`col-${indent + 1}-cb`}
            color={greenIds.includes(item.id) ? "green" : "grey"}
            checked={selectedIds.includes(item.id)}
            onClick={(state) => selectFn(item.id, state)}
            label={item.itemText}
          />
        </Fragment>
      ))}
    </>
  )
}

export default CategoryLevel
