import React from 'react'
import { FieldError, FieldErrors, UseFormWatch, UseFormSetValue, get } from "react-hook-form"
import { IonInput, IonLabel } from '@ionic/react'
import { InputChangeEventDetail, IonInputCustomEvent } from '@ionic/core'

interface InputProps {
    id?: string
    className?: string,
    label?: string
    fieldName: string
    inputMode?: "email" | "url" | "search" | "text" | "none" | "tel" | "numeric" | "decimal"
    placeholder?: string
    watch: UseFormWatch<any>
    setValue: UseFormSetValue<any>
    errors?: FieldErrors
    error?: FieldError // Use if just this field's error is passed (may need for array fields)
}

const Input: React.FC<InputProps> = ({ id, className, label, fieldName, inputMode, placeholder, watch, setValue, errors, error }) => {

    const fieldError = errors && errors[fieldName] ? errors[fieldName] : error
    const value = watch(fieldName)

    const onChange = (event: IonInputCustomEvent<InputChangeEventDetail>) => {
        setValue(fieldName, get(event, 'currentTarget.value'), { shouldDirty: true })
    }

    return (
        <div id={id} className={className}>
            {label ? <IonLabel class="oss-input-label">{label}</IonLabel> : <></>}

            <IonInput
                inputmode={inputMode}
                style={fieldError ? { borderColor: "red" } : {}}
                class="oss-input"
                value={value}
                placeholder={placeholder}
                onIonInput={onChange}
            >
            </IonInput>

            <div className="oss-input-error-message">{fieldError?.message}</div>
        </div>
    )
}

export default Input