import { AppDispatch, RootState } from '../../redux/store'

import { setTaToolboxMode } from "../../redux/slices/pgTaskAnalysis"
import { loadTa } from './loadTa'
import { saveTa } from "./saveTa"
import { toolboxReviewTaDone } from '../toolbox/toolboxIntegration'
import { setRedirectPath } from '../../redux/slices/temp'
import { stTASKANALYSIS } from './taskAnalysisTypes'
import { setAlert } from '../../utils/alert'
import { getResource } from '../../datastore'
import { Ta } from '../../datastore/models'

/************** Toolbox Integration ***************/

/**
 * Dispatched from toolbox page to initiate ta review during toolbox meeting
 */
export const taToolboxReviewSelected = (taId: number) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    const { stTaskAnalysis, taEditDirty } = getState().pgTaskAnalysis

    if (stTaskAnalysis !== stTASKANALYSIS.START && taEditDirty) {
        // If another ta is in the process of being edited, save it
        // Note ta save is synchronous due to not containing images - otherwise would need to await here
        dispatch(saveTa())
    }
    await loadTa(taId, false)(dispatch, getState, services)

    dispatch(setTaToolboxMode({ mode: true, ta: taId }))
    dispatch(setRedirectPath('/task-analysis'))
}

/**
 * Dispatched from toolbox page to initiate creating a ta during toolbox meeting
 */
export const taToolboxCreateSelected = (projectId: number | null, siteId: number | null, taskId?: number) => (dispatch: AppDispatch, getState: () => RootState) => {

    const { stTaskAnalysis, taEditDirty } = getState().pgTaskAnalysis

    if (stTaskAnalysis !== stTASKANALYSIS.START && taEditDirty) {
        // If another ta is in the process of being edited, save it
        dispatch(saveTa())
    }
    dispatch(setTaToolboxMode({ mode: true, project: projectId, site: siteId, task: taskId }))
    dispatch(setRedirectPath('/task-analysis'))
}

/**
 * Dispatched to return to toolbox page when in toolbox mode:
 * - From 'return to toolbox' buttons
 * - From navbar home icon
 */
export const taReturnToToolbox = () => (dispatch: AppDispatch, getState: () => RootState) => {

    const { stTaskAnalysis, taEdit, taEditDirty } = getState().pgTaskAnalysis

    if (stTaskAnalysis !== stTASKANALYSIS.START &&
        stTaskAnalysis !== stTASKANALYSIS.SIGNOFF &&
        taEditDirty) {
        if (taEdit.draft) {

            dispatch(setAlert(
                'SWMS Not Active',
                "This SWMS hasn't been made active yet. If you want to use it today, select 'cancel' " +
                "and then 'Use SWMS' before returning to toolbox meeting. If you select 'Continue', SWMS will be saved " +
                "as a draft but will not be added to the toolbox meeting",
                [{
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => true
                },
                {
                    text: 'Continue',
                    handler: () => {
                        dispatch(saveTa())
                        dispatch(setTaToolboxMode({ mode: false }))
                        dispatch(setRedirectPath('/toolbox'))
                        return true
                    }
                }]
            ))
            return
        }
        dispatch(saveTa())
    }

    // get whole ta so we have updated id and localId
    const ta = getResource<Ta>(getState().resources.tas, taEdit.id || 0)

    if (ta) {
        dispatch(toolboxReviewTaDone(ta))
    }

    dispatch(setTaToolboxMode({ mode: false }))
    dispatch(setRedirectPath('/toolbox'))
}
