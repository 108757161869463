import { AppDispatch, RootState } from '../../redux/store'

import { IncidentReportEvent, stINCIDENT_REPORTS } from './incidentReportsTypes'
import { setIncidentReportsState } from '../../redux/slices/pgIncidentReports'

export const updateSequence = (event?: IncidentReportEvent) => (dispatch: AppDispatch, getState: () => RootState) => {

    let state = getState()

    const { stIncidentReports } = state.pgIncidentReports

    // Can be sent to 'Details' or 'Review' from any state, e.g. by toolbox
    
    if (event === IncidentReportEvent.DETAILS_SELECTED) {
        dispatch(setIncidentReportsState(stINCIDENT_REPORTS.DETAILS_1))
        return
    }
    
    if (event === IncidentReportEvent.REVIEW_SELECTED) {
        dispatch(setIncidentReportsState(stINCIDENT_REPORTS.REVIEW))
        return
    }

    switch (stIncidentReports) {

        case stINCIDENT_REPORTS.START:

            if (event === IncidentReportEvent.REGISTER_SELECTED) {
                dispatch(setIncidentReportsState(stINCIDENT_REPORTS.REGISTER))
            }
            if (event === IncidentReportEvent.INCIDENT_TYPE_SELECTED) {
                dispatch(setIncidentReportsState(stINCIDENT_REPORTS.INCIDENT_TYPE))
            }
            break

        case stINCIDENT_REPORTS.REGISTER:

            if (event === IncidentReportEvent.BACK_SELECTED) {
                dispatch(setIncidentReportsState(stINCIDENT_REPORTS.START))
            }
            break

        case stINCIDENT_REPORTS.INCIDENT_TYPE:

            if (event === IncidentReportEvent.BACK_SELECTED) {
                dispatch(setIncidentReportsState(stINCIDENT_REPORTS.START))
            }
            break

        case stINCIDENT_REPORTS.DETAILS_1:

            if (event === IncidentReportEvent.START_SELECTED) {
                dispatch(setIncidentReportsState(stINCIDENT_REPORTS.START))
            }

            if (event === IncidentReportEvent.BACK_SELECTED) {
                dispatch(setIncidentReportsState(stINCIDENT_REPORTS.INCIDENT_TYPE))
            }
            if (event === IncidentReportEvent.NEXT_SELECTED) {
                dispatch(setIncidentReportsState(stINCIDENT_REPORTS.WORKERS_INVOLVED))
            }
            break

        case stINCIDENT_REPORTS.WORKERS_INVOLVED:

            if (event === IncidentReportEvent.BACK_SELECTED) {
                dispatch(setIncidentReportsState(stINCIDENT_REPORTS.DETAILS_1))
            }
            if (event === IncidentReportEvent.NEXT_SELECTED) {
                dispatch(setIncidentReportsState(stINCIDENT_REPORTS.DETAILS_2))
            }
            break

        case stINCIDENT_REPORTS.DETAILS_2:

            if (event === IncidentReportEvent.BACK_SELECTED) {
                dispatch(setIncidentReportsState(stINCIDENT_REPORTS.WORKERS_INVOLVED))
            }
            if (event === IncidentReportEvent.NEXT_SELECTED) {
                dispatch(setIncidentReportsState(stINCIDENT_REPORTS.ACTIONS))
            }
            break

        case stINCIDENT_REPORTS.ACTIONS:

            if (event === IncidentReportEvent.BACK_SELECTED) {
                dispatch(setIncidentReportsState(stINCIDENT_REPORTS.DETAILS_2))
            }

            if (event === IncidentReportEvent.START_SELECTED) {
                dispatch(setIncidentReportsState(stINCIDENT_REPORTS.START))
            }
            break

        case stINCIDENT_REPORTS.REVIEW:

            if (event === IncidentReportEvent.BACK_SELECTED) {
                dispatch(setIncidentReportsState(stINCIDENT_REPORTS.START))
            }
            break


        default:
            dispatch(setIncidentReportsState(stINCIDENT_REPORTS.START))
        }
}