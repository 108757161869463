import { IonCard, IonCol, IonContent, IonGrid, IonRow } from "@ionic/react"
import { useAppDispatch, useAppSelector } from '../../redux/hooks'

import './Start.css'

import { useHomeIconHandler } from "../../hooks/homeIconHandler"
import { homeIconAction } from "../../features/incidentReports/homeIconAction"
import { updateSequence } from "../../features/incidentReports/incidentReportsSequence"
import { IncidentReportEvent } from "../../features/incidentReports/incidentReportsTypes"
import { incidentReportReturnToToolbox } from "../../features/incidentReports/incidentReportsToolbox"
import { selectCanCreateAnyIncident } from "../../redux/selectors/abilities"
import { useStartResources } from "../../features/incidentReports/resourceRequests/useStartResources"

export const Start: React.FC = () => {
    const dispatch = useAppDispatch()

    const { toolboxMode, reviewIncidentReportId } = useAppSelector(state => state.pgIncidentReports)
    const canCreateIncident = useAppSelector(state => selectCanCreateAnyIncident(state))
    const currentPath = useAppSelector(state => state.temp.currentPath)

    useStartResources(currentPath === '/incidents')

    useHomeIconHandler('/incidents', () => dispatch(homeIconAction()))

    return (
        <>
            <IonContent class="content-padding">
                <IonGrid fixed={true} className="app-grid">
                    <IonRow>
                        <IonCol>
                            <div id="incident-start-grid">
                                <IonCard
                                    button={true}
                                    onClick={() => dispatch(updateSequence(IncidentReportEvent.INCIDENT_TYPE_SELECTED))}
                                    disabled={!canCreateIncident}
                                >
                                    <div>
                                        <span>Create NEW</span>
                                        <span>Incident Report</span>
                                    </div>
                                </IonCard>
                                <IonCard button={true} onClick={() => dispatch(updateSequence(IncidentReportEvent.REGISTER_SELECTED))}>
                                    <div>
                                        <span>Open Existing</span>
                                        <span>Incident Report</span>
                                    </div>
                                </IonCard>
                                {toolboxMode ?
                                    <IonCard
                                        className="full-width-card"
                                        button={true}
                                        onClick={() => dispatch(incidentReportReturnToToolbox(reviewIncidentReportId || null))}
                                    >
                                        <span>Return to Toolbox Meeting</span>
                                    </IonCard>
                                    : <></>
                                }
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </>)
}