import { isPlatform } from "@ionic/react"
import { getLogger } from "loglevel"

import { AppDispatch, RootState } from "../../redux/store"
import { setRedirectPath } from "../../redux/slices/temp"
import { logoutPurgeResources } from "./logoutPurgeResources"
import { ApiService } from "../../interfaces/api"
import { setGuestAccessToken } from "../../redux/slices/app"
import { authGetUser, getAuthService } from "../../services/Auth"

const log = getLogger("auth")

export const logout =
  () =>
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      const api: ApiService = services.api

      api.setAccessToken("")
      dispatch(setGuestAccessToken(''))

      const user = getState().app.user
      const isGuest = user?.isGuest

      dispatch(logoutPurgeResources())

      // Currently does nothing
      // await api.logout()

      let authUser = null
      try {
        authUser = await authGetUser()
      } catch (err) { }

      if (isGuest || !authUser) {
        // - Guest users aren't logged in via auth server, so no need to log out of it
        // - If auth service doesn't know about a logged in user then prefer not to do a redirect logout - since
        //   we'll have no id token the auth server would leave the user on a confirmation prompt
        dispatch(setRedirectPath("/signin"))
      } else {
        dispatch(setRedirectPath("/logout"))

        if (isPlatform("capacitor")) {
          dispatch(inAppLogout())
        } else {
          dispatch(inAppLogout())
          //dispatch(redirectLogout())
        }
      }
    }

const inAppLogout =
  () => (dispatch: AppDispatch, getState: () => RootState) => {

    const authService = getAuthService()

    try {
      authService.signOut()
    } catch (err) {
      log.info('authService error:', JSON.stringify(err))
    }
  }

/**
 * 'Redirect' logout
 *
 * Implemented as redux 'heldAction' - will be 'held' by the persistence middleware until any pending state saving operations are complete
 * (since it will cause the browser to redirect and lose any unsaved state information)
 * @returns
 */
/*
const redirectLogout = () => {
  return {
    type: "persistence/heldAction",
    payload: () => {
      try {
        getUserManager().signoutRedirect()
      } catch (err) {
        log.warn(`redirectLogout: signoutRedirect error ${err}`)
      }
    }
  }
}
*/