import { addDays } from "date-fns"
import { IonButton, IonContent, IonFooter, IonItemDivider } from "@ionic/react"
import { useAppSelector, useAppDispatch } from '../../redux/hooks'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import DateInput from "../../components/form/DateInput"
import TextArea from "../../components/form/TextArea"
import SelectListInput from "../../components/form/SelectListInput"
import CameraInput, { PhotoData } from "../../components/form/CameraInput"
import PhotoDisplay from "../../components/form/PhotoDisplay"
import { setSiteObservationDetails } from "../../redux/slices/pgSiteObservations"
import { SiteObservation } from "../../datastore/models"
import { initISODateValidator } from "../../helpers/yupISODateValidator"
import { setAlert } from "../../utils/alert"
import { returnToStart } from "../../features/siteObservations/returnToStart"

import { useDetailsResources } from '../../features/siteObservations/resourceRequests/useDetailsResources'
import { useHomeIconHandler } from "../../hooks/homeIconHandler"
import { homeIconAction } from "../../features/siteObservations/homeIconAction"
import { saveObservation } from "../../features/siteObservations/saveObservation"
import { updateSequence } from "../../features/siteObservations/siteObservationsSequence"
import { SiteObservationEvent } from "../../features/siteObservations/siteObservationsTypes"
import { useRequestUsersBySite } from "../../features/commonResourceRequests/useRequestUsersBySite"
import { selectUser } from "../../redux/selectors/users"
import { uniqBy } from "lodash"

export type SiteObservationDetailsEdit = Omit<SiteObservation, 'photoBefore' | 'photoAfter'> & {
    photoBefore?: PhotoData,
    photoAfter?: PhotoData,
}

// Add yup validator for ISO string formatted dates
initISODateValidator()

const schema = yup.object().shape({
    site: yup.number().required('Please select a site'),
    observationDate: yup.string().isISODate({ max: addDays(new Date(), 1), maxMsg: 'Cannot observe something in the future' }),
    observer: yup.number().required('Please select observer'),
    description: yup.string().required("Please describe what you're observing"),
}).required()

interface Props {
    // Modals are instantiated in the containing 'index' component - this fn is used to present them
    presentModal: (modalKey: string) => void
}

export const Details: React.FC<Props> = ({ presentModal }) => {
    const dispatch = useAppDispatch()
    const { toolboxMode, toolboxSite, siteObservationEdit, dirty } = useAppSelector(state => state.pgSiteObservations)

    const { handleSubmit, watch, getValues, setValue, formState: { errors, isDirty } } = useForm<SiteObservationDetailsEdit>({
        resolver: yupResolver(schema),
        defaultValues: siteObservationEdit.details
    })

    const siteId = watch('site')
    const { availableSites } = useDetailsResources(siteId)
    const { siteUsers } = useRequestUsersBySite(siteId)

    const currentUser = useAppSelector(state => state.app.user)
    const observer = useAppSelector((state) => selectUser(state, {userId: siteObservationEdit.details.observer}))
    // Users that might be displayed / selected from - include site users, currently logged in user, 
    // and any other user previously selected as observer
    const availableUsers = uniqBy(siteUsers.concat(currentUser?[currentUser]:[], observer?[observer]:[]), 'id')

    const formSubmitNext = (data: any) => {
        formSubmit(data)
        dispatch(updateSequence(SiteObservationEvent.NEXT_SELECTED))
    }

    const formSubmitHome = (data: any) => {
        formSubmit(data)
        dispatch(homeIconAction())
    }
    useHomeIconHandler('/observations', handleSubmit(formSubmitHome))

    const formSubmitNotify = (data: any) => {
        formSubmit(data)

        dispatch(setAlert(
            'Notify Observation',
            'By continuing, you are indicating that you are unable to resolve the issue and that further action by a supervisor is required',
            [{
                text: 'Cancel',
                role: 'cancel',
                handler: () => true
            },
            {
                text: "Continue",
                handler: () => {
                    dispatch(saveObservation())
                    presentModal('selectSupervisor')
                    return true
                }
            }]
        ))
    }

    const formSubmit = (data: any) => {
        if (isDirty) {
            dispatch(setSiteObservationDetails(data))
        }
    }

    const cancel = () => {
        if (isDirty || dirty) {
            dispatch(setAlert(
                'Discard changes?',
                "You've made changes to this observation, do you want to discard them?",
                [{
                    text: 'Continue with Observation',
                    role: 'cancel',
                    handler: () => true
                },
                {
                    text: "Discard changes and Exit",
                    handler: () => {
                        dispatch(returnToStart())
                        return true
                    }
                }]
            ))
        } else {
            dispatch(returnToStart())
        }
    }

    return (
        <>
            <IonContent class="content-padding">
                <form id="details-form">
                    <SelectListInput
                        className="modal-input"
                        title="Select site you want to make an observation for:"
                        placeholder="Site"
                        listData={availableSites?.map(site => ({ text: site.siteName, id: site.id })) || []}
                        fieldName="site"
                        watch={watch}
                        setValue={setValue}
                        error={errors.site}
                        // disable edit if:
                        // - only one site available
                        // - in toolbox mode for a specified site
                        // (site will be fixed to match toolbox meeting) 
                        disabled={(getValues('site') && (!availableSites || availableSites.length < 2)) || (toolboxMode && !!toolboxSite)}
                    />

                    <DateInput
                        fieldName="observationDate"
                        watch={watch}
                        setValue={setValue}
                        error={errors.observationDate}
                    />

                    <SelectListInput
                        className="modal-input"
                        title="Select person making observation:"
                        placeholder="Name of person making observation"
                        listData={availableUsers.map(user => ({ text: `${user.firstName} ${user.lastName}`, id: user.id })) || []}
                        fieldName="observer"
                        watch={watch}
                        setValue={setValue}
                        error={errors.observer}
                    />

                    <TextArea
                        fieldName="description"
                        inputMode="text"
                        placeholder={"Description of Observation"}
                        rows={2}
                        watch={watch}
                        setValue={setValue}
                        error={errors.description}
                    />

                    <CameraInput
                        fieldName="photoBefore"
                        placeholder="Add BEFORE photo to show issue"
                        watch={watch}
                        setValue={setValue}
                    />
                    <PhotoDisplay
                        fieldName="photoBefore"
                        watch={watch}
                        setValue={setValue}
                    />

                </form>

                <IonItemDivider />
                <p>Can you make safe and close out the issue now?</p>
                <IonButton onClick={handleSubmit(formSubmitNext)}>MAKE SAFE</IonButton>
                <IonItemDivider />
                <p>Do you want to make safe but notify a supervisor as further action may be needed to close out?</p>
                <IonButton onClick={handleSubmit(formSubmitNext)}>MAKE SAFE and NOTIFY</IonButton>
                <IonItemDivider />
                <p>Are you unable to make safe, and need to notify a supervisor to have them close out?</p>
                <IonButton onClick={handleSubmit(formSubmitNotify)}>NOTIFY</IonButton>

            </IonContent>

            <IonFooter class="observations-nav">
                <IonButton onClick={cancel}>Cancel</IonButton>
                <IonButton onClick={handleSubmit(formSubmitNext)}>Next</IonButton>
            </IonFooter>
        </>)
}
