import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { genericTextRegex } from "../helpers/regex"
import Input from "./form/Input"
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader
} from "@ionic/react"
import CategorySelector from "./CategorySelector"
import { MutableRefObject, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { RootState } from "../redux/store"
import { CategoryContents } from "../redux/selectors/categories"
import { hideLoadingSpinner, showLoadingSpinner } from "../redux/slices/temp"

type Inputs = {
  itemName: string
}

const schema = yup
  .object()
  .shape({
    itemName: yup
      .string()
      .matches(genericTextRegex, {
        message: "Invalid characters",
        excludeEmptyString: true
      })
      .max(50, "Too many characters")
      .required("Name cannot be blank")
  })
  .required()

interface SelectCategoryItemProps {
  selectOnly: boolean
  itemType: string
  initialItemName: string
  selectCategoryPath: (
    state: RootState,
    categoryId: number | null
  ) => { id: number | null; categoryText: string }[]
  selectCategoryContents: (
    state: RootState,
    params: { categoryId: number | null, companyId?: number | null }
  ) => CategoryContents
  selectItem: (state: RootState, itemId: number | null) => { name: string }
  selectItemFromName: (
    state: RootState,
    params: { itemName: string, categoryId: number | null, companyId?: number | null }
  ) => { id: number }
  setResult: (
    companyId: number | null,
    categoryId: number | null,
    itemId: number | null,
    itemName: string
  ) => void
  cancel: () => void
  // Set to 'true' to display loading spinner following selection
  loadingSpinner?: boolean
  // company to supply as parameter to selectCategoryContents
  // suppled as ref so it can be set after props have been supplied to useIonModal 
  companyId?: MutableRefObject<number | undefined>
}

export const SelectCategoryItem: React.FC<SelectCategoryItemProps> = ({
  selectOnly,
  itemType,
  initialItemName,
  selectCategoryPath,
  selectCategoryContents,
  selectItem,
  selectItemFromName,
  setResult,
  cancel,
  loadingSpinner,
  companyId
}) => {
  const dispatch = useAppDispatch()
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null)
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null)

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    watch
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      itemName: initialItemName
    }
  })

  const itemName = watch("itemName")

  const categoryPath = useAppSelector((state) =>
    selectCategoryPath(state, selectedCategory)
  )
  const categoryContents = useAppSelector((state) =>
    selectCategoryContents(state, { categoryId: selectedCategory, companyId: companyId?.current })
  )

  // Item data matching currently selected id (i.e. existing item user has clicked on)
  const selectedItem = useAppSelector((state) =>
    selectItem(state, selectedItemId)
  )

  // Item data matching currently displayed name and category (i.e. an existing item user has typed the name of)
  const namedItem = useAppSelector((state) =>
    selectItemFromName(state, { itemName: itemName, categoryId: selectedCategory, companyId: companyId?.current })
  )

  // If namedItem changes, user has typed the name of an existing item
  // (or changed to a category containing an existing item with the displayed name)
  // -> set selectedItemId to match (may also set to null if itemName did refer to an existing item but has been edited so it now doesn't)
  useEffect(() => {
    setSelectedItemId(namedItem?.id || null)
  }, [namedItem])

  // If selectedItemId changes, user has clicked on an existing item - set the displayed name to match
  // (may have also been changed by effect above, so don't set it if it already matches)
  useEffect(() => {
    if (selectedItemId && itemName !== selectedItem?.name) {
      setValue("itemName", selectedItem?.name)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedItemId])


  // If a loading spinner was required, hide it on the component being unmounted
  useEffect(() => () => {
    if (loadingSpinner) {
      dispatch(hideLoadingSpinner())
    }
  }, []
  )

  const onCategorySelected = (categoryId: number | null) => {
    // If user changed category, clear any previous item selection
    setSelectedItemId(null)
    setSelectedCategory(categoryId)
  }

  const onItemSelected = (itemId: number | null) => {
    setSelectedItemId(itemId)
    // If we're in 'select existing only' mode, can complete process at this point (user has clicked an existing item)
    // (otherwise, user needs to click 'ok' button as well)
    if (selectOnly && itemId) {
      const selectedItem = categoryContents.items.find(
        (item) => item.id === itemId
      )

      setResult(
        selectedItem?.companyId || null,
        selectedCategory,
        itemId,
        itemName
      )
      if (loadingSpinner) {
        dispatch(showLoadingSpinner('Loading...'))
      }
    }
  }

  const formSubmit = (data: any) => {
    const selectedItem = categoryContents.items.find(
      (item) => item.id === selectedItemId
    )
    if (selectedItem?.itemText === itemName) {
      setResult(
        selectedItem.companyId,
        selectedCategory,
        selectedItemId,
        itemName
      )
    } else {
      // If displayed name doesn't match selected item, then user has edited it - set id to null (i.e. assume they want a new item)
      setResult(null, selectedCategory, null, itemName)
    }
    if (loadingSpinner) {
      dispatch(showLoadingSpinner('Loading...'))
    }
  }

  return (
    <>
      <IonHeader>
        {selectOnly ? (
          <div className='safe-area-top'></div>
        ) : (
          <form className='safe-area-top'>
            <h2 className="p-1">{itemType} Name:</h2>
            <Input
              className="p-1"
              fieldName="itemName"
              inputMode="text"
              watch={watch}
              setValue={setValue}
              errors={errors}
            />
          </form>
        )}
      </IonHeader>
      <IonContent class="normal-page" force-overscroll="false">

        <h2 style={{ padding: "5px" }}>{itemType} Category:</h2>

        <CategorySelector
          categoryPath={categoryPath}
          categoryContents={categoryContents}
          setSelectedCategory={onCategorySelected}
          setSelectedItem={onItemSelected}
        />
      </IonContent>
      <IonFooter className="TA-nav-footer">
        <IonButton onClick={cancel}>CANCEL</IonButton>
        {selectOnly ? (
          <></>
        ) : (
          <IonButton onClick={handleSubmit(formSubmit)}>OK</IonButton>
        )}
      </IonFooter>
    </>
  )
}
