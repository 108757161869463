import { useAppDispatch } from '../../redux/hooks'
import { IonButton, IonContent, IonFooter } from "@ionic/react"

import { useHomeIconHandler } from '../../hooks/homeIconHandler'
import { homeIconAction } from '../../features/taskAnalysis/homeIconAction'
import { SelectList } from '../../components/SelectList'
import { useDraftsResources } from '../../features/taskAnalysis/resourceRequests/useDraftsResources'
import { setAlert } from '../../utils/alert'
import { deleteTa } from '../../features/taskAnalysis/deleteTa'
import { loadTa } from '../../features/taskAnalysis/loadTa'

import { TaEvent, updateSequence } from '../../features/taskAnalysis/taskAnalysisSequence'
import { format } from 'date-fns'
import { get } from 'lodash'

export const DraftRegister: React.FC = () => {
    const dispatch = useAppDispatch()

    const { draftTas } = useDraftsResources()

    const selectTa = (taId: number | null) => {

        if (taId) {
            dispatch(loadTa(taId, true))            
        }
    }

    const deleteTaSelected = (taId: number | null) => {

        if (taId) {
            dispatch(setAlert(
                'Delete SWMS',
                'Delete SWMS?',
                [{
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => true
                },
                {
                    text: "Ok",
                    handler: () => {
                        dispatch(deleteTa(taId))
                        return true
                    }
                }]
            ))
        }
    }

    useHomeIconHandler('/task-analysis', () => dispatch(homeIconAction()))

    return (
        <>
            <IonContent class="content-padding">
                                
                <SelectList
                    title='Select a Draft SWMS:'
                    listData={draftTas.map(ta => ({
                        id:ta.id,
                        text:`${format(ta.lastModifiedDate, 'EEE dd MMM yyyy HH:mm:ss')}:${get(ta, 'userData.name')}`
                    }))}
                    selectOption={selectTa}
                    deleteOption={deleteTaSelected}
                />

            </IonContent>
            <IonFooter className='TA-nav-footer'>
                <IonButton onClick={() => dispatch(updateSequence(TaEvent.START_SELECTED))}>CANCEL</IonButton>                
            </IonFooter>
        </>)
}

