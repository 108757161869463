import { format, parseJSON } from 'date-fns'
import { IonButton, IonContent, IonFooter, IonIcon, IonItem, IonList } from "@ionic/react"
import { useAppSelector, useAppDispatch } from '../../redux/hooks'

import { selectSignedinSiteId } from "../../redux/selectors/signins"
import { checkmarkCircle } from 'ionicons/icons'
import { useRegisterResources } from '../../features/incidentReports/resourceRequests/useRegisterResources'
import { useHomeIconHandler } from '../../hooks/homeIconHandler'
import { homeIconAction } from '../../features/incidentReports/homeIconAction'
import { loadIncident } from '../../features/incidentReports/loadIncident'

import './Register.css'
import { IncidentReportEvent } from '../../features/incidentReports/incidentReportsTypes'
import { updateSequence } from '../../features/incidentReports/incidentReportsSequence'
import { useState } from 'react'

export const Register: React.FC = () => {
    const dispatch = useAppDispatch()
    const signedinSiteId = useAppSelector(selectSignedinSiteId)
    const [currentSiteOnly, setCurrentSiteOnly] = useState<boolean>(true)
    const { incidents } = useRegisterResources(currentSiteOnly ? signedinSiteId : null)

    const incidentSelected = (incidentId?: number) => {
        if (incidentId) {
            dispatch(loadIncident(incidentId))
        }
    }

    useHomeIconHandler('/incidents', () => dispatch(homeIconAction()))

    return (
        <>
            <IonContent class="content-padding">
                <IonList id="incident-register-list">
                    <div id="incident-register-headings" className="incident-register-table">
                        <h2>Date</h2>
                        <h2>Incident</h2>
                        <h2>Closed</h2>
                    </div>
                    {incidents.map((incident, i) =>
                        <IonItem key={i} onClick={() => incidentSelected(incident.id)} >
                            <div className="incident-register-table">
                                <div className="flex column">
                                    <span>{format(parseJSON(incident.incidentDate), 'EEE')}</span>
                                    <span>{format(parseJSON(incident.incidentDate), 'dd MMM')}</span>
                                    <span>{format(parseJSON(incident.incidentDate), 'yyyy')}</span>
                                </div>
                                <span>{incident.description}</span>
                                {incident.closedDate
                                    ? <IonIcon icon={checkmarkCircle} size="large" />
                                    : <span></span>
                                }
                            </div>
                        </IonItem>
                    )}

                </IonList>

            </IonContent>

            <IonFooter class="incidents-nav">
                <IonButton onClick={() => dispatch(updateSequence(IncidentReportEvent.BACK_SELECTED))}
                    style={{ "gridColumnStart": "1" }}>Back</IonButton>
                {signedinSiteId ? <>
                    <IonButton color={currentSiteOnly ? 'success' : 'light'} onClick={() => setCurrentSiteOnly(true)}>Current Site</IonButton>
                    <IonButton color={currentSiteOnly ? 'light' : 'success'} onClick={() => setCurrentSiteOnly(false)}>All Sites</IonButton>
                </> : <></>}
            </IonFooter>
        </>)
}
