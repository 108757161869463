/* eslint-disable @typescript-eslint/no-unused-vars */

import { getLogger } from "loglevel"
import { AppDispatch, RootState } from "../../redux/store"
import { isPlatform } from "@ionic/react"

import { authLogin, getAuthService } from "../../services/Auth"
import { setAlert } from "../../utils/alert"

const log = getLogger("auth")

export const scopes =
  "openid profile healthandsafety api.buildaprice.entities.read api.buildaprice.users.read api.buildaprice.workers.read api.buildaprice.projects.read api.buildaprice.tasks.read"

export const login =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    log.info("login initiated")
    if (isPlatform("capacitor")) {
      dispatch(inAppLogin())
    } else {
      dispatch(inAppLogin())
      //dispatch(redirectLogin())
    }
  }

const inAppLogin = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    await authLogin()
  } catch (err: any) {
    if (err.message === 'Timeout') {
      // This will be the normal case - promise returned by authLogin will time out/resolve
      // while user is in browser login screen
      return
    }
    log.info('authService error:', err.message)
    if (err.message === 'Unable To Obtain Server Configuration') {
      dispatch(setAlert('Unable to log in', 'Your device may be offline'))
    }
  }
}

/**
 * 'Redirect' login
 *
 * Implemented as redux 'heldAction' - will be 'held' by the persistence middleware until any pending state saving operations are complete
 * (since it will cause the browser to redirect and lose any unsaved state information)
 * @returns
 */
/*
const redirectLogin =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch({
      type: "persistence/heldAction",
      payload: () => {
        try {
          getUserManager().signinRedirect({
            scope: scopes
          })
        } catch (err) {
          log.warn(`redirectLogin: signinRedirect error ${err}`)
        }
      }
    })
  }
*/