import { createSelector } from '@reduxjs/toolkit'
import Project from '../../datastore/models/Project'
import type { RootState } from '../../redux/store'
import { getResource, getResources } from '../../datastore'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { ProjectSite } from '../../datastore/models'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'

const selectAllProjects = (state: RootState) => state.resources.projects
const selectProjectParams = selectParamsMemo()
/**
 * Get a project by id
 */
export const selectProject = createSelector([selectAllProjects, selectProjectParams], (allProjects, params) => {

    return getResource<Project>(allProjects, params.projectId)
})

const selectProjectsResources = selectResourcesMemo(['projects','sites', 'projectSites'])
const selectProjectsParams = selectParamsMemo()
/**
 * Get array of projects:
 * 
 * - By siteId
 * - All available projects if no siteId specified
 */
export const selectProjects = createSelector([selectProjectsResources, selectProjectsParams], (resources, params) => {

    if (params.siteId) {
        const projectSites = getResources<ProjectSite>(resources, 'projectSites', {eqFilter: { site: params.siteId }})
        return (
            projectSites.map(projectSite => getResource<Project>(resources.projects, projectSite.project))
                .filter(project => project) // Remove any null projects, i.e. ProjectSite with no corresponding Project
        ) as Project[]
    } else {
        return getResources<Project>(resources, 'projects')
    }
})
