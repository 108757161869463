import { createSelector } from "@reduxjs/toolkit"
import { uniqBy } from "lodash"
import { getResource, getResources } from "../../datastore"
import { ProjectSite, SiteContact, SiteUser } from "../../datastore/models"
import User from "../../datastore/models/User"
import { selectParamsMemo } from "./helpers/selectParamsMemo"
import { selectResourcesMemo } from "./helpers/selectResourcesMemo"

const selectUsersResources = selectResourcesMemo(["users"])
const selectUsersParams = selectParamsMemo()
export const selectUsers = createSelector(
  [selectUsersResources, selectUsersParams],
  (resources, params) => {
    const {
      companyIds,
      projectIds,
      superviseProjectIds,
      userIds,
      removeDuplicates
    } = params

    let users: User[] = []
    // If selecting by explicit id(s), don't need any other criteria
    if (userIds) {
      users = getResources<User>(resources, "users", {
        inFilter: { id: userIds }
      })
    }

    // canSuperviseProject permission will be explicitly by project (not applied at company level at this point)
    else if (superviseProjectIds) {
      users = getResources<User>(resources, "users", {
        inFilter: { superviseProjects: superviseProjectIds }
      })
    } else if (companyIds) {
      const usersByCompany = getResources<User>(resources, "users", {
        inFilter: { companies: companyIds }
      })
      // If selecting users by project, need to find by project and company separately - most users will have permission assigned at company level
      // rather than project specifically. 'Users by Project' queries must specify both company and project
      const usersByProject = projectIds
        ? getResources<User>(resources, "users", {
            inFilter: { projects: projectIds }
          })
        : []

      users = uniqBy(usersByCompany.concat(usersByProject), "id")
    } else {
      // If no parameters specified, get all available users
      users = getResources<User>(resources, "users", {})
    }

    // BP users often exist as both workers and users, meaning the same person appears twice. 'removeDuplicates' option
    // is used to remove unnecessary duplicate names in select lists
    if (removeDuplicates) {
      users = users.reduce((acc: User[], testUser) => {
        if (
          !acc.some(
            (accUser) =>
              accUser.firstName === testUser.firstName &&
              accUser.lastName === testUser.lastName
          )
        ) {
          // No user with this name in acc yet
          // -> find all users with the same name
          const duplicates = users.filter(
            (user) =>
              user.firstName === testUser.firstName &&
              user.lastName === testUser.lastName
          )
          if (duplicates.length === 1) {
            // If there is only one user with this name, all good
            acc.push(testUser)
          } else {
            const dupesWithPhone = duplicates.filter((user) => user.phone)
            if (dupesWithPhone.length) {
              // Prefer user records that have a phone number. If there's more than one, keep all, just in case
              // they're different people
              acc = acc.concat(uniqBy(dupesWithPhone, "phone"))
            } else {
              // All users with no phone should have an email, but filter in case
              const dupesWithEmail = duplicates.filter((user) => user.email)
              if (dupesWithEmail.length) {
                acc = acc.concat(uniqBy(dupesWithEmail, "email"))
              } else {
                // If we got here then this user has no phone or email
                acc.push(testUser)
              }
            }
          }
        }
        return acc
      }, [])
    }

    return users.sort((a, b) =>
      `${a.firstName}${a.lastName}`.localeCompare(`${b.firstName}${b.lastName}`)
    )
  }
)

const selectUserParams = selectParamsMemo()
export const selectUser = createSelector(
  [selectUsersResources, selectUserParams],
  (resources, params) => {
    return getResource<User>(resources.users, params.userId)
  }
)

const selectSiteUsersResources = selectResourcesMemo([
  "projects",
  "sites",
  "projectSites",
  "siteUsers",
  "users"
])
const selectSiteUsersParams = selectParamsMemo()
/**
 * Select users from siteUser records:
 * params.siteId: selects all siteUsers for given site
 * params.projectId: selects all siteUsers for site(s) associated with given project
 * (i.e. still returns full set of site users, some of whom may me assigned by / belong to diffferent projects)
 */
export const selectSiteUsers = createSelector(
  [selectSiteUsersResources, selectSiteUsersParams],
  (resources, params) => {
    let siteUserIds: number[] = []

    if (params.siteIds) {
      const siteUsers = getResources<SiteUser>(resources, "siteUsers", {
        inFilter: { site: params.siteIds }
      })
      siteUserIds = siteUsers.map((siteUser) => siteUser.user)
    } else if (params.siteId) {
      const siteUsers = getResources<SiteUser>(resources, "siteUsers", {
        eqFilter: { site: params.siteId }
      })
      siteUserIds = siteUsers.map((siteUser) => siteUser.user)
    } else if (params.projectId) {
      // Get projectSite record for site(s) associated with specified project
      const projectSites = getResources<ProjectSite>(
        resources,
        "projectSites",
        {
          eqFilter: { project: params.projectId }
        }
      )
      const siteUsers = getResources<SiteUser>(resources, "siteUsers", {
        inFilter: { site: projectSites.map((projectSite) => projectSite.site) }
      })
      siteUserIds = siteUsers.map((siteUser) => siteUser.user)
    }

    return getResources<User>(resources, "users", {
      inFilter: { id: siteUserIds }
    }).sort((a, b) =>
      `${a.firstName} ${a.lastName}`.localeCompare(
        `${b.firstName} ${b.lastName}`
      )
    )
  }
)

const selectSiteContactUsersResources = selectResourcesMemo([
  "sites",
  "siteContacts",
  "users"
])
const selectSiteContactUsersParams = selectParamsMemo()
/**
 * Select users from siteContact records
 */
export const selectSiteContactUsers = createSelector(
  [selectSiteContactUsersResources, selectSiteContactUsersParams],
  (resources, params) => {
    const eqFilter: Record<string, string | number> = {}

    if (params.siteId) {
      eqFilter.site = params.siteId
    }
    if (params.contactType) {
      eqFilter.contactType = params.contactType
    }

    const siteContacts = getResources<SiteContact>(resources, "siteContacts", {
      eqFilter
    })

    return getResources<User>(resources, "users", {
      inFilter: { id: siteContacts.map((siteContact) => siteContact.user) }
    }).sort((a, b) =>
      `${a.firstName} ${a.lastName}`.localeCompare(
        `${b.firstName} ${b.lastName}`
      )
    )
  }
)
