import type { AppDispatch, RootState } from '../../../redux/store'

type ThunkFn = (dispatch: AppDispatch, getState: () => RootState, services: any) => any

/**
 * Create a 'reactor', i.e. a special class of selector that should return a thunk that should run conditionally on state values
 * 
 * Receives an array of 'input selectors' which are called and the results compared to the previous values. If there is a change the 
 * supplied 'reactor' function is called
 * 
 * Similar to RTK 'createSelector', except that a 'createSelector' function will continue to output it's last value if 
 * there is no change in inputs, whereas createReactor will only return the thunk once on a change in inputs
 * 
 * @param inputs  - array of 'input selectors'
 * @param reactor - function which receives results of inputs as a parameter list, and should return a thunk if input conditions require it to run
 *                  (or undefined otherwise)
 * @returns thunk or undefined
 */
export const createReactor = (inputs: ((state:RootState) => any)[],   reactor: (...args:any) => ThunkFn | undefined) => {

    const lastValues:any[] = new Array(inputs.length)

    return (state:RootState) => {
        let change = false
        inputs.forEach((input, i) => {
            const value = input(state)
            if (value !== lastValues[i]) {
                change = true
                lastValues[i] = value
            }
        })
        if (change) {
            return reactor(...lastValues)
        }
    }
}