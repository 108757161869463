import normalize from '@disruptph/json-api-normalizer'
import Axios from 'axios'
import { AXIOS_GET_TIMEOUT } from '.'
import { getApiRoot } from '../helpers/getApiRoot'
import { ApiResult } from '../interfaces/api'
import { getAccessToken } from './accessToken'
import { errorCheckedReq } from './helpers/errorCheckedRequest'

export const apiGetResource = errorCheckedReq(async (endpoint: string) => {
    const response = await Axios.get(`${getApiRoot()}resources/${endpoint}`, {
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      },
      timeout: AXIOS_GET_TIMEOUT
    })
    return {
      result: ApiResult.SUCCESS,
      responseData: Object.assign({}, normalize(response.data))
    }
  })
  