import { RootState } from "../store"

import { selectHazardCategory } from '../../redux/selectors/hazardsAndControls'
import { selectHazardCategoryContents, selectHazardCategoryPath } from '../../redux/selectors/categories'
import { getResource } from "../../datastore"
import { HazardCategory } from "../../datastore/models"
import { TaHazard } from "../../datastore/models/Ta"

export const selectRootCategoriesFromHazards = (hazards: TaHazard[]) => (state: RootState) => {
   
    // Get an array of the hazard types currently selected for this step
    const hazardTypes = hazards.reduce((hazardTypes: number[], hazard) => {
        return hazard.hazardTypeId ? hazardTypes.concat([hazard.hazardTypeId]) : hazardTypes
    }, [])

    const rootCategories: number[] = []

    for (const hazardType of hazardTypes) {
        const category = selectHazardCategory(state, { hazardTypeId: hazardType })
        const categoryPath = selectHazardCategoryPath(state, category)
        // categoryPath[0] is a single top level item (e.g. 'All Hazard Categories'), categoryPath[1] contains the first actual category
        if (categoryPath[1] && categoryPath[1].id) {
            if (!rootCategories.includes(categoryPath[1].id))
                rootCategories.push(categoryPath[1].id)
        }
    }

    return rootCategories
}


export const selectHazardCategoriesContents = (categoryList: number[]) => (state: RootState) => {

    return categoryList.map(categoryId => {
        const category = getResource<HazardCategory>(state.resources.hazardCategories, categoryId)

        return {
            id: categoryId,
            categoryText: category?.categoryText || '',
            contents: selectHazardCategoryContents(state, { categoryId, recurse: true })
        }
    }).sort((a, b) => a.categoryText.localeCompare(b.categoryText))
}
