import { differenceInSeconds, isValid, parseJSON } from 'date-fns'
import { get } from 'lodash'
import { LocalResourceData, ResourceType } from "../../redux/slices/resources"
import { RootState } from "../../redux/store"
import { selectRequestResult/*, selectRequestInProgress*/ } from "../../redux/selectors/resourceMeta"
import { RequestResourcesOptions } from '../requestResources'
import { RequestStatus } from '../types'

export const requestIsDuplicate = (resourceType: ResourceType, opts: RequestResourcesOptions, minInterval: number, getState: () => RootState) => {

    const state = getState()

    // Check if a request with the same request key is in progress - if so do NOT
    // flag as duplicate (this case is handled elsewhere)
    // TODO: may eliminate explicitly named requests in future, making this check redundant
    const status = selectRequestResult(state, {requestKey: opts.requestKey })
    if (status.status !== RequestStatus.NONE) {
        return false
    }

    const resourceId = opts.eqFilter?.id as string | number
    if (resourceId) {
        const resource = get(getState(), ['resources', resourceType, resourceId])
        if (resource && requestedWithinMinInterval(resource, minInterval)) {
            return true
        }
    }

    const resourceIds = (opts.inFilter?.id as (string | number)[])
    if (resourceIds) {
        const isDuplicate = resourceIds.reduce((acc: boolean, resourceId) => {
            const resource = get(getState(), ['resources', resourceType, resourceId])
            return acc && resource && requestedWithinMinInterval(resource, minInterval)
        }, true)
        return isDuplicate
    }
    return false
}


const requestedWithinMinInterval = (resource: LocalResourceData, minInterval: number) => {
    const lastRequestedDate = parseJSON(resource.lastRequestedDate)
    if (isValid(lastRequestedDate) && differenceInSeconds(new Date(), lastRequestedDate) < minInterval) {
        return true
    }
    return false
}
