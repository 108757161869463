import { RootState, AppDispatch } from '../../redux/store'
import { clearWriteQueue, clearResourceStatus } from '../../redux/slices/resourceMeta'
import { removeResource, removeResourceType, ResourceType } from '../../redux/slices/resources'
import { setProject, setViewSite } from '../../redux/slices/app'
import { setUser } from './setUser'
import { getResources } from '../../datastore'
import { DocInfo } from '../../datastore/models'
import { localStore } from '../../storage/storage'
import { clearDocStatus } from '../../redux/slices/docMeta'

const clearOnLogout = [
    'companies',
    'docInfo',
    'incidents',
    'incidentActions',
    'incidentWorkers',
    'inductions',
    'projectSites',
    'projects',
    'signinDocs',
    'signins',
    'siteContacts',
    'siteHazards',
    'siteObservations',
    'siteObservationActions',
    'sitePpe',
    'sites',
    'taTemplates',
    'tas',
    'taWorkers',
    'toolboxes',
    'tbAttendees',
    'tbIncidents',
    'tbObservations',
    'tbOther',
    'tbSiteHazards',
    'tbTas',
    'tbTasks',
    'users'
]


/**
 * Delete all non-public data from redux store (will be called on logout to prevent potential data leakage)
 * 
 * Data deleted will include:
 * 
 * - Any resource types in 'clearOnLogout' list above 
 * - Future - any individual resources that are company - specific
 *   e.g. most hazard / control types will be public and should be retained, however those having a non null 'company' relationship
 *   are company specific and should be deleted?
 */
export const logoutPurgeResources = () => async (dispatch: AppDispatch, getState: () => RootState) => {

    let state = getState()

    // Find and remove all non-public documents
    //const nonPublicDocInfo = getResources<DocInfo>(state.resources, 'docInfo', { neqFilter: { company: 1 } })
    const nonPublicDocInfo = getResources<DocInfo>(state.resources, 'docInfo', { neqFilter: { viewAccess: 'canSigninHealthAndSafety' } })
    nonPublicDocInfo.forEach(docInfo => dispatch(removeResource({ resourceType: 'docInfo', id: docInfo.id.toString() })))

    state = getState()

    // Whatever's left must be public
    const publicDocIds = getResources<DocInfo>(state.resources, 'docInfo').map(docInfo => docInfo.id)

    // Find and remove status info for non-public documents being removed
    const docMetaToRemove = Object.keys(state.docMeta.docStatus).filter(key => !publicDocIds.includes(+key))
    docMetaToRemove.forEach(key => dispatch(clearDocStatus(key)))

    for (const resourceType in state.resources) {

        if (clearOnLogout.includes(resourceType)) {
            // Delete entire resource type
            dispatch(removeResourceType(resourceType))
            // Delete metadata for resource type (to trigger a full re-read next login)
            dispatch(clearResourceStatus(resourceType as ResourceType))
        } else {
            // TODO: delete individual non-public resources?
        }
    }

    dispatch(setUser(null))
    dispatch(setViewSite(null))
    dispatch(setProject(null))
    dispatch({ type: 'pgSignin/setInitial' })
    dispatch({ type: 'pgTaskAnalysis/setInitial' })
    dispatch({ type: 'pgToolbox/setInitial' })
    dispatch({ type: 'pgSiteObservations/setInitial' })
    dispatch({ type: 'pgIncidentReports/setInitial' })
    dispatch({ type: 'pgDocuments/setInitial' })
    dispatch(clearWriteQueue())

    // Remove non-public documents from store
    const storeKeys = await localStore.keys()
    await Promise.all(storeKeys.map(async key => {    
        if (typeof key === 'string' && key.startsWith('docs/')) {
            const docId = +key.split('/')[1]
            if (!publicDocIds.includes(docId)) {
                await localStore.remove(key)                
            }
        }        
    }))
}