import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { requestResourcesWithRelated } from '../../../datastore'
import { selectOpenSites } from '../../../redux/selectors/sites'
import { selectUser } from '../../../redux/selectors/users'
import { selectCanCreateIncidentSites } from '../../../redux/selectors/abilities'

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results 
 * 
 * @returns  requested resources 
 */
export const useDetails1Resources = (reporterId: number) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(requestResourcesWithRelated('projects', {
            eqFilter: { status: 'OPEN' }
        }, ['projectSites.sites']))

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    const sites = useAppSelector(state => selectOpenSites(state, {}))

    return {
        availableSites: useAppSelector(state => selectCanCreateIncidentSites(state, sites)),
        reporter: useAppSelector(state => selectUser(state, { userId: reporterId }))
    }
}