import { useState } from 'react'
import { IonButton, IonContent, IonFooter, IonItem, IonList, useIonModal } from "@ionic/react"
import { useAppSelector, useAppDispatch } from '../../redux/hooks'

import SignatureModal from '../../components/SignatureModal'
import { setTaEditDirty, setTaWorker } from '../../redux/slices/pgTaskAnalysis'
import { saveResource } from '../../datastore'

import './Signoff.css'
import { selectTaEdit } from '../../redux/selectors/tas'
import { selectUsers } from '../../redux/selectors/users'
import { useHomeIconHandler } from '../../hooks/homeIconHandler'
import { homeIconAction } from '../../features/taskAnalysis/homeIconAction'
import { idMatchesResource } from '../../datastore'
import { userContact } from '../../features/reportRequests/helpers/userContact'
import { TaEvent, updateSequence } from '../../features/taskAnalysis/taskAnalysisSequence'


export const Signoff: React.FC = () => {
    const dispatch = useAppDispatch()
    const taEdit = useAppSelector(selectTaEdit)
    const { toolboxMode, toolboxTa } = useAppSelector(state => state.pgTaskAnalysis)
    const [workerIndex, setWorkerIndex] = useState<number>(0)
    const users = useAppSelector(state => selectUsers(state, { userIds: taEdit?.workers?.map(worker => worker.user) }))

    const getContactDetails = (index: number) => {
        const worker = taEdit.workers[index]
        return userContact(users.find(user => idMatchesResource(worker?.user, user)))
    }

    const signSelected = (index: number) => {
        if (!taEdit.workers[index].signature) {
            setWorkerIndex(index)
            presentSignatureModal()
        }
    }
    const signatureModalSetSignature = (sigData: string) => {
        dismissSignatureModal()

        // Update the worker entry in taEdit
        dispatch(setTaWorker({ index: workerIndex, worker: { signature: sigData } }))

        // Update taWorker record
        dispatch(saveResource(
            {
                id: taEdit.workers[workerIndex]?.id,
                signature: sigData
            }, 'taWorkers'
        ))
        dispatch(setTaEditDirty(false))
    }

    const signatureModalDismiss = () => {
        dismissSignatureModal()
    }

    const [presentSignatureModal, dismissSignatureModal] = useIonModal(SignatureModal, {
        title: 'Sign on to SWMS',
        message: 'I confirm that I have been consulted in the preparation of this SWMS, and have read it and will comply with its requirements.',
        contact: getContactDetails(workerIndex),
        sigData: '',
        setSignature: signatureModalSetSignature,
        dismiss: signatureModalDismiss
    })

    useHomeIconHandler('/task-analysis', () => dispatch(homeIconAction()))

    const toolboxReview = toolboxMode && toolboxTa
    return (
        <>
            <IonContent id="signoff-page" className="content-padding">
                <h2>Worker Sign On:</h2>
                <IonList>

                    {taEdit.workers.filter(worker => !worker.deleted).map((worker, i) => {
                        const contact = getContactDetails(i)                        
                        return (
                            <IonItem key={i} onClick={() => signSelected(i)}>
                                <div>
                                    <p>{`${contact.firstName} ${contact.lastName}${i === 0 ? ' (supervisor)' : ''}`} </p>
                                    {worker.signature ? <div><img alt="Worker signature" src={worker.signature} /></div> : <></>}
                                </div>
                            </IonItem>
                        )
                    })}

                </IonList>

            </IonContent>
            <IonFooter className='TA-nav-footer'>            
                <IonButton onClick={() => dispatch(updateSequence(TaEvent.BACK_SELECTED))}>Review</IonButton>
                {toolboxReview ? <></> : <IonButton onClick={() => dispatch(updateSequence(TaEvent.START_SELECTED))}>Back to Start</IonButton>}
            </IonFooter>
        </>
    )
}

