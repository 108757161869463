import { getResourceById } from './helpers/getResourceById'
import { flattenResource } from './helpers/flattenResource'
import { ResourceMap } from '../redux/slices/resources'

export function getResource<Type>(resourceMap: ResourceMap, resourceId?: number | null) {
    if (resourceId) {
        const resource = getResourceById(resourceMap, resourceId)
        if (resource) {
            return (flattenResource(resource) as unknown) as Type
        }
    }
    return null
}