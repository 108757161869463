import { AppDispatch, RootState } from '../../../redux/store'
import { requestResources, requestResourcesWithRelated } from '../../../datastore'
import { ResourceEvent } from '../../../datastore/types'
import { setRequestComplete, setRequestStart } from '../../../redux/slices/resourceMeta'
import { resourceEventCombined } from '../../../datastore/resourceEventCombined'

/**
 * Requests specified incident report plus it's site, action, worker, and related user records
 * @param params { incidentId }
 * @returns 
 */
export const requestIncident = (params: { [key: string]: any }) =>
    async (dispatch: AppDispatch, getState: () => RootState, services: any): Promise<ResourceEvent> => {
        const { incidentId, requestKey } = params

        if (requestKey) {
            dispatch(setRequestStart({ requestKey }))
        }

        const results = await Promise.all([
            requestResourcesWithRelated('incidents', {
                eqFilter: { id: incidentId },
                include: ['reporter', 'actionVerifier']
            }, ['sites', 'incidentActions'])(dispatch, getState, services),

            requestResources('incidentWorkers', {
                eqFilter: { incident: incidentId },
                include: ['user']
            })(dispatch, getState, services)
        ])

        const requestResult = resourceEventCombined(results[0], [results[1]])
        if (requestKey) {
            dispatch(setRequestComplete({ requestKey, resourceEvent: requestResult }))
        }
        return requestResult
    }