import { DocData } from '../docstore/types'
import HtmlDocContainer from './HtmlDocContainer'
import PdfDocContainer from './PdfDocContainer'
import ImgDocContainer from './ImgDocContainer'

import './DocContainer.css'
import { IonSpinner } from '@ionic/react'
import { useDocument } from '../docstore/useDocument'

const imageTypes = ['png', 'jpg', 'jpeg', 'svg']

interface DocContainerProps {
    docId: number | null
    selectorParams: Record<string, any>
    refreshTrigger?: unknown,   // reference to anything which, on change, will trigger re-rendering
    setUserData?: (userData: Record<string, any>) => void
    setIsValid?: (isValid: boolean) => void
    setWarnMessages?: (warnMessages: string[]) => void
    setBlockMessages?: (blockMessages: string[]) => void
    setNumPages?: (numPages: number) => void
    setIsLoading?: (isLoading: boolean) => void
    pageNum?: number
    containerClass?: string
}

export type DocTypeContainerProps = Omit<DocContainerProps, "docId"> & { docData?: DocData, isError?: boolean }

const DocContainer: React.FC<DocContainerProps> = ({
    docId,
    selectorParams,
    refreshTrigger,
    setUserData,
    setIsValid,
    setWarnMessages,
    setBlockMessages,
    setNumPages,
    setIsLoading,
    pageNum,
    containerClass
}) => {

    const { docData, isError } = useDocument(docId)

    if (docData) {
        if (docData.docBlob && docData.docExt === 'html') {
            return <HtmlDocContainer
                docData={docData}
                selectorParams={selectorParams}
                refreshTrigger={refreshTrigger}
                setUserData={setUserData}
                setIsValid={setIsValid}
                setWarnMessages={setWarnMessages}
                setBlockMessages={setBlockMessages}
                setIsLoading={setIsLoading}
                containerClass={containerClass}
            />
        }
        if (docData.docBlob && docData.docExt === 'pdf') {
            return <PdfDocContainer
                docBlob={docData.docBlob}
                setUserData={setUserData}
                setIsValid={setIsValid}
                setNumPages={setNumPages}
                setIsLoading={setIsLoading}
                pageNum={pageNum}
            />
        }
        if (docData.docBlob && imageTypes.includes(docData.docExt)) {
            return <ImgDocContainer
                docBlob={docData.docBlob}
                setUserData={setUserData}
                setIsValid={setIsValid}
                setIsLoading={setIsLoading}
            />
        }

    }
    // If no data, show error message or spinner (if no error then hopefully still loading...)
    return <div className='flex centre-row' style={{ "paddingBottom": "10px" }}>
        {isError
            ? <h3>Unable to load Document</h3>
            : <IonSpinner name="circles" />
        }
    </div>

};

export default DocContainer;