import { getRiskBand, getRiskScore } from '../../../helpers/riskScore'
import { TaHazard, TaStep, TaStepState } from '../../../datastore/models/Ta'

/**
 * Determine whether risk scoring and mitigation is complete for a given hazard
 * 
 * Complete if:
 * - All consequence / likelyhood values set
 * - Residual risk is scored in the 'Low' band
 * - At least one control has been applied, unless initial risk was deemed to be in the 'Low' band
 *
 */
export const getRiskScoringComplete = (hazard: TaHazard) => (
    !!(
        hazard.irConsequence &&
        hazard.irLikelyhood &&
        hazard.rrConsequence &&
        hazard.rrLikelyhood &&
        getRiskBand(getRiskScore(hazard.rrConsequence, hazard.rrLikelyhood)) === 'Low' &&
        (hazard.controls.length || hazard.customControls.length || getRiskBand(getRiskScore(hazard.irConsequence, hazard.irLikelyhood)) === 'Low')
    )
)

/**
 * Risk scoring complete for all hazards in step 
 *
 */
export const getStepRiskScoringComplete = (step: TaStep) => {
    if (step.hazards.length) {
        // If step has hazards configured, return true if they are all risk scored / mitigated AND state is 'COMPLETE'
        // (note that a template might include a step with completed hazards but with state set to add/review to ensure user checks it)
        return step.hazards.reduce((complete: boolean, hazard) => {
            return complete && getRiskScoringComplete(hazard)
        }, true) && step.state !== TaStepState.REVIEW_HAZARDS && step.state !== TaStepState.ADD_HAZARDS
    } else {
        // If no hazards configured, return true if user has confirmed no hazards exist
        return step.state === TaStepState.COMPLETE
    }
}