const companies = {
  table: 'companies',
  idKey: 'id',

  attrs: [
    'companyName',
    'address',
    'region',
    'templateDocs',
    'linkedEntity',
    'logo',
    'creationDate',
    'lastModifiedDate',
    'active',
    'deleted'
  ],

  relationships: [],
}

export default companies