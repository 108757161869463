import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { IonCard, IonCol, IonContent, IonGrid, IonRow } from "@ionic/react"
import { taReturnToToolbox } from '../../features/taskAnalysis/taskAnalysisToolbox'
import './Start.css'
import { useHomeIconHandler } from '../../hooks/homeIconHandler'
import { homeIconAction } from '../../features/taskAnalysis/homeIconAction'
import { TaEvent, updateSequence } from '../../features/taskAnalysis/taskAnalysisSequence'
import { selectCanCreateAnyTa } from '../../redux/selectors/abilities'
import { createTa } from '../../features/taskAnalysis/createTa'
import { useState } from 'react'
import { useStartResources } from '../../features/taskAnalysis/resourceRequests/useStartResources'

interface Props {
    // Modals are instantiated in the containing 'index' component - this fn is used to present them
    presentModal: (modalKey: string) => void
}

export const Start: React.FC<Props> = ({ presentModal }) => {
    const dispatch = useAppDispatch()
    const { toolboxMode } = useAppSelector(state => state.pgTaskAnalysis)
    const canCreateTa = useAppSelector(state => selectCanCreateAnyTa(state))
    // Change in refreshTemplates triggers useStartResources to make request
    const [refreshTemplates, setRefreshTemplates] = useState<number>(0)
    const currentPath = useAppSelector(state => state.temp.currentPath)


    useStartResources(refreshTemplates, currentPath === '/task-analysis')

    const selectTaTemplate = () => {
        setRefreshTemplates(refreshTemplates + 1)
        presentModal('selectTaTemplate')
    }

    useHomeIconHandler('/task-analysis', () => dispatch(homeIconAction()))

    return (
        <>
            <IonContent class="content-padding">

                <h2>How would you like to start this SWMS?</h2>
                <IonGrid fixed={true} className="app-grid">
                    <IonRow>
                        <IonCol>
                            <div id="ta-start-grid">
                                <IonCard
                                    button={true}
                                    onClick={() => dispatch(createTa())}
                                    disabled={!canCreateTa}
                                >
                                    <div>
                                        <span>Create NEW</span>
                                        <span>SWMS</span>
                                    </div>
                                </IonCard>
                                <IonCard
                                    button={true}
                                    onClick={selectTaTemplate}
                                    disabled={!canCreateTa}
                                >
                                    <div>
                                        <span>Use Existing</span>
                                        <span>Template</span>
                                    </div>
                                </IonCard>
                                {toolboxMode ?
                                    <IonCard className="full-width-card" button={true} onClick={() => dispatch(taReturnToToolbox())}>
                                        <span>Return to Toolbox Meeting</span>
                                    </IonCard>
                                    : <>
                                        <IonCard
                                            button={true}
                                            onClick={() => dispatch(updateSequence(TaEvent.DRAFT_REGISTER_SELECTED))}
                                            disabled={!canCreateTa}
                                        >
                                            <div>
                                                <span>Edit a DRAFT</span>
                                                <span>SWMS</span>
                                            </div>
                                        </IonCard>
                                        <IonCard button={true} onClick={() => dispatch(updateSequence(TaEvent.ACTIVE_REGISTER_SELECTED))}>
                                            <div>
                                                <span>Review ACTIVE</span>
                                                <span>SWMS</span>
                                            </div>
                                        </IonCard>
                                    </>
                                }
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </>)
}

