import log from "loglevel"
import { AppDispatch, RootState } from "../../redux/store"
import { saveResource } from "../../datastore"
import { setDocList, setSigninCode, setSigninSite } from "../../redux/slices/pgSignin"
import { selectLatestSignin } from '../../redux/selectors/signins'
import { logout } from "../auth"
import { setAlert } from "../../utils/alert"
import { stTOOLBOX } from "../toolbox/toolboxTypes"
import { closeToolbox } from "../toolbox/closeToolbox"
import { stopSiteResourceUpdate } from "../resources/siteResourceUpdate"
import { updateSequence } from "./signinSequence"
import { SigninEvent } from "./signinTypes"


/**
 * Dispatched on 'signout' by user
 */
export const signout = () => (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState()
    const user = state.app.user
    if (user) {
        const signin = selectLatestSignin(state, { siteId: null, userId: user.id })
        if (signin) {
            dispatch(saveResource({
                ...signin,
                signoutTime: new Date()
            }, 'signins'))
        }

        if (user.isGuest) {
            // Guest cannot be logged in when signed out of a site - log them out
            log.info('Guest user automatically logged out on signout')
            dispatch(logout())
        } else {
            dispatch(setSigninSite(null))
            dispatch(setSigninCode(''))
            dispatch(setDocList([]))
        }
    }    
    dispatch(stopSiteResourceUpdate())
    dispatch(updateSequence(SigninEvent.RESET_SEQUENCE))
}

export const signoutWithConfirm = (siteName: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()

    if (state.pgToolbox.stToolbox !== stTOOLBOX.START && 
        state.pgToolbox.stToolbox !== stTOOLBOX.REGISTER &&
        state.pgToolbox.stToolbox !== stTOOLBOX.REVIEW) {
        // User is trying to sign out while part way through a toolbox meeting
        dispatch(setAlert('Toolbox in Progress', 'You have a toolbox meeting in progress, if you sign out it will be closed',
            [{
                text: 'Cancel',
                role: 'cancel'
            },
            {
                text: 'Close Toolbox and Sign Out',
                handler: () => {
                    dispatch(closeToolbox())
                    dispatch(signout())
                    return true
                }
            }]
        ))
    } else {
        dispatch(setAlert('Confirm Signout', `Sign out of ${siteName}?`,
            [{
                text: 'Cancel',
                role: 'cancel'
            },
            {
                text: 'Sign Out',
                handler: () => { dispatch(signout()); return true }
            }]
        ))
    }
}
