import { deleteResource, saveResource } from '../../datastore'
import { AppDispatch, RootState } from '../../redux/store'
import { selectSite } from '../../redux/selectors/sites'
import log from 'loglevel'
import { savePhoto } from '../../docstore/savePhoto'
import { setSiteObservationActionId, setSiteObservationDetails, setSiteObservationDirty } from '../../redux/slices/pgSiteObservations'
import dateToISOString from '../../helpers/dateToIsoString'
import parseDate from '../../helpers/parseDate'

export const saveObservation = () => async (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState()
    const { dirty, siteObservationEdit: { details, actions } } = state.pgSiteObservations

    const site = selectSite(state, { siteId: details.site })
    if (!site) {
        log.warn('Failed to save observation - site not found')
        return
    }

    if (dirty) {

        const photoBefore = await savePhoto(
            details.photoBefore,
            'Observation Before',
            { company: site.company, site: site.id }
        )(dispatch, getState)
        const photoAfter = await savePhoto(
            details.photoAfter,
            'Observation After',
            { company: site.company, site: site.id }
        )(dispatch, getState)

        const observationId = saveResource({
            ...details,
            company: site.company,
            photoBefore: photoBefore || null,
            photoAfter: photoAfter || null,

        }, 'siteObservations')(dispatch, getState)
        dispatch(setSiteObservationDetails({ id: observationId }))

        actions.forEach((action, index) => {

            if (action.deleted) {
                dispatch(deleteResource(action.id, 'siteObservationActions'))

            } else if (action.dirty) {

                const actionId = saveResource({
                    id: action.id,
                    company: site?.company,
                    observation: observationId,
                    actionText: action.actionText,
                    creator: action.creator,
                    assignedTo: action.assignedTo,
                    completedDate: dateToISOString(parseDate(action.completedDate))

                }, 'siteObservationActions')(dispatch, getState)
                dispatch(setSiteObservationActionId({ index, id: actionId }))
            }
        })
        dispatch(setSiteObservationDirty(false))
    }
}