import { useAppSelector, useAppDispatch } from '../../redux/hooks'

import { IonContent, IonItemDivider } from '@ionic/react'
import { selectHazardCategory, selectHazardType } from '../../redux/selectors/hazardsAndControls'

import { setTaHazardIrConsequence, setTaHazardIrLikelyhood } from '../../redux/slices/pgTaskAnalysis'
import { selectHazardEdit, selectStepEdit } from '../../redux/selectors/tas'
import { selectHazardCategoryPath } from '../../redux/selectors/categories'
import RiskIndicator from '../../components/RiskIndicator'
import { getRiskScore } from '../../helpers/riskScore'
import TaskAnalysisNav from './TaskAnalysisNav'
import './RiskScoring.css'
import TaskHeading from './TaskHeading'
import RiskSlider from '../../components/RiskSlider'
import { useHomeIconHandler } from '../../hooks/homeIconHandler'
import { homeIconAction } from '../../features/taskAnalysis/homeIconAction'

export const IrScore: React.FC = () => {
    const dispatch = useAppDispatch()

    const { selectedStepIndex } = useAppSelector((state) => state.pgTaskAnalysis)
    const stepEdit = useAppSelector(selectStepEdit)
    const hazardEdit = useAppSelector(selectHazardEdit)
    const selectedHazardType = useAppSelector((state) => selectHazardType(state, { hazardTypeId: hazardEdit?.hazardTypeId }))
    const selectedHazardCategory = useAppSelector((state) => selectHazardCategory(state, { hazardTypeId: hazardEdit?.hazardTypeId }))
    const selectedHazardCategoryPath = useAppSelector((state) => selectHazardCategoryPath(state, selectedHazardCategory))

    useHomeIconHandler('/task-analysis', () => dispatch(homeIconAction()))

    return (
        <>
            <IonContent className="risk-page content-padding">
                <TaskHeading stepNumber={true} />
                <IonItemDivider />
                <h3 id="step-name">{`${selectedStepIndex + 1}. ${stepEdit?.text}`}</h3>
                <IonItemDivider />

                <div className="tree-grid-risk-page">
                    {selectedHazardType ? <>
                        <h4 className={`col-1-item`} >{selectedHazardCategoryPath[selectedHazardCategoryPath.length - 1].categoryText + ' >'}</h4>
                        <h1 className={`col-1-item`} >{selectedHazardType.hazardText}</h1>
                    </>
                        : <h1 className={`col-1-item`} >{hazardEdit?.customHazardType}</h1>
                    }

                </div>


                <div className="slider-display-container">
                    <h2 className="bp-orange">If the worst thing happened as a result of this hazard, how SERIOUS would it be?</h2>
                    <RiskSlider
                        color='red'
                        labels={[
                            'Minor (No Treatment or Minor First Aid Injury)',
                            'Significant (Medical Treatment Injury or Restricted Activity)',
                            'Serious (Lost Time Injury)',
                            'Severe (Serious harm event resulting in more than 7 days off work)',
                            'Critical (Fatality or Permanent Disability)',
                        ]}
                        initialValue={hazardEdit?.irConsequence || 0}
                        setValue={(value) => dispatch(setTaHazardIrConsequence(value))}
                    />
                    <div>
                        <span>1</span><span>2</span><span>3</span><span>4</span><span>5</span>
                    </div>
                </div>

                <div className="slider-display-container">
                    <h2 className="bp-orange">How LIKELY is it that the worst possible thing could happen?</h2>
                    <RiskSlider
                        color="red"
                        labels={[
                            'Rare (practically impossible)',
                            'Unlikely (could occur - known to happen)',
                            'Moderate (might occur - has happened)',
                            'Likely (will probably occur)',
                            'Almost Certain (expected)',
                        ]}
                        initialValue={hazardEdit?.irLikelyhood || 0}
                        setValue={(value) => dispatch(setTaHazardIrLikelyhood(value))}
                    />
                    <div>
                        <span>1</span><span>2</span><span>3</span><span>4</span><span>5</span>
                    </div>
                </div>


                <div className="risk-display">
                    <h1>INITIAL RISK SCORE (IRS)</h1>
                    <RiskIndicator
                        text=""
                        score={getRiskScore(hazardEdit?.irConsequence || 0, hazardEdit?.irLikelyhood || 0)}
                        classSuffix="large"
                    />
                    <p>The higher the number, the greater the risk. Next, you will add controls to reduce the risk.</p>
                </div>

            </IonContent>
            <TaskAnalysisNav />
        </>
    )
}

