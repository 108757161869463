import { AppDispatch, RootState } from "../../redux/store"
import { requestResources } from "../../datastore"
import { selectSite } from "../../redux/selectors/sites"
import { setRequestComplete, setRequestStart } from "../../redux/slices/resourceMeta"
import { resourceEventCombined } from "../../datastore/resourceEventCombined"
import { selectNotifiableWork } from "../../redux/selectors/notifiableWork"

export const requestSiteNotifiableWork = (params: { [key: string]: any }) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const { siteId, requestKey } = params

    if (requestKey) {
        dispatch(setRequestStart({ requestKey }))
    }

    const results = await Promise.all([
        requestResources('sites', { eqFilter: { id: siteId } })(dispatch, getState, services),
        requestResources('notifiableWork', {})(dispatch, getState, services)
    ])

    const requestResult = resourceEventCombined(results[0], [results[1]])
    if (requestKey) {
        dispatch(setRequestComplete({ requestKey, resourceEvent: requestResult}))
    }
    return requestResult
}

export const getSiteNotifiableWork = (params: { [key: string]: any }) => (dispatch: AppDispatch, getState: () => RootState) => {
    const siteId = params.siteId
    const state = getState()

    const site = selectSite(state, { siteId })    
    if (!site) {
        return null
    }   

    return { 
        notifiableWork: selectNotifiableWork(state, {}).map(item => ({
            text: item.text,
            detail: item.detail,
            isSelected: !!site.notifiableWork.find(siteItem => +(siteItem) === +item.id)
        }))
     }    
}