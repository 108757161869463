import { ResourceType, setResourceRequestedDate } from "../../redux/slices/resources";
import { AppDispatch, RootState } from "../../redux/store";
import { ResourceFilters } from "../types";
import { filterResources } from "./filterResources";

export const setLastRequestedDate = (resourceType: ResourceType, effectiveFilter: ResourceFilters, dispatch: AppDispatch, getState: () => RootState) => {

    const requestedResources = filterResources(getState().resources, resourceType, effectiveFilter)
    const requestedDate = new Date().toISOString()

    requestedResources.forEach(resource => {
        dispatch(setResourceRequestedDate({ resourceType, id: resource.id, requestedDate }))
    })
}