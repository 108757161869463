const sitePpe =  {
  table: 'site_ppe',
  idKey: 'id',

  attrs: [
    'customPpe',
    'detail',
    'creationDate',
    'lastModifiedDate',  
    'deleted' 
  ],

  relationships: [   
    {
      type: 'companies',
      key: 'company',
    },
    {
      type: 'sites',
      key: 'site',
    },
    {
      type: 'ppe-types',
      key: 'ppeType',
    },
  ],
}

export default sitePpe