import { AppDispatch, RootState } from "../../redux/store"
import { requestResources, requestResourcesWithRelated } from "../../datastore"
import { setRequestComplete, setRequestStart } from "../../redux/slices/resourceMeta"
import { resourceEventCombined } from "../../datastore/resourceEventCombined"
import { selectSiteHazardReport, SiteHazardWithControls } from "../../redux/selectors/siteHazards"
import { isEmpty, uniqBy } from "lodash"
import { ResourceEvent } from "../../datastore/types"

export const requestSiteHazards = (params: { [key: string]: any }) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const { siteId, requestKey } = params

    if (requestKey) {
        dispatch(setRequestStart({ requestKey }))
    }

    const { resources } = getState()

    const results = await Promise.all([
        requestResourcesWithRelated('sites', { eqFilter: { id: siteId } }, ['siteHazards'])(dispatch, getState, services),

        // hazardTypes/controls should have been read by background update - only request if resources are empty
        !resources.hazardTypes || isEmpty(resources.hazardTypes)
            ? requestResources('hazardTypes', {})(dispatch, getState, services) : ResourceEvent.RESOURCE_VALID,
        !resources.controls || isEmpty(resources.controls)
            ? requestResources('controls', {})(dispatch, getState, services) : ResourceEvent.RESOURCE_VALID,
        !resources.hazardCategories || isEmpty(resources.hazardCategories)
            ? requestResources('hazardCategories', {})(dispatch, getState, services) : ResourceEvent.RESOURCE_VALID,        
    ])

    const requestResult = resourceEventCombined(results[0], results.slice(1))
    if (requestKey) {
        dispatch(setRequestComplete({ requestKey, resourceEvent: requestResult}))
    }
    return requestResult
}

export const getSiteHazards = (params: { [key: string]: any }) => (dispatch: AppDispatch, getState: () => RootState) => {
    const siteId = params.siteId
    const state = getState()

    const siteHazards = selectSiteHazardReport(state, { siteId })
    
    return {
        siteHazards: siteHazards.map(hazard => {
            return {
                categoryText: hazard.categoryText,
                hazardText: hazard.hazardText,
                hazardTextShort: hazard.hazardTextShort,
                detail: hazard.detail,
                controls: hazard.controls,
                concatControls: hazard.controls.map(control => control.controlTextShort).join(' / ')
            }
        }).sort((a,b) => (a.categoryText+a.hazardTextShort).localeCompare(b.categoryText+b.hazardTextShort, 'en')),
        siteHazardsBoard: siteHazards.reduce((acc: SiteHazardWithControls[], hazard) => {
            const existingHazard = (acc.find(existing => existing.hazardTextShort === hazard.hazardTextShort))
            if (existingHazard) {
                existingHazard.controls = existingHazard.controls.concat(hazard.controls)
            } else {
                acc.push(hazard)
            }
            return acc            
        },[])
        .map(hazard => ({
            categoryText: hazard.categoryText,                
            hazardTextShort: hazard.hazardTextShort,
            controls: uniqBy(hazard.controls,'controlTextShort'),
        }))
        .map(hazard => ({
            ...hazard,
            concatControls: hazard.controls.map(control => control.controlTextShort).join(' / ')
        }))
        .sort((a,b) => (a.categoryText+a.hazardTextShort).localeCompare(b.categoryText+b.hazardTextShort, 'en'))
    } 
}