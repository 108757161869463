/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import Axios, { AxiosError } from 'axios'
import { getApiRoot } from '../helpers/getApiRoot'
import { ApiResult } from '../interfaces/api'
import { getAccessToken } from './accessToken'
import { updateOnlineStatus } from './onlineStatus'

export async function getDocument(endpoint: string, options = {}) {

    try {
      const response = await Axios.get(`${getApiRoot()}documents/${endpoint}`, {
        ...options,
        headers: {
          'Authorization': `Bearer ${getAccessToken()}`
        },
        responseType: 'blob'
      })
      updateOnlineStatus(ApiResult.SUCCESS)
      return {
        result: ApiResult.SUCCESS,
        responseData: response.data
      }
    } catch (err: any | AxiosError) {
      if (Axios.isAxiosError(err) && err.response) {
        // API responded with error code
        if (err.response.status === 401) {
          // We will get 401 unauthorized if the token is missing or invalid
          // Note API will also return 403 for requests where the user is authed but doesn't have permission on a specific resource
          return { result: ApiResult.AUTH_ERROR }
  
        } else if (err.response.status >= 400 && err.response.status <= 499) {
          return { result: ApiResult.CLIENT_ERROR, responseData: null }
  
        } else {
          return { result: ApiResult.SERVER_ERROR, responseData: null }
        }
  
      } else {
        // Request failed or no response from API
        // Probably means backend is down or no internet connection
        updateOnlineStatus(ApiResult.NETWORK_ERROR)
        return { result: ApiResult.NETWORK_ERROR, responseData: null }
      }
    }
  }