import { deleteResource, saveResource, getResources } from '../../datastore'
import TbSiteHazard from '../../datastore/models/TbSiteHazard'
import { initialiseToolboxSiteHazards } from '../../redux/slices/pgToolbox'
import { AppDispatch, RootState } from '../../redux/store'
import { checklistItemsToAdd, checklistItemsToDelete } from './resourceChecklist'

export const saveTbSiteHazards = (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState()  
    const { toolboxEdit, siteHazardsList } = state.pgToolbox

    // Find items that have just been de-selected
    // => delete corresponding resources
    checklistItemsToDelete(siteHazardsList).forEach(siteHazardId => {
        const hazardsToDelete = getResources<TbSiteHazard>(state.resources, 'tbSiteHazards', { eqFilter: { toolbox: toolboxEdit.id, siteHazard: siteHazardId } })
        // Should be only a single resource, but if there are multiple records for the same hazard, delete them all
        hazardsToDelete.forEach(siteHazard => dispatch(deleteResource(siteHazard.id, 'tbSiteHazards')))
    })

    // Find items that have just been selected
    // => Save as new 'tbSiteHazards' resources
    checklistItemsToAdd(siteHazardsList).forEach(siteHazardId => {
        dispatch(saveResource({
            company: toolboxEdit.company,
            toolbox: toolboxEdit.id,
            siteHazard: siteHazardId
        }, 'tbSiteHazards'))
    })

    // Set preEdit to match checked now that changes have been saved
    dispatch(initialiseToolboxSiteHazards(siteHazardsList.checked.map(item=>item.id)))
}