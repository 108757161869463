import { IonIcon, IonItem, IonItemDivider, IonList } from "@ionic/react"
import { chevronBackOutline, folder } from "ionicons/icons"
import { FolderInfo } from "../redux/selectors/docFolders"

interface DocumentBrowserProps {
    path: string
    folderInfo: FolderInfo
    showExtensions?: boolean
    selectedItemId?: number | null
    setSelectedPath?: (path: string) => void
    setSelectedItem: (itemId: number | null) => void
}

const DocumentBrowser: React.FC<DocumentBrowserProps> = ({ path, folderInfo, showExtensions, selectedItemId, setSelectedPath, setSelectedItem }) => {

    const pathSections = (path || '').split('/')
    const selectedItem = folderInfo.items.find(item => item.id === selectedItemId)

    if (!pathSections.length) {
        return (<></>)
    }

    const parent = pathSections.length > 1 ? pathSections.slice(0, -1).join('/') : ''

    const displayItemText = (itemText: string) => {
        return showExtensions
        ? itemText
        : itemText.substring(0, itemText.lastIndexOf('.')) || itemText
    }

    return (
        <>
            {folderInfo.folderName && !selectedItem ?
                <div className="cat-select-level" >
                    {pathSections.length > 1 ? <IonIcon className="nav-chevron" icon={chevronBackOutline}></IonIcon> : <></>}

                    <h3 onClick={() => {
                        if (setSelectedPath) {
                            if (selectedItem) {
                                setSelectedItem(null)
                            } else {
                                setSelectedPath(parent)
                            }
                        }
                    }}>
                        {/*selectedItem ? selectedItem.itemText : */folderInfo.folderName}
                    </h3>
                </div>
                : <></>
            }
            {selectedItem ? <></>
                : <>
                    <IonItemDivider />

                    <IonList>
                        {folderInfo.subfolders!.map((subfolder, i) => (
                            <IonItem key={i}>
                                <div className="cat-select-level item" >
                                    <IonIcon icon={folder}></IonIcon>
                                    <div onClick={() => {
                                        if (setSelectedPath) setSelectedPath(`${path}/${subfolder.key}`)
                                    }}>
                                        {subfolder.folderName}
                                    </div>
                                </div>
                            </IonItem>
                        ))}

                        {folderInfo.items!.map((item) => (

                            <IonItem key={item.id}>
                                <div className="cat-select-level item" key={item.id}>
                                    <img alt='' className="item-icon" src="./assets/ui_images/document.svg" />
                                    <div onClick={() => {
                                        setSelectedItem(+item.id)
                                    }
                                    }>
                                        {displayItemText(item.itemText)}
                                    </div>
                                </div>
                            </IonItem>
                        ))}
                    </IonList>
                </>
            }
        </>)
}

export default DocumentBrowser