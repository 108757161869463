import { AppDispatch, RootState } from "../../redux/store"
import { getResource } from "../../datastore"
import { User } from "../../datastore/models"
import { selectSite } from "../../redux/selectors/sites"
import { incidentTypeTexts, injuryTypeTexts } from '../incidentReports/incidentReportsTypes'
import { userContact } from "./helpers/userContact"
import { selectIncident } from "../../redux/selectors/incidents"
import { selectIncidentWorkers } from "../../redux/selectors/incidentWorkers"
import { selectIncidentActions } from "../../redux/selectors/incidentActions"
import { addressFull } from "./helpers/addressFull"

export const getIncident = (params: { [key: string]: any }) => (dispatch: AppDispatch, getState: () => RootState) => {
    const incidentId = params.incidentId

    const state = getState()
    const incident = selectIncident(getState(), { incidentId })
    if (!incident) {
        return null
    }

    const site = selectSite(state, { siteId: incident.site })
    const workers = selectIncidentWorkers(state, { incidentId })
    const actions = selectIncidentActions(state, { incidentId })

    return {
        incident: {
            company: incident.company,
            site: {
                siteName: site?.siteName || '',
                ...addressFull(site),
                timeZone: site?.timeZone || '',
            },
            incidentDate: incident.incidentDate,
            incidentType: incident.incidentType,
            incidentTypeText: incidentTypeTexts[incident.incidentType],
            reporter: userContact(getResource<User>(state.resources.users, incident.reporter)),
            description: incident.description,
            photo: incident.photo,
            actionVerifier: userContact(getResource<User>(state.resources.users, incident.actionVerifier)),
            closedDate: incident.closedDate,

            ...incident.incidentData,

            workers: workers.map(worker => ({
                ...userContact(getResource<User>(state.resources.users, worker.user)),
                photo: worker.photo,
                injuryDescription: worker.injuryDescription,
                injuryTypeText: injuryTypeTexts[worker.injuryType]
            })),

            actions: actions.map(action => ({
                actionText: action.actionText,
                verifiedText: action.verifiedText
            })),
            images: [incident.photo].concat(workers.map(worker => worker.photo))
                .filter(id => !!id)
                .map(id => ({ savedId: id }))
        }
    }
}