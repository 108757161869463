/**
 * Calculate a risk score given consequence and likelyhood values
 * 
 * The following 2 dimentional array corresponds to a typical risk matrix, 
 * with increasing consequence from bottom to top and increasing likelyhood from left to right
 * (the actual weightings could vary slightly depending on the specific matrix used)
 */

const riskMatrix:number[][] = [
[12, 19, 23, 24, 25],
[10, 11, 18, 21, 22],
[ 6,  9, 16, 17, 20],
[ 4,  5,  8, 14, 15],
[ 1,  2,  3,  7, 13]
]

/**
 * 
 * @param consequence 1-5, 1=least serious, 5=most serious
 * @param likelyhood 1-5, 1=least likely, 5=most likely
 * @returns risk score from 1-25, or null if parameter out of range
 */
export const getRiskScore = (consequence:number, likelyhood:number) => {
    if (!consequence || !likelyhood || consequence < 1 || consequence > 5 || likelyhood < 1 || likelyhood > 5) {
        return null
    }
    return riskMatrix[5-consequence][likelyhood-1]
}

/**
 * Calculates risk band that a particular risk score falls into
 *  
 * @returns Low | Medium | High | Extreme
 */
export const getRiskBand = (score:number | null) => {
    if (!score) {
        return null
    }

    if (score >= 1 && score <= 6) {
        return 'Low'
    }
    if (score >= 7 && score <= 12) {
        return 'Medium'
    }
    if (score >= 13 && score <= 19) {
        return 'High'
    }
    if (score >= 20 && score <= 25) {
        return 'Extreme'
    }
    return null
}
