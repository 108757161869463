import { AppDispatch, RootState } from '../../redux/store'

import { setSiteObservationToolboxMode } from "../../redux/slices/pgSiteObservations"
import { saveObservation } from "./saveObservation"
import { stSITE_OBSERVATIONS } from './siteObservationsTypes'
import { toolboxReviewObservationDone } from '../toolbox/toolboxIntegration'
import { setRedirectPath } from '../../redux/slices/temp'
import { selectSiteObservation } from '../../redux/selectors/siteObservations'
import { loadObservation } from './loadObservation'
import { createObservation } from './createObservation'

/************** Toolbox Integration ***************/

/**
 * Dispatched from toolbox page to initiate observation review / edit during toolbox meeting
 */
export const siteObservationToolboxReviewSelected = (observationId: number) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    const { stSiteObservations, dirty } = getState().pgSiteObservations
    
    if ((stSiteObservations === stSITE_OBSERVATIONS.DETAILS || stSiteObservations === stSITE_OBSERVATIONS.ACTIONS) && dirty) {
        // If an observation is in the process of being edited, save it
        await saveObservation()(dispatch, getState)
    } 
    await loadObservation(observationId)(dispatch, getState, services)
    dispatch(setSiteObservationToolboxMode({ mode: true, observation: observationId }))
    dispatch(setRedirectPath('/observations')) 
}

/**
 * Dispatched from toolbox page to initiate creating an observation during toolbox meeting
 */
export const siteObservationToolboxCreateSelected = (siteId?: number) => async (dispatch: AppDispatch, getState: () => RootState) => {

    const { stSiteObservations, dirty } = getState().pgSiteObservations

    if ((stSiteObservations === stSITE_OBSERVATIONS.DETAILS || stSiteObservations === stSITE_OBSERVATIONS.ACTIONS) && dirty) {
        // If an observation is in the process of being edited, save it
        await saveObservation()(dispatch, getState)
    }
    dispatch(createObservation(siteId))
    dispatch(setSiteObservationToolboxMode({ mode: true, site: siteId }))
    dispatch(setRedirectPath('/observations'))
}

/**
 * Dispatched to return to toolbox page when in toolbox mode:
 * - From 'return to toolbox' buttons
 * - From navbar home icon
 */
export const siteObservationReturnToToolbox = (observationId: number | null) => async (dispatch: AppDispatch, getState: () => RootState) => {

    // Read back whole observation so we have updated id and localId
    const observation = selectSiteObservation(getState(), { siteObservationId: observationId })

    if (observation?.id) {
        dispatch(toolboxReviewObservationDone({ id: observation.id, localId: observation.localId }))
    }

    dispatch(setSiteObservationToolboxMode({ mode: false }))
    dispatch(setRedirectPath('/toolbox'))
}