import { AppDispatch, RootState } from '../../../redux/store'
import { requestResources } from '../../../datastore'
import { selectRequestStatus } from '../../../redux/selectors/resourceMeta'
import { ResourceEvent } from '../../../datastore/types'
import { selectSigninDocs } from '../../../redux/selectors/signinDocs'
import { requestDocs } from '../../../docstore/requestDocs'
import { resourceEventCombined } from '../../../datastore/resourceEventCombined'
import { setRequestComplete, setRequestStart } from '../../../redux/slices/resourceMeta'
import { getLogger } from 'loglevel'
import { uniq } from 'lodash'

const log = getLogger('signin')

/**
 * Request site data required to complete induction process (signinDocs, site hazards, etc) 
 */
export const requestSigninSiteData = (siteId?: number) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    if (!siteId) {
        log.error('requestSigninSiteData called with no siteId')
        return ResourceEvent.RESOURCE_LOAD_ERROR
    }

    dispatch(setRequestStart({ requestKey: 'signinSiteData'}))

    const resourceResults = await Promise.all([
        requestResources('signinDocs', { eqFilter: { site: siteId } })(dispatch, getState, services),
        requestResources('siteHazards', { eqFilter: { site: siteId } })(dispatch, getState, services),
        requestResources('sitePpe', { eqFilter: { site: siteId } })(dispatch, getState, services),
        requestResources('siteContacts', { eqFilter: { site: siteId }, include: ['user'] })(dispatch, getState, services)
    ])

    // Request all documents used as signin docs
    const docIds = uniq(selectSigninDocs(getState(), { siteId }).map(doc => doc.docInfo))

    const docResult = await requestDocs({ inFilter: { id: docIds } })(dispatch, getState, services)

    // If there was an error reading documents, log but allow signin to continue - could be permission assignment error etc
    if (docResult !== ResourceEvent.RESOURCE_VALID) {
        log.debug('requestSigninSiteData: error requesting documents, result=', docResult)
    }

    const result = resourceEventCombined(ResourceEvent.RESOURCE_VALID, resourceResults)

    dispatch(setRequestComplete({ requestKey: 'signinSiteData', resourceEvent: result }))

    return result
}

/**
 * Get combined result of initial requests (used by signin sequence to confirm required site data is available before proceeding)
 * 
 */
export const requestSigninSiteDataResult = (state: RootState) => {

    return selectRequestStatus(state, { requestKey: 'signinSiteData' })
}