import { AppDispatch, RootState } from "../../../redux/store"
import { useAppSelector } from "../../../redux/hooks"
import { requestResources } from "../../../datastore"
import { selectCompanies } from "../../../redux/selectors/companies"
import { useTask } from "../../../hooks/useTask"
import { ResourceEvent } from "../../../datastore/types"

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results
 *
 */
export const useRequestCompanies = (companyIds: number[]) => {
  const taskResult = useTask(
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      const result = await requestResources("companies", {
        inFilter: { id: companyIds }
      })(dispatch, getState, services)

      return {
        isSuccess: result !== ResourceEvent.RESOURCE_LOAD_ERROR
      }
    },
    []
  )

  return {
    companies: useAppSelector((state) => selectCompanies(state, companyIds)),
    isLoading: taskResult.isLoading,
    isSuccess: taskResult.isSuccess,
    isError: taskResult.isError
  }
}
