import { AppDispatch, RootState } from '../redux/store'

import { saveResource } from "../datastore"
import { DocInfo } from "../datastore/models"
import { UnsavedDocInfo } from "../datastore/models"
import { localStore } from '../storage/storage'
import { clearDocStatus, LoadStatus, setDocStatus } from '../redux/slices/docMeta'
import { ApiResult, ApiService } from '../interfaces/api'
import log from 'loglevel'
import { processWriteQueue } from '../datastore/saveResource'
import { formatResource } from '../datastore/json-api/helpers/formatResource'
import { LOCAL_ID_START } from '../redux/slices/resourceMeta'

export const saveDoc = (docData: Blob, docInfo: DocInfo | UnsavedDocInfo) => async (dispatch: AppDispatch, getState: () => RootState) => {

    const docId = saveResource(docInfo, 'docInfo', true)(dispatch, getState)
    if (docId) {

        await localStore.set(`docs/${docId}`, docData)
        dispatch(setDocStatus({ docId, docStatus: { loadStatus: LoadStatus.SAVE_PENDING } }))
        dispatch(processWriteQueue())
        return docId
    }
}

export const writeDocToBackend = async (docInfo: DocInfo, dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const api: ApiService = services.api

    const docData = await localStore.get(`docs/${docInfo.id}`)

    if (!docData) {
        log.error('writeDocToBackend could not get document from storage')
        return { result: ApiResult.NONE }
    }

    const { id, ...newDocInfo } = docInfo
    const addDocumentResult = await api.addDocument(docInfo.id >= LOCAL_ID_START ? newDocInfo : docInfo, docData)
    const lastRequestTime = new Date().toISOString()

    const savedDocInfo = addDocumentResult.responseData
    if (addDocumentResult.result === ApiResult.SUCCESS && savedDocInfo) {

        if (savedDocInfo.id !== docInfo.id) {
            // new document being saved for the first time
            // => Move status and locally stored copy to the 'real' id 
            await localStore.set(`docs/${savedDocInfo.id}`, docData)
        }

        dispatch(setDocStatus({
            docId: docInfo.id, docStatus: {
                loadStatus: LoadStatus.COMPLETE,
                lastModifiedDate: docInfo.lastModifiedDate || '',
                lastLoaded: lastRequestTime,
                lastRequestTime
            }
        }))

        if (savedDocInfo.id !== docInfo.id) {
            // Remove original copy / status stored under localId
            dispatch(clearDocStatus(docInfo.id.toString()))
            await localStore.remove(`docs/${docInfo.id}`)
        }

        return {
            result: addDocumentResult.result,
            responseData: savedDocInfo ? formatResource(savedDocInfo, 'docInfo', savedDocInfo?.id) : null
        }

    } else {
        log.warn('Error saving document:', addDocumentResult.status)

        dispatch(setDocStatus({
            docId: docInfo.id, docStatus: {
                loadStatus: LoadStatus.SAVE_ERROR,
                lastRequestTime
            }
        }))
    }

    return { result: addDocumentResult.result }
}
