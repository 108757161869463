import { AppDispatch, RootState } from '../../redux/store'
import { updateSequence } from './incidentReportsSequence'
import { IncidentReportEvent } from './incidentReportsTypes'
import { incidentReportReturnToToolbox } from './incidentReportsToolbox'

/**
 * Called on any function that completes editing and returns user to start screen (i.e. close/notify/submit)
 * 
 * If in toolbox mode, returns to toolbox meeting
 */
export const returnToStart = () => (dispatch: AppDispatch, getState: () => RootState) => {

    const { toolboxMode, incidentReportEdit } = getState().pgIncidentReports

    if (toolboxMode) {
        dispatch(incidentReportReturnToToolbox(incidentReportEdit.id || null))
    }
    dispatch(updateSequence(IncidentReportEvent.START_SELECTED))
}