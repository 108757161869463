import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { requestResources } from '../../../datastore'
import { selectDraftTas } from '../../../redux/selectors/tas'

/**
 * 'useResources' hook
 * - Requests resources for page
 * - 'selects' results 
 * 
 * @returns  requested resources 
 */
export const useDraftsResources = () => {
    const dispatch = useAppDispatch()

    const user = useAppSelector(state => state.app.user)

    useEffect(() => {
        dispatch(requestResources('tas', { eqFilter: { draft: true, creator: user?.id } }))
    }, [])

    return {
        draftTas: useAppSelector(state => selectDraftTas(state, { userId: user?.id }))        
    }
}