export enum stTASKANALYSIS {
    START,
    DRAFT_REGISTER,
    ACTIVE_REGISTER,
    GENERAL_DETAILS,
    STEP_LIST_CREATE,
    STEP_LIST_HAZARDS,
    SELECT_HAZARD_CATEGORIES,
    SELECT_HAZARDS,
    INITIAL_RISK_SCORE,
    SELECT_CONTROLS,
    RESIDUAL_RISK_SCORE,
    SELECT_PPE,
    REVIEW,    
    SIGNOFF
}