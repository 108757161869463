const projects = {
    table: 'projects',
    idKey: 'id',

    attrs: [
        'projectName',
        'description',
        'address',
        'status',
        'startDate',
        'completionDate',
        'linkedProject',
        'creationDate',
        'lastModifiedDate',
        'deleted'
    ],

    relationships: [
        {
            type: 'companies',
            key: 'company',
        },
    ],
}

export default projects