import { getLogger } from "loglevel"
import { API_MAX_FAILURES } from "."
import { saveBufferedLogs } from "../utils/log"
import { ApiResult } from "../interfaces/api"

const log = getLogger('resourceRequests')

var apiOnline = true
var apiFailures = 0

export const isOnline = () => apiOnline

export const setOnline = (online: boolean) => apiOnline = online

export const updateOnlineStatus = (lastResult: ApiResult) => {

    if (lastResult === ApiResult.NETWORK_ERROR) {

        apiFailures++
        if (apiOnline && apiFailures > API_MAX_FAILURES) {
            apiOnline = false
            log.debug('api: max failures reached, api set to offline')
        }

    } else {
        apiFailures = 0
        if (!apiOnline) {
            // Write any buffered log entries to back end
            saveBufferedLogs()
            apiOnline = true
            log.debug('api: request succeeded, api set to online')            
        }
    }
}