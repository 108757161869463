import { IonButton, IonContent, IonFooter } from "@ionic/react"
import { useAppSelector, useAppDispatch } from '../../redux/hooks'

import DocContainer from "../../components/DocContainer"
import { useHomeIconHandler } from "../../hooks/homeIconHandler"
import { homeIconAction } from "../../features/incidentReports/homeIconAction"
import { selectIncident } from "../../redux/selectors/incidents"
import { incidentReportReturnToToolbox } from "../../features/incidentReports/incidentReportsToolbox"
import { updateSequence } from "../../features/incidentReports/incidentReportsSequence"
import { IncidentReportEvent } from "../../features/incidentReports/incidentReportsTypes"
import { useTemplateDoc } from "../../features/commonResourceRequests/useTemplateDoc"

export const Review: React.FC = () => {
    const dispatch = useAppDispatch()

    const { toolboxMode, reviewIncidentReportId } = useAppSelector(state => state.pgIncidentReports)
    const reviewIncident = useAppSelector(state => selectIncident(state, { incidentId: reviewIncidentReportId }))

    const { templateDoc } = useTemplateDoc(reviewIncident?.company, 'incident')

    useHomeIconHandler('/incidents', () => dispatch(homeIconAction()))

    return (
        <>
            <IonContent class="normal-page">
                <DocContainer
                    docId={templateDoc}
                    selectorParams={{ incidentId: reviewIncidentReportId }}
                />
            </IonContent>

            <IonFooter class="incidents-nav">
                {toolboxMode
                    ? <IonButton style={{ "gridColumnStart": "1" }}
                        onClick={() => {
                            dispatch(incidentReportReturnToToolbox(reviewIncidentReportId || null))
                            dispatch(updateSequence(IncidentReportEvent.BACK_SELECTED))
                        }}
                    >
                        Return to Toolbox Meeting
                    </IonButton>
                    :
                    <IonButton style={{ "gridColumnStart": "1" }} onClick={() => dispatch(updateSequence(IncidentReportEvent.BACK_SELECTED))}>Back</IonButton>
                }
            </IonFooter>
        </>)
}
