import { useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

//import { arrowDownCircleOutline } from "ionicons/icons"
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonContent,
  IonFooter,
  IonItem,
  IonList
  //   IonRefresher,
  //   IonRefresherContent
} from "@ionic/react"

import { useAppSelector, useAppDispatch } from "../../redux/hooks"

import {
  setToolboxMeetingData,
  setToolboxObservation
} from "../../redux/slices/pgToolbox"
import { inChecklist } from "../../features/toolbox/resourceChecklist"
import { useSiteObservationsResources } from "../../features/toolbox/resourceRequests/useSiteObservationsResources"
import { saveToolbox } from "../../features/toolbox/saveToolbox"
import { saveTbObservations } from "../../features/toolbox/saveTbObservations"
import {
  siteObservationToolboxCreateSelected,
  siteObservationToolboxReviewSelected
} from "../../features/siteObservations/siteObservationsToolbox"
import TextArea from "../../components/form/TextArea"
import { updateSequence } from "../../features/toolbox/toolboxSequence"
import { ToolboxEvent } from "../../features/toolbox/toolboxTypes"
import ASCheckbox from "../../components/ASCheckbox"

type Inputs = {
  comments: string
}

const schema = yup
  .object()
  .shape({
    comments: yup.string()
  })
  .required()

export const SiteObservations: React.FC = () => {
  const dispatch = useAppDispatch()

  const { toolboxEdit, observationsList } = useAppSelector(
    (state) => state.pgToolbox
  )
  const siteId = toolboxEdit.site
  const { siteObservations, isLoading } =
    useSiteObservationsResources(siteId, toolboxEdit.company, observationsList)

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty }
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      comments: toolboxEdit.meetingData.observationsComments
    }
  })

  const formSubmitBack = (data: any) => {
    formSubmit(data)
    dispatch(updateSequence(ToolboxEvent.BACK_SELECTED))
  }

  const formSubmitNext = (data: any) => {
    formSubmit(data)
    dispatch(updateSequence(ToolboxEvent.NEXT_SELECTED))
  }

  const formSubmit = (data: any) => {
    if (isDirty) {
      dispatch(setToolboxMeetingData({ observationsComments: data.comments }))
      dispatch(saveToolbox)
    }
    dispatch(saveTbObservations)
  }

  const refresherRef = useRef<HTMLIonRefresherElement>(null)
  useEffect(() => {
    if (!isLoading) {
      refresherRef.current?.complete()
    }
  }, [!isLoading])

  return (
    <>
      <IonContent class="content-padding">
        <h2>Site Observations</h2>
        <p>Have any potential hazards been observed?</p>
        {/* 
                <IonRefresher
                    ref={refresherRef}
                    slot="fixed"
                    onIonRefresh={() => setRefresh(refresh+1)}
                >
                    <IonRefresherContent
                        pullingIcon={arrowDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing..."
                    />
                </IonRefresher>
                 */}
        <form className="toolbox-form">
          <IonList id="site-observations-list">
            <IonAccordionGroup multiple={true}>
              {siteObservations?.map((item) => {
                const { observation, actions } = item
                return (
                  <IonAccordion key={observation.id}>
                    <IonItem slot="header">
                      <ASCheckbox
                        checked={inChecklist(observationsList, observation)}
                        disabled={
                          !!observation.observerClosedDate &&
                          !!observation.supervisorClosedDate
                        }
                        onClick={(checked) =>
                          dispatch(
                            setToolboxObservation({
                              observation,
                              newState: checked
                            })
                          )
                        }
                        label={observation.description}
                      />
                    </IonItem>
                    <IonList slot="content">
                      <IonItem>
                        <div className="expanded-actions flex column">
                          <h3>Actions:</h3>
                          <ul>
                            {actions.map((action, id) => (
                              <li key={id}>{action.actionText}</li>
                            ))}
                          </ul>
                          <IonButton
                            onClick={() =>
                              dispatch(
                                siteObservationToolboxReviewSelected(
                                  observation.id || 0
                                )
                              )
                            }
                          >
                            Review Observation
                          </IonButton>
                        </div>
                      </IonItem>
                    </IonList>
                  </IonAccordion>
                )
              })}
            </IonAccordionGroup>
          </IonList>
          <div className="flex centre-row">
            <IonButton
              onClick={() =>
                dispatch(
                  siteObservationToolboxCreateSelected(siteId || undefined)
                )
              }
            >
              Add Observation
            </IonButton>
          </div>

          <TextArea
            fieldName="comments"
            inputMode="text"
            placeholder="COMMENTS - Any extra details you want to record"
            rows={6}
            watch={watch}
            setValue={setValue}
            errors={errors}
          />
        </form>
      </IonContent>

      <IonFooter class="toolbox-nav">
        <IonButton onClick={handleSubmit(formSubmitBack)}>Back</IonButton>
        <IonButton onClick={handleSubmit(formSubmitNext)}>Next</IonButton>
      </IonFooter>
    </>
  )
}
