import React from "react"
import { FieldError, UseFormWatch, UseFormSetValue } from "react-hook-form"
import ASCheckbox from "../ASCheckbox"

interface InputProps {
  id?: string
  label?: string
  fieldName: string
  watch: UseFormWatch<any>
  setValue: UseFormSetValue<any>
  error?: FieldError
}

const Checkbox: React.FC<InputProps> = ({
  id,
  label,
  fieldName,
  watch,
  setValue,
  error
}) => {
  const value = watch(fieldName)

  const onClickFn = (state: boolean) => {
    setValue(fieldName, state, { shouldDirty: true })
  }

  return (
    <div id={id}>
      <div className="checkbox-wrapper">
        <ASCheckbox label={label} checked={value} onClick={onClickFn} />
      </div>
      <div className="oss-input-error-message">{error?.message}</div>
    </div>
  )
}

export default Checkbox
