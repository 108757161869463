import { AppDispatch, RootState } from '../../redux/store'
import { setTaEdit, setTaSavedAsDraft } from '../../redux/slices/pgTaskAnalysis'
import { selectTa } from '../../redux/selectors/tas'
import { requestTa } from './resourceRequests/requestTa'
import { selectCanEditTa } from '../../redux/selectors/abilities'
import { TaEvent, updateSequence } from './taskAnalysisSequence'
import { hideLoadingSpinner, showLoadingSpinner } from '../../redux/slices/temp'

export const loadTa = (taId: number | null, edit:boolean) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    dispatch(showLoadingSpinner('Loading SWMS...'))

    await requestTa({taId})(dispatch, getState, services)

    const ta = selectTa(getState(), { taId })

    if (ta) {
        dispatch(setTaEdit(ta))

        if (ta.draft) {
            // If loading a draft, set 'saved as draft' flag so the 'save as draft' button doesn't appear 
            // until something's changed
            dispatch(setTaSavedAsDraft())
        }
        if (edit && selectCanEditTa(getState(),ta)) {
            dispatch(updateSequence(TaEvent.GENERAL_DETAILS_SELECTED))
        } else {
            dispatch(updateSequence(TaEvent.REVIEW_SELECTED))
        }
    } else {
        // If for some reason we couldn't load ta, return to start
        dispatch(updateSequence(TaEvent.START_SELECTED))
    }
    dispatch(hideLoadingSpinner())
}