import { getLogger } from 'loglevel'
import { get, uniq } from 'lodash'

import { AppDispatch, RootState } from '../redux/store'
import { ApiResult, ApiService } from '../interfaces/api'
import { LoadStatus, removeDocRequest, setDocRequestComplete, setDocRequestStart, setDocStatus } from '../redux/slices/docMeta'
import { ResourceEvent } from '../datastore/types'
import { getDocLoadStatus } from './getDocLoadStatus'
import { LOCAL_ID_START } from '../redux/slices/resourceMeta'
import { getResources, requestResources } from '../datastore'
import { DocInfo } from '../datastore/models'
import { localStore } from '../storage/storage'
import { getNextId } from '../datastore/getNextId'
import { ResourceFilters } from '../datastore/types'

const log = getLogger('resourceRequests')

/**
 * 
 * @param opts request key and resource filters (same as for requestResources)
 * @returns 
 */
interface RequestDocsOpts extends ResourceFilters {
    requestKey?: string
}

export const requestDocs = (opts: RequestDocsOpts ) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    const api: ApiService = services.api

    const {requestKey, ...filters} = opts

    // If caller supplied a request key, use it, otherwise use the next free localId
    const _requestKey = opts.requestKey || getNextId(dispatch, getState).toString()

    // If specific list of documents requested via inFilter, record
    const requestedDocIds = uniq((opts.inFilter?.id || [])) as number[]
    dispatch(setDocRequestStart({ requestKey:_requestKey, docIds: requestedDocIds }))

    // Default to 'all complete' until we know otherwise
    let allComplete = true

    // Request docInfo records matching filters
    const docInfoResult = await requestResources('docInfo', filters )(dispatch, getState, services)
   
    if (docInfoResult === ResourceEvent.RESOURCE_LOAD_ERROR) {
        log.debug(`requestDocs:error requesting docInfo record(s), filters=${JSON.stringify(filters)}`)
        allComplete = false
    }   

    // get actual matching docInfo records
    const requestedDocInfo = getResources<DocInfo>(getState().resources, 'docInfo', filters)    

    // If caller requested a specific list of documents via inFilter, check we got the right number
    if (docInfoResult !== ResourceEvent.RESOURCE_LOAD_ERROR && requestedDocIds.length > requestedDocInfo.length) {
        log.warn(`requestDocs:not all requested documents retrieved (requested ${requestedDocIds.length}, got ${requestedDocInfo.length})`)
        allComplete = false  
    }

    const statuses = await Promise.all(requestedDocInfo.map(async docInfo => {
        if (docInfo.id >= LOCAL_ID_START) {
            return LoadStatus.COMPLETE
        }

        // Update docStatus to 'LOAD_PENDING' for any new or updated docInfo records we've just received
        updateDocStatus(docInfo.id, dispatch, getState)

        const state = getState()
        
        let docStatus = getDocLoadStatus(state.resources.docInfo, state.docMeta.docStatus, docInfo.id)

        if (docStatus === LoadStatus.LOAD_PENDING) {

            dispatch(setDocStatus({ docId: docInfo.id, docStatus: { loadStatus: LoadStatus.LOADING } }))

            const getDocResult = await api.getDocument(`${docInfo.company}/${docInfo.id}`)
            const lastRequestTime = new Date().toISOString()
            if (getDocResult.result === ApiResult.SUCCESS) {
                await localStore.set(`docs/${docInfo.id}`, getDocResult.responseData)

                dispatch(setDocStatus({
                    docId: docInfo.id, docStatus: {
                        loadStatus: LoadStatus.COMPLETE,
                        lastModifiedDate: docInfo.lastModifiedDate || '',
                        lastLoaded: lastRequestTime,
                        lastRequestTime
                    }
                }))

                return LoadStatus.COMPLETE

            } else {
                log.debug(`requestDocs:error loading document ${docInfo.id}`)
                dispatch(setDocStatus({
                    docId: docInfo.id, docStatus: {
                        loadStatus: LoadStatus.LOAD_ERROR,
                        lastModifiedDate: docInfo.lastModifiedDate || '',
                        lastRequestTime
                    }
                }))

                return LoadStatus.LOAD_ERROR
            }
        }
        return docStatus
    }))

    if (statuses.some(status => status !== LoadStatus.COMPLETE)) {
        allComplete = false
    }

    const result = allComplete ? ResourceEvent.RESOURCE_VALID : ResourceEvent.RESOURCE_LOAD_ERROR

    dispatch(setDocRequestComplete({ requestKey: _requestKey, resourceEvent: result }))

    if (!opts.requestKey) {
        dispatch(removeDocRequest(_requestKey))
    }

    return result
}

/**
 * For specified docId, set docStatus to 'LOAD_PENDING' if:
 * 
 * - No docStatus exists (i.e. document is being requested for the first time)
 * - loadStatus is NOT COMPLETE or SAVE_PENDING(i.e. last load / save NOT completed sucessfully)
 * - docStatus exists but has a different lastModifiedDate  (i.e. there is a newer version on server)
 * 
 * LOAD_PENDING status can then be used to determine which documents to request new coppies of
 */
const updateDocStatus = (docId: number, dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()    

    const docInfo = state.resources.docInfo[docId]
    if (docInfo) {

        let docStatus = get(state.docMeta, ['docStatus', docId])

        if (!docStatus ||
            (docStatus.loadStatus !== LoadStatus.COMPLETE && docStatus.loadStatus !== LoadStatus.SAVE_PENDING) || (
                docStatus.lastModifiedDate &&
                docStatus.lastModifiedDate !== docInfo.attributes.lastModifiedDate &&
                docStatus.loadStatus !== LoadStatus.SAVE_PENDING)
        ) {
            // docStatus either doesn't exist or has a different 'lastModified' date (and isn't waiting to be saved)
            // => document has been newly requested or has been updated on server, reset docStatus
            dispatch(setDocStatus({
                docId: +docId, docStatus: {
                    loadStatus: LoadStatus.LOAD_PENDING,
                    lastModifiedDate: docInfo.attributes.lastModifiedDate,
                    lastLoaded: '',
                    lastUsed: ''
                }
            }))
        }
    } 
}