import log from "loglevel"
import { Browser } from 'ionic-appauth/lib/auth-browser';
import { Browser as CapBrowser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

/*
class Browser {

    constructor() {
        this.onCloseFunction = () => { };
    }
    browserCloseListener(closeBrowserEvent) {
        this.onCloseFunction = closeBrowserEvent;
    }
}
*/

export class DefaultBrowser extends Browser {
    showWindow(url: string, callbackUrl?: string | undefined): string | Promise<string | undefined> | undefined {
        const openWindow = window.open(url, '_self');
        if (openWindow) {
            //openWindow.addEventListener('beforeupload', () => this.onCloseFunction());
            openWindow.addEventListener('browserfinished', () => this.onCloseFunction());
        }
        return;
    }
    closeWindow() {
        // Invoking window.close() is not desired. It will either be ignored (most of the time),
        // or it will close the current browser tab if this site was opened via a "_blank" target.
    }
}


export class CapacitorBrowser extends Browser {
    async closeWindow() {
        if (!CapBrowser)
            throw new Error('Capacitor Browser Is Undefined!');
        if (Capacitor.getPlatform() !== 'android') {
            try {
                await CapBrowser.close();
            } catch (err) {
                log.info(err)
            }
        }
    }

    showWindow(url: string, callbackUrl?: string | undefined): string | Promise<string | undefined> | undefined {
        const options = {
            url: url,
            windowName: '_self',
        };
        if (!CapBrowser) {
            throw new Error('Capacitor Browser Is Undefined!');
        }
        return CapBrowser.addListener('browserFinished', () => {
            this.onCloseFunction();
        }).then(() => {
            CapBrowser.open(options);
            return undefined
        })
    }
}
