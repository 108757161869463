import { setRedirectPath } from "../../redux/slices/temp"
import { AppDispatch, RootState } from "../../redux/store"
import { updateSequence } from './incidentReportsSequence'
import { IncidentReportEvent, stINCIDENT_REPORTS } from "./incidentReportsTypes"
import { incidentReportReturnToToolbox } from "./incidentReportsToolbox"
import { saveIncident } from "./saveIncident"

/**
 * Actions performed on tapping navbar home icon
 * 
 * - if in toolbox mode, return to toolbox meeting
 * - otherwise return to home page 
 */
export const homeIconAction = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { stIncidentReports, toolboxMode, dirty, reviewIncidentReportId } = getState().pgIncidentReports

    // If user has an unsaved edit, save it
    if (dirty) {
        // Need to await so that we get new / updated incidentId
        // Shouldn't be long wait as we're only awaiting async blob conversion / storage access
        await saveIncident()(dispatch, getState)
    }

    if (toolboxMode) {
        let incidentId = null

        if (stIncidentReports === stINCIDENT_REPORTS.REVIEW) {
            // If we return from 'review' page, we've been looking at observation with id reviewSiteObservationId
            incidentId = reviewIncidentReportId
        }

        if (stIncidentReports === stINCIDENT_REPORTS.DETAILS_1 || 
            stIncidentReports === stINCIDENT_REPORTS.WORKERS_INVOLVED || 
            stIncidentReports === stINCIDENT_REPORTS.DETAILS_2 || 
            stIncidentReports === stINCIDENT_REPORTS.ACTIONS )
        {
            // If we've been looking at incident stored in edit buffer,
            // re-read incident id in case it was set / updated by save
            incidentId = getState().pgIncidentReports.incidentReportEdit.id
        }    
        dispatch(incidentReportReturnToToolbox(incidentId || null))
        dispatch(updateSequence(IncidentReportEvent.START_SELECTED))

    } else {

        dispatch(setRedirectPath('/home'))
    }
}