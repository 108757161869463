import { useEffect, useState } from "react"
import { IonButton, IonContent, IonFooter } from "@ionic/react"

import DocContainer from "../../components/DocContainer"
import { useAppSelector, useAppDispatch } from "../../redux/hooks"
import { setAlert } from "../../utils/alert"
import { updateSequence } from "../../features/signin/signinSequence"
import { SigninEvent } from "../../features/signin/signinTypes"
import { selectNextSigninDoc } from "../../features/signin/selectNextSigninDoc"
import { selectPreviousSigninDoc } from "../../features/signin/selectPreviousSigninDoc"

const PresentDocs: React.FC = () => {
  const dispatch = useAppDispatch()

  const { signinSiteId, docList, selectedDocIndex } = useAppSelector(
    (state) => state.pgSignin
  )

  const [warnMessages, setWarnMessages] = useState<string[]>([])
  const [blockMessages, setBlockMessages] = useState<string[]>([])
  const [numPages, setNumPages] = useState<number>(1)
  const [pageNum, setPageNum] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // Reset page count / select first page when a new doc is selected
  useEffect(() => {
    setPageNum(1)
    setNumPages(1)
  }, [selectedDocIndex])

  // warnMessages and blockMessages arrays set by HtmlDocContainer based on any invalid / disallowed user input detected:
  // Any blockMessages:
  // -> user cannot proceed without providing valid input (and will be shown warning message)
  // Any warnMessages (and no blockMessages):
  // -> user CAN proceed without providing valid input, but will be shown warning message
  // docList[selectedDocIndex].completed:
  // -> user has viewed / provided correct response previously, so don't care what they enter this time
  const nextSelected = () => {
    if (
      (!warnMessages.length && !blockMessages.length) ||
      (selectedDocIndex !== null && docList[selectedDocIndex].completed)
    ) {
      setBlockMessages([])
      setWarnMessages([])
      setIsLoading(true)
      dispatch(selectNextSigninDoc())
    } else {
      dispatch(
        setAlert(
          "Signin Alert",
          blockMessages.length ? blockMessages[0] : warnMessages[0],
          [
            {
              text: "Ok",
              handler: () => {
                if (!blockMessages.length) {
                  setBlockMessages([])
                  setWarnMessages([])
                  setIsLoading(true)
                  dispatch(selectNextSigninDoc())
                }
                return true
              }
            }
          ]
        )
      )
    }
  }

  return (
    <>
      <IonContent class="normal-page">
        <div id="signin-doc-container">
          <DocContainer
            docId={
              selectedDocIndex !== null
                ? docList[selectedDocIndex].docInfo
                : null
            }
            selectorParams={{ siteId: signinSiteId }}
            setWarnMessages={setWarnMessages}
            setBlockMessages={setBlockMessages}
            setNumPages={setNumPages}
            setIsLoading={setIsLoading}
            pageNum={pageNum}
          />
        </div>
      </IonContent>
      <IonFooter
        class={numPages > 1 ? "present-paged-doc-nav" : "present-doc-nav"}
      >
        <IonButton
          disabled={selectedDocIndex === 0}
          onClick={() => dispatch(selectPreviousSigninDoc())}
        >
          Back
        </IonButton>
        {numPages > 1 ? (
          <>
            <IonButton
              disabled={pageNum === 1}
              onClick={() => setPageNum(pageNum - 1)}
            >
              {"<"}
            </IonButton>
            <IonButton
              disabled={pageNum === numPages}
              onClick={() => setPageNum(pageNum + 1)}
            >
              {">"}
            </IonButton>
          </>
        ) : (
          <></>
        )}
        <IonButton onClick={nextSelected} disabled={isLoading}>
          Next
        </IonButton>
        <IonButton
          onClick={() => dispatch(updateSequence(SigninEvent.RESET_SEQUENCE))}
        >
          Cancel
        </IonButton>
      </IonFooter>
    </>
  )
}

export default PresentDocs
