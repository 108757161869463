import React from "react"
import { createRoot } from 'react-dom/client';
import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from "./reportWebVitals"

import { defineCustomElements } from "@ionic/pwa-elements/loader"
import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';

import { store } from "./redux/store"
import { Provider } from "react-redux"

import log from "loglevel"
import { initLogging } from "./utils/log"
import { initRequestIdleCallback } from "./helpers/requestIdleCallback"
import { initAuthService } from "./services/Auth";
import { isPlatform } from "@ionic/react";
import { initialisePath } from "./features/startup/initialisePath";
import { startup } from "./features/startup/startup";
import { setAppOpenUrl, setRedirectPath } from "./redux/slices/temp";
import { getAppOpenUrl } from "./features/startup/getAppOpenUrl";

initLogging()
log.info("App Starting")

// Add a timer implementation of requestIdleCallback if not supported
initRequestIdleCallback()

initAuthService()

if (isPlatform("capacitor")) {

  CapacitorApp.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {

    const url = await getAppOpenUrl(event.url)

    console.log(`appUrlOpen event fired, protocol:${url.protocol}, host:${url.host}, pathname:${url.pathname}, search:${url.search}`)

    if (url.protocol === 'attentionsafety:') {
      store.dispatch(setAppOpenUrl(url.href))

      switch (url.pathname) {
        case '/callback':
          store.dispatch(setRedirectPath("/callback"))
          break;

        case '/splash':
          store.dispatch(setRedirectPath("/signin"))
          break;
      }
    }
  })
}

// Ensure app enters an appropriate path on startup, and initialise in store
store.dispatch(initialisePath())

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window)

const root = createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)

// Run other startup code (restore persisted state / user session / site signin status etc)
store.dispatch(startup())

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
