import { createSelector } from '@reduxjs/toolkit'
import { getResources } from '../../datastore'
import { TbOther } from '../../datastore/models'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'

const selectResources = selectResourcesMemo(['toolboxes', 'tbOther'])
const selectParams = selectParamsMemo()

export const selectTbOther = createSelector([selectResources, selectParams], (resources, params) => {
    return getResources<TbOther>(resources, 'tbOther', { eqFilter: { toolbox: params.toolboxId } })
})