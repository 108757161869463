/**
 * embed - generate html to embed a pdf or image into a document
 * 
 * Could theoretically allow different handling for pdfs (as is done in portal addon),
 * however for now we're converting pdfs to images prior to parsing template, and just handling everything 
 * as an image at this point
 * 
 * @param imageData - dataUrl
 * @param ext - file extension
 */
export const embed = (imageData: string, ext: string) => {  
  
  return `<img src="${imageData}"/>`;
  
};
