import { useEffect, useState } from "react"
import {
  IonPage,
  IonHeader,
  IonContent,
  IonFooter,
  IonButton
} from "@ionic/react"

import { useAppSelector, useAppDispatch } from "../redux/hooks"
import { setPath, setViewDoc } from "../redux/slices/pgDocuments"
import { selectFolderContents } from "../redux/selectors/docFolders"
import DocContainer from "../components/DocContainer"
import NavBar from "../components/NavBar"
import DocumentBrowser from "../components/DocumentBrowser"
import { selectSignedinSiteId } from "../redux/selectors/signins"
import { selectSite } from "../redux/selectors/sites"
import { useDocumentsResources } from "../features/documents/resourceRequests/useDocumentsResources"
import { useRequestDocumentsBySite } from "../features/documents/resourceRequests/useRequestDocumentsBySite"

import "./Documents.css"

const Documents: React.FC = () => {
  const dispatch = useAppDispatch()

  // Due to the way ionic router works, page component stays mounted after we leave page
  // -> check currentPath to determine if documents page is currently displayed
  const currentPath = useAppSelector((state) => state.temp.currentPath)
  const pageActive = currentPath === "/documents"

  const signedinSiteId = useAppSelector(selectSignedinSiteId)
  const signedinSite = useAppSelector((state) =>
    selectSite(state, { siteId: signedinSiteId })
  )

  const { path, selectedDocId } = useAppSelector((state) => state.pgDocuments)

  const pathSections = path.split("/")
  const selectedSiteId =
    pathSections.length >= 4 && pathSections[2] === "sites"
      ? +pathSections[3]
      : null
  const selectedCompanyId =
    pathSections.length >= 2 && pathSections[0] === "companies"
      ? +pathSections[1]
      : null

  const { companies, sites, ...docResources } =
    useDocumentsResources(pageActive)

  useRequestDocumentsBySite(selectedCompanyId, selectedSiteId, pageActive)

  const companyIds = companies ? companies.map((company) => company.id) : []

  const folderInfo = useAppSelector((state) =>
    selectFolderContents(state, { path, companyIds })
  )
  const [numPages, setNumPages] = useState<number>(1)
  const [pageNum, setPageNum] = useState<number>(1)

  const setSelectedPath = (path: string) => {
    if (path.search("companies/") === 0) {
      dispatch(setPath(path))
    } else {
      dispatch(setPath("companies"))
    }
    dispatch(setViewDoc(null))
  }

  // Reset to first page when a new doc is selected
  useEffect(() => {
    setPageNum(1)
    if (!selectedDocId) {
      // If no document shown, reset page count to hide next / prev buttons
      setNumPages(1)
    }
  }, [selectedDocId])

  useEffect(() => {
    if (pageActive && !path) {
      if (signedinSite) {
        dispatch(
          setPath(`companies/${signedinSite.company}/sites/${signedinSite.id}`)
        )
      } else {
        if (docResources.isSuccess && companies.length && sites.length) {
          if (companies.length === 1) {
            if (sites.length === 1) {
              // User has access to a single site - go straight to it
              dispatch(
                setPath(`companies/${companies[0].id}/sites/${sites[0].id}`)
              )
            } else {
              // User has access to multiple sites for a single company - go to 'sites' folder
              dispatch(setPath(`companies/${companies[0].id}/sites`))
            }
          } else {
            // User has access to multiple companies - go to 'companies' folder
            dispatch(setPath("companies"))
          }
        }
      }
    }
  }, [pageActive, docResources.isSuccess])

  const setSelectedItem = (docId: number | null) => {
    dispatch(setViewDoc(docId))
  }

  return (
    <IonPage>
      <IonHeader>
        <NavBar pageTitle="Documents" />
      </IonHeader>
      <IonContent>
        <div id="documents-grid">
          <div>
            <DocumentBrowser
              path={path}
              folderInfo={folderInfo}
              selectedItemId={selectedDocId}
              setSelectedPath={setSelectedPath}
              setSelectedItem={setSelectedItem}
            />
          </div>

          {selectedDocId ? (
            <DocContainer
              docId={selectedDocId}
              selectorParams={{ siteId: selectedSiteId }}
              setNumPages={setNumPages}
              pageNum={pageNum}
            />
          ) : (
            <></>
          )}
        </div>
      </IonContent>
      <IonFooter class="documents-nav">
        {selectedDocId ? (
          <IonButton onClick={() => dispatch(setViewDoc(null))}>Back</IonButton>
        ) : (
          <></>
        )}
        {selectedDocId && (numPages > 1) ? (
          <>
            <IonButton
              disabled={pageNum === 1}
              onClick={() => setPageNum(pageNum - 1)}
            >
              {"<"}
            </IonButton>
            <IonButton
              disabled={pageNum === numPages}
              onClick={() => setPageNum(pageNum + 1)}
            >
              {">"}
            </IonButton>
          </>
        ) : (
          <></>
        )}
      </IonFooter>
    </IonPage>
  )
}

export default Documents
