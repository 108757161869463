import { AppDispatch, RootState } from '../../redux/store'
import { setTaEditDirty, setTaEditId, setTaSavedAsDraft, setTaWorker, setTaWorkers } from '../../redux/slices/pgTaskAnalysis'
import { selectTaEdit } from '../../redux/selectors/tas'
import { selectProject } from '../../redux/selectors/projects'
import { saveResource, deleteResource } from '../../datastore'
import log from 'loglevel'
import { TaHazard, TaStep } from '../../datastore/models/Ta'

// format hazard for saving (remove any fields that shouldn't be persisted)
const getSaveHazard = (hazard: TaHazard) => {
    // Actually, keep 'needsReview' - if user saves as draft part way through reviewing, want to 
    // keep for hazards they haven't reviewed yet
    const { /*needsReview,*/ ...saveHazard } = hazard
    return saveHazard
}

// format step for saving
const getSaveStep = (step: TaStep) => {
    const saveStep = {
        ...step,
        hazards: step.hazards.map(hazard => getSaveHazard(hazard))
    }
    // Don't save placeholder if step text has been entered
    if (step.placeholder && step.text) {
        delete saveStep.placeholder
    }
    return saveStep
}

export const saveTa = () => (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState()
    const taEdit = selectTaEdit(state)

    const project = selectProject(state, { projectId: taEdit.project })
    if (!project) {
        log.warn('Failed to save ta - project not found')
        return
    }

    // Need to call saveResource directly rather than dispatching so we can get resource id back
    const savedTaId = saveResource({
        id: taEdit.id,
        company: project.company,
        project: taEdit.project,
        site: taEdit.site,
        scheduledTask: taEdit.scheduledTask,
        creator: taEdit.creator,
        startDate: taEdit.startDate,
        draft: taEdit.draft,
        revision: taEdit.revision,
        superseded: taEdit.superseded,
        supersedes: taEdit.supersedes,
        userData: {
            name: taEdit.userData.name,
            description: taEdit.userData.description,
            steps: taEdit.userData.steps.map(step => getSaveStep(step)),
            ppe: taEdit.userData.ppe,
            customPpe: taEdit.userData.customPpe,
        }
    }, 'tas')(dispatch, getState)
    dispatch(setTaEditId(savedTaId))

    let anyWorkerDeleted = false
    taEdit.workers.forEach((worker, i) => {
        if (worker.dirty || worker.company !== project.company || !worker.id) {

            if (worker.id && worker.deleted) {
                anyWorkerDeleted = true
                deleteResource(worker.id, 'taWorkers')(dispatch, getState)

            } else {
                // remove dirty flag and update company / ta id
                let { dirty, ...updatedWorker } = worker
                updatedWorker.company = project.company
                updatedWorker.ta = savedTaId

                const savedId = saveResource(updatedWorker, 'taWorkers')(dispatch, getState)
                // write changes back to taEdit copy
                dispatch(setTaWorker({
                    worker: {
                        ...updatedWorker,
                        id: savedId,
                        dirty: false
                    }, index: i
                }))
            }
        }
    })

    // If any workers were deleted, remove them from workers array (now that deletions have been saved to local state / DB)
    if (anyWorkerDeleted) {
        const updatedWorkers = getState().pgTaskAnalysis.taEdit.workers
        dispatch(setTaWorkers(updatedWorkers.filter(worker => !worker.deleted)))
    }

    if (taEdit.draft) {
        dispatch(setTaSavedAsDraft())
    }
    dispatch(setTaEditDirty(false))
}