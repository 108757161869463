
import {
  Camera,
  CameraResultType,
  CameraSource,
} from "@capacitor/camera";

import log from "loglevel";

export function useCamera() {
  const takePhoto = async () => {
    try {
      const photoData = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
        quality: 100,
      })
      return photoData

    } catch (e:any) {
      if (e.message !== 'User cancelled photos app')
        log.warn('Camera error:', e.message)
    }
  }

  return {
    takePhoto,
  };
}