/**
 * State management specific to documents page
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PgDocumentsState {
    selectedDocId: number | null
    path: string
}

const initialState: PgDocumentsState = {
    selectedDocId: null,
    path: ''
}

export const pgDocuments = createSlice({
    name: 'pgDocuments',
    initialState,
    reducers: {

        /**
        * Replace entire slice with new data
        * (will be called when initialising from persistent storage)  
        */
        setAll(state, action: PayloadAction<PgDocumentsState>) {
            return {
                ...state,
                ...action.payload
            }
        },

        /**
        * Reset state to initial values (e.g. on logout)
        *
        */
        setInitial(state, action: PayloadAction<void>) {
            return initialState
        },

        setViewDoc(state, action: PayloadAction<number | null>) {
            state.selectedDocId = action.payload
        },

        setPath(state, action: PayloadAction<string>) {
            state.path = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { setAll, setViewDoc, setPath } = pgDocuments.actions

export default pgDocuments.reducer