import { IonCard } from "@ionic/react"
import { incidentTypeTexts } from "../../features/incidentReports/incidentReportsTypes"
import { IncidentType } from '../../datastore/models/Incident'

import './IncidentTypeTile.css'

interface Props {
    id?: string
    reportType?: string
    row?: string
    type: IncidentType
    action?: (type: IncidentType) => void
}

const IncidentTypeTile: React.FC<Props> = ({ id, reportType, type, row, action }) => {

    if (action) {
        return (
            <IonCard
                className={"incident-type-card " + reportType}
                style={
                    row?
                    { "background": `var(--card${type})`, "gridRowStart":`${row}` }
                    :
                    { "background": `var(--card${type})`}
                }
                button={true}
                onClick={() => action(type)}
            >
                <div>
                    <span>{incidentTypeTexts[type].title}</span>
                    <span>{incidentTypeTexts[type].subtitle}</span>
                </div>
            </IonCard>
        )
    } else {
        return (
            //? Assume that an actionless card is being displayed in incident report??
            <IonCard
                className="incident-type-card short"
                style={{ background: `var(--card${type})` }}
            >
                <div>
                    <span>{incidentTypeTexts[type].title}</span>
                    <span>{incidentTypeTexts[type].subtitle}</span>
                </div>
            </IonCard>
        )
    }
}

export default IncidentTypeTile