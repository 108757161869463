import { AppDispatch, RootState } from '../../redux/store'

import { SiteObservationEvent, stSITE_OBSERVATIONS } from './siteObservationsTypes'
import { setSiteObservationsState } from '../../redux/slices/pgSiteObservations'

export const updateSequence = (event?: SiteObservationEvent) => (dispatch: AppDispatch, getState: () => RootState) => {

    let state = getState()

    const { stSiteObservations } = state.pgSiteObservations

    // Can be sent to 'Details' or 'Review' from any state, e.g. by toolbox
    if (event === SiteObservationEvent.DETAILS_SELECTED) {
        dispatch(setSiteObservationsState(stSITE_OBSERVATIONS.DETAILS))
        return
    }

    if (event === SiteObservationEvent.REVIEW_SELECTED) {
        dispatch(setSiteObservationsState(stSITE_OBSERVATIONS.REVIEW))
        return
    }

    switch (stSiteObservations) {

        case stSITE_OBSERVATIONS.START:

            if (event === SiteObservationEvent.REGISTER_SELECTED) {
                dispatch(setSiteObservationsState(stSITE_OBSERVATIONS.REGISTER))
            }
            break

        case stSITE_OBSERVATIONS.REGISTER:

            if (event === SiteObservationEvent.BACK_SELECTED) {
                dispatch(setSiteObservationsState(stSITE_OBSERVATIONS.START))
            }
            break

        case stSITE_OBSERVATIONS.DETAILS:

            if (event === SiteObservationEvent.START_SELECTED) {
                dispatch(setSiteObservationsState(stSITE_OBSERVATIONS.START))
            }

            if (event === SiteObservationEvent.NEXT_SELECTED) {
                dispatch(setSiteObservationsState(stSITE_OBSERVATIONS.ACTIONS))
            }
            break

        case stSITE_OBSERVATIONS.ACTIONS:

            if (event === SiteObservationEvent.BACK_SELECTED) {
                dispatch(setSiteObservationsState(stSITE_OBSERVATIONS.DETAILS))
            }

            if (event === SiteObservationEvent.START_SELECTED) {
                dispatch(setSiteObservationsState(stSITE_OBSERVATIONS.START))
            }
            break

        case stSITE_OBSERVATIONS.REVIEW:

            if (event === SiteObservationEvent.BACK_SELECTED) {
                dispatch(setSiteObservationsState(stSITE_OBSERVATIONS.START))
            }
            break


        default:
            dispatch(setSiteObservationsState(stSITE_OBSERVATIONS.START))
    }
}