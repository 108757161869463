export enum stSIGNIN {
    INITIALISE,
    SCAN_QR,
    ENTER_CODE,
    CHECK_CODE,
    GET_USER,
    GET_LAST_SIGNIN,
    CONFIRM_SIGNIN_SIGNOUT,
    SELECT_SIGNIN_TYPE,
    WAIT_SITE_RESOURCES,
    WAIT_SIGNIN_DOCS,
    PRESENT_DOCS,
    RECORD_SIGNIN,
    COMPLETE
}

export enum SigninEvent {
    SITE_NOT_FOUND,
    RESOURCE_SUCCESS,
    RESOURCE_ERROR,
    REVIEW_DOCUMENT,
    RESET_SEQUENCE,
    PRESENT_DOCS_COMPLETE,
    SET_COMPLETE,
    SET_CODE_ENTRY_MODE,
    SET_CAMERA_MODE,
    URL_SIGNIN,
    SELECT_SIGNIN_TYPE
}