import { Geolocation, Position } from "@capacitor/geolocation"
import GeoPoint from "geopoint"
import { AppDispatch, RootState } from "../../redux/store"
import {
  getResources,
  requestResourcesWithRelated
} from "../../datastore"
import { Site } from "../../datastore/models"
import { setNearbySites } from "../../redux/slices/pgSignin"
import { setAlert } from "../../utils/alert"

const SEARCH_RADIUS = 0.5

export const findNearbySites =
  () =>
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      let myLocation: Position
      try {
        myLocation = await Geolocation.getCurrentPosition()
        console.log(JSON.stringify(myLocation.coords))
        if (!myLocation.coords) {
          throw (new Error('Unable to get location'))
        }
      } catch {
        dispatch(setAlert('Location Error', 'Unable to get location'))
        dispatch(setNearbySites([]))
        return
      }

      await requestResourcesWithRelated(
        "projects",
        {
          eqFilter: { status: "OPEN" }
        },
        ["projectSites.sites"]
      )(dispatch, getState, services)

      const nearbySites = getResources<Site>(getState().resources, "sites", {
        fnFilter: (site) => {
          try {
            const ewkt = site.attributes["location"]
            if (!ewkt) {
              return false
            }
            const siteLocation = EWKTToCoords(ewkt)

            const distance = new GeoPoint(
              siteLocation[1],
              siteLocation[0]
            ).distanceTo(
              new GeoPoint(myLocation.coords.latitude, myLocation.coords.longitude), true
            )
            return (distance < SEARCH_RADIUS)
          } catch {
            return false
          }
        }
      })
      dispatch(setNearbySites(nearbySites))
      if (!nearbySites.length) {
        dispatch(setAlert('No sites found', 'No sites found near your location'))
      }
    }

const EWKTToCoords = (EWKT: string) => {
  const pointContents = EWKT.slice(EWKT.indexOf("(") + 1, EWKT.indexOf(")"))
  return pointContents
    .split(" ")
    .map((value) => +value)
    .filter((value) => !isNaN(value))
}