import { useState } from 'react'
import { format, parseJSON } from 'date-fns'
import { IonButton, IonContent, IonFooter, IonIcon, IonItem, IonList } from "@ionic/react"
import { arrowForwardCircle, checkmarkCircle } from 'ionicons/icons'

import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { selectSignedinSiteId } from "../../redux/selectors/signins"
import { loadObservation } from '../../features/siteObservations/loadObservation'
import { useRegisterResources } from '../../features/siteObservations/resourceRequests/useRegisterResources'
import { useHomeIconHandler } from '../../hooks/homeIconHandler'
import { homeIconAction } from '../../features/siteObservations/homeIconAction'
import { updateSequence } from '../../features/siteObservations/siteObservationsSequence'
import { SiteObservationEvent } from '../../features/siteObservations/siteObservationsTypes'

import './Register.css'

export const Register: React.FC = () => {
    const dispatch = useAppDispatch()
    const signedinSiteId = useAppSelector(selectSignedinSiteId)
    const [currentSiteOnly, setCurrentSiteOnly] = useState<boolean>(true)
    const { observations } = useRegisterResources(currentSiteOnly ? signedinSiteId : null)

    const observationSelected = (observationId?: number) => {
        if (observationId) {
            dispatch(loadObservation(observationId))
        }
    }

    useHomeIconHandler('/observations', () => dispatch(homeIconAction()))

    return (
        <>
            <IonContent class="content-padding">
                <IonList id="registrar-list">
                    <div id="registrar-headings" className="register-table">
                        <h2>Date</h2>
                        <h2>Observation</h2>
                        <h2>O</h2>
                        <h2>S</h2>
                    </div>
                    {observations.map((observation, i) =>
                        <IonItem key={i} onClick={() => observationSelected(observation.id)} >
                            <div className="register-table">
                                <div className="flex column">
                                    <span>{format(parseJSON(observation.observationDate), 'EEE')}</span>
                                    <span>{format(parseJSON(observation.observationDate), 'dd MMM')}</span>
                                    <span>{format(parseJSON(observation.observationDate), 'yyyy')}</span>
                                </div>
                                <span>{observation.description}</span>
                                {observation.observerClosedDate ?
                                    <IonIcon icon={checkmarkCircle} size="large" />
                                    : observation.notifiedSupervisor ?
                                        <IonIcon icon={arrowForwardCircle} size="large" />
                                        : <span></span>
                                }
                                {observation.supervisorClosedDate ?
                                    <IonIcon icon={checkmarkCircle} size="large" />
                                    : <span></span>
                                }
                            </div>
                        </IonItem>
                    )}

                </IonList>

            </IonContent>

            <IonFooter class="observations-nav">
                <IonButton onClick={() => dispatch(updateSequence(SiteObservationEvent.BACK_SELECTED))}
                    style={{ "gridColumnStart": "1" }}>Back</IonButton>
                {signedinSiteId ? <>
                    <IonButton color={currentSiteOnly ? 'success' : 'light'} onClick={() => setCurrentSiteOnly(true)}>Current Site</IonButton>
                    <IonButton color={currentSiteOnly ? 'light' : 'success'} onClick={() => setCurrentSiteOnly(false)}>All Sites</IonButton>
                </> : <></>}
            </IonFooter>
        </>)
}
