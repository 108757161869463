import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import {
    IonButton,
    IonContent,
    IonFooter,
    IonIcon,
    IonItem,
    IonItemOption,
    IonLabel,
    IonList,
    IonReorder,
    IonReorderGroup,
    IonToggle,
    ItemReorderEventDetail,
    ToggleChangeEventDetail
} from "@ionic/react"

import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { setTaSteps } from '../../redux/slices/pgTaskAnalysis'
import { TaStep, TaStepState } from '../../datastore/models/Ta'
import { useRef, useState } from 'react'
import { repeat } from 'ionicons/icons'
import { SlidingItem } from '../../components/SlidingItem'
import { selectTaEdit } from '../../redux/selectors/tas'
import TaskHeading from './TaskHeading'
import { useHomeIconHandler } from '../../hooks/homeIconHandler'
import { homeIconAction } from '../../features/taskAnalysis/homeIconAction'

import './StepListCreate.css'
import Input from '../../components/form/Input'
import _ from 'lodash'
import { TaEvent, updateSequence } from '../../features/taskAnalysis/taskAnalysisSequence'
import { saveTa } from '../../features/taskAnalysis/saveTa'

type Inputs = {
    steps: TaStep[]
}

const schema = yup.object().shape({
    steps: yup.array().of(
        yup.object().shape({
            text: yup.string().required("Each step needs a name. If you don't need this step, you can delete it by sliding it left"),
        })
    )
}).required()


export const StepListCreate: React.FC = () => {
    const dispatch = useAppDispatch()
    const { savedAsDraft } = useAppSelector((state) => state.pgTaskAnalysis)
    const taEdit = useAppSelector(selectTaEdit)
    const [reorder, setReorder] = useState<boolean>(false)


    const { handleSubmit, control, watch, setValue, reset, formState: { errors, isDirty } } = useForm<Inputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            steps: taEdit.userData.steps
        }
    })

    const { fields, remove, insert, append, move } = useFieldArray({
        control,
        name: "steps",
        keyName: "ufaId", // defaults to "id" - changed so as not to overwrite resource id
    })

    const insertStep = (index: number) => {
        stepListRef?.current?.closeSlidingItems()
        insert(index, {
            text: '',
            placeholder: '(new step)',
            hazards: [],
            state: TaStepState.NONE
        })
    }

    const addStep = () => {
        append({
            text: '',
            placeholder: '(new step)',
            hazards: [],
            state: TaStepState.NONE
        })
    }

    const deleteStep = (index: number) => {
        stepListRef?.current?.closeSlidingItems()
        remove(index)
    }

    const formSubmitBack = (data: any) => {
        formSubmit(data)
        dispatch(updateSequence(TaEvent.BACK_SELECTED))
    }

    const formSubmitSaveDraft = (data: any) => {
        formSubmit(data)
        dispatch(saveTa())

        // Reset form with updated values (resets 'dirty' status and allows us to detect further changes)
        reset(data)

        // May 2023: decided to go back to start menu on 'save Draft'
        // => means above is probably not necessary
        dispatch(updateSequence(TaEvent.START_SELECTED))
    }

    const formSubmitNext = (data: any) => {
        formSubmit(data)
        dispatch(updateSequence(TaEvent.NEXT_SELECTED))
    }

    const formSubmit = (data: any) => {

        if (isDirty) {
            dispatch(setTaSteps(data.steps))
        }
    }

    const stepListRef = useRef<HTMLIonListElement>(null)

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        move(event.detail.from, event.detail.to)

        // Finish the reorder - calling with 'false' prevents the items being reordered in the DOM
        // which we don't want because we've re-ordered the underlying array, we just need it redrawn
        event.detail.complete(false);
    }

    useHomeIconHandler('/task-analysis', () => dispatch(homeIconAction()))

    return (
        <>
            <IonContent className="content-padding">
                <TaskHeading />
                <div className="reorder-toggle-container flex row">
                    <IonLabel>Change Order:</IonLabel>
                    <IonToggle slot="end" onIonChange={(e) => setReorder((e.detail as ToggleChangeEventDetail).checked)} />
                </div>

                <IonList id="task-step-list" ref={stepListRef}>
                    <IonReorderGroup disabled={!reorder} onIonItemReorder={doReorder}>
                        {fields?.map((step, i) => (

                            reorder ?
                                <IonReorder slot="start" key={step.ufaId}>
                                    <IonItem>
                                        <div className="reorder-row">
                                            <IonIcon icon={repeat} />
                                            <h3>{i + 1}.</h3>
                                            <h3>{step.text}</h3>
                                        </div>
                                    </IonItem>
                                </IonReorder>
                                :
                                <SlidingItem
                                    key={step.ufaId}
                                    itemContent={
                                        <div className="flex row step-item-container" >
                                            <h3>{i + 1}.</h3>

                                            <Input
                                                fieldName={`steps[${i}].text`}
                                                placeholder={step.placeholder}
                                                inputMode="text"
                                                watch={watch}
                                                setValue={setValue}
                                                error={_.get(errors, ['steps', i, 'text'])}
                                            />

                                        </div>
                                    }
                                    optionsContent={
                                        <>
                                            <IonItemOption color="primary" onClick={() => insertStep(i)}>Insert Step</IonItemOption>
                                            <IonItemOption color="danger" onClick={() => deleteStep(i)}>Delete Step</IonItemOption>
                                        </>
                                    }
                                />
                        ))}
                    </IonReorderGroup>
                </IonList>
                <div className="flex centre-row">
                    <IonButton onClick={() => { addStep() }}>Add Task Step</IonButton>
                </div>
            </IonContent>

            <IonFooter className='TA-nav-footer'>
                <IonButton onClick={handleSubmit(formSubmitBack)}>BACK</IonButton>
                <IonButton disabled={!taEdit.draft || (savedAsDraft && !isDirty)} onClick={handleSubmit(formSubmitSaveDraft)}>Save Draft</IonButton>
                <IonButton onClick={handleSubmit(formSubmitNext)}>NEXT</IonButton>
            </IonFooter>
        </>
    )
}
