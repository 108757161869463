import { useEffect, useRef, useState } from "react"
import { uniq } from "lodash"

import { DocTypeContainerProps } from "../DocContainer"

import { useImages } from "./useImages"
import { useDocumentContent } from "./useDocumentContent"
import { populateTemplate } from "./populateTemplate"

import "./HtmlDocContainer.css"
import { IonSpinner } from "@ionic/react"
import log from "loglevel"

const HtmlDocContainer: React.FC<DocTypeContainerProps> = ({
  docData,
  selectorParams,
  refreshTrigger,
  setUserData,
  setIsValid,
  setWarnMessages,
  setBlockMessages,
  setIsLoading,
  containerClass
}) => {
  const containerRef = useRef(null)
  const iframeRef = useRef(null)
  const componentMounted = useRef(true)

  const [renderError, setRenderError] = useState(false)

  const {
    docHtml,
    interactive,
    incompleteMessage,
    title,
    styleNode,
    body,
    reportData,
    isLoading,
    isSuccess,
    isError
  } = useDocumentContent(docData, selectorParams)
  const { getImgData, imagesReady } = useImages(reportData?.images)

  /**
   * On new report data and / or images, merge with template and add to DOM
   *
   * (reportDataReady and imagesReady will default to 'true' if document doesn't use any live data or images)
   */
  useEffect(() => {
    if (!containerRef.current || !isSuccess) {
      return
    }
    // container=actual DOM element that document content will be added to
    const container = containerRef.current as HTMLDivElement

    setRenderError(false)

    if (!interactive) {
      // *** No user interaction in document -> use iFrame ***
      try {
        const outputHtml = populateTemplate(docHtml, reportData, getImgData)

        const iframe = iframeRef.current as unknown as HTMLIFrameElement
        if (iframe) {
          iframe.srcdoc = outputHtml
          iframe.onload = function () {
            if (iframe.contentWindow) {
              iframe.style.height =
                iframe.contentWindow.document.body.scrollHeight + "px"
            }
          }
        }
      } catch (err) {
        log.error(`Error parsing document template '${title}': ${err}`)
        setRenderError(true)
      }

      if (setUserData) {
        setUserData({})
      }
      if (setIsValid) {
        setIsValid(true)
      }
    } else {
      // *** Document includes user interaction -> insert body directly into DOM ***
      try {
        // Note we're only parsing the body, not the whole document
        // -> not currently supporting template expressions in head for interative docs
        const bodyHtml = populateTemplate(body, reportData, getImgData)

        container.innerHTML = bodyHtml

        // append style node from document to DOM head
        if (styleNode) {
          ;(styleNode as HTMLElement).setAttribute(
            "data-template-doc-stylesheet",
            "true"
          )
          document.head.appendChild(styleNode)
        }

        // Look for any user input elements
        const docInputs = container.getElementsByTagName("input")
        if (docInputs.length) {
          // Connect an event handler to fire on any input events
          for (const input of Array.from(docInputs)) {
            input.addEventListener("input", handleInputEvent)
          }
          // Read initial state of inputs
          const initialUserData = readUserData()
          if (
            incompleteMessage &&
            setBlockMessages &&
            !userDataComplete(initialUserData)
          ) {
            // If there's an 'incomplete' message, it overrides any 'block on invalid' messages
            setBlockMessages([incompleteMessage])
          }
        } else {
          // If document doesn't contain any user input, signal to caller that there is no user data
          // and user input is valid by default
          if (setUserData) {
            setUserData({})
          }
          if (setIsValid) {
            setIsValid(true)
          }
        }
      } catch (err) {
        log.error(`Error parsing document template '${title}': ${err}`)
        setRenderError(true)
      }
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isSuccess, imagesReady])

  // If parent component supplied a 'setIsLoading' function, use it to signal loading state
  // Parent will initially set isLoading true - set to false once we have finished loading
  useEffect(() => {
    if (setIsLoading && (isSuccess || isError)) {
      setIsLoading(false)
    }
  }, [isSuccess, isError])

  // Component cleanup (needs to be a useEffect with no dependencies to be called only on unmount)
  useEffect(() => {
    componentMounted.current = true
    return () => {
      const headElement = document.head
      const styleElements = headElement?.getElementsByTagName("style")

      // remove style element from dom if we added one
      if (styleElements) {
        for (const styleElement of Array.from(styleElements)) {
          if (styleElement.dataset.templateDocStylesheet) {
            document.head.removeChild(styleElement)
          }
        }
      }
      // reset 'componentMounted'
      componentMounted.current = false
    }
  }, [])

  const handleInputEvent = (e: Event) => {
    const userData = readUserData()
    if (incompleteMessage && setBlockMessages && !userDataComplete(userData)) {
      // If there's an 'incomplete' message, it overrides any 'block on invalid' messages
      setBlockMessages([incompleteMessage])
    }
  }

  const readUserData = () => {
    let userData: Record<string, string> = {}
    const container = containerRef.current
    if (container) {
      let warnMessages: string[] = []
      let blockMessages: string[] = []
      // Assume user input is valid until we confirm it isn't
      let isValid = true
      const docInputs = (container as HTMLDivElement).getElementsByTagName(
        "input"
      )
      for (const input of Array.from(docInputs)) {
        let inputValue
        switch (input.type) {
          case "radio":
            inputValue = getRadioValue(userData, input)
            break
          case "checkbox":
            inputValue = input.checked.toString()
            break
          default:
            inputValue = input.value
        }

        if (input.dataset.fieldName) {
          userData[input.dataset.fieldName] = inputValue || ""
        }

        if (
          inputValue &&
          input.dataset.validValue &&
          input.dataset.validValue !== inputValue
        ) {
          if (input.dataset.warnMessage) {
            isValid = false
            warnMessages.push(input.dataset.warnMessage)
          }
          if (input.dataset.blockMessage) {
            isValid = false
            blockMessages.push(input.dataset.blockMessage)
          }
        }
      }
      if (setUserData) {
        setUserData(userData)
      }
      if (setIsValid) {
        setIsValid(isValid)
      }
      if (setWarnMessages) {
        setWarnMessages(uniq(warnMessages))
      }
      if (setBlockMessages) {
        setBlockMessages(uniq(blockMessages))
      }
    }
    return userData
  }

  return isLoading ? (
    <div className="flex centre-row" style={{ paddingBottom: "10px" }}>
      <IonSpinner name="circles" />
    </div>
  ) : (
    <div ref={containerRef} className={containerClass || "html-doc-container"}>
      {interactive ? <></> : <iframe title="Document Viewer" ref={iframeRef} />}
      {isError || renderError ? (
        <div className="flex centre-row" style={{ paddingBottom: "10px" }}>
          <h3>Unable to display Document</h3>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

/**
 * Get radio button group value, based on previously known value (from userData) and the state of a specified button
 *
 * (Yes / No radio buttons are effectively tri-state - need to know if neither is selected)
 *
 * @returns :
 * - this input's data-checked-value if checked
 * - value from userData if unchecked (which will have been set by any other selected input in the group if we've already it)
 * - empty string if unchecked and no value in userData (meaning value is not yet known or no option is selected)
 */
const getRadioValue = (
  userData: Record<string, string>,
  input: HTMLInputElement
) => {
  const fieldName = input.dataset.fieldName
  const checkedValue = input.dataset.checkedValue

  if (!fieldName || !checkedValue) return ""

  if (input.checked) {
    return checkedValue
  } else {
    if (userData.hasOwnProperty(fieldName)) {
      return userData[fieldName]
    } else {
      return ""
    }
  }
}

/**
 * Check for 'user data complete' - primarily used to check that all radio button groups have at least one option selected
 *
 * @returns false if any item in userData is 'falsy' (radio button values will be an empty string if no option is selected)
 */
const userDataComplete = (userData: Record<string, string>) => {
  for (const key in userData) {
    if (!userData[key]) {
      return false
    }
  }
  return true
}

export default HtmlDocContainer
