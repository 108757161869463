const projectSites = {
    table: 'project_sites',
    idKey: 'id',

    attrs: [
        'closedDate',
        'creationDate',
        'lastModifiedDate',
        'active',
        'deleted'
    ],

    relationships: [
        {
            type: 'companies',
            key: 'company',
        },
        {
            type: 'projects',
            key: 'project',            
        },
        {
            type: 'sites',
            key: 'site',            
        },
    ],
}

export default projectSites