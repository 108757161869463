import { isPlatform } from "@ionic/react"
import { AppDispatch, RootState } from "../../redux/store"
import { getAppOpenUrl } from "./getAppOpenUrl"
import {
  setAppOpenUrl,
  setCurrentPath,
  setRedirectPath
} from "../../redux/slices/temp"

/**
 * Ensure app starts in either the 'callback' path (if logging in) or 'splash' (on any other startup) 
 */
export const initialisePath = () => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
  const url = await getAppOpenUrl()
  dispatch(setAppOpenUrl(url.href))
  
  switch (url.pathname) {
    case "/callback":
      // Initialise currentPath in store with actual path we're starting with
      dispatch(setCurrentPath("/callback"))
      break
  
    default:
      if (url.pathname === "/splash") {
        // Initialise currentPath in store with actual path we're starting with
        dispatch(setCurrentPath("/splash"))
  
      } else {
        // Unless path set to 'callback' (i.e. user logging in), go to splash screen at startup
        // This ensures the splash screen is always shown, but will prevent deep linking
        // => don't see any need for deep linking at present?
        if (!isPlatform("capacitor")) {
          window.history.pushState(null, "", "/splash")
        }
        dispatch(setRedirectPath("/splash"))
      }
    }
  }