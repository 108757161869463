import { useEffect } from 'react'
import { useIonModal, UseIonModalResult } from '@ionic/react'
import { useAppDispatch } from '../../redux/hooks'
import { SelectCategoryItem } from "../../components/SelectCategoryItem"
import { selectJobCategoryContents, selectJobCategoryPath } from "../../redux/selectors/categories"
import { selectTaTemplate, selectTaTemplateFromName } from "../../redux/selectors/taTemplates"
import { createTa } from '../../features/taskAnalysis/createTa'
import { AppDispatch, RootState } from '../../redux/store'

const createTaThenDismiss = (taTemplateId: number, dismissModal: () => void) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    await createTa(taTemplateId)(dispatch, getState, services)
    dismissModal()
}

interface SelectTaTemplateModalProps {
    setModalFns: (useIonModalResult: UseIonModalResult) => void
}

export const SelectTaTemplateModal: React.FC<SelectTaTemplateModalProps> = ({
    setModalFns
}) => {
    const dispatch = useAppDispatch()

    const modalOk = (companyId: number | null, categoryId: number | null, itemId: number | null, itemName: string) => {

        if (itemId) {
            dispatch(createTaThenDismiss(itemId, dismissModal))
        } else {
            dismissModal()
        }
    }

    const modalCancel = () => {
        dismissModal()
    }

    const [presentModal, dismissModal] = useIonModal(SelectCategoryItem, {
        selectOnly: true,
        itemType: 'Template',
        selectCategoryPath: selectJobCategoryPath,
        selectCategoryContents: selectJobCategoryContents,
        selectItem: selectTaTemplate,
        selectItemFromName: selectTaTemplateFromName,
        setResult: modalOk,
        cancel: modalCancel,
        loadingSpinner:true
    })

    useEffect(() => {
        setModalFns([presentModal, dismissModal])
    },[])    

    return (<></> )
}