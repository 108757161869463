const docInfo = {
  table: 'doc_info',
  idKey: 'id',

  attrs: [
    'fileName',
    'path',
    'viewAccess', 
    'showInApp',
    'creationDate',
    'lastModifiedDate',
    'deleted'
  ],

  relationships: [   
    {
      type: 'companies',
      key: 'company',
    },
    {
      type: 'projects',
      key: 'project',
    },
    {
      type: 'sites',
      key: 'site',
    },
    {
      type: 'users',
      key: 'user',
    }
  ],
}

export default docInfo