import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { setHomeIconEvent } from "../redux/slices/temp"

/**
 * 'Home Icon' handler
 * 
 * Monitors 'homeIconEvent' flag and triggers any page - specific function that should occur before leaving the current page
 * 
 * Tapping the home icon returns the user to either the home screen or the toolbox activity. If they are in an activity that 
 * doesn't involve data entry etc then the home icon simply changes the current route, otherwise the following will occur:
 * 
 * - home icon onClick sets 'homeIconEvent' flag
 * - this hook clears 'homeIconEvent' flag and calls 'homeFn'
 * - if page contains a form, homeFn will trigger a submit. If form validates OK, data will be saved and an activity-specific
 *   'homeIconAction' called
 * - if page doesn't contain a form, 'homeFn' calls activity-specific 'homeIconAction' directly
 * 
 * @param homeFn page - specific function to call when home icon is tapped
 */
export const useHomeIconHandler = (thisPath: string, homeFn: () => void) => {
    const dispatch = useAppDispatch()
    const { currentPath, homeIconEvent } = useAppSelector(state => state.temp)

    useEffect(() => {
        // Need to check the path because ion router will sometimes keep previous pages in scope
        // -> need to make sure we only call homeFn for the currently active page 
        if (homeIconEvent && currentPath === thisPath) {
            dispatch(setHomeIconEvent(false))
            homeFn()
        }
    }, [homeIconEvent])
}