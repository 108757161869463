const controls = {
  table: 'controls',
  idKey: 'id',

  attrs: [
    'hierarchy',
    'controlText',
    'controlTextShort',
    'detail',
    'creationDate',
    'lastModifiedDate',
    'deleted'
  ],

  relationships: [       
  ],
}

export default controls