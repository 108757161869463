/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import Axios, { AxiosError } from 'axios'
import { AXIOS_GET_TIMEOUT } from '.'
import { getApiRoot } from '../helpers/getApiRoot'
import { ApiResult } from '../interfaces/api'
import { updateOnlineStatus } from './onlineStatus'

export const apiPing = async ():Promise<ApiResult> => {

  try {
    await Axios.get(`${getApiRoot()}ping`,
    { timeout: AXIOS_GET_TIMEOUT }
    )
    // If api is offline, don't set back online here - calling code needs to re-establish session first
    //updateOnlineStatus(ApiResult.SUCCESS)
    return ApiResult.SUCCESS

  } catch (err: any | AxiosError) {
    if (Axios.isAxiosError(err) && err.response) {
      // API responded with error code
      if (err.response.status === 401) {
        return ApiResult.AUTH_ERROR

      } else if (err.response.status >= 400 && err.response.status <= 499) {
        return ApiResult.CLIENT_ERROR

      } else {
        return ApiResult.SERVER_ERROR
      }

    } else {
      // Request failed or no response from API
      // Probably means backend is down or no internet connection
      updateOnlineStatus(ApiResult.NETWORK_ERROR)
      return ApiResult.NETWORK_ERROR
    }
  }
}
  