import { AppDispatch, RootState } from '../../redux/store'
import { setSiteObservationDetails } from "../../redux/slices/pgSiteObservations"
import { returnToStart } from "./returnToStart"
import { sendObservationNotification } from "./sendObservationNotification"
import { setAlert } from '../../utils/alert'
import { saveObservation } from './saveObservation'

export const notifyObservation = (supervisorId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {

    dispatch(setSiteObservationDetails({ notifiedSupervisor: supervisorId }))

    // Need to await save, following processes read saved version
    await saveObservation()(dispatch, getState)
    const observationId = getState().pgSiteObservations.siteObservationEdit.details.id

    if (observationId) {        

        dispatch(sendObservationNotification(observationId, supervisorId))
        dispatch(setAlert(
            'Supervisor Notified',
            'Thank you for recording this observation - your selected supervisor has been notified'
        ))
    }
    dispatch(returnToStart())
}