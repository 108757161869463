import { IonButton, IonContent, IonInput } from "@ionic/react";
import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { isValidSigninCode, updateSequence } from "../../features/signin/signinSequence";
import { SigninEvent, stSIGNIN } from "../../features/signin/signinTypes";
import { setSigninCode } from "../../redux/slices/pgSignin";

const EnterCode: React.FC = () => {
    const { stSignin } = useAppSelector((state) => state.pgSignin)

    const [pageSigninCode, setpageSigninCode] = useState<string>("")
    const [codeEntryDone, setCodeEntryDone] = useState<boolean>(false)

    useEffect(() => {
        if (stSignin !== stSIGNIN.ENTER_CODE) {
            setpageSigninCode("")
            setCodeEntryDone(false)
        }
    }, [stSignin])

    const dispatch = useAppDispatch()


    function enterSigninCode(signinCode: string) {
        setpageSigninCode(signinCode)
        if (isValidSigninCode(signinCode)) {
            dispatch(setSigninCode(signinCode))
            setCodeEntryDone(true)
            setpageSigninCode("")
        }
    }

    return (

        <IonContent class="signin-page">
            <div id="site-code-container">
                <h1>Enter 6 digit site code:</h1>
                <div id="all-inputs" >
                    <div id="inputs-container">
                        {/* <IonInput disabled={true}></IonInput>
                            <IonInput disabled={true}></IonInput>
                            <IonInput disabled={true}></IonInput>
                            <IonInput disabled={true}></IonInput>
                            <IonInput disabled={true}></IonInput>
                            <IonInput disabled={true}></IonInput> */}
                        <IonInput value={pageSigninCode.slice(0, 1)} placeholder="0"></IonInput>
                        <IonInput value={pageSigninCode.slice(1, 2)} placeholder="0"></IonInput>
                        <IonInput value={pageSigninCode.slice(2, 3)} placeholder="0"></IonInput>
                        <IonInput value={pageSigninCode.slice(3, 4)} placeholder="0"></IonInput>
                        <IonInput value={pageSigninCode.slice(4, 5)} placeholder="0"></IonInput>
                        <IonInput value={pageSigninCode.slice(5, 6)} placeholder="0"></IonInput>
                    </div>
                    {!codeEntryDone ?
                        <IonInput id="main-input"
                            autofocus={true}
                            placeholder="000000"
                            inputmode="numeric"
                            enterkeyhint="go"
                            onIonInput={e => enterSigninCode(e.detail.value!)}>
                        </IonInput>
                        : <></>}
                </div>
                <IonButton onClick={() => dispatch(updateSequence(SigninEvent.SET_CAMERA_MODE))}>Return to QR Code scanner</IonButton>
            </div>
        </IonContent>
    )
}

export default EnterCode