import { addDays, differenceInDays, isAfter, parseJSON } from 'date-fns'
import { getLogger } from 'loglevel'

import { AppDispatch, RootState } from '../../redux/store'
import { setViewSite } from "../../redux/slices/app";
import { setAlertResponse, setRedirectPath } from "../../redux/slices/temp";
import {
    setSigninCode,
    setSigninSite,
    setSigninState,
    setDocList,
    setSelectedDocIndex,
    setSigninType,
    setIsInduction,
    setSelectedDocTitle
} from '../../redux/slices/pgSignin'

import { selectAllSiteSigninDocsLoaded, selectDocInfoLoaded, selectNoSiteSigninDocsLoading } from '../../redux/selectors/docInfo'
import { selectIsSignedIn, selectLatestSignin, selectSignedinSiteId } from '../../redux/selectors/signins'
import { selectLatestInduction } from '../../redux/selectors/inductions'
import { selectSite } from '../../redux/selectors/sites'
import { selectSigninDocs } from '../../redux/selectors/signinDocs';

import { RequestStatus } from '../../datastore/types'
import { SigninEvent, stSIGNIN } from './signinTypes'
import { getResource, saveResource } from '../../datastore'
import { setAlert } from '../../utils/alert'

import { requestSiteBySigninCode, requestSiteBySigninCodeResult } from './resourceRequests/requestSiteBySigninCode'
import { requestLatestSignin, requestLatestSigninResult } from './resourceRequests/requestLatestSignin'
import { requestInductions, requestInductionsResult } from './resourceRequests/requestInductions'

import { createReactor } from '../../redux/selectors/helpers/createReactor';
import { signout } from './signout';
import { initialiseSiteResourceUpdate } from '../resources/siteResourceUpdate';
import { requestSigninSiteData, requestSigninSiteDataResult } from './resourceRequests/requestSigninSiteData';
import { Site } from '../../datastore/models';
import { logout } from '../auth';

const log = getLogger('signin')

export const isValidSigninCode = (signinCode: string | null): boolean => {
    if (!signinCode) {
        return false
    }
    if (signinCode.length !== 6 || !(/[a-z]|[0-9]|&/i.test(signinCode))) {
        return false
    }
    return true
}

var lastState = -1
export const updateSequence = (event?: SigninEvent) => (dispatch: AppDispatch, getState: () => RootState) => {

    let state = getState()

    const { stSignin, signinCode, signinSiteId, induction } = state.pgSignin
    const { user } = state.app
    const { startupInProgress, currentPath } = state.temp

    let isSignedIn

    if (event === SigninEvent.RESET_SEQUENCE) {
        dispatch(setSigninCode(''))
        dispatch(setSigninState(stSIGNIN.INITIALISE))
        return
    }

    if (event === SigninEvent.SET_COMPLETE) {
        dispatch(setSigninState(stSIGNIN.COMPLETE))
        return
    }

    // Wait for startup logic to complete before updating sequence
    if (startupInProgress) {
        return
    }

    // Flag indicating this is the first update following a state change
    const stateEntry = (stSignin !== lastState)
    lastState = stSignin

    switch (stSignin) {

        case stSIGNIN.INITIALISE:
            if (stateEntry) {
                if (signinSiteId) {
                    dispatch(setSigninSite(null))
                }
            }

            if (currentPath === '/signin') {
                // Signin page displayed - check code if we have one already (i.e. from url signin), otherwise start scanning
                if (signinCode) {
                    dispatch(setSigninState(stSIGNIN.CHECK_CODE))
                } else {
                    dispatch(setSigninState(stSIGNIN.SCAN_QR));
                }
            }
            break

        case stSIGNIN.SCAN_QR:
            if (stateEntry && user?.isGuest) {
                // Shouldn't be able to get here while still logged in as guest, but if we do, logout before continuing
                dispatch(logout())
            }

            if (signinCode) {
                dispatch(setSigninState(stSIGNIN.CHECK_CODE))
            }

            if (event === SigninEvent.SET_CODE_ENTRY_MODE) {
                dispatch(setSigninState(stSIGNIN.ENTER_CODE))
            }

            if (currentPath !== '/signin') {
                // If user leaves signin page, stop scanning
                dispatch(setSigninState(stSIGNIN.INITIALISE));
            }
            break

        case stSIGNIN.ENTER_CODE:

            if (signinCode) {
                dispatch(setSigninState(stSIGNIN.CHECK_CODE))
            }

            if (event === SigninEvent.SET_CAMERA_MODE) {
                dispatch(setSigninState(stSIGNIN.SCAN_QR))
            }

            if (currentPath !== '/signin') {
                // If user leaves signin page, reset sequence
                dispatch(setSigninState(stSIGNIN.INITIALISE));
            }
            break

        case stSIGNIN.CHECK_CODE:
            if (stateEntry) {
                dispatch(requestSiteBySigninCode(signinCode))

                console.log('entered CHECK_CODE state')

            } else {
                const result = requestSiteBySigninCodeResult(getState())

                switch (result) {

                    case RequestStatus.NO_RESOURCE_FOUND:
                        dispatch(setAlert('Site Signin Failed', 'Site or QR Code may be invalid'))
                        dispatch(setSigninCode(''))
                        dispatch(setSigninState(stSIGNIN.INITIALISE))
                        break

                    case RequestStatus.RESOURCE_LOAD_ERROR:
                        dispatch(setAlert('Site Signin Failed', 'Error connecting to server'))
                        dispatch(setSigninCode(''))
                        dispatch(setSigninState(stSIGNIN.INITIALISE))
                        break

                    case RequestStatus.RESOURCE_VALID:
                        // requestSiteBySigninCode succeeded...
                        const site = selectSite(getState(), { signinCode: state.pgSignin.signinCode })
                        if (!site) {
                            dispatch(setAlert('Site Signin Failed', 'Site or QR Code may be invalid'))
                            dispatch(setSigninCode(''))
                            dispatch(setSigninState(stSIGNIN.INITIALISE))
                        } else {
                            const siteId = site.id
                            dispatch(setSigninSite(siteId))

                            // Initiate background update of site - specific resources
                            dispatch(initialiseSiteResourceUpdate(siteId))
                            dispatch(requestSigninSiteData(siteId))

                            if (user) {
                                dispatch(setSigninState(stSIGNIN.GET_LAST_SIGNIN))
                            } else {
                                dispatch(setSigninState(stSIGNIN.GET_USER))
                            }
                        }
                }

            }
            break

        case stSIGNIN.GET_USER:
            if (stateEntry) {
                console.log('entered GET_USER')
            }

            if (user) {
                dispatch(setSigninState(stSIGNIN.GET_LAST_SIGNIN))
            }
            break

        case stSIGNIN.GET_LAST_SIGNIN:

            if (stateEntry) {
                console.log('entered GET_LAST_SIGNIN')

                const signinSite = selectSite(state, { siteId: signinSiteId })
                const inductionDays = signinSite?.inductionDays || 0

                dispatch(requestLatestSignin(user!.id!))
                dispatch(requestInductions({ userId: user?.id, siteId: signinSiteId, periodStart: addDays(new Date(), (inductionDays + 5) * -1) }))

            } else {
                const signinResult = requestLatestSigninResult(getState())
                const inductionResult = requestInductionsResult(getState())

                if (signinResult === RequestStatus.RESOURCE_LOAD_ERROR) {
                    log.warn('Signin sequence: error getting last signin')
                }

                // Proceed once both requests above have completed. If error or no resource found, continue anyway - 
                // will either use last known signin status or treat as new user
                if ((
                    signinResult === RequestStatus.NO_RESOURCE_FOUND ||
                    signinResult === RequestStatus.RESOURCE_LOAD_ERROR ||
                    signinResult === RequestStatus.RESOURCE_VALID
                ) && (
                        inductionResult === RequestStatus.NO_RESOURCE_FOUND ||
                        inductionResult === RequestStatus.RESOURCE_LOAD_ERROR ||
                        inductionResult === RequestStatus.RESOURCE_VALID
                    )) {

                    const isSignedIn = selectIsSignedIn(state)

                    if (isSignedIn) {
                        dispatch(setSigninState(stSIGNIN.CONFIRM_SIGNIN_SIGNOUT))

                    } else {
                        const site = selectSite(state, { siteId: signinSiteId })
                        // If this site has more than one signin option, go to 'choose signin type' screen
                        if (+(site?.visitorSignin || 0) + +(site?.workerSignin || 0) + +(site?.driverSignin || 0) > 1) {
                            dispatch(setSigninState(stSIGNIN.SELECT_SIGNIN_TYPE))
                        } else {
                            dispatch(setSigninState(stSIGNIN.WAIT_SITE_RESOURCES))
                        }
                    }
                }
            }
            break;

        case stSIGNIN.CONFIRM_SIGNIN_SIGNOUT:

            const signedinSite = selectSite(state, { siteId: selectSignedinSiteId(state) || undefined })
            const thisSite = selectSite(state, { siteId: state.pgSignin.signinSiteId })
            if (stateEntry) {

                dispatch(setAlertResponse(''))

                if (signedinSite?.id === thisSite?.id) {
                    dispatch(setAlert(
                        'Already Signed In',
                        `You are currently signed in to ${signedinSite?.siteName}, do you want to sign out?`,
                        [{
                            text: 'Remain Signed In',
                            role: 'cancel',
                            handler: () => {
                                dispatch(setAlertResponse('cancel'))
                                dispatch(updateSequence())
                                return true
                            }
                        },
                        {
                            text: 'Sign Out',
                            handler: () => {
                                dispatch(setAlertResponse('ok'))
                                dispatch(updateSequence())
                                return true
                            }
                        }]
                    ))
                } else {
                    dispatch(setAlert(
                        'Signed In to Different Site',
                        `You are currently signed in to ${signedinSite?.siteName}, you will be signed out before signing in to ${thisSite?.siteName}`,
                        [{
                            text: 'Ok',
                            handler: () => {
                                dispatch(setAlertResponse('ok'))
                                dispatch(updateSequence())
                                return true
                            }
                        }]
                    ))
                }
            } else {
                if (signedinSite?.id === thisSite?.id) {
                    if (state.temp.alertResponse === 'cancel') {
                        dispatch(setSigninState(stSIGNIN.COMPLETE))
                        dispatch(setRedirectPath('/home'))
                    }

                    if (state.temp.alertResponse === 'ok') {
                        dispatch(signout())
                        dispatch(setSigninState(stSIGNIN.INITIALISE))
                        if (user && !user.isGuest) {
                            // If non-guest user signs out, redirect to home page
                            // (guest users will be logged out on signout and redirected to signin page)
                            dispatch(setRedirectPath('/home'))
                        }
                    }

                } else {
                    if (state.temp.alertResponse === 'ok') {
                        const signin = selectLatestSignin(state, { siteId: null, userId: user!.id })
                        if (signin) {
                            dispatch(saveResource({
                                ...signin,
                                signoutTime: new Date()
                            }, 'signins'))
                        }

                        // Initiate background update of site - specific resources
                        // Need to do this here because we're switching from one site to another
                        dispatch(initialiseSiteResourceUpdate(thisSite?.id))
                        dispatch(requestSigninSiteData(thisSite?.id))

                        // If this site has more than one signin option, go to 'choose signin type' screen
                        if (+(thisSite?.visitorSignin || 0) + +(thisSite?.workerSignin || 0) + +(thisSite?.driverSignin || 0) > 1) {
                            dispatch(setSigninState(stSIGNIN.SELECT_SIGNIN_TYPE))
                        } else {
                            dispatch(setSigninState(stSIGNIN.WAIT_SITE_RESOURCES))
                        }
                    }
                }
            }
            break

        case stSIGNIN.SELECT_SIGNIN_TYPE:
            if (event === SigninEvent.SELECT_SIGNIN_TYPE) {
                dispatch(setSigninState(stSIGNIN.WAIT_SITE_RESOURCES))
            }
            break

        case stSIGNIN.WAIT_SITE_RESOURCES:
            if (stateEntry) {
                console.log('Entered WAIT_SITE_RESOURCES')
            }
            // Check on the result of background update started either:
            // - in stSIGNIN.CHECK_CODE state, or
            // - in startup function if user logged in partway through sequence            
            const loadResult = requestSigninSiteDataResult(getState())
            if (loadResult === RequestStatus.RESOURCE_LOAD_ERROR) {

                log.debug('signinSequence:requestSigninSiteDataResult returned RESOURCE_LOAD_ERROR')

                dispatch(setAlert('Site Signin Failed', 'Error connecting to server'))
                dispatch(setSigninCode(''))
                dispatch(setSigninState(stSIGNIN.INITIALISE))
            }

            if (loadResult === RequestStatus.RESOURCE_VALID) {

                console.log('WAIT_SITE_RESOURCES RESOURCE_VALID')

                dispatch(setSigninState(stSIGNIN.WAIT_SIGNIN_DOCS))
            }

            break

        case stSIGNIN.WAIT_SIGNIN_DOCS:
            if (stateEntry) {
                console.log('Entered WAIT_SIGNIN_DOCS')
            }

            if (selectNoSiteSigninDocsLoading(state)) {
                // All required documents have either finished loading or failed to load

                const allSigninDocsLoaded = selectAllSiteSigninDocsLoaded(state)

                if (!allSigninDocsLoaded) {
                    // Not all required documents loaded successfully
                    // (will continue regardless - we still want to allow user to sign in)
                    log.warn('One or more signin documents failed to load')
                }

                const site = selectSite(state, { siteId: signinSiteId })
                if (!site?.visitorSignin && !site?.driverSignin) {

                    log.debug('Signin sequence: multiple signin options NOT found')

                    // If site doesn't have multiple signin options configured, default to 'Worker' only
                    dispatch(setSigninType('Worker'))
                    state = getState()
                }

                const signinType = state.pgSignin.signinType
                // Find the user's last signin at this site, of the type of signin they are attempting                
                const lastSignin = selectLatestSignin(state, { siteId: signinSiteId, userId: user?.id, signinType:signinType })
                const daysSinceSignin = lastSignin ? differenceInDays(new Date(), parseJSON(lastSignin.signinTime)) : 1000000

                const signinSite = selectSite(state, { siteId: signinSiteId })
                const inductionDays = signinSite?.inductionDays || 0

                // Find the user's last induction for this site, for the type of signin they are attempting                
                const lastInduction = selectLatestInduction(state, { siteId: signinSiteId, userId: user?.id, inductionType: signinType })
                // Determine whether an induction is due
                const inductionDue = !lastInduction || isAfter(new Date(), addDays(parseJSON(lastInduction.inductionDate), inductionDays))

                const siteSigninDocs = selectSigninDocs(state, { siteId: signinSiteId }).filter(doc => {
                    const showFrequency = doc[`showFrequency${signinType}`]

                    return selectDocInfoLoaded(state, doc.docInfo) && (
                        showFrequency === 'signin' ||
                        (showFrequency === 'daily' && daysSinceSignin > 0) ||
                        (showFrequency === 'induction' && inductionDue)
                    )
                })
                dispatch(setDocList(siteSigninDocs))

                // If an induction is due, and at least one document found with frequency 'induction', consider that this is an induction
                const isInduction = (allSigninDocsLoaded &&
                    !!siteSigninDocs.filter(doc => doc[`showFrequency${signinType}`] === 'induction').length && inductionDue)
                dispatch(setIsInduction(isInduction))

                if (siteSigninDocs.length) {
                    // Document(s) to view
                    // => go to 'present docs' display
                    dispatch(setSelectedDocIndex(0))
                    dispatch(setSelectedDocTitle(siteSigninDocs[0].title))
                    dispatch(setSigninState(stSIGNIN.PRESENT_DOCS));
                } else {
                    // Nothing to view
                    // => go to 'complete'
                    dispatch(setSigninState(stSIGNIN.RECORD_SIGNIN))
                }
            }
            break;

        case stSIGNIN.PRESENT_DOCS:
            if (event === SigninEvent.PRESENT_DOCS_COMPLETE) {
/*
                // If this was a site induction, record it
                if (induction && user && user.id && signinSiteId) {
                    dispatch(saveResource({
                        company: site?.company,
                        site: signinSiteId,
                        user: user.id,
                        inductionType: state.pgSignin.signinType,
                        inductionDate: new Date()
                    }, 'inductions'))
                }
*/
                dispatch(setSigninState(stSIGNIN.RECORD_SIGNIN))
            }
            break;

        case stSIGNIN.RECORD_SIGNIN:
            if (stateEntry) {
                if (user && user.id && signinSiteId) {
                    const site = getResource<Site>(getState().resources.sites, signinSiteId)                    
                    dispatch(saveResource({
                        company: site?.company,
                        site: signinSiteId,
                        user: user.id,
                        signinType: state.pgSignin.signinType,
                        signinTime: new Date()
                    }, 'signins'))

                    if (induction) {
                        // If this was a site induction, record it
                        dispatch(saveResource({
                            company: site?.company,
                            site: signinSiteId,
                            user: user.id,
                            inductionType: state.pgSignin.signinType,
                            inductionDate: new Date()
                        }, 'inductions'))
                    }

                    dispatch(setViewSite(signinSiteId))
                } else {
                    // Error - somehow got here without a valid user / site?
                    dispatch(setSigninCode(''))
                    dispatch(setSigninState(stSIGNIN.INITIALISE))
                }
            }

            isSignedIn = selectIsSignedIn(state)
            if (isSignedIn) {
                dispatch(setRedirectPath('/home'))
                dispatch(setSigninState(stSIGNIN.COMPLETE));
            }

            break

        case stSIGNIN.COMPLETE:

            isSignedIn = selectIsSignedIn(state)

            if (!isSignedIn) {
                dispatch(setSigninState(stSIGNIN.INITIALISE))
            }

    }
}


/**
 * Signin Sequence 'Reactor' - dispatches thunk whenever a state change that could affect sequence occurs
 * 
 * Will run on changes in relevant state slices
 * 
 * @returns thunk to be dispached if an update is due
 */

// TODO: consider putting 10 second pulse in here just in case?

export const signinSequenceReactor = createReactor([
    (state: RootState) => state.temp.startupInProgress,
    (state: RootState) => state.temp.currentPath,
    (state: RootState) => state.app.user,
    (state: RootState) => state.pgSignin,
    (state: RootState) => state.resourceMeta,
    (state: RootState) => state.docMeta],

    (startupInProgress, currentPath, user, pgSignin, resourceMeta, docMeta) => {

        if (!startupInProgress && currentPath === '/signin') {
            return updateSequence()
        }
    }
)