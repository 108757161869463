import { AppDispatch, RootState } from '../../redux/store'

import { getRiskBand, getRiskScore } from '../../helpers/riskScore'
import { selectHazardEdit, selectTaEdit, selectStepEdit } from '../../redux/selectors/tas'

import {
    addAvailableCustomHazard,
    setSelectedHazardCategories,
    setTaHazardNeedsReview,
    setTaSelectedHazard,
    setTaSelectedStep,
    setTaState,
    setTaStepEditState
} from '../../redux/slices/pgTaskAnalysis'

import { setAlert } from '../../utils/alert'
import { stTASKANALYSIS } from "./taskAnalysisTypes";
import { selectRootCategoriesFromHazards } from '../../redux/selectors/taHazardSelection'
import { getRiskScoringComplete, getStepRiskScoringComplete } from './helpers/getRiskScoringComplete'
import { getControlsConfigured } from './helpers/getControlsConfigured'
import { TaStepState } from '../../datastore/models/Ta'

export enum TaEvent {
    DRAFT_REGISTER_SELECTED,
    ACTIVE_REGISTER_SELECTED,
    GENERAL_DETAILS_SELECTED,
    NEXT_SELECTED,
    BACK_SELECTED,
    EDIT_STEP_SELECTED,
    EDIT_HAZARD_SELECTED,
    REVIEW_SELECTED,
    SIGNOFF_SELECTED,
    EDIT_SELECTED,
    START_SELECTED
}

export const updateSequence = (event?: TaEvent) => (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState()

    const { stTaskAnalysis, selectedHazardCategories } = state.pgTaskAnalysis

    const hazardEdit = selectHazardEdit(state)
    let stepEdit = selectStepEdit(state)
    const taEdit = selectTaEdit(state)

    // Can be sent to 'Start', 'Details' or 'Review' from any state, e.g. by toolbox
    if (event === TaEvent.START_SELECTED) {
        dispatch(setTaState(stTASKANALYSIS.START))
        return
    }

    if (event === TaEvent.GENERAL_DETAILS_SELECTED) {
        dispatch(setTaState(stTASKANALYSIS.GENERAL_DETAILS))
        return
    }

    if (event === TaEvent.REVIEW_SELECTED) {
        dispatch(setTaState(stTASKANALYSIS.REVIEW))
        return
    }

    switch (stTaskAnalysis) {
        case stTASKANALYSIS.START:
            if (event === TaEvent.NEXT_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.GENERAL_DETAILS))
            }
            if (event === TaEvent.DRAFT_REGISTER_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.DRAFT_REGISTER))
            }
            if (event === TaEvent.ACTIVE_REGISTER_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.ACTIVE_REGISTER))
            }
            break

        case stTASKANALYSIS.DRAFT_REGISTER:
            if (event === TaEvent.NEXT_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.GENERAL_DETAILS))
            }
            break

        case stTASKANALYSIS.GENERAL_DETAILS:
            if (event === TaEvent.NEXT_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.STEP_LIST_CREATE))
            }
            break

        case stTASKANALYSIS.STEP_LIST_CREATE:
            if (event === TaEvent.NEXT_SELECTED) {
                if (taEdit.userData.steps.length === 0) {
                    dispatch(setAlert(
                        'Task needs at least one step',
                        'Add steps to describe the different stages of the task. Most tasks should have around 5-6 steps'
                    ))
                } else {
                    const stepTextValid = taEdit.userData.steps.reduce((acc: boolean, testStep) => {
                        return acc && testStep.text.length > 0
                    }, true)
                    if (!stepTextValid) {
                        dispatch(setAlert(
                            'Step name missing',
                            `All steps need to have a name. If there's a step you don't need, you can delete it by swiping left`
                        ))
                    } else {
                        dispatch(setTaState(stTASKANALYSIS.STEP_LIST_HAZARDS))
                    }
                }
            }
            if (event === TaEvent.BACK_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.GENERAL_DETAILS))
            }
            break

        case stTASKANALYSIS.STEP_LIST_HAZARDS:
            let doStepEdit = false

            if (event === TaEvent.NEXT_SELECTED) {

                // Find the first step for which risk scoring / mitigation hasn't been completed
                for (let i = 0; i < taEdit.userData.steps.length; i++) {
                    if (!getStepRiskScoringComplete(taEdit.userData.steps[i])) {

                        doStepEdit = true
                        dispatch(setTaSelectedStep(i))
                        break
                    }
                }

                // If all steps are scored / marked as complete, move on to PPE page, UNLESS no controls found in task
                // (we will allow some steps to have no hazards, but the task as a whole must contain at least some hazards / controls)
                if (!doStepEdit) {
                    if (getControlsConfigured(taEdit)) {
                        dispatch(setTaState(stTASKANALYSIS.SELECT_PPE))
                    } else {
                        dispatch(setAlert(
                            'Task needs controls',
                            'No control measures have been selected. If this task involves any potential hazards then control measures need to be in place'
                        ))
                    }
                }
            }

            if (event === TaEvent.BACK_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.STEP_LIST_CREATE))
            }

            if (event === TaEvent.EDIT_STEP_SELECTED) {
                // User has explicitly chosen to review / edit a step by clicking on it
                doStepEdit = true
            }

            if (doStepEdit) {
                stepEdit = selectStepEdit(getState())
                // Get an array of root hazard categories for hazards currently selected in step
                dispatch(setSelectedHazardCategories(selectRootCategoriesFromHazards(stepEdit?.hazards || [])(state)))

                // Add any existing custom hazards in the step to the 'AvailableCustomHazards' list
                stepEdit?.hazards.forEach(hazard => {
                    if (hazard.customHazardType) {
                        dispatch(addAvailableCustomHazard(hazard.customHazardType))
                    }
                })
                dispatch(setTaState(stTASKANALYSIS.SELECT_HAZARD_CATEGORIES))
            }
            break

        case stTASKANALYSIS.SELECT_HAZARD_CATEGORIES:
            if (event === TaEvent.NEXT_SELECTED) {
                // If no categories or custom controls have been selected, user is saying there are no hazards in this step - confirm
                if (selectedHazardCategories.length === 0 &&
                    stepEdit?.hazards.filter(hazard => !hazard.hazardTypeId).length === 0) {
                    dispatch(setAlert(
                        'No hazards selected',
                        'Are you sure there are no hazards present in this job step?',
                        [{
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => true
                        },
                        {
                            text: "I'm sure",
                            handler: () => {
                                dispatch(setTaStepEditState(TaStepState.COMPLETE))
                                dispatch(setTaState(stTASKANALYSIS.STEP_LIST_HAZARDS))
                                return true
                            }
                        }]
                    ))
                    // If no categories selected but one or more custom hazards are selected...
                    // -> possible to skip 'Select Hazards' screen as it will just show the same thing
                } else if (selectedHazardCategories.length === 0 &&
                    stepEdit?.hazards.filter(hazard => !hazard.hazardTypeId).length) {

                    // See if there's a hazard that's either incomplete / unscored or flagged for review
                    let allHazardsScored = true

                    // Get custom hazards sorted alphabetically (so that they will be reviewed in the same order they appear on screen)
                    const sortedHazards = stepEdit.hazards.map((hazard, index) => ({ hazardText: hazard.customHazardType, index }))
                        .sort((a, b) => a.hazardText.localeCompare(b.hazardText))

                    for (const hazard of sortedHazards) {
                        const index = hazard.index
                        if (!getRiskScoringComplete(stepEdit.hazards[index]) || stepEdit.hazards[index].needsReview) {
                            // ... there is, go directly to risk scoring        
                            dispatch(setTaSelectedHazard(index))
                            dispatch(setTaState(stTASKANALYSIS.INITIAL_RISK_SCORE))
                            allHazardsScored = false
                            break
                        }
                    }
                    if (allHazardsScored) {
                        // No unscored hazards - return to step list
                        dispatch(setTaState(stTASKANALYSIS.STEP_LIST_HAZARDS))
                    }

                } else {
                    // Default case - show individual hazards in selected categories
                    dispatch(setTaState(stTASKANALYSIS.SELECT_HAZARDS))
                }
            }
            if (event === TaEvent.BACK_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.STEP_LIST_HAZARDS))
            }

            // User can get directly from 'select hazard category' to 'score hazard' if reviewing a custom hazard
            if (event === TaEvent.EDIT_HAZARD_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.INITIAL_RISK_SCORE))
            }

            break

        case stTASKANALYSIS.SELECT_HAZARDS:

            if (event === TaEvent.EDIT_HAZARD_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.INITIAL_RISK_SCORE))
            }

            if (event === TaEvent.NEXT_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.STEP_LIST_HAZARDS))
            }

            if (event === TaEvent.BACK_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.SELECT_HAZARD_CATEGORIES))
            }
            break

        case stTASKANALYSIS.INITIAL_RISK_SCORE:
            if (event === TaEvent.NEXT_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.SELECT_CONTROLS))
            }
            if (event === TaEvent.BACK_SELECTED) {
                // If no categories selected but one or more custom hazards are selected, skip 'Select Hazards' screen and go 
                // to 'select hazard categories' (don't want to show both in this case as they will be the same)
                if (selectedHazardCategories.length === 0 &&
                    stepEdit?.hazards.filter(hazard => !hazard.hazardTypeId).length) {
                    dispatch(setTaState(stTASKANALYSIS.SELECT_HAZARD_CATEGORIES))
                } else {
                    dispatch(setTaState(stTASKANALYSIS.SELECT_HAZARDS))
                }
            }
            break

        case stTASKANALYSIS.SELECT_CONTROLS:
            if (event === TaEvent.NEXT_SELECTED && hazardEdit) {
                const riskBand = getRiskBand(getRiskScore(hazardEdit.irConsequence, hazardEdit.irLikelyhood))
                if (riskBand === 'Low'
                    || hazardEdit.controls.length
                    || hazardEdit.customControls.length
                ) {
                    dispatch(setTaState(stTASKANALYSIS.RESIDUAL_RISK_SCORE))
                } else {
                    dispatch(setAlert(
                        'No controls selected',
                        `The risk for this hazard is considered '${riskBand}'. Please select controls to reduce the risk to a safe level`
                    ))
                }
            }
            if (event === TaEvent.BACK_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.INITIAL_RISK_SCORE))
            }
            break

        case stTASKANALYSIS.RESIDUAL_RISK_SCORE:
            if (event === TaEvent.NEXT_SELECTED && hazardEdit) {
                const riskBand = getRiskBand(getRiskScore(hazardEdit.rrConsequence, hazardEdit.rrLikelyhood))
                if (riskBand === 'Low') {
                    dispatch(setTaState(stTASKANALYSIS.SELECT_HAZARDS))
                    dispatch(setTaHazardNeedsReview(false))
                } else {
                    dispatch(setAlert(
                        'Residual Risk Too High',
                        `The residual risk for this hazard is considered '${riskBand}'. Please select additional controls to reduce the risk`
                        , [{
                            text: "Ok",
                            handler: () => {
                                dispatch(setTaState(stTASKANALYSIS.SELECT_CONTROLS))
                                return true
                            }
                        }]))
                }
            }
            if (event === TaEvent.BACK_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.SELECT_CONTROLS))
            }
            break

        case stTASKANALYSIS.SELECT_PPE:
            if (event === TaEvent.NEXT_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.REVIEW))
            }
            if (event === TaEvent.BACK_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.STEP_LIST_HAZARDS))
            }
            break

        case stTASKANALYSIS.REVIEW:

            if (event === TaEvent.NEXT_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.SIGNOFF))
            }

            if (event === TaEvent.BACK_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.SELECT_PPE))
            }

            if (event === TaEvent.EDIT_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.GENERAL_DETAILS))
            }

            if (event === TaEvent.SIGNOFF_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.SIGNOFF))
            }
            break

        case stTASKANALYSIS.SIGNOFF:
            if (event === TaEvent.BACK_SELECTED) {
                dispatch(setTaState(stTASKANALYSIS.REVIEW))
            }
            break
    }
}