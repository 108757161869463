import { useEffect } from "react"
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { setRedirectPath } from "../../redux/slices/temp"

/**
 * This page shouldn't be displayed - if it is, redirect back to home page
 * (will happen if user hits browser back button after signing in)
 */
const Complete: React.FC = () => {
    const dispatch = useAppDispatch()
    const { currentPath } = useAppSelector(state => state.temp)

    useEffect(() => {
        if (currentPath === '/signin') {
            // Need short delay, ion router doesn't seem to like two route changes too close together
            setTimeout(() => dispatch(setRedirectPath('/home')), 500)
        }
    }, [currentPath])

    return <></>
}

export default Complete;