import { ResourceMap } from '../../redux/slices/resources'

/**
 * Finds a resource by it's id. Id may be either:
 * - the cannonical database id, or
 * - a temporary 'local id' assigned when the resource was first created. When a resource is successfully written to the database
 *   the local copy will be updated to reflect the 'real' id, but with the original local id saved as [resource].localId
 * 
 * @param resourcesOfType - state slice containing required resource, e.g. state.resources.tas
 * @param id  - id to search for
 */
 export const getResourceById = (resourcesOfType:ResourceMap, id: number) => {

    if (!resourcesOfType || id === undefined) {
        return null
    }    

    if (resourcesOfType[id]) {
        // Found resource having the specified id as it's key
        return resourcesOfType[id]
    } else {
        for (const key in resourcesOfType) {
            if (+resourcesOfType[key].localId === +id) {
                // Found resource having a 'localId' equal to the specified id
                return resourcesOfType[key]
            }
        }
    }
    return null
}