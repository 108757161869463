import { createSelector } from "@reduxjs/toolkit"
import { uniq } from "lodash"
import { getResources } from "../../datastore"
import {
  Incident,
  ProjectSite,
  Site,
  SiteObservation,
  Ta,
  Toolbox,
  User
} from "../../datastore/models"
import { UserAttribute, UserPermissions } from "../slices/app"
import { TaEdit } from "../slices/pgTaskAnalysis"
import { ResourceMap } from "../slices/resources"
import { RootState } from "../store"
import { selectParamsMemo } from "./helpers/selectParamsMemo"
//import { selectResourcesMemo } from "./helpers/selectResourcesMemo"
import { FlattenedProjectSite } from "./projectSites"

/**
 * Get user abilities
 */

export const includesSomeClaimsAboutCompany = (
  permissions: UserPermissions,
  companyId: number | undefined,
  claims: UserAttribute[]
) =>
  permissions.companyAttributes.some(
    (attribute) =>
      claims.includes(attribute.attribute) && attribute.company === companyId
  )

export const includesSomeClaimsAboutProject = (
  permissions: UserPermissions,
  projectId: number | undefined,
  claims: UserAttribute[]
) =>
  permissions.projectAttributes.some(
    (attribute) =>
      claims.includes(attribute.attribute) && attribute.project === projectId
  )

export const includesSomeClaimsAboutSite = (
  permissions: UserPermissions,
  siteId: number | undefined,
  claims: UserAttribute[]
) =>
  permissions.siteAttributes.some(
    (attribute) =>
      claims.includes(attribute.attribute) && attribute.site === siteId
  )

// Select permissions received from getUser call
const selectPermissions = (state: RootState) => state.app.permissions

const selectUser = (state: RootState) => state.app.user

// select all known projectSites records (for confirming that projects refered to by project attributes have been configured for safety)
// Note - ensure that projectSite records have actually been requested, e.g. on entry to a 'start' page
const selectProjectSites = (state: RootState) => state.resources.projectSites

type AbilitySelectParamsMemo = (
  state: RootState,
  params: { company: number; project?: number; site?: number }
) => { company: number; project?: number; site?: number }
const selectParams = selectParamsMemo() as unknown as AbilitySelectParamsMemo

// General purpose 'createSelector' parameter selectors
const selectParamsSites = (state: RootState, sites: Site[]) => ({
  sites: sites
})
const selectParamsProjectSites = (
  state: RootState,
  projectSites: FlattenedProjectSite[]
) => ({ projectSites: projectSites })

const signinAttributes: UserAttribute[] = [
  "canSigninHealthAndSafety",
  "canViewHealthAndSafety",
  "canCreateHealthAndSafety",
  "canManageHealthAndSafety",
  "canManageCompany"
]
const viewAttributes: UserAttribute[] = [
  "canViewHealthAndSafety",
  "canCreateHealthAndSafety",
  "canManageHealthAndSafety",
  "canManageCompany"
]
const createAttributes: UserAttribute[] = [
  "canCreateHealthAndSafety",
  "canManageHealthAndSafety",
  "canManageCompany"
]

/**
 * Test whether user has any create permission anywhere, i.e. create permission for either a company or project that's configured for safety
 *
 * @param permissions
 * @param projectSites
 * @returns
 */
const canCreateAny = (
  permissions: UserPermissions,
  user: User | null,
  projectSites: ResourceMap
) => {
  const flattenedProjectSites = getResources<ProjectSite>(
    { projectSites },
    "projectSites"
  )

  const configuredCompanies = uniq(
    flattenedProjectSites.map((projectSite) => projectSite.company)
  )
  const configuredProjects = uniq(
    flattenedProjectSites.map((projectSite) => projectSite.project)
  )

  return (
    user?.isAdmin ||
    permissions.companyAttributes.some(
      (attribute) =>
        createAttributes.includes(attribute.attribute) &&
        configuredCompanies.includes(attribute.company)
    ) ||
    permissions.projectAttributes.some(
      (attribute) =>
        createAttributes.includes(attribute.attribute) &&
        configuredProjects.includes(attribute.project)
    )
  )
}

/**
 * Test whether user has any view permission anywhere, i.e. view or higher permission for either a company or project that's configured for safety
 * -> changed to just 'user has a view permission attribute' without considering whether it's for a configured project
 * (otherwise home screen takes too long to initialise after login because we need to load all the projects first))
 *
 * @param permissions
 * @param projectSites
 * @returns
 */
const canViewAny = (
  permissions: UserPermissions,
  user: User | null,
  projectSites: ResourceMap
) => {
  /*
  const flattenedProjectSites = getResources<ProjectSite>(
    { projectSites },
    "projectSites"
  )

  const configuredCompanies = uniq(
    flattenedProjectSites.map((projectSite) => projectSite.company)
  )
  const configuredProjects = uniq(
    flattenedProjectSites.map((projectSite) => projectSite.project)
  )
*/
  return (
    user?.isAdmin ||
    permissions.companyAttributes.some(attribute => createAttributes.includes(attribute.attribute)) ||
    permissions.projectAttributes.some(attribute => createAttributes.includes(attribute.attribute))
    /*
    permissions.companyAttributes.some(
      (attribute) =>
        viewAttributes.includes(attribute.attribute) &&
        configuredCompanies.includes(attribute.company)
    ) ||
    permissions.projectAttributes.some(
      (attribute) =>
        viewAttributes.includes(attribute.attribute) &&
        configuredProjects.includes(attribute.project)
    )
    */
  )
}

const selectParamsSite = (state: RootState, site: Site) => ({ site: site })
export const selectCanSuperviseSite = createSelector(
  [selectPermissions, selectUser, selectParamsSite],
  (permissions, user, params) => {
    return (
      user?.isAdmin ||
      includesSomeClaimsAboutCompany(permissions, params.site.company, [
        "canSuperviseHealthAndSafety"
      ]) ||
      includesSomeClaimsAboutSite(permissions, params.site.id, [
        "canSuperviseHealthAndSafety"
      ])
    )
  }
)

export const selectCanSigninSite = createSelector(
  [selectPermissions, selectUser, selectParamsSite],
  (permissions, user, params) => {
    return (
      user?.isAdmin ||
      includesSomeClaimsAboutCompany(
        permissions,
        params.site.company,
        signinAttributes
      ) ||
      includesSomeClaimsAboutSite(permissions, params.site.id, signinAttributes)
    )
  }
)

/**
 * Returns boolean indicating user has 'create SWMS' ability for at least one company or project
 */
export const selectCanViewAny = createSelector(
  [selectPermissions, selectUser, selectProjectSites],
  (permissions, user, projectSites) => {
    return canViewAny(permissions, user, projectSites)
  }
)

// ********************** SWMS Abilities **********************

const selectParamsTa = (state: RootState, ta: Ta | TaEdit) => ({ ta: ta })

export const selectCanViewTa = createSelector(
  [selectPermissions, selectUser, selectParamsTa],
  (permissions, user, params) => {
    return (
      user?.isAdmin ||
      includesSomeClaimsAboutCompany(
        permissions,
        params.ta.company,
        viewAttributes
      ) ||
      includesSomeClaimsAboutProject(
        permissions,
        params.ta.project,
        viewAttributes
      )
    )
  }
)

const canCreateTa = (
  permissions: UserPermissions,
  user: User | null,
  companyId: number,
  projectId?: number
) => {
  return (
    user?.isAdmin ||
    includesSomeClaimsAboutCompany(permissions, companyId, createAttributes) ||
    includesSomeClaimsAboutProject(permissions, projectId, createAttributes)
  )
}
export const selectCanCreateTa = createSelector(
  [selectPermissions, selectUser, selectParams],
  (permissions, user, params) => {
    return canCreateTa(permissions, user, params.company, params.project)
  }
)

/**
 * Returns array of projectSite records for which user has 'create SWMS' ability, filtered from a supplied 'superset' array
 * (will typically be all the projectSites the user has view access to in the calling context)
 */
export const selectCanCreateTaProjectSites = createSelector(
  [selectPermissions, selectUser, selectParamsProjectSites],
  (permissions, user, params) => {
    return params.projectSites.filter((projectSite) =>
      canCreateTa(permissions, user, projectSite.company, projectSite.projectId)
    )
  }
)

/**
 * Returns boolean indicating user has 'create SWMS' ability for at least one company or project
 */
export const selectCanCreateAnyTa = createSelector(
  [selectPermissions, selectUser, selectProjectSites],
  (permissions, user, projectSites) => {
    return canCreateAny(permissions, user, projectSites)
  }
)

export const selectCanEditTa = createSelector(
  [selectPermissions, selectUser, selectParamsTa],
  (permissions, user, params) => {
    return (
      user?.isAdmin ||
      (user?.id === params.ta.creator &&
        (includesSomeClaimsAboutCompany(permissions, params.ta.company, [
          "canCreateHealthAndSafety"
        ]) ||
          includesSomeClaimsAboutProject(permissions, params.ta.project, [
            "canCreateHealthAndSafety"
          ]))) ||
      includesSomeClaimsAboutCompany(permissions, params.ta.company, [
        "canSuperviseHealthAndSafety",
        "canManageHealthAndSafety",
        "canManageCompany"
      ]) ||
      includesSomeClaimsAboutProject(permissions, params.ta.project, [
        "canSuperviseHealthAndSafety",
        "canManageHealthAndSafety",
        "canManageCompany"
      ])
    )
  }
)

// ********************** Toolbox Abilities **********************

const selectParamsToolbox = (state: RootState, toolbox: Toolbox) => ({
  toolbox: toolbox
})

export const selectCanViewToolbox = createSelector(
  [selectPermissions, selectUser, selectParamsToolbox],
  (permissions, user, params) => {
    return (
      user?.isAdmin ||
      includesSomeClaimsAboutCompany(
        permissions,
        params.toolbox.company,
        viewAttributes
      ) ||
      includesSomeClaimsAboutProject(
        permissions,
        params.toolbox.project,
        viewAttributes
      )
    )
  }
)

const canCreateToolbox = (
  permissions: UserPermissions,
  user: User | null,
  companyId: number,
  projectId?: number
) => {
  return (
    user?.isAdmin ||
    includesSomeClaimsAboutCompany(permissions, companyId, createAttributes) ||
    includesSomeClaimsAboutProject(permissions, projectId, createAttributes)
  )
}

export const selectCanCreateToolbox = createSelector(
  [selectPermissions, selectUser, selectParams],
  (permissions, user, params) => {
    return canCreateToolbox(permissions, user, params.company, params.project)
  }
)

/**
 * Returns array of projectSite records for which user has 'create Toolbox' ability, filtered from a supplied 'superset' array
 * (will typically be all the projectSites the user has view access to in the calling context)
 */
export const selectCanCreateToolboxProjectSites = createSelector(
  [selectPermissions, selectUser, selectParamsProjectSites],
  (permissions, user, params) => {
    return params.projectSites.filter((projectSite) =>
      canCreateToolbox(
        permissions,
        user,
        projectSite.company,
        projectSite.projectId
      )
    )
  }
)

/**
 * Returns boolean indicating user has 'create toolbox' ability for at least one project
 * used to check against available projects but could spuriously deny permission if still loading
 *  - just check user has some create permission
 */
/*
const selectCanCreateAnyToolboxResources = selectResourcesMemo([
  "projectSites",
  "sites"
])
*/
export const selectCanCreateAnyToolbox = createSelector(
  [
    selectPermissions,
    selectUser,
//    selectCanCreateAnyToolboxResources,
  ],
  (permissions, user /*, resources*/) => {


    return (
      user?.isAdmin ||
      permissions.companyAttributes.some(attribute => createAttributes.includes(attribute.attribute)) ||
      permissions.projectAttributes.some(attribute => createAttributes.includes(attribute.attribute))
      )
/*
    // get projectSite records applicable to site user is signed in to
    const siteProjectSites = getResources<ProjectSite>(
      resources,
      "projectSites"
    )

    const canCreateAnyToolbox = siteProjectSites.some((projectSite) =>
      canCreateToolbox(
        permissions,
        user,
        projectSite.company,
        projectSite.project
      )
    )
    return canCreateAnyToolbox
    */
  }
)

// ******************** Site Observation abilities *********************

const selectParamsObservation = (
  state: RootState,
  observation: SiteObservation
) => ({ observation: observation })
export const selectCanViewObservation = createSelector(
  [selectPermissions, selectUser, selectParamsObservation],
  (permissions, user, params) => {
    return (
      user?.isAdmin ||
      includesSomeClaimsAboutCompany(
        permissions,
        params.observation.company,
        viewAttributes
      ) ||
      includesSomeClaimsAboutSite(
        permissions,
        params.observation.site,
        viewAttributes
      )
    )
  }
)

const canCreateObservation = (
  permissions: UserPermissions,
  user: User | null,
  companyId: number,
  siteId?: number
) => {
  return (
    user?.isAdmin ||
    includesSomeClaimsAboutCompany(permissions, companyId, createAttributes) ||
    includesSomeClaimsAboutSite(permissions, siteId, createAttributes)
  )
}
export const selectCanCreateObservation = createSelector(
  [selectPermissions, selectUser, selectParams],
  (permissions, user, params) => {
    return canCreateObservation(permissions, user, params.company, params.site)
  }
)

/**
 * Returns array of site records for which user has 'create Site Observation' ability, filtered from a supplied 'superset' array
 * (will typically be all the sites the user has view access to in the calling context)
 */
export const selectCanCreateObservationSites = createSelector(
  [selectPermissions, selectUser, selectParamsSites],
  (permissions, user, params) => {
    return params.sites.filter((site) =>
      canCreateObservation(permissions, user, site.company, site.id)
    )
  }
)

/**
 * user has 'create Site Observation' ability for at least one company or project
 */
export const selectCanCreateAnyObservation = createSelector(
  [selectPermissions, selectUser, selectProjectSites],
  (permissions, user, projectSites) => {
    return canCreateAny(permissions, user, projectSites)
  }
)

export const selectCanEditObservationAsObserver = createSelector(
  [selectPermissions, selectUser, selectParamsObservation],
  (permissions, user, params) => {
    return (
      user?.id === params.observation.observer &&
      (includesSomeClaimsAboutCompany(
        permissions,
        params.observation.company,
        createAttributes
      ) ||
        includesSomeClaimsAboutSite(
          permissions,
          params.observation.site,
          createAttributes
        ))
    )
  }
)

export const selectCanEditObservationAsSupervisor = createSelector(
  [selectPermissions, selectUser, selectParamsObservation],
  (permissions, user, params) => {
    return (
      user?.isAdmin ||
      includesSomeClaimsAboutCompany(permissions, params.observation.company, [
        "canSuperviseHealthAndSafety",
        "canManageHealthAndSafety",
        "canManageCompany"
      ]) ||
      includesSomeClaimsAboutSite(permissions, params.observation.site, [
        "canSuperviseHealthAndSafety",
        "canManageHealthAndSafety",
        "canManageCompany"
      ])
    )
  }
)

// ******************** Incident Report abilities *********************

const selectParamsIncident = (state: RootState, incident: Incident) => ({
  incident: incident
})
export const selectCanViewIncident = createSelector(
  [selectPermissions, selectUser, selectParamsIncident],
  (permissions, user, params) => {
    return (
      user?.isAdmin ||
      includesSomeClaimsAboutCompany(
        permissions,
        params.incident.company,
        viewAttributes
      ) ||
      includesSomeClaimsAboutSite(
        permissions,
        params.incident.site,
        viewAttributes
      )
    )
  }
)

const canCreateIncident = (
  permissions: UserPermissions,
  user: User | null,
  companyId: number,
  siteId?: number
) => {
  return (
    user?.isAdmin ||
    includesSomeClaimsAboutCompany(permissions, companyId, createAttributes) ||
    includesSomeClaimsAboutSite(permissions, siteId, createAttributes)
  )
}
export const selectCanCreateIncident = createSelector(
  [selectPermissions, selectUser, selectParams],
  (permissions, user, params) => {
    return canCreateIncident(permissions, user, params.company, params.site)
  }
)

/**
 * Returns array of site records for which user has 'create Incident Report' ability, filtered from a supplied 'superset' array
 * (will typically be all the sites the user has view access to in the calling context)
 */
export const selectCanCreateIncidentSites = createSelector(
  [selectPermissions, selectUser, selectParamsSites],
  (permissions, user, params) => {
    return params.sites.filter((site) =>
      canCreateIncident(permissions, user, site.company, site.id)
    )
  }
)

/**
 * user has 'create Incident Report' ability for at least one company or project
 */
export const selectCanCreateAnyIncident = createSelector(
  [selectPermissions, selectUser, selectProjectSites],
  (permissions, user, projectSites) => {
    return canCreateAny(permissions, user, projectSites)
  }
)

export const selectCanEditIncidentAsReporter = createSelector(
  [selectPermissions, selectUser, selectParamsIncident],
  (permissions, user, params) => {
    return (
      user?.id === params.incident.reporter &&
      (includesSomeClaimsAboutCompany(
        permissions,
        params.incident.company,
        createAttributes
      ) ||
        includesSomeClaimsAboutSite(
          permissions,
          params.incident.site,
          createAttributes
        ))
    )
  }
)

export const selectCanEditIncidentAsSupervisor = createSelector(
  [selectPermissions, selectUser, selectParamsIncident],
  (permissions, user, params) => {
    return (
      user?.isAdmin ||
      includesSomeClaimsAboutCompany(permissions, params.incident.company, [
        "canSuperviseHealthAndSafety",
        "canManageHealthAndSafety",
        "canManageCompany"
      ]) ||
      includesSomeClaimsAboutSite(permissions, params.incident.site, [
        "canSuperviseHealthAndSafety",
        "canManageHealthAndSafety",
        "canManageCompany"
      ])
    )
  }
)
