import { updateResourcesReactor } from '../datastore/updateResources'
import { updateWriteQueueReactor } from '../datastore/saveResource'

import { signinSequenceReactor } from '../features/signin/signinSequence'
import { siteResourceUpdateReactor } from '../features/resources/siteResourceUpdate'
import { globalResourceUpdateReactor } from '../features/resources/globalResourceUpdate'

const reactors = [    
    signinSequenceReactor,
    updateResourcesReactor,    
    updateWriteQueueReactor,
    siteResourceUpdateReactor,
    globalResourceUpdateReactor
]

export default reactors 