import { RootState, AppDispatch } from "../../redux/store"
import { ResourceEvent, ResourceUpdateStatus } from "../../datastore/types"
import { requestResources } from "../../datastore"
import { requestDocs } from "../../docstore/requestDocs"
import { selectSigninDocs } from "../../redux/selectors/signinDocs"
import { differenceInSeconds, subDays } from "date-fns"
import { createReactor } from "../../redux/selectors/helpers/createReactor"
import { selectSignedinSiteId } from "../../redux/selectors/signins"
import log from "loglevel"
import { ResourceType } from "../../redux/slices/resources"
import { initialiseResourceStatus } from "../../redux/slices/resourceMeta"
import { setDynamicFilters } from "./helpers/setDynamicFilters"
import { filterIsSubset } from "../../datastore/helpers/filterIsSubset"
import { selectResourceUpdateStatusValid } from "../../redux/selectors/resourceMeta"

const siteResourceUpdateConfig: {
  [key in Partial<ResourceType>]?: ResourceUpdateStatus
} = {
  signinDocs: {
    autoRequestFilter: { eqFilter: { site: "%siteId%" } }
  },
  siteHazards: {
    autoRequestFilter: { eqFilter: { site: "%siteId%" } }
  },
  siteContacts: {
    autoRequestFilter: { eqFilter: { site: "%siteId%" } },
    minRefreshSeconds: 60
  },
  /*
  Caching won't work for siteUsers at present due to need to use 'include' to get user records
  
  siteUsers: {
    autoRequestFilter: { eqFilter: { site: "%siteId%" } },
    minRefreshSeconds: 60
  },
  */
  sitePpe: {
    autoRequestFilter: { eqFilter: { site: "%siteId%" } },
    minRefreshSeconds: 60
  },

  toolboxes: {
    autoRequestFilter: {
      eqFilter: { site: "%siteId%" },
      periodFilter: { attr: "openDateTime", periodStart: "%tbOldest%" }
    },
    autoRequestRelated: [
      "tbAttendees",
      "tbSiteHazards",
      "tbObservations",
      "tbIncidents",
      "tbTasks",
      "tbTas",
      "tbOther"
    ]
  },
  tbAttendees: {
    autoRefreshSeconds: 0,
    expireAfterDays: 7
  },
  tbSiteHazards: {
    autoRefreshSeconds: 0,
    expireAfterDays: 7
  },
  tbObservations: {
    autoRefreshSeconds: 0,
    expireAfterDays: 7
  },
  tbIncidents: {
    autoRefreshSeconds: 0,
    expireAfterDays: 7
  },
  tbTasks: {
    autoRefreshSeconds: 0,
    expireAfterDays: 7
  },
  tbTas: {
    autoRefreshSeconds: 0,
    expireAfterDays: 7
  },
  tbOther: {
    autoRefreshSeconds: 0,
    expireAfterDays: 7
  },

  tas: {
    autoRequestFilter: {
      eqFilter: { site: "%siteId%" },
      periodFilter: { attr: "startDate", periodStart: "%taOldest%" }
    },
    autoRequestRelated: ["taWorkers"],
    expireAfterDays: 7
  },
  taWorkers: {
    autoRefreshSeconds: 0,
    expireAfterDays: 7
  },

  siteObservations: {
    autoRequestFilter: {
      eqFilter: { site: "%siteId%" },
      periodFilter: {
        attr: "observationDate",
        periodStart: "%observationOldest%"
      }
    },
    autoRequestRelated: ["siteObservationActions"],
    expireAfterDays: 7
  },
  siteObservationActions: {
    autoRefreshSeconds: 0,
    expireAfterDays: 7
  },

  incidents: {
    autoRequestFilter: {
      eqFilter: { site: "%siteId%" },
      periodFilter: { attr: "incidentDate", periodStart: "%incidentOldest%" }
    },
    autoRequestRelated: ["incidentActions", "incidentWorkers"],
    expireAfterDays: 7
  },
  incidentActions: {
    autoRefreshSeconds: 0,
    expireAfterDays: 7
  },
  incidentWorkers: {
    autoRefreshSeconds: 0,
    expireAfterDays: 7
  },

  tasks: {
    // TODO: Determine appropriate filter for tasks
    // Probably will get all 'active' tasks for projects on this site?
    // -> autoRequestRelated from projectSites?
  }
}

const DEFAULT_AUTO_REFRESH_SECONDS = 3600
const DEFAULT_MIN_REFRESH_SECONDS = 5

export const initialiseSiteResourceUpdate =
  (siteId?: number) => (dispatch: AppDispatch, getState: () => RootState) => {
    log.debug("Initialising site resource update, new site:", siteId)

    const filterSubstitutions = {
      siteId,
      taOldest: subDays(new Date(), 8).toISOString(),
      tbOldest: subDays(new Date(), 8).toISOString(),
      observationOldest: subDays(new Date(), 15).toISOString(),
      incidentOldest: subDays(new Date(), 15).toISOString()
    }

    const siteUpdateResources = Object.keys(
      siteResourceUpdateConfig
    ) as ResourceType[]

    siteUpdateResources.forEach((resourceType) => {
      const config = siteResourceUpdateConfig[resourceType]
      const newAutoRequestFilter = config!.autoRequestFilter
        ? setDynamicFilters(config!.autoRequestFilter, filterSubstitutions)
        : undefined
      const existingStatus =
        getState().resourceMeta.resourceUpdateStatus[resourceType]

      const newStatus: ResourceUpdateStatus = {
        autoRefreshSeconds:
          config!.autoRefreshSeconds ?? DEFAULT_AUTO_REFRESH_SECONDS,
        minRefreshSeconds:
          config!.minRefreshSeconds ?? DEFAULT_MIN_REFRESH_SECONDS,
        autoRequestFilter: newAutoRequestFilter,
        autoRequestRelated: config!.autoRequestRelated,
        changeDetectFilter: config!.changeDetectFilter
          ? setDynamicFilters(config!.changeDetectFilter, filterSubstitutions)
          : undefined,
        expireAfterDays: config!.expireAfterDays
      }

      const resourceStatusInvalid =
        existingStatus?.lastUpdated &&
        !selectResourceUpdateStatusValid(getState(), { type: resourceType })

      if (
        resourceStatusInvalid ||
        !filterIsSubset(
          existingStatus?.autoRequestFilter || {},
          newAutoRequestFilter || {}
        )
      ) {
        // If resource cache status is invalid, or filter has changed, reset valid range etc
        newStatus.validTo = undefined
        newStatus.lastUpdated = undefined
        newStatus.lastUpdateResult = ResourceEvent.NONE
      }
      if (resourceStatusInvalid) {
        log.info(
          `initialiseSiteResourceUpdate: ${resourceType} cache status missing or suspect, reset`
        )
      }

      dispatch(
        initialiseResourceStatus({
          resourceType,
          status: newStatus
        })
      )
    })
  }

/**
 * Stop auto - refresh of site resources, i.e. on signout
 *
 * Stops auto refresh by setting autoRefreshSeconds to 0; autoRequestFilter etc are left as-is so that if user
 * signs back in to the same site later the auto-refresh can pick up where it left off
 */
export const stopSiteResourceUpdate =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    log.debug("Stopping background site resource update")

    const siteUpdateResources = Object.keys(
      siteResourceUpdateConfig
    ) as ResourceType[]

    siteUpdateResources.forEach((resourceType) => {
      dispatch(
        initialiseResourceStatus({
          resourceType,
          status: {
            autoRefreshSeconds: 0
          }
        })
      )
    })
  }

/**
 *
 * Called periodically to get any updated resources or documents for the currently signed in site
 */
const updateSiteDocs =
  (siteId: number | undefined) =>
    async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
      if (!siteId) {
        log.error("updateSiteDocs called with no siteId")
        return
      }

      await requestResources("signinDocs", { eqFilter: { site: siteId } })(
        dispatch,
        getState,
        services
      )

      const docIds = selectSigninDocs(getState(), { siteId }).map(
        (doc) => doc.docInfo
      )
      dispatch(requestDocs({ inFilter: { id: docIds } }))
    }

const REACTOR_UPDATE_SECONDS = 3600
var updateTimeLast: Date

/**
 * Auto-request 'Reactor' - updates site document data in the background
 *
 * Will run every 10 seconds on change in temp.appTime
 *
 * @returns thunk to be dispached if an update is due
 */

export const siteResourceUpdateReactor = createReactor(
  [
    (state: RootState) => state.temp.appTime,
    (state: RootState) => state.app.user,
    selectSignedinSiteId
  ],
  (appTime, user, signedInSiteId) => {
    if (
      user &&
      signedInSiteId &&
      differenceInSeconds(new Date(), updateTimeLast || new Date()) >=
      REACTOR_UPDATE_SECONDS
    ) {
      updateTimeLast = new Date()
      return updateSiteDocs(signedInSiteId)
    }
  }
)
