import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import log from 'loglevel';

/**
 * Adapted from ionic-appauth/lib/capacitor/capacitor-secure-storage.js
 * - converted to typescript
 * - added missing error handlers (otherwise could generate unhandled error from removeItem call)
 */

export class CapacitorSecureStorage {
    async getItem(name: string) {
        if (!SecureStoragePlugin) {
            log.error('Capacitor Secure Storage Is Undefined!');
            return null
        }
        try {
            const returned = await SecureStoragePlugin.get({
                key: name,
            })
            return returned.value;
        } catch (err) {
            // getItem is called at startup to test for item which may or may not exist
            // -> don't treat as error
            log.debug(`CapacitorSecureStorage getItem(${name}):${err}`)
            return null
        }
    }
    async removeItem(name: string) {
        if (!SecureStoragePlugin) {
            log.error('Capacitor Secure Storage Is Undefined!');
            return
        }
        try {
            await SecureStoragePlugin.remove({
                key: name,
            })
        } catch (err) {
            log.warn(`CapacitorSecureStorage removeItem(${name}):${err}`)
        }
    }
    async clear() {
        if (!SecureStoragePlugin) {
            log.error('Capacitor Secure Storage Is Undefined!');
            return
        }
        try {
            await SecureStoragePlugin.clear()
        } catch (err) {
            log.warn(`CapacitorSecureStorage clear:${err}`)
        }
    }
    async setItem(name: string, value: string) {
        if (!SecureStoragePlugin) {
            log.error('Capacitor Secure Storage Is Undefined!');
            return
        }
        try {
            await SecureStoragePlugin.set({
                key: name,
                value: value,
            })
        } catch (err) {
            log.warn(`CapacitorSecureStorage setItem(${name},${value}):${err}`)
        }
    }
}
