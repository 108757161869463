import { isEmpty } from "lodash"
import { AppDispatch, RootState } from "../../redux/store"
import { getResource, getResources, requestResources, requestResourcesWithRelated } from "../../datastore"
import {
    Company,
    Contact,
    HazardType,
    Incident,
    Project,
    Site,
    SiteHazard,
    SiteObservation,
    Ta,
    Task,
    TbAttendee,
    TbIncident,
    TbObservation,
    TbOther,
    TbTa,
    TbTask,
    User
} from "../../datastore/models"
import { userContact } from "./helpers/userContact"
import { resourceEventCombined } from "../../datastore/resourceEventCombined"
import { setRequestComplete, setRequestStart } from "../../redux/slices/resourceMeta"
import { selectToolbox } from "../../redux/selectors/toolboxes"
import TbSiteHazard from "../../datastore/models/TbSiteHazard"
import { ResourceEvent } from "../../datastore/types"
import { addressFull } from "./helpers/addressFull"

export const requestToolbox = (params: { [key: string]: any }) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    const { toolboxId, requestKey } = params

    if (requestKey) {
        dispatch(setRequestStart({ requestKey }))
    }

    const { resources } = getState()

    const results = await Promise.all([
        requestResourcesWithRelated(
            'toolboxes', { eqFilter: { id: toolboxId }, include: ['creator'] }, ['companies', 'sites', 'projects', 'tbOther'])(dispatch, getState, services),
        requestResources('tbAttendees', { eqFilter: { toolbox: toolboxId }, include: ['user'] })(dispatch, getState, services),
        requestResourcesWithRelated('tbSiteHazards', { eqFilter: { toolbox: toolboxId } }, ['siteHazards'])(dispatch, getState, services),
        requestResourcesWithRelated('tbObservations', { eqFilter: { toolbox: toolboxId } }, ['siteObservations'])(dispatch, getState, services),
        requestResourcesWithRelated('tbIncidents', { eqFilter: { toolbox: toolboxId } }, ['incidents'])(dispatch, getState, services),
        requestResources('tbTasks', { eqFilter: { toolbox: toolboxId }, include: ['task'] })(dispatch, getState, services),
        requestResourcesWithRelated('tbTas', { eqFilter: { toolbox: toolboxId } }, ['tas'])(dispatch, getState, services),

        // hazardTypes should have been read by background update - only request if resource is empty
        !resources.hazardTypes || isEmpty(resources.hazardTypes)
            ? requestResources('hazardTypes', {})(dispatch, getState, services) : ResourceEvent.RESOURCE_VALID,
    ])

    const requestResult = resourceEventCombined(results[0], results.slice(1))
    if (requestKey) {
        dispatch(setRequestComplete({ requestKey, resourceEvent: requestResult }))
    }
    return requestResult
}

export const getToolbox = (params: { [key: string]: any }) => (dispatch: AppDispatch, getState: () => RootState) => {
    const toolboxId = params.toolboxId

    const state = getState()
    const toolbox = selectToolbox(getState(), { toolboxId })
    if (!toolbox) {
        return null
    }

    const relOpts = { eqFilter: { toolbox: toolboxId } }

    const company = getResource<Company>(state.resources.companies, toolbox.company)
    const site = getResource<Site>(state.resources.sites, toolbox.site)
    const project = getResource<Project>(state.resources.projects, toolbox.project)
    const tbAttendees = getResources<TbAttendee>(state.resources, 'tbAttendees', relOpts)
    const tbSiteHazards = getResources<TbSiteHazard>(state.resources, 'tbSiteHazards', relOpts)
    const tbObservations = getResources<TbObservation>(state.resources, 'tbObservations', relOpts)
    const tbIncidents = getResources<TbIncident>(state.resources, 'tbIncidents', relOpts)
    const tbTasks = getResources<TbTask>(state.resources, 'tbTasks', relOpts)
    const tbTas = getResources<TbTa>(state.resources, 'tbTas', relOpts)
    const tbOther = getResources<TbOther>(state.resources, 'tbOther', relOpts)

    const attendees = tbAttendees.reduce((acc: Contact[], tbAttendee) => {
        const contact = userContact(getResource<User>(state.resources.users, tbAttendee.user))
        if (contact) {
            acc.push(contact)
        }
        return acc
    }, [])

    const siteHazards = tbSiteHazards.reduce((acc: { hazardText: string }[], tbSiteHazard) => {
        const siteHazard = getResource<SiteHazard>(state.resources.siteHazards, tbSiteHazard.siteHazard)
        const hazardText = siteHazard?.hazardType
            ? getResource<HazardType>(state.resources.hazardTypes, siteHazard.hazardType)?.hazardText
            : siteHazard?.customHazard || ''

        if (hazardText) {
            acc.push({ hazardText })
        }
        return acc
    }, [])

    const observations = tbObservations.reduce(
        (acc: { observationDate: string, description: string, photoBefore: number | null | undefined }[], tbObservation) => {
        const observation = getResource<SiteObservation>(state.resources.siteObservations, tbObservation.observation)
        if (observation) {
            acc.push({
                observationDate: observation?.observationDate,
                description: observation?.description,
                photoBefore: observation?.photoBefore
            })
        }
        return acc
    }, [])

    const incidents = tbIncidents.reduce((acc: Partial<Incident>[], tbIncident) => {
        const incident = getResource<Incident>(state.resources.incidents, tbIncident.incident)
        if (incident) {
            acc.push({
                incidentDate: incident?.incidentDate,
                description: incident?.description,
                photo: incident?.photo
            })
        }
        return acc
    }, [])

    const tasks = tbTasks.reduce((acc: Partial<Task>[], tbTask) => {
        const task = getResource<Task>(state.resources.tasks, tbTask.task)
        if (task) {
            acc.push({
                id: task.id,
                taskName: task.taskName
            })
        }
        return acc
    }, [])

    const tas = tbTas.reduce((acc: { name: string, description: string, scheduledTask?: number }[], tbTa) => {
        const ta = getResource<Ta>(state.resources.tas, tbTa.ta)
        if (ta) {
            acc.push({
                name: ta.userData.name,
                description: ta.userData.description,
                scheduledTask: ta.scheduledTask
            })
        }
        return acc
    }, [])

    return {
        toolbox: {
            company: {
                id: toolbox.company,
                companyName: company?.companyName
            },
            site: site
            ?{
                siteName: site.siteName,
                ...addressFull(site),
                timeZone: site.timeZone || ''
            }
            :{
                siteName: company?.companyName,
                // We currrently don't have a timezone for company-wide toolboxes
                // - default to NZ (should generally show correct date for NZ / AUS companies)
                // TODO: add a timezone to company record for things like this
                timeZone: 'Pacific/Auckland',
            },
            project: { projectName: project?.projectName },
            creator: userContact(getResource<User>(state.resources.users, toolbox.creator)),
            openDateTime: toolbox.openDateTime,
            closeDateTime: toolbox.closeDateTime,
            groupPhoto: toolbox.groupPhoto,

            ...toolbox.meetingData,

            attendees,
            siteHazards,
            observations,
            incidents,
            tasks,
            tas,

            other: tbOther.map(item => ({
                heading: item.heading,
                photo: item.photo,
                text: item.text
            }
            )),

            images: [toolbox.groupPhoto]
                .concat(observations.map(observation => observation.photoBefore))
                .concat(incidents.map(incident => incident.photo))
                .concat(tbOther.map(tbOther => tbOther.photo))
                .filter(id => !!id).map(id => ({ savedId: id }))
        }
    }
}
