import { AppDispatch, RootState } from "../../redux/store"
import { getResource } from "../../datastore"
import { Control, HazardType, PpeType, User } from "../../datastore/models"
import { userContact } from "./helpers/userContact"
import { selectTa, selectTaEdit } from "../../redux/selectors/tas"
import { selectTask } from '../../redux/selectors/tasks'
import { getRiskScore } from "../../helpers/riskScore"
import { selectSite } from "../../redux/selectors/sites"
import { selectProject } from "../../redux/selectors/projects"
import { addressFull } from "./helpers/addressFull"

export const getTaskAnalysis = (params: { [key: string]: any }) => (dispatch: AppDispatch, getState: () => RootState) => {
    const { taId, taEdit } = params
    const state = getState()
    // taEdit flag specifies that we should use data from taEdit buffer (e.g. when reviewing a ta that hasn't been saved yet)
    const ta = taEdit ? selectTaEdit(state) : selectTa(state, { taId })
    if (!ta) {
        return null
    }

    const project = selectProject(state, { projectId: ta.project })
    const site = selectSite(state, { siteId: ta.site })
    const task = selectTask(state, { taskId: ta.scheduledTask })

    return {
        ta: {
            company: ta.company,
            site: {
                siteName: site?.siteName,
                ...addressFull(site),
                timeZone: site?.timeZone || '',
            },
            project: { projectName: project?.projectName || '' },
            revision: ta.revision,
            superseded: ta.superseded,
            scheduledTask: { taskName: task?.taskName || '' },
            name: ta.userData.name,
            description: ta.userData.description,
            startDate: ta.startDate,

            steps: ta.userData.steps.map((step, i) => ({
                stepNum: i + 1,
                text: step.text,
                hazards: step.hazards.map(hazard => {
                    let hazardText = ''
                    if (hazard.hazardTypeId) {
                        hazardText = getResource<HazardType>(state.resources.hazardTypes, hazard.hazardTypeId)?.hazardText || ''
                    } else {
                        hazardText = hazard.customHazardType
                    }

                    return {
                        hazardText,
                        ir: getRiskScore(hazard.irConsequence, hazard.irLikelyhood),
                        rr: getRiskScore(hazard.rrConsequence, hazard.rrLikelyhood),
                        controls:
                            hazard.controls.map(control => ({
                                controlText: getResource<Control>(state.resources.controls, control)?.controlText || ''
                            }))
                                .concat(hazard.customControls.map(control => ({ controlText: control })))
                    }
                }).sort((a, b) => a.hazardText.localeCompare(b.hazardText))
            })),

            ppe: ta.userData.ppe.map(ppeTypeId => {
                const ppeType = getResource<PpeType>(state.resources.ppeTypes, ppeTypeId)
                return ppeType ? {
                    name: ppeType.name,
                    description: ppeType.description,
                    icon: ppeType.icon
                } : {}

            }).concat(ta.userData.customPpe.map(item => ({
                name: item,
                description: '',
                icon: ''
            }))),

            workers: ta.workers.filter(worker => !worker.deleted).map(worker => ({
                signature: worker.signature,
                userId: worker.user,
                isSupervisor: worker.isSupervisor,
                ...userContact(getResource<User>(state.resources.users, worker.user)),
            }))
        }
    }
}