import { AppDispatch, RootState } from '../../../redux/store'
import { requestResources } from '../../../datastore'
import { selectRequestStatus } from '../../../redux/selectors/resourceMeta'
import { selectLatestSignin } from '../../../redux/selectors/signins'
import { setRequestComplete, setRequestStart } from '../../../redux/slices/resourceMeta'
import { resourceEventCombined } from '../../../datastore/resourceEventCombined'

export const requestLatestSignin = (user: number) => async (dispatch: AppDispatch, getState: () => RootState, services: any) => {

    dispatch(setRequestStart({ requestKey: 'signinsLatest'}))

    const latestSigninResult = await requestResources('signins', {
        eqFilter: { id: 'latest', user },
    })(dispatch, getState, services)

    const latestSignin = selectLatestSignin(getState(), { userId: user })

    if (!latestSignin) {
        dispatch(setRequestComplete({ requestKey: 'signinsLatest', resourceEvent: latestSigninResult }))
        return latestSigninResult
    }

    // request corresponding site (note cannot use 'requestResourcesWithRelated' to get this as filtering logic doesn't understand 'id=latest')
    const siteResult = await requestResources('sites', {
        eqFilter: { id: latestSignin.site },
    })(dispatch, getState, services)

    dispatch(setRequestComplete({ requestKey: 'signinsLatest', resourceEvent: resourceEventCombined(latestSigninResult, [siteResult]) }))
}

export const requestLatestSigninResult = (state: RootState) => {
    return selectRequestStatus(state, { requestKey: 'signinsLatest' })
}