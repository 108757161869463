import log from 'loglevel'
import { AppDispatch, RootState } from '../../redux/store'
import { deleteResource, saveResource } from '../../datastore'
import { selectSite } from '../../redux/selectors/sites'
import { savePhoto } from '../../docstore/savePhoto'
import {
    setIncidentReportActionId,
    setIncidentReportDirty,
    setIncidentReportEdit,
    setIncidentReportWorkerId
} from '../../redux/slices/pgIncidentReports'

export const saveIncident = () => async (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState()
    const { dirty, incidentReportEdit, incidentReportEdit: { workersInvolved, actions } } = state.pgIncidentReports

    const site = selectSite(state, { siteId: incidentReportEdit.site })
    if (!site) {
        log.warn('Failed to save incident - site not found')
        return
    }

    if (dirty) {

        const photo = await savePhoto(
            incidentReportEdit.photo || undefined,
            'Incident',
            { company: site.company, site: site.id }
        )(dispatch, getState)

        const incidentId = saveResource({
            id: incidentReportEdit.id,
            company: site.company,
            site: incidentReportEdit.site,
            project: incidentReportEdit.project,
            incidentDate: incidentReportEdit.incidentDate,
            incidentType: incidentReportEdit.incidentType,
            reporter: incidentReportEdit.reporter,
            description: incidentReportEdit.description,
            photo: photo || null,
            actionVerifier: incidentReportEdit.actionVerifier,
            incidentData: incidentReportEdit.incidentData,
            notifiedDate: incidentReportEdit.notifiedDate,
            closedDate: incidentReportEdit.closedDate
        }, 'incidents')(dispatch, getState)
        dispatch(setIncidentReportEdit({ id: incidentId }))

        await Promise.all(workersInvolved.map(async (worker, index) => {

            if (worker.deleted) {
                dispatch(deleteResource(worker.id, 'incidentWorkers'))

            } else if (worker.dirty) {

                const workerPhoto = await savePhoto(
                    worker.photo || undefined,
                    'Incident Worker',
                    { company: site.company, site: site.id }
                )(dispatch, getState)

                const workerId = saveResource({
                    id: worker.id,
                    company: site?.company,
                    incident: incidentId,
                    user: worker.user,
                    photo: workerPhoto,
                    injuryDescription: worker.injuryDescription,
                    injuryType: worker.injuryType
                }, 'incidentWorkers')(dispatch, getState)
                dispatch(setIncidentReportWorkerId({ index, id: workerId }))
            }
        }))

        actions.forEach((action, index) => {

            if (action.deleted) {
                dispatch(deleteResource(action.id, 'incidentActions'))

            } else if (action.dirty) {

                const actionId = saveResource({
                    id: action.id,
                    company: site?.company,
                    incident: incidentId,
                    actionText: action.actionText,
                    creator: action.creator,
                    verifiedText: action.verifiedText,

                }, 'incidentActions')(dispatch, getState)
                dispatch(setIncidentReportActionId({ index, id: actionId }))
            }
        })

        dispatch(setIncidentReportDirty(false))
    }
}