import { saveResource } from '../../datastore'
import dateToISOString from '../../helpers/dateToIsoString'
import { AppDispatch, RootState } from '../../redux/store'

export const setTermsAgreed = () => async (dispatch: AppDispatch, getState: () => RootState) => {

    const user = getState().app.user

    saveResource({
        ...user,
        termsAgreedDate: dateToISOString(new Date())
    }, 'users')(dispatch, getState)
}