import { createSelector } from '@reduxjs/toolkit'
import { isValid, isAfter, parseJSON } from 'date-fns'
import type { RootState } from '../../redux/store'
import Signin from '../../datastore/models/Signin'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { ResourceMap } from '../slices/resources'
import { getResource, getResources } from '../../datastore'
import { User } from '../../datastore/models'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'

const selectAllSignins = (state: RootState) => state.resources.signins
const selectUser = (state: RootState) => state.app.user

const selectLatestSigninParams = selectParamsMemo()
/**
 * Gets most recent signin record for a given site and user
  * 
 * @param state 
 * @returns Signin
 */
export const selectLatestSignin = createSelector([selectAllSignins, selectLatestSigninParams], (allSignins, params) => {

    const siteId = params.siteId || null
    const userId = params.userId || null
    const signinType = params.signinType || null
    return findLatestSignin(allSignins, siteId, userId, signinType)
})

/**
 * Gets boolean 'is signed in' status for currently logged in user
 */
export const selectIsSignedIn = createSelector([selectAllSignins, selectUser], (allSignins, user) => {
    if (!user || !user.id) {
        return false
    }
    const latestSignin = findLatestSignin(allSignins, null, user.id, null)
    if (!latestSignin) { return false }

    if (!latestSignin.signoutTime || !isValid(parseJSON(latestSignin.signoutTime))) {
        return true
    }
    return false
})

/**
 * gets id of site currently logged in user is currently signed into, based on signin records
 * (or null if not signed in to a site)
 */
export const selectSignedinSiteId = createSelector([selectAllSignins, selectUser], (allSignins, user) => {
    if (!user || !user.id) {
        return null
    }
    const latestSignin = findLatestSignin(allSignins, null, user.id, null)
    if (!latestSignin) { return null }

    if (!latestSignin.signoutTime || !isValid(parseJSON(latestSignin.signoutTime))) {
        return latestSignin.site
    }
    return null
})

const selectSignedInUsersResources = selectResourcesMemo(['sites', 'users', 'signins'])
const selectSignedInUsersParams = selectParamsMemo()
export const selectSignedinUsers = createSelector([selectSignedInUsersResources, selectSignedInUsersParams], (resources, params) => {

    const filter = params.siteIds
    ?{
        eqFilter: { signoutTime: undefined},
        inFilter: { site: params.siteIds }
    }
    : {
        eqFilter: { signoutTime: undefined, site: params.siteId}
    }

    const activeSignins = getResources<Signin>(resources, 'signins', filter)
        // Sort into reverse chronological order
        .sort((a, b) => parseJSON(b.signinTime).getTime() - parseJSON(a.signinTime).getTime())
        // Remove items with duplicate user (keep only the newest)
        // Shouldn't be possible to get more than one active signin for the same user, but if we somehow do we want to disregard all but
        // the most recent
        .reduce((acc: Signin[], current) => {
            if (!acc.find(signin => signin.user === current.user)) {
                acc.push(current)
            }
            return acc
        }, [])

    // join signins with corresponding user records, filtering out any where user isn't found (this may occur temporarily if user and signin resources
    // are being loaded at the same time and a user hasn't been received yet) 
    return activeSignins.reduce((acc: any[], signin) => {
        const user = getResource<User>(resources.users, signin.user)
        if (user) {
            acc.push({
                ...user,
                signinType: signin.signinType,
                signinTime: signin.signinTime
            })
        }
        return acc
    }, [])
})

/**
 * Find the most recent signin for a given user / site / signin type
 * Typically will be used to determine whether a given user is currently signed in or not
 * Also used during signin ro determine when user last signed in as a given type
 */
function findLatestSignin(allSignins: ResourceMap, siteId: number | null, userId: number | null, signinType: string | null) {
    if (allSignins) {
        const latestSignin = Object.keys(allSignins).reduce((latestSignin: Signin | null, currentId: string) => {

            const testSignin = getResource<Signin>(allSignins, +currentId)
            if (
                testSignin &&
                (siteId === null || +testSignin!.site === siteId) &&
                (userId === null || +testSignin!.user === userId) &&
                (signinType === null || testSignin.signinType === signinType) &&
                (!latestSignin || isAfter(parseJSON(testSignin.signinTime), parseJSON(latestSignin.signinTime)))
            ) {
                return testSignin
            }
            return latestSignin

        }, null)
        return latestSignin

    }
    return null
}

