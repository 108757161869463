import Axios from 'axios'
import { AXIOS_GET_TIMEOUT } from '.'
import { getApiRoot } from '../helpers/getApiRoot'
import { ApiResult } from '../interfaces/api'
import { getAccessToken } from './accessToken'
import { errorCheckedReq } from './helpers/errorCheckedRequest'

export const getCurrentUser = errorCheckedReq(async () => {
    const response = await Axios.get(`${getApiRoot()}auth/get-current-user`, {
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      },
      timeout: AXIOS_GET_TIMEOUT
    })    
    return {
      result: ApiResult.SUCCESS,
      responseData: {
        user: response.data.user,
        permissions: response.data.permissions
      }
    }
  })
  