import { AppDispatch, RootState } from "../../redux/store"
import { selectSignedinSiteId } from "../../redux/selectors/signins"
import { initialiseSiteObservationEdit } from "../../redux/slices/pgSiteObservations"
import { updateSequence } from "./siteObservationsSequence"
import { SiteObservationEvent } from "./siteObservationsTypes"
import { requestResourcesWithRelated } from "../../datastore"
import { selectCanCreateObservation } from "../../redux/selectors/abilities"
import { selectOpenProjectSites } from "../../redux/selectors/projectSites"
import log from "loglevel"
import { hideLoadingSpinner, showLoadingSpinner } from "../../redux/slices/temp"
import { uniq } from "lodash"

export const createObservation =
  (siteId?: number) =>
  async (dispatch: AppDispatch, getState: () => RootState, services: any) => {
    let state = getState()
    const currentDateTime = new Date().toISOString()
    const userId = state.app.user?.id
    const signedInSiteId = selectSignedinSiteId(state)

    dispatch(showLoadingSpinner("Loading..."))

    // Get projectSite records, and filter to those for projects this user has create permission for
    // (get as related resource of projects so that we only get records related to open projects)
    await requestResourcesWithRelated(
      "projects",
      { eqFilter: { status: "OPEN" } },
      ["projectSites"]
    )(dispatch, getState, services)
    state = getState()
    const projectSites = selectOpenProjectSites(state)
    const availableSiteIds = uniq(
      projectSites
        .filter((projectSite) => selectCanCreateObservation(state, projectSite))
        .map((projectSite) => projectSite.site)
    )

    let defaultSiteId = 0

    // Set default site for observation:
    if (availableSiteIds.length) {
      if (siteId && availableSiteIds.includes(siteId)) {
        // If we were given a specific site id, use it (i.e. creation from toolbox)
        defaultSiteId = siteId
      } else if (signedInSiteId && availableSiteIds.includes(signedInSiteId)) {
        // If we're signed in to a site, use that
        defaultSiteId = signedInSiteId
      } else if (availableSiteIds.length === 1) {
        // If only one site is avaiable, choose it by default
        defaultSiteId = availableSiteIds[0]
      }
      
    } else {
      log.error("createObservation:No projectSite with create permission found")
    }

    dispatch(
      initialiseSiteObservationEdit({
        details: {
          company: 0,
          site: defaultSiteId,
          observationDate: currentDateTime,
          observer: userId || 0,
          description: ""
        },
        actions: []
      })
    )

    dispatch(hideLoadingSpinner())

    dispatch(updateSequence(SiteObservationEvent.DETAILS_SELECTED))
  }
