import { NormalizedData } from '@disruptph/json-api-normalizer'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isAfter, isValid, parseJSON } from 'date-fns'
import log from 'loglevel'
import { updateRequestSubscriptions } from '../../datastore/requestResultSubscription'
import { RequestStatus, ResourceEvent, ResourceUpdateStatus } from '../../datastore/types'
import { ResourceType } from './resources'

export interface ResourceMetaState {
    nextId: number
    writeQueue: NormalizedData[]
    writeQueueHead: NormalizedData | null
    writeInProgress: boolean

    resourceUpdateStatus: { [key in Partial<ResourceType>]?: ResourceUpdateStatus }

    activeRequests: {
        [key: string]: {
            requestStartTime: string
            status: RequestStatus
            version: number,
            resourceType?: ResourceType,
            opts?: string
        }
    }
}

// Starting value for locally generated temporary IDs
// -> a number that's big enough not to be used as an actual id in database, but not big enough to hit js number precision limit
export const LOCAL_ID_START = 10000000000000

const initialState: ResourceMetaState = {
    nextId: LOCAL_ID_START,
    writeQueue: [],
    writeQueueHead: null,
    writeInProgress: false,

    resourceUpdateStatus: {},

    activeRequests: {},
}

export const resourceMeta = createSlice({
    name: 'resourceMeta',
    initialState,
    reducers: {
        /**
        * Replace entire slice with new data
        * (will be called when initialising from persistent storage)  
        */
        setAll(state, action: PayloadAction<ResourceMetaState>) {
            return {
                ...state,
                ...action.payload,
                // Don't use persisted 'writeInProgress' flag or activeRequests (should always be reset at startup)
                writeInProgress: false,
                activeRequests: {}
            }
        },

        incNextId(state, action: PayloadAction<void>) {
            state.nextId++
        },

        queueWrite(state, action: PayloadAction<NormalizedData>) {
            state.writeQueue.push(action.payload)
            if (!state.writeQueueHead) {
                state.writeQueueHead = state.writeQueue[0]
                state.writeInProgress = false
            }
        },

        setWriteInProgress(state, action: PayloadAction<void>) {
            state.writeInProgress = true
        },

        setWriteResult(state, action: PayloadAction<boolean>) {
            if (action.payload) {
                // Write succeeded
                state.writeQueue.shift()
                state.writeQueueHead = state.writeQueue[0] || null
            }
            state.writeInProgress = false
        },

        clearWriteQueue(state, action: PayloadAction<void>) {
            state.writeQueue = []
            state.writeQueueHead = null
            state.writeInProgress = false
        },

        initialiseResourceStatus(state, action: PayloadAction<{ resourceType: ResourceType, status: Partial<ResourceUpdateStatus> }>) {
            state.resourceUpdateStatus[action.payload.resourceType] = {
                ...state.resourceUpdateStatus[action.payload.resourceType],
                ...action.payload.status
            }
        },

        updateResourceStatus(state, action: PayloadAction<{ resourceType: ResourceType, resourceEvent: ResourceEvent, mostRecentModified?: string }>) {
            if (state.resourceUpdateStatus[action.payload.resourceType]) {
                if (action.payload.resourceEvent === ResourceEvent.RESOURCE_VALID) {
                    // If request suceeded then update 'validTo' time to the 'lastModifiedDate' of the 
                    // most recently modified resource we received
                    const mostRecentModified = action.payload.mostRecentModified
                    const dtMostRecentModified = mostRecentModified ? parseJSON(mostRecentModified) : null
                    const existingValidTo = state.resourceUpdateStatus[action.payload.resourceType]!.validTo

                    if (dtMostRecentModified &&
                        isValid(dtMostRecentModified) && (
                            !existingValidTo || isAfter(dtMostRecentModified, parseJSON(existingValidTo))
                        )) {
                        state.resourceUpdateStatus[action.payload.resourceType]!.validTo = mostRecentModified
                    }
                }
                if (action.payload.resourceEvent === ResourceEvent.RESOURCE_VALID || action.payload.resourceEvent === ResourceEvent.NO_RESOURCE_FOUND) {
                    state.resourceUpdateStatus[action.payload.resourceType]!.lastUpdated = new Date().toISOString()
                }
                state.resourceUpdateStatus[action.payload.resourceType]!.lastUpdateResult = action.payload.resourceEvent
            }
        },

        clearResourceStatus(state, action: PayloadAction<ResourceType>) {
            delete state.resourceUpdateStatus[action.payload]
        },

        setRequestStart(state, action: PayloadAction<{ requestKey: string, resourceType?: ResourceType, opts?: string }>) {

            // console.log('setRequestStart:', action.payload.requestKey)

            // Normally version will be 0; increment if we're restarting a request that's already in progress
            const requestKey = action.payload.requestKey
            const version = (state.activeRequests[requestKey] && state.activeRequests[requestKey].status === RequestStatus.IN_PROGRESS)
                ? state.activeRequests[requestKey].version + 1
                : 0

            state.activeRequests[requestKey] = {
                status: RequestStatus.IN_PROGRESS,
                requestStartTime: new Date().toISOString(),
                version,
                resourceType:action.payload.resourceType,
                opts: action.payload.opts
            }
        },

        setRequestComplete(state, action: PayloadAction<{ requestKey: string, resourceEvent: ResourceEvent }>) {

            // console.log('setRequestComplete:', action.payload.requestKey)

            if (state.activeRequests[action.payload.requestKey]) {

                switch (action.payload.resourceEvent) {
                    case ResourceEvent.NO_RESOURCE_FOUND:
                        state.activeRequests[action.payload.requestKey].status = RequestStatus.NO_RESOURCE_FOUND
                        break

                    case ResourceEvent.RESOURCE_LOAD_ERROR:
                        state.activeRequests[action.payload.requestKey].status = RequestStatus.RESOURCE_LOAD_ERROR
                        break

                    case ResourceEvent.RESOURCE_VALID:
                        state.activeRequests[action.payload.requestKey].status = RequestStatus.RESOURCE_VALID
                        break

                    default:
                        log.error('setRequestComplete called without valid completion event')
                }
                updateRequestSubscriptions(action.payload.requestKey, action.payload.resourceEvent)
            }
        },

        removeRequest(state, action: PayloadAction<string>) {
            delete state.activeRequests[action.payload]
        }

    }
})

// Action creators are generated for each case reducer function
export const {
    incNextId,
    queueWrite,
    setWriteInProgress,
    setWriteResult,
    clearWriteQueue,

    initialiseResourceStatus,
    updateResourceStatus,
    clearResourceStatus,

    setRequestStart,
    setRequestComplete,
    removeRequest,

} = resourceMeta.actions

export default resourceMeta.reducer