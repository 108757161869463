/**
 * State management specific to 'site observations' page
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { stSITE_OBSERVATIONS } from '../../features/siteObservations/siteObservationsTypes'
import { SiteObservationActionEdit } from '../../pages/SiteObservations/Actions'
import { SiteObservationDetailsEdit } from '../../pages/SiteObservations/Details'


export interface SiteObservationEdit {
    details: SiteObservationDetailsEdit,
    actions: SiteObservationActionEdit[],
}

export interface PgSiteObservationsState {
    stSiteObservations: stSITE_OBSERVATIONS
    siteObservationEdit: SiteObservationEdit
    reviewSiteObservationId?: number
    dirty: boolean
    toolboxMode: boolean
    toolboxObservation: number | null
    toolboxSite: number | null
}

const initialState: PgSiteObservationsState = {
    stSiteObservations: stSITE_OBSERVATIONS.START,
    siteObservationEdit: {
        details: {
            company: 0,
            site: 0,
            observationDate: '',
            observer: 0,
            description: '',
        },
        actions: []
    },
    dirty: false,
    toolboxMode: false,
    toolboxObservation: null,
    toolboxSite: null
}

export const pgSiteObservations = createSlice({
    name: 'pgSiteObservations',
    initialState,
    reducers: {

        /**
        * Replace entire slice with new data
        * (will be called when initialising from persistent storage)  
        * - merge with initial state in case no saved copy or new items have been added since last save
        */
        setAll(state, action: PayloadAction<PgSiteObservationsState>) {
            return {
                ...state,
                ...action.payload
            }
        },

        /**
        * Reset state to initial values (e.g. on logout)
        *
        */
        setInitial(state, action: PayloadAction<void>) {
            return initialState
        },

        setSiteObservationsState(state, action: PayloadAction<stSITE_OBSERVATIONS>) {
            state.stSiteObservations = action.payload
        },

        setSiteObservationReviewId(state, action: PayloadAction<number>) {
            state.reviewSiteObservationId = action.payload
        },

        /**
         * Initialise edit buffer (either with blank / default data or with an existing  record)         
         */
        initialiseSiteObservationEdit(state, action: PayloadAction<SiteObservationEdit>) {
            state.siteObservationEdit = action.payload
            state.dirty = false
        },

        /**
         * Update edit buffer by merging new data with it 
         * (can be used to selectively set different attributes)         
         */
        setSiteObservationDetails(state, action: PayloadAction<Partial<SiteObservationDetailsEdit>>) {
            state.siteObservationEdit.details = {
                ...state.siteObservationEdit.details,
                ...action.payload
            }
            state.dirty = true
        },

        setSiteObservationActions(state, action: PayloadAction<SiteObservationActionEdit[]>) {
            state.siteObservationEdit.actions = [...action.payload]
            state.dirty = true
        },

        setSiteObservationActionId(state, action: PayloadAction<{ index: number, id?: number }>) {
            if (action.payload.id) {
                const editAction = state.siteObservationEdit.actions[action.payload.index]
                if (editAction) {
                    editAction.id = action.payload.id
                }
                state.dirty = true
            }
        },

        setSiteObservationDirty(state, action: PayloadAction<boolean>) {
            state.dirty = action.payload
        },

        setSiteObservationToolboxMode(state, action: PayloadAction<{ mode: boolean, observation?: number, site?: number }>) {

            if (action.payload.mode) {
                // Entering 'toolbox mode'            
                state.toolboxMode = true
                state.toolboxObservation = action.payload.observation || null
                state.toolboxSite = action.payload.site || null

            } else {
                state.toolboxMode = false
                state.toolboxObservation = null
                state.toolboxSite = null
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const {
    setAll,
    setInitial,
    setSiteObservationsState,
    setSiteObservationReviewId,
    initialiseSiteObservationEdit,
    setSiteObservationDetails,
    setSiteObservationActions,
    setSiteObservationActionId,
    setSiteObservationDirty,
    setSiteObservationToolboxMode
} = pgSiteObservations.actions

export default pgSiteObservations.reducer