import { useRef, useState } from "react"
import log from "loglevel"

import {
  IonButton,
  IonContent,
  IonFooter,
  useIonViewDidLeave
} from "@ionic/react"
// import { cameraReverse, flashlight } from "ionicons/icons";
import { useAppSelector, useAppDispatch } from "../../redux/hooks"
import QrScanner from "../../components/QrScanner"
import { login } from "../../features/auth"
import {
  isValidSigninCode,
  updateSequence
} from "../../features/signin/signinSequence"
import { SigninEvent, stSIGNIN } from "../../features/signin/signinTypes"
import { setRedirectPath } from "../../redux/slices/temp"
import env from "../../config"
import { setNearbySites, setSigninCode } from "../../redux/slices/pgSignin"
import { findNearbySites } from "../../features/signin/findNearbySites"
import { selectCanViewAny } from "../../redux/selectors/abilities"

const ScanQr: React.FC = () => {
  const [cameraUnavailable, setCameraUnavailable] = useState<Boolean>(false)
  const { user } = useAppSelector((state) => state.app)
  const { stSignin, nearbySites } = useAppSelector((state) => state.pgSignin)
  const lastPath = useAppSelector((state) => state.temp.lastPath)
  const canViewAny = useAppSelector(selectCanViewAny)
  // Arbitrary value used to debounce scan errors so we don't hammer the log...
  const scanError = useRef(0)

  const dispatch = useAppDispatch()

  // Disable QRcode scanning if user leaves signin tab
  // Due to the way ionic router works, page component stays mounted after we leave page
  // -> check currentPath to determine if signin page is currently displayed
  const currentPath = useAppSelector((state) => state.temp.currentPath)
  const enableScanner =
    currentPath === "/signin" && stSignin === stSIGNIN.SCAN_QR

  function handleQrResult(result: string, scanData: string) {
    log.debug("HandleQrResult:" + result)
    let foundCode = false
    if (result === "Ok") {
      try {
        log.debug(`scanData:${scanData}`)
        const appUrl = new URL(env.appUrl!)
        const codeDataUrl = new URL(scanData)
        if (appUrl.host === codeDataUrl.host) {
          const params = codeDataUrl.searchParams
          const signinCode = params.get("signinCode")
          if (isValidSigninCode(signinCode)) {
            foundCode = true
            dispatch(setSigninCode(signinCode!))
            return true
          }
          if (!foundCode) {
            if (scanError.current !== 1) {
              log.debug("No signin code found, resume scanning...")
              scanError.current = 1
            }
          }
        } else {
          if (scanError.current !== 2) {
            log.warn("QR code host missmatch, resume scanning...")
            scanError.current = 2
          }
        }
      } catch (e) {
        if (scanError.current !== 3) {
          log.debug("Invalid url, resume scanning...")
          scanError.current = 3
        }
      }
    } else {
      // scanning process failed (e.g. no camera available)
      // - attempt manual site selection
      log.debug("Unable to access camera, select site manually")
      setCameraUnavailable(true)
    }
    return false
  }

  // Clear 'nearby sites' list on exit
  // (don't want to retain in case the user is in a different location when they next open the page)
  useIonViewDidLeave(() => {
    dispatch(setNearbySites([]))
  })

  return (
    <>
      <IonContent class="signin-page">
        <div id="qr-content-flex">
          {nearbySites.length ? (
            <></>
          ) : (
            <>
              {cameraUnavailable ? (
                <div id="no-cam-error">
                  <h1>Camera unavailable</h1>
                  <IonButton
                    color="danger"
                    onClick={() =>
                      dispatch(updateSequence(SigninEvent.SET_CODE_ENTRY_MODE))
                    }
                  >
                    Tap to enter site code
                  </IonButton>
                </div>
              ) : (
                <div id="qr-container">
                  {enableScanner ? (
                    <QrScanner callback={handleQrResult} />
                  ) : (
                    <></>
                  )}
                  <div id="qr-overlay">
                    <div id="qr-guide">
                      {/* Top Left */}
                      <div id="tlh" className="t r"></div>
                      <div id="tlv" className="b l"></div>
                      <div id="tlc" className="t l"></div>
                      <div id="tli" className="t l i"></div>

                      {/* Top Right */}
                      <div id="trh" className="t l"></div>
                      <div id="trv" className="r b"></div>
                      <div id="trc" className="t r"></div>
                      <div id="tri" className="t r i"></div>

                      {/* Bottom Left */}
                      <div id="blh" className="r b"></div>
                      <div id="blv" className="t l"></div>
                      <div id="blc" className="b l"></div>
                      <div id="bli" className="l b i"></div>

                      {/* Bottom Right */}
                      <div id="brh" className="b l"></div>
                      <div id="brv" className="t r"></div>
                      <div id="brc" className="r b"></div>
                      <div id="bri" className="r b i"></div>
                    </div>
                    <div id="qr-buttons">
                      {/* 
                                <IonButton>
                                    <IonIcon icon={flashlight}></IonIcon>
                                </IonButton>
                                 */}
                      <IonButton
                        color="danger"
                        onClick={() =>
                          dispatch(
                            updateSequence(SigninEvent.SET_CODE_ENTRY_MODE)
                          )
                        }
                      >
                        Can't scan code?
                      </IonButton>
                      {/* 
                                <IonButton>
                                    <IonIcon icon={cameraReverse}></IonIcon>
                                </IonButton>
                                 */}
                    </div>
                  </div>
                </div>
              )}

              <div
                className="divider-tape"
                style={{ backgroundColor: "#FF7052" }}
              ></div>
            </>
          )}
          <div id="title-button-box">
            {nearbySites.length ? (
              <></>
            ) : (
              <h2>Scan QR code to sign into a site</h2>
            )}
            {/* <span>- or -</span> */}
            {nearbySites.length ? (
              <>
                <h2>Tap a site to sign in</h2>
                {nearbySites.map((site) => (
                  <IonButton
                    className="qr-page-button"
                    onClick={() => dispatch(setSigninCode(site.signinCode!))}
                  >
                    {site.siteName}
                  </IonButton>                  
                ))}
                <hr />
                <IonButton
                  className="qr-page-button secondary"
                  onClick={() => dispatch(findNearbySites())}
                >
                  Search Again
                </IonButton>

                <IonButton
                  className="qr-page-button secondary"
                  onClick={() => dispatch(setNearbySites([]))}
                >
                  Scan QR Code
                </IonButton>
              </>
            ) : (user && canViewAny) ? (
              <>
                <hr />
                <IonButton
                  className="qr-page-button"
                  onClick={() => dispatch(findNearbySites())}
                >
                  Search for nearby sites
                </IonButton>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </IonContent>
      <IonFooter class="signin-page">
          {user ? (
            <IonButton
              className="qr-page-button"
              onClick={() => dispatch(setRedirectPath("/home"))}
            >
              <div>
                <img
                  alt="cone"
                  src="./assets/ui_images/oss_cone_only.svg"
                ></img>
                {lastPath === "/home" ? (
                  <p>Return to Home Screen</p>
                ) : (
                  <p>Continue to App</p>
                )}
              </div>
            </IonButton>
          ) : (
            <IonButton
              className="qr-page-button last"
              onClick={() => dispatch(login())}
            >
              <div>
                <img
                  alt="cone"
                  src="./assets/ui_images/oss_cone_only.svg"
                ></img>
                <p>Login to app</p>
              </div>
            </IonButton>
          )}
      </IonFooter>
    </>
  )
}

export default ScanQr
