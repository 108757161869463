import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getResource, getResources } from '../../datastore'
import { Company } from '../../datastore/models'
import { selectParamsMemo } from './helpers/selectParamsMemo'
import { selectResourcesMemo } from './helpers/selectResourcesMemo'

const selectCompanyResources = selectResourcesMemo(['companies'])
const selectCompanyParams = selectParamsMemo()

/**
 * Get company by id
  */
export const selectCompany = createSelector([selectCompanyResources, selectCompanyParams], (resources, params) => {

    return getResource<Company>(resources.companies, params.companyId)
})

/**
 * Get all available companies
 */
const selectCompaniesParams = selectParamsMemo()
export const selectCompanies = createSelector([selectCompanyResources, selectCompaniesParams], (resources, params) => {
    const opts = params.companyIds
        ? { inFilter: { id: params.companyIds } }
        : {}
    return getResources<Company>(resources, 'companies', opts).filter(company => company?.id !== 1)
})

/**
 * Get id of a named template document for a specified company
 */
const selectTemplateDocParams = selectParamsMemo()
export const selectTemplateDoc = createSelector([selectCompanyResources, selectTemplateDocParams], (resources, params) => {
    const { companyId, templateName } = params
    if (!companyId || !templateName) {
        return null
    }

    const company = getResource<Company>(resources.companies, companyId)

    const templateDoc = _.get(company, ['templateDocs', templateName])
    return templateDoc || null
})