const hazardTypes = {
  table: 'hazard_types',
  idKey: 'id',

  attrs: [
    'hazardText',  
    'hazardTextShort',
    'detail',
    'creationDate',
    'lastModifiedDate'
  ],

  relationships: [ 
    {
      type: 'companies',
      key: 'company',
    },
    {
      type: 'hazard-categories',
      key: 'category',
    }    
  ],
}
export default  hazardTypes
