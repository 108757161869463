import { useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonContent,
  IonFooter,
  IonItem,
  IonList
} from "@ionic/react"

import { useAppSelector, useAppDispatch } from "../../redux/hooks"

import { useIncidentReportsResources } from "../../features/toolbox/resourceRequests/useIncidentReportsResources"
import {
  setToolboxIncident,
  setToolboxMeetingData
} from "../../redux/slices/pgToolbox"
import { saveToolbox } from "../../features/toolbox/saveToolbox"
import { saveTbIncidents } from "../../features/toolbox/saveTbIncidents"
import { inChecklist } from "../../features/toolbox/resourceChecklist"
import {
  incidentReportToolboxCreateSelected,
  incidentReportToolboxReviewSelected
} from "../../features/incidentReports/incidentReportsToolbox"
import TextArea from "../../components/form/TextArea"
import { updateSequence } from "../../features/toolbox/toolboxSequence"
import { ToolboxEvent } from "../../features/toolbox/toolboxTypes"
import ASCheckbox from "../../components/ASCheckbox"

type Inputs = {
  comments: string
}

const schema = yup
  .object()
  .shape({
    comments: yup.string()
  })
  .required()

export const IncidentReports: React.FC = () => {
  const dispatch = useAppDispatch()

  const { toolboxEdit, incidentsList } = useAppSelector(
    (state) => state.pgToolbox
  )
  const siteId = toolboxEdit.site
  const { incidents, isLoading } = useIncidentReportsResources(
    siteId,
    toolboxEdit.company,
    incidentsList
  )

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty }
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      comments: toolboxEdit.meetingData.incidentsComments
    }
  })

  const formSubmitBack = (data: any) => {
    formSubmit(data)
    dispatch(updateSequence(ToolboxEvent.BACK_SELECTED))
  }

  const formSubmitNext = (data: any) => {
    formSubmit(data)
    dispatch(updateSequence(ToolboxEvent.NEXT_SELECTED))
  }

  const formSubmit = (data: any) => {
    if (isDirty) {
      dispatch(setToolboxMeetingData({ incidentsComments: data.comments }))
      dispatch(saveToolbox)
    }
    dispatch(saveTbIncidents)
  }

  const refresherRef = useRef<HTMLIonRefresherElement>(null)
  useEffect(() => {
    if (!isLoading) {
      refresherRef.current?.complete()
    }
  }, [!isLoading])

  return (
    <>
      <IonContent class="content-padding">
        <h2>Incident Reports</h2>
        <p>
          Have there been any safety incidents on site? Include Near Miss and
          Injury Incidents
        </p>
        {/* 
                <IonRefresher
                    ref={refresherRef}
                    slot="fixed"
                    onIonRefresh={() => setRefresh(refresh+1)}
                >
                    <IonRefresherContent
                        pullingIcon={arrowDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing..."
                    />
                </IonRefresher>
 */}
        <form className="toolbox-form">
          <IonList class="toolbox-list">
            <IonAccordionGroup multiple={true}>
              {incidents?.map((item) => {
                const { incident, actions } = item
                return (
                  <IonAccordion key={incident.id}>
                    <IonItem slot="header">                      
                      <ASCheckbox
                        checked={inChecklist(incidentsList, incident)}
                        disabled={!!incident.closedDate}
                        onClick={(checked) =>
                          dispatch(
                            setToolboxIncident({ incident, newState: checked })
                          )
                        }
                        label={incident.description}
                      />
                    </IonItem>
                    <IonList slot="content">
                      <IonItem>
                        <div className="expanded-actions flex column">
                          <h3>Actions:</h3>
                          <ul>
                            {actions.map((action, id) => (
                              <li key={id}>{action.actionText}</li>
                            ))}
                          </ul>
                          <IonButton
                            onClick={() =>
                              dispatch(
                                incidentReportToolboxReviewSelected(incident.id)
                              )
                            }
                          >
                            Review Incident
                          </IonButton>
                        </div>
                      </IonItem>
                    </IonList>
                  </IonAccordion>
                )
              })}
            </IonAccordionGroup>
          </IonList>
          <div className="flex centre-row">
            <IonButton
              onClick={() =>
                dispatch(
                  incidentReportToolboxCreateSelected(siteId || undefined)
                )
              }
            >
              Add Incident
            </IonButton>
          </div>

          <TextArea
            fieldName="comments"
            inputMode="text"
            placeholder="COMMENTS - Any extra details you want to record"
            rows={6}
            watch={watch}
            setValue={setValue}
            errors={errors}
          />
        </form>
      </IonContent>

      <IonFooter class="toolbox-nav">
        <IonButton onClick={handleSubmit(formSubmitBack)}>Back</IonButton>
        <IonButton onClick={handleSubmit(formSubmitNext)}>Next</IonButton>
      </IonFooter>
    </>
  )
}
