
import { IonButton, IonContent, IonFooter, IonItemDivider } from '@ionic/react'
import './WorksafeInfoModal.css'

interface Props {
    dismiss: () => void
}

export const WorksafeInfoModal: React.FC<Props> = ({ dismiss }) => {



    return (
        <>
            <IonContent class="content-padding">
                <div id="worksafe-info-container" className="safe-area-top">
                    <h1>NOTIFIABLE INCIDENTS</h1>
                    <p>Think about the Incident you are about to report. Is it a SERIOUS INCIDENT as noted below</p>
                    <IonItemDivider />

                    <div id="info-note-container">
                        <h2><strong>PLEASE NOTE:&nbsp;</strong><strong>ONLY&nbsp;</strong>
                            <span>the following types of Incidents require notification to WorkSafe</span></h2>
                    </div>

                    <div>
                        <h3>HI RISK INCIDENT</h3>
                        <p>Notify WorkSafe if someone has a <strong>SERIOUS</strong> or <strong>IMMEDIATE RISK</strong>
                            &nbsp;to their health and safety because of an unplanned or uncontrolled work incident.</p>
                    </div>

                    <div>
                        <h3>SERIOUS INJURY</h3>
                        <p>Notify WorkSafe if someone has a been <strong>SERIOUSLY INJURED</strong> as a result of a work incident.</p>
                    </div>

                    <div>
                        <h3>SERIOUS ILLNESS</h3>
                        <p>Notify WorkSafe if someone has a become <strong>SERIOUSLY ILL</strong> as a result of work.</p>
                    </div>

                    <div>
                        <h3>DEATH</h3>
                        <p>If there has been a death in the workplace, you MUST call WorkSafe IMMEDIATELY.</p>
                    </div>

                    <div id="worksafe-logo">
                        <img alt="Worksafe Logo" src="./assets/ui_images/worksafe_logo.png"></img>
                        <h1>0800 030 040</h1>
                    </div>
                </div>

            </IonContent>
            <IonFooter class="incidents-nav">
                <IonButton style={{ "grid-column-start": "1" }} onClick={dismiss}>Cancel</IonButton>
            </IonFooter>
        </>
    )
}