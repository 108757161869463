/**
 * Temporary (i.e. non-persisted) state items
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface TempState {
    startupInProgress: boolean
    splashStartTime: number
    appTime: number
    homeIconEvent: boolean
    apiLastPingTime: string | null
    
    appOpenUrl: string
    authCode: string
    isNewLogin: boolean // Last login was new, as opposed to restored session
    nextRefreshTime: number

    currentPath: string
    redirectPath: string
    lastPath: string

    alertHeader: string
    alertMessage: string
    alertResponse: string

    loadingSpinner: boolean
    loadingMessage: string
}

const initialState: TempState = {
    startupInProgress: true,
    splashStartTime: Date.now(),
    appTime: Date.now(),
    homeIconEvent: false,
    apiLastPingTime: null,
    
    appOpenUrl: '',
    authCode: '',
    isNewLogin: false,
    nextRefreshTime: 0,

    currentPath: '',
    redirectPath: '',
    lastPath: '',

    alertHeader: '',
    alertMessage: '',
    alertResponse: '',

    loadingSpinner: false,
    loadingMessage: ''
}

export const tempSlice = createSlice({
    name: 'temp',
    initialState,
    reducers: {

        setStartupInProgress(state, action: PayloadAction<boolean>) {
            state.startupInProgress = action.payload
        },

        setSplashStartTime(state, action: PayloadAction<void>) {
            state.splashStartTime = Date.now()
        },

        setAppTime(state, action: PayloadAction<void>) {
            state.appTime = Date.now()
        },

        setHomeIconEvent(state, action: PayloadAction<boolean>) {
            state.homeIconEvent = action.payload
        },

        setApiLastPingTime(state, action: PayloadAction<string>) {
            state.apiLastPingTime = action.payload
        },
                
        setAppOpenUrl(state, action: PayloadAction<string>) {
            state.appOpenUrl = action.payload
        },

        setAuthCode(state, action: PayloadAction<string>) {
            state.authCode = action.payload
        },  
        
        setIsNewLogin(state, action: PayloadAction<boolean>) {
            state.isNewLogin = action.payload
        },  

        setNextRefreshTime(state, action: PayloadAction<number>) {
            state.nextRefreshTime = action.payload
        },

        setCurrentPath(state, action: PayloadAction<string>) {
            state.currentPath = action.payload
        },

        setRedirectPath(state, action: PayloadAction<string>) {
            state.redirectPath = action.payload
        },

        setLastPath(state, action: PayloadAction<string>) {
            state.lastPath = action.payload
        },

        setAlertHeader(state, action: PayloadAction<string>) {
            state.alertHeader = action.payload
        },

        setAlertMessage(state, action: PayloadAction<string>) {
            state.alertMessage = action.payload
        },

        setAlertResponse(state, action: PayloadAction<string>) {
            state.alertResponse = action.payload
        },

        showLoadingSpinner(state, action: PayloadAction<string>) {
            state.loadingMessage = action.payload
            state.loadingSpinner = true
        },

        hideLoadingSpinner(state, action: PayloadAction<void>) {
            state.loadingSpinner = false
        },
    }
})

// Action creators are generated for each case reducer function
export const {
    setStartupInProgress,
    setSplashStartTime,
    setAppTime,
    setHomeIconEvent,
    setApiLastPingTime,
    setAppOpenUrl,
    setAuthCode,
    setIsNewLogin,
    setNextRefreshTime,
    setCurrentPath,
    setRedirectPath,
    setLastPath,
    setAlertHeader,
    setAlertMessage,
    setAlertResponse,
    showLoadingSpinner,
    hideLoadingSpinner
} = tempSlice.actions

export default tempSlice.reducer